import { API } from 'aws-amplify';

function UsacDataAPI() {
    async function getFileDataStats() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUsacDataStats';
            const queryStringParameters = {
                queryStringParameters: { datatype: 'usacfiledata' },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting data: ', error);
        }
    }

    async function getApiDataStats() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUsacDataStats';
            const queryStringParameters = {
                queryStringParameters: { datatype: 'usacapidata' },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting data: ', error);
        }
    }

    return {
        GetFileDataStats: getFileDataStats,
        GetApiDataStats: getApiDataStats,
    };
}

export default UsacDataAPI;

import React from 'react';
import Container from '@mui/material/Container';
import Embed from '../components/Embed.js';

function F470ApplyForDiscounts() {
    return (
        <>
            <Container
                maxWidth={false}
                style={{
                    width: '100%',
                }}
            >
                <Embed dashboardName='Form470TrackerMainReport' defaultToCurrentUser={true} />
            </Container>
        </>
    );
}

export default F470ApplyForDiscounts;

import React from 'react';
import Container from '@mui/material/Container';
import Embed from '../components/Embed.js';
import { useLocation } from 'react-router-dom';

function GlobalSearchResult() {
    const { state } = useLocation();
    const { searchType, searchText, searchState } = state;

    const [parameterObject, setParameterObject] = React.useState({});
    const [dashboardName, setDashboardName] = React.useState('');
    const [sheetID, setSheetID] = React.useState('');

    React.useEffect(() => {
        setParameterObject({});

        let tempParamObject = {};
        if (searchText !== '') {
            tempParamObject[searchType] = searchText;
            setParameterObject(tempParamObject);
        }

        if (searchType === 'BEN' || searchType === 'SPIN') {
            setDashboardName('ApplicantsSummaryDashboard');

            if (searchState !== '' && searchState !== 'ALL_STATES') {
                tempParamObject['State'] = searchState;
                setParameterObject(tempParamObject);
            }
        } else if (searchType === 'F471ID') {
            if (searchText.slice(0, 3).toUpperCase() === 'ECF') {
                tempParamObject['F471'] = searchText; //Handling that parameters are different for ECF dashboard vs Regular Dashboard
                setParameterObject(tempParamObject);
                setDashboardName('ApplicantsSummaryDashboard');
                setSheetID('177d3fcb-58cd-439d-a4db-a75b93bfb0dc_0fd2f367-81d6-4c8b-b8c6-192f34408128');
            } else {
                setDashboardName('ApplicationSummaryDashboard');
                setSheetID('b669d4e3-deb6-4aff-9ebb-c67bfe2f1ef8_6a0b87a0-6559-4b74-8ab2-f214fd289a78');
            }
        } else if (searchType === 'FRN') {
            if (searchText.slice(0, 3).toUpperCase() === 'ECF') {
                tempParamObject['FRNECF'] = searchText;
                setParameterObject(tempParamObject);
                setDashboardName('ApplicantsSummaryDashboard');
                setSheetID('177d3fcb-58cd-439d-a4db-a75b93bfb0dc_7467e410-c33c-4f7e-8e4e-f8e7118f1e07');
            } else {
                setDashboardName('ApplicationSummaryDashboard');
                setSheetID('b669d4e3-deb6-4aff-9ebb-c67bfe2f1ef8_dee02a4a-f1a8-4a79-8f07-e6e705dc31de');
            }
        } else if (searchType === 'CRNSingle') {
            setDashboardName('ApplicantsSummaryDashboard');
            setSheetID('177d3fcb-58cd-439d-a4db-a75b93bfb0dc_402ef36e-c37c-429a-a682-2b821420eaff');

            if (searchState !== '' && searchState !== 'ALL_STATES') {
                tempParamObject['State'] = searchState;
                setParameterObject(tempParamObject);
            }
        }
    }, [searchType, searchText, searchState]);

    return (
        <Container
            maxWidth={false}
            style={{
                width: '100%',
            }}
        >
            <Embed
                dashboardName={dashboardName}
                sheetID={sheetID}
                defaultToCurrentUser={false}
                passedParameters={parameterObject}
            />
        </Container>
    );
}

export default GlobalSearchResult;

import React from 'react';
import Container from '@mui/material/Container';
import Embed from '../components/Embed.js';

function LandingPage() {
    return (
        <Container
            maxWidth={false}
            style={{
                width: '100%',
            }}
        >
            <Embed dashboardName='ApplicantsSummaryDashboard' defaultToCurrentUser={true} />
        </Container>
    );
}

export default LandingPage;

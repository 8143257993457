import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { makeStyles } from '@mui/styles';

export const BulkEditDatePicker = ({ label, providedDate, initialDate, onDateChange, multipleValues }) => {
    const [date, setDate] = useState(providedDate && providedDate !== '<cleared>' ? providedDate : '');
    const [isInteracted, setIsInteracted] = useState(false);
    const [showClearCalendarIcon, setShowClearCalendarIcon] = useState(false);
    const [showRefreshIcon, setShowRefreshIcon] = useState(false);
    const [isRed, setIsRed] = React.useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setDate(newValue);
        onDateChange(newValue);
        setIsInteracted(newValue !== '');

        if (showRefreshIcon && newValue) {
            setShowRefreshIcon(false);
            setIsRed(false);
        }
    };

    const handleClearOutDate = () => {
        onDateChange('<cleared>');
        setDate('');
        setIsInteracted(false);
        setShowRefreshIcon(true);
        setIsRed(true);
    };

    const handleResetField = () => {
        onDateChange('');
        initialDate == null ? setDate('') : setDate(initialDate);
        setIsInteracted(false);
        setIsRed(false);
        setShowRefreshIcon(false);
        setShowClearCalendarIcon(false);
    };

    React.useEffect(() => {
        setIsInteracted(providedDate !== '');

        if (providedDate === '<cleared>') {
            setShowRefreshIcon(true);
            setIsRed(true);
            setShowClearCalendarIcon(true);
        } else if (providedDate !== '<cleared>' && providedDate !== '') {
            setShowRefreshIcon(true);
            setIsRed(false);
            // setShowClearCalendarIcon(true);
        }
    }, [providedDate]);

    const useStyles = makeStyles((theme) => ({
        redButton: {
            color: '#ff0000',
            '&:disabled': {
                color: '#ff0000',
            },
        },
    }));

    const CustomCalendarButton = ({ onClick, disabled, isRed }) => {
        const classes = useStyles();

        return (
            <Tooltip title={isRed ? 'Clearing Existing Dates' : 'Clear Existing Dates'} disableInteractive={false}>
                <span>
                    <IconButton onClick={onClick} disabled={disabled} className={isRed ? classes.redButton : ''}>
                        <EventBusyIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <TextField
                margin='dense'
                id='bulk-edit-date-picker'
                label={label}
                type='date'
                fullWidth
                variant='outlined'
                onClick={(event) => {
                    setShowClearCalendarIcon(true);
                }}
                value={date}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: isInteracted && initialDate !== providedDate ? 'orange' : '',
                            borderWidth: isInteracted && initialDate !== providedDate ? '2px' : '1px',
                        },
                    },
                    backgroundColor: multipleValues ? '#f0f0f0' : '',
                }}
            />

            {showClearCalendarIcon && (
                <CustomCalendarButton onClick={handleClearOutDate} disabled={showRefreshIcon && isRed} isRed={isRed} />
            )}

            {showRefreshIcon && initialDate !== providedDate && (
                <Tooltip title='Reset Field'>
                    <IconButton onClick={handleResetField}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

import React from 'react';
import { API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import { useAuthContext } from '../useAuthContext';

var QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

const useStyles = (theme) => ({
    loading: {
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: theme.spacing(4),
    },
});

function Embed(props) {
    const { classes } = props;
    const { dashboardName } = props;
    const { defaultToCurrentUser } = props;
    const { passedParameters } = props;
    const { sheetID } = props;

    const auth = useAuthContext();
    const [loader, setLoader] = React.useState(true);

    React.useEffect(() => {
        if (dashboardName) {
            setLoader(true);
            const containerDiv = document.getElementById('dashboardContainer');
            containerDiv.innerHTML = '';
            getQuickSightDashboardEmbedURL();
        }
    }, [dashboardName, passedParameters]);

    async function getQuickSightDashboardEmbedURL() {
        const quicksight = await API.get('ERateCentralPortalAPI', '/getQuickSightDashboardEmbedURL', {
            queryStringParameters: { name: dashboardName },
        });
        //console.log(quicksight);
        const containerDiv = document.getElementById('dashboardContainer');

        let parametersObject = {
            ...passedParameters,
        };

        if (defaultToCurrentUser) {
            parametersObject.AccountManagerName = auth.userFullName;
            parametersObject.AccountManager = auth.userFullName;
        }

        const options = {
            url: quicksight,
            container: containerDiv,
            scrolling: 'no',
            height: 'AutoFit',
            loadingHeight: '700px',
            iFrameResizeOnSheetChange: true,
            footerPaddingEnabled: true,
            sheetTabsDisabled: false,
            printEnabled: true,
            undoRedoDisabled: false,
            resetDisabled: false,
            sheetId: sheetID,
            parameters: parametersObject,
        };
        const dashboard = QuickSightEmbedding.embedDashboard(options);
        setLoader(false);
    }

    if (!props.dashboardName) {
        return null;
    }

    return (
        <div className='outermost'>
            {loader && (
                <div className={classes.loading}>
                    {' '}
                    <CircularProgress />{' '}
                </div>
            )}
            <div id='dashboardContainer' style={{ textAlign: 'center' }}></div>
        </div>
    );
}

export default withStyles(useStyles)(Embed);

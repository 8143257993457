import React from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form470TrackerAppDetails from '../components/Form470TrackerAppDetails.js';
import Form470TrackerAppEdit from '../components/Form470TrackerAppEdit.js';
import TrackersAPI from '../api/TrackersAPI.js';

export default function F470TAppDetailsPage({ displayMode }) {
    const trackersAPI = new TrackersAPI();
    const [displayMode2, setDisplayMode2] = React.useState(displayMode && '');

    let params = useParams();
    const form470AppNum = params.form470AppNum;
    const form470AppIncrement = params.form470AppIncrement;
    //console.log('form470AppNum = ', form470AppNum);

    if (!form470AppNum) {
        console.error('Invalid form470AppNum: ' + form470AppNum);
        toast.error('Invalid form470AppNum: ' + form470AppNum);
        return null;
    }

    if (!form470AppIncrement) {
        console.error('Invalid form470AppIncrement: ' + form470AppIncrement);
        toast.error('Invalid form470AppIncrement: ' + form470AppIncrement);
        return null;
    }

    function handleEditButtonClick() {
        setDisplayMode2('edit');
    }

    function handleBackToDetails() {
        setDisplayMode2('details');
    }

    if (displayMode2 === 'edit') {
        return (
            <>
                <ToastContainer theme='colored' />
                <Form470TrackerAppEdit
                    form470AppNum={form470AppNum}
                    form470AppIncrement={form470AppIncrement}
                    trackersAPI={trackersAPI}
                    handleReturnToSourcePage={handleBackToDetails}
                />
            </>
        );
    } else {
        return (
            <>
                <ToastContainer theme='colored' />
                <Form470TrackerAppDetails
                    form470AppNum={form470AppNum}
                    form470AppIncrement={form470AppIncrement}
                    trackersAPI={trackersAPI}
                    handleEditButtonClick={handleEditButtonClick}
                />
            </>
        );
    }
}

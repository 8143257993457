import React from 'react';
import UserAPI from '../api/UserAPI.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Divider from '@mui/material/Divider';

const userAPI = new UserAPI();

function NotificationTypes() {
    const [notificationTypes, setNotificationTypes] = React.useState([]);

    React.useEffect(() => {
        const getNotificationTypes = async () => {
            try {
                let notificationTypes = await userAPI.GetNotificationTypes();
                //console.log(notificationTypes);
                setNotificationTypes(notificationTypes);
            } catch (error) {
                //console.log('Error loading notification types');
                console.error(error);
                toast.error('Error loading notification types');
            }
        };
        getNotificationTypes();
    }, []);

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <Box>
                {notificationTypes.map((type) => {
                    return (
                        <Box key={type.name} sx={{ marginTop: '8px', marginBottom: '8px' }}>
                            <Typography variant='h4'>{type.display_name}</Typography>
                            <Typography
                                sx={{
                                    fontStyle: 'italic',
                                    color: 'gray',
                                    fontWeight: 'light',
                                    fontSize: 12,
                                }}
                            >
                                Cadence: {type.cadence}
                            </Typography>
                            <Box sx={{ marginLeft: '25px', marginBottom: '25px+' }}>
                                <Typography variant='body'>{type.description}</Typography>
                            </Box>
                            <Divider />
                        </Box>
                    );
                })}
            </Box>
        </>
    );
}

export default NotificationTypes;

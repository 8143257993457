import * as React from 'react';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { TextField, InputLabel } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

function DoesNotContainInputComponent(props) {
    const { item, applyValue } = props;
    const applyValueRef = React.useRef(applyValue);
    const [filterValue, setFilterValue] = React.useState(item.value || '');
    const [isApplying, setIsApplying] = React.useState(false);
    const filterTimeoutRef = React.useRef();
    // console.log('doesNotContainFilter: DoesNotContainInputComponent item = ', item); // ({columField (field name), id (row's id), operatorValue, value (user's input)})

    React.useEffect(() => {
        applyValueRef.current = applyValue;
    });

    const handleValueChange = (event) => {
        const newValue = event.target.value;
        setFilterValue(newValue);
        setIsApplying(true);

        clearTimeout(filterTimeoutRef.current);
        filterTimeoutRef.current = setTimeout(() => {
            setIsApplying(false);
            applyValueRef.current({ ...item, value: newValue });
        }, 500);
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeoutRef.current);
        };
    }, []);

    return (
        <>
            <InputLabel shrink>Value</InputLabel>
            <TextField
                type='text'
                value={filterValue}
                onChange={handleValueChange}
                placeholder='Filter value'
                variant='standard'
                InputProps={{
                    endAdornment: isApplying ? <SyncIcon /> : null,
                }}
                sx={{ marginTop: 2 }}
                fullWidth
            />
        </>
    );
}

// For the filterOperators attribute of a column definition
const doesNotContainOperator = {
    label: "doesn't contain",
    value: 'doesNotContain',

    // filterItem is the 'configuration' info of your applied filter
    getApplyFilterFn: (filterItem, column) => {
        if (
            !filterItem.columnField ||
            !filterItem.operatorValue ||
            filterItem.value === null ||
            filterItem.value === undefined
        ) {
            return null;
        }

        // User's provided value
        const loweredFilterValue = filterItem.value.toString().toLowerCase();

        if (loweredFilterValue === '') {
            // Filter box is empty so don't filter.
            return () => true;
        }

        // params is individual row data
        return (params) => {
            if (params.value === undefined) {
                // For columns that are not really 'string' columns.
                return true;
            }

            // The field's value as a string
            const valueAsString = params.value != null ? params.value.toString().toLowerCase() : '';

            // Text to search against the column's value
            return !valueAsString.includes(loweredFilterValue);
        };
    },
    InputComponent: DoesNotContainInputComponent,
};

const doesNotContainWrapper = (columns) => {
    const defaultStringOperators = getGridStringOperators();

    return columns.map((col) => {
        const columnType = col.type || 'string';

        if (columnType === 'string') {
            const containsOperatorIndex = defaultStringOperators.findIndex((ea_operator) => {
                return ea_operator.value === 'contains';
            });
            let customOperators = [...defaultStringOperators];

            // If 'contains' is present, put 'doesn't contain' one index below it, else at the end
            const insertIndex = containsOperatorIndex >= 0 ? containsOperatorIndex + 1 : customOperators.length;
            customOperators.splice(insertIndex, 0, doesNotContainOperator);

            return {
                ...col,
                filterOperators: customOperators,
            };
        }
        return col;
    });
};

export default doesNotContainWrapper;

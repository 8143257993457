import * as React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function MultiAutoComplete(props) {
    const { id, label = '', options = [], selectedOptions = [], handleSelectChange = () => {} } = props;

    function eqCheck(option, value) {
        if (typeof value === 'object') {
            return option.value === value.value;
        } else {
            return option.value === value;
        }
    }

    function showOpt(option) {
        let resultObj = undefined;
        if (typeof option === 'object') {
            resultObj = options.find((obj) => {
                return obj.value === option.value;
            });
        } else {
            resultObj = options.find((obj) => {
                return obj.value === option;
            });
        }

        if (resultObj) {
            return resultObj.text;
        }
    }

    return (
        <Autocomplete
            multiple
            id={id}
            options={options}
            value={selectedOptions}
            getOptionLabel={(option) => showOpt(option)}
            isOptionEqualToValue={(option, value) => eqCheck(option, value)}
            onChange={(event, value) => {
                let returnArray = value.map((object) => {
                    if (typeof object === 'object') {
                        return object.value;
                    } else {
                        return object;
                    }
                });

                handleSelectChange(event, returnArray);
            }}
            variant='outlined'
            fullWidth
            renderInput={(params) => <TextField {...params} label={label} placeholder={label} />}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>
                        {option.text}
                    </li>
                );
            }}
        />
    );
}

MultiAutoComplete.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: function (props, propName, componentName) {
        if (!(props[propName] instanceof Array)) {
            return new Error(
                `Invalid prop '${propName}' supplied to '${componentName}' - Array required, recieved '${typeof props[
                    propName
                ]}' instead`
            );
        }
    },
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    handleSelectChange: PropTypes.func,
};

export default MultiAutoComplete;

import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { addDays, subDays } from 'date-fns';
import { API } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// MUI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { useGridApiRef, GridRowModes } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// Portal
import { useAuthContext } from '../useAuthContext';
import TrackersAPI from '../api/TrackersAPI.js';
import UserAPI from '../api/UserAPI.js';
import FRNTable from './FRNTable.js';
import MultiAutoComplete from './MultiAutoComplete.js';

import { TDGPrefsContext, useProvideTDGPrefsContext } from './TDGPrefsContext';
import { TrackerDataGrid, determineInitialHideShowState } from './TrackerDataGrid.js';
import { Form471ApplicationsColumnsDefinitionCreator2 } from './datagridcolumns/Form471ApplicationsColumnsDefinitionCreator.js';
import NotesEditDialog from './dialogs/NotesEditDialog.js';
import { PromptUpdateOrSaveNewViewDialog } from './customcomponents/PromptUpdateOrSaveNewViewDialog.js';
import { PromptUpdateOrSaveNewSearchDialog } from './customcomponents/PromptUpdateOrSaveNewSearchDialog.js';
import { Form471AppsBulkEdit } from './dialogs/Form471AppsBulkEdit.js';

export default function Form471TrackerApplications(props) {
    const appliesTo = 'Form471 Tracker';
    const auth = useAuthContext();
    const trackersAPI = new TrackersAPI();
    const userAPI = new UserAPI();
    const [currentUserID, setCurrentUserID] = React.useState(null);
    const [searchParameters, setSearchParameters] = React.useState(null);
    let navigate = useNavigate();
    let params = useParams();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const benFromURL = parseInt(urlSearchParams.get('ben'));
    const fundingYearFromURL = parseInt(urlSearchParams.get('fundingYear'));

    const handleDetailsButtonClick = (form471AppNum) => {
        navigate('details/' + form471AppNum);
    };

    const handleEditButtonClick = (form471AppNum) => {
        navigate('edit/' + form471AppNum);
    };

    const clearQueryStrings = () => {
        navigate(location.pathname, { replace: true });
    };

    React.useEffect(() => {
        const getCurrentUserID = async (cognitoID) => {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetDatabaseIDForUser';
            const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

            const idResponse = await API.get(apiName, path, queryStringParameters);
            setCurrentUserID(idResponse);
        };

        getCurrentUserID(auth.cognitoID);
    }, []);

    // handleFieldsInitialized puts a value into searchParameters which causes the table to query and load data on first page load.
    return (
        <>
            <Form471TrackerAppsSearchBox
                appliesTo={appliesTo}
                benFromURL={benFromURL}
                clearQueryStrings={clearQueryStrings}
                currentUserID={currentUserID}
                defaultBen={params.ben}
                fundingYearFromURL={fundingYearFromURL}
                handleFieldsInitialized={setSearchParameters}
                handleSearchButtonClick={setSearchParameters}
                trackersAPI={trackersAPI}
                userAPI={userAPI}
            />
            <LastForm471UsacDataRetrievalDatetimes trackersAPI={trackersAPI} />
            <Form471TrackerAppsTable
                appliesTo={appliesTo}
                benFromURL={benFromURL}
                currentUserID={currentUserID}
                fundingYearFromURL={fundingYearFromURL}
                handleDetailsButtonClick={handleDetailsButtonClick}
                handleEditButtonClick={handleEditButtonClick}
                searchParameters={searchParameters}
                trackersAPI={trackersAPI}
                userAPI={userAPI}
            />
        </>
    );
}

function Form471TrackerAppsTable({
    appliesTo,
    benFromURL,
    currentUserID,
    fundingYearFromURL,
    handleDetailsButtonClick,
    handleEditButtonClick,
    searchParameters,
    trackersAPI,
    userAPI,
}) {
    const CACHE_INCREMENT = 1;
    const F471_CACHE_NAME = 'ced-f471tapplications-cache-' + CACHE_INCREMENT;

    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const defaultFilterPrefs = '{"items":[]}';
    const defaultSortPrefs = '[]';
    const defaultViewPrefs = '{}';
    const defaultSavedViewNamePrefs = '';
    const defaultCurrentSavedViewWidthPrefs = '{}';
    const defaultOriginalSavedViewWidthPrefs = '{}';
    const defaultCurrentSavedViewOrderPrefs = '[]';
    const defaultOriginalSavedViewOrderPrefs = '[]';
    const defaultHiddenColumns =
        '{"FilingWindowID":false,"fundingProgram":false,"state":false,"QAReviewStatusID":false,"QASubmitterUserID":false,"QADateSubmitted":false,"QADateNeeded":false,"QAEstimatedFundingRequest":false,"QAReviewerUserID":false,"_qaNotes":false,"_appStatusCombined":false}';
    const defaultPinnedColumns = '{"left":["actions"],"right":[]}';

    const prefs = useProvideTDGPrefsContext(
        LS_INCREMENT, // localStorageIncrement,
        SS_INCREMENT, // sessionStorageIncrement,
        'cedF471TApplications', // localStoragePrefix,
        'cedF471TApplications', // sessionStoragePrefix,
        defaultFilterPrefs, // defaultFilterPrefs,
        defaultSortPrefs, // defaultSortPrefs,
        defaultViewPrefs,
        defaultSavedViewNamePrefs,
        defaultCurrentSavedViewWidthPrefs,
        defaultOriginalSavedViewWidthPrefs,
        defaultCurrentSavedViewOrderPrefs,
        defaultOriginalSavedViewOrderPrefs,
        defaultHiddenColumns, // defaultHiddenColumns,
        defaultPinnedColumns // defaultPinnedColumns
    );

    //################################### VIEW STATE #############################################
    const [resetWatcher, setResetWatcher] = React.useState(0);
    const [currentSavedViews, setCurrentSavedViews] = React.useState([]);
    const [editingSavedViewName, setEditingSavedViewName] = React.useState('');
    const [currentColumnWidths, setCurrentColumnWidths] = React.useState({});
    const [currentColumnOrder, setCurrentColumnOrder] = React.useState([]);
    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [savedViewObject, setSavedViewObject] = React.useState({});

    // ################################### User data for NotesEditDialog and Bulk Editing #############################################
    const [userData, setUserData] = React.useState(null);

    // ################################### Bulk Editing #############################################
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [bulkEditButtonClicked, setBulkEditButtonClicked] = React.useState(false);
    const [pruUpdatedRow, setPruUpdatedRow] = React.useState({});

    // ################################### NOTES STATE #############################################
    const [openNotesEditDialog, setOpenNotesEditDialog] = React.useState(false);
    const [editableNotes, setEditableNotes] = React.useState('');
    const [notesEditRowId, setNotesEditRowId] = React.useState(null); // Used in saveEditedNotes function.
    const [notesFieldName, setNotesFieldName] = React.useState('');
    const [notesEditDialogTitle, setNotesEditDialogTitle] = React.useState('');
    const [notesEditDialogLabel, setNotesEditDialogLabel] = React.useState('');

    // ################################### Data and MS State #############################################
    const apiRef = useGridApiRef();

    //----- Table data -----
    const [trackerData, setTrackerData] = React.useState([]);
    const [dataIsLoading, setDataIsLoading] = React.useState(false);
    const [usedCacheOnLoad, setUsedCacheOnLoad] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});

    //----- Filter Options -----
    const [applicantMSFilterOptions, setApplicantMSFilterOptions] = React.useState([]);
    const [stateMSFilterOptions, setStateMSFilterOptions] = React.useState([]);
    const [applicantCohortMSFilterOptions, setApplicantCohortMSFilterOptions] = React.useState([]);
    const [fundingProgramMSFilterOptions, setFundingProgramMSFilterOptions] = React.useState([]);
    const [piaReviewStatusMSFilterOptions, setPIAReviewStatusMSFilterOptions] = React.useState([]);

    //----- Inline-edit dropdowns options -----
    const [haveEditDropdownOptions, setHaveEditDropdownOptions] = React.useState(false);
    const [filingWindowEditOptions, setFilingWindowEditOptions] = React.useState([]);
    const [applicationOwnerEditOptions, setApplicationOwnerEditOptions] = React.useState([]);
    const [taskOwnerEditOptions, setTaskOwnerEditOptions] = React.useState([]);
    const [ercAppStatusEditOptions, setERCAppStatusEditOptions] = React.useState([]);
    const [qaReviewStatusEditOptions, setQAReviewStatusEditOptions] = React.useState([]);
    const [qaReviewerEditOptions, setQAReviewerEditOptions] = React.useState([]);
    const [qaSubmitterEditOptions, setQASubmitterEditOptions] = React.useState([]);
    const [qaEstimatedFundingRequestEditOptions, setQAEstimatedFundingRequestEditOptions] = React.useState([]);

    //----- Get the inline-editing dropdown options and get cached data -----
    // Gets all of the saved views (should be called on page load within useEffect)
    const getSavedViews = async (ap) => {
        const viewResults = await userAPI.GetSavedViews(ap);
        const parsedViewResults = JSON.parse(viewResults);
        // console.log('Form471TrackerAppsTable: getSavedViewsFN: parsedViewResults = ', parsedViewResults);

        // Defines the base layout for all retrieved views
        const structuredViews = parsedViewResults.map((ea_row) => ({
            view_id: ea_row.id,
            view_name: ea_row.view_name,
            parameters: ea_row,
        }));
        structuredViews.sort((a, b) => a.view_name.localeCompare(b.view_name));
        console.log('Form471TrackerAppsTable: getSavedViewsFN: structuredViews = ', structuredViews);

        setCurrentSavedViews(structuredViews);
    };

    React.useEffect(() => {
        const checkCacheForPreviousSearch = async () => {
            let APICache = await caches.open(F471_CACHE_NAME);
            const response = await APICache.match('/customForm471Search');

            if (response !== undefined) {
                const cachedData = await response.json();
                setTrackerData(cachedData);
                setUsedCacheOnLoad(true);
            }
        };

        const fetchInlineEditOptions = async () => {
            try {
                let result = await trackersAPI.GetForm471TrackerMainReportEditOptions();
                // console.log('[fetchInlineEditOptions] inline-editing options = ', result);
                setFilingWindowEditOptions(result.filingWindows);
                setApplicationOwnerEditOptions(result.applicationOwners);
                setTaskOwnerEditOptions(result.taskOwners);
                setERCAppStatusEditOptions(result.ercAppStatuses);
                setQAReviewStatusEditOptions(result.qaReviewStatuses);
                setQAReviewerEditOptions(result.qaReviewers);
                setQASubmitterEditOptions(result.qaSubmitters);
                setQAEstimatedFundingRequestEditOptions(result.qaEstimatedFundingRequests);
                setHaveEditDropdownOptions(true);
            } catch (error) {
                console.error('fetchInlineEditOptions error: ', error);
                toast.error(error);
            }
        };

        const getOptionsForFiltering = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                let options = await trackersAPI.GetForm471TrackerMainReportFilterOptions();
                // console.log('[getOptionsForFiltering][options] =', options);
                setApplicantMSFilterOptions(options.applicants);
                setApplicantCohortMSFilterOptions(options.applicantCohorts);
                setFundingProgramMSFilterOptions(options.fundingProgram);
                setPIAReviewStatusMSFilterOptions(options.reviewStatuses);
                setStateMSFilterOptions(options.states);
            } catch (error) {
                console.log('Error getting filter options: ' + error);
                toast.error(error);
            }
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUserData(parsedObject);
        };

        if (!benFromURL && !fundingYearFromURL) {
            checkCacheForPreviousSearch();
        }
        fetchInlineEditOptions();
        getOptionsForFiltering();
        getUserData();
        getSavedViews(appliesTo);
    }, []);

    //----- Generate the report data (whenever searchParameters changes) -----
    React.useEffect(() => {
        const searchForm471Applications = async (searchParameters) => {
            try {
                if (!usedCacheOnLoad) {
                    setDataIsLoading(true);
                }

                // Contains LS/SS data or default data
                const searchParametersForAPI = {
                    // TODO: Pluralize searchParameters field names that are multiple. Here and in service and repository.
                    funding_year: searchParameters.fundingYears,
                    filing_window: searchParameters.filingWindows,
                    primary_contact: searchParameters.primaryContacts,
                    secondary_contact: searchParameters.secondaryContacts,
                    ben: searchParameters.ben,
                    application_number: searchParameters.applicationNumber,
                    nickname: searchParameters.nickname,
                    category: searchParameters.category,
                    applicant: searchParameters.applicant,
                    application_owner: searchParameters.applicationOwners,
                    task_owner: searchParameters.taskOwners,
                    is_a_client: searchParameters.isAClient,
                    applicant_cohort: searchParameters.applicantCohorts,
                    usac_app_status: searchParameters.usacAppStatuses,
                    erc_app_status: searchParameters.ercAppStatuses,
                    pia_review_status: searchParameters.piaReviewStatuses,
                    qa_status: searchParameters.qaStatuses,
                    qa_reviewer: searchParameters.qaReviewers,
                    services: searchParameters.services,
                    teams: searchParameters.teams,
                    states: searchParameters.states,
                    // deadline_field_name: searchParameters?.deadlineFieldName?.value ?? null,
                    // deadline_field_operator: searchParameters?.deadlineFieldOperator?.value ?? null,
                    // deadline_field_days: searchParameters?.deadlineFieldDays ?? null,
                    // calculated_deadline_date: searchParameters.calculatedDeadlineDate
                };

                // const deadlineFieldName = searchParameters?.deadlineFieldName?.value ?? null;
                // const deadlineFieldOperator = searchParameters?.deadlineFieldOperator?.value ?? null;
                // const deadlineFieldDays = searchParameters?.deadlineFieldDays ?? null;
                // console.log(deadlineFieldName, deadlineFieldOperator, deadlineFieldDays);

                // if (deadlineFieldName && deadlineFieldOperator && deadlineFieldDays) {
                //     let targetDate = 0;
                //     const currentDate = new Date();
                //     searchParametersForAPI.current_date = currentDate.toISOString().split('T')[0];

                //     if (deadlineFieldOperator == 'isBeforeXDays') {
                //         targetDate = subDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }

                //     if (deadlineFieldOperator == 'isAfterXDays') {
                //         targetDate = addDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }
                // }
                console.log(
                    '[Form471TrackerAppsTable, React.useEffect, searchForm471Applications]  searchParametersForAPI = ',
                    searchParametersForAPI
                );

                let trackerSearchResult = await trackersAPI.CustomForm471Search(searchParametersForAPI);
                console.log(
                    '[Form471TrackerAppsTable, React.useEffect, searchForm471Applications]  trackerSearchResult = ',
                    trackerSearchResult
                );

                if (trackerSearchResult !== false) {
                    let APICache = await caches.open(F471_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    APICache.put(
                        '/customForm471Search',
                        new Response(JSON.stringify(trackerSearchResult), headersOptions)
                    );
                }

                setTrackerData(trackerSearchResult);
                setUsedCacheOnLoad(false);
                setDataIsLoading(false);
            } catch (error) {
                toast.error(error);
            }
        };

        if (searchParameters) {
            console.log('Form471TrackerAppsTable: searchForm471ApplicationsFN: executing search...');
            if (!usedCacheOnLoad) {
                setTrackerData([]); // Setting data to a empty array displays the loading spinner while we query for new data.
            }
            searchForm471Applications(searchParameters);
        }
    }, [searchParameters]);

    //----- Details Panel -----
    const getDetailPanelContent = React.useCallback(
        ({ row }) => (
            <Stack
                sx={{
                    padding: '25px',
                    marginLeft: '25px',
                }}
            >
                {row.fundingProgram === 'ECF' && (
                    <Typography variant='h3' mt={0} mb={0}>
                        No FST Data for ECF Application
                    </Typography>
                )}
                {row.fundingProgram !== 'ECF' && (
                    <>
                        <Typography variant='h3' mt={0} mb={0}>
                            FRNs for Application Number: {row.form471AppNum}
                        </Typography>
                        <FRNTable form471AppNum={row.form471AppNum} trackersAPI={trackersAPI} />
                    </>
                )}
            </Stack>
        ),
        []
    );

    const getDetailPanelHeight = React.useCallback(() => 'auto', []);

    //################################### SAVED VIEWS #############################################
    const endsWithView = (viewName) => {
        const lowercaseName = viewName.trim().toLowerCase();
        return lowercaseName.endsWith('view') || lowercaseName.split(-4).pop().endsWith('view');
    };

    // Check if the view name already exists
    const viewNameExists = (viewName) => {
        return currentSavedViews.some((ea_view) => ea_view.view_name === viewName);
    };

    // NOTE: attempting to immediately save the view BEFORE the toast expires can lead to warning being displayed
    const handleSaveView = async (vp_obj) => {
        const newOrUpdatedViewName = prefs.viewNamePrefs;
        setSavedViewObject(vp_obj);

        if (newOrUpdatedViewName !== '' && newOrUpdatedViewName !== null) {
            // Search for duplicate/determine if the user has already selected a view
            const duplicateViewIndex = currentSavedViews.findIndex(
                (ea_view) => ea_view.view_name === newOrUpdatedViewName
            );

            // If a duplicate was found/the user has already selected a view
            if (duplicateViewIndex !== -1) {
                openDialog(newOrUpdatedViewName); // opens the promptUpdateOrSaveNewView
            }
        } else {
            openDialog2();
            promptSaveNewView();
        }

        getSavedViews(appliesTo);
        prefs.setViewNamePrefs(newOrUpdatedViewName);
    };

    const handleOnlyUpdateView = async () => {
        let message = 'update';
        let newOrUpdatedViewName = prefs.viewNamePrefs;
        let tempCurrentSavedViews = [...currentSavedViews];

        const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === newOrUpdatedViewName);

        // Finds the matching view object
        const matchingSavedView = currentSavedViews[duplicateViewIndex];

        // Building the updated view object (state updates too slowly to add viewName to CSPO)
        const replacementView = {
            view_id: matchingSavedView.parameters.id,
            view_name: newOrUpdatedViewName,
            parameters: savedViewObject,
        };
        replacementView['parameters']['viewName'] = newOrUpdatedViewName;

        // Update state
        tempCurrentSavedViews[duplicateViewIndex] = replacementView;
        const response = await userAPI.SaveView(
            currentUserID,
            message,
            appliesTo,
            tempCurrentSavedViews.at(duplicateViewIndex)
        );

        if (response) {
            setLanding(false);
            setShowTextField1(false);
            setIsDialogOpen1(false);

            let successMessage = endsWithView(newOrUpdatedViewName)
                ? `Successfully updated the ${newOrUpdatedViewName}`
                : `Successfully updated the ${newOrUpdatedViewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithView(newOrUpdatedViewName)
                ? `Failed to update the ${newOrUpdatedViewName}`
                : `Failed to update the ${newOrUpdatedViewName} saved view`;

            toast.error(failedMessage);
        }
        getSavedViews(appliesTo);
        setLanding(true);
    };

    const handleOnlySaveAsNewView1 = () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewView2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved view name cannot be empty!');
            return;
        }

        const message = 'new';
        const viewName = textFieldValue.trim();
        let tempCurrentSavedViews = [...currentSavedViews];

        if (viewNameExists(viewName)) {
            toast.error(
                `A saved view with the name ${viewName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const newView = {
            view_name: viewName,
            parameters: savedViewObject,
        };
        newView['parameters']['viewName'] = viewName;
        tempCurrentSavedViews.push(newView);

        const response = await userAPI.SaveView(currentUserID, message, appliesTo, tempCurrentSavedViews.at(-1));
        if (response) {
            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            let successMessage = endsWithView(viewName)
                ? `Successfully created the ${viewName}`
                : `Successfully created the ${viewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithView(viewName)
                ? `Failed to create the ${viewName}`
                : `Failed to create the ${viewName} saved view`;

            toast.error(failedMessage);
        }
        setLanding(true);
        getSavedViews(appliesTo);
        prefs.setViewNamePrefs(viewName);
    };

    // Called when the user clicks the edit icon
    const handleEditSavedViewName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/view_name already exists for the other saved views
            if (currentSavedViews.some((ea_view) => ea_view.view_name === new_name)) {
                alert('A saved view with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the view we want to edit
            const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === old_name);

            // If a duplicate was found, let's update it
            if (duplicateViewIndex !== -1) {
                // Returns an array of objects with the previous saved view + the edited view
                const updatedViews = currentSavedViews.map((ea_view, index) => {
                    if (index === duplicateViewIndex) {
                        return {
                            ...ea_view,
                            view_name: new_name,
                            parameters: {
                                ...ea_view.parameters,
                                view_name: new_name,
                                view_filters: {
                                    ...ea_view.parameters.view_filters,
                                    viewName: new_name, // for setting selectedViewName on render
                                },
                            },
                        };
                    }
                    return ea_view;
                });
                // console.log(
                //     'Form471TrackerAppsTable: editSavedViewNameFN: modified/edited object = ',
                //     updatedViews.at(duplicateViewIndex)
                // );

                // Process the edited view
                const view_response = await userAPI.SaveView(
                    currentUserID,
                    message,
                    appliesTo,
                    updatedViews.at(duplicateViewIndex)
                );
                // console.log('Form471TrackerAppsTable: editSavedViewNameFN: view_response = ', view_response);

                // Update the state
                if (old_name === prefs.viewNamePrefs) {
                    prefs.setViewNamePrefs(new_name);
                }
                getSavedViews(appliesTo);

                if (view_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedView = async (view_name, view_id, applies_to, user_id) => {
        let confirmationMessage = endsWithView(view_name)
            ? `Are you sure you want to delete the ${view_name}?`
            : `Are you sure you want to delete the ${view_name} saved view?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedView(view_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithView(view_name)
                    ? `Successfully deleted the ${view_name}`
                    : `Successfully deleted the ${view_name} saved view`;

                toast.success(successMessage, {
                    autoClose: 3000,
                });

                prefs.resetViewNamePrefs();
                getSavedViews(appliesTo);
            } else {
                const errorMessage = endsWithView(view_name)
                    ? `Failed to delete the ${view_name}`
                    : `Failed to delete the ${view_name} saved view`;

                toast.error(errorMessage);
            }
            // console.log('Form471TrackerAppsTable: deleteSavedViewFN: delete_response = ', delete_response);
        }
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewView2();
        }
    };

    // Asks if the user wants to update the current view or save it as a new view, controls dialog
    const openDialog = (newOrUpdatedViewName) => {
        let confirmationMessage = endsWithView(newOrUpdatedViewName)
            ? `Do you want to update the ${newOrUpdatedViewName} or save as a new view?`
            : `Do you want to update the ${newOrUpdatedViewName} saved view or save as a new view?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewView = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved view: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='View Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewView2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED VIEWS #############################################

    // ################################### Custom Notes Start (NotesEditDialog) #############################################

    const usernameForNotesEditDialog = userData ? userData.email.split('@')[0] : '';

    // Functions to be passed to Form470ApplicationsColumnsDefinitionCreator for the pencil button onClick:
    const handleEditApplicationNotes = (rowId, fieldName, currentNotes, ben, nickname) => {
        setNotesEditRowId(rowId);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(`Edit Note for BEN: ${ben}, Application: ${rowId}, Nickname: ${nickname}`);
        setNotesEditDialogLabel('Application Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };
    const handleEditQANotes = (rowId, fieldName, currentNotes, ben, nickname) => {
        setNotesEditRowId(rowId);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(`Edit Note for BEN: ${ben}, Application: ${rowId}, Nickname: ${nickname}`);
        setNotesEditDialogLabel('QA Reviewer Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };

    const handleNotesEditDialogSave = (newNotes) => {
        //console.log('[handleNotesEditDialogSave] ');
        saveNewNotes(newNotes);
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const saveNewNotes = async (newNotes) => {
        // Determine the field title of the notes that was edited. (And sanity check notesFieldName.)
        let fieldTitle = '';
        if (notesFieldName === '_form471AppNotes') {
            fieldTitle = 'Application Notes';
        } else if (notesFieldName === 'qa_notes') {
            fieldTitle = 'QA Reviewer Notes';
        } else {
            console.error(`An Error Occurred. Invalid notesFieldName: ${notesFieldName}`);
            toast.error(`An Error Occurred. (Invalid notesFieldName.)`);
            return;
        }

        // Find the record to edit/update in `trackerData`.
        const specificRow = trackerData.find((row) => row.form471AppNum === notesEditRowId);
        //console.log('handleSaveNote --> specificRow: ', specificRow);

        // Create `uedata` and call the 'save notes' api endpoint.
        let uedata;
        if (notesFieldName === '_form471AppNotes') {
            uedata = {
                form471AppNum: specificRow.form471AppNum,
                form471_app_notes: newNotes,
            };
        } else if (notesFieldName === 'qa_notes') {
            uedata = {
                form471AppNum: specificRow.form471AppNum,
                qa_notes: newNotes,
            };
        }
        //console.log('handleSaveNote --> uedata: ', uedata);

        const apiResponse = await trackersAPI.SaveForm471TrackerNotes(uedata);
        console.log('handleSaveNote --> apiResponse: ', apiResponse);

        if (apiResponse) {
            // Update the data locally.
            if (notesFieldName === '_form471AppNotes') {
                const newForm471AppNotes = apiResponse['form471app']['form471_app_notes'];
                const modifiedOn = apiResponse['form471app']['user_entered_field_updated_timestamp'];
                updateTrackerData_WithNewForm471AppNotes(notesEditRowId, newForm471AppNotes, modifiedOn);
            } else if (notesFieldName === 'qa_notes') {
                const newQANotes = apiResponse['qa_form471app']['qa_notes'];
                const modifiedOn = apiResponse['qa_form471app']['record_updated_timestamp'];
                updateTrackerData_WithNewQANotes(notesEditRowId, newQANotes, modifiedOn);
            }

            toast.success(`Successfully updated the ${fieldTitle}`, {
                autoClose: 3000,
            });
        } else {
            toast.error(`Failed to update the ${fieldTitle}`);
        }
    };

    const updateTrackerData_WithNewForm471AppNotes = (rowId, newNotes, modifiedOn) => {
        let updatedTrackerData = trackerData.map((row) => {
            if (row.form471AppNum === rowId) {
                return {
                    ...row,
                    form471app: {
                        ...row.form471app,
                        form471_app_notes: newNotes,
                        user_entered_field_updated_timestamp: modifiedOn,
                    },
                };
            }
            return row;
        });
        setTrackerData(updatedTrackerData);
    };

    const updateTrackerData_WithNewQANotes = (rowId, newNotes, modifiedOn) => {
        let updatedTrackerData = trackerData.map((row) => {
            if (row.form471AppNum === rowId) {
                return {
                    ...row,
                    form471app: {
                        ...row.form471app,
                        user_entered_field_updated_timestamp: modifiedOn,
                    },
                    qa_form471app: {
                        ...row.qa_form471app,
                        qa_notes: newNotes,
                    },
                };
            }
            return row;
        });
        setTrackerData(updatedTrackerData);
    };

    const handleNotesEditDialogCancel = () => {
        //console.log('[handleNotesEditDialogCancel] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const handleNotesEditDialogClose = () => {
        //console.log('[handleNotesEditDialogClose] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const cleanupNotesEditDialogStateVars = () => {
        setNotesEditRowId(null);
        setNotesFieldName('');
        setNotesEditDialogLabel('');
        setNotesEditDialogTitle('');
        setEditableNotes('');
    };

    // ################################### End Custom Notes #############################################

    //----- processRowUpdate and handleProcessRowUpdateError -----
    const processRowUpdate = React.useCallback(
        async (newRow, oldRow) => {
            console.log('[processRowUpdate] newRow = ');
            console.log(newRow);
            //console.log('[processRowUpdate] oldRow = ');  console.log(oldRow);

            //return Promise.reject(new Error('newRow.ApplicationOwnerUserID = ' + newRow.ApplicationOwnerUserID));  // FOR TESTING. Preventing the save and testing inline edit output.

            if (newRow.FilingWindowID == null) {
                return Promise.reject(new Error('Invalid FilingWindowID: ' + newRow.FilingWindowID));
            }
            if (newRow.ApplicationOwnerUserID == null) {
                return Promise.reject(new Error('Invalid ApplicationOwnerUserID: ' + newRow.ApplicationOwnerUserID));
            }
            if (newRow.TaskOwnerUserID == null) {
                return Promise.reject(new Error('Invalid TaskOwnerUserID: ' + newRow.TaskOwnerUserID));
            }
            if (newRow.ERCAppStatusID == null) {
                return Promise.reject(new Error('Invalid ERCAppStatusID: ' + newRow.ERCAppStatusID));
            }
            if (newRow.QASubmitterUserID == null) {
                return Promise.reject(new Error('Invalid QASubmitterUserID: ' + newRow.QASubmitterUserID));
            }
            if (newRow.QAReviewStatusID == null) {
                return Promise.reject(new Error('Invalid QAReviewStatusID: ' + newRow.QAReviewStatusID));
            }
            if (newRow.QAReviewerUserID == null) {
                return Promise.reject(new Error('Invalid QAReviewerUserID: ' + newRow.QAReviewerUserID));
            }

            // console.log('[processRowUpdate] newRow.QADateSubmitted = ' + newRow.QADateSubmitted);
            // console.log('[processRowUpdate] newRow.QADateNeeded = ' + newRow.QADateNeeded);
            // console.log('[processRowUpdate] typeof newRow.QADateSubmitted = ' + typeof newRow.QADateSubmitted);
            // console.log('[processRowUpdate] typeof newRow.QADateNeeded = ' + typeof newRow.QADateNeeded);

            // Check if the old date submitted existed and the new date submitted is cleared
            const oldDateSubmitted = oldRow.qa_form471app?.qa_date_submitted ?? '';
            const newDateSubmitted = newRow.QADateSubmitted;
            if (oldDateSubmitted && newDateSubmitted === '') {
                newRow.QADateSubmitted = '<cleared>';
            }

            // Check if the old date needed existed and the new date needed is cleared
            const oldDateNeeded = oldRow.qa_form471app?.qa_date_needed ?? '';
            const newDateNeeded = newRow.QADateNeeded;
            if (oldDateNeeded && newDateNeeded === '') {
                newRow.QADateNeeded = '<cleared>';
            }

            // console.log('[processRowUpdate] newRow.QASubmitterUserID = ' + newRow.QASubmitterUserID);
            // console.log('[processRowUpdate] newRow.QADateSubmitted = ' + newRow.QADateSubmitted);
            // console.log('[processRowUpdate] newRow.QADateNeeded = ' + newRow.QADateNeeded);
            // console.log('[processRowUpdate] newRow.QAReviewStatusID = ' + newRow.QAReviewStatusID);

            let uedata = {
                form471_app_num: newRow.form471AppNum,
                filing_window_id: newRow.FilingWindowID,
                application_owner_id: newRow.ApplicationOwnerUserID,
                task_owner_id: newRow.TaskOwnerUserID,
                erc_app_status_id: newRow.ERCAppStatusID,
                form471_app_notes: newRow._form471AppNotes,
                qa_status_id: newRow.QAReviewStatusID,
                qa_reviewer_id: newRow.QAReviewerUserID,
                qa_notes: newRow._qaNotes,
                qa_submitter_user_id: newRow.QASubmitterUserID,
                qa_date_submitted: newRow.QADateSubmitted,
                qa_date_needed: newRow.QADateNeeded,
                qa_estimated_funding_request: newRow.QAEstimatedFundingRequest,
                date_sent_to_client_to_certify: newRow.DateSentToClientToCertify,
            };

            //console.log('[processRowUpdate] uedata = ');
            //console.log(uedata);

            //return Promise.reject(new Error('WORK-IN-PROGRESS.'));

            const apiResponse = await trackersAPI.SaveForm471TrackerMainReportUED(uedata);

            //console.log('[processRowUpdate] apiResponse = ');
            //console.log(apiResponse);

            if (apiResponse === false) {
                console.error('Unknown error while saving.');
                return Promise.reject(new Error('Unknown error while saving.'));
            }

            // Return the new row data. It needs to contain form471AppNum for the row id as per getRowId.
            setPruUpdatedRow(apiResponse);
            return apiResponse;
        },
        [trackersAPI]
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        //console.log('[handleProcessRowUpdateError] error.message =', error.message);
        toast.error('Error saving data: ' + error.message);
    }, []);

    //----- getRowId and getRowClassName functions. DataGrid columns array. -----
    const getRowId = (row) => row.form471AppNum;

    const getRowClassName = (params) => 'Form471-' + params.row.usacAppStatus4Text;

    const originalColumnWidthSetter = prefs.setOriginalSavedViewColumnWidthPrefs;
    const originalColumnOrderSetter = prefs.setOriginalSavedViewColumnOrderPrefs;

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSaveIconClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelIconClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    // prefs.viewCurrentColumnOrderPrefs
    // [haveEditDropdownOptions, prefs.rowHeightPref, currentColumnWidths, currentColumnOrder] // cco causes you to not be able to move multiple cols

    // Create the (unsorted) columns array.
    const columns = React.useMemo(() => {
        return Form471ApplicationsColumnsDefinitionCreator2(
            apiRef,
            applicantCohortMSFilterOptions,
            applicantMSFilterOptions,
            applicationOwnerEditOptions,
            currentColumnWidths,
            ercAppStatusEditOptions,
            filingWindowEditOptions,
            fundingProgramMSFilterOptions,
            handleCancelIconClick,
            handleDetailsButtonClick,
            handleEditApplicationNotes,
            handleEditButtonClick,
            handleEditQANotes,
            handleSaveIconClick,
            originalColumnOrderSetter,
            originalColumnWidthSetter,
            prefs.rowHeightPref,
            rowModesModel,
            qaEstimatedFundingRequestEditOptions,
            qaReviewStatusEditOptions,
            qaReviewerEditOptions,
            qaSubmitterEditOptions,
            stateMSFilterOptions,
            piaReviewStatusMSFilterOptions,
            taskOwnerEditOptions
        );
    }, [
        applicantCohortMSFilterOptions,
        applicantMSFilterOptions,
        applicationOwnerEditOptions,
        fundingProgramMSFilterOptions,
        stateMSFilterOptions,
        piaReviewStatusMSFilterOptions,
        haveEditDropdownOptions,
        qaEstimatedFundingRequestEditOptions,
        rowModesModel,
        prefs.rowHeightPref,
        prefs.viewCurrentColumnOrderPrefs,
        prefs.viewCurrentColumnWidthPrefs,
    ]);

    //----- Custom column visibility buttons for TrackerDataGridButtons -----
    // 'Hide/Show QA Columns' button
    const qaColumnNamesArray = [
        'QASubmitterUserID',
        'QADateSubmitted',
        'QADateNeeded',
        'QAEstimatedFundingRequest',
        'QAReviewStatusID',
        'QAReviewerUserID',
        '_qaNotes',
    ];
    const initialHideShowStateOfQAColumnsButton = React.useMemo(
        () => determineInitialHideShowState(qaColumnNamesArray, prefs.hiddenColumnPrefs),
        [prefs.hiddenColumnPrefs]
    );
    const customColumnVisibilityButtonsConfig = [
        {
            showText: 'Show QA Columns',
            hideText: 'Hide QA Columns',
            initialAction: initialHideShowStateOfQAColumnsButton, // 'hide' or 'show'
            columns: qaColumnNamesArray,
        },
    ];

    //----- Display -----
    if (trackerData === undefined || trackerData === false) {
        return (
            <>
                <Typography variant='h3' sx={{ marginTop: '25px' }}>
                    An error occured while trying to complete this search
                </Typography>
                <Typography variant='body2' sx={{ marginLeft: '16px' }}>
                    please contact your system administrators and share your search criteria with them.
                </Typography>
            </>
        );
    }

    if (haveEditDropdownOptions === false) {
        //console.log('haveEditDropdownOptions = ' + haveEditDropdownOptions);
        return null;
    }

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <TDGPrefsContext.Provider value={prefs}>
                <TrackerDataGrid
                    apiRef={apiRef}
                    appliesTo={appliesTo}
                    columnsArray={columns}
                    currentColumnOrder={currentColumnOrder}
                    currentColumnWidths={currentColumnWidths}
                    currentSavedViews={currentSavedViews}
                    currentUserID={currentUserID}
                    customColumnVisibilityButtonsConfig={customColumnVisibilityButtonsConfig}
                    dataIsLoading={dataIsLoading}
                    defaultHiddenColumns={defaultHiddenColumns}
                    editingSavedViewName={editingSavedViewName}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={getDetailPanelHeight}
                    getRowClassName={getRowClassName}
                    getRowId={getRowId}
                    handleEditSavedViewName={handleEditSavedViewName}
                    handleDeleteSavedView={handleDeleteSavedView}
                    handleProcessRowUpdateError={handleProcessRowUpdateError}
                    handleRowModesModelChange={handleRowModesModelChange}
                    handleSaveView={handleSaveView}
                    processRowUpdate={processRowUpdate}
                    reportData={trackerData}
                    resetWatcher={resetWatcher}
                    rowModesModel={rowModesModel}
                    selectionModel={selectionModel}
                    setBulkEditButtonClicked={setBulkEditButtonClicked}
                    setCurrentColumnOrder={setCurrentColumnOrder}
                    setCurrentColumnWidths={setCurrentColumnWidths}
                    setEditingSavedViewName={setEditingSavedViewName}
                    setResetWatcher={setResetWatcher}
                    setSelectionModel={setSelectionModel}
                    setTextFieldValue={setTextFieldValue}
                    usedCacheOnLoad={usedCacheOnLoad}
                    // customSx={{
                    //     '& .MuiDataGrid-row.Form471-Certified:nth-of-type(2n+1), .MuiDataGrid-row.Form471-Committed:nth-of-type(2n+1)':
                    //         {
                    //             backgroundColor: 'hsl(103, 47%, 90%)',
                    //         },
                    //     '& .MuiDataGrid-row.Form471-Certified:nth-of-type(2n), .MuiDataGrid-row.Form471-Committed:nth-of-type(2n)':
                    //         {
                    //             backgroundColor: 'hsl(103, 43%, 86%)',
                    //         },
                    // }}
                />
            </TDGPrefsContext.Provider>

            <Form471AppsBulkEdit
                applicationOwnerEditOptions={applicationOwnerEditOptions}
                bulkEditButtonClicked={bulkEditButtonClicked}
                ercAppStatusEditOptions={ercAppStatusEditOptions}
                pruUpdatedRow={pruUpdatedRow}
                qaEstimatedFundingRequestEditOptions={qaEstimatedFundingRequestEditOptions}
                qaReviewerEditOptions={qaReviewerEditOptions}
                qaReviewStatusEditOptions={qaReviewStatusEditOptions}
                qaSubmitterEditOptions={qaSubmitterEditOptions}
                selectionModel={selectionModel}
                setBulkEditButtonClicked={setBulkEditButtonClicked}
                setTrackerData={setTrackerData}
                taskOwnerEditOptions={taskOwnerEditOptions}
                trackerData={trackerData}
                trackersAPI={trackersAPI}
                username={userData}
            />

            {/* 
            import { OLDNotesEditDialog } from './dialogs/NotesEditDialog.js';
            <OLDNotesEditDialog
                openNotesEditDialog={openNotesEditDialog}
                setOpenNotesEditDialog={setOpenNotesEditDialog}
                handleAddNoteButtonClick={handleAddNoteButtonClick}
                notesEditDialogTitle={notesEditDialogTitle}
                notesEditDialogLabel={notesEditDialogLabel}
                handleCloseDialog={handleCloseDialog}
                handleSaveNote={handleSaveNote}
                editableNotes={editableNotes}
                setEditableNotes={setEditableNotes}
            />
            */}

            <NotesEditDialog
                open={openNotesEditDialog}
                title={notesEditDialogTitle}
                label={notesEditDialogLabel}
                initialNotes={editableNotes}
                username={usernameForNotesEditDialog}
                onSave={handleNotesEditDialogSave}
                onCancel={handleNotesEditDialogCancel}
                onClose={handleNotesEditDialogClose}
            />

            <PromptUpdateOrSaveNewViewDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateView={handleOnlyUpdateView}
                handleOnlySaveAsNewView1={handleOnlySaveAsNewView1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewView2={handleOnlySaveAsNewView2}
            />
            {promptSaveNewView()}
        </>
    );
}

function Form471TrackerAppsSearchBox({
    appliesTo,
    benFromURL,
    clearQueryStrings,
    currentUserID,
    defaultBen,
    fundingYearFromURL,
    handleFieldsInitialized,
    handleSearchButtonClick,
    trackersAPI,
    userAPI,
}) {
    //################################################################################
    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const F471T_APPS_SEARCH_LS_NAME = 'cedF471TAppsSearchFieldValuesLS' + LS_INCREMENT;
    const F471T_APPS_SEARCH_SS_NAME = 'cedF471TAppsSearchFieldValuesSS' + SS_INCREMENT;
    const [searchFieldValuesInLS, setSearchFieldValuesInLS] = useLocalStorage(F471T_APPS_SEARCH_LS_NAME, '');
    //################################################################################

    //################################### SAVED SEARCHES #############################################
    const [currentSavedSearches, setCurrentSavedSearches] = React.useState([]);
    const [selectedSearchName, setSelectedSearchName] = React.useState('');
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [editingSearchName, setEditingSearchName] = React.useState('');

    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [savedSearchObject, setSavedSearchObject] = React.useState({});
    //################################### END SAVED SEARCHES #############################################

    const [defaultUserID, setDefaultUserID] = React.useState(null);
    const auth = useAuthContext();

    const accordionCollapsedText = 'Click to Search Form 471 Applications';
    const accordionExpandedText = 'Search Form 471 Applications';
    const [accordionTitle, setAccordionTitle] = React.useState(accordionCollapsedText);

    //=====  Search Box Options  =====
    const [fundingYearMSOptions, setFundingYearMSOptions] = React.useState([]);
    const [filingWindowMSOptions, setFilingWindowMSOptions] = React.useState([]);
    const [primaryContactMSOptions, setPrimaryContactMSOptions] = React.useState([]);
    const [secondaryContactMSOptions, setSecondaryContactMSOptions] = React.useState([]);
    const [applicationOwnerMSOptions, setApplicationOwnerMSOptions] = React.useState([]);
    const [applicantMSOptions, setApplicantMSOptions] = React.useState([]);
    const [taskOwnerMSOptions, setTaskOwnerMSOptions] = React.useState([]);
    const [teamsMSOptions, setTeamsMSOptions] = React.useState([]);
    const [stateMSOptions, setStateMSOptions] = React.useState([]);
    const [applicantCohortMSOptions, setApplicantCohortMSOptions] = React.useState([]);
    const [servicesMSOptions, setServicesMSOptions] = React.useState([]);
    const [ercAppStatusMSOptions, setERCAppStatusMSOptions] = React.useState([]);
    const [piaReviewStatusMSOptions, setPIAReviewStatusMSOptions] = React.useState([]);
    const [qaStatusMSOptions, setQAStatusMSOptions] = React.useState([]);
    const [qaReviewerMSOptions, setQAReviewerMSOptions] = React.useState([]);

    //=====  Search Box State  =====
    const [fundingYears, setFundingYears] = React.useState([]);
    const [filingWindows, setFilingWindows] = React.useState([]);
    const [category, setCategory] = React.useState(0);
    const [ben, setBen] = React.useState(defaultBen ?? '');
    const [applicationNumber, setApplicationNumber] = React.useState('');
    const [nickname, setNickname] = React.useState('');
    const [primaryContacts, setPrimaryContacts] = React.useState([]);
    const [secondaryContacts, setSecondaryContacts] = React.useState([]);
    const [applicationOwners, setApplicationOwners] = React.useState([]);
    const [taskOwners, setTaskOwners] = React.useState([]);
    const [teams, setTeams] = React.useState([]);
    const [applicant, setApplicant] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [applicantCohorts, setApplicantCohorts] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [isAClient, setIsAClient] = React.useState(true);
    const [usacAppStatuses, setUSACAppStatuses] = React.useState([]);
    const [ercAppStatuses, setERCAppStatuses] = React.useState([]);
    const [piaReviewStatuses, setPIAReviewStatuses] = React.useState([]);
    const [qaStatuses, setQAStatuses] = React.useState([]);
    const [qaReviewers, setQAReviewers] = React.useState([]);
    const [areFieldsInitialized, setAreFieldsInitialized] = React.useState(false);

    //=====  Seach Box State (Deadline)  =====
    // const [deadlineFieldName, setDeadlineFieldName] = React.useState(null);
    // const [deadlineFieldOperator, setDeadlineFieldOperator] = React.useState(null);
    // const [deadlineFieldDays, setDeadlineFieldDays] = React.useState(null);
    // const [deadlineValue, setDeadlineValue] = React.useState(deadlineFieldDays ?? '');
    // const [calculatedDeadlineDate, setCalculatedDeadlineDate] = React.useState('');
    // const [deadlineFieldsCount, setDeadlineFieldsCount] = React.useState(0);
    // const [isDeadlineAccordionSelected, setIsDeadlineAccordionSelected] = React.useState(false);

    //=====  Dropdown and multi-select options  =====
    const categoryOptions = [
        { text: 'Any Category', value: 0 },
        { text: 'Category 1', value: 1 },
        { text: 'Category 2', value: 2 },
    ];
    const usacAppStatusOptions = [
        { text: 'Incomplete', value: 1 },
        { text: 'Certified', value: 2 },
        { text: 'Committed', value: 3 },
        { text: 'Cancelled', value: 4 },
    ];

    //=====  setDefaultSearchValues, createSearchParametersObject, and setSearchFieldValues  =====
    const setDefaultSearchValues = (defaultUserID) => {
        setFundingYears([new Date().getFullYear()]);
        setFilingWindows([]);
        setSecondaryContacts([]);
        setBen('');
        setSelectedSearchName('');
        setApplicationNumber('');
        setNickname('');
        setCategory(0);
        setApplicant([]);
        setApplicationOwners([]);
        setTaskOwners([]);
        setIsAClient(true);
        setApplicantCohorts([]);
        setUSACAppStatuses([]);
        setERCAppStatuses([]);
        setPIAReviewStatuses([]);
        setQAStatuses([]);
        setQAReviewers([]);
        setServices([]);
        setTeams([]);
        setStates([]);

        if (defaultUserID != null) {
            setPrimaryContacts([defaultUserID]);
        } else {
            setPrimaryContacts([]);
        }
    };

    // Creates an object of key/value pairs of search field data
    const createSearchParametersObject = () => {
        return {
            fundingYears: fundingYears,
            filingWindows: filingWindows,
            category: category,
            ben: ben,
            selectedSearchName: selectedSearchName,
            applicationNumber: applicationNumber,
            nickname: nickname,
            primaryContacts: primaryContacts,
            secondaryContacts: secondaryContacts,
            applicationOwners: applicationOwners,
            taskOwners: taskOwners,
            teams: teams,
            applicant: applicant,
            states: states,
            applicantCohorts: applicantCohorts,
            services: services,
            isAClient: isAClient,
            usacAppStatuses: usacAppStatuses,
            ercAppStatuses: ercAppStatuses,
            piaReviewStatuses: piaReviewStatuses,
            qaStatuses: qaStatuses,
            qaReviewers: qaReviewers,
            // deadlineFieldName: deadlineFieldName,
            // deadlineFieldOperator: deadlineFieldOperator,
            // deadlineFieldDays: deadlineFieldDays,
            // calculatedDeadlineDate: calculatedDeadlineDate
        };
    };

    const setSearchFieldValues = (parsedSP) => {
        // Start with the default values
        setDefaultSearchValues(defaultUserID);

        // Fill in search values from local/session storage data
        try {
            if (parsedSP.fundingYears != null && Array.isArray(parsedSP.fundingYears)) {
                setFundingYears(parsedSP.fundingYears);
            }
            if (parsedSP.filingWindows != null && Array.isArray(parsedSP.filingWindows)) {
                setFilingWindows(parsedSP.filingWindows);
            }
            if (parsedSP.primaryContacts != null && Array.isArray(parsedSP.primaryContacts)) {
                setPrimaryContacts(parsedSP.primaryContacts);
            }
            if (parsedSP.secondaryContacts != null && Array.isArray(parsedSP.secondaryContacts)) {
                setSecondaryContacts(parsedSP.secondaryContacts);
            }
            if (parsedSP.ben != null) {
                setBen(parsedSP.ben);
            }
            if (parsedSP.selectedSearchName != null) {
                setSelectedSearchName(parsedSP.selectedSearchName);
            }
            if (parsedSP.applicationNumber != null) {
                setApplicationNumber(parsedSP.applicationNumber);
            }
            if (parsedSP.nickname != null) {
                setNickname(parsedSP.nickname);
            }
            if (parsedSP.category != null && Number.isInteger(parsedSP.category)) {
                setCategory(parsedSP.category);
            }
            if (parsedSP.applicant != null && Array.isArray(parsedSP.applicant)) {
                setApplicant(parsedSP.applicant);
            }
            if (parsedSP.applicationOwners != null && Array.isArray(parsedSP.applicationOwners)) {
                setApplicationOwners(parsedSP.applicationOwners);
            }
            if (parsedSP.taskOwners != null && Array.isArray(parsedSP.taskOwners)) {
                setTaskOwners(parsedSP.taskOwners);
            }
            if (parsedSP.isAClient != null && (parsedSP.isAClient === true || parsedSP.isAClient === false)) {
                setIsAClient(parsedSP.isAClient);
            }
            if (parsedSP.applicantCohorts != null && Array.isArray(parsedSP.applicantCohorts)) {
                setApplicantCohorts(parsedSP.applicantCohorts);
            }
            if (parsedSP.usacAppStatuses != null && Array.isArray(parsedSP.usacAppStatuses)) {
                setUSACAppStatuses(parsedSP.usacAppStatuses);
            }
            if (parsedSP.ercAppStatuses != null && Array.isArray(parsedSP.ercAppStatuses)) {
                setERCAppStatuses(parsedSP.ercAppStatuses);
            }
            if (parsedSP.piaReviewStatuses != null && Array.isArray(parsedSP.piaReviewStatuses)) {
                setPIAReviewStatuses(parsedSP.piaReviewStatuses);
            }
            if (parsedSP.qaStatuses != null && Array.isArray(parsedSP.qaStatuses)) {
                setQAStatuses(parsedSP.qaStatuses);
            }
            if (parsedSP.qaReviewers != null && Array.isArray(parsedSP.qaReviewers)) {
                setQAReviewers(parsedSP.qaReviewers);
            }
            if (parsedSP.services != null && Array.isArray(parsedSP.services)) {
                setServices(parsedSP.services);
            }
            if (parsedSP.teams != null && Array.isArray(parsedSP.teams)) {
                setTeams(parsedSP.teams);
            }
            if (parsedSP.states != null && Array.isArray(parsedSP.states)) {
                setStates(parsedSP.states);
            }
        } catch (error) {
            console.error('error: ', error);
            toast.error(error);
            return false;
        }
    };

    //################################################################################
    //=====  Functions to retrieve, save, and clear search field values from/to/in storage  =====
    // SOMEDAY: "Storage" may someday also include a database table.

    const retrieveSearchFieldValuesJSONFromStorage = () => {
        // Check session storage first..
        let ss_json = sessionStorage.getItem(F471T_APPS_SEARCH_SS_NAME);
        if (ss_json) {
            return ss_json;
        }
        // Then local storage..
        if (searchFieldValuesInLS) {
            return searchFieldValuesInLS;
        }
        return null;
    };

    const saveSearchFieldValuesJSONToStorage = (json) => {
        setSearchFieldValuesInLS(json);
        sessionStorage.setItem(F471T_APPS_SEARCH_SS_NAME, json);
    };

    const clearSearchFieldValuesStorage = () => {
        setSearchFieldValuesInLS('');
        sessionStorage.setItem(F471T_APPS_SEARCH_SS_NAME, '');
    };
    //################################################################################

    // Gets all of the user's saved searches
    const getSavedSearches = async (ap) => {
        const searchResults = await userAPI.GetSavedSearches(ap);
        const parsedSearchResults = JSON.parse(searchResults);
        // console.log('Form471TrackerAppsTable: getSavedSearchesFN: parsedSearchResults = ', parsedSearchResults);

        // Defines the base layout for all retrieved searches
        const structuredSearches = parsedSearchResults.map((ea_row) => ({
            search_id: ea_row.id,
            search_name: ea_row.search_name,
            parameters: ea_row,
        }));
        structuredSearches.sort((a, b) => a.search_name.localeCompare(b.search_name));
        console.log('Form471TrackerAppsTable: getSavedSearchesFN: structuredSearches = ', structuredSearches);
        setCurrentSavedSearches(structuredSearches);
    };

    const setQueryStringStateChanges = () => {
        setBen(benFromURL ? benFromURL : '');
        setFundingYears([fundingYearFromURL].length > 0 ? [fundingYearFromURL] : [new Date().getFullYear() + 1]);
    };

    //=====  useEffects  =====
    React.useEffect(() => {
        // Populate dropdown options state variables with data
        // then set search fields with cached LS/SS values or default values.
        const getSearchOptionsAndInitialize = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                let options = await trackersAPI.GetForm471TrackerMainReportSearchOptions();
                setFilingWindowMSOptions(options.filingWindows);
                setFundingYearMSOptions(options.fundingYears);
                setPrimaryContactMSOptions(options.employees);
                setSecondaryContactMSOptions(options.employees);
                setApplicantMSOptions(options.applicants); // (client_name, client_id).
                setApplicationOwnerMSOptions(options.employees);
                setTaskOwnerMSOptions(options.employees);
                setApplicantCohortMSOptions(options.applicantCohorts);
                setERCAppStatusMSOptions(options.ercAppStatuses);
                setPIAReviewStatusMSOptions(options.reviewStatuses);
                setQAStatusMSOptions(options.qaReviewStatuses);
                setQAReviewerMSOptions(options.employees);
                setServicesMSOptions(options.form471Services);
                setTeamsMSOptions(options.teams);
                setStateMSOptions(options.states);

                // Get the user's userId
                let userID = currentUserID;

                // Check if that user (id) in the "Primary Contacts" options array..
                let pcoption = options.employees.find((pc) => pc.value === userID);
                if (!pcoption) {
                    userID = null;
                }

                setDefaultUserID(userID);

                // Initialize search box to previous values or defaults
                let sfv_json = retrieveSearchFieldValuesJSONFromStorage();
                if (sfv_json && (!benFromURL || !fundingYearFromURL)) {
                    setSearchFieldValues(JSON.parse(sfv_json));
                } else if (!sfv_json && (!benFromURL || !fundingYearFromURL)) {
                    setDefaultSearchValues(userID);
                } else {
                    setQueryStringStateChanges();
                }

                setAreFieldsInitialized(true);
            } catch (error) {
                console.error('error: ', error);
                toast.error(error);
            }
        };

        getSearchOptionsAndInitialize();
        getSavedSearches(appliesTo);
    }, []);

    // Execute handleFieldsInitialized after the search fields have been initialized.
    React.useEffect(() => {
        if (areFieldsInitialized === true) {
            handleFieldsInitialized(createSearchParametersObject());
        }
    }, [areFieldsInitialized]);

    //=====  Dropdown and multi-select change handlers  =====
    const handleFundingYearChange = (event, selectedValues) => {
        setFundingYears(selectedValues);
    };

    const handleFilingWindowChange = (event, selectedValues) => {
        setFilingWindows(selectedValues);
    };

    const handlePrimaryContactChange = (event, selectedValues) => {
        setPrimaryContacts(selectedValues);
    };

    const handleSecondaryContactChange = (event, selectedValues) => {
        setSecondaryContacts(selectedValues);
    };

    const handleApplicationOwnerChange = (event, selectedValues) => {
        setApplicationOwners(selectedValues);
    };

    const handleTaskOwnerChange = (event, selectedValues) => {
        setTaskOwners(selectedValues);
    };

    const handleApplicantCohortChange = (event, selectedValues) => {
        setApplicantCohorts(selectedValues);
    };

    const handleUSACAppStatusChange = (event, selectedValues) => {
        setUSACAppStatuses(selectedValues);
    };

    const handleERCAppStatusChange = (event, selectedValues) => {
        setERCAppStatuses(selectedValues);
    };

    const handlePIAReviewStatusChange = (event, selectedValues) => {
        setPIAReviewStatuses(selectedValues);
    };

    const handleQAStatusChange = (event, selectedValues) => {
        setQAStatuses(selectedValues);
    };

    // For Deadlines
    // const handleDeadlineFieldStatusChange = (event) => {
    //     setDeadlineFieldName(event);
    // };

    // const handleDeadlineOperatorStatusChange = (event) => {
    //     setDeadlineFieldOperator(event);
    // };

    // const handleDeadlineDaysChange = (event) => {
    //     setDeadlineFieldDays(event);
    // };

    const handleQAReviewerChange = (event, selectedValues) => {
        setQAReviewers(selectedValues);
    };

    const handleServicesChange = (event, selectedValues) => {
        setServices(selectedValues);
    };

    const handleTeamsChange = (event, selectedValues) => {
        setTeams(selectedValues);
    };

    const handleApplicantChange = (event, selectedValues) => {
        setApplicant(selectedValues);
    };

    const handleStateChange = (event, selectedValues) => {
        setStates(selectedValues);
    };

    // For Deadlines
    // React.useEffect(() => {
    //     const totals = (deadlineFieldName ? 1 : 0) + (deadlineFieldOperator ? 1 : 0) + (deadlineValue ? 1 : 0);

    //     const anySelected = totals > 0;

    //     if (anySelected !== isDeadlineAccordionSelected) {
    //         setIsDeadlineAccordionSelected(anySelected);
    //     }

    //     if (anySelected) {
    //         setDeadlineFieldsCount(totals);
    //     } else if (deadlineFieldsCount !== 0) {
    //         setDeadlineFieldsCount(0);
    //     }
    // }, [deadlineFieldName, deadlineFieldOperator, deadlineValue, deadlineFieldsCount, isDeadlineAccordionSelected]);

    //################################### SAVED SEARCHES #############################################
    const endsWithSearch = (searchName) => {
        const lowercaseName = searchName.toLowerCase();
        return lowercaseName.endsWith('search') || lowercaseName.split(-6).pop().endsWith('search');
    };

    // Check if the search name already exists
    const searchNameExists = (searchName) => {
        return currentSavedSearches.some((ea_search) => ea_search.search_name === searchName);
    };

    // Components rendered when the user begins to edit a MenuItem
    const EditableMenuItem = ({ ea_search, onSave, onCancel }) => {
        const [editValue, setEditValue] = React.useState(ea_search.search_name);

        const handleKeyDown = (event) => {
            // Stops the select component from navigating while typing
            event.stopPropagation();
            if (event.key === 'Enter') {
                onSave(ea_search.search_name, editValue);
            }
        };

        return (
            <MenuItem>
                <TextField
                    value={editValue}
                    onChange={(event) => setEditValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => onSave(ea_search.search_name, editValue)}>
                    <DoneIcon fontSize='inherit' />
                </IconButton>
                <IconButton onClick={onCancel}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </MenuItem>
        );
    };

    const handleSaveSearch = async (sfv_obj) => {
        setSavedSearchObject(sfv_obj);

        if (selectedSearchName !== '' && selectedSearchName !== null) {
            // Search for duplicate/determine if the user has already selected a search
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === selectedSearchName
            );

            // If a duplicate was found/the user has already selected a view
            if (duplicateSearchIndex !== -1) {
                openDialog1(selectedSearchName); // opens the promptUpdateOrSaveNewView
            }
        } else {
            openDialog2();
            promptSaveNewSearch();
        }
    };

    const handleOnlyUpdateSearch = async () => {
        let message = 'update';
        let newOrUpdatedSearchName = selectedSearchName;
        let tempCurrentSavedSearches = [...currentSavedSearches];

        const duplicateSearchIndex = currentSavedSearches.findIndex(
            (ea_search) => ea_search.search_name === newOrUpdatedSearchName
        );

        // Finds the matching search object
        const matchingSavedSearch = currentSavedSearches[duplicateSearchIndex];

        // Building the updated search object (state updates too slowly to add searchName to CSPO)
        const replacementSearch = {
            search_id: matchingSavedSearch.parameters.id,
            search_name: newOrUpdatedSearchName,
            parameters: savedSearchObject,
        };

        // Update state
        tempCurrentSavedSearches[duplicateSearchIndex] = replacementSearch;
        const response = await userAPI.SaveSearch(
            currentUserID,
            message,
            appliesTo,
            tempCurrentSavedSearches.at(duplicateSearchIndex)
        );

        if (response) {
            setLanding(false);
            setShowTextField1(false);
            setIsDialogOpen1(false);

            let successMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Successfully updated the ${newOrUpdatedSearchName}`
                : `Successfully updated the ${newOrUpdatedSearchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Failed to update the ${newOrUpdatedSearchName}`
                : `Failed to update the ${newOrUpdatedSearchName} saved search`;

            toast.error(failedMessage);
        }
        getSavedSearches(appliesTo);
        setLanding(true);
    };

    const handleOnlySaveAsNewSearch1 = () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewSearch2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved search name cannot be empty!');
            return;
        }

        const message = 'new';
        const searchName = textFieldValue.trim();
        const tempCurrentSavedSearches = [...currentSavedSearches];

        if (searchNameExists(searchName)) {
            toast.error(
                `A saved search with the name ${searchName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const newSearch = {
            search_name: searchName,
            parameters: savedSearchObject,
        };
        newSearch['parameters']['selectedSearchName'] = searchName;
        tempCurrentSavedSearches.push(newSearch);

        const response = await userAPI.SaveSearch(currentUserID, message, appliesTo, tempCurrentSavedSearches.at(-1));
        if (response) {
            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            let successMessage = endsWithSearch(searchName)
                ? `Successfully created the ${searchName}`
                : `Successfully created the ${searchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(searchName)
                ? `Failed to create the ${searchName}`
                : `Failed to create the ${searchName} saved search`;

            toast.error(failedMessage);
        }
        setLanding(true);
        getSavedSearches(appliesTo);
        setSelectedSearchName(searchName);
    };

    // Called when the user selects a search from the dropdown
    const handleSelectSavedSearch = (search_name) => {
        setSelectedSearchName(search_name);

        // Finds the matching search object
        const retrieved_search = currentSavedSearches.find((ea_search) => ea_search.search_name === search_name);
        if (retrieved_search) {
            setSearchFieldValues(retrieved_search.parameters.search_filters); // parses the values into searchbar
        } else {
            return;
        }
    };

    // Called when the user clicks the edit icon
    const handleEditSavedSearchName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/search_name already exists for the other saved searches
            if (currentSavedSearches.some((ea_search) => ea_search.search_name === new_name)) {
                alert('A saved search with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the search we want to edit
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === old_name
            );

            // If a duplicate was found
            if (duplicateSearchIndex !== -1) {
                // Returns an array of objects with the previous saved search + the edited search
                const updatedSearches = currentSavedSearches.map((ea_search, index) => {
                    if (index === duplicateSearchIndex) {
                        return {
                            ...ea_search,
                            search_name: new_name,
                            parameters: {
                                ...ea_search.parameters,
                                search_name: new_name,
                                search_filters: {
                                    ...ea_search.parameters.search_filters,
                                    selectedSearchName: new_name,
                                },
                            },
                        };
                    }
                    return ea_search;
                });
                // console.log(
                //     'Form471TrackerAppsTable: editSavedSearchNameFN: modified/edited object = ',
                //     updatedSearches.at(duplicateSearchIndex)
                // );

                // Update the state
                if (selectedSearchName === old_name) {
                    setSelectedSearchName(new_name);
                }
                setCurrentSavedSearches(updatedSearches);

                // Process the edited search
                const search_response = await userAPI.SaveSearch(
                    currentUserID,
                    message,
                    appliesTo,
                    updatedSearches.at(duplicateSearchIndex)
                );

                if (search_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }
                getSavedSearches(appliesTo);
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedSearch = async (search_name, search_id, applies_to, user_id) => {
        let confirmationMessage = endsWithSearch(search_name)
            ? `Are you sure you want to delete the ${search_name}?`
            : `Are you sure you want to delete the ${search_name} saved search?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedSearch(search_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithSearch(search_name)
                    ? `Successfully deleted the ${search_name}`
                    : `Successfully deleted the ${search_name} saved search`;

                toast.success(successMessage, {
                    autoClose: 3000,
                });

                if (search_name === selectedSearchName) {
                    setSelectedSearchName('');
                    resetToDefaults();
                }
                getSavedSearches(appliesTo);
            } else {
                const errorMessage = endsWithSearch(search_name)
                    ? `Failed to delete the ${search_name}`
                    : `Failed to delete the ${search_name} saved search`;

                toast.error(errorMessage);
            }
            // console.log('Form471TrackerAppsTable: deleteSavedSearchFN: delete_response = ', delete_response);
        }
    };

    const searchButtonClicked = () => {
        clearQueryStrings();
        if (isNaN(ben) || isNaN(applicationNumber)) {
            if (isNaN(ben)) {
                toast.error('BEN must be a number');
            }

            if (isNaN(applicationNumber)) {
                toast.error('Application Number must be a number');
            }
        } else {
            let sfv_obj = createSearchParametersObject();
            saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
            handleSearchButtonClick(sfv_obj);
        }
    };

    const saveSearchButtonClicked = () => {
        let sfv_obj = createSearchParametersObject();
        handleSaveSearch(sfv_obj);
        saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewSearch2();
        }
    };

    const openDialog1 = (searchName) => {
        let confirmationMessage = endsWithSearch(searchName)
            ? `Do you want to update the ${searchName} or save as a new search?`
            : `Do you want to update the ${searchName} saved search or save as a new search?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewSearch = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved search: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='Search Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewSearch2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED SEARCHES #############################################

    // const deadlineFieldsMapping = [
    //     { text: 'Date Sent to Client to Certify', value: 'DateSentToClientToCertify' },
    //     { text: 'Date Certified', value: 'certifiedDate' },
    //     { text: 'QA Date Needed', value: 'QADateNeeded' },
    //     { text: 'QA Date Submitted', value: 'QADateSubmitted' },
    // ];
    // deadlineFieldsMapping.sort((a, b) => a.text.localeCompare(b.text));

    // const deadlineFieldsOperatorMapping = [
    //     { text: 'is x days past deadline', value: 'isBeforeXDays' },
    //     { text: 'is x days out from deadline', value: 'isAfterXDays' },
    // ];

    // const deadlineDaysOptions = [
    //     { text: '30', value: 30 },
    //     { text: '60', value: 60 },
    //     { text: '90', value: 90 },
    // ];

    // const handleInputChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (inputValue === '') {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     } else {
    //         setDeadlineValue(inputValue);
    //         handleDeadlineDaysChange(inputValue);
    //     }
    // };

    // const handleSelect = (event, newValue) => {
    //     if (newValue !== null) {
    //         setDeadlineValue(newValue.value);
    //         handleDeadlineDaysChange(newValue.value);
    //     } else {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     }
    // };

    //=====  Accordion and button handlers  =====
    const handleAccordionOnChange = (event, expanded) => {
        setAccordionTitle(expanded ? accordionExpandedText : accordionCollapsedText);
    };

    const resetToDefaults = () => {
        clearSearchFieldValuesStorage();
        setTextFieldValue('');
        setDefaultSearchValues(defaultUserID);
    };

    return (
        <>
            <Accordion sx={{ marginBottom: '1em' }} onChange={handleAccordionOnChange}>
                <AccordionSummary
                    id='search-panel-header'
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='search-panel-content'
                    sx={{ m: 0 }}
                >
                    {accordionTitle}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-funding-year'
                                    selectedOptions={fundingYears}
                                    options={fundingYearMSOptions}
                                    handleSelectChange={handleFundingYearChange}
                                    label='Funding Year'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-filing-window'
                                    selectedOptions={filingWindows}
                                    options={filingWindowMSOptions}
                                    handleSelectChange={handleFilingWindowChange}
                                    label='Filing Window'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <InputLabel id='search-category-label'>Category</InputLabel>
                                <Select
                                    labelId='search-category-label'
                                    id='search-category'
                                    value={category}
                                    label='Category'
                                    onChange={(event) => setCategory(event.target.value)}
                                    displayEmpty
                                >
                                    {categoryOptions.map((row, i) => {
                                        return (
                                            <MenuItem value={row.value} key={row.value}>
                                                {row.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <TextField
                                onChange={(event) => setBen(event.target.value)}
                                value={ben}
                                label='BEN'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />

                            <TextField
                                onChange={(event) => setApplicationNumber(event.target.value)}
                                value={applicationNumber}
                                label='Application Number'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />

                            <TextField
                                onChange={(event) => setNickname(event.target.value)}
                                value={nickname}
                                label='Nickname'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-primary-contact'
                                    selectedOptions={primaryContacts}
                                    options={primaryContactMSOptions}
                                    handleSelectChange={handlePrimaryContactChange}
                                    label='Primary Contact'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-secondary-contact'
                                    selectedOptions={secondaryContacts}
                                    options={secondaryContactMSOptions}
                                    handleSelectChange={handleSecondaryContactChange}
                                    label='Secondary Contact'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-application-owner'
                                    selectedOptions={applicationOwners}
                                    options={applicationOwnerMSOptions}
                                    handleSelectChange={handleApplicationOwnerChange}
                                    label='Application Owner'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-task-owner'
                                    selectedOptions={taskOwners}
                                    options={taskOwnerMSOptions}
                                    handleSelectChange={handleTaskOwnerChange}
                                    label='Task Owner'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-teams'
                                    selectedOptions={teams}
                                    options={teamsMSOptions}
                                    handleSelectChange={handleTeamsChange}
                                    label='Teams'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicant'
                                    selectedOptions={applicant}
                                    options={applicantMSOptions}
                                    handleSelectChange={handleApplicantChange}
                                    label='Applicants'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-states'
                                    selectedOptions={states}
                                    options={stateMSOptions}
                                    handleSelectChange={handleStateChange}
                                    label='Client State'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicant-cohort'
                                    selectedOptions={applicantCohorts}
                                    options={applicantCohortMSOptions}
                                    handleSelectChange={handleApplicantCohortChange}
                                    label='Applicant Cohorts'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-services-owner'
                                    selectedOptions={services}
                                    options={servicesMSOptions}
                                    handleSelectChange={handleServicesChange}
                                    label='Services'
                                />
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAClient}
                                            onChange={(event) => setIsAClient(event.target.checked)}
                                        />
                                    }
                                    label='Is A Client'
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-usac-app-status'
                                    selectedOptions={usacAppStatuses}
                                    options={usacAppStatusOptions}
                                    handleSelectChange={handleUSACAppStatusChange}
                                    label='USAC App Status'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-erc-app-status'
                                    selectedOptions={ercAppStatuses}
                                    options={ercAppStatusMSOptions}
                                    handleSelectChange={handleERCAppStatusChange}
                                    label='ERC App Status'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-pia-review-status'
                                    selectedOptions={piaReviewStatuses}
                                    options={piaReviewStatusMSOptions}
                                    handleSelectChange={handlePIAReviewStatusChange}
                                    label='PIA Review Status'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-qa-status'
                                    selectedOptions={qaStatuses}
                                    options={qaStatusMSOptions}
                                    handleSelectChange={handleQAStatusChange}
                                    label='QA Status'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-qa-reviewer'
                                    selectedOptions={qaReviewers}
                                    options={qaReviewerMSOptions}
                                    handleSelectChange={handleQAReviewerChange}
                                    label='QA Reviewer'
                                />
                            </FormControl>

                            {/* Deadline Dropdowns */}
                            {/* <Accordion
                                style={{
                                    border: isDeadlineAccordionSelected ? '2px solid #4CAF50' : '',
                                }}
                            > */}
                            {/* <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='qa-fields'
                                    id='qa-fields'
                                    sx={{ mt: 1, mb: 0.7 }}
                                >
                                    <Typography color='textSecondary'>
                                        {isDeadlineAccordionSelected
                                            ? `(${deadlineFieldsCount}) Deadline Field${
                                                  deadlineFieldsCount > 1 ? 's' : ''
                                              } Selected`
                                            : 'Deadline Fields'}
                                    </Typography>
                                </AccordionSummary> */}
                            {/* <AccordionDetails> */}
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            id='deadline-field-options'
                                            options={deadlineFieldsMapping}
                                            getOptionLabel={(option) => (option.text ? option.text : '')}
                                            value={deadlineFieldName}
                                            isOptionEqualToValue={(option, value) => {
                                                // console.log(option, value);
                                                return option.value === value.value;
                                            }}
                                            onChange={(event, newValue) => {
                                                handleDeadlineFieldStatusChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label='Date Fields' variant='outlined' />
                                            )}
                                        />
                                    </FormControl> */}

                            {/* Before or After Deadline */}
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            id='deadline-field-operator-options'
                                            options={deadlineFieldsOperatorMapping}
                                            getOptionLabel={(option) => (option.text ? option.text : '')}
                                            value={deadlineFieldOperator}
                                            isOptionEqualToValue={(option, value) => {
                                                // console.log(option, value);
                                                return option.value === value.value;
                                            }}
                                            onChange={(event, newValue) => {
                                                handleDeadlineOperatorStatusChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label='Operators' variant='outlined' />
                                            )}
                                        />
                                    </FormControl> */}

                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            freeSolo
                                            options={deadlineDaysOptions}
                                            getOptionLabel={(option) => option.text || ''}
                                            value={
                                                deadlineDaysOptions.find((option) => option.value === deadlineValue) ||
                                                null
                                            }
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            onChange={handleSelect}
                                            onInputChange={(event, value) => {
                                                // Handles the direct text input from renderInput
                                                if (value === '') {
                                                    setDeadlineValue('');
                                                    handleDeadlineDaysChange('');
                                                } else {
                                                    setDeadlineValue(value);
                                                    handleDeadlineDaysChange(value);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    id='amount-of-days'
                                                    label='Amount of Days'
                                                    type='number'
                                                    value={deadlineValue}
                                                    onChange={handleInputChange}
                                                    variant='outlined'
                                                />
                                            )}
                                        />
                                    </FormControl> */}
                            {/* </AccordionDetails> */}
                            {/* </Accordion> */}
                        </Grid>
                    </Grid>

                    <Box display='flex' justifyContent='space-between' width='100%'>
                        <Box flex='1' maxWidth='340.2px' mr={0}>
                            <FormControl fullWidth={true} margin='normal'>
                                <InputLabel
                                    id='ercAppStatus-label'
                                    shrink={true}
                                    sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                                >
                                    Saved Searches
                                </InputLabel>
                                <Select
                                    id='currentSavedSearches-select'
                                    labelId='currentSavedSearches-label'
                                    label='Current Saved Searches'
                                    value={selectedSearchName ?? ''}
                                    renderValue={(selected) => (selected ? selected : 'Select a search')}
                                    onChange={(event) => {
                                        handleSelectSavedSearch(event.target.value);
                                    }}
                                    onOpen={() => setIsSelectOpen(true)}
                                    onClose={() => setIsSelectOpen(false)}
                                >
                                    <MenuItem value='' onClick={resetToDefaults}>
                                        Default
                                    </MenuItem>
                                    {currentSavedSearches.map((ea_search, index) => {
                                        if (editingSearchName === ea_search.search_name) {
                                            return (
                                                <EditableMenuItem
                                                    key={index}
                                                    ea_search={ea_search}
                                                    onSave={(oldName, newName) => {
                                                        handleEditSavedSearchName(oldName, newName);
                                                        setEditingSearchName(null);
                                                    }}
                                                    onCancel={() => {
                                                        setEditingSearchName(null);
                                                    }}
                                                />
                                            );
                                        } else {
                                            return (
                                                <MenuItem key={index} value={ea_search.search_name}>
                                                    {ea_search.search_name}
                                                    {isSelectOpen && (
                                                        <>
                                                            <Tooltip title='Edit Name'>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setEditingSearchName(ea_search.search_name);
                                                                    }}
                                                                    size='small'
                                                                    style={{ marginLeft: '8px' }}
                                                                >
                                                                    <EditIcon fontSize='inherit' />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title='Delete'>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleDeleteSavedSearch(
                                                                            ea_search.search_name,
                                                                            ea_search.search_id,
                                                                            appliesTo,
                                                                            currentUserID
                                                                        );
                                                                    }}
                                                                    size='small'
                                                                    style={{ marginLeft: '8px' }}
                                                                >
                                                                    <DeleteIcon fontSize='inherit' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                        <Grid
                            item
                            xs={12}
                            md={7}
                            mr={4}
                            container
                            justifyContent='flex-start'
                            spacing={2}
                            marginTop={'1em'}
                        >
                            <Grid item>
                                <Button
                                    variant='contained'
                                    onClick={searchButtonClicked}
                                    sx={{ backgroundColor: '#4CAF50' }}
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' onClick={saveSearchButtonClicked}>
                                    {selectedSearchName ? 'Update Search' : 'Save Search'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='secondary' onClick={resetToDefaults}>
                                    Reset to Defaults
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <PromptUpdateOrSaveNewSearchDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateSearch={handleOnlyUpdateSearch}
                handleOnlySaveAsNewSearch1={handleOnlySaveAsNewSearch1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewSearch2={handleOnlySaveAsNewSearch2}
            />
            {promptSaveNewSearch()}
        </>
    );
}

function LastForm471UsacDataRetrievalDatetimes({ trackersAPI }) {
    const [fstForm471Datetime, setFSTForm471Datetime] = React.useState(null);
    const [epcForm471Datetime, setEPCForm471Datetime] = React.useState(null);
    const [odForm471BIDatetime, setODForm471BIDatetime] = React.useState(null);
    const [ecfWebsiteForm471Datetime, setECFWebsiteForm471Datetime] = React.useState(null);

    React.useEffect(() => {
        const getDatetimes = async () => {
            try {
                let data = await trackersAPI.GetLastUsacDataRetrievalDatetimes('Form471TrackerMetadata');
                setFSTForm471Datetime(data.fstForm471_datetime);
                setEPCForm471Datetime(data.epcForm471_datetime);
                setODForm471BIDatetime(data.odForm471BI_datetime);
                setECFWebsiteForm471Datetime(data.ecfWebsiteForm471_datetime);
            } catch (error) {
                console.error('error: ', error);
                toast.error(error);
            }
        };

        getDatetimes();
    }, []);

    const renderDateTime = (isoDateTimeString, displayTime) => {
        if (isoDateTimeString) {
            try {
                let datedate = new Date(isoDateTimeString);
                let text;
                if (!displayTime) {
                    text = new Intl.DateTimeFormat('en-US').format(datedate);
                } else {
                    let dtfoptions = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    };
                    text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
                }
                return <span title={datedate}>{text}</span>;
            } catch (err) {
                console.error('renderDateTime error:', err);
            }
        }
        return isoDateTimeString;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: '0.8em', marginY: '0.8em' }}>
            {fstForm471Datetime && (
                <Box>Application data from FST last retrieved: {renderDateTime(fstForm471Datetime, true)}</Box>
            )}
            {epcForm471Datetime && (
                <Box sx={{ marginLeft: 2 }}>
                    Application data EPC last scraped: {renderDateTime(epcForm471Datetime, true)}
                </Box>
            )}
            {odForm471BIDatetime && (
                <Box sx={{ marginLeft: 2 }}>
                    Application data from Open Data Form 471 BI dataset: {renderDateTime(odForm471BIDatetime, true)}
                </Box>
            )}
            {ecfWebsiteForm471Datetime && (
                <Box sx={{ marginLeft: 2 }}>
                    {/* Application data from ECF last scraped: {renderDateTime(ecfWebsiteForm471Datetime, true)} */}
                    Application data from Open Data ECF: {renderDateTime(ecfWebsiteForm471Datetime, true)}
                </Box>
            )}
            <Box sx={{ marginLeft: 2 }}>
                <Link href={'/usacdataretrievaldatetimes'} target='_blank' rel='noopener'>
                    Form 471 Data Retrieval Datetimes
                </Link>
            </Box>
        </Box>
    );
}

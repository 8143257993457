/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ERateCentralPortalAPI",
            "endpoint": "https://yaabx9osxi.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:fde1260f-c385-4ecc-8114-57fdc2d9b803",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LvHmFJrFw",
    "aws_user_pools_web_client_id": "2sddels6fs0siha169e7ebeqv5",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eratecentralportal-frontend-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dzooq1tmoy48t.cloudfront.net",
    "aws_user_files_s3_bucket": "eratecentralportals3assets103957-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

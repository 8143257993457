import React from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { GridRowModes, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { InlineEditTextarea1 } from '../customcomponents/InlineEditTextarea.js';
import { InlineEditDropdown } from '../customcomponents/InlineEditDropdown.js';
// import { InlineEditDatePickerISOString } from '../customcomponents/InlineEditDatePicker.js';
// import { NotesCellDisplay } from './RenderCellExpand.js'; //ORIGINAL
import { NotesCellDisplay } from '../customcomponents/RenderCellExpandV2.js'; // NEW
import removeAnyBooleanFilterValueWrapper from '../customcomponents/RemoveAnyBooleanFilterValue.js';
import doesNotContainWrapper from '../customcomponents/DoesNotContainFilter.js';
import IsEqualToColumnWrapper from '../customcomponents/IsEqualToColumnFilter.js';
import IsNotEqualToColumnWrapper from '../customcomponents/IsNotEqualToColumnFilter.js';
import IsGreaterThanColumnWrapper from '../customcomponents/IsGreaterThanColumnFilter.js';
import IsGreaterThanOrEqualToColumnWrapper from '../customcomponents/IsGreaterThanOrEqualToColumnFilter.js';
import IsLessThanColumnWrapper from '../customcomponents/IsLessThanColumnFilter.js';
import IsLessThanOrEqualToColumnWrapper from '../customcomponents/IsLessThanOrEqualToColumnFilter.js';
import IsAnyOfWrapper from '../customcomponents/IsAnyOfFilter.js';
import IsNotAnyOfWrapper from '../customcomponents/IsNotAnyOfFilter.js';
// import IsBeforeXDaysWrapper from '../customcomponents/IsBeforeXDaysFilter.js';
// import IsAfterXDaysWrapper from '../customcomponents/IsAfterXDaysFilter.js';

// Returns the columns array.
export default function Form470ApplicationsColumnsDefinitionCreator(
    handleDetailsButtonClick,
    handleEditButtonClick,
    handleCancelIconClick,
    handleSaveIconClick,
    handleEditApplicationNotes,
    handleEditQAReviewerNotes,
    applicantMSFilterOptions,
    applicantCohortMSFilterOptions,
    form470AFYStatusesMSFilterOptions,
    serviceTypesMSFilterOptions,
    lastUpdatedByMSFilterOptions,
    billedEntityNameMSFilterOptions,
    usacAppStatusMSFilterOptions,
    applicationOwnerEditOptions,
    taskOwnerEditOptions,
    ercAppStatusEditOptions,
    qaReviewStatusEditOptions,
    qaReviewerEditOptions,
    qaSubmitterEditOptions,
    qaBidEvalStatusEditOptions,
    qaBidEvalReviewerEditOptions,
    qaBidEvalSubmitterEditOptions,
    qaIssuesEditOptions,
    qaDarkFiberEditOptions,
    apiRef,
    rowHeightPref,
    rowModesModel,
    columnWidths,
    originalColumnWidthSetter,
    originalColumnOrderSetter
) {
    let service_mapping = {
        'Basic Maintenance Of Internal Connections': 'BMIC',
        'Data Transmission And/Or Internet Access': 'DTIA',
        'Internal Connections': 'IC',
        'Internet Access And/Or Telecommunications': 'IAT',
        'Managed Internal Broadband Services': 'MIBS',
    };

    const renderDateTime2 = (jsDate, displayTime) => {
        // Version 2. Input is a JavaScript Date object.
        // Returns a React node (when there is data).
        if (!jsDate) {
            return null;
        }
        try {
            let text;
            if (!displayTime) {
                text = new Intl.DateTimeFormat('en-US').format(jsDate); // "12/20/2020"
            } else {
                let dtfoptions = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                };
                text = new Intl.DateTimeFormat('en-US', dtfoptions).format(jsDate); // "10/26/1985, 1:21 AM"
            }
            let ttoptions = {
                weekday: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short',
            };
            let titletext = new Intl.DateTimeFormat('en-US', ttoptions).format(jsDate);

            return <span title={titletext}>{text}</span>;
        } catch (err) {
            console.error('renderDateTime2 error:', err);
        }
        // If above failed then output something reasonable.
        return jsDate.toLocaleString();
    };

    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    //============================================================
    //  For DataGrid columns:
    //  `valueGetter`, `valueSetter`, `renderCell`, etc functions
    //============================================================

    const correctTheDropdownValue = (value, ddoptions) => {
        // If value is a number, return it.  If value is text then search the dropdown options for the numeric value (id).
        if (value === null || value === undefined) {
            return 0;
        }
        if (Number.isInteger(value)) {
            return value;
        }
        if (
            value === '' ||
            value.toLowerCase() === '<default to primary contact>' ||
            value.toLowerCase() === '<not set>' ||
            value.toLowerCase() === '<blank>'
        ) {
            return 0;
        }

        let ddoption = ddoptions.find((element) => element.text === value);
        let ddvalue = ddoption?.value;
        return ddvalue;
    };

    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    const valueGetter_getTaskOwnerNames = (params) => {
        if (!params.row.task_owners || params.row.task_owners.length === 0) {
            return '';
        }
        let ary = params.row.task_owners.map((tu) => {
            return getUserDisplayName(tu);
        });
        return ary.join(', ');
    };

    const valueSetter_setTaskOwnerUserID = (params) => {
        let task_owner_user_id = correctTheDropdownValue(params.value, taskOwnerEditOptions);
        return { ...params.row, task_owner_user_id };
    };

    const getPrimaryContactName = (params) => {
        return getUserDisplayName(params.row.client_primary_contact);
    };

    const getSecondaryContactNames = (params) => {
        if (!params.row.client_secondary_contacts || params.row.client_secondary_contacts.length === 0) {
            return '';
        }
        let ary = params.row.client_secondary_contacts.map((sc) => {
            return getUserDisplayName(sc);
        });
        return ary.join(', ');
    };

    const createUserChips = (userList, rowid, keyprefix) => {
        return userList.map((u) => {
            let chipKey = (keyprefix ? keyprefix + '-' : '') + rowid + '-' + u.first_name + u.last_name;
            let chipLabel = u.first_name + ' ' + u.last_name;
            return <Chip key={chipKey} label={chipLabel} sx={{ marginRight: '2px' }} />;
        });
    };

    const createUserChipsForContacts = (clientObject, rowid, rowIncrement, keyprefix) => {
        let chipKey =
            (keyprefix ? keyprefix + '-' : '') +
            rowid +
            '-' +
            rowIncrement +
            '-' +
            clientObject.first_name +
            clientObject.last_name;
        let chipLabel = clientObject.first_name + ' ' + clientObject.last_name;
        return <Chip key={chipKey} label={chipLabel} sx={{ marginRight: '2px' }} />;
    };

    const createServiceChips = (serviceTypeList, rowid, keyprefix) => {
        let serviceList = serviceTypeList.split('|');
        return serviceList.map((s) => {
            let chipKey = (keyprefix ? keyprefix + '-' : '') + rowid + getRandomNumber(1, 5000);
            let chipLabel = service_mapping[s];
            return <Chip key={chipKey} label={chipLabel} sx={{ marginRight: '2px' }} />;
        });
    };

    const valueGetter_getServiceTypeAbbreviations = (params) => {
        if (
            !params.row.form470_sr_service_types ||
            params.row.form470_sr_service_types === '' ||
            params.row.form470_sr_service_types === null
        ) {
            return '';
        }

        let serviceList = params.row.form470_sr_service_types.split('|');

        if (serviceList.length === 0) {
            return '';
        }

        let ary = serviceList.map((s) => {
            return service_mapping[s];
        });

        return ary.join(', ');
    };

    const getForm470TrackerNotes = (params) => {
        return params.row.cfy_form470_tracker_notes || '';
    };

    const getApplicantCohortNames = (params) => {
        if (!params.row.client_applicantcohorts || params.row.client_applicantcohorts.length === 0) {
            return '';
        }
        let ary = params.row.client_applicantcohorts.map((cac) => {
            return cac.applicantcohort.applicantcohort_name;
        });
        return ary.join(', ');
    };

    const getForm470AppNotes = (params) => {
        return params.row.form470_app_notes || '';
    };

    //----------  QA columns  ----------
    const valueGetter_getQANotes = (params) => {
        return params.row.qa_notes || '';
    };

    const valueGetter_getQABidEvalSubmitterName = (params) => {
        if (params.row.qa_bid_eval_submitter_name != null && params.row.qa_bid_eval_submitter_name !== '') {
            return params.row.qa_bid_eval_submitter_name;
        }
    };
    const valueSetter_setQABidEvalSubmitterUserID = (params) => {
        let qa_bid_eval_submitter_user_id = correctTheDropdownValue(params.value, qaBidEvalSubmitterEditOptions);
        return { ...params.row, qa_bid_eval_submitter_user_id };
    };

    const getQAReviewStatusCellClassName = (params) => {
        const qaReviewStatusId = params.row.qa_review_status_id;

        const classNames = {
            2: 'InlineEditable QAReviewStatus-470QAReadyForReview',
            3: 'InlineEditable QAReviewStatus-QAInProgress',
            4: 'InlineEditable QAReviewStatus-QAReturned',
            6: 'InlineEditable QAReviewStatus-470QACompleteNoIssues',
            7: 'InlineEditable QAReviewStatus-470QACompletewithIssues',
            8: 'InlineEditable QAReviewStatus-WillNotBeReviewed',
            9: 'InlineEditable QAReviewStatus-470Canceled/Reposted',
        };

        return classNames[qaReviewStatusId] || 'InlineEditable';
    };

    const getQABidEvalStatusCellClassName = (params) => {
        const qaBidEvalStatusId = params.row.qa_bid_eval_status_id;

        const classNames = {
            1: 'InlineEditable QABidEvalStatus-BidEvalReadyforQA',
            2: 'InlineEditable QABidEvalStatus-BidEvalQAInProgress',
            3: 'InlineEditable QABidEvalStatus-BidEvalQAReturned',
            4: 'InlineEditable QABidEvalStatus-BidEvalCompleteWithIssues',
            5: 'InlineEditable QABidEvalStatus-WillNotBeReviewed',
        };

        return classNames[qaBidEvalStatusId] || 'InlineEditable';
    };

    const valueGetter_getQAReviewStatusText = (params) => {
        if (params.row.qa_review_status_id != null && params.row.qa_review_status_id !== '') {
            return params.row.qa_review_status_text;
        }
        return '';
    };

    const valueGetter_getQABidEvalStatusText = (params) => {
        if (params.row.qa_bid_eval_status_id != null && params.row.qa_bid_eval_status_id !== '') {
            return params.row.qa_bid_eval_status_text;
        }
        return '';
    };

    const valueSetter_setQAReviewStatusID = (params) => {
        let qa_review_status_id = correctTheDropdownValue(params.value, qaReviewStatusEditOptions);
        return { ...params.row, qa_review_status_id };
    };

    const valueSetter_setQABidEvalStatusID = (params) => {
        let qa_bid_eval_status_id = correctTheDropdownValue(params.value, qaBidEvalStatusEditOptions);
        return { ...params.row, qa_bid_eval_status_id };
    };

    const valueGetter_getQAReviewerName = (params) => {
        if (params.row.qa_reviewer_user_id != null && params.row.qa_reviewer_user_id !== '') {
            return params.row.qa_reviewer_name;
        }
        return '';
    };

    const valueGetter_getQABidEvalReviewerName = (params) => {
        if (params.row.qa_bid_eval_reviewer_user_id != null && params.row.qa_bid_eval_reviewer_user_id !== '') {
            return params.row.qa_bid_eval_reviewer_name;
        }
        return '';
    };

    const valueSetter_setQAReviewerUserID = (params) => {
        let qa_reviewer_user_id = correctTheDropdownValue(params.value, qaReviewerEditOptions);
        return { ...params.row, qa_reviewer_user_id };
    };

    const valueSetter_setQABidEvalReviewerUserID = (params) => {
        let qa_bid_eval_reviewer_user_id = correctTheDropdownValue(params.value, qaBidEvalReviewerEditOptions);
        return { ...params.row, qa_bid_eval_reviewer_user_id };
    };

    const correctTheDropdownValueQAIssues = (value, ddoptions) => {
        // If value is null, return 0
        if (value === null || value === undefined) {
            return 0;
        }

        // If value is a number, return it.
        if (Number.isInteger(value)) {
            return value;
        }

        // If value is a single object, find the matching option based on issueId
        if (typeof value === 'object' && value !== null) {
            let ddoption = ddoptions.find((element) => element.value === value.issueId);
            return ddoption?.value;
        }

        // If an array is provided as the value, return a list of the results of CTDVQAI on each individual item
        if (Array.isArray(value)) {
            // console.log("Received an array for correctTheDropdownValue2");
            return value.map((val) => correctTheDropdownValueQAIssues(val, ddoptions));
        }

        // If value is a text string then search the dropdown options for the first matching numeric value (id) of that text string.
        let ddoption = ddoptions.find((element) => element.text === value);
        let ddvalue = ddoption?.value;
        return ddvalue;
    };

    const createQAIssueChips = (qaIssueList, rowid, keyprefix) => {
        return qaIssueList.map((issue) => {
            // Match the database(db) text value based on the issue's issueId int value
            const issueText = qaIssuesEditOptions.find((dbIssueObj) => dbIssueObj.value === issue.issueId)?.text;

            if (issueText) {
                return <Chip label={issueText} key={issue.issueId} />;
            }

            return null;
        });

        // qaIssueList = Comma-separated list of qa_issue_text.
        // let issueList = qaIssueList.split(',');
        // return issueList.map((qai) => {
        //     let chipKey = (keyprefix ? keyprefix + '-' : '') + rowid + getRandomNumber(1, 5000);
        //     let chipLabel = qai;
        //     return <Chip key={chipKey} label={chipLabel} sx={{ marginRight: '2px' }} />;
        // });
    };

    const valueGetter_getQAIssues = (params) => {
        // console.log('valuegeeet:', params);
        if (!params.value || params.value.length === 0) {
            return '';
        }
        return params.value.map((issue) => issue.issueText).join(', ');
    };

    const valueSetter_setQAIssues = (params) => {
        // console.log('This is the valueSetter params.value: ', params.value, ',', typeof params.value);

        let QAIssues = correctTheDropdownValueQAIssues(params.value, qaIssuesEditOptions);
        // console.log('These are the QA issues: ', QAIssues);
        return { ...params.row, qa_issues: QAIssues }; // would need to be an array to handle multiple
    };

    //----------  App Status columns  ----------
    const cellClassName_USACAppStatus = (params) => {
        // Returns the cell class.
        switch (params.row.usac_app_status_text) {
            case 'Incomplete':
                return 'USACAppStatus AppStatus-Incomplete';
            case 'Certified':
                return 'USACAppStatus AppStatus-Certified';
            case 'Committed':
                return 'USACAppStatus AppStatus-Committed';
            default:
                return 'USACAppStatus';
        }
    };

    const cellClassName_ERCAppStatus = (params) => {
        // Returns the cell class.
        if (params.row.erc_form470_app_status_id === 50 || params.row.erc_form470_app_status_id === 60) {
            // If the ERC App Status is "Ready for QA" or "Out of QA"..
            let qaReviewStatusId = params.row.qa_review_status_id;
            if (qaReviewStatusId === 2) {
                // "470 QA Ready For Review"
                return 'InlineEditable QAReviewStatus-470QAReadyForReview';
            } else if (qaReviewStatusId === 3) {
                // "QA In Progress"
                return 'InlineEditable QAReviewStatus-QAInProgress';
            } else if (qaReviewStatusId === 4) {
                // "QA Returned"
                return 'InlineEditable QAReviewStatus-QAReturned';
            } else if (qaReviewStatusId === 6) {
                // "470 QA Complete No Issues"
                return 'InlineEditable QAReviewStatus-470QACompleteNoIssues';
            } else if (qaReviewStatusId === 7) {
                // "470 QA Complete with Issues"
                return 'InlineEditable QAReviewStatus-470QACompletewithIssues';
            } else if (qaReviewStatusId === 8) {
                // "Will Not Be Reviewed"
                return 'InlineEditable QAReviewStatus-WillNotBeReviewed';
            } else if (qaReviewStatusId === 9) {
                // "470 Canceled/Reposted"
                return 'InlineEditable QAReviewStatus-470Canceled/Reposted';
            }
            return 'InlineEditable';
        }
        return 'InlineEditable';
    };

    const valueGetter_ECRAppStatusText = (params) => {
        // console.log("THESE ARE THE PARAMS OF A VALUE GETTER (erc_form470_app_status_id)")
        // console.log(params);
        // console.log("END FOR erc_form470_app_status_id");
        if (!params.row.erc_form470_app_status_id) {
            return '';
        }
        return params.row.erc_form470_app_status_text;
    };

    const valueSetter_ERCAppStatusID = (params) => {
        let erc_app_status_id = correctTheDropdownValue(params.value, ercAppStatusEditOptions);
        return { ...params.row, erc_app_status_id };
    };

    //============================================================
    //      DataGrid - "linked columns"
    //============================================================
    const checkIfEmptyAndFillQAFields = (params) => {
        if (!params) {
            return;
        }
        // User has changed the "ERC App Status" dropdown to "Ready for QA" (50)...
        // If empty, set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.

        // console.log('[checkIfEmptyAndFillQAFields] params.row = ', params.row);
        // console.log('[checkIfEmptyAndFillQAFields] params.row.application_owner_user_id = ', params.row.application_owner_user_id);
        // console.log('[checkIfEmptyAndFillQAFields] params.row.resolved_application_owner = ', params.row.resolved_application_owner);

        let ExistingSubmitterUserID = params.row.qa_submitter_user_id;
        let ExistingDateSubmitted = params.row.qa_date_submitted;
        let ExistingDateNeeded = params.row.qa_date_needed;

        // console.log('[checkIfEmptyAndFillQAFields] ExistingSubmitterUserID = ' + ExistingSubmitterUserID);
        // console.log('[checkIfEmptyAndFillQAFields] ExistingDateSubmitted = ' + ExistingDateSubmitted);
        // console.log('[checkIfEmptyAndFillQAFields] ExistingDateNeeded = ' + ExistingDateNeeded);

        if (ExistingSubmitterUserID == null && ExistingDateSubmitted == null && ExistingDateNeeded == null) {
            //*****  Set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.  *****

            let newQASubmitterUserId = -1;
            if (params.row.application_owner_user_id !== null) {
                // If "Application Owner" has been overriden, then use that value.
                newQASubmitterUserId = params.row.application_owner_user_id;
            } else {
                // If "Application Owner" is the default value..
                // TODO: We can get 2 different resolved_application_owner here. One with `id` and one with `user_id`. Needs investigation.
                if (params.row.resolved_application_owner.id) {
                    //console.log('[checkIfEmptyAndFillQAFields] Using params.row.resolved_application_owner.*id*');
                    newQASubmitterUserId = params.row.resolved_application_owner.id;
                } else if (params.row.resolved_application_owner.user_id) {
                    //console.log('[checkIfEmptyAndFillQAFields] Using params.row.resolved_application_owner.*user_id*');
                    newQASubmitterUserId = params.row.resolved_application_owner.user_id;
                }
            }
            //console.log('[checkIfEmptyAndFillQAFields] newQASubmitterUserId = ' + newQASubmitterUserId);

            // TODO: Instead of params.row.resolved_application_owner it would be better if we could dynamically read the value of the Application Owner dropdown so we're not using the previous value.

            let bOnQASubmitterDropdownList = false;
            let ddoption = qaSubmitterEditOptions.find((element) => element.value === newQASubmitterUserId);
            if (ddoption) {
                bOnQASubmitterDropdownList = true;
            }

            if (bOnQASubmitterDropdownList) {
                apiRef.current.setEditCellValue({
                    id: params.id,
                    field: 'qa_submitter_user_id',
                    value: newQASubmitterUserId,
                });
            }

            let today = create_todayJSDate();
            let defaultQADateNeeded = create_todayJSDate();
            defaultQADateNeeded.setDate(defaultQADateNeeded.getDate() + 2); // TODO: Add 2 weekday/business days not calendar days.

            apiRef.current.setEditCellValue({
                id: params.id,
                field: 'qa_date_submitted',
                value: today,
            });
            // apiRef.current.setEditCellValue({
            //     id: params.id,
            //     field: 'QADateNeeded',
            //     value: defaultQADateNeeded,
            // });
        }
    };

    const checkIfEmptyAndFillQABidEvalFields = (params) => {
        // User has changed the "Bid Eval" dropdown to "Ready for QA" (50)...
        // If empty, set "QA Bid Eval Submitter", "QA Bid Eval Date Submitted", and "QA Bid Eval Date Needed" to defaults.

        let ExistingSubmitterUserID = params.row.qa_bid_eval_submitter_user_id;
        let ExistingDateSubmitted = params.row.qa_bid_eval_date_submitted;
        let ExistingDateNeeded = params.row.qa_bid_eval_date_needed;
        let Existing470QAReviewer = params.row.qa_reviewer_user_id;

        if (ExistingSubmitterUserID == null && ExistingDateSubmitted == null && ExistingDateNeeded == null) {
            //*****  Set "QA Bid Eval Submitter", "QA Bid Eval Date Submitted", and "QA Bid Eval Date Needed" to defaults.  *****

            let newQABidEvalSubmitterUserId = -1;
            if (params.row.application_owner_user_id !== null) {
                // If "Application Owner" has been overriden, then use that value.
                newQABidEvalSubmitterUserId = params.row.application_owner_user_id;
            } else {
                // If "Application Owner" is the default value..
                if (params.row.resolved_application_owner.id) {
                    newQABidEvalSubmitterUserId = params.row.resolved_application_owner.id;
                } else if (params.row.resolved_application_owner.user_id) {
                    newQABidEvalSubmitterUserId = params.row.resolved_application_owner.user_id;
                }
            }

            let bOnQABidEvalSubmitterDropdownList = false;
            let ddoption = qaBidEvalSubmitterEditOptions.find(
                (element) => element.value === newQABidEvalSubmitterUserId
            );
            if (ddoption) {
                bOnQABidEvalSubmitterDropdownList = true;
            }

            if (bOnQABidEvalSubmitterDropdownList) {
                apiRef.current.setEditCellValue({
                    id: params.id,
                    field: 'qa_bid_eval_submitter_user_id',
                    value: newQABidEvalSubmitterUserId,
                });
            }

            let today = create_todayJSDate();
            let defaultQADateNeeded = create_todayJSDate();
            defaultQADateNeeded.setDate(defaultQADateNeeded.getDate() + 2); // TODO: Add 2 weekday/business days not calendar days.

            apiRef.current.setEditCellValue({
                id: params.id,
                field: 'qa_bid_eval_date_submitted',
                value: today,
            });
            // apiRef.current.setEditCellValue({
            //     id: params.id,
            //     field: 'QADateNeeded',
            //     value: defaultQADateNeeded,
            // });
            apiRef.current.setEditCellValue({
                id: params.id,
                field: 'qa_bid_eval_review_user_id',
                value: Existing470QAReviewer,
            });
        }
    };

    const convert_yyyymmddString_to_JSDate = (yyyymmddString) => {
        // Input: Date string in the format "yyyy-mm-dd".
        // Output: Javascript Date object.  Time is pushed forward as per the local timezone offset.
        // For DataGrid 'date' edit fields.
        if (!(typeof yyyymmddString === 'string')) {
            console.error('Error in convert_yyyymmddString_to_JSDate: Input was not a string.');
            return null;
        }
        let jsdate1 = new Date(yyyymmddString);
        return new Date(jsdate1.getTime() + jsdate1.getTimezoneOffset() * 60 * 1000);
    };

    const convert_JSDate_to_yyyymmddString = (jsdate) => {
        // Input: Javascript Date object.
        // Output: Date string in the format "yyyy-mm-dd".  Time was pushed back as per the local timezone offset.
        // For DataGrid 'date' edit fields.
        if (!(jsdate instanceof Date)) {
            console.error('Error in convert_JSDate_to_yyyymmddString: Input was not a JavaScript Date object.');
            return null;
        }
        let jsdate2 = new Date(jsdate.getTime() - jsdate.getTimezoneOffset() * 60 * 1000);
        return jsdate2.toISOString().split('T')[0];
    };

    const create_todayJSDate = () => {
        // Create a 'today' JavaScript Date for DataGrid 'date' edit fields.
        let today = new Date();
        today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero.
        return new Date(today.getTime() + today.getTimezoneOffset() * 60 * 1000);
    };

    // Updates the old widths with the values of the new "current" widths if present, or just uses the original width
    const dynamicColumnWidthWrapper = (original_cols, current_cols) => {
        return original_cols.map((col) => ({
            ...col,
            width: current_cols[col.field] || col.width,
        }));
    };

    const originalColumns = [
        {
            // Actions (Details and Edit page links)
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            getActions: (params) => {
                // params = GridRowParams (columns, id, row)
                // console.log('[originalColumns][getActions] params:', params);
                // console.log('[originalColumns][getActions] rowModesModel:', rowModesModel);

                const appdetailsbuttonkey =
                    'appDetailsButton-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;
                const appeditbuttonkey =
                    'appEditButton-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <Tooltip title='Save' key={`save-${params.id}`}>
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label='Save'
                                onClick={handleSaveIconClick(params.id)}
                                // color='inherit'
                                style={{ color: '#4CAF50' }}
                            />
                        </Tooltip>,
                        <Tooltip title='Cancel' key={`cancel-${params.id}`}>
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label='Cancel'
                                onClick={handleCancelIconClick(params.id)}
                                // color='inherit'
                                style={{ color: 'red' }}
                            />
                        </Tooltip>,
                    ];
                }

                return [
                    <Tooltip title='Details' key={appdetailsbuttonkey}>
                        <GridActionsCellItem
                            icon={<ArticleIcon />}
                            label='Details'
                            color='info'
                            aria-label='details'
                            onClick={() =>
                                handleDetailsButtonClick(params.row.form470AppNum, params.row.form470AppIncrement)
                            }
                        ></GridActionsCellItem>
                    </Tooltip>,
                    <Tooltip title='Edit' key={appeditbuttonkey}>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label='Edit'
                            color='primary'
                            aria-label='edit'
                            onClick={() =>
                                handleEditButtonClick(params.row.form470AppNum, params.row.form470AppIncrement)
                            }
                        ></GridActionsCellItem>
                    </Tooltip>,
                ];
            },
            filterable: false,
            sortable: false,
            width: 100,
        },
        {
            // Funding Year
            headerName: 'Funding Year',
            renderHeader: (params) => renderMultilineHeader(params, 'Funding', 'Year'),
            field: 'fundingYear',
            valueGetter: (params) => {
                // If regular app return:  fundingYear
                // If mini-bid app return:  minibidFundingYear
                if (params.row.isMinibid === true) {
                    return params.row.minibidFundingYear;
                }
                return params.row.fundingYear;
            },
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            width: 100,
        },
        {
            // BEN
            headerName: 'BEN',
            headerAlign: 'left',
            field: 'ben',
            valueGetter: (params) => {
                // If regular app return:  ben
                // If mini-bid app return:  minibidClientBEN
                if (params.row.isMinibid === true) {
                    return params.row.minibidClientBEN;
                }
                return params.row.ben;
            },
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            renderCell: (params) => {
                let viewclientlinkkey =
                    'viewClientBENLink-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;
                return (
                    <Tooltip title='View Client' key={viewclientlinkkey}>
                        <Link
                            href={'../../client-list/details/' + params.row.clientId + '?ben=' + params.value}
                            target='_blank'
                            rel='noopener'
                        >
                            {params.value}
                        </Link>
                    </Tooltip>
                );
            },
            width: 120,
        },
        {
            // Applicant
            headerName: 'Applicant',
            field: 'client_name',
            width: 210,
        },
        {
            // Application Funding Year
            headerName: '470 Application Funding Year',
            renderHeader: (params) => renderMultilineHeader(params, '470 Application', 'Funding Year'),
            field: 'application_fundingYear',
            valueGetter: (params) => params.row.fundingYear,
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            width: 100,
        },
        {
            // Application BEN
            headerName: '470 Application BEN',
            renderHeader: (params) => renderMultilineHeader(params, '470 Application', 'BEN'),
            field: 'application_ben',
            valueGetter: (params) => params.row.ben,
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            width: 120,
        },
        {
            // Application Billed Entity Name
            headerName: '470 Application Billed Entity Name',
            renderHeader: (params) => renderMultilineHeader(params, '470 Application', 'Billed Entity Name'),
            field: 'application_billedentityname',
            valueGetter: (params) => params.row.billed_entity_name,
            width: 210,
        },
        {
            // Primary Contact
            headerName: 'Primary Contact',
            field: 'client_primary_contact',
            valueGetter: getPrimaryContactName,
            renderCell: (params) => {
                return createUserChipsForContacts(
                    params.row.client_primary_contact,
                    params.row.form470AppNum,
                    params.row.form470AppIncrement,
                    'pc'
                );
            },
            width: 150,
        },
        {
            // Secondary Contacts
            headerName: 'Secondary Contacts',
            field: 'client_secondary_contacts',
            valueGetter: getSecondaryContactNames,
            renderCell: (params) => {
                return createUserChips(params.row.client_secondary_contacts, params.row.form470AppNum, 'sc');
            },
            width: 300,
        },
        {
            // Application Owner
            headerName: 'Application Owner',
            headerClassName: 'Editable-NotRequired',
            field: 'resolved_application_owner', // (Not the best name for this field - 'ApplicationOwner' would be better - but we are stuck with it because of existing saved preferences.)
            type: 'string',
            cellClassName: (params) => {
                if (params.row.application_owner_user_id !== null) {
                    return 'InlineEditable ApplicationOwner-Overridden';
                }
                return 'InlineEditable ApplicationOwner-Default';
            },
            valueGetter: (params) => {
                //~ Display the "resolved" Application Owner.  By default the Application Owner is the Primary Contact.
                //console.log('[valueGetter] params.row.application_owner_user_id = ' + params.row.application_owner_user_id + '  (for form470AppNum ' +  params.row.form470AppNum + ')');
                //return getUserDisplayName(params.row.resolved_application_owner) + ' (' + params.row.application_owner_user_id + ')';
                return getUserDisplayName(params.row.resolved_application_owner);
            },
            valueSetter: (params) => {
                //~ Returning an `ApplicationOwnerUserID` for processRowUpdate to read (in newRow).
                //~ params.value is the employee name returned by valueGetter unless/until the user changes the dropdown.
                if (Number.isInteger(params.value)) {
                    //~ User has changed the Application Owner edit dropdown. params.value has changed from employee name text to a userid integer.
                    //console.log('[valueSetter] params.value has changed from employee name text to a userid integer.  params.value = ' + params.value);
                    let ApplicationOwnerUserID = params.value;
                    return { ...params.row, ApplicationOwnerUserID };
                } else {
                    //~ Keep the same application_owner_user_id.
                    //console.log('[valueSetter] Keep the same application_owner_user_id.  params.value = ' + params.value);
                    let ApplicationOwnerUserID = params.row.application_owner_user_id || 0;
                    return { ...params.row, ApplicationOwnerUserID };
                }
            },
            renderEditCell: (params) => {
                //console.log('[renderEditCell] params.row = ', params.row);
                //console.log('[renderEditCell] params.field = ' + params.field);
                //console.log('[renderEditCell] params.row.application_owner_user_id = ' + params.row.application_owner_user_id);
                let selectedid = params.row.application_owner_user_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={applicationOwnerEditOptions}
                    />
                );
            },
            editable: true,
            width: 200,
        },
        {
            // Task Owner
            headerName: 'Task Owners',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable TaskOwner',
            field: 'task_owners',
            valueGetter: valueGetter_getTaskOwnerNames,
            valueSetter: valueSetter_setTaskOwnerUserID,
            renderCell: (params) => {
                return createUserChips(params.row.task_owners, params.row.form470AppNum, 'to');
            },
            renderEditCell: (params) => {
                let selectedid = 0;
                if (params.row.task_owners != null && params.row.task_owners.length > 0) {
                    selectedid = params.row.task_owners[0].id;
                }
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={taskOwnerEditOptions}
                    />
                );
            },
            editable: true,
            width: 200,
        },
        {
            // Application Number
            headerName: 'F470 Application Number',
            headerAlign: 'left',
            field: 'form470AppNum',
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            renderCell: (params) => {
                if (!params.row.epc_webpage_url) {
                    return params.row.form470AppNum;
                }
                let apppageinepclinkkey =
                    'appPageInEpcLink-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;
                return (
                    <Tooltip title='Application page in EPC' key={apppageinepclinkkey}>
                        <Link className='ExternalLink' href={params.row.epc_webpage_url} target='_blank' rel='noopener'>
                            {params.row.form470AppNum}
                        </Link>
                    </Tooltip>
                );
            },
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    F470 Application
                    <br />
                    Number
                </Box>
            ),
            width: 150,
        },
        {
            // Service Types
            headerName: 'Service Types',
            renderHeader: (params) => renderMultilineHeader(params, 'Service', 'Types'),
            field: 'form470_sr_service_types',
            renderCell: (params) => {
                if (params.row.form470_sr_service_types !== null && params.row.form470_sr_service_types !== '') {
                    return createServiceChips(
                        params.row.form470_sr_service_types,
                        params.row.form470AppNum,
                        'serviceTypes'
                    );
                }
                return '';
            },
            valueGetter: valueGetter_getServiceTypeAbbreviations,
            width: 200,
        },
        {
            // Categories of Service
            headerName: 'Categories of Service',
            renderHeader: (params) => renderMultilineHeader(params, 'Categories', 'of Service'),
            field: 'form470_sr_categories_of_service',
            align: 'center',
            width: 100,
        },
        {
            // Nickname
            headerName: 'Nickname',
            field: 'nickname',
            width: 200,
        },
        {
            // ERC App Status
            headerName: 'ERC App Status',
            headerClassName: 'Editable-NotRequired',
            cellClassName: cellClassName_ERCAppStatus,
            field: 'erc_form470_app_status_id',
            valueGetter: valueGetter_ECRAppStatusText, // form470app.erc_form470_app_status.status_text
            valueFormatter: (params) => {
                // console.log("THESE ARE THE PARAMS OF A VALUE FORMATTER (erc_form470_app_status_id)")
                // console.log(params);
                // console.log("END FOR erc_form470_app_status_id");
                if (params.value == null || params.value === '') {
                    return '-';
                }
                return params.value;
            },
            editable: true,
            renderEditCell: (params) => {
                const selectedid = params.row.erc_form470_app_status_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={ercAppStatusEditOptions}
                    />
                );
            },
            valueParser: (value, params) => {
                if (value === 50) {
                    // User has changed the "ERC App Status" dropdown to "Ready for QA" (50)...
                    // If empty, set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.
                    checkIfEmptyAndFillQAFields(params);
                }

                return value;
            },
            valueSetter: valueSetter_ERCAppStatusID,
            width: 200,
        },
        {
            // USAC App Status
            headerName: 'USAC App Status',
            cellClassName: cellClassName_USACAppStatus,
            field: 'usac_app_status_text',
            width: 150,
        },
        {
            // Allowable Contract Date
            headerName: 'Allowable Contract Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Allowable', 'Contract Date'),
            field: 'allowable_contract_date',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                //console.log("['Allowable Contract Date', valueGetter]  params.value = " + params.value);   // "2022-11-10"
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            width: 150,
        },
        {
            // Certified Date
            headerName: 'Certified Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified Date'),
            field: 'certified_date_time',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Example: "2021-12-29T16:45:00+00:00"
                return new Date(params.value);
            },
            renderCell: (params) => {
                // params.value is from valueGetter and is expected to be either null or a JavaScript Date object.
                if (params.value === null) {
                    return null;
                }
                return renderDateTime2(params.value, false);
            },
            width: 150,
        },
        {
            // Is Mini-Bid
            headerName: 'Is Mini-Bid',
            renderHeader: (params) => renderMultilineHeader(params, 'Is a', 'Mini-Bid'),
            field: 'isMinibid',
            type: 'boolean',
            renderCell: (params) => <>{params.value === true ? <CheckIcon style={{ color: 'green' }} /> : ''}</>,
            align: 'center',
            width: 100,
        },
        {
            // Is a Client
            headerName: 'Is a Client',
            renderHeader: (params) => renderMultilineHeader(params, 'Is a', 'Client'),
            field: 'isAClient',
            type: 'boolean',
            renderCell: (params) => <>{params.value === true ? 'Yes' : params.value === false ? 'No' : ''}</>,
            align: 'center',
            width: 100,
        },
        {
            // Applicant Cohorts
            headerName: 'Applicant Cohorts',
            field: 'ApplicantCohorts',
            valueGetter: getApplicantCohortNames,
            renderCell: (params) => {
                //console.log("params.row.client_applicantcohorts = ", params.row.client_applicantcohorts);
                return params.row.client_applicantcohorts.map((cac) => {
                    let applicantcohortchipkey =
                        'applicantCohortChip-' +
                        params.row.form470AppNum +
                        '-' +
                        params.row.form470AppIncrement +
                        '-' +
                        cac.applicantcohort_id;
                    //console.log("applicantcohortchipkey = " + applicantcohortchipkey);
                    return (
                        <Chip
                            key={applicantcohortchipkey}
                            label={cac.applicantcohort.applicantcohort_name}
                            sx={{ marginRight: '4px' }}
                        />
                    );
                });
            },
            width: 200,
        },
        {
            // AFY Status
            headerName: 'AFY Status',
            field: 'cfy_form470_afystatus_text',
            width: 210,
        },
        {
            // AFY Notes
            headerName: 'AFY Notes',
            field: 'cfy_form470_tracker_notes',
            minPopupWidth: 500,
            valueGetter: getForm470TrackerNotes,
            // ORIGINAL RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => (
                <NotesCellDisplay
                    notes={params.value}
                    noteIsEditable={false}
                    minPopupWidth={500}
                    computedWidth={params.colDef.computedWidth}
                    rowHeightPref={rowHeightPref}
                    isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                />
            ),
            // headerClassName: 'Editable-NotRequired',
            // cellClassName: 'InlineEditable',
            // renderEditCell: (params) => <InlineEditTextarea1 {...params} />,
            // editable: true,
            width: 300,
        },
        {
            // QA Dark Fiber Self-Provisioned
            headerName: 'QA Dark Fiber or Self-Provisioned',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            renderHeader: (params) => renderMultilineHeader(params, 'Dark Fiber', 'or Self Prov'),
            field: 'qa_darkfiberselfprovisioned_name',
            type: 'string',
            // valueSetter: (params) => {
            //     return params.row.qa_darkfiberselfprovisioned_name;
            // },
            valueGetter: (params) => {
                return params.row.qa_darkfiberselfprovisioned_value;
            },
            renderEditCell: (params) => {
                let selectedId = '';
                if (params.row.qa_darkfiberselfprovisioned_name === 'Dark Fiber') {
                    selectedId = 'DarkFiber';
                } else if (params.row.qa_darkfiberselfprovisioned_name === 'Self Provisioned') {
                    selectedId = 'SelfProvisioned';
                }

                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedId}
                        field={params.field}
                        dropDownOptions={qaDarkFiberEditOptions}
                    />
                );
            },
            renderCell: (params) => {
                return params.row.qa_darkfiberselfprovisioned_value;
            },
            editable: true,
            width: 130,
        },
        {
            // Application Notes
            headerName: 'Application Notes',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'form470_app_notes',
            minPopupWidth: 500,
            valueGetter: getForm470AppNotes, // form470app.form470_app_notes
            // ORIGINAL RENDERCELL:
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL:
            renderCell: (params) => {
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={rowHeightPref}
                        onEdit={() =>
                            handleEditApplicationNotes(
                                params.id,
                                params.field,
                                params.value,
                                params['row']['ben'],
                                params['row']['nickname']
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            renderEditCell: (params) => <InlineEditTextarea1 {...params} />,
            editable: false,
            width: 300,
        },
        {
            // QA Submitter
            headerName: 'QA Submitter',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_submitter_user_id',
            valueGetter: (params) => {
                //console.log('[QA Submitter, valueGetter] params.field = ' + params.field + ', params.row.qa_submitter_name = ' + params.row.qa_submitter_name + ', params.row.qa_submitter_user_id = ' + params.row.qa_submitter_user_id);
                if (params.row.qa_submitter_name != null && params.row.qa_submitter_name !== '') {
                    return params.row.qa_submitter_name;
                }
                return null;
            },
            valueSetter: (params) => {
                //~ Updates params.row.qa_submitter_user_id if necessary with new value.
                //~ params.value is the employee name (or null) returned by valueGetter unless/until the user changes the dropdown.
                //console.log('[QA Submitter, valueSetter] params.value = ' + params.value);
                //console.log('[QA Submitter, valueSetter] params.row = ', params.row);
                if (params.value == null) {
                    //~ No "QA Submitter".  Return 0 (to processRowUpdate).
                    //console.log('[QA Submitter, valueSetter] No "QA Submitter".  Return 0 (to processRowUpdate).   params.value = ' + params.value);
                    let qa_submitter_user_id = 0;
                    return { ...params.row, qa_submitter_user_id };
                } else if (Number.isInteger(params.value)) {
                    //~ User has changed the dropdown.  params.value has changed from employee name text to a userid integer.  Can be 0 which indicates "Not set".
                    //console.log('[QA Submitter, valueSetter] params.value has changed from employee name text to a userid integer.  params.value = ' + params.value);
                    let qa_submitter_user_id = params.value;
                    return { ...params.row, qa_submitter_user_id };
                } else {
                    //~ Keep the original userid.
                    //console.log('[QA Submitter, valueSetter] Keep the original qa_submitter_user_id.  params.value = ' + params.value + ', params.row.qa_submitter_user_id = ' + params.row.qa_submitter_user_id);
                    return { ...params.row };
                }
            },
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.qa_submitter_user_id ? params.row.qa_submitter_user_id : 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaSubmitterEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            // QA Status
            headerName: '470 QA Status',
            headerClassName: 'Editable-NotRequired',
            cellClassName: getQAReviewStatusCellClassName,
            field: 'qa_review_status_id',
            valueGetter: valueGetter_getQAReviewStatusText,
            valueFormatter: (params) => {
                if (params.value == null || params.value === '') {
                    return '-';
                }
                return params.value;
            },
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.qa_review_status_id ? params.row.qa_review_status_id : 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaReviewStatusEditOptions}
                    />
                );
            },
            valueSetter: valueSetter_setQAReviewStatusID,
            width: 200,
        },
        {
            // QA Date Submitted
            headerName: '470 QA Date Submitted',
            renderHeader: (params) => renderMultilineHeader(params, '470 QA Date', 'Submitted'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_date_submitted',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, qa_date_submitted: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, qa_date_submitted: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            // QA Date Needed
            headerName: '470 QA Date Needed',
            renderHeader: (params) => renderMultilineHeader(params, '470 QA Date', 'Needed'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_date_needed',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                //console.log("['470 QA Date Needed', valueGetter]  params.value = " + params.value);   // "2023-08-23"
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, qa_date_needed: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, qa_date_needed: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            // QA Date Assigned
            headerName: '470 QA Date Assigned',
            renderHeader: (params) => renderMultilineHeader(params, '470 QA Date', 'Assigned'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_date_assigned',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }

                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, qa_date_assigned: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        qa_date_assigned: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // QA Reviewer
            headerName: '470 QA Reviewer',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_reviewer_user_id',
            valueGetter: valueGetter_getQAReviewerName,
            valueSetter: valueSetter_setQAReviewerUserID,
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.qa_reviewer_user_id ? params.row.qa_reviewer_user_id : 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaReviewerEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            // QA Date Completed
            headerName: '470 QA Date Completed',
            renderHeader: (params) => renderMultilineHeader(params, '470 QA Date', 'Completed'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_date_completed',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }

                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, qa_date_completed: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        qa_date_completed: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // QA Submitter
            headerName: 'Bid Eval QA Submitter',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval QA', 'Submitter'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_bid_eval_submitter_user_id',
            valueGetter: valueGetter_getQABidEvalSubmitterName,
            valueSetter: valueSetter_setQABidEvalSubmitterUserID,
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.qa_bid_eval_submitter_user_id
                    ? params.row.qa_bid_eval_submitter_user_id
                    : 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaBidEvalSubmitterEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            // Bid Eval QA Status
            headerName: 'Bid Eval QA Status',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval QA', 'Status'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: getQABidEvalStatusCellClassName,
            field: 'qa_bid_eval_status_id',
            valueGetter: valueGetter_getQABidEvalStatusText,
            valueFormatter: (params) => {
                if (params.value == null || params.value === '') {
                    return '-';
                }
                return params.value;
            },
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.qa_bid_eval_status_id ? params.row.qa_bid_eval_status_id : 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaBidEvalStatusEditOptions}
                    />
                );
            },
            valueParser: (value, params) => {
                if (value === 1) {
                    // User has changed the "Bid Eval" dropdown to "Bid Eval Ready for QA" (1)...
                    // If empty, set "Bid Eval QA Submitter", "Bid Eval QA Date Submitted", and "Bid Eval QA Date Needed" to defaults.
                    checkIfEmptyAndFillQABidEvalFields(params);
                }

                return value;
            },
            valueSetter: valueSetter_setQABidEvalStatusID,
            width: 200,
        },
        {
            // QA Bid Eval Date Submitted
            headerName: 'Bid Eval QA Date Submitted',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval QA Date', 'Submitted'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_bid_eval_date_submitted',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                //console.log("['Bid Eval QA Date Submitted', valueGetter]  params.value = " + params.value);   // "2023-01-05"
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, qa_bid_eval_date_submitted: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, qa_bid_eval_date_submitted: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            // Bid Eval QA Date Needed
            headerName: 'Bid Eval QA Date Needed',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval QA Date', 'Needed'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_bid_eval_date_needed',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, qa_bid_eval_date_needed: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, qa_bid_eval_date_needed: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            // Bid Eval QA Reviewer
            headerName: 'Bid Eval QA Reviewer',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval QA', 'Reviewer'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_bid_eval_review_user_id',
            valueGetter: valueGetter_getQABidEvalReviewerName,
            valueSetter: valueSetter_setQABidEvalReviewerUserID,
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.qa_bid_eval_review_user_id ? params.row.qa_bid_eval_review_user_id : 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaBidEvalReviewerEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            // Bid Eval QA Date Completed
            headerName: 'Bid Eval QA Date Completed',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval QA Date', 'Completed'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_bid_eval_date_completed',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }

                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, qa_bid_eval_date_completed: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        qa_bid_eval_date_completed: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // QA Reviewer Notes
            headerName: 'QA Reviewer Notes',
            renderHeader: (params) => renderMultilineHeader(params, 'QA Reviewer Notes'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'qa_notes',
            valueGetter: valueGetter_getQANotes,
            // ORIGINAL RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => {
                // console.log(
                //     '[form470_app_notes, renderCell] ' +
                //         params.id +
                //         ' | apiRef.current.getRowMode() = ' +
                //         apiRef.current.getRowMode(params.id)
                // );
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={rowHeightPref}
                        onEdit={() =>
                            handleEditQAReviewerNotes(
                                params.id,
                                params.field,
                                params.value,
                                params['row']['ben'],
                                params['row']['nickname']
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            editable: false,
            renderEditCell: (params) => <InlineEditTextarea1 {...params} />,
            width: 300,
        },
        // {
        //     // Has QA Issues
        //     headerName: 'Has QA Issues',
        //     renderHeader: (params) => renderMultilineHeader(params, 'Has QA', 'Issues'),
        //     renderCell: (params) => <>{params.value === true ? <CheckIcon style={{ color: 'red' }} /> : ''}</>,
        //     field: 'hasQAIssues',
        //     type: 'boolean',
        //     align: 'center',
        //     width: 100,
        // },
        {
            // QA Issues
            headerName: 'QA Issues',
            field: 'qa_issues',
            valueGetter: valueGetter_getQAIssues,
            valueSetter: valueSetter_setQAIssues,
            // ORIGINAL RENDERCELL
            // renderCell: (params) => {
            //     // console.log('qa_issues renderCell params:', params);

            //     if (!params.row.qa_issues || params.row.qa_issues.length === 0) {
            //         return '';
            //     }

            //     // return createQAIssueChips([params.value], params.row.form470AppNum, 'qaIssues'); // this works for ONE issue, [params.value] is a single object turned into a list
            //     const chips = createQAIssueChips(params.row.qa_issues, params.row.form470AppNum, 'qaIssues'); // turns all available issues into chips
            //     const chips_string = params.row.qa_issues.map((issue) => issue.issueText).join('\n'); // convert issues to a single string & have their own line

            //     if (chips_string) {
            //         return (
            //             <NotesCellDisplay
            //                 notes={chips_string}
            //                 minPopupWidth={300}
            //                 computedWidth={params.colDef.computedWidth}
            //                 rowHeightPref={rowHeightPref}
            //                 // children={chips} // optional when wanting to pass in chips alone (for now we're passing a string)
            //             />
            //         );
            //     }

            //     return chips;
            // },

            // NEW RENDERCELL
            renderCell: (params) => {
                // console.log('qa_issues renderCell params:', params);

                if (!params.row.qa_issues || params.row.qa_issues.length === 0) {
                    return '';
                }

                // return createQAIssueChips([params.value], params.row.form470AppNum, 'qaIssues'); // this works for ONE issue, [params.value] is a single object turned into a list
                const chips = createQAIssueChips(params.row.qa_issues, params.row.form470AppNum, 'qaIssues'); // turns all available issues into chips
                const chips_string = params.row.qa_issues.map((issue) => issue.issueText).join('\n'); // convert issues to a single string & have their own line

                if (chips_string) {
                    return (
                        <NotesCellDisplay
                            notes={chips_string}
                            minPopupWidth={300}
                            computedWidth={params.colDef.computedWidth}
                            rowHeightPref={rowHeightPref}
                            // children={chips} // optional when wanting to pass in chips alone (for now we're passing a string)
                            // isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                        />
                    );
                }

                return chips;
            },
            valueFormatter: (params) => {
                return params.value || '';
            },
            width: 150,
            // valueFormatter: (params) => {
            //     if (Array.isArray(params.value)) {
            //         return params.value.join('\n');
            //     }
            //     return '';
            // },
            // headerClassName: 'Editable-NotRequired',
            // cellClassName: 'InlineEditable',
            // renderEditCell: (params) => {
            //     let selectedIDs;
            //     if (params.row.qa_issues && params.row.qa_issues.length > 0) {
            //         selectedIDs = params.row.qa_issues.map((issue) => issue.issueId);
            //     }
            //     return (
            //         <InlineEditDropdown2
            //             id={params.id}
            //             value={selectedIDs}
            //             field={params.field}
            //             dropDownOptions={qaIssuesEditOptions}
            //         />
            //     );
            // },
            // editable: true,
        },
        {
            // Date Sent to Client to Certify
            headerName: 'Date Sent to Client to Certify',
            renderHeader: (params) => renderMultilineHeader(params, 'Date Sent to', 'Client to Certify'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'date_sent_to_client_to_certify',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }

                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, date_sent_to_client_to_certify: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        date_sent_to_client_to_certify: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Issue Date
            headerName: 'Issue Date',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'issue_date',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }

                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, issue_date: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        issue_date: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // W/T/PreBid Mtg Date
            // App dates values are arrays of objects, and have length 0 if not set
            headerName: 'W/T/PreBid Mtg Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Pre Bid'),
            headerClassName: 'Editable-NotRequired',
            field: 'appdates_WTPreBidMtg',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_WTPreBidMtg.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_WTPreBidMtg[0]['date']).getTime() +
                        new Date(params.row.appdates_WTPreBidMtg[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_WTPreBidMtg: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_WTPreBidMtg: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Questions Deadline Date
            // App dates values are arrays of objects, and have length 0 if not set
            headerName: 'Questions Deadline Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Questions', 'Deadline'),
            headerClassName: 'Editable-NotRequired',
            field: 'appdates_QuestionsDeadline',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_QuestionsDeadline.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_QuestionsDeadline[0]['date']).getTime() +
                        new Date(params.row.appdates_QuestionsDeadline[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_QuestionsDeadline: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_QuestionsDeadline: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Answers Deadline Date
            headerName: 'Answers Deadline Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Answers', 'Deadline'),
            headerClassName: 'Editable-NotRequired',
            field: 'appdates_AnswersDeadline',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_AnswersDeadline.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_AnswersDeadline[0]['date']).getTime() +
                        new Date(params.row.appdates_AnswersDeadline[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_AnswersDeadline: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_AnswersDeadline: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Answers Posted Date
            headerName: 'Answers Posted Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Answers', 'Posted'),
            headerClassName: 'Editable-NotRequired',
            field: 'appdates_AnswersPosted',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_AnswersPosted.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_AnswersPosted[0]['date']).getTime() +
                        new Date(params.row.appdates_AnswersPosted[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_AnswersPosted: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_AnswersPosted: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Bid Closing Date
            headerName: 'Bid Closing Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid', 'Closing'),
            headerClassName: 'Editable-NotRequired',
            field: 'appdates_BidClosing',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_BidClosing.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_BidClosing[0]['date']).getTime() +
                        new Date(params.row.appdates_BidClosing[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_BidClosing: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_BidClosing: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Bid Opening Date
            headerName: 'Bid Evaluation Complete/Vendor Selected Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval'),
            headerClassName: 'Editable-NotRequired',
            field: 'appdates_BidEvalCompVendSel',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_BidEvalCompVendSel.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_BidEvalCompVendSel[0]['date']).getTime() +
                        new Date(params.row.appdates_BidEvalCompVendSel[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_BidEvalCompVendSel: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_BidEvalCompVendSel: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Award Date
            headerName: 'Award Date',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'appdates_Award',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_Award.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_Award[0]['date']).getTime() +
                        new Date(params.row.appdates_Award[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_Award: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_Award: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Date Email Sent to Vendors
            headerName: 'Date Email Sent to Vendors',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            renderHeader: (params) => renderMultilineHeader(params, 'Email Sent', 'to Vendors'),
            field: 'appdates_EmailSentToVendors',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value || params.row.appdates_EmailSentToVendors.length === 0) {
                    return null;
                }

                return new Date(
                    new Date(params.row.appdates_EmailSentToVendors[0]['date']).getTime() +
                        new Date(params.row.appdates_EmailSentToVendors[0]['date']).getTimezoneOffset() * 60 * 1000
                );
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    return { ...params.row, appdates_EmailSentToVendors: '' };
                }
                if (params.value instanceof Date) {
                    return {
                        ...params.row,
                        appdates_EmailSentToVendors: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    };
                }
                return { ...params.row };
            },
            editable: true,
            width: 150,
        },
        {
            // Last Updated By User
            headerName: 'Last Updated by User',
            renderHeader: (params) => renderMultilineHeader(params, 'Last Updated', 'by User'),
            field: 'last_updated_by',
            type: 'string',
            valueGetter: (params) => {
                return params.row.last_updated_by;
            },
            width: 150,
        },
        {
            // Last Updated On
            headerName: 'Last Updated On',
            field: 'last_updated_on',
            type: 'dateTime',
            valueGetter: (params) => {
                // Input example: "2024-01-12T15:32:20.080060+00:00" or null
                if (!params.row.last_updated_on) {
                    return null;
                }
                return new Date(params.row.last_updated_on);
            },
            renderCell: (params) => {
                //console.log('<Last Updated by User, renderCell> params.row.form470AppNum = ' + params.row.form470AppNum);
                //console.log('<Last Updated by User, renderCell> params.row.form470app.user_entered_field_updated_timestamp = ' + params.row.form470app.user_entered_field_updated_timestamp);
                // params.value is from valueGetter and is expected to be either null or a JavaScript Date object.
                if (params.value === null) {
                    return null;
                }
                return renderDateTime2(params.value, true);
            },
            width: 150,
        },
    ];

    // To remove the "not set" and "default to primary contact" options
    const newApplicationOwnerEditOptions = applicationOwnerEditOptions.filter((ea_object) => ea_object.value !== 0);
    const newTaskOwnerEditOptions = taskOwnerEditOptions.filter((ea_object) => ea_object.value !== 0);
    const newQABidEvalReviewerEditOptions = qaBidEvalReviewerEditOptions.filter((ea_object) => ea_object.value !== 0);
    const newQABidEvalSubmitterEditOptions = qaBidEvalSubmitterEditOptions.filter((ea_object) => ea_object.value !== 0);
    const newQAReviewerEditOptions = qaReviewerEditOptions.filter((ea_object) => ea_object.value !== 0);
    const newQASubmitterEditOptions = qaSubmitterEditOptions.filter((ea_object) => ea_object.value !== 0);

    const editOptions = {
        applicantCohortMSFilterOptions,
        applicantMSFilterOptions,
        billedEntityNameMSFilterOptions,
        ercAppStatusEditOptions,
        form470AFYStatusesMSFilterOptions,
        lastUpdatedByMSFilterOptions,
        newApplicationOwnerEditOptions,
        newQABidEvalReviewerEditOptions,
        newQABidEvalSubmitterEditOptions,
        newQAReviewerEditOptions,
        newQASubmitterEditOptions,
        newTaskOwnerEditOptions,
        qaBidEvalStatusEditOptions,
        qaDarkFiberEditOptions,
        qaIssuesEditOptions,
        qaReviewStatusEditOptions,
        serviceTypesMSFilterOptions,
        usacAppStatusMSFilterOptions,
    };

    const editOptionsMapping = {
        ApplicantCohorts: 'applicantCohortMSFilterOptions',
        cfy_form470_afystatus_text: 'form470AFYStatusesMSFilterOptions',
        client_name: 'applicantMSFilterOptions',
        client_primary_contact: 'newApplicationOwnerEditOptions',
        client_secondary_contacts: 'newApplicationOwnerEditOptions',
        erc_form470_app_status_id: 'ercAppStatusEditOptions',
        form470_sr_service_types: 'serviceTypesMSFilterOptions',
        last_updated_by: 'lastUpdatedByMSFilterOptions',
        application_billedentityname: 'billedEntityNameMSFilterOptions',
        qa_bid_eval_review_user_id: 'newQABidEvalReviewerEditOptions',
        qa_bid_eval_status_id: 'qaBidEvalStatusEditOptions',
        qa_bid_eval_submitter_user_id: 'newQABidEvalSubmitterEditOptions',
        qa_darkfiberselfprovisioned_name: 'qaDarkFiberEditOptions',
        qa_issues: 'qaIssuesEditOptions',
        qa_reviewer_user_id: 'newQAReviewerEditOptions',
        qa_review_status_id: 'qaReviewStatusEditOptions',
        qa_submitter_user_id: 'newQASubmitterEditOptions',
        resolved_application_owner: 'newApplicationOwnerEditOptions',
        task_owners: 'newTaskOwnerEditOptions',
        usac_app_status_text: 'usacAppStatusMSFilterOptions',
    };

    const originalWidths = {};
    originalColumns.forEach((ea_col) => {
        originalWidths[ea_col.field] = ea_col.width;
    });
    originalColumnWidthSetter(JSON.stringify(originalWidths));
    originalColumnOrderSetter(JSON.stringify(originalColumns.map((ea_col) => ea_col.field)));

    let col_array = dynamicColumnWidthWrapper(
        // Uncomment for column-to-column filter comparisons
        // IsAfterXDaysWrapper(
        //     IsBeforeXDaysWrapper(
        //         IsNotAnyOfWrapper(
        //             IsAnyOfWrapper(
        //                 IsLessThanOrEqualToColumnWrapper(
        //                     IsLessThanColumnWrapper(
        //                         IsGreaterThanOrEqualToColumnWrapper(
        //                             IsGreaterThanColumnWrapper(
        //                                 IsNotEqualToColumnWrapper(
        //                                     IsEqualToColumnWrapper(
        //                                         doesNotContainWrapper(
        //                                             removeAnyBooleanFilterValueWrapper(originalColumns)
        //                                         )
        //                                     )
        //                                 )
        //                             )
        //                         )
        //                     )
        //                 ),
        //                 editOptions,
        //                 editOptionsMapping
        //             ),
        //             editOptions,
        //             editOptionsMapping
        //         )
        //     )
        // ),

        // IsNotAnyOfWrapper(
        //     IsAnyOfWrapper(
        //         doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns)),
        //         editOptions,
        //         editOptionsMapping
        //     ),
        //     editOptions,
        //     editOptionsMapping
        // ),

        IsNotAnyOfWrapper(
            IsAnyOfWrapper(
                IsLessThanOrEqualToColumnWrapper(
                    IsLessThanColumnWrapper(
                        IsGreaterThanOrEqualToColumnWrapper(
                            IsGreaterThanColumnWrapper(
                                IsNotEqualToColumnWrapper(
                                    IsEqualToColumnWrapper(
                                        doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns))
                                    )
                                )
                            )
                        )
                    )
                ),
                editOptions,
                editOptionsMapping
            ),
            editOptions,
            editOptionsMapping
        ),
        columnWidths
    );
    return col_array;
}

import React from 'react';
//import Form471TrackerMainReport from '../components/TrackerTable.js';
import Form471TrackerApplications from '../components/Form471TrackerAppsTable.js';

function F471Applications() {
    return (
        <>
            {/* <Form471TrackerMainReport /> */}
            <Form471TrackerApplications />
        </>
    );
}

export default F471Applications;

import React from 'react';
import FormControl from '@mui/material/FormControl';
import MultiAutoComplete from '../MultiAutoComplete.js';

// V3
function IsAnyOfInputComponent(props) {
    const { item, applyValue, editOptions, editOptionsMapping } = props;
    const field = item.columnField;

    const editOptionsKey = editOptionsMapping[field];
    const options = editOptionsKey ? editOptions[editOptionsKey] : [];

    const applyValueRef = React.useRef(applyValue);

    React.useEffect(() => {
        applyValueRef.current = applyValue;
    });

    const handleValueChange = (event, newValue) => {
        applyValueRef.current({ ...item, value: newValue }); // grid-filter-item docs
    };

    return (
        <>
            <FormControl fullWidth margin='dense' size='small'>
                <MultiAutoComplete
                    id={`search-${field}`}
                    selectedOptions={item.value || []}
                    options={options ?? []}
                    handleSelectChange={handleValueChange}
                    label={'Filter value'}
                />
            </FormControl>
        </>
    );
}

const applyTransformations = (arr, editOptions) => {
    // console.log(arr, editOptions);
    if (!arr || arr.length === 0) {
        return [];
    }

    let newArr = arr.map((ea_num) => {
        const result = editOptions.find((ea_option) => ea_option['value'] == ea_num);
        // console.log(result);
        return result?.text;
    });
    return newArr;
};

const createIsAnyOfOperator = (editOptions, editOptionsMapping) => ({
    label: 'is any of',
    value: 'isAnyOf',

    // This function must return another function that takes the cell value as an input and return true if it satisfies the operator condition
    getApplyFilterFn: (filterItem, column) => {
        const fieldName = column.field;
        if (
            !fieldName ||
            !filterItem.columnField ||
            !filterItem.operatorValue ||
            filterItem.value === null ||
            filterItem.value === undefined
        ) {
            return null;
        }

        // Determine if filterItem.value is an array or a single value (string)
        if (Array.isArray(filterItem.value)) {
            const editOptionsKey = editOptionsMapping[fieldName];
            const options = editOptionsKey ? editOptions[editOptionsKey] || [] : [];

            if (filterItem.value.length === 0) {
                return () => false;
            }

            let convertedFilterValues = applyTransformations(filterItem.value, options);
            // console.log('createIsAnyOfOperator, getApplyFilterFn --> convertedFilterValues, options = ', convertedFilterValues, options);

            return (params) => {
                if (params.value === undefined || params.value === null) {
                    return false; // Skips filtering for undefined values/doesn't include them
                }

                let newValues = [];
                if (editOptionsKey === 'qaEstimatedFundingRequestEditOptions') {
                    newValues = params.value;
                } else if (
                    editOptionsKey === 'cipaComplianceMSFilterOptions' &&
                    (params.value === '' || params.value === '')
                ) {
                    return () => true;
                } else {
                    newValues = params.value.split(',').map((ea_val) => ea_val.trim());
                }

                // Checking if the selected values in the array match the column's value
                return convertedFilterValues.some((ea_val) => newValues.includes(ea_val));
            };
        } else {
            // Handling single string value
            return (params) => {
                if (params.value === undefined) {
                    return () => false; // Skips filtering for undefined values
                }

                // console.log('filterItem.value, params.value -->', filterItem.value, params.value);

                // Checking if the selected value matches the column's value
                return params.value.includes(filterItem.value);
            };
        }
    },
    // InputComponent: IsAnyOfInputComponent,
    InputComponent: (props) => (
        <IsAnyOfInputComponent {...props} editOptions={editOptions} editOptionsMapping={editOptionsMapping} />
    ),
});

const IsAnyOfWrapper = (columns, editOptions, editOptionsMapping) => {
    // console.log('IsAnyOfWrapper, shouldBeHere --> editOptions = ', editOptions);
    // console.log('IsAnyOfWrapper, shouldBeHere --> editOptionsMapping = ', editOptionsMapping);
    const isAnyOfOperatorV2 = createIsAnyOfOperator(editOptions, editOptionsMapping);

    return columns.map((col) => {
        const columnType = col.type || 'string';

        // Filter out the default 'isAnyOf' operator if the column has an attribute mapping
        if (columnType === 'string' && Object.keys(editOptionsMapping).includes(col.field)) {
            const customOperators = col.filterOperators
                .filter((ea_operator) => ea_operator['value'] !== 'isAnyOf')
                .concat(isAnyOfOperatorV2);

            return {
                ...col,
                filterOperators: customOperators,
            };
        }

        // Or just return the normal operators for this column
        return col;
    });
};

export default IsAnyOfWrapper;

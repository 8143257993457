import React from 'react';

export default function useSessionStorage(key, initialValue) {
    // Uses an existing SS K:P or uses the provided K:P upon function invocation
    const [storedValue, setStoredValue] = React.useState(() => {
        try {
            const ss_key = window.sessionStorage.getItem(key);
            return ss_key ? ss_key : initialValue; // use existing SS item else set a new one
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            window.sessionStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
        setStoredValue(value);
    };

    return [storedValue, setValue];
}

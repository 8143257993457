import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ClientAPI from '../api/ClientAPI.js';
import TrackersAPI from '../api/TrackersAPI.js';
import CreateMinibidDialog from '../components/dialogs/CreateMinibidDialog.js';

export default function TEST_CreateMinibidUI() {
    const clientAPI = new ClientAPI();
    const trackersAPI = new TrackersAPI();

    const [openCMDialog, setOpenCMDialog] = React.useState(false);
    const [cmResult, setCMResult] = React.useState(null);

    const handleCreateMinibidButtonClick = () => {
        setCMResult(null); // reset state
        setOpenCMDialog(true);
    };

    React.useEffect(() => {
        if (cmResult === 'minibid_created') {
            console.log(cmResult);
        } else if (cmResult === 'minibid_creation_errored') {
            console.log(cmResult);
        }
    }, [cmResult]);

    return (
        <Box>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />

            <Button variant='contained' color='primary' onClick={handleCreateMinibidButtonClick}>
                Create Mini-bid
            </Button>
            <CreateMinibidDialog
                open={openCMDialog}
                setOpen={setOpenCMDialog}
                setResult={setCMResult}
                clientAPI={clientAPI}
                trackersAPI={trackersAPI}
            />
        </Box>
    );
}

import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const PromptUpdateOrSaveNewSearchDialog = ({
    isDialogOpen1,
    setIsDialogOpen1,
    landing,
    dialogMessage,
    handleOnlyUpdateSearch,
    handleOnlySaveAsNewSearch1,
    handleCancelButtonClick1,
    showTextField1,
    textFieldValue,
    setTextFieldValue,
    handleKeyDown,
    handleOnlySaveAsNewSearch2,
}) => {
    return (
        <Dialog open={isDialogOpen1} onClose={() => setIsDialogOpen1(false)}>
            <DialogContent>
                {landing && (
                    <>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button onClick={handleOnlyUpdateSearch} color='primary'>
                                Update Search
                            </Button>
                            <Button onClick={handleOnlySaveAsNewSearch1} color='primary' autoFocus>
                                New Search
                            </Button>
                            <Button onClick={handleCancelButtonClick1} color='primary'>
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                )}

                {showTextField1 && (
                    <Box>
                        {'Please enter a name for the new saved search: '}
                        <TextField
                            autoFocus
                            margin='dense'
                            label='Search Name'
                            type='text'
                            fullWidth
                            value={textFieldValue}
                            onChange={(event) => setTextFieldValue(event.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <Box mt={2}>
                            <Button
                                disabled={textFieldValue === ''}
                                onClick={handleOnlySaveAsNewSearch2}
                                color='primary'
                            >
                                OK
                            </Button>
                            <Button onClick={handleCancelButtonClick1} color='primary'>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

import React from 'react';

import ClientAPI from '../api/ClientAPI.js';
import ManageServices from '../components/ManageServices.js';

export default function Services() {
    const clientAPI = new ClientAPI();

    return (
        <>
            <h2>Services</h2>
            <ManageServices clientAPI={clientAPI} />
        </>
    );
}

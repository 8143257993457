import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export const CustomGridToolbarViewSelectorTDG = (props) => {
    const {
        appliesTo,
        currentSavedViews,
        currentUserID,
        defaultFilterPrefs,
        defaultHiddenColumns,
        defaultPinnedColumns,
        defaultSortPrefs,
        editingSavedViewName,
        handleEditSavedViewName,
        handleDeleteSavedView,
        handleSelectSavedView,
        prefs,
        resetViewToCompleteDefaults,
        resetWatcher,
        // selectedViewName,
        setEditingSavedViewName,
        // setSelectedViewName,
        setTextFieldValue,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedViewItem, setSelectedViewItem] = React.useState('');
    const open = Boolean(anchorEl);

    // When the reset column visibility button is clicked or "default" is selected, clear the selected item
    React.useEffect(() => {
        // When resetCounter changes, it indicates resetViewToDefaults was called
        // console.log(
        //     'CustomGridToolbarViewSelector: CustomGridToolbarViewSelectorFN: useEffect for resetWatcher = ',
        //     resetWatcher
        // );
        setSelectedViewItem('');
    }, [resetWatcher]);

    // When prefs.viewNamePrefs changes, it indicates the view name was changed
    // React.useEffect(() => {
    //     console.log(
    //         'CustomGridToolbarViewSelector: CustomGridToolbarViewSelectorFN: prefs.viewNamePrefs = ',
    //         prefs.viewNamePrefs
    //     );
    // }, [prefs.viewNamePrefs]);

    // React.useEffect(() => {
    //     console.log(`The selected view has been changed to: ${selectedViewName}.`);
    //     setSelectedViewItem(selectedViewName);
    // }, [selectedViewName]);

    const isValidJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (error) {
            return false;
        }
        return true;
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleViewSelectionChildComp = (viewName) => {
        // console.log('CustomGridToolbarViewSelector: handleViewSelectionChildCompFN: viewName = ', viewName);
        handleSelectSavedView(viewName);
        setTextFieldValue(viewName);
        prefs.setViewNamePrefs(viewName);
        // setSelectedViewName(viewName);
        setSelectedViewItem(viewName); // because this is handled by the useEffect
        setAnchorEl(null);
    };

    // "Reset" everything to defaults
    const handleDefaultSelectionClick = () => {
        resetViewToCompleteDefaults();
        setAnchorEl(null);
    };

    // Components rendered when the user begins to edit a MenuItem
    const EditableMenuItem = ({ oldEditValue, onSave, onCancel }) => {
        const [newEditValue, setNewEditValue] = React.useState(oldEditValue);

        const handleKeyDown = (event) => {
            // Stops the select component from navigating while typing
            event.stopPropagation();
            if (event.key === 'Enter') {
                onSave(oldEditValue, newEditValue);
            }
        };

        return (
            <MenuItem>
                <TextField
                    value={newEditValue}
                    onChange={(event) => setNewEditValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => onSave(oldEditValue, newEditValue)}>
                    <DoneIcon fontSize='inherit' />
                </IconButton>
                <IconButton onClick={onCancel}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </MenuItem>
        );
    };

    // const defaultSelectedStyling = () => {
    //     if (prefs.viewNamePrefs !== '') return {};

    //     const current_order = JSON.parse(prefs.viewCurrentColumnOrderPrefs);
    //     const current_widths = JSON.parse(prefs.viewCurrentColumnWidthPrefs);

    //     const original_order = prefs.viewOriginalColumnOrderPrefs;
    //     const original_widths = prefs.viewOriginalColumnWidthPrefs;

    //     const newCurrentColumnOrder = current_order.filter((ea_item) => ea_item !== '__detail_panel_toggle__');

    //     // Left are the defaults, right is CURRENT
    //     const isMatch =
    //         'standard' === prefs.densityPrefs &&
    //         defaultFilterPrefs === prefs.filterPrefs &&
    //         defaultHiddenColumns === prefs.hiddenColumnPrefs &&
    //         defaultPinnedColumns === prefs.pinnedColumnPrefs &&
    //         defaultSortPrefs === prefs.sortPrefs &&
    //         (original_order === JSON.stringify(current_order) ||
    //             original_order === JSON.stringify(newCurrentColumnOrder)) &&
    //         original_widths === JSON.stringify(current_widths);

    //     return isMatch
    //         ? { backgroundColor: 'rgba(25, 99, 139, 0.08)', color: 'black' }
    //         : { backgroundColor: '#ffcccc', color: 'black' };
    // };

    // const selectedStyling = (view_name) => {
    //     if (!view_name) return {};
    //     const retrieved_view = currentSavedViews.find((ea_view) => ea_view.view_name === view_name);

    //     if (!retrieved_view || prefs.viewNamePrefs !== view_name) return {};

    //     const {
    //         densityPref,
    //         filterPrefs,
    //         hiddenColumnPrefs,
    //         pinnedColumnPrefs,
    //         sortPrefs,
    //         viewColumnOrderPrefs,
    //         viewColumnWidthPrefs,
    //     } = retrieved_view.parameters.view_filters;

    //     // Left is from the saved view, right is CURRENT
    //     const isMatch =
    //         densityPref === prefs.densityPrefs &&
    //         filterPrefs === prefs.filterPrefs &&
    //         hiddenColumnPrefs === prefs.hiddenColumnPrefs &&
    //         pinnedColumnPrefs === prefs.pinnedColumnPrefs &&
    //         sortPrefs === prefs.sortPrefs &&
    //         ((viewColumnOrderPrefs === prefs.viewCurrentColumnOrderPrefs) || JSON.stringify(viewColumnOrderPrefs) === prefs.viewCurrentColumnOrderPrefs) &&
    //         viewColumnWidthPrefs === prefs.viewCurrentColumnWidthPrefs;

    //     return isMatch
    //         ? { backgroundColor: 'rgba(25, 99, 139, 0.08)', color: 'black' }
    //         : { backgroundColor: '#ffcccc', color: 'black' };
    // };

    return (
        <React.Fragment>
            <Button size='small' startIcon={<VisibilityIcon />} onClick={handleMenuClick}>
                View
            </Button>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'view-button',
                    style: {
                        padding: 0,
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
            >
                <MenuItem value='' onClick={handleDefaultSelectionClick}>
                    Default
                </MenuItem>
                {currentSavedViews.map((ea_view, index) => {
                    if (editingSavedViewName === ea_view.view_name) {
                        return (
                            <EditableMenuItem
                                key={index}
                                oldEditValue={ea_view.view_name}
                                onSave={(oldName, newName) => {
                                    handleEditSavedViewName(oldName, newName);
                                    setEditingSavedViewName(null);
                                }}
                                onCancel={() => {
                                    setEditingSavedViewName(null);
                                }}
                            />
                        );
                    } else {
                        return (
                            <MenuItem
                                key={index}
                                value={ea_view.view_name}
                                // style={selectedStyling(ea_view.view_name)}
                                selected={
                                    (prefs.viewNamePrefs &&
                                        prefs.viewNamePrefs !== '' &&
                                        ea_view.view_name ===
                                            (isValidJSON(prefs.viewNamePrefs)
                                                ? JSON.parse(prefs.viewNamePrefs)
                                                : prefs.viewNamePrefs)) ||
                                    ea_view.view_name === selectedViewItem
                                }
                                // selected={ea_view.view_name === selectedViewName}
                                onClick={() => handleViewSelectionChildComp(ea_view.view_name)}
                            >
                                {ea_view.view_name}
                                {
                                    <>
                                        <Tooltip title='Edit Name'>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setEditingSavedViewName(ea_view.view_name);
                                                }}
                                                size='small'
                                                style={{ marginLeft: '8px' }}
                                            >
                                                <EditIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Delete'>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (ea_view.view_name === selectedViewItem) {
                                                        handleDefaultSelectionClick();
                                                    }
                                                    handleDeleteSavedView(
                                                        ea_view.view_name,
                                                        ea_view.view_id,
                                                        appliesTo,
                                                        currentUserID
                                                    );
                                                }}
                                                size='small'
                                                style={{ marginLeft: '8px' }}
                                            >
                                                <DeleteIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            </MenuItem>
                        );
                    }
                })}
            </Menu>
        </React.Fragment>
    );
};

import React from 'react';

import throttle from 'lodash/throttle';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

export default function Form470AppSearchInOpenData({ applicationNumber, setApplicationNumber }) {
    const [inputValue, setInputValue] = React.useState(''); // Text in the input (autocomplete) box.
    const [value, setValue] = React.useState(null); // The data object of the selected application.
    const [options, setOptions] = React.useState([]); // Array of application data for options list.
    const [suppressAutocompleteSearch, setSuppressAutocompleteSearch] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const setApplicationNumber2 = (selectedOptionData) => {
        // console.log('[setApplicationNumber2] selectedOptionData = ', selectedOptionData);
        if (!selectedOptionData) {
            setApplicationNumber(null);
        } else {
            setApplicationNumber(parseInt(selectedOptionData.application_number));
        }
    };

    //====================================================================================================
    // Form 470, Basic Information dataset
    // https://opendata.usac.org/E-Rate/E-Rate-Open-Competitive-Bidding-Basic-Information-/jp7a-89nd
    // https://dev.socrata.com/foundry/opendata.usac.org/jp7a-89nd
    const DatasetIdentifier_Form470BasicInformation = 'jp7a-89nd';

    const SocrataAppToken = 'W4bEC1KYhL2M90eTmI0d7UwsT';

    const createAPIURLBase = (datasetIdentifier) => {
        return 'https://opendata.usac.org/resource/' + datasetIdentifier + '.json?$$app_token=' + SocrataAppToken;
    };

    async function searchForm470BIDataset(searchText) {
        //console.log('[searchForm470BIDataset] searchText = ' + searchText);

        let url = createAPIURLBase(DatasetIdentifier_Form470BasicInformation);
        if (isNumeric(searchText)) {
            // Application Number or BEN search
            url += '&$where=';
            url += "(starts_with(application_number,'" + encodeURIComponent(searchText) + "')";
            url += " OR starts_with(ben,'" + encodeURIComponent(searchText) + "'))";
        } else {
            // Text search - Nickname or Billed Entity Name
            url += '&$where=';
            url += "(upper(form_nickname) like upper('%25" + encodeURIComponent(searchText) + "%25')";
            url += " OR upper(billed_entity_name) like upper('%25" + encodeURIComponent(searchText) + "%25'))";
        }
        url += " AND form_version='Original'";
        url +=
            '&$select=distinct application_number,form_nickname,funding_year,f470_status,ben,billed_entity_name,applicant_type';
        url += '&$order=application_number DESC';
        url += '&$limit=20';
        //console.log('url = ' + url);

        setLoading(true);
        return fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                if (data.hasOwnProperty('error')) {
                    console.error(
                        'error: ' + data.error + '\r\n' + 'code: ' + data.code + '\r\n' + 'message: ' + data.message
                    );
                    return [];
                }
                //console.log('Success.  data = ', data);
                //console.log('Success.  data.length = ' + data.length);
                return data;
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    }

    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    //====================================================================================================

    React.useEffect(() => {
        if (applicationNumber !== null) {
            // We're starting with an Application Number..
            setSuppressAutocompleteSearch(true);
            let appnumstring = applicationNumber.toString();
            searchForm470BIDataset(appnumstring).then((searchresults) => {
                //console.log('[React.useEffect 1]  searchresults = \r\n', searchresults);
                if (searchresults && searchresults.length > 0) {
                    //console.log("Setting inputValue (to applicationNumber) and value (to searchresults[0])..");
                    setInputValue(appnumstring);
                    setValue(searchresults[0]);
                }
                setSuppressAutocompleteSearch(false);
            });
        }
    }, []);

    const fetchODData = React.useMemo(
        () =>
            throttle((searchText, callback) => {
                //console.log("fetchODData - throttle - searchForm470BIDataset");
                searchForm470BIDataset(searchText).then(callback);
            }, 200),
        []
    );

    React.useEffect(() => {
        //console.log("[React.useEffect 2]  value = ", value);
        //console.log("[React.useEffect 2]  inputValue = ", inputValue);

        let active = true;

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        if (suppressAutocompleteSearch) {
            //console.log("[React.useEffect 2] Suppressing the normal autocomplete search.");
            return undefined;
        }

        fetchODData(inputValue, (searchresults) => {
            //console.log('[React.useEffect 2]  searchresults = \r\n', searchresults);
            if (active) {
                setOptions(searchresults);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetchODData]);

    return (
        <Box sx={{ mt: 1, mb: 1, p: 2, borderRadius: 2, border: '1px solid #E5EAF2', bgcolor: '#F3F6F9' }}>
            <Box sx={{ mb: 2, fontWeight: 'bold' }}>
                Search for a Form 470 application (by Application Number, Nickname, BEN, or Billed Entity Name):
            </Box>
            <Grid container justifyContent='flex-start' alignItems='center'>
                <Grid item xs={7}>
                    <Autocomplete
                        id='appnumsfromoddataset-autocomplete'
                        disabled={suppressAutocompleteSearch}
                        loading={loading}
                        sx={{ bgcolor: '#FFFFFF' }}
                        options={options}
                        getOptionLabel={(option) => option.application_number}
                        filterOptions={(x) => x}
                        value={value}
                        isOptionEqualToValue={(option, value) => {
                            //console.log("isOptionEqualToValue  -  option = ", option);
                            //console.log("isOptionEqualToValue  -  value = ", value);
                            return option.application_number === value.application_number;
                        }}
                        onChange={(event, newValue) => {
                            //console.log("onChange  newValue = ", newValue);
                            setValue(newValue); // newValue is the selected `option` object
                            setApplicationNumber2(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            //console.log("onInputChange  newInputValue = ", newInputValue);
                            setInputValue(newInputValue); // newInputValue is the text in the input box
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label='Application Number, Nickname, BEN, or Billed Entity Name' />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <Form470ODAppInfoBox data={option} />
                                </li>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    {loading && <CircularProgress size={25} sx={{ ml: 2.5 }} />}
                </Grid>
            </Grid>

            {value && (
                <Box sx={{ mt: '0.7em' }}>
                    <Box sx={{ fontSize: '0.8em', mb: '0.5em' }}>Selected Form 470 Application:</Box>
                    <Box>
                        <Form470ODAppInfoTable data={[value]} />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function Form470ODAppInfoBox({ data }) {
    //console.log('[Form470ODAppInfoBox] data = ', data);
    if (data == null) {
        return null;
    }
    return (
        <Grid
            container
            id={'form470odappinfobox_' + data.application_number}
            sx={{
                m: '0.1em',
                p: '0.5rem',
                backgroundColor: '#F8F9FA',
                border: 1,
                borderRadius: 1,
                borderColor: 'grey.500',
            }}
        >
            <Grid item xs={12}>
                <div>
                    <strong>Application Number:</strong> {data.application_number}
                </div>
                <div>
                    <strong>Form Nickname:</strong> {data.form_nickname}
                </div>
                <div>
                    <strong>Funding Year:</strong> {data.funding_year}
                </div>
                <div>
                    <strong>Status:</strong> {data.f470_status}
                </div>
                <div>
                    <strong>BEN:</strong> {data.ben}
                </div>
                <div>
                    <strong>Billed Entity Name:</strong> {data.billed_entity_name}
                </div>
                <div>
                    <strong>Applicant Type:</strong> {data.applicant_type}
                </div>
            </Grid>
        </Grid>
    );
}

function Form470ODAppInfoTable({ data }) {
    //console.log('[Form470ODAppInfoTable]  data: \r\n', data);
    return (
        <TableContainer component={Paper}>
            <Table size='small' sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                color: '#1C6387',
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Application Number
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Form Nickname
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Funding Year
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Status
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            BEN
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Billed Entity Name
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Applicant Type
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.application_number}>
                            <TableCell sx={{ color: '#1C6387', fontWeight: 'bold' }}>
                                {row.application_number}
                            </TableCell>
                            <TableCell>{row.form_nickname}</TableCell>
                            <TableCell>{row.funding_year}</TableCell>
                            <TableCell>{row.f470_status}</TableCell>
                            <TableCell>{row.ben}</TableCell>
                            <TableCell>{row.billed_entity_name}</TableCell>
                            <TableCell>{row.applicant_type}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import React from 'react';
import Form470TrackerAppsTable from '../components/Form470TrackerAppsTable.js';

function F470Applications() {
    return (
        <>
            <Form470TrackerAppsTable />
        </>
    );
}

export default F470Applications;

import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// To manage cohorts assignment to clients. Table: 'applicantcohort'
export default function ManageCohorts({ clientAPI }) {
    // Tabs
    const [tabValue, setTabValue] = React.useState(0);

    // Tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // For Cohorts table
    const [originalCohortsRows, setOriginalCohortsRows] = React.useState([]);
    const [cohortsRows, setCohortsRows] = React.useState([]);
    const [cohortsSearchTerm, setCohortsSearchTerm] = React.useState('');
    const [cohortsPage, setCohortsPage] = React.useState(0);

    // For Edit/Update Cohort
    const [cohortId, setCohortId] = React.useState(-1);
    const [cohortName, setCohortName] = React.useState('');
    const resetFormState = () => {
        setCohortId(-1);
        setCohortName('');
    };
    const [cohortSubmitted, setCohortSubmitted] = React.useState(false);

    const fetchCohorts = async () => {
        let cohorts = await clientAPI.GetApplicantCohorts();
        //console.log('cohorts:', cohorts);
        setOriginalCohortsRows(cohorts.sort((a, b) => (a.applicantcohort_name > b.applicantcohort_name ? 1 : -1)));
        setCohortsRows(cohorts.sort((a, b) => (a.applicantcohort_name > b.applicantcohort_name ? 1 : -1)));
    };

    React.useEffect(() => {
        fetchCohorts();
    }, []);

    // Cohorts Table
    const handleCohortsTextFilter = (event) => {
        setCohortsSearchTerm(event.target.value);
        filterCohortsTable(event.target.value);
    };

    const filterCohortsTable = (cohortsSearchTerm) => {
        setCohortsPage(0);
        setCohortsRows(
            originalCohortsRows.filter((row) =>
                row.applicantcohort_name.toLowerCase().includes(cohortsSearchTerm.toLowerCase())
            )
        );
    };

    // Save Cohort
    async function submitCohort() {
        try {
            setCohortSubmitted(true);

            // Validation
            if (cohortName === '') {
                toast.error('Please fill in cohort name');
                return;
            }

            // Save to database
            let cohortInfo = {
                applicantcohort_id: cohortId,
                applicantcohort_name: cohortName,
            };
            await clientAPI.SaveApplicantCohort(cohortInfo);

            let successMessage = cohortId === -1 ? 'Successfully created cohort' : 'Successfully updated cohort';
            toast.success(successMessage);

            // Cleanup
            resetFormState();
            setCohortSubmitted(false);

            // Return to list and refresh list
            setTabValue(0);
            fetchCohorts();
        } catch (error) {
            console.error('[submitCohort] error:', error);
            toast.error('Error saving Cohort');
        }
    }

    async function showEditCohort(cohort_id, cohortsRow) {
        setCohortId(cohortsRow.applicantcohort_id);
        setCohortName(cohortsRow.applicantcohort_name);
        setTabValue(1);
    }

    return (
        <>
            <ToastContainer theme='colored' />
            <Box sx={{ width: '90%', margin: '0 auto' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label='tabs'>
                        <Tab label='Manage Master Cohorts List' {...a11yProps(0)} />
                        <Tab label='Add Cohorts' {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <Box>
                        <TextField
                            label='Filter Cohorts'
                            margin='normal'
                            variant='outlined'
                            onChange={handleCohortsTextFilter}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label='Cohorts Table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cohortsRows.map((row, i) => {
                                    return (
                                        <TableRow key={row.applicantcohort_id}>
                                            <TableCell>{row.applicantcohort_id}</TableCell>
                                            <TableCell>{row.applicantcohort_name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => showEditCohort(row.applicantcohort_id, row)}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Container component='main' maxWidth='xs'>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component='h1' variant='h5'>
                                {cohortId === -1 ? 'Create Cohort' : 'Edit  Cohort'}
                            </Typography>
                            <Box>
                                <TextField
                                    label=' Cohort Name'
                                    value={cohortName}
                                    error={cohortSubmitted && cohortName === ''}
                                    helperText={cohortSubmitted && cohortName === '' ? ' Cohort Name is required' : ''}
                                    onChange={(event) => setCohortName(event.target.value)}
                                    required={true}
                                    variant='outlined'
                                    fullWidth={true}
                                    margin='normal'
                                />

                                <Button
                                    onClick={submitCohort}
                                    fullWidth={true}
                                    variant='contained'
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {cohortId === -1 ? 'Create  Cohort' : 'Update  Cohort'}
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </TabPanel>
            </Box>
        </>
    );
}

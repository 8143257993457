import React from 'react';
import {
    useGridApiContext,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnDefinitionsSelector,
} from '@mui/x-data-grid-pro';
import MenuItem from '@mui/material/MenuItem';
import { CustomGridToolbarDensitySelector } from './CustomGridToolbarDensitySelector';
import { CustomGridToolbarViewSelectorTDG } from './CustomGridToolbarViewSelectorTDG';
import { toast } from 'react-toastify';

export function CustomDatagridToolbarTDG({
    appliesTo,
    currentSavedViews,
    currentUserID,
    defaultFilterPrefs,
    defaultHiddenColumns,
    defaultPinnedColumns,
    defaultSortPrefs,
    editingSavedViewName,
    handleDeleteSavedView,
    handleDensityChange,
    handleEditSavedViewName,
    handleSelectSavedView,
    prefs,
    viewNameGetter,
    viewNameSetter,
    resetViewToCompleteDefaults,
    resetWatcher,
    // selectedViewName,
    setCurrentColumnOrder,
    setCurrentColumnWidths,
    setEditingSavedViewName,
    // setSelectedViewName,
    setTextFieldValue,
}) {
    return (
        <GridToolbarContainer>
            <CustomGridToolbarViewSelectorTDG
                appliesTo={appliesTo}
                currentSavedViews={currentSavedViews}
                currentUserID={currentUserID}
                defaultFilterPrefs={defaultFilterPrefs}
                defaultHiddenColumns={defaultHiddenColumns}
                defaultPinnedColumns={defaultPinnedColumns}
                defaultSortPrefs={defaultSortPrefs}
                editingSavedViewName={editingSavedViewName}
                handleDeleteSavedView={handleDeleteSavedView}
                handleEditSavedViewName={handleEditSavedViewName}
                handleSelectSavedView={handleSelectSavedView}
                prefs={prefs}
                resetViewToCompleteDefaults={resetViewToCompleteDefaults}
                resetWatcher={resetWatcher}
                // selectedViewName={selectedViewName}
                setCurrentColumnOrder={setCurrentColumnOrder}
                setCurrentColumnWidths={setCurrentColumnWidths}
                setEditingSavedViewName={setEditingSavedViewName}
                // setSelectedViewName={setSelectedViewName}
                setTextFieldValue={setTextFieldValue}
                viewNameGetter={viewNameGetter}
                viewNameSetter={viewNameSetter}
            />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomGridToolbarDensitySelector onDensityChange={handleDensityChange} />
            <CustomExportButton />
            <GridToolbarQuickFilter
                sx={{ marginLeft: 'auto', paddingRight: '4px' }}
                debounceMs={500}
                placeholder={'Quick filter...'}
            />
        </GridToolbarContainer>
    );
}

export default CustomDatagridToolbarTDG;

export function CustomExportButton() {
    const csvOptions = { delimiter: ',' };
    return (
        <GridToolbarExportContainer>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem />
            <ClipboardExportMenuItem />
        </GridToolbarExportContainer>
    );
}

export const ClipboardExportMenuItem = (props) => {
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    // const copyToClipboard = (apiRef) => {
    //     let clipboardOutput = '';
    //     const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    //     const visibleColumnsField = gridVisibleColumnDefinitionsSelector(apiRef);

    //     visibleColumnsField.forEach((fieldObj) => {
    //         let field = fieldObj.field;

    //         if (field !== '__detail_panel_toggle__' && field !== 'details_and_edit') {
    //             clipboardOutput = clipboardOutput.concat(fieldObj.headerName, '\t');
    //         }
    //     });
    //     clipboardOutput = clipboardOutput.concat('\n');

    //     filteredSortedRowIds.forEach((id) => {
    //         visibleColumnsField.forEach((fieldObj) => {
    //             let field = fieldObj.field;
    //             if (field !== '__detail_panel_toggle__' && field !== 'details_and_edit') {
    //                 let rawValue = apiRef.current.getCellParams(id, field).value;

    //                 if (rawValue === undefined || rawValue == null) {
    //                     rawValue = '';
    //                 }

    //                 let parsedValue = rawValue.toString().replace(/[\t\n\r\n]/gm, ' ');
    //                 clipboardOutput = clipboardOutput.concat(parsedValue, '\t');
    //             }
    //         });

    //         clipboardOutput = clipboardOutput.concat('\n');
    //     });

    //     navigator.clipboard.writeText(clipboardOutput);
    // };

    const copyToClipboard = (apiRef) => {
        let clipboardOutput = '';

        // Get the IDs of the selected rows
        const selectedRowIds = Array.from(apiRef.current.getSelectedRows().keys());
        const hasSelectedRows = selectedRowIds.length > 0;

        // Determine which rows to use
        const rowIdsToCopy = hasSelectedRows ? selectedRowIds : gridFilteredSortedRowIdsSelector(apiRef);
        const rowIdCount = rowIdsToCopy.length;
        const visibleColumnsField = gridVisibleColumnDefinitionsSelector(apiRef);

        visibleColumnsField.forEach((fieldObj) => {
            let field = fieldObj.field;
            if (field !== '__detail_panel_toggle__' && field !== 'details_and_edit') {
                clipboardOutput = clipboardOutput.concat(fieldObj.headerName, '\t');
            }
        });
        clipboardOutput = clipboardOutput.concat('\n');

        rowIdsToCopy.forEach((id) => {
            visibleColumnsField.forEach((fieldObj) => {
                let field = fieldObj.field;
                if (field !== '__detail_panel_toggle__' && field !== 'details_and_edit') {
                    let rawValue = apiRef.current.getCellParams(id, field).value;

                    if (rawValue === undefined || rawValue == null) {
                        rawValue = '';
                    }

                    let parsedValue = rawValue.toString().replace(/[\t\n\r\n]/gm, ' ');
                    clipboardOutput = clipboardOutput.concat(parsedValue, '\t');
                }
            });

            clipboardOutput = clipboardOutput.concat('\n');
        });

        navigator.clipboard
            .writeText(clipboardOutput) // Returns a promise
            .then(() => {
                toast.success(`Copied (${rowIdCount}) row${rowIdCount > 1 ? 's' : ''} to clipboard!`, {
                    autoClose: 3000,
                });
            })
            .catch((e) => {
                toast.error(`Failed to copy (${rowIdCount}) rows to clipboard!`);
            });
    };

    return (
        <MenuItem
            onClick={() => {
                copyToClipboard(apiRef);
                hideMenu?.();
            }}
        >
            Copy to Clipboard
        </MenuItem>
    );
};

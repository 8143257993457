import React from 'react';
import { useGridApiContext, GRID_STRING_COL_DEF } from '@mui/x-data-grid-pro';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export function InlineEditTextarea1(props) {
    const { id, field, value, colDef } = props;
    const [valueState, setValueState] = React.useState(value);
    const [popperOpen, setPopperOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null); // Anchor for the Popper.
    const [popperInputEl, setPopperInputEl] = React.useState(null); // The inputRef of InputBase.
    const shiftTabOutRef = React.useRef(); // ref to the "shift+tab out" button
    const tabOutRef = React.useRef(); // ref to the "tab out" button
    const apiRef = useGridApiContext();

    const handleAnchorRef = React.useCallback((el) => {
        setAnchorEl(el);
    }, []);

    const handlePopperInputRef = React.useCallback((el) => {
        // console.log('[handlePopperInputRef]');
        setPopperInputEl(el);
    }, []);

    React.useEffect(() => {
        // console.log('[React.useEffect]');
        // console.log('[React.useEffect] popperOpen = ');
        // console.log(popperOpen);
        // console.log('[React.useEffect] popperInputEl = ');
        // console.log(popperInputEl);
        if (popperInputEl) {
            popperInputEl.focus();
            popperInputEl.selectionStart = popperInputEl.value.length;
        }
    }, [popperInputEl]);

    const handleTextFieldFocus = () => {
        // Show the popper when the text field gets focus.
        // This triggers a re-render, 'popperInputEl' gets a value via handlePopperInputRef, and then 'React.useEffect' puts the focus on the textarea.
        //console.log('[handleTextFieldFocus] popperOpen is currently ' + popperOpen + '.  Setting it to true..');
        setPopperOpen(true);
    };

    const handleTOButtonFocus = () => {
        //console.log('[handleTOButtonFocus] popperOpen is ' + popperOpen);
    };
    const handleSTOButtonFocus = () => {
        //console.log('[handleSTOButtonFocus] popperOpen is ' + popperOpen);
    };

    const handleKeyDown = React.useCallback(
        (event) => {
            //console.log('event.ctrlKey = ' + event.ctrlKey);
            //console.log('event.key = ' + event.key);
            if (event.key === 'Escape') {
                // Exit edit mode. Discard changes.
                apiRef.current.stopRowEditMode({ id: id, ignoreModifications: true });
            } else if (event.ctrlKey === true && event.key === 'Enter') {
                // Ctrl + Enter
                // Exit edit mode. Changes are kept/saved.
                apiRef.current.stopRowEditMode({ id: id });
            } else if (event.shiftKey === true && event.key === 'Tab') {
                // Shift + Tab
                // Close the popper and go to the previous entry field (by setting focus to the "shift+tab out" button).
                // console.log('[handleKeyDown] Shift + Tab');
                setPopperOpen(false);
                shiftTabOutRef.current.focus();
            } else if (event.key === 'Tab') {
                // Tab
                // Close the popper and go to the next entry field (by setting focus to the "tab out" button).
                // console.log('[handleKeyDown] Tab');
                // console.log('[handleKeyDown] tabOutRef = ');
                // console.log(tabOutRef);
                // console.log(tabOutRef.current);
                setPopperOpen(false);
                tabOutRef.current.focus();
            }
        },
        [apiRef, id]
    );

    const handleBlur = React.useCallback((event) => {
        // Hide the popper when user clicks away.
        //console.log('[handleBlur] popperOpen is currently ' + popperOpen + '.  Setting it to false..');
        setPopperOpen(false);
    }, []);

    const handleChange = React.useCallback(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
        },
        [apiRef, field, id]
    );

    return (
        <Box
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={handleAnchorRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />

            {popperOpen && (
                <Button
                    ref={shiftTabOutRef}
                    onFocus={handleSTOButtonFocus}
                    tabIndex={-1}
                    sx={{ width: 0, minWidth: 0, padding: 0, flexBasis: 0, flexGrow: 0 }}
                />
            )}

            <TextField
                value={valueState}
                InputProps={{ readOnly: true }}
                onFocus={handleTextFieldFocus}
                sx={{ flexBasis: '100%', flexGrow: 1 }}
            ></TextField>

            {popperOpen && (
                <Button
                    ref={tabOutRef}
                    onFocus={handleTOButtonFocus}
                    tabIndex={-1}
                    sx={{ width: 0, minWidth: 0, padding: 0, flexBasis: 0, flexGrow: 0 }}
                />
            )}

            <Popper open={popperOpen} anchorEl={anchorEl} placement='bottom-start'>
                <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
                    <InputBase
                        multiline
                        rows={10}
                        value={valueState}
                        sx={{ textarea: { resize: 'both' }, width: '100%' }}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputRef={handlePopperInputRef}
                    />
                </Paper>
            </Popper>
        </Box>
    );
}

export const isKeyboardEvent = (event) => {
    return !!event.key;
};

/*
export function InlineEditTextareaA(props) {
    const { id, field, value, colDef } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [popperEl, setPopperEl] = React.useState(null);
    //const [tabOutEl, setTabOutEl] = React.useState(null);
    const apiRef = useGridApiContext();
    const tabOutRef = React.useRef();

    const handleRef = React.useCallback((el) => {
        setAnchorEl(el);
    }, []);

    const handlePopperRef = React.useCallback((el) => {
        setPopperEl(el);
    }, []);

    React.useEffect(() => {
        console.log(popperEl);
        if (popperEl) {
            popperEl.focus();
            popperEl.selectionStart = popperEl.value.length;
        }
    }, [popperEl]);

    const handleKeyDown = React.useCallback(
        (event) => {
            console.log('event.ctrlKey = ' + event.ctrlKey);
            console.log('event.key = ' + event.key);
            if (event.key === 'Escape') {
                // Exit edit mode. Discard changes.
                apiRef.current.stopRowEditMode({ id: id, ignoreModifications: true });
            }
            if (event.ctrlKey === true && event.key === 'Enter') {
                // Ctrl+Enter
                // Exit edit mode. Changes are kept/saved.
                apiRef.current.stopRowEditMode({ id: id });
            }
            if (event.key === 'Tab') {
                // Tab
                // Exit Popper View, Return focus to inline component in notes cell.
                setExpanded(false);
                tabOutRef.current.focus();
            }
        },
        [apiRef, id]
    );

    const handleChange = React.useCallback(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
        },
        [apiRef, field, id]
    );

    const handleTabIn = () => {
        setExpanded(true);
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />

            {!expanded && (
                <Box>
                    <TextField
                        onFocus={handleTabIn}
                        value={valueState}
                        sx={{ flexBasis: '100%', flexGrow: 1 }}
                    ></TextField>
                </Box>
            )}

            <Button ref={tabOutRef} sx={{ color: 'white', flexBasis: '1px', flexGrow: 0 }}></Button>

            <Popper open={expanded} anchorEl={anchorEl} placement='bottom-start'>
                <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
                    <InputBase
                        multiline
                        rows={10}
                        value={valueState}
                        sx={{ textarea: { resize: 'both' }, width: '100%' }}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        inputRef={handlePopperRef}
                    />
                </Paper>
            </Popper>
        </Box>
    );
}
*/

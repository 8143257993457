import React from 'react';
import Box from '@mui/material/Box';
import { Storage } from 'aws-amplify';
import { CircularProgress } from '@mui/material';

function DeadlineReport() {
    const [reportUrl, setReportUrl] = React.useState('');
    React.useEffect(() => {
        const fetchReport = async () => {
            // List all keys, filtering out folder markers (size 0)
            let list = await Storage.list('reports/deadline/');
            list = list.filter((item) => item.size > 0);
            if (list.length > 0) {
                let s3Result = await Storage.get(list[0].key);
                setReportUrl(s3Result);
            }
        };
        fetchReport();
    }, []);

    if (reportUrl) {
        return (
            <Box height='calc(100vh - 125px)'>
                <object data={reportUrl} type='application/pdf' width='100%' height='100%'>
                    <p>
                        <a href={reportUrl} target='_blank' rel='noreferrer'>
                            Download
                        </a>
                    </p>
                </object>
            </Box>
        );
    } else {
        return (
            <Box height='50vh' display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Box>
        );
    }
}

export default DeadlineReport;

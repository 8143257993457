import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const PromptUpdateOrSaveNewViewDialog = ({
    isDialogOpen1,
    setIsDialogOpen1,
    landing,
    dialogMessage,
    handleOnlyUpdateView,
    handleOnlySaveAsNewView1,
    handleCancelButtonClick1,
    showTextField1,
    textFieldValue,
    setTextFieldValue,
    handleKeyDown,
    handleOnlySaveAsNewView2,
}) => {
    return (
        <Dialog open={isDialogOpen1} onClose={() => setIsDialogOpen1(false)}>
            <DialogContent>
                {landing && (
                    <>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button onClick={handleOnlyUpdateView} color='primary'>
                                Update View
                            </Button>
                            <Button onClick={handleOnlySaveAsNewView1} color='primary' autoFocus>
                                New View
                            </Button>
                            <Button onClick={handleCancelButtonClick1} color='primary'>
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                )}

                {showTextField1 && (
                    <Box>
                        {'Please enter a name for the new saved view: '}
                        <TextField
                            autoFocus
                            margin='dense'
                            label='View Name'
                            type='text'
                            fullWidth
                            value={textFieldValue}
                            onChange={(event) => setTextFieldValue(event.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <Box mt={2}>
                            <Button disabled={textFieldValue === ''} onClick={handleOnlySaveAsNewView2} color='primary'>
                                OK
                            </Button>
                            <Button onClick={handleCancelButtonClick1} color='primary'>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

import React from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ClientListSearchForBen from '../ClientListSearchForBEN.js';
import Form470AppSearchInOpenData from '../Form470AppSearchInOpenData.js';

export default function CreateMinibidDialog({ open, setOpen, setResult, clientAPI, trackersAPI }) {
    // result can be "cancelled", "minibid_created", or "minibid_creation_errored".

    const endfy = new Date().getFullYear() + 1;

    const [ben, setBen] = React.useState(null);
    const [applicationNumber, setApplicationNumber] = React.useState(null);
    const [fundingYear, setFundingYear] = React.useState(endfy);
    const [createButtonDisabled, setCreateButtonDisabled] = React.useState(true);

    const createFundingYearsArray = () => {
        let arr = [];
        for (let fy = endfy; fy >= 2016; fy--) {
            arr.push(fy);
        }
        return arr;
    };
    const fundingYearsArray = createFundingYearsArray();

    // React.useEffect(() => {
    //     console.log("open is now: " + open);
    //     console.log("    ben = ", ben);
    //     console.log("    applicationNumber = ", applicationNumber);
    //     console.log("    fundingYear = ", fundingYear);
    // }, [open]);

    // Enable/disable the "Create Mini-bid" button based on UED presence
    React.useEffect(() => {
        if (open === true) {
            if (ben !== null && applicationNumber !== null && fundingYear !== null) {
                // console.log("We have a ben, applicationNumber, and fundingYear.");
                // console.log("    ben = ", ben);
                // console.log("    applicationNumber = ", applicationNumber);
                // console.log("    fundingYear = ", fundingYear);

                // Enable the "Create Mini-bid" button once we have values.
                setCreateButtonDisabled(false);
            } else {
                setCreateButtonDisabled(true);
            }
        }
    }, [ben, applicationNumber, fundingYear]);

    const handleClose = (event, reason) => {
        //console.log('[handleClose]');

        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // If they searched and selected values then confirm they want to exit.
        if (ben !== null || applicationNumber !== null) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        setBen(null);
        setApplicationNumber(null);
        setFundingYear(endfy);

        if (setResult instanceof Function) {
            setResult('cancelled');
        }
        setOpen(false);
    };

    const createMinibid = async () => {
        //console.log('[createMinibid]');

        // Disable "Create Mini-bid" button (to prevent multiple clicks)
        setCreateButtonDisabled(true);

        // Check to make sure we have valid ben, applicationNumber, and fundingYear
        // This is just in case.  The "create" button should have been disabled.
        if (ben === null || applicationNumber === null || fundingYear === null) {
            alert('BEN, Application Number, and Funding Year are needed to create the mini-bid.');
            return;
        }

        // Send ben, applicationNumber, and fundingYear to an API that creates the Mini-bid
        const toastId = toast.info('Creating the mini-bid..'); // return a toast string | int
        let minibidIds = { clientBEN: ben, applicationNumber: applicationNumber, fundingYear: fundingYear };
        let apiResponse = await trackersAPI.CreateForm470Minibid(minibidIds);

        console.log('apiResponse = ', apiResponse);

        if (!apiResponse) {
            toast.dismiss(toastId);
            toast.error('Error occurred in mini-bid creation.', {
                autoClose: 3000,
            });
            console.error('apiResponse = ', apiResponse);
            setResult('minibid_creation_errored');
            return;
        }
        if (apiResponse.error) {
            toast.dismiss(toastId);
            toast.error('Error occurred in mini-bid creation.', {
                autoClose: 3000,
            });
            if (setResult instanceof Function) {
                setResult('minibid_creation_errored');
            }
            if (apiResponse.error === true) {
                console.error('Error occurred in mini-bid creation: \r\n', apiResponse.errorMessage);
            } else {
                console.error('Error occurred in mini-bid creation: \r\n', apiResponse.error.message);
            }
            return;
        }

        toast.dismiss(toastId);
        toast.success('Mini-bid created.', {
            autoClose: 3000,
        });

        // Reset the state
        setBen(null);
        setApplicationNumber(null);
        setFundingYear(endfy);

        // Return success and close the model dialog box
        if (setResult instanceof Function) {
            setResult('minibid_created');
        }
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle sx={{ fontSize: '1.5em' }}>Create Mini-Bid</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2 }}>
                    In order to create a mini-bid we need 3 pieces of information: Client BEN, Form 470 Application
                    Number, and Funding Year.
                </Box>
                <ClientListSearchForBen clientAPI={clientAPI} ben={ben} setBen={setBen} />
                <Form470AppSearchInOpenData
                    applicationNumber={applicationNumber}
                    setApplicationNumber={setApplicationNumber}
                />
                <Box sx={{ mt: 1, mb: 1, p: 2, borderRadius: 2, border: '1px solid #E5EAF2', bgcolor: '#F3F6F9' }}>
                    <Box sx={{ mb: 2, fontWeight: 'bold' }}>Create Mini-Bid for Funding Year:</Box>
                    <Select
                        sx={{ bgcolor: 'background.paper' }}
                        value={fundingYear}
                        onChange={(event) => setFundingYear(event.target.value)}
                    >
                        {fundingYearsArray.map((fy) => (
                            <MenuItem key={fy} value={fy}>
                                {fy}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {/*
                <Box>
                    <br />
                    <div>BEN: {ben}</div>
                    <div>Application Number: {applicationNumber}</div>
                    <div>Funding Year: {fundingYear}</div>
                </Box>
                 */}
            </DialogContent>
            <DialogActions sx={{ p: 1 }}>
                <Button
                    id='createMinibidButton'
                    variant='contained'
                    color='primary'
                    sx={{ m: 1 }}
                    disabled={createButtonDisabled}
                    onClick={() => createMinibid()}
                >
                    Create Mini-bid
                </Button>
                <Button sx={{ m: 1 }} onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// For managing the list of services available to assign to clients. Table: 'service'
export default function ManageServices({ clientAPI }) {
    // Tabs
    const [tabValue, setTabValue] = React.useState(0);

    // Tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // For Services table
    const [originalServicesRows, setOriginalServicesRows] = React.useState([]);
    const [servicesRows, setServicesRows] = React.useState([]);
    const [selectedServiceGroup, setSelectedServiceGroup] = React.useState('');
    const [servicesSearchTerm, setServicesSearchTerm] = React.useState('');
    const [groups, setGroups] = React.useState([]); // For "Groups" dropdown filter

    // For Edit/Update Service
    const [serviceId, setServiceId] = React.useState(-1);
    const [serviceName, setServiceName] = React.useState('');
    const [serviceGroup, setServiceGroup] = React.useState('');
    const resetFormState = () => {
        setServiceId(-1);
        setServiceName('');
        setServiceGroup('');
    };
    const [serviceSubmitted, setServiceSubmitted] = React.useState(false);

    const fetchServices = async () => {
        let services = await clientAPI.GetServices();
        //console.log('services:', services);
        setOriginalServicesRows(services);
        setServicesRows(services);
    };

    React.useEffect(() => {
        //TODO: When creating or editing a service, user can select a group that was used on another service (for consistency) or create a new one.  Autocomplete, Free solo.

        const fetchGroups = async () => {
            let groups = await clientAPI.GetServiceGroups(); // Distinct list of all Service.service_grouping ordered alphabetically.
            //console.log('groups:', groups);
            setGroups(groups);
        };

        fetchServices();
        fetchGroups();
    }, []);

    // Service Table
    const handleServicesTextFilter = (event) => {
        setServicesSearchTerm(event.target.value);
        filterServicesTable(event.target.value, selectedServiceGroup);
    };

    // Service Table
    const handleServiceGroupFilter = (event) => {
        setSelectedServiceGroup(event.target.value);
        filterServicesTable(servicesSearchTerm, event.target.value);
    };

    const filterServicesTable = (servicesSearchTerm, serviceGroup) => {
        setServicesRows(
            originalServicesRows.filter(
                (row) =>
                    row.service_name.toLowerCase().includes(servicesSearchTerm.toLowerCase()) &&
                    (serviceGroup === '' || row.service_grouping === serviceGroup)
            )
        );
    };

    // Save Service
    async function submitService() {
        try {
            setServiceSubmitted(true);

            // Validation
            if (serviceName === '' || serviceGroup === '') {
                toast.error('Please complete all required fields');
                return;
            }

            // Save to database
            let serviceInfo = {
                service_id: serviceId,
                service_name: serviceName,
                service_grouping: serviceGroup,
            };
            await clientAPI.SaveService(serviceInfo);

            let successMessage = serviceId === -1 ? 'Successfully created service' : 'Successfully updated service';
            toast.success(successMessage);

            // Cleanup
            resetFormState();
            setServiceSubmitted(false);

            // Return to list and refresh list
            setTabValue(0);
            fetchServices();
        } catch (error) {
            console.error('[submitService] error:', error);
            toast.error('Error saving service');
        }
    }

    async function showEditService(service_id, serviceRow) {
        setServiceId(serviceRow.service_id);
        setServiceName(serviceRow.service_name);
        setServiceGroup(serviceRow.service_grouping);
        setTabValue(1);
    }

    return (
        <>
            <ToastContainer theme='colored' />
            <Box sx={{ width: '90%', margin: '0 auto' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label='tabs'>
                        <Tab label='Manage Master Services List' {...a11yProps(0)} />
                        <Tab label='Add Service' {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <Box>
                        <TextField
                            label='Filter Services'
                            margin='normal'
                            variant='outlined'
                            onChange={handleServicesTextFilter}
                        />
                        <FormControl margin='normal' sx={{ minWidth: 240, marginLeft: '25px' }}>
                            <InputLabel id='service-group-filter-label'>Filter by Group</InputLabel>
                            <Select
                                label='Service Group'
                                labelId='service-group-filter-label'
                                id='service-group-filter'
                                value={selectedServiceGroup}
                                onChange={handleServiceGroupFilter}
                                defaultValue=''
                            >
                                <MenuItem value=''>No Filter</MenuItem>
                                {groups.map((row, i) => {
                                    return (
                                        <MenuItem value={row} key={row}>
                                            {row}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label='Services Table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Group</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {servicesRows.map((row, i) => {
                                    return (
                                        <TableRow key={row.service_id}>
                                            <TableCell>{row.service_id}</TableCell>
                                            <TableCell>{row.service_name}</TableCell>
                                            <TableCell>{row.service_grouping}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => showEditService(row.service_id, row)}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Container component='main' maxWidth='xs'>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component='h1' variant='h5'>
                                {serviceId === -1 ? 'Create Service' : 'Edit Service'}
                            </Typography>
                            <Box>
                                <TextField
                                    label='Service Name'
                                    value={serviceName}
                                    error={serviceSubmitted && serviceName === ''}
                                    helperText={
                                        serviceSubmitted && serviceName === '' ? 'Service Name is required' : ''
                                    }
                                    onChange={(event) => setServiceName(event.target.value)}
                                    required={true}
                                    variant='outlined'
                                    fullWidth={true}
                                    margin='normal'
                                />
                                <TextField
                                    label='Service Group'
                                    value={serviceGroup}
                                    onChange={(event) => setServiceGroup(event.target.value)}
                                    variant='outlined'
                                    fullWidth={true}
                                    margin='normal'
                                />

                                <Button
                                    onClick={submitService}
                                    fullWidth={true}
                                    variant='contained'
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {serviceId === -1 ? 'Create Service' : 'Update Service'}
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </TabPanel>
            </Box>
        </>
    );
}

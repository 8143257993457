import React from 'react';
import { useParams } from 'react-router-dom';

import ClientDetails from '../components/ClientDetails.js';
import ClientCreateEdit from '../components/ClientCreateEdit.js';
import ClientAPI from '../api/ClientAPI.js';
import UserAPI from '../api/UserAPI.js';

export default function ClientDetailsPage() {
    const clientAPI = new ClientAPI();
    const userAPI = new UserAPI();
    let params = useParams();

    const [displayMode, setDisplayMode] = React.useState('');

    function handleEditButtonClick() {
        setDisplayMode('edit');
    }

    function handleBackToDetails() {
        setDisplayMode('details');
    }

    if (displayMode === 'edit') {
        return (
            <ClientCreateEdit
                mode='edit'
                clientId={params.clientId}
                clientAPI={clientAPI}
                userAPI={userAPI}
                sourcePageName='Details'
                //handleReturnToSourcePage={handleBackToDetails}
            />
        );
    } else {
        return (
            <ClientDetails
                clientId={params.clientId}
                clientAPI={clientAPI}
                userAPI={userAPI}
                //handleEditButtonClick={handleEditButtonClick}
            />
        );
    }
}

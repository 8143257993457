import React from 'react';
import { Typography } from '@mui/material';
import F471TrackerApplicantTable from '../components/F471TrackerApplicantTable.js';

function F471Applicants() {
    return (
        <>
            <h2 style={{ marginTop: 0, marginBottom: '16px' }}>
                Applicants (with/without Form 470/Form 471 Applications)
            </h2>
            <F471TrackerApplicantTable />
        </>
    );
}

export default F471Applicants;

import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function LedgerRecordsTable(props) {
    const clientAPI = props.api;
    const entityID = props.entityID;
    const entityType = props.entityType;

    const [entityHistory, setEntityHistory] = React.useState([]);

    async function loadHistory() {
        let hist = await clientAPI.GetLedgerRecordsForEntity(entityID, entityType);
        //console.log(hist);
        setEntityHistory(hist);
    }

    return (
        <Box>
            <Typography variant='h3'>Revision History</Typography>
            <Button variant='contained' onClick={loadHistory} size='small'>
                Show History
            </Button>
            <TableContainer>
                <Table sx={{ width: '50%', margin: '0 auto' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Attribute</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>By</TableCell>
                            <TableCell>On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entityHistory.map((eh) => (
                            <TableRow
                                key={eh.attribute_name + '_' + eh.changed_by + '_' + eh.changed_on}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{eh.attribute_name}</TableCell>
                                <TableCell>{eh.before_value}</TableCell>
                                <TableCell>{eh.after_value}</TableCell>
                                <TableCell>{eh.editor.first_name + ' ' + eh.editor.last_name}</TableCell>
                                <TableCell>{eh.changed_on}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default LedgerRecordsTable;

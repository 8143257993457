import * as React from 'react';
import './App.css';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useAuthContext } from './useAuthContext.js';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import UserManagement from './pages/UserManagement';
import ProfilePage from './pages/ProfilePage';
import UserSettings from './pages/UserSettings';
import ClientList from './pages/ClientList';
import ClientDetailsPage from './pages/ClientDetailsPage';
import ClientContactSearchReportPage from './pages/ClientContactSearchReportPage.js';
import ClientFundingSummaryPage from './pages/ClientFundingSummaryPage';
import ClientFundingYearDetailsPage from './pages/ClientFundingYearDetailsPage';
import ClientFundingYearFormDetailsPage from './pages/ClientFundingYearFormDetailsPage.js';
import ClientFundingHistoryDetailsECFPage from './pages/ClientFundingHistoryDetailsECFPage.js';
import ClientEditPage from './pages/ClientEditPage';
import Services from './pages/Services';
import ClientServices from './pages/ClientServices';
import F470ApplyForDiscounts from './pages/F470ApplyForDiscounts';
import F471ApplyForDiscounts from './pages/F471ApplyForDiscounts';
/*import CompetitiveBidding from './pages/CompetitiveBidding';
import PostCommitment from './pages/PostCommitment';
import PreparedReporting from './pages/PreparedReporting';
import DynamicReporting from './pages/DynamicReporting';*/
import LandingPage from './pages/LandingPage';
import Cohorts from './pages/Cohorts';
import Form470Applications from './pages/F470Applications';
import F471Applications from './pages/F471Applications';
//import F471Applications2 from './pages/F471Applications2';
import F470TAppDetailsPage from './pages/F470TAppDetails';
import F471TAppDetailsPage from './pages/F471TAppDetails';
import F470TAppEditPage from './pages/F470TAppEdit';
import F471TAppEditPage from './pages/F471TAppEdit';
import F471Stats from './pages/F471Stats';
import F471Applicants from './pages/F471Applicants';
import UsacDataRetrievalDatetimes from './pages/UsacDataRetrievalDatetimes';
import SystemSettings from './pages/SystemSettings';
import UsacDataStats from './pages/UsacDataStats';
import GlobalSearchResult from './pages/GlobalSearchResult';
import Notifications from './pages/Notifications';
import GeneralEmbeddedDashboard from './pages/GeneralEmbeddedDashboard';

import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import DeadlineReport from './pages/DeadlineReport';

import TEST_CreateMinibidUI from './pages/TEST_CreateMinibidUI';

const drawerWidth = 240;
const theme = createTheme({
    palette: {
        primary: {
            main: '#19638B',
        },
        focus: {
            main: '#248EC7',
        },
        secondary: {
            main: '#F6F6F6',
        },
        dark: {
            main: '#707070',
            contrastText: '#FFFFFF',
        },
        accent: {
            main: '#93CD40',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        color: '#413c40',
        h1: {
            color: '#19638B',
            fontSize: '2rem',
            fontWeight: 600,
        },
        h2: {
            color: '#011340',
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        h3: {
            color: '#011340',
            fontSize: '1.375rem',
            fontWeight: 300,
        },
        h4: {
            color: '#333333',
            fontSize: '1.125rem',
            fontWeight: 500,
        },
        h5: {
            color: '#333333',
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        h6: {
            color: '#333333',
            fontSize: '0.625rem',
            fontWeight: 500,
        },
        p: {
            color: '#413C40',
            fontSize: '1rem',
            fontWeight: 400,
        },
        a: {
            color: '#028BFF',
            fontSize: '1rem',
            fontWeight: 400,
        },
    },
});

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawer',
})(({ theme, open, drawer }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(drawer && { marginLeft: `-${drawerWidth}px` }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function App() {
    LicenseInfo.setLicenseKey(
        'e8a9b99da68b790a2534e3d1ed17563bTz04MTU2OCxFPTE3MzYwMDkxNTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
    );

    const [open, setOpen] = React.useState(false);
    let auth = useAuthContext();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    {auth.isSignedIn && (
                        <Navbar open={open} handleOpen={handleDrawerOpen} handleClose={handleDrawerClose} />
                    )}
                    <Main open={open} drawer={auth.isSignedIn}>
                        <Backdrop
                            sx={{
                                color: '#fff',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={auth.loading}
                        >
                            <CircularProgress />
                        </Backdrop>
                        <DrawerHeader />
                        <Routes>
                            <Route path='/login' element={<Login />} />
                            <Route
                                exact
                                path='/'
                                element={
                                    <RequireAuth>
                                        <LandingPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/profile'
                                element={
                                    <RequireAuth>
                                        <ProfilePage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/manage-users'
                                element={
                                    <RequireAuth>
                                        <UserManagement />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/user-settings'
                                element={
                                    <RequireAuth>
                                        <UserSettings />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/system-settings'
                                element={
                                    <RequireAuth>
                                        <SystemSettings />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/system-settings/usac-data-stats'
                                element={
                                    <RequireAuth>
                                        <UsacDataStats />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list'
                                element={
                                    <RequireAuth>
                                        <ClientList />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/details/:clientId'
                                element={
                                    <RequireAuth>
                                        <ClientDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            {/* <Route
                                path='/client-list/funding-summary/:clientId/:kyList/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingSummaryPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/funding-summary/:clientId/:kyList/:spin/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingSummaryPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/contact-search-report/:clientId/:kyList/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientContactSearchReportPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/form-details/:clientId/:kyList/:fundingYear/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingYearFormDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/funding-year-details/:clientId/:kyList/:fundingYear/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingYearDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/funding-year-details/:clientId/:kyList/:fundingYear/:state/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingYearDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/funding-year-details/:clientId/:kyList/:fundingYear/:f471appnum/:state/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingYearDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/funding-history-details/:clientId/:kyList/:program/:searchType'
                                element={
                                    <RequireAuth>
                                        <ClientFundingHistoryDetailsECFPage />
                                    </RequireAuth>
                                }
                            /> */}
                            <Route
                                path='/client-list/edit/:clientId'
                                element={
                                    <RequireAuth>
                                        <ClientEditPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/services'
                                element={
                                    <RequireAuth>
                                        <Services />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/cohorts'
                                element={
                                    <RequireAuth>
                                        <Cohorts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/client-list/client-services/:clientId'
                                element={
                                    <RequireAuth>
                                        <ClientServices />
                                    </RequireAuth>
                                }
                            />
                            {/*<Route
                                path='/competitive-bidding'
                                element={
                                    <RequireAuth>
                                        <CompetitiveBidding />
                                    </RequireAuth>
                                }
                            />*/}
                            <Route
                                path='/f471'
                                element={
                                    <RequireAuth>
                                        <F471ApplyForDiscounts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f471/f471-applications'
                                element={
                                    <RequireAuth>
                                        <F471Applications />
                                    </RequireAuth>
                                }
                            />
                            {/* <Route
                                path='/f471/f471-applications-testbed'
                                element={
                                    <RequireAuth>
                                        <F471Applications2 />
                                    </RequireAuth>
                                }
                            /> */}
                            <Route
                                path='/f471/f471-applications/:ben'
                                element={
                                    <RequireAuth>
                                        <F471Applications />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f471/f471-applications/details/:form471AppNum'
                                element={
                                    <RequireAuth>
                                        <F471TAppDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f471/f471-applications/edit/:form471AppNum'
                                element={
                                    <RequireAuth>
                                        <F471TAppEditPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f471/f471-stats'
                                element={
                                    <RequireAuth>
                                        <F471Stats />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f470/'
                                element={
                                    <RequireAuth>
                                        <F470ApplyForDiscounts />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f470/f470-applications'
                                element={
                                    <RequireAuth>
                                        <Form470Applications />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f470/f470-applications/details/:form470AppNum/:form470AppIncrement'
                                element={
                                    <RequireAuth>
                                        <F470TAppDetailsPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/f470/f470-applications/edit/:form470AppNum/:form470AppIncrement'
                                element={
                                    <RequireAuth>
                                        <F470TAppEditPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/usacdataretrievaldatetimes'
                                element={
                                    <RequireAuth>
                                        <UsacDataRetrievalDatetimes />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/reports'
                                element={
                                    <RequireAuth>
                                        <LandingPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/reports/deadline-report'
                                element={
                                    <RequireAuth>
                                        <DeadlineReport />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/reports/applicants'
                                element={
                                    <RequireAuth>
                                        <F471Applicants />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/global-search'
                                element={
                                    <RequireAuth>
                                        <GlobalSearchResult />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/embedded-dashboard'
                                element={
                                    <RequireAuth>
                                        <GeneralEmbeddedDashboard />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/notifications'
                                element={
                                    <RequireAuth>
                                        <Notifications />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/TEST_CreateMinibidUI'
                                element={
                                    <RequireAuth>
                                        <TEST_CreateMinibidUI />
                                    </RequireAuth>
                                }
                            />
                            {/*<Route
                                path='/post-commitment'
                                element={
                                    <RequireAuth>
                                        <PostCommitment />
                                    </RequireAuth>
                                }
                            />*/}
                            {/*<Route
                                path='/prepared-reporting'
                                element={
                                    <RequireAuth>
                                        <PreparedReporting />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path='/dynamic-reporting'
                                element={
                                    <RequireAuth>
                                        <DynamicReporting />
                                    </RequireAuth>
                                }
                            />*/}
                        </Routes>
                    </Main>
                </Router>
            </ThemeProvider>
        </Box>
    );
}

function RequireAuth({ children }) {
    let auth = useAuthContext();
    let location = useLocation();

    if (auth.loading) {
        // For intial page load - if auth is still loading, return an empty container
        return <div></div>;
    }
    if (!auth.isSignedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return children;
}

export default App;

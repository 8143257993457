import React from 'react';
import { useContext } from 'react';

import { DataGridPro, useGridApiRef, gridColumnFieldsSelector } from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { TDGPrefsContext } from './TDGPrefsContext';
import CustomDatagridToolbarTDG from './CustomDatagridToolbarTDG.js';

export function TrackerDataGrid({
    apiRef,
    appliesTo,
    columnsArray,
    currentColumnOrder,
    currentColumnWidths,
    currentSavedViews, // Needed so the CustomToolbar can loop through all of the data in it, filled in the AppsTable
    currentUserID,
    customColumnVisibilityButtonsConfig, // Optional.
    customSx, // Optional.
    dataIsLoading, // boolean
    defaultFilterPrefs,
    defaultHiddenColumns,
    defaultPinnedColumns,
    defaultSortPrefs,
    editingSavedViewName,
    getDetailPanelContent, // Optional.
    getDetailPanelHeight, // Optional.
    getRowClassName, // Optional.
    getRowId,
    handleBulkEditButtonClicked,
    handleEditSavedViewName,
    handleDeleteSavedView,
    handleProcessRowUpdateError,
    handleRowModesModelChange,
    handleSaveView,
    onCreateMinibidButtonClick, // Optional. On click function for the Form 470 "Create Mini-bid" button.
    processRowUpdate,
    reportData,
    resetWatcher,
    rowModesModel,
    // selectedViewName,
    selectionModel,
    setBulkEditButtonClicked,
    setCurrentColumnOrder,
    setCurrentColumnWidths,
    setEditingSavedViewName,
    setResetWatcher,
    // setSelectedViewName,
    setSelectionModel,
    setTextFieldValue,
    showCreateMinibidButton, // Optional. Displays the Form 470 "Create Mini-bid" button.
    usedCacheOnLoad, // boolean
}) {
    //----- Display customization and preferences -----
    const [pageSize, setPageSize] = React.useState(2000);
    const prefs = useContext(TDGPrefsContext);
    const [columnResetCount, setColumnResetCount] = React.useState(0); // Just need a state value that changes when the columns are reset.
    const defaultSavedViewDGBResetRef = React.createRef();
    //################################### SAVED VIEWS #############################################
    const viewNameGetter = prefs.viewNamePrefs;
    const viewNameSetter = prefs.setViewNamePrefs;

    // Used when saving/updating a saved view
    const createViewsParametersObject = () => {
        return {
            filterPrefs: prefs.filterPrefs,
            sortPrefs: prefs.sortPrefs,
            densityPref: prefs.densityPrefs,
            hiddenColumnPrefs: prefs.hiddenColumnPrefs,
            pinnedColumnPrefs: prefs.pinnedColumnPrefs,
            viewColumnWidthPrefs: prefs.viewCurrentColumnWidthPrefs,
            viewColumnOrderPrefs: prefs.viewCurrentColumnOrderPrefs,
        };
    };

    // Saves SFVs to session & local storage as cedF471TApplicationsViewPrefs1
    const saveViewFieldValuesJSONToStorage = (json) => {
        prefs.setViewPrefs(json);
    };

    const handleSaveViewButtonClicked = () => {
        let vp_obj = createViewsParametersObject();
        handleSaveView(vp_obj);
        saveViewFieldValuesJSONToStorage(JSON.stringify(vp_obj));
    };

    // Attached to Reset Column Visibility.
    const resetViewToDefaults = () => {
        const originalWidths = prefs.viewOriginalColumnWidthPrefs;

        prefs.resetFilterPrefs();
        prefs.resetDensityPrefToDefault();
        prefs.resetColumnVisibility();
        prefs.resetSortPrefs();
        setCurrentColumnWidths(JSON.parse(originalWidths));
        prefs.setCurrentSavedViewColumnWidthPrefs(originalWidths);
    };

    const triggerDGBResets = () => {
        if (defaultSavedViewDGBResetRef.current) {
            defaultSavedViewDGBResetRef.current.resetAllCVB();
            defaultSavedViewDGBResetRef.current.resetToFixedRowHeight();
        }
    };
    // Completely wipes all things related to a view, executed when selecting a new view and on "default" view selection
    const resetViewToCompleteDefaults = () => {
        const originalWidths = prefs.viewOriginalColumnWidthPrefs;
        const originalOrder = prefs.viewOriginalColumnOrderPrefs;

        setTextFieldValue('');
        prefs.resetPinnedColumnsPrefs();
        prefs.resetViewPrefs();
        prefs.resetFilterPrefs();
        prefs.resetDensityPrefToDefault();
        prefs.resetColumnVisibility();
        prefs.resetSortPrefs();
        prefs.resetViewNamePrefs();

        setCurrentColumnWidths(JSON.parse(originalWidths));
        setCurrentColumnOrder(JSON.parse(originalOrder));

        prefs.setCurrentSavedViewColumnWidthPrefs(originalWidths);
        prefs.setCurrentSavedViewColumnOrderPrefs(originalOrder);

        triggerDGBResets();
        setResetWatcher((prev) => prev + 1);
    };

    // Called when a view has been selected via handleSelectSavedViewFN
    const setViewFieldValues = (parsedVP) => {
        // console.log('TrackerDataGrid: setViewFieldValuesFN: parsedVP = ', parsedVP);
        resetViewToCompleteDefaults();

        // Fill in view values with real data
        try {
            if (parsedVP.viewName) {
                prefs.setViewNamePrefs(parsedVP.viewName);
                // setSelectedViewName(parsedVP.viewName);
            }
            if (parsedVP.filterPrefs != null) {
                prefs.setFilterPrefs(parsedVP.filterPrefs);
            }
            if (parsedVP.sortPrefs != null) {
                prefs.setSortPrefs(parsedVP.sortPrefs);
            }
            if (parsedVP.densityPref != null) {
                prefs.setDensityPref(parsedVP.densityPref);
            }
            if (parsedVP.hiddenColumnPrefs != null) {
                prefs.setHiddenColumnPrefs(parsedVP.hiddenColumnPrefs);
            }
            if (parsedVP.pinnedColumnPrefs != null) {
                prefs.setPinnedColumnPrefs(parsedVP.pinnedColumnPrefs);
            }
            if (parsedVP.viewColumnWidthPrefs != null) {
                prefs.setCurrentSavedViewColumnWidthPrefs(parsedVP.viewColumnWidthPrefs);
                setCurrentColumnWidths(JSON.parse(parsedVP.viewColumnWidthPrefs));
            }
            if (parsedVP.viewColumnOrderPrefs != null) {
                const colOrderFromStorage = parsedVP.viewColumnOrderPrefs;
                prefs.setCurrentSavedViewColumnOrderPrefs(colOrderFromStorage);
            }
        } catch (error) {
            console.error('TrackerDataGrid: setViewFieldValuesFN: error = ', error);
            // toast.error(error);
            return false;
        }
    };

    const handleSelectSavedView = (view_name) => {
        // setTextFieldValue(view_name);
        const retrieved_view = currentSavedViews.find((ea_view) => ea_view.view_name === view_name);

        // Applies the correct filters to the DGP and sets the filters to storage
        if (retrieved_view) {
            const retrieved_filters = retrieved_view.parameters.view_filters;
            setViewFieldValues(retrieved_filters);
            saveViewFieldValuesJSONToStorage(JSON.stringify(retrieved_filters));
            // setSelectedViewName(view_name);
        } else {
            return;
        }
    };
    //################################### END SAVED VIEWS #############################################

    //----- DataGridPro customization and preferences handlers -----
    const FIXED_ROW_HEIGHT = 38;
    const getRowHeight = React.useCallback(
        ({ densityFactor }) => {
            if (prefs.rowHeightPref === 'auto') {
                return 'auto';
            } else {
                return FIXED_ROW_HEIGHT * densityFactor; // 0.7, 1, or 1.3
            }
        },
        [prefs.rowHeightPref]
    );

    const handleDensityChange = (newDensity) => {
        prefs.setDensityPref(newDensity);
    };

    // Doesn't handle Date --> DateTime column switching
    // async function handleOnFilterModelChange(newFilterModel) {
    //     prefs.setFilterPrefs(JSON.stringify(newFilterModel));
    // };

    async function handleOnFilterModelChange(newFilterModel) {
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
        const updatedItems = newFilterModel.items.map((filter) => {
            if (filter.columnField === 'last_updated_on') {
                if (!dateTimeRegex.test(filter.value)) {
                    return {
                        ...filter,
                        value: '', // set to an empty string instead of bug that keeps the previous date's value
                    };
                }
            }
            return filter;
        });

        const updatedFilterModel = {
            ...newFilterModel,
            items: updatedItems,
        };

        prefs.setFilterPrefs(JSON.stringify(updatedFilterModel));
    }

    function handleOnSortModelChange(newSortModel) {
        prefs.setSortPrefs(JSON.stringify(newSortModel));
    }

    // newSelectionModel is a list of the rowIDs (470 = appNum + appInc, 471 = appNum)
    function handleSelectionModelChange(newSelectionModel) {
        setSelectionModel(newSelectionModel);
    }

    function handleOnColumnVisibilityModelChange(newVizModel) {
        const defaultColumns = JSON.parse(defaultHiddenColumns);

        // Needed for the red highlight
        Object.keys(newVizModel).forEach((ea_key) => {
            // Check if the key is not in defaultColumns and its value is true
            if (!defaultColumns.hasOwnProperty(ea_key) && newVizModel[ea_key] === true) {
                delete newVizModel[ea_key];
            }
        });

        prefs.setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    function handlePinnedColumnsChange(newPinModel) {
        prefs.setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    // Changes the CURRENT column ORDERS to SS, LS, and state
    const handleOnColumnOrderChange = React.useCallback(() => {
        let columnfields = gridColumnFieldsSelector(apiRef.current.state);
        setCurrentColumnOrder(columnfields);
        prefs.setCurrentSavedViewColumnOrderPrefs(JSON.stringify(columnfields));
    }, [apiRef]);

    // Changes the CURRENT column WIDTHS to SS, LS, and state
    const handleOnColumnWidthChange = React.useCallback(
        (event) => {
            const allColumns = apiRef.current.getAllColumns();
            const updatedWidths = allColumns.reduce((accumulator, current_col) => {
                accumulator[current_col.field] = current_col.width;
                return accumulator;
            }, {});

            setCurrentColumnWidths(updatedWidths);
            prefs.setCurrentSavedViewColumnWidthPrefs(JSON.stringify(updatedWidths));
        },
        [apiRef]
    );

    // React.useEffect(() => {
    //     prefs.setCurrentSavedViewColumnOrderPrefs(JSON.stringify(currentColumnOrder));
    //     prefs.setCurrentSavedViewColumnWidthPrefs(JSON.stringify(currentColumnWidths));
    // }, [currentColumnOrder, currentColumnWidths]);

    //----- TrackerDataGridButtons click handlers -----
    const handleToggleRowHeightButtonClick = (newRowHeightPref) => {
        prefs.setRowHeightPref(newRowHeightPref);
    };

    const handleResetColumnOrderButtonClick = () => {
        const originalOrder = prefs.viewOriginalColumnOrderPrefs;
        prefs.resetPinnedColumnsPrefs();
        setCurrentColumnOrder(JSON.parse(originalOrder));
        prefs.setCurrentSavedViewColumnOrderPrefs(originalOrder);
        setColumnResetCount((prevCount) => prevCount + 1);
    };

    const handleClearAllFiltersButtonClick = () => {
        prefs.resetFilterPrefs();
    };

    const handleColumnVisibilityButtonClick = (action, columns) => {
        if (action === 'reset_column_visibility') {
            prefs.resetColumnVisibility();
        } else if (action === 'show_all_columns') {
            prefs.showAllColumns();
        } else if (action === 'hide' && columns) {
            prefs.updateColumnVisibility(columns, false);
        } else if (action === 'show' && columns) {
            prefs.updateColumnVisibility(columns, true);
        }
    };

    // const reorderColumns = (columnsArray, currentOrder) => {
    //     if (!currentOrder) return columnsArray;
    //     const orderedColumns = currentOrder
    //         .map((field) => columnsArray.find((col) => col.field === field))
    //         .filter((col) => col !== undefined);
    //     return orderedColumns.concat(columnsArray.filter((col) => !currentOrder.includes(col.field))); // fixes columns not showing upon first login
    // };

    const applyColumnOrderAndWidths = (columnsArray, currentOrder, currentWidths) => {
        if (!currentOrder) return columnsArray;

        const orderedAndSizedColumns = currentOrder
            .map((field) => {
                const foundColumn = columnsArray.find((col) => col.field === field);

                // Apply the width from the preferences if it exists
                if (foundColumn && currentWidths && currentWidths[foundColumn.field]) {
                    return { ...foundColumn, width: currentWidths[foundColumn.field] };
                }
                return foundColumn;
            })
            .filter((col) => col !== undefined);

        // Add any columns not included in the current order
        const remainingColumns = columnsArray.filter((col) => !currentOrder.includes(col.field));
        return orderedAndSizedColumns.concat(remainingColumns);
    };

    const createColumnsArrayV2 = React.useCallback(() => {
        try {
            // Get the current column order from storage and parse it
            let currentColumnOrderPrefsJSON = prefs.viewCurrentColumnOrderPrefs;
            let currentColumnWidthPrefsJSON = prefs.viewCurrentColumnWidthPrefs;
            let currentOrder, currentWidths;

            if (currentColumnOrderPrefsJSON) {
                currentOrder = JSON.parse(currentColumnOrderPrefsJSON);
            }

            if (currentColumnWidthPrefsJSON) {
                currentWidths = JSON.parse(currentColumnWidthPrefsJSON);
            }

            return applyColumnOrderAndWidths(columnsArray, currentOrder, currentWidths);
        } catch (error) {
            console.error('Error creating a sorted columns array: ', error);
        }
        return columnsArray;
    }, [columnsArray, currentColumnOrder, currentColumnWidths]);

    //----- Columns -----
    // const createColumnsArrayV2 = React.useCallback(() => {
    //     // console.log('[ createColumnsArray ] columnResetCount = ' + columnResetCount);
    //     // console.log('[ createColumnsArray ] columnsAreResetting = ' + columnsAreResetting);
    //     // console.log("ENTERING THE createColumnsArray FUNCTION");
    //     try {
    //         let columnorderprefsJSON = prefs.columnOrderPref;
    //         if (columnorderprefsJSON !== null) {
    //             let col_order = JSON.parse(columnorderprefsJSON);
    //             if (col_order) {
    //                 // console.log('[ createColumnsArray ] Using the saved (in local storage) column order.  ' + new Date().toISOString());
    //                 let sorted_cols = columnsArray.toSorted(function (a, b) {
    //                     let indexA = col_order.findIndex((element) => element === a.field);
    //                     let indexB = col_order.findIndex((element) => element === b.field);
    //                     return indexA - indexB;
    //                 });
    //                 //consoleLogColumnsArrayFields('sorted_cols', sorted_cols);
    //                 // console.log("THE SORTED COLUMNS:");
    //                 // console.log(sorted_cols);
    //                 return sorted_cols;
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error creating a sorted columns array: ', error);
    //     }
    //     // consoleLogColumnsArrayFields('columnsArray', columnsArray);
    //     // console.log('[ createColumnsArray ] Using the original column order.  ' + new Date().toISOString());
    //     return columnsArray;
    //     //};
    // }, [columnsArray, columnResetCount, columnsAreResetting]);

    // const consoleLogColumnsArrayFields = (varname, columnsArray) => {
    //     let fields = '';
    //     for (let i = 0; i < columnsArray.length; i++) {
    //         fields += columnsArray[i].field + ', ';
    //     }
    //     console.log(varname + ' fields = ' + fields);
    // };

    //----- Display -----
    if (reportData === undefined) {
        return <h3>ERROR retrieving data.</h3>;
    }

    return (
        <>
            <Box sx={{ paddingBottom: '4px' }}>
                <TrackerDataGridButtons
                    ref={defaultSavedViewDGBResetRef}
                    customColumnVisibilityButtonsConfig={customColumnVisibilityButtonsConfig}
                    initialRowHeightPref={prefs.rowHeightPref}
                    onToggleRowHeightButtonClick={handleToggleRowHeightButtonClick}
                    onResetColumnOrderButtonClick={handleResetColumnOrderButtonClick}
                    onClearAllFiltersButtonClick={handleClearAllFiltersButtonClick}
                    handleBulkEditButtonClicked={handleBulkEditButtonClicked}
                    onColumnVisibilityButtonClick={handleColumnVisibilityButtonClick}
                    handleSaveViewButtonClicked={handleSaveViewButtonClicked}
                    resetViewToDefaults={resetViewToDefaults}
                    selectionModel={selectionModel}
                    setBulkEditButtonClicked={setBulkEditButtonClicked}
                    viewNameGetter={viewNameGetter}
                    // showCreateMinibidButton={showCreateMinibidButton}
                    // onCreateMinibidButtonClick={onCreateMinibidButtonClick}
                />
                {usedCacheOnLoad && (
                    <>
                        <CircularProgress sx={{ marginLeft: '25px' }} size='.75rem' />
                        <Typography variant='span' sx={{ color: 'gray', marginLeft: '10px' }}>
                            <em>Most recent data loading - Inline editing temporarily disabled</em>
                        </Typography>
                    </>
                )}
                {showCreateMinibidButton === true && (
                    <Button
                        variant='contained'
                        size='small'
                        color='info'
                        onClick={() => onCreateMinibidButtonClick()}
                        sx={{ float: 'right' }}
                    >
                        Create Mini-bid
                    </Button>
                )}
            </Box>
            <DataGridPro
                checkboxSelection
                disableSelectionOnClick
                className='trackerReportDataGrid'
                columns={createColumnsArrayV2()}
                rows={reportData}
                getRowId={getRowId}
                getRowClassName={getRowClassName}
                apiRef={apiRef}
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                loading={dataIsLoading && reportData.length === 0}
                components={{
                    Toolbar: CustomDatagridToolbarTDG,
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            display='flex'
                            justifyContent='flex-start'
                            flexDirection='column'
                            alignItems='center'
                            paddingTop='5%'
                        >
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Your search returned no records.</div>
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Please adjust the Search criteria.</div>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            display='flex'
                            justifyContent='flex-start'
                            flexDirection='column'
                            alignItems='center'
                            paddingTop='5%'
                        >
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>No records matched your filters.</div>
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>Please adjust the DataGrid filters.</div>
                        </Stack>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                        appliesTo,
                        currentSavedViews,
                        currentUserID,
                        defaultFilterPrefs,
                        defaultHiddenColumns,
                        defaultPinnedColumns,
                        defaultSortPrefs,
                        editingSavedViewName,
                        handleDeleteSavedView,
                        handleDensityChange,
                        handleEditSavedViewName,
                        handleSelectSavedView,
                        prefs,
                        viewNameGetter,
                        viewNameSetter,
                        resetViewToCompleteDefaults,
                        resetWatcher,
                        // selectedViewName,
                        setCurrentColumnOrder,
                        setCurrentColumnWidths,
                        setEditingSavedViewName,
                        // setSelectedViewName,
                        setTextFieldValue,
                    },
                }}
                editMode='row'
                isCellEditable={() => !usedCacheOnLoad}
                density={prefs.densityPrefs}
                getRowHeight={getRowHeight}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                pagination
                rowsPerPageOptions={[25, 50, 100, 250, 500, 1000, 2000]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                filterModel={JSON.parse(prefs.filterPrefs)}
                onFilterModelChange={async (newFilterModel) => await handleOnFilterModelChange(newFilterModel)}
                onSelectionModelChange={(newSelectionModel) => handleSelectionModelChange(newSelectionModel)}
                sortModel={JSON.parse(prefs.sortPrefs)}
                onSortModelChange={(newSortModel) => handleOnSortModelChange(newSortModel)}
                columnVisibilityModel={JSON.parse(prefs.hiddenColumnPrefs)}
                onColumnVisibilityModelChange={(newVizModel) => handleOnColumnVisibilityModelChange(newVizModel)}
                pinnedColumns={JSON.parse(prefs.pinnedColumnPrefs)}
                onPinnedColumnsChange={(newPinnedModel) => handlePinnedColumnsChange(newPinnedModel)}
                onColumnWidthChange={handleOnColumnWidthChange}
                onColumnOrderChange={handleOnColumnOrderChange}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                sx={{
                    minHeight: '300px',
                    height: '75vh',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: '1px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '10px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                        py: '20px',
                    },
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell': {
                        border: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                        backgroundColor: '#fdfffe',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n)': {
                        backgroundColor: '#f0f2f4',
                    },
                    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                        backgroundColor: '#fdfffe',
                    },
                    ...customSx,
                }}
            />
        </>
    );
}

export const TrackerDataGridButtons = React.forwardRef(
    (
        {
            customColumnVisibilityButtonsConfig, // Array of configuration info. Optional.
            handleBulkEditButtonClicked,
            handleSaveViewButtonClicked,
            initialRowHeightPref, // 'fixed' or 'auto'
            onClearAllFiltersButtonClick,
            onColumnVisibilityButtonClick, // onColumnVisibilityButtonClick(action, columns)
            onToggleRowHeightButtonClick, // onToggleRowHeightButtonClick('fixed'|'auto')
            onResetColumnOrderButtonClick,
            resetViewToDefaults,
            selectionModel,
            setBulkEditButtonClicked,
            viewNameGetter,
            // showCreateMinibidButton,
            // onCreateMinibidButtonClick,
        },
        defaultSavedViewDGBResetRef
    ) => {
        // EXAMPLE customColumnVisibilityButtonsConfig INPUT:
        //      const customColumnVisibilityButtonsConfig = [{
        //          showText: 'Show ABC Columns',
        //          hideText: 'Hide ABC Columns',
        //          initialAction: 'hide',
        //          columns: ['column_A','column_B']
        //      }]

        // Allows reset to complete defaults to trigger the DGBs to their defaults
        React.useImperativeHandle(defaultSavedViewDGBResetRef, () => ({
            resetAllCVB,
            resetToFixedRowHeight,
        }));

        const createInitialCVBDataArray = () => {
            if (customColumnVisibilityButtonsConfig && Array.isArray(customColumnVisibilityButtonsConfig)) {
                return customColumnVisibilityButtonsConfig.map((c) => {
                    return {
                        ...c,
                        currentAction: c.initialAction,
                        currentText: c.initialAction === 'show' ? c.showText : c.hideText,
                    };
                });
            }
            return null;
        };
        const [cvbData, setCVBData] = React.useState(createInitialCVBDataArray);
        // EXAMPLE cvbData:
        //      [
        //          {
        //              showText: 'Show ABC Columns',
        //              hideText: 'Hide ABC Columns',
        //              initialAction: 'hide',
        //              columns: ['column_A','column_B'],
        //              currentAction: 'show',
        //              currentText: 'Show ABC Columns'
        //          }
        //      ]
        const toggleOneCVB = (index) => {
            if (cvbData) {
                let newAry = cvbData.map((d, i) => {
                    if (i === index) {
                        if (d.currentAction === 'show') {
                            return {
                                ...d,
                                currentAction: 'hide',
                                currentText: d.hideText,
                            };
                        } else {
                            return {
                                ...d,
                                currentAction: 'show',
                                currentText: d.showText,
                            };
                        }
                    }
                    return d;
                });
                setCVBData(newAry);
            }
        };
        const resetAllCVB = () => {
            if (cvbData) {
                let newAry = cvbData.map((d) => {
                    return {
                        ...d,
                        currentAction: d.initialAction,
                        currentText: d.initialAction === 'show' ? d.showText : d.hideText,
                    };
                });
                setCVBData(newAry);
            }
        };
        const setAllCVBToHideState = () => {
            if (cvbData) {
                let newAry = cvbData.map((d) => {
                    return {
                        ...d,
                        currentAction: 'hide',
                        currentText: d.hideText,
                    };
                });
                setCVBData(newAry);
            }
        };

        const resetToFixedRowHeight = () => {
            onToggleRowHeightButtonClick('fixed');
            setRowHeightButtonText('Fixed Row Height');
        };

        const determineInitialRowHeightButtonText = () => {
            return initialRowHeightPref === 'fixed' ? 'Fixed Row Height' : 'Auto Row Height';
        };
        const [rowHeightButtonText, setRowHeightButtonText] = React.useState(determineInitialRowHeightButtonText);

        const handleToggleRowHeightButtonClick = () => {
            if (rowHeightButtonText === 'Auto Row Height') {
                // toggle to fixed row height
                onToggleRowHeightButtonClick('fixed');
                setRowHeightButtonText('Fixed Row Height');
            } else {
                // toggle to auto row height
                onToggleRowHeightButtonClick('auto');
                setRowHeightButtonText('Auto Row Height');
            }
        };

        const handleResetColumnOrderButtonClick = () => {
            onResetColumnOrderButtonClick();
        };

        const handleResetColumnVisibilityButtonClick = () => {
            onColumnVisibilityButtonClick('reset_column_visibility');
            resetAllCVB(); // Reset the custom column visibility buttons to their initial states.
        };

        const handleShowAllColumnsButtonClick = () => {
            onColumnVisibilityButtonClick('show_all_columns');
            setAllCVBToHideState(); // Set all the custom column visibility buttons to the "Hide" state.
        };

        const handleClearAllFiltersButtonClick = () => {
            onClearAllFiltersButtonClick();
        };

        return (
            <>
                <Button
                    variant='contained'
                    size='small'
                    disabled={selectionModel.length < 2}
                    color='info'
                    sx={{ marginRight: '1em' }}
                    // sx={{ marginRight: '1em', backgroundColor: '#4CAF50' }}
                    onClick={() => setBulkEditButtonClicked(true)}
                >
                    {selectionModel.length > 0 ? `Bulk Edit (${selectionModel.length})` : 'Bulk Edit'}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    sx={{ marginRight: '1em', backgroundColor: '#4CAF50' }}
                    onClick={() => handleSaveViewButtonClicked()}
                >
                    {viewNameGetter ? 'Update View' : 'Save View'}
                </Button>
                {/* Loop through cvbData and create buttons. */}
                {cvbData.map((d, index) => {
                    return (
                        <Button
                            key={'trackerdatagridbuttons-cvb-' + index}
                            variant='contained'
                            size='small'
                            sx={{ marginLeft: index === 0 ? '0' : '1em' }}
                            onClick={() => {
                                onColumnVisibilityButtonClick(d.currentAction, d.columns);
                                toggleOneCVB(index);
                            }}
                        >
                            {d.currentText}
                        </Button>
                    );
                })}
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => handleToggleRowHeightButtonClick()}
                    sx={{ marginLeft: '1em' }}
                >
                    {rowHeightButtonText}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => handleResetColumnOrderButtonClick()}
                    sx={{ marginLeft: '1em' }}
                >
                    Reset Column Order
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        handleResetColumnVisibilityButtonClick();
                        resetViewToDefaults();
                    }}
                    sx={{ marginLeft: '1em' }}
                >
                    Reset Column Visibility
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => handleShowAllColumnsButtonClick()}
                    sx={{ marginLeft: '1em' }}
                >
                    Show All Columns
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => handleClearAllFiltersButtonClick()}
                    sx={{ marginLeft: '1em' }}
                >
                    Clear All Filters
                </Button>
                {/* {showCreateMinibidButton === true && (
                    <Button
                        variant='contained'
                        size='small'
                        color='info'
                        onClick={() => onCreateMinibidButtonClick()}
                        sx={{ marginLeft: '1em' }}
                    >
                        Create Mini-bid
                    </Button>
                )} */}
            </>
        );
    }
);

export function determineInitialHideShowState(columnFieldNamesArray, hiddenColumnPrefsJSON) {
    // 'hide' or 'show'
    let hcprefs = JSON.parse(hiddenColumnPrefsJSON);
    for (let colname of columnFieldNamesArray) {
        // If any of these columns are hidden (not visible) set the button text to 'Show X Columns'.
        if (hcprefs[colname] === false) {
            return 'show';
        }
    }
    return 'hide';
}

import React from 'react';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function ClientFundingYearDetails(props) {
    const clientAPI = props.clientAPI;
    const clientId = props.clientId;
    const kyList = props.kyList;
    const fundingYear = props.fundingYear;
    const state = props.state;
    const searchType = props.searchType;
    const f471appnum = props.f471appnum;
    const [clientData, setClientData] = React.useState([]);
    const [allStates, setAllStates] = React.useState([]);
    const [fundingDetailsData, setFundingDetailsData] = React.useState([]);
    const [cfydRecordData, setCfydRecordData] = React.useState({});
    const [selectedYear, setSelectedYear] = React.useState(fundingYear);
    const [selectedState, setSelectedState] = React.useState('');
    let navigate = useNavigate();
    console.log(
        '[ClientFundingSummary] clientId, kyList, f471appnum, fundingYear, state, searchType:',
        clientId,
        kyList,
        f471appnum,
        fundingYear,
        state,
        searchType
    );

    React.useEffect(() => {
        const getClientData = async (clientId) => {
            const clientdata = await clientAPI.GetClient(clientId);
            setClientData(clientdata);
            console.log('[ClientFundingYearDetails][useEffect][getClientData] clientdata:', clientdata);
            return clientdata;
        };

        const getStates = async () => {
            const allStatesVar = await clientAPI.GetStates();
            setAllStates(allStatesVar);
            console.log('[ClientFundingYearDetails][useEffect][getStates] allStatesVar:', allStatesVar);
            return allStatesVar;
        };

        const getFundingYearDetailsData = async (searchType, ky, state, fundingYear) => {
            const fundingDetailsDataVar = await clientAPI.GetClientFundingYearDetailsData(
                searchType,
                ky,
                state,
                fundingYear
            );
            setFundingDetailsData(fundingDetailsDataVar);
            console.log(
                '[ClientFundingYearDetails][useEffect][getFundingYearDetailsData] fundingDetailsDataVar:',
                fundingDetailsDataVar
            );

            // Search and set the specific record data
            for (let i = 0; i < fundingDetailsDataVar.length; i++) {
                if (
                    (searchType === 'entNum' &&
                        ky
                            .split(',')
                            .map((ben) => ben.trim())
                            .includes(fundingDetailsDataVar[i]['ben'].toString())) ||
                    (searchType === 'f471' && fundingDetailsDataVar[i]['f471applicationnumber'] == ky) ||
                    (searchType === 'spin' && fundingDetailsDataVar[i]['spin'] == ky)
                ) {
                    setCfydRecordData(fundingDetailsDataVar[i]);
                    break;
                }
            }
        };

        const fetchAllFundingData = async () => {
            const tempClientData = await getClientData(clientId);
            if (!tempClientData) return;

            if (searchType === 'entNum') {
                if (state) {
                    await getFundingYearDetailsData(searchType, kyList, state, fundingYear);
                } else {
                    await getFundingYearDetailsData(searchType, kyList, tempClientData.state_abbrev, fundingYear);
                }
            } else if (searchType === 'f471') {
                await getFundingYearDetailsData(searchType, f471appnum, state, fundingYear);
            } else if (searchType === 'spin') {
                const statesArray = await getStates();
                if (!statesArray) return;
                const states = statesArray.map((ea_state_obj) => ea_state_obj.abbreviation);
                await getFundingYearDetailsData(searchType, kyList, states, fundingYear);
            }
        };

        fetchAllFundingData();
    }, [clientAPI, clientId, f471appnum, fundingYear, kyList, searchType, state]);

    React.useEffect(() => {
        setSelectedYear(fundingYear);
    }, [fundingYear]);

    // Don't display anything until we have data.
    if (!clientData || !allStates || !fundingDetailsData || !cfydRecordData) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>{`Gathering data for E-Rate Funding Summary Chart for Funding Year ${fundingYear}...`}</em>
                </Typography>
            </Box>
        );
    }

    const createYearsOptions = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

        const startYear = 2016;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            if (year < currentYear || (year === currentYear && currentMonth >= 6)) {
                years.push(year);
            }
        }

        return years.sort((a, b) => b - a);
    };
    const years = createYearsOptions();

    const createStatesDropdown = (fyDataArray) => {
        const uniqueStates = new Set(fyDataArray.map((ea_obj) => ea_obj.applicantstate));
        return Array.from(uniqueStates);
    };
    const specificStates = createStatesDropdown(fundingDetailsData).sort();

    const handleStateSelectionChange = (event) => {
        setSelectedState(event.target.value);
    };

    const handleFundingYearSelectionChange = (event, value) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
        navigate(`/client-list/funding-year-details/${clientId}/${kyList}/${newYear}/${searchType}`);
    };

    return (
        <Box>
            {searchType === 'entNum' ? (
                <>
                    <Box id='top' sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Applicant:
                            <Link
                                href={`/client-list/contact-search-report/${clientId}/${kyList}/${searchType}`}
                                // target='_blank'
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 1,
                                    fontSize: '1.125rem',
                                }}
                            >
                                {clientData.name}
                            </Link>
                        </h3>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Billed Entity Number{kyList.includes(',') ? 's' : ''}:
                            <Link
                                href={`/client-list/funding-summary/${clientId}/${kyList}/entNum`} // CORRECT
                                // target='_blank'
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 1,
                                    fontSize: '1.125rem',
                                }}
                            >
                                {kyList}
                            </Link>
                        </h3>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Address:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                                {cfydRecordData.applicantstreetaddress1 ||
                                cfydRecordData.applicantcity ||
                                cfydRecordData.applicantstate ||
                                cfydRecordData.applicantzipcode
                                    ? `${cfydRecordData.applicantstreetaddress1 || ''}, ${
                                          cfydRecordData.applicantcity || ''
                                      }, ${cfydRecordData.applicantstate || ''} ${
                                          cfydRecordData.applicantzipcode || ''
                                      }`
                                          .trim()
                                          .replace(/^, | , | $/g, '')
                                    : ''}
                            </span>
                        </h3>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Type: <span style={{ fontWeight: 'normal' }}>{cfydRecordData.applicanttype || ''}</span>
                        </h3>
                    </Box>

                    {/* Only display the funding year dropdown when you're NOT drilled down to a specific 471 appnum */}
                    {!f471appnum && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: 0, marginRight: 4 }}>{`Funding Year:   `}</h3>
                            <FormControl sx={{ minWidth: 100 }}>
                                <InputLabel id='funding-year-selection-label'>Year</InputLabel>
                                <Select
                                    id='funding-year-selection'
                                    label='Funding Year Selection'
                                    value={selectedYear || ''}
                                    defaultValue={fundingYear || ''}
                                    onChange={handleFundingYearSelectionChange}
                                    sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                                >
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </>
            ) : searchType === 'f471' ? (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Applicant:
                            <Link
                                href={`/client-list/contact-search-report/${clientId}/${kyList}/${searchType}`}
                                // target='_blank'
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 3,
                                    fontSize: '1.125rem',
                                }}
                            >
                                {clientData.name}
                            </Link>
                        </h3>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Billed Entity Number{kyList.includes(',') ? 's' : ''}:{' '}
                            <span style={{ fontWeight: 'normal' }}>{kyList}</span>
                            {/* <Link
                                href={`/client-list/funding-summary/${clientId}/${f471appnum}/f471`}
                                // target='_blank'
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 3,
                                    fontSize: '1.125rem',
                                }}
                            >
                                {f471appnum}
                            </Link> */}
                        </h3>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Address:{' '}
                            <span style={{ fontWeight: 'normal' }}>
                                {cfydRecordData.applicantstreetaddress1 ||
                                cfydRecordData.applicantcity ||
                                cfydRecordData.applicantstate ||
                                cfydRecordData.applicantzipcode
                                    ? `${cfydRecordData.applicantstreetaddress1 || ''}, ${
                                          cfydRecordData.applicantcity || ''
                                      }, ${cfydRecordData.applicantstate || ''} ${
                                          cfydRecordData.applicantzipcode || ''
                                      }`
                                          .trim()
                                          .replace(/^, | , | $/g, '')
                                    : ''}
                            </span>
                        </h3>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Type: <span style={{ fontWeight: 'normal' }}>{cfydRecordData.applicanttype || ''}</span>
                        </h3>
                    </Box>

                    {/* Only display the funding year dropdown when you're NOT drilled down to a specific 471 appnum */}
                    {!f471appnum && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: 0, marginRight: 4 }}>{`Funding Year:   `}</h3>
                            <FormControl sx={{ minWidth: 100 }}>
                                <InputLabel id='funding-year-selection-label'>Year</InputLabel>
                                <Select
                                    id='funding-year-selection'
                                    label='Funding Year Selection'
                                    value={selectedYear || ''}
                                    defaultValue={fundingYear || ''}
                                    onChange={handleFundingYearSelectionChange}
                                    sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                                >
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </>
            ) : (
                <>
                    {/* Only display the funding year dropdown when you're NOT drilled down to a specific 471 appnum */}
                    {!f471appnum && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: 0, marginRight: 4 }}>{`Funding Year:   `}</h3>
                            <FormControl sx={{ minWidth: 100 }}>
                                <InputLabel id='funding-year-selection-label'>Year</InputLabel>
                                <Select
                                    id='funding-year-selection'
                                    label='Funding Year Selection'
                                    value={selectedYear || ''}
                                    defaultValue={fundingYear || ''}
                                    onChange={handleFundingYearSelectionChange}
                                    sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                                >
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <h3 style={{ margin: 0, marginLeft: 20, marginRight: 4 }}>{`State:   `}</h3>
                            <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                    id='state-selection'
                                    label='State Selection'
                                    value={selectedState || ''}
                                    onChange={handleStateSelectionChange}
                                    sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                                >
                                    {specificStates.map((state) => (
                                        <MenuItem
                                            key={state}
                                            value={state}
                                            onClick={() => {
                                                const section = document.getElementById(state);
                                                section.scrollIntoView({ behavior: 'smooth' });
                                            }}
                                        >
                                            {state}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </>
            )}

            <Box sx={{ flexBasis: '700px' }}>
                <ClientFundingYearDetailsSection
                    clientId={clientId}
                    kyList={kyList}
                    fundingYear={fundingYear}
                    searchType={searchType}
                    fundingDetailsData={fundingDetailsData}
                    allStates={allStates}
                />
            </Box>
        </Box>
    );
}

function ClientFundingYearDetailsSection({ clientId, kyList, fundingYear, searchType, fundingDetailsData, allStates }) {
    const [fydOrder, setFydOrder] = React.useState('asc');
    const [fydOrderBy, setFydOrderBy] = React.useState('f471applicationnumber');
    const [newClientFundingYearDetailsData, setNewClientFundingYearDetailsData] = React.useState([]);

    React.useEffect(() => {
        console.log('[ClientFundingYearDetailsSection][useEffect] fundingDetailsData: ', fundingDetailsData);

        const processedFYDResults = processAllFYD(fundingDetailsData);
        console.log('[ClientFundingYearDetailsSection][useEffect] processedFYDResults: ', processedFYDResults);

        setNewClientFundingYearDetailsData(processedFYDResults);
    }, [fundingDetailsData]);

    // Don't display anything until we have data.
    if (!fundingDetailsData) {
        return null;
    }

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => {
        // Checking if the value is 0 or "0"
        if (value === 0 || value === '0' || !value) {
            return '$0.00';
        }

        // Ensuring the value is a number
        const numericValue = parseFloat(value);

        // Formatting the number to money
        return `$${numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    const entNumFundingDetailsHeaders = [
        { id: 'f471applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'frnnickname', label: 'Nickname' },
        { id: 'spin', label: 'SPIN' },
        { id: 'serviceprovidername', label: 'Service Provider' },
        { id: 'service', label: 'Service' },
        { id: 'origrannualcost', label: 'Pre-Discount Original' },
        { id: 'cmtdrannualcost', label: 'Pre-Discount Committed' },
        { id: 'origfundingrequest', label: 'Discounted Original' },
        { id: 'frncommittedamount', label: 'Discounted Committed' },
        { id: 'totalauthorizeddisbursement', label: 'Disbursed' },
        { id: 'percentUtilized', label: 'Util.%' },
        { id: 'discount', label: 'Discount' },
        { id: 'f486filed', label: '486 Filed' },
        { id: 'invoicingmode', label: 'Invoice Mode' },
        { id: 'wavenumber', label: 'Wave' },
        { id: 'fcdldate', label: 'FCDL Date' },
    ];

    const spinFundingDetailsHeaders = [
        { id: 'f471applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'ben', label: 'BEN' },
        { id: 'billedentityname', label: 'Applicant' },
        { id: 'serviceType', label: 'Service' },
        { id: 'origrannualcost', label: 'Estimated Annual Cost' }, // unsure
        { id: 'origfundingrequest', label: 'Original Request' }, // unsure
        { id: 'frncommittedamount', label: 'Current Commitment' }, // unsure
        { id: 'totalauthorizeddisbursement', label: 'Disbursed' },
        { id: 'percentUtilized', label: 'Util.%' },
        { id: 'discount', label: 'Discount' },
        { id: 'f486servicestartdate', label: '486 Filed' },
        { id: 'invoicingmode', label: 'Invoice Mode' },
        { id: 'wavenumber', label: 'Wave' },
    ];

    const serviceMapper = (serviceType) => {
        if (!serviceType || serviceType.trim() === '') {
            return '';
        }

        const upperCaseServiceType = serviceType.toUpperCase();

        if (upperCaseServiceType === 'TELCOMM SERVICES') {
            return 'T';
        } else if (upperCaseServiceType === 'INTERNAL CONNECTIONS') {
            return 'IC';
        } else if (
            upperCaseServiceType === 'INTERNAL CONNECTIONS MNT' ||
            upperCaseServiceType === 'BASIC MAINTENANCE OF INTERNAL CONNECTIONS'
        ) {
            return 'ICM';
        } else if (
            upperCaseServiceType === 'INTERNET ACCESS' ||
            upperCaseServiceType === 'DATA TRANSMISSION AND/OR INTERNET ACCESS'
        ) {
            return 'IA';
        } else if (upperCaseServiceType === 'VOICE SERVICES' || upperCaseServiceType === 'VOICE') {
            return 'VS';
        } else if (
            upperCaseServiceType === 'INTERNAL CONNECTIONS MIBS' ||
            upperCaseServiceType === 'MANAGED INTERNAL BROADBAND SERVICES'
        ) {
            return 'MIB';
        } else {
            return '-';
        }
    };

    const handleFYDRequestSort = (property) => {
        const isAsc = fydOrderBy === property && fydOrder === 'asc';
        setFydOrder(isAsc ? 'desc' : 'asc');
        setFydOrderBy(property);
    };

    const sortFYData = (data, orderBy, order) => {
        return [...data].sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Convert monetary values to numbers for sorting
            if (
                orderBy.startsWith('origrannualcost') ||
                orderBy.startsWith('cmtdrannualcost') ||
                orderBy.startsWith('origfundingrequest') ||
                orderBy.startsWith('frncommittedamount') ||
                orderBy.startsWith('totalauthorizeddisbursement')
            ) {
                aValue = parseFloat(aValue.replace(/[$,]/g, ''));
                bValue = parseFloat(bValue.replace(/[$,]/g, ''));
            }

            // Handle sorting based on the value type
            if (typeof aValue === 'number') {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string') {
                return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            return 0;
        });
    };

    // Performs the required mathematical operations on each year's worth of FRNs, returns an array of objects
    const processEachFYDObj = (ea_year_obj_array) => {
        return ea_year_obj_array.map((ea_obj) => {
            const origrannualcostparsed = parseMoney(ea_obj['origrannualcost']) || 0; // Confirmed $
            const cmtdrannualcostparsed = parseMoney(ea_obj['cmtdrannualcost']) || 0; // Confirmed $
            const origfundingrequestparsed = parseMoney(ea_obj['origfundingrequest']) || 0; // Confirmed $
            const frncommittedamountparsed = parseMoney(ea_obj['frncommittedamount']) || 0; // Confirmed $
            const frnstatusparsed = ea_obj['frnstatus'];
            const totalauthorizeddisbursementparsed = parseMoney(ea_obj['totalauthorizeddisbursement']) || 0; // Confirmed $
            const cmtddiscountparsed = ea_obj['cmtddiscount'] || 0; // Is NOT $
            const origdiscountparsed = ea_obj['origdiscount'] || 0; // Is NOT $
            const f486servicestartdateparsed = ea_obj['f486servicestartdate'];
            const origfrnservicetypeparsed = ea_obj['origfrnservicetype'];
            const cmtdfrnservicetypeparsed = ea_obj['cmtdfrnservicetype'];

            // Reconstructing each object with just the data we need per row in the table
            let baseObj = {
                frn: ea_obj['frn'],
                frnnickname: ea_obj['frnnickname'],
                frnstatus: '',
                f471applicationnumber: ea_obj['f471applicationnumber'],
                applicantstate: ea_obj['applicantstate'],
                ben: ea_obj['ben'],
                billedentityname: ea_obj['billedentityname'],
                serviceprovidername: ea_obj['serviceprovidername'],
                spin: ea_obj['spin'],
                f486servicestartdate: ea_obj['f486servicestartdate'],
                fundingYear: ea_obj['fundingYear'],
                origrannualcost: 0,
                cmtdrannualcost: 0,
                discount: 0,
                serviceType: '',
                origfundingrequest: 0,
                wavenumber: ea_obj['wavenumber'],
                fcdldate: ea_obj['fcdldate'],
                pcwavenumber: ea_obj['pcwavenumber'],
                frncommittedamount: 0,
                invoicingmode: ea_obj['invoicingmode'],
                totalauthorizeddisbursement: 0,
                percentUtilized: '',
                f486OnFile: '',
                // ...ea_obj
            };

            // *** Special handling based on fundingHistoryDetail ***

            if (cmtdfrnservicetypeparsed == null || cmtdfrnservicetypeparsed === '') {
                baseObj.serviceType = serviceMapper(origfrnservicetypeparsed);
            } else {
                baseObj.serviceType = serviceMapper(cmtdfrnservicetypeparsed);
            }

            // Total Estimated Annual (strORIG_ESTMTD_ANNUAL_COST) == origrannualcost. Pre-Discount Original
            if (origrannualcostparsed > 0) {
                baseObj.origrannualcost = ea_obj['origrannualcost'];
            } else if (origrannualcostparsed === 0) {
                baseObj.origrannualcost = formatMoney('0');
            } else {
                baseObj.origrannualcost = formatMoney('0');
            }

            // Total Committed Annual (strCOMM_ESTMTD_ANNUAL_COST) == cmtdrannualcost. Pre-Discount Committed
            if (cmtdrannualcostparsed > 0) {
                baseObj.cmtdrannualcost = ea_obj['cmtdrannualcost'];
            } else if (cmtdrannualcostparsed == null || cmtdrannualcostparsed === ' ' || cmtdrannualcostparsed === '') {
                baseObj.cmtdrannualcost = formatMoney('0');
            } else {
                baseObj.cmtdrannualcost = formatMoney('0');
            }

            // Original Requested Amount == origfundingrequest. Discounted Original
            if (origfundingrequestparsed > 0) {
                baseObj.origfundingrequest = ea_obj['origfundingrequest'];
            } else if (origfundingrequestparsed === 0) {
                baseObj.origfundingrequest = formatMoney('0');
            } else {
                baseObj.origfundingrequest = formatMoney('0');
            }

            // Funded Amount == frncommittedamount. Discounted Committed
            if (frncommittedamountparsed > 0) {
                baseObj.frncommittedamount = ea_obj['frncommittedamount'];
            } else if (frncommittedamountparsed === '' || frncommittedamountparsed == null) {
                baseObj.frncommittedamount = formatMoney('0');
            } else if (frncommittedamountparsed === 0) {
                baseObj.frncommittedamount = formatMoney('0');
            } else {
                baseObj.frncommittedamount = formatMoney('0');
            }

            // Display strStatusFCDL (a20) if Funded Amount = 0 == fst_FST.frnstatus (could default to the original frnstatus if funded)
            if (frncommittedamountparsed === 0 || frncommittedamountparsed !== '' || frncommittedamountparsed == null) {
                if (frnstatusparsed === 'FUNDED') {
                    baseObj.frnstatus = '';
                } else if (frnstatusparsed === 'NOT FUNDED' || frnstatusparsed === 'Denied') {
                    baseObj.frnstatus = 'NF';
                } else if (frnstatusparsed == null) {
                    baseObj.frnstatus = '';
                } else {
                    baseObj.frnstatus = '';
                }
            } else {
                baseObj.frnstatus = '';
            }

            // Disbursed == fst_FST.totalauthorizeddisbursement
            if (totalauthorizeddisbursementparsed > 0) {
                baseObj.totalauthorizeddisbursement = ea_obj['totalauthorizeddisbursement'];
            } else if (
                totalauthorizeddisbursementparsed === '' ||
                totalauthorizeddisbursementparsed === 0 ||
                totalauthorizeddisbursementparsed == null
            ) {
                baseObj.totalauthorizeddisbursement = formatMoney('0');
            } else {
                baseObj.totalauthorizeddisbursement = formatMoney('0');
            }

            // Utilization Percent  a(15,i)/a(12,i) == fst_FST.totalauthorizeddisbursement / fst_FST.frncommittedamount
            if (totalauthorizeddisbursementparsed > 0 && frncommittedamountparsed > 0) {
                baseObj.percentUtilized = `${(
                    (totalauthorizeddisbursementparsed / frncommittedamountparsed) *
                    100
                ).toFixed(2)}%`;
            } else if (
                totalauthorizeddisbursementparsed == null ||
                totalauthorizeddisbursementparsed === '' ||
                totalauthorizeddisbursementparsed === ' '
            ) {
                baseObj.percentUtilized = '0%';
            } else {
                baseObj.percentUtilized = '0%';
            }

            // Discount (strCOMM_DISCOUNT_PCT == fst_FST.cmtddiscount or fst_FST.origdiscount) origdiscount (a(19 equiv))
            if (cmtddiscountparsed == null || cmtddiscountparsed === '') {
                baseObj.discount = `${parseInt(origdiscountparsed).toFixed(2)}%` || '0%';
            } else {
                baseObj.discount = `${parseInt(cmtddiscountparsed).toFixed(2)}%` || '0%';
            }

            if (
                f486servicestartdateparsed == null ||
                f486servicestartdateparsed === '' ||
                f486servicestartdateparsed === ' '
            ) {
                baseObj.f486OnFile = 'N';
            } else {
                baseObj.f486OnFile = 'Y';
            }
            return baseObj;
        });
    };

    const processAllFYD = (fundingDetailsData) => {
        if (!fundingDetailsData || fundingDetailsData.length === 0) {
            return [];
        }
        return processEachFYDObj(fundingDetailsData);
    };

    const calculateFRNTotals = (processedData) => {
        return processedData.reduce(
            (acc, yearData) => {
                acc.origrannualcost += parseMoney(yearData.origrannualcost);
                acc.cmtdrannualcost += parseMoney(yearData.cmtdrannualcost);
                acc.origfundingrequest += parseMoney(yearData.origfundingrequest);
                acc.frncommittedamount += parseMoney(yearData.frncommittedamount);
                acc.totalauthorizeddisbursement += parseMoney(yearData.totalauthorizeddisbursement);
                acc.percentUtilized += parseFloat(yearData.percentUtilized.replace('%', ''));

                return acc;
            },
            {
                origrannualcost: 0, // Pre-Discount Original
                cmtdrannualcost: 0, // Pre-Discount Committed
                origfundingrequest: 0, // Discounted Original
                frncommittedamount: 0, //Discounted Committed
                totalauthorizeddisbursement: 0, // Disbursed
                percentUtilized: 0, // Util. %
            }
        );
    };

    const groupDataByState = (data) => {
        return data.reduce((acc, ea_item) => {
            const state = ea_item.applicantstate;
            if (!acc[state]) {
                acc[state] = [];
            }
            acc[state].push(ea_item);
            return acc;
        }, {});
    };

    const getStateName = (abbreviation, allStates) => {
        const stateObj = allStates.find((ea_state_obj) => ea_state_obj.abbreviation === abbreviation);
        return stateObj ? `${stateObj.name} [${stateObj.abbreviation}]` : abbreviation;
    };

    const spinColumnStyles = {
        f471applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        ben: { minWidth: '80px', maxWidth: '150px' },
        billedentityname: { minWidth: '150px', maxWidth: '300px' },
        serviceType: { minWidth: '80px', maxWidth: '150px' },
        origrannualcost: { minWidth: '100px', maxWidth: '200px' },
        origfundingrequest: { minWidth: '100px', maxWidth: '200px' },
        frncommittedamount: { minWidth: '100px', maxWidth: '200px' },
        totalauthorizeddisbursement: { minWidth: '100px', maxWidth: '200px' },
        percentUtilized: { minWidth: '80px', maxWidth: '150px' },
        discount: { minWidth: '80px', maxWidth: '150px' },
        f486OnFile: { minWidth: '80px', maxWidth: '150px' },
        invoicingmode: { minWidth: '80px', maxWidth: '150px' },
        wavenumber: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
    };

    const entNumColumnStyles = {
        f471applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        frnnickname: { minWidth: '80px', maxWidth: '150px' },
        spin: { minWidth: '150px', maxWidth: '300px' },
        serviceprovidername: { minWidth: '150px', maxWidth: '300px' },
        serviceType: { minWidth: '80px', maxWidth: '150px' },
        origrannualcost: { minWidth: '100px', maxWidth: '200px' },
        cmtdrannualcost: { minWidth: '100px', maxWidth: '200px' },
        origfundingrequest: { minWidth: '100px', maxWidth: '200px' },
        frncommittedamount: { minWidth: '100px', maxWidth: '200px' },
        totalauthorizeddisbursement: { minWidth: '100px', maxWidth: '200px' },
        percentUtilized: { minWidth: '80px', maxWidth: '150px' },
        discount: { minWidth: '80px', maxWidth: '150px' },
        f486OnFile: { minWidth: '80px', maxWidth: '150px' },
        invoicingmode: { minWidth: '80px', maxWidth: '150px' },
        wavenumber: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
    };

    const frnTotals =
        newClientFundingYearDetailsData && newClientFundingYearDetailsData.length > 0
            ? calculateFRNTotals(newClientFundingYearDetailsData)
            : {
                  origrannualcost: 0,
                  cmtdrannualcost: 0,
                  origfundingrequest: 0,
                  frncommittedamount: 0,
                  totalauthorizeddisbursement: 0,
                  percentUtilized: 0,
              };

    const yearData = newClientFundingYearDetailsData;
    if (!yearData || yearData.length === 0) {
        return [];
    }

    const sortedYearData = sortFYData(yearData, fydOrderBy, fydOrder);
    const groupedYearData = groupDataByState(sortedYearData);
    const sortedGroupedYearDataKeys = Object.keys(groupedYearData).sort();

    return searchType === 'entNum' || searchType === 'f471' ? (
        <Box>
            <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                {`E-Rate Funding Search for Funding Year ${fundingYear}`}
            </h4>
            <TableContainer component={Paper}>
                <Table
                    sx={{
                        minWidth: 800,
                        width: '100%', // Ensures the table takes up the full width of the container
                        tableLayout: 'fixed', // Helps in managing how the table handles the available width
                    }}
                    size='small'
                >
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {entNumFundingDetailsHeaders.map((header) => (
                                <TableCell
                                    key={header.id}
                                    sortDirection={fydOrderBy === header.id ? fydOrder : false}
                                    sx={{
                                        padding: '4px 8px',
                                        lineHeight: '1.2',
                                        flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                        flexGrow: 1, // Allows the columns to grow to fill available space
                                        flexShrink: 1, // Allows the columns to shrink if necessary
                                        ...entNumColumnStyles[header.id],
                                    }}
                                >
                                    <TableSortLabel
                                        active={fydOrderBy === header.id}
                                        direction={fydOrderBy === header.id ? fydOrder : 'asc'}
                                        onClick={() => handleFYDRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedYearData.map((rowData, index) => (
                            <TableRow
                                key={`${rowData.filingwindow}-${index}`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/funding-year-details/${clientId}/${kyList}/${fundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471/`}
                                        // target='_blank'
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {rowData.f471applicationnumber}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/form-details/${clientId}/${rowData.frn}/${fundingYear}/${
                                            searchType === 'entNum' ? 'frn' : 'f47'
                                        }`}
                                        // target='_blank'
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {rowData.frn}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/form-details/${clientId}/${rowData.frn}/${fundingYear}/${
                                            searchType === 'entNum' ? 'frn' : 'f47'
                                        }`}
                                        // target='_blank'
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {rowData.frnnickname}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        // clientId, "ben", spin number, search_type
                                        href={`/client-list/funding-summary/${clientId}/${kyList}/${rowData.spin}/spin`} // CORRECT
                                        // target='_blank'
                                        // Potential
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {rowData.spin}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.serviceprovidername}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.serviceType}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.origrannualcost}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.cmtdrannualcost}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.origfundingrequest}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.frncommittedamount}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.totalauthorizeddisbursement}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.percentUtilized}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.discount}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.f486OnFile}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.invoicingmode}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.wavenumber}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{formatDate(rowData.fcdldate)}</TableCell>
                            </TableRow>
                        ))}

                        {sortedYearData.length > 0 && (
                            <TableRow sx={{ backgroundColor: sortedYearData.length % 2 === 0 ? 'white' : '#f5f5f5' }}>
                                <TableCell sx={{ padding: '4px 8px' }}>Totals</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(frnTotals.origrannualcost)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(frnTotals.cmtdrannualcost)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(frnTotals.origfundingrequest)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(frnTotals.frncommittedamount)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(frnTotals.totalauthorizeddisbursement)}
                                </TableCell>
                                <TableCell>
                                    {isNaN(frnTotals.frncommittedamount)
                                        ? '0.0%'
                                        : `${(
                                              (frnTotals.totalauthorizeddisbursement / frnTotals.frncommittedamount) *
                                              100
                                          ).toFixed(2)}%`}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    ) : (
        searchType === 'spin' && (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '25px',
                        marginBottom: '25px',
                    }}
                >
                    <h4 style={{ color: '#19638B', margin: 0 }}>
                        {`Market Organizer Funding Search Analysis for Funding Year ${fundingYear}`}
                    </h4>
                    <Link
                        href={`/client-list/funding-summary/${clientId}/${kyList}/spin`}
                        sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 3, fontSize: '1rem' }}
                    >
                        Utilization Summary Chart
                    </Link>
                </Box>
                {sortedGroupedYearDataKeys.map((state) => {
                    const stateData = groupedYearData[state];
                    const stateFRNTotals = calculateFRNTotals(stateData);
                    console.log('groupedYearData:', groupedYearData[state]);

                    return (
                        <>
                            <Box id={state} key={state} sx={{ marginBottom: '40px' }}>
                                <Box>
                                    <h5 style={{ margin: 0 }}>
                                        SPIN:
                                        <Link
                                            href={`/client-list/funding-summary/${clientId}/${kyList}/spin`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontWeight: 'normal' }}
                                        >
                                            {kyList}
                                        </Link>
                                    </h5>
                                </Box>
                                <Box>
                                    <h5 style={{ margin: 0 }}>
                                        Funding Year: <span style={{ fontWeight: 'normal' }}>{fundingYear}</span>
                                    </h5>
                                </Box>
                                <Box>
                                    <h5 style={{ margin: 0 }}>
                                        State:{' '}
                                        <span style={{ fontWeight: 'normal' }}>{getStateName(state, allStates)}</span>
                                    </h5>
                                </Box>
                                <Box>
                                    <h5 style={{ margin: 0 }}>
                                        Service Provider:{' '}
                                        <span style={{ fontWeight: 'normal' }}>
                                            {groupedYearData[state][0]['serviceprovidername'] || ''}
                                        </span>
                                    </h5>
                                </Box>

                                <TableContainer component={Paper}>
                                    <Table
                                        sx={{
                                            minWidth: 800,
                                            width: '100%', // Ensures the table takes up the full width of the container
                                            tableLayout: 'fixed', // Helps in managing how the table handles the available width
                                        }}
                                        size='small'
                                    >
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                                {spinFundingDetailsHeaders.map((header) => (
                                                    <TableCell
                                                        key={header.id}
                                                        sortDirection={fydOrderBy === header.id ? fydOrder : false}
                                                        sx={{
                                                            padding: '4px 8px',
                                                            lineHeight: '1.2',
                                                            flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                                            flexGrow: 1, // Allows the columns to grow to fill available space
                                                            flexShrink: 1, // Allows the columns to shrink if necessary
                                                            ...spinColumnStyles[header.id],
                                                        }}
                                                    >
                                                        <TableSortLabel
                                                            active={fydOrderBy === header.id}
                                                            direction={fydOrderBy === header.id ? fydOrder : 'asc'}
                                                            onClick={() => handleFYDRequestSort(header.id)}
                                                        >
                                                            {header.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {groupedYearData[state].map((rowData, index) => (
                                                <TableRow
                                                    key={`${rowData.filingwindow}-${index}`}
                                                    sx={{
                                                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Link
                                                            href={`/client-list/funding-year-details/${clientId}/${kyList}/${fundingYear}/${rowData.f471applicationnumber}/${state}/f471`}
                                                            rel='noreferrer'
                                                            sx={{ textDecoration: 'none' }}
                                                        >
                                                            {rowData.f471applicationnumber}
                                                        </Link>
                                                    </TableCell>

                                                    {/* This links you to the funding year details page based on FundingYear (not the 'all' version) */}
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        <Link
                                                            href={`/client-list/form-details/${clientId}/${rowData.frn}/${fundingYear}/frn`}
                                                            // href={`/client-list/form-details/${clientId}/${rowData.frn}/${fundingYear}/${searchType === 'entNum' ? 'frn' : 'f47'}`}
                                                            // target='_blank'
                                                            // Potential
                                                            rel='noreferrer'
                                                            sx={{ textDecoration: 'none' }}
                                                        >
                                                            {rowData.frn}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        <Link
                                                            href={`/client-list/funding-year-details/${clientId}/${rowData.ben}/${fundingYear}/${state}/entNum`}
                                                            rel='noreferrer'
                                                            sx={{ textDecoration: 'none' }}
                                                        >
                                                            {rowData.ben}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ padding: '4px 8px' }}
                                                    >{`${rowData.billedentityname} [${rowData.applicantstate}]`}</TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.serviceType}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.origrannualcost}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.origfundingrequest}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.frncommittedamount}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.totalauthorizeddisbursement}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.percentUtilized}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.discount}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.f486OnFile}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.invoicingmode}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {rowData.wavenumber}
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                            {stateData.length > 0 && (
                                                <TableRow
                                                    sx={{
                                                        backgroundColor:
                                                            groupedYearData[state].length % 2 === 0
                                                                ? 'white'
                                                                : '#f5f5f5',
                                                    }}
                                                >
                                                    <TableCell>Totals</TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {formatMoney(stateFRNTotals.origrannualcost)}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {formatMoney(stateFRNTotals.origfundingrequest)}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {formatMoney(stateFRNTotals.frncommittedamount)}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {formatMoney(stateFRNTotals.totalauthorizeddisbursement)}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}>
                                                        {`${(stateFRNTotals.percentUtilized / stateData.length).toFixed(
                                                            2
                                                        )}%`}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            <Box sx={{ marginTop: '-35px', marginBottom: '40px', textAlign: 'right' }}>
                                <Link
                                    href='#top'
                                    sx={{
                                        textDecoration: 'none',
                                        fontWeight: 'normal',
                                        marginLeft: 3,
                                        fontSize: '1rem',
                                    }}
                                    onClick={() => {
                                        const section = document.getElementById('top');
                                        section.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    {'Top of Page'}
                                </Link>
                            </Box>
                        </>
                    );
                })}
            </Box>
        )
    );
}

import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export const CustomGridToolbarViewSelectorARCL = (props) => {
    const {
        appliesTo,
        currentSavedViews,
        editingViewName,
        handleDeleteSavedView,
        handleEditSavedViewName,
        handleSelectSavedView,
        resetViewToDefaults,
        selectedViewName,
        setEditingViewName,
        userID,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleViewSelectionChildComp = (viewName) => {
        handleSelectSavedView(viewName);
        setAnchorEl(null);
    };

    const handleDefaultSelectionClick = () => {
        resetViewToDefaults();
        setAnchorEl(null);
    };

    // Components rendered when the user begins to edit a MenuItem
    const EditableMenuItem = ({ oldEditValue, onSave, onCancel }) => {
        const [newEditValue, setNewEditValue] = React.useState(oldEditValue);

        const handleKeyDown = (event) => {
            // Stops the select component from navigating while typing
            event.stopPropagation();
            if (event.key === 'Enter') {
                onSave(oldEditValue, newEditValue);
            }
        };

        return (
            <MenuItem>
                <TextField
                    value={newEditValue}
                    onChange={(event) => setNewEditValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => onSave(oldEditValue, newEditValue)}>
                    <DoneIcon fontSize='inherit' />
                </IconButton>
                <IconButton onClick={onCancel}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </MenuItem>
        );
    };

    // const defaultSelectedStyling = () => {
    //     if (selectedViewName !== '') return {};

    //     const original_orders = getOriginalColumnOrderFromStorage();
    //     const original_widths = getOriginalColumnWidthsFromStorage();

    //     const newCurrentColumnOrder = currentColumnOrder.filter((ea_item) => ea_item !== '__detail_panel_toggle__');
    //     const newCurrentColumnWidths = currentColumnWidths;
    //     const newCurrentHiddenColumnPrefs = JSON.parse(currentHiddenColumnPrefs);

    //     delete newCurrentHiddenColumnPrefs['__detail_panel_toggle__'];
    //     delete newCurrentColumnWidths['__detail_panel_toggle__'];

    //     // Left are the defaults, right is CURRENT
    //     const isMatch =
    //         'standard' === currentDensityPref &&
    //         defaultFilter === currentFilterPrefs &&
    //         defaultHiddenColumns === JSON.stringify(newCurrentHiddenColumnPrefs) &&
    //         defaultPinnedColumns === currentPinnedColumnPrefs &&
    //         defaultSort === currentSortPrefs &&
    //         (original_orders === JSON.stringify(currentColumnOrder) ||
    //             original_orders === JSON.stringify(newCurrentColumnOrder)) &&
    //         (original_widths === JSON.stringify(currentColumnWidths) ||
    //             original_widths === JSON.stringify(newCurrentColumnWidths));

    //     return isMatch
    //         ? { backgroundColor: 'rgba(25, 99, 139, 0.08)', color: 'black' }
    //         : { backgroundColor: '#ffcccc', color: 'black' };
    // };

    // const selectedStyling = (view_name) => {
    //     if (!view_name) return {};
    //     const retrieved_view = currentSavedViews.find((ea_view) => ea_view.view_name === view_name);

    //     if (!retrieved_view || selectedViewName !== view_name) return {};

    //     const {
    //         densityPref,
    //         filterPrefs,
    //         hiddenColumnPrefs,
    //         pinnedColumnPrefs,
    //         sortPrefs,
    //         viewColumnOrderPrefs,
    //         viewColumnWidthPrefs,
    //     } = retrieved_view.parameters.view_filters;
    //     const newCurrentColumnOrder = currentColumnOrder.filter((ea_item) => ea_item !== '__detail_panel_toggle__');

    //     // Left is from the saved view, right is CURRENT
    //     const isMatch =
    //         densityPref === currentDensityPref &&
    //         filterPrefs === currentFilterPrefs &&
    //         hiddenColumnPrefs === currentHiddenColumnPrefs &&
    //         pinnedColumnPrefs === currentPinnedColumnPrefs &&
    //         sortPrefs === currentSortPrefs &&
    //         viewColumnOrderPrefs === (JSON.stringify(currentColumnOrder) || JSON.stringify(newCurrentColumnOrder)) &&
    //         viewColumnWidthPrefs === JSON.stringify(currentColumnWidths);

    //     return isMatch
    //         ? { backgroundColor: 'rgba(25, 99, 139, 0.08)', color: 'black' }
    //         : { backgroundColor: '#ffcccc', color: 'black' };
    // };

    return (
        <React.Fragment>
            <Button size='small' startIcon={<VisibilityIcon />} onClick={handleMenuClick}>
                View
            </Button>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'view-button',
                    style: {
                        padding: 0,
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
            >
                <MenuItem value='' onClick={handleDefaultSelectionClick}>
                    Default
                </MenuItem>
                {currentSavedViews.map((ea_view, index) => {
                    if (editingViewName === ea_view.view_name) {
                        return (
                            <EditableMenuItem
                                key={index}
                                oldEditValue={ea_view.view_name}
                                onSave={(oldName, newName) => {
                                    handleEditSavedViewName(oldName, newName);
                                    setEditingViewName(null);
                                }}
                                onCancel={() => {
                                    setEditingViewName(null);
                                }}
                            />
                        );
                    } else {
                        return (
                            <MenuItem
                                key={index}
                                value={ea_view.view_name}
                                // style={selectedStyling(ea_view.view_name)}
                                selected={ea_view.view_name === selectedViewName}
                                onClick={() => handleViewSelectionChildComp(ea_view.view_name)}
                            >
                                {ea_view.view_name}
                                {
                                    <>
                                        <Tooltip title='Edit Name'>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setEditingViewName(ea_view.view_name);
                                                }}
                                                size='small'
                                                style={{ marginLeft: '8px' }}
                                            >
                                                <EditIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Delete'>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteSavedView(
                                                        ea_view.view_name,
                                                        ea_view.view_id,
                                                        appliesTo,
                                                        userID
                                                    );
                                                }}
                                                size='small'
                                                style={{ marginLeft: '8px' }}
                                            >
                                                <DeleteIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            </MenuItem>
                        );
                    }
                })}
            </Menu>
        </React.Fragment>
    );
};

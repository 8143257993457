import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import FRNTable from '../components/FRNTable.js';

export default function Form471TrackerAppDetails({
    form471AppNum,
    trackersAPI,
    handleReturnToSourcePage,
    handleEditButtonClick,
    sourcePageName,
}) {
    let navigate = useNavigate();

    //~~~  Get app data  ~~~
    const [appData, setAppData] = React.useState(null);

    React.useEffect(() => {
        const fetchForm471AppData = async (form471AppNum) => {
            try {
                let appdata = await trackersAPI.GetForm471TrackerOneAppData(form471AppNum);
                //console.log('[fetchForm471AppData] appdata = ', appdata);
                setAppData(appdata);
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        };

        fetchForm471AppData(form471AppNum);
    }, []);

    //~~~  Display functions  ~~~
    const renderDateTime = (isoDateTimeString, displayTime) => {
        if (isoDateTimeString) {
            try {
                let datedate = new Date(isoDateTimeString);
                let text;
                if (!displayTime) {
                    text = new Intl.DateTimeFormat('en-US').format(datedate);
                } else {
                    let dtfoptions = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    };
                    text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
                }
                return <span title={datedate}>{text}</span>;
            } catch (err) {
                console.error('renderDateTime error:', err);
            }
        }
        return isoDateTimeString;
    };

    const convertYMDtoMDY = (ymddatestring) => {
        // Converts "YYYY-MM-DD" to "MM/DD/YYYY".
        if (!ymddatestring) {
            return '';
        }
        const [year, month, day] = ymddatestring.split('-');
        const mdy = [month, day, year].join('/');
        //console.log(ymddatestring + ' -> ' + mdy);
        return mdy;
    };

    const getUsersDisplayNames = (users) => {
        if (!users || users.length === 0) {
            return '';
        }
        let ary = users.map((user) => {
            return getUserDisplayName(user);
        });
        return ary.join(', ');
    };

    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    const getApplicantCohortNames = (applicantCohorts) => {
        if (!applicantCohorts || applicantCohorts.length === 0) {
            return '';
        }
        return applicantCohorts
            .map((ac) => {
                return ac.applicantcohort.applicantcohort_name;
            })
            .join(', ');
    };

    const displayCategory12 = (category) => {
        return category === null || category === 0 || category === '0' ? '' : category;
    };

    function createNavButtons() {
        const EditButton_toeditpage = (
            <Button
                sx={{ m: 1 }}
                variant='contained'
                color='primary'
                size='small'
                onClick={() => navigate('/apply-for-discounts/f471-applications/edit/' + form471AppNum)}
            >
                Edit
            </Button>
        );
        const ButtonToMainReport = (
            <Button
                sx={{ m: 1 }}
                variant='contained'
                color='secondary'
                size='small'
                onClick={() =>
                    navigate('/f471/f471-applications', {
                        state: {
                            updatedRow: appData,
                        },
                    })
                }
            >
                Go to Main Report
            </Button>
        );

        let EditButton_usinghandler = '';
        if (handleEditButtonClick instanceof Function) {
            EditButton_usinghandler = (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={() => handleEditButtonClick(form471AppNum)}
                >
                    Edit
                </Button>
            );
        }

        let ReturnToSourceButton = '';
        if (handleReturnToSourcePage instanceof Function) {
            ReturnToSourceButton = (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={() => handleReturnToSourcePage()}
                >
                    {sourcePageName ? sourcePageName : 'Back'}
                </Button>
            );
        }

        return (
            <>
                {EditButton_usinghandler ? EditButton_usinghandler : EditButton_toeditpage}
                {ReturnToSourceButton ? ReturnToSourceButton : ButtonToMainReport}
            </>
        );
    }

    //~~~  Display  ~~~
    if (!form471AppNum) {
        return (
            <Box>
                <h3>Form 471 Application Details</h3>
                <h4 sx={{ color: 'red' }}>Missing or empty Form 471 AppNum provided.</h4>
            </Box>
        );
    }

    if (!appData) {
        // Don't display anything until we have data.
        return null;
    }

    // get QA values
    let qaStatusText = '';
    let qaReviewerName = '';
    let qaNotes = '';
    let qaSubmitterName = '';
    let qaDateSubmitted = '';
    let qaDateNeeded = '';
    let qaEstimatedFundingRequest = '';
    if (appData.qa_form471app) {
        if (appData.qa_form471app.qa_form471_qa_review_status) {
            qaStatusText = appData.qa_form471app.qa_form471_qa_review_status.status_text;
        }
        if (appData.qa_form471app.reviewer) {
            qaReviewerName = getUserDisplayName(appData.qa_form471app.reviewer);
        }
        if (appData.qa_form471app.submitter) {
            qaSubmitterName = getUserDisplayName(appData.qa_form471app.submitter);
        }

        qaNotes = appData.qa_form471app.qa_notes;
        qaDateSubmitted = appData.qa_form471app.qa_date_submitted;
        qaDateNeeded = appData.qa_form471app.qa_date_needed;
        qaEstimatedFundingRequest = appData.qa_form471app.qa_estimated_funding_request;
    }

    const displayAppNumAsLinkToEPC = (form471AppNum, epcURL) => {
        if (!epcURL) {
            return form471AppNum;
        }
        return (
            <Tooltip title='Application page in EPC'>
                <Link href={epcURL} target='_blank' rel='noopener'>
                    {form471AppNum}
                </Link>
            </Tooltip>
        );
    };

    return (
        <Box>
            <h2 style={{ marginTop: 0, marginBottom: '1em' }}>Form 471 Application Details</h2>
            <h4>
                AppNum {form471AppNum} - {appData.nickname}
            </h4>
            <Box sx={{ my: 1 }}>{createNavButtons()}</Box>

            <Box sx={{ width: 900, mx: 1 }}>
                {/* data from Form471TrackerMainReportSchema */}

                <h4>Client Data</h4>
                <Table sx={{ width: 900 }}>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold', width: 250 }}>
                                Client Name
                            </TableCell>
                            <TableCell>{appData.clientName}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Is a Client
                            </TableCell>
                            <TableCell>
                                {appData.isAClient === true ? 'Yes' : appData.isAClient === false ? 'No' : ''}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                                Primary Contact
                            </TableCell>
                            <TableCell>{getUserDisplayName(appData.primary_contact)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                                Secondary Contacts
                            </TableCell>
                            <TableCell>{getUsersDisplayNames(appData.secondary_contacts)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                                Applicant Cohorts
                            </TableCell>
                            <TableCell>{getApplicantCohortNames(appData.client_applicantcohorts)}</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>

                <h4>Form 471 Application data</h4>
                <Table sx={{ width: 900 }}>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold', width: 250 }}>
                                Form 471 Application Number
                            </TableCell>
                            <TableCell>{displayAppNumAsLinkToEPC(appData.form471AppNum, appData.epcURL)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Nickname
                            </TableCell>
                            <TableCell>{appData.nickname}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Funding Year
                            </TableCell>
                            <TableCell>{appData.fundingYear}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                BEN
                            </TableCell>
                            <TableCell>{appData.ben}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Funding Program
                            </TableCell>
                            <TableCell>{appData.fundingProgram}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Applicant Type
                            </TableCell>
                            <TableCell>{appData.applicantTypeText}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Category of Service
                            </TableCell>
                            <TableCell>{displayCategory12(appData.category)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Certified Date
                            </TableCell>
                            <TableCell>{renderDateTime(appData.certifiedDate, true)}</TableCell>
                        </StyledTableRow>
                        {/*
                        TODO
                        {appData.FST_RetrievedDateTime &&
                            <>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold', textAlign: 'center' }} colSpan={2}>FST data</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>FST Retrieved On</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Review Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Total Pre-Discounted Amount</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Total Post-Discounted Amount</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            </>
                        }
                        */}
                        {/*
                        TODO
                        {appData.EPC_ScrapedDateTime &&
                            <>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold', textAlign: 'center' }} colSpan={2}>EPC data</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>EPC Scraped On</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Link to EPC page</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Review Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Window Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            </>
                        }
                        */}
                        {/*
                        TODO
                        {appData.Form471BI_LastUpdatedDateTime &&
                            <>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold', textAlign: 'center' }} colSpan={2}>Open Data Form 471 Basic Information dataset data</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Last Updated DateTime</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell component="th" sx={{ fontWeight: 'bold' }}>Window Status</TableCell>
                                <TableCell>{appData.XXXXXXXXXXXX}</TableCell>
                            </StyledTableRow>
                            </>
                        }
                        */}
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                ERC App Status
                            </TableCell>
                            <TableCell>{appData.form471app.erc_form471_app_status.status_text}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Application Owner
                            </TableCell>
                            <TableCell>{getUserDisplayName(appData.resolvedApplicationOwner)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Task Owner
                            </TableCell>
                            <TableCell>{getUsersDisplayNames(appData.form471app.task_users)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Date Sent to Client to Certify
                            </TableCell>
                            <TableCell>{convertYMDtoMDY(appData.form471app.date_sent_to_client_to_certify)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Form 471 App Notes
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                                {appData.form471app.form471_app_notes}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                Filing Window
                            </TableCell>
                            <TableCell>{appData.form471app.form471_filing_window.filing_window_name}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Submitter
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{qaSubmitterName}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Date Submitted
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{convertYMDtoMDY(qaDateSubmitted)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Date Needed
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{convertYMDtoMDY(qaDateNeeded)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Estimated Funding Request
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{qaEstimatedFundingRequest}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Status
                            </TableCell>
                            <TableCell>{qaStatusText}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Reviewer
                            </TableCell>
                            <TableCell>{qaReviewerName}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                QA Notes
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{qaNotes}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                User-entered Field(s) Updated By
                            </TableCell>
                            <TableCell>
                                {getUserDisplayName(appData.form471app.user_entered_field_updated_by)}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                User-entered Field(s) Updated On
                            </TableCell>
                            <TableCell>
                                {renderDateTime(appData.form471app.user_entered_field_updated_timestamp, true)}
                            </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>

                {/*
                Example from production site:

                Form 471 AppNum 	221002797
                Funding Year 	2022
                BEN 	120931
                Funding Program
                Applicant Type 	School District
                Nickname 	MSD- FY 2022- Category 1
                Category of Service 	1
                Certified Date 	2/16/2022 11:41:00 AM
                ---------------------------------------------------
                *****  FST data
                Retrieved On 	11/3/2022 6:16:30 AM -04:00
                Status 	Committed
                Review Status 	FCDL Issued
                Total Pre-Discounted Amount 	$107,268.36
                Total Post-Discounted Amount 	$85,814.69
                ---------------------------------------------------
                *****  EPC data
                Scraped On 	11/2/2022 5:04:48 PM -04:00
                Link to EPC page 	221002797
                Status 	Committed
                Review Status
                Window Status
                ---------------------------------------------------
                *****  Form471BI data
                Last Updated Date/Time
                Status
                Window Status
                ---------------------------------------------------
                ERC App Status 	Certified
                Application Owner 	Andy Eisley
                Task Owner
                Date Sent to Client to Certify
                Form 471 App Notes 	Continuation of existing services (IA and dark fiber maintenance). See MSD 2022 Funding Analysis for supporting information.
                Filing Window 	Traditional
                QA Status 	Will Not Be Reviewed
                QA Reviewer 	Carl Parker
                QA Notes
                User-entered Field(s) Updated By
                User-entered Field(s) Updated On 	3/14/2022 7:51:55 AM -04:00
                */}

                <Box sx={{ my: 2, mx: 'auto', width: '300px' }}>{createNavButtons()}</Box>
            </Box>

            {appData.fundingProgram !== 'ECF' && (
                <Stack sx={{ marginTop: '2em', marginBottom: '1em' }}>
                    <Typography variant='h3' mt={0} mb={1}>
                        FST data for Form 471 Application Number {form471AppNum} ({appData.nickname})
                    </Typography>
                    <FRNTable form471AppNum={Number(form471AppNum)} trackersAPI={trackersAPI} />
                </Stack>
            )}

            {appData.fundingProgram === 'ECF' && (
                <Typography variant='h3' mt={0} mb={0}>
                    No FST Data for ECF Application
                </Typography>
            )}
        </Box>
    );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

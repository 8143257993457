import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from '@mui/material/Select';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import {
    GridRowModes,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter,
    GridRowEditStopReasons,
} from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import removeAnyBooleanFilterValueWrapper from './customcomponents/RemoveAnyBooleanFilterValue.js';
import doesNotContainWrapper from './customcomponents/DoesNotContainFilter.js';
import IsEqualToColumnWrapper from './customcomponents/IsEqualToColumnFilter.js';
import IsNotEqualToColumnWrapper from './customcomponents/IsNotEqualToColumnFilter.js';
import IsGreaterThanColumnWrapper from './customcomponents/IsGreaterThanColumnFilter.js';
import IsGreaterThanOrEqualToColumnWrapper from './customcomponents/IsGreaterThanOrEqualToColumnFilter.js';
import IsLessThanColumnWrapper from './customcomponents/IsLessThanColumnFilter.js';
import IsLessThanOrEqualToColumnWrapper from './customcomponents/IsLessThanOrEqualToColumnFilter.js';
import IsAnyOfWrapper from './customcomponents/IsAnyOfFilter.js';
import IsNotAnyOfWrapper from './customcomponents/IsNotAnyOfFilter.js';
// import IsBeforeXDaysWrapper from './customcomponents/IsBeforeXDaysFilter.js';
// import IsAfterXDaysWrapper from './customcomponents/IsAfterXDaysFilter.js';
import { CustomExportButton } from './CustomDatagridToolbar.js';
import { InlineEditDropdown } from './customcomponents/InlineEditDropdown.js';
import { InlineEditTextarea1 } from './customcomponents/InlineEditTextarea.js';
// import { NotesCellDisplay } from './customcomponents/RenderCellExpand.js';
import { NotesCellDisplay } from './customcomponents/RenderCellExpandV2.js'; // NEW
import NotesEditDialog from './dialogs/NotesEditDialog.js';

const defaultHiddenColumns = '{"expected_number_of_minibids": false, "minibid_count": false}';
const defaultPinnedColumns = '{"left":["actions"],"right":[]}';

function ClientFundingYearDetailsToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            {/* <GridToolbarDensitySelector /> */}
            <CustomExportButton />
            <GridToolbarQuickFilter
                sx={{ marginLeft: 'auto', paddingRight: '4px' }}
                debounceMs={500}
                placeholder={'Quick filter...'}
            />
        </GridToolbarContainer>
    );
}

export function ClientFundingYearDetails({ clientAPI, clientId }) {
    const [cfyData, setCFYData] = React.useState(null);
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = React.useState(defaultHiddenColumns);

    // Makes the API call for the funding year data, sets it to cfyData
    React.useEffect(() => {
        const getCFYData = async (clientId) => {
            let data = await clientAPI.GetClientFundingYearData(clientId);
            data.sort((a, b) => b.funding_year - a.funding_year);
            setCFYData(data);
        };
        getCFYData(clientId);
    }, []);

    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    // Prevents anything from displaying if no data is present
    if (!cfyData) {
        return null;
    }

    function handleOnColumnVisibilityModelChange(newVizModel) {
        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    const local_t_and_tz_iso8601string = new Date().toISOString().substring(10);

    const renderCell_FormData = (
        fundingYear,
        ben,
        form470Count,
        form470InfoText,
        cellText,
        formType,
        usacAppStatus
    ) => {
        if (form470Count !== null) {
            if (form470Count > 0) {
                if (typeof cellText === 'string' || cellText instanceof String) {
                    return (
                        <>
                            {cellText}
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        {form470InfoText} <br />
                                        Exclude applications marked as 'Discarded Application' or 'ERC not Responsible
                                        for Filing'
                                    </span>
                                }
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <InfoOutlinedIcon color='info' fontSize='small' />
                                {/* <IconButton size='small'>
                                    <InfoOutlinedIcon color='info' fontSize='small' />
                                </IconButton> */}
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title='Open in new tab' placement='top'>
                                <Link
                                    href={`/${formType}/${formType}-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=${usacAppStatus}&applicationType=Regular`}
                                    target='_blank'
                                    rel='noreferrer'
                                    underline='hover'
                                    // style={{ textDecoration: 'none' }}
                                >
                                    {cellText}
                                </Link>
                            </Tooltip>
                            {/* {cellText} */}
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        {form470InfoText} <br />
                                        Excludes applications marked as 'Discarded Application' or 'ERC not Responsible
                                        for Filing'
                                    </span>
                                }
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <InfoOutlinedIcon color='info' fontSize='small' />
                                {/* <IconButton size='small'>
                                    <InfoOutlinedIcon color='info' fontSize='small' />
                                </IconButton> */}
                            </Tooltip>
                        </>
                    );
                }
            }
            return '-';
        }
        return '';
    };

    const renderCell_DraftForms = (fundingYear, ben, count, formType) => {
        return (
            <>
                {/* {count} */}
                <Tooltip title='Open in new tab' placement='top'>
                    <Link
                        href={`/${formType}/${formType}-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=1&applicationType=Regular`}
                        target='_blank'
                        rel='noreferrer'
                        underline='hover'
                        // style={{ textDecoration: 'none' }}
                    >
                        {count}
                    </Link>
                </Tooltip>
                <Tooltip
                    title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                            Excludes applications marked as 'Discarded Application' or 'ERC not Responsible for Filing'
                        </span>
                    }
                    sx={{ marginLeft: '0.5em' }}
                    placement='top-start'
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 900 }}
                >
                    <InfoOutlinedIcon color='info' fontSize='small' />
                    {/* <IconButton size='small'>
                        <InfoOutlinedIcon color='info' fontSize='small' />
                    </IconButton> */}
                </Tooltip>
            </>
            // <>
            //     <Tooltip title="Excludes 'Discarded' and 'ERC not Responsible for filing' applications"
            //         // sx={{ marginLeft: '0.5em' }}
            //         placement='top-start'
            //         arrow
            //         TransitionComponent={Fade}
            //         TransitionProps={{ timeout: 900 }}
            //     >
            //         <IconButton size="small"><span style={{ fontSize: '0.8em' }}>{count}</span></IconButton>
            //     </Tooltip>
            // </>
        );
    };

    const renderCell_MinibidData = (fundingYear, ben, count) => {
        return (
            <Tooltip title='Open in new tab' placement='top'>
                <Link
                    href={`/f470/f470-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=2&applicationType=Minibid`}
                    target='_blank'
                    rel='noreferrer'
                    underline='hover'
                    // style={{ textDecoration: 'none' }}
                >
                    {count}
                </Link>
            </Tooltip>
        );
    };

    const columns = [
        {
            headerName: 'Funding Year',
            renderHeader: (params) => renderMultilineHeader(params, 'Funding', 'Year'),
            field: 'funding_year',
            headerAlign: 'center',
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            cellClassName: 'centered-cell',
            width: 100,
        },
        {
            headerName: 'Expected # of Form 470s',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Form 470s'),
            field: 'expected_number_of_form470s',
            headerAlign: 'center',
            type: 'number', // Sort as a number..
            valueFormatter: (params) => {
                if (params.value < 0) {
                    return 'Client Not Contacted';
                }
                return params.value;
            },
            cellClassName: (params) =>
                params.value < 0 ? 'ExpectedNumberOfForm470s-false centered-cell' : 'centered-cell',
            width: 100,
        },
        {
            headerName: 'Draft # of Forms 470',
            field: 'form470_draft_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Draft # of', 'Forms 470'),
            renderCell: (params) => {
                if (params.value === null) return '';
                return params.value > 0
                    ? renderCell_DraftForms(params.row.funding_year, params.row.bens[0], params.value, 'f470')
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Certified Form 470 Count',
            field: 'form470_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 470', 'Count'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form470_count,
                    params.row.form470_categories_info_string,
                    params.value,
                    'f470',
                    2
                ),
            width: 90,
        },
        {
            headerName: 'Certified Form 470 Categories',
            field: 'form470_categories_string',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 470', 'Categories'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form470_count,
                    params.row.form470_categories_info_string,
                    params.value,
                    'f470',
                    2
                ),
            width: 90,
        },
        {
            headerName: 'Expected # of Mini-Bids',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Mini-Bids'),
            field: 'expected_number_of_minibids',
            headerAlign: 'center',
            type: 'number', // Sort as a number..
            valueFormatter: (params) => params.value,
            cellClassName: 'centered-cell',
            width: 100,
        },
        {
            headerName: 'Created # of Mini-Bids',
            field: 'minibid_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Created # of', 'Mini-Bids'),
            renderCell: (params) => {
                if (params.value === null) return '';
                return params.value > 0
                    ? renderCell_MinibidData(params.row.funding_year, params.row.bens[0], params.value)
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Form 470 Status (per-Applicant, per-FY)',
            renderHeader: (params) => renderMultilineHeader(params, 'Form 470 Status', '(per-Applicant, per-FY)'),
            field: 'form470_afystatus_text',
            cellClassName: 'centered-cell',
            headerAlign: 'center',
            type: 'string',
            width: 200,
        },
        {
            headerName: 'Form 470 Initial Outreach Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Form 470', 'Initial Outreach Date'),
            field: 'form470_initialoutreachdate',
            headerAlign: 'center',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Input is a string in the format "yyyy-mm-dd".
                // Convert it to a javascript Date object and set it the client's local time and timezone.
                // Otherwise Date assumes "T00:00:00.000Z" which causes an off-by-one-day issue when Datagrid displays it.
                // Needs to be a javascript Date object for filtering and sorting.
                // return new Date(params.value + local_t_and_tz_iso8601string);
                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                ); //DB date -> JS Date conversion: adjust UTC by local timezone offset to be shown properly in DataGrid
            },
            cellClassName: 'centered-cell',
            width: 200,
        },
        {
            headerName: '470 Notes',
            field: 'form470_tracker_notes',
            headerAlign: 'center',
            renderCell: (params) => (
                <NotesCellDisplay
                    notes={params.value}
                    minPopupWidth={500}
                    computedWidth={params.colDef.computedWidth}
                    rowHeightPref={'auto'}
                />
            ),
            minWidth: 350,
            flex: 1,
        },
        // {
        //     headerName: 'Not Filing Form 471',
        //     renderHeader: (params) => renderMultilineHeader(params, 'Not Filing', 'Form 471'),
        //     field: 'form471_will_not_be_filed',
        //     cellClassName: 'centered-cell',
        //     headerAlign: 'center',
        //     renderCell: (params) => <>{params.value === true ? 'X' : params.value === false ? '' : '-'}</>,
        //     type: 'boolean',
        //     width: 100,
        // },
        {
            headerName: 'Expected # of Forms471',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Forms 471'),
            field: 'expected_number_of_form471s',
            headerAlign: 'center',
            type: 'number', // Sort as a number..
            valueFormatter: (params) => {
                if (params.value < 0) {
                    return 'Client Not Contacted';
                }
                return params.value;
            },
            cellClassName: (params) =>
                params.value < 0 ? 'ExpectedNumberOfForm471s-false centered-cell' : 'centered-cell',
            width: 120,
        },
        {
            headerName: 'Draft # of Forms 471',
            field: 'form471_draft_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Draft # of', 'Forms 471'),
            renderCell: (params) => {
                if (params.value === null) return '';
                // console.log('[form471DraftCount]  params = ', params, ' | draft params.value = ', params.value);
                return params.value > 0
                    ? renderCell_DraftForms(params.row.funding_year, params.row.bens[0], params.value, 'f471')
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Certified Form 471 Count',
            field: 'form471_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 471', 'Count'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form471_count,
                    params.row.form471_categories_info_string,
                    params.value,
                    'f471',
                    23
                ),
            width: 90,
        },
        {
            headerName: 'Certified Form 471 Categories',
            field: 'form471_categories_string',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 471', 'Categories'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form471_count,
                    params.row.form471_categories_info_string,
                    params.value,
                    'f471',
                    23
                ),
            width: 90,
        },
        {
            headerName: '471 Notes',
            field: 'form471_tracker_notes',
            headerAlign: 'center',
            renderCell: (params) => (
                <NotesCellDisplay
                    notes={params.value}
                    minPopupWidth={500}
                    computedWidth={params.colDef.computedWidth}
                    rowHeightPref={'auto'}
                />
            ),
            minWidth: 350,
            flex: 1,
        },
        {
            headerName: 'Last Updated By',
            field: 'user_entered_field_updated_by_user_name',
            headerAlign: 'center',
            cellClassName: 'centered-cell',
            width: 200,
        },
        {
            headerName: 'Last Modified On',
            field: 'user_entered_field_updated_timestamp',
            headerAlign: 'center',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            cellClassName: 'centered-cell',
            width: 200,
        },
    ];

    return (
        <DataGridPro
            components={{ Toolbar: ClientFundingYearDetailsToolbar }}
            columns={columns}
            rows={cfyData}
            disableSelectionOnClick
            getRowId={(row) => row.funding_year}
            loading={cfyData === null}
            autoHeight={true}
            getRowHeight={() => 'auto'}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'funding_year', sort: 'desc' }],
                },
            }}
            columnVisibilityModel={JSON.parse(hiddenColumnPrefs)}
            onColumnVisibilityModelChange={(newVizModel) => handleOnColumnVisibilityModelChange(newVizModel)}
            sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell': {
                    border: '1px solid rgba(224, 224, 224, 1)',
                },
                '& .MuiDataGrid-row:nth-of-type(2n)': {
                    backgroundColor: '#f0f2f4',
                },
                '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                    backgroundColor: '#fdfffe',
                },
                '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                    backgroundColor: '#fdfffe',
                },
                '& .centered-cell': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
        />
    );
}

function ClientFundingYearEditToolbar(props) {
    const { isFundingYearInCFYData, onAddNewRecordClick } = props;
    const [selectedYear, setSelectedYear] = React.useState(null);
    const [fundingYearsMS, setFundingYearsMS] = React.useState([]);
    const [popperOpenStatus, setPopperOpenStatus] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const current_year = new Date().getFullYear();

    const yearsRangeList = () => {
        let temp = [];
        for (let i = 2016; i <= current_year + 1; i++) {
            if (isFundingYearInCFYData(i)) {
                continue;
            }
            temp.push(i);
        }
        temp.sort((a, b) => b - a);
        setFundingYearsMS(temp);
    };

    const handleFundingYearSelect = (event) => {
        setSelectedYear(event.target.value);
    };

    React.useEffect(() => {
        yearsRangeList();
        setSelectedYear(fundingYearsMS[0]);
    }, []);

    const handleNewRecordClick = (event) => {
        yearsRangeList();
        setSelectedYear(fundingYearsMS[0]);
        setAnchorEl(event.currentTarget);
        setPopperOpenStatus(true);
    };

    const handleClose = () => {
        if (menuOpen) {
            return;
        } // needed for the onClickAway
        setPopperOpenStatus(false);
    };

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const addRecord = () => {
        if (selectedYear == null) {
            toast.error('You must select a funding year to add a new record.');
            return;
        }
        if (onAddNewRecordClick) {
            onAddNewRecordClick(selectedYear);
        }
    };

    return (
        <GridToolbarContainer>
            {/* Handles adding a new record */}
            <Button
                color='primary'
                startIcon={<AddIcon />}
                disabled={fundingYearsMS.length === 0}
                onClick={handleNewRecordClick}
            >
                Add New Record
            </Button>
            <Popper id='popper' open={popperOpenStatus} anchorEl={anchorEl} style={{ zIndex: 999 }}>
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper
                        square={true}
                        elevation={5}
                        style={{ position: 'relative', zIndex: 999, padding: '0.01em', marginLeft: '-20px' }}
                    >
                        <Select
                            value={selectedYear}
                            defaultValue={fundingYearsMS[0]}
                            onChange={handleFundingYearSelect}
                            onOpen={handleMenuOpen}
                            onClose={handleMenuClose}
                            style={{ borderRadius: 0 }}
                        >
                            {fundingYearsMS.map((each_year) => (
                                <MenuItem key={each_year} value={each_year}>
                                    {each_year}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            color='primary'
                            onClick={() => {
                                addRecord();
                                handleClose();
                            }}
                        >
                            Add
                        </Button>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            {/* <GridToolbarDensitySelector /> */}
            <CustomExportButton />
            <GridToolbarQuickFilter
                sx={{ marginLeft: 'auto', paddingRight: '4px' }}
                debounceMs={500}
                placeholder={'Quick filter...'}
            />
        </GridToolbarContainer>
    );
}

export function ClientFundingYearEdit({ bens, clientAPI, clientId, username }) {
    const [cfyData, setCFYData] = React.useState(null);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [afyStatusEditOptions, setAfyStatusEditOptions] = React.useState([]);
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = React.useState(defaultHiddenColumns);
    const [pinnedColumnPrefs, setPinnedColumnPrefs] = React.useState(defaultPinnedColumns);

    // ################################### NOTES STATE #############################################
    const apiRef = useGridApiRef();
    const [openNotesEditDialog, setOpenNotesEditDialog] = React.useState(false);
    const [editableNotes, setEditableNotes] = React.useState('');
    const [notesEditRowId, setNotesEditRowId] = React.useState(null); // Used in saveEditedNotes function.
    const [notesFieldName, setNotesFieldName] = React.useState('');
    const [notesEditDialogTitle, setNotesEditDialogTitle] = React.useState('');
    const [notesEditDialogLabel, setNotesEditDialogLabel] = React.useState('');

    React.useEffect(() => {
        // Grabs the client's funding year data
        const getCFYData = async (clientId) => {
            let data = await clientAPI.GetClientFundingYearData(clientId);
            console.log('ClientFundingYear data: \r\n', data);

            data = data.map((row) => {
                return { ...row, isNew: false }; // Potential - Add a flag indicating that this is an existing record/row.
            });
            data.sort((a, b) => b.funding_year - a.funding_year);
            setCFYData(data);
        };

        // Grabs the AFY statuses
        const fetchInlineEditOptions = async () => {
            try {
                let result = await clientAPI.GetClientFundingYearEditOptionsData();
                // console.log('result.form470AFYStatuses = ');
                // console.log(result.form470AFYStatuses);
                setAfyStatusEditOptions(result.form470AFYStatuses);
            } catch (error) {
                console.error('fetchInlineEditOptions error: ', error);
                toast.error(error);
            }
        };

        fetchInlineEditOptions();
        getCFYData(clientId);
    }, []);

    // Used to check if the current year is already in the retrieved CFY data (true/false)
    const isFundingYearInCFYData = (fy) => {
        return cfyData.some((row) => row.funding_year === fy);
    };

    const handleEditClick = (funding_year) => () => {
        setRowModesModel({ ...rowModesModel, [funding_year]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (funding_year) => () => {
        setRowModesModel({ ...rowModesModel, [funding_year]: { mode: GridRowModes.View } });
    };

    const usernameForNotesEditDialog = username ? username : '';

    const handleEditApplicationNotes = (client_id, funding_year, fieldName, currentNotes) => {
        setNotesEditRowId(`${client_id}-${funding_year}`);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(`Edit Note for Client: ${client_id}, Funding Year: ${funding_year}`);
        setNotesEditDialogLabel('Application Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };

    const updateCFYData_WithNewForm470TrackerNotes = (notesEditRowId, apiResponse, modifiedOn, modifiedBy) => {
        let updatedTrackerData = cfyData.map((row) => {
            if (`${row.client_id}-${row.funding_year}` === notesEditRowId) {
                return {
                    ...row,
                    form470_tracker_notes: apiResponse['record']['form470_tracker_notes'],
                    form471_tracker_notes: apiResponse['record']['form471_tracker_notes'],
                    user_entered_field_updated_timestamp: modifiedOn,
                    user_entered_field_updated_by_user_name: modifiedBy,
                    isNew: apiResponse['record']['isNew'],
                    expected_number_of_form470s: apiResponse['record']['expected_number_of_form470s'],
                    expected_number_of_form471s: apiResponse['record']['expected_number_of_form471s'],
                    form471_will_not_be_filed: apiResponse['record']['form471_will_not_be_filed'],
                    form470_afystatus_id: apiResponse['record']['form470_afystatus_id'],
                    form470_afystatus_text: apiResponse['record']['form470_afystatus_text'],
                    form470_initialoutreachdate: apiResponse['record']['form470_initialoutreachdate'],
                    expected_number_of_minibids: apiResponse['record']['expected_number_of_minibids'],
                };
            }
            return row;
        });
        setCFYData(updatedTrackerData);
    };

    const updateCFYData_WithNewForm471TrackerNotes = (rowId, apiResponse, modifiedOn, modifiedBy) => {
        let updatedTrackerData = cfyData.map((row) => {
            if (`${row.client_id}-${row.funding_year}` === rowId) {
                return {
                    ...row,
                    form470_tracker_notes: apiResponse['record']['form470_tracker_notes'],
                    form471_tracker_notes: apiResponse['record']['form471_tracker_notes'],
                    user_entered_field_updated_timestamp: modifiedOn,
                    user_entered_field_updated_by_user_name: modifiedBy,
                    isNew: apiResponse['record']['isNew'],
                    expected_number_of_form470s: apiResponse['record']['expected_number_of_form470s'],
                    expected_number_of_form471s: apiResponse['record']['expected_number_of_form471s'],
                    form471_will_not_be_filed: apiResponse['record']['form471_will_not_be_filed'],
                    form470_afystatus_id: apiResponse['record']['form470_afystatus_id'],
                    form470_afystatus_text: apiResponse['record']['form470_afystatus_text'],
                    form470_initialoutreachdate: apiResponse['record']['form470_initialoutreachdate'],
                    expected_number_of_minibids: apiResponse['record']['expected_number_of_minibids'],
                };
            }
            return row;
        });
        setCFYData(updatedTrackerData);
    };

    const handleNotesEditDialogClose = () => {
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const handleNotesEditDialogCancel = () => {
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const cleanupNotesEditDialogStateVars = () => {
        setNotesEditRowId(null);
        setNotesFieldName('');
        setNotesEditDialogLabel('');
        setNotesEditDialogTitle('');
        setEditableNotes('');
    };

    const handleNotesEditDialogSave = (newNotes) => {
        //console.log('[handleNotesEditDialogSave] ');
        saveNewNotes(newNotes);
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const saveNewNotes = async (newNotes) => {
        let fieldTitle = '';

        // Determine the field title of the notes that was edited. (And sanity check notesFieldName.)
        if (notesFieldName === 'form470_tracker_notes') {
            fieldTitle = 'Form 470 Notes';
        } else if (notesFieldName === 'form471_tracker_notes') {
            fieldTitle = 'Form 471 Notes';
        } else {
            console.error(`An Error Occurred. Invalid notesFieldName: ${notesFieldName}`);
            toast.error(`An Error Occurred. (Invalid notesFieldName.)`);
            return;
        }

        // Find the record to edit/update in `cfyData`
        const specificRow = cfyData.find(
            (row) => `${row.client_id}-${row.funding_year}` === notesEditRowId // [saveNewNotes] setNotesEditRowId
        );
        // console.log('[saveNewNotes][specificRow] =', specificRow);

        const uedata = {
            client_id: specificRow.client_id,
            funding_year: specificRow.funding_year,
            fieldToChange: notesFieldName,
            newNotes: newNotes,
        };
        // console.log('[saveNewNotes][uedata] =', uedata);

        const apiResponse = await clientAPI.SaveClientFundingYearRowNotes(uedata);
        console.log('[saveNewNotes][apiResponse] =', apiResponse);
        if (apiResponse) {
            apiResponse['record']['isNew'] = false;

            const modifiedOn = apiResponse['record']['user_entered_field_updated_timestamp'];
            const modifiedBy = apiResponse['record']['user_entered_field_updated_by_user_name'];
            // Update the data locally.
            if (notesFieldName === 'form470_tracker_notes') {
                updateCFYData_WithNewForm470TrackerNotes(notesEditRowId, apiResponse, modifiedOn, modifiedBy);
            } else if (notesFieldName === 'form471_tracker_notes') {
                updateCFYData_WithNewForm471TrackerNotes(notesEditRowId, apiResponse, modifiedOn, modifiedBy);
            }

            toast.success(`Successfully updated the ${fieldTitle}`, {
                autoClose: 3000,
            });
        } else {
            toast.error(`Failed to update the ${fieldTitle}`);
        }
    };

    const handleCancelClick = (funding_year) => () => {
        setRowModesModel({
            ...rowModesModel,
            [funding_year]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = cfyData.find((row) => row.funding_year === funding_year);
        if (editedRow.isNew) {
            setCFYData(cfyData.filter((row) => row.funding_year !== funding_year));
        }
    };

    const handleToolbarAddNewRowClick = (selectedYear) => {
        const new_row = {
            bens: bens,
            client_id: clientId,
            funding_year: selectedYear,
            isNew: true,
            expected_number_of_form470s: -1, // to be transformed to null in save lambda
            expected_number_of_form471s: -1,
            form471_will_not_be_filed: false,
            form470_afystatus_id: 1,
            form470_afystatus_text: '', // needed for display.
            form470_initialoutreachdate: null,
            expected_number_of_minibids: null,
            form470_tracker_notes: '',
            form471_tracker_notes: '',
            user_entered_field_updated_by_user_name: '', // read-only. just display blank.
            user_entered_field_updated_timestamp: '', // read-only. just display blank.
        };
        setCFYData((prevRows) => [...prevRows, new_row]);
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [selectedYear]: { mode: GridRowModes.Edit, fieldToFocus: 'expected_number_of_form470s' }, // ID: Options for specific row
        }));
    };

    const processRowUpdate = React.useCallback(async (newRow, oldRow) => {
        // console.log('processRowUpdate | oldRow:', oldRow);
        // console.log('processRowUpdate | newRow:', newRow);
        // isNew is not present in the [saveNewNotes][apiResponse] =... log
        // isNew is present in both newRow and oldRow
        // Sanity check values
        if (newRow.isNew !== true && newRow.isNew !== false) {
            return Promise.reject(new Error('"isNew" value is not valid: ' + newRow.isNew)); // For some reason, SOMETIMES both oldRow and newRod have "underfined" for isNew
        }

        // console.log("~ newRow.form470_initialoutreachdate = ", newRow.form470_initialoutreachdate);
        // console.log("~ typeof newRow.form470_initialoutreachdate = ", typeof newRow.form470_initialoutreachdate);

        // Create data object to send
        let data = {
            client_id: newRow.client_id,
            funding_year: newRow.funding_year,
            isNew: newRow.isNew,
            expected_number_of_form470s:
                newRow.expected_number_of_form470s < -1 ? -1 : newRow.expected_number_of_form470s,
            form470_afystatus_id: newRow.form470_afystatus_id,
            form470_initialoutreachdate: newRow.form470_initialoutreachdate, // This should be a string in the format "yyyy-mm-dd".
            expected_number_of_minibids: newRow.expected_number_of_minibids,
            form470_tracker_notes: newRow.form470_tracker_notes,
            expected_number_of_form471s:
                newRow.expected_number_of_form471s < -1 ? -1 : newRow.expected_number_of_form471s,
            // form471_will_not_be_filed: newRow.form471_will_not_be_filed,
            form471_will_not_be_filed:
                newRow.expected_number_of_form471s === null || newRow.expected_number_of_form471s !== 0 ? false : true,
            form471_tracker_notes: newRow.form471_tracker_notes,
        };
        //console.log('processRowUpdate | uedata = ', data);

        let apiResponse = await clientAPI.SaveClientFundingYearRowData(data);
        console.log('processRowUpdate | apiResponse:', apiResponse);

        if (apiResponse === false) {
            console.error('Unknown error while saving.', apiResponse);
            return Promise.reject(new Error('Unknown error while saving.'));
        }

        if (apiResponse.error === true) {
            console.error(apiResponse.errorMessage || 'Error while saving.');
            return Promise.reject(new Error(apiResponse.errorMessage || 'Error while saving.'));
        }

        // console.log('processRowUpdate | apiResponse.record = ', apiResponse.record);
        // Potential - isNew
        let rowdatatoreturn = {
            ...apiResponse.record,
            isNew: false,
        };
        if (apiResponse) {
            toast.success(`FY ${newRow.funding_year} record has been successfully updated.`, {
                autoClose: 3000,
            });
        }

        // console.log('rowdatatoreturn = ', rowdatatoreturn);

        return rowdatatoreturn;
    }, []);

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.error('error.message = ', error.message);
        toast.error('Error saving data: ' + error.message);
    }, []);

    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    function handleOnColumnVisibilityModelChange(newVizModel) {
        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    function handlePinnedPrefs(newPinModel) {
        setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    // Converts the status options into [{ value: 'X', label: 'Y' }]
    //console.log(afyStatusEditOptions);
    const form470Status_DataGridFilterOptions = afyStatusEditOptions.map((opt) => {
        if (opt.text === '<not set>') {
            return { value: '-', label: '<no status>' };
        } else {
            return { value: opt.text, label: opt.text };
        }
    });

    const local_t_and_tz_iso8601string = new Date().toISOString().substring(10);

    // Gets a "yyyy-mm-dd" string from a javascript date.
    const get_YMDString_from_JavascriptDate = (jsdate) => {
        //console.log('jsdate = ', jsdate);
        if (!jsdate) {
            return null;
        }
        if (!(jsdate instanceof Date)) {
            return null;
        }
        let offset = jsdate.getTimezoneOffset();
        let jsdate2 = new Date(jsdate.getTime() - offset * 60 * 1000); // remove offset
        let yyyymmddstring = jsdate2.toISOString().substring(0, 10); // or jsdate2.toISOString().split('T')[0];
        //console.log('yyyymmddstring = ', yyyymmddstring);
        return yyyymmddstring;
    };

    if (!cfyData) {
        return null;
    }

    const renderCell_FormData = (
        fundingYear,
        ben,
        form470Count,
        form470InfoText,
        cellText,
        formType,
        usacAppStatus
    ) => {
        if (form470Count !== null) {
            if (form470Count > 0) {
                if (typeof cellText === 'string' || cellText instanceof String) {
                    return (
                        <>
                            {cellText}
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        {form470InfoText} <br />
                                        Exclude applications marked as 'Discarded Application' or 'ERC not Responsible
                                        for Filing'
                                    </span>
                                }
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <InfoOutlinedIcon color='info' fontSize='small' />
                                {/* <IconButton size='small'>
                                    <InfoOutlinedIcon color='info' fontSize='small' />
                                </IconButton> */}
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title='Open in new tab' placement='top'>
                                <Link
                                    href={`/${formType}/${formType}-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=${usacAppStatus}&applicationType=Regular`}
                                    target='_blank'
                                    rel='noreferrer'
                                    underline='hover'
                                    // style={{ textDecoration: 'none' }}
                                >
                                    {cellText}
                                </Link>
                            </Tooltip>
                            {/* {cellText} */}
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        {form470InfoText} <br />
                                        Excludes applications marked as 'Discarded Application' or 'ERC not Responsible
                                        for Filing'
                                    </span>
                                }
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <InfoOutlinedIcon color='info' fontSize='small' />
                                {/* <IconButton size='small'>
                                    <InfoOutlinedIcon color='info' fontSize='small' />
                                </IconButton> */}
                            </Tooltip>
                        </>
                    );
                }
            }
            return '-';
        }
        return '';
    };

    const renderCell_DraftForms = (fundingYear, ben, count, formType) => {
        return (
            <>
                {/* {count} */}
                <Tooltip title='Open in new tab' placement='top'>
                    <Link
                        href={`/${formType}/${formType}-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=1&applicationType=Regular`}
                        target='_blank'
                        rel='noreferrer'
                        underline='hover'
                        // style={{ textDecoration: 'none' }}
                    >
                        {count}
                    </Link>
                </Tooltip>
                <Tooltip
                    title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                            Excludes applications marked as 'Discarded Application' or 'ERC not Responsible for Filing'
                        </span>
                    }
                    sx={{ marginLeft: '0.5em' }}
                    placement='top-start'
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 900 }}
                >
                    <InfoOutlinedIcon color='info' fontSize='small' />
                    {/* <IconButton size='small'>
                        <InfoOutlinedIcon color='info' fontSize='small' />
                    </IconButton> */}
                </Tooltip>
            </>
            // <>
            //     <Tooltip title="Excludes 'Discarded' and 'ERC not Responsible for filing' applications"
            //         // sx={{ marginLeft: '0.5em' }}
            //         placement='top-start'
            //         arrow
            //         TransitionComponent={Fade}
            //         TransitionProps={{ timeout: 900 }}
            //     >
            //         <IconButton size="small"><span style={{ fontSize: '0.8em' }}>{count}</span></IconButton>
            //     </Tooltip>
            // </>
        );
    };

    const renderCell_MinibidData = (fundingYear, ben, count) => {
        return (
            <Tooltip title='Open in new tab' placement='top'>
                <Link
                    href={`/f470/f470-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=2&applicationType=Minibid`}
                    target='_blank'
                    rel='noreferrer'
                    underline='hover'
                    // style={{ textDecoration: 'none' }}
                >
                    {count}
                </Link>
            </Tooltip>
        );
    };

    const originalColumns = [
        // {
        //     headerName: 'isNew',
        //     field: 'isNew'
        // },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            cellClassName: 'actions',
            getActions: (params) => {
                // params = GridRowParams (columns, id, row), passes in the funding_year value as the id
                // console.log('[originalColumns][getActions] rowModesModel:', rowModesModel);
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <Tooltip title='Save' key={`save-${params.id}`}>
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label='Save'
                                onClick={handleSaveClick(params.id)}
                                // color='inherit'
                                style={{ color: '#4CAF50' }}
                            />
                        </Tooltip>,
                        <Tooltip title='Cancel' key={`cancel-${params.id}`}>
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label='Cancel'
                                onClick={handleCancelClick(params.id)}
                                // color='inherit'
                                style={{ color: 'red' }}
                            />
                        </Tooltip>,
                    ];
                }

                return [
                    <Tooltip title='Edit' key={`edit-${params.id}`}>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label='Edit'
                            className='textPrimary'
                            onClick={handleEditClick(params.id)}
                            color='primary'
                        />
                    </Tooltip>,
                ];
            },
            width: 70,
            filterable: false,
            sortable: false,
        },
        {
            headerName: 'Funding Year',
            renderHeader: (params) => renderMultilineHeader(params, 'Funding', 'Year'),
            field: 'funding_year',
            headerAlign: 'center',
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            cellClassName: 'centered-cell',
            width: 100,
        },
        {
            headerName: 'Expected # of Form 470s',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Form 470s'),
            field: 'expected_number_of_form470s',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'number', // Sort as a number..
            valueFormatter: (params) => {
                if (params.value < 0) {
                    return 'Client Not Contacted';
                }
                return params.value;
            },
            cellClassName: (params) =>
                params.value < 0 ? 'ExpectedNumberOfForm470s-false centered-cell' : 'centered-cell',
            width: 100,
        },
        {
            headerName: 'Draft # of Forms 470',
            field: 'form470_draft_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Draft # of', 'Forms 470'),
            renderCell: (params) => {
                if (params.value === null) return '';
                // console.log('[form471DraftCount]  params = ', params, ' | draft params.value = ', params.value);
                return params.value > 0
                    ? renderCell_DraftForms(params.row.funding_year, params.row.bens[0], params.value, 'f470')
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Certified Form 470 Count',
            field: 'form470_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 470', 'Count'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form470_count,
                    params.row.form470_categories_info_string,
                    params.value,
                    'f470',
                    2
                ),
            width: 90,
        },
        {
            headerName: 'Certified Form 470 Categories',
            field: 'form470_categories_string',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 470', 'Categories'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form470_count,
                    params.row.form470_categories_info_string,
                    params.value,
                    'f470',
                    2
                ),
            width: 90,
        },
        {
            headerName: 'Expected # of Mini-Bids',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Mini-Bids'),
            field: 'expected_number_of_minibids',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'number', // Sort as a number..
            valueFormatter: (params) => params.value,
            cellClassName: 'centered-cell',
            width: 100,
        },
        {
            headerName: 'Created # of Mini-Bids',
            field: 'minibid_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Created # of', 'Mini-Bids'),
            renderCell: (params) => {
                if (params.value === null) return '';
                return params.value > 0
                    ? renderCell_MinibidData(params.row.funding_year, params.row.bens[0], params.value)
                    : '-';
            },
            width: 90,
        },

        {
            headerName: 'Form 470 Status (per-Applicant, per-FY)',
            renderHeader: (params) => renderMultilineHeader(params, 'Form 470 Status', '(per-Applicant, per-FY)'),
            field: 'form470_afystatus_text',
            valueGetter: ({ value }) => (value === '' ? '-' : value),
            editable: true,
            renderEditCell: (params) => {
                // console.log("STATUS PARAMS:", params);
                // console.log('params.id = ', params.id);
                // console.log('params.field = ', params.field);
                // console.log('params.row = ', params.row);
                // console.log('params.row.form470_afystatus_id = ', params.row.form470_afystatus_id);
                // console.log('params.row.form470_afystatus_text = ', params.row.form470_afystatus_text);
                // console.log('afyStatusEditOptions = ', afyStatusEditOptions);
                let selectedid = params.row.form470_afystatus_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id} // the funding year
                        value={selectedid} // integer representing the status text
                        field={params.field} // field name header
                        dropDownOptions={afyStatusEditOptions} // list of status texts
                    />
                );
            },
            valueSetter: (params) => {
                // console.log('[valueSetter]  params.value = ', params.value);
                if (Number.isInteger(params.value)) {
                    // console.log('[valueSetter]  ' + params.value + ' is an integer.')
                    const form470_afystatus_id = params.value;
                    return { ...params.row, form470_afystatus_id };
                }
                return { ...params.row };
            },
            type: 'singleSelect', // For DataGrid Filter.
            valueOptions: form470Status_DataGridFilterOptions, // For DataGrid Filter.
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'centered-cell',
            width: 200,
        },
        {
            headerName: 'Form 470 Initial Outreach Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Form 470', 'Initial Outreach Date'),
            field: 'form470_initialoutreachdate',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Input is a string in the format "yyyy-mm-dd".
                // Convert it to a javascript Date object and set it the client's local time and timezone.
                // Otherwise Date assumes "T00:00:00.000Z" which causes an off-by-one-day issue when Datagrid displays it.
                // Needs to be a javascript Date object for filtering and sorting.
                // return new Date(params.value + local_t_and_tz_iso8601string);
                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                ); //DB date -> JS Date conversion: adjust UTC by local timezone offset to be shown properly in DataGrid
            },
            valueSetter: (params) => {
                // console.log('[form470_initialoutreachdate  valueSetter]  params.value = ', params.value);
                // console.log('[form470_initialoutreachdate  valueSetter]  typeof params.value = ', (typeof params.value));
                // console.log('[form470_initialoutreachdate  valueSetter]  params = ', params);
                if (params.value === null) {
                    return { ...params.row, form470_initialoutreachdate: null };
                }
                if (params.value instanceof Date) {
                    // Convert javascript Date back into "yyy-mm-dd" string.
                    // Example:  Thu Jan 02 2003 00:00:00 GMT-0500 (Eastern Standard Time)
                    // let yyyymmddstring = get_YMDString_from_JavascriptDate(params.value);
                    // return { ...params.row, form470_initialoutreachdate: yyyymmddstring };
                    return {
                        ...params.row,
                        form470_initialoutreachdate: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    }; //JS Date -> DB date: adjust UTC by local timezone offset to be saved properly to DB
                }
                return { ...params.row };
            },
            cellClassName: 'centered-cell',
            width: 200,
        },
        {
            headerName: '470 Notes',
            field: 'form470_tracker_notes',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            renderCell: (params) => {
                // console.log('[form470_tracker_notes] params.id:', params.id); // Just returns the funding year itself (value of params.row.funding_year)
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={''}
                        onEdit={() =>
                            handleEditApplicationNotes(
                                params.row.client_id, // client_id value, composite key
                                params.id, // params.row.funding_year, composite key
                                params.field,
                                params.value
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            editable: false, // Saved to ClientFundingYear.Form471TrackerNotes by client_id and fundingYear.
            renderEditCell: (params) => {
                return <InlineEditTextarea1 {...params} />;
            },
            minWidth: 350,
            flex: 1,
        },
        // {
        //     headerName: 'Not Filing Form 471',
        //     renderHeader: (params) => renderMultilineHeader(params, 'Not Filing', 'Form 471'),
        //     field: 'form471_will_not_be_filed',
        //     headerAlign: 'center',
        //     headerClassName: 'Editable-NotRequired',
        //     type: 'boolean',
        //     editable: true,
        //     renderCell: (params) => <>{params.value === true ? 'X' : params.value === false ? '' : '-'}</>,
        //     cellClassName: 'centered-cell',
        //     width: 100,
        // },
        {
            headerName: 'Expected # of Forms471',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Forms 471'),
            field: 'expected_number_of_form471s',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'number', // Sort as a number..
            valueFormatter: (params) => {
                if (params.value < 0) {
                    return 'Client Not Contacted';
                }
                return params.value;
            },
            cellClassName: (params) =>
                params.value < 0 ? 'ExpectedNumberOfForm471s-false centered-cell' : 'centered-cell',
            width: 120,
        },
        {
            headerName: 'Draft # of Forms 471',
            field: 'form471_draft_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Draft # of', 'Forms 471'),
            renderCell: (params) => {
                if (params.value === null) return '';
                // console.log('[form471DraftCount]  params = ', params, ' | draft params.value = ', params.value);
                return params.value > 0
                    ? renderCell_DraftForms(params.row.funding_year, params.row.bens[0], params.value, 'f471')
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Certified Form 471 Count',
            field: 'form471_count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 471', 'Count'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form471_count,
                    params.row.form471_categories_info_string,
                    params.value,
                    'f471',
                    23
                ),
            width: 90,
        },
        {
            headerName: 'Certified Form 471 Categories',
            field: 'form471_categories_string',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 471', 'Categories'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.funding_year,
                    params.row.bens[0],
                    params.row.form471_count,
                    params.row.form471_categories_info_string,
                    params.value,
                    'f471',
                    23
                ),
            width: 90,
        },
        {
            headerName: '471 Notes',
            field: 'form471_tracker_notes',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            renderCell: (params) => {
                // console.log('[form471_tracker_notes] params.id:', params.id); // Just returns the funding year itself (value of params.row.funding_year)
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={''}
                        onEdit={() =>
                            handleEditApplicationNotes(
                                params.row.client_id, // client_id value, composite key
                                params.id, // params.row.funding_year, composite key
                                params.field,
                                params.value
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            editable: false, // Saved to ClientFundingYear.Form471TrackerNotes by client_id and fundingYear.
            renderEditCell: (params) => {
                return <InlineEditTextarea1 {...params} />;
            },
            minWidth: 350,
            flex: 1,
        },
        {
            headerName: 'Last Updated By',
            field: 'user_entered_field_updated_by_user_name',
            headerAlign: 'center',
            cellClassName: 'centered-cell',
            width: 200,
        },
        {
            headerName: 'Last Modified On',
            field: 'user_entered_field_updated_timestamp',
            headerAlign: 'center',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            cellClassName: 'centered-cell',
            width: 200,
        },
    ];

    let columnsV2 = IsLessThanOrEqualToColumnWrapper(
        IsLessThanColumnWrapper(
            IsGreaterThanOrEqualToColumnWrapper(
                IsGreaterThanColumnWrapper(
                    IsNotEqualToColumnWrapper(
                        IsEqualToColumnWrapper(
                            doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns))
                        )
                    )
                )
            )
        )
    );

    return (
        <>
            <DataGridPro
                apiRef={apiRef}
                components={{ Toolbar: ClientFundingYearEditToolbar }}
                componentsProps={{
                    toolbar: { isFundingYearInCFYData, onAddNewRecordClick: handleToolbarAddNewRowClick }, // Potential - Whenever
                }}
                columns={columnsV2}
                rows={cfyData}
                loading={cfyData === null}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                editMode='row'
                getRowId={(row) => row.funding_year}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'funding_year', sort: 'desc' }],
                    },
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                columnVisibilityModel={JSON.parse(hiddenColumnPrefs)}
                onColumnVisibilityModelChange={(newVizModel) => handleOnColumnVisibilityModelChange(newVizModel)}
                pinnedColumns={JSON.parse(pinnedColumnPrefs)}
                onPinnedColumnsChange={(newPinnedModel) => handlePinnedPrefs(newPinnedModel)}
                autoHeight={true}
                getRowHeight={() => 'auto'}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={(params, event) => {
                    //console.log('params.reason = ' + params.reason);
                    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
                        if (params.row.isNew === true) {
                            setCFYData(cfyData.filter((row) => row.funding_year !== params.row.funding_year));
                        }
                    }
                }}
                sx={{
                    boxShadow: 2,
                    border: 2,
                    '& .MuiDataGrid-cell': {
                        border: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n)': {
                        backgroundColor: '#f0f2f4',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                        backgroundColor: '#fdfffe',
                    },
                    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                        backgroundColor: '#fdfffe',
                    },
                    '& .centered-cell': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
            />

            <NotesEditDialog
                open={openNotesEditDialog}
                title={notesEditDialogTitle}
                label={notesEditDialogLabel}
                initialNotes={editableNotes}
                username={usernameForNotesEditDialog}
                onSave={handleNotesEditDialogSave}
                onCancel={handleNotesEditDialogCancel}
                onClose={handleNotesEditDialogClose}
            />
        </>
    );
}

import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function LessThanColumnComponent(props) {
    const { item, applyValue, columns } = props; // "item" represents all attrs in GridFilterItem (it's of class GridFilterForm)
    const field = item.columnField;
    const applyValueRef = React.useRef(applyValue);

    React.useEffect(() => {
        applyValueRef.current = applyValue;
    }, [applyValue]);

    // This is what allows us to find the second column's value within the params.row in createCompareColumnsOperator
    const handleSecondColumnSelection = (event, newValue) => {
        applyValueRef.current({ ...item, comparedColumnFieldName: event.target.value, value: true }); // applyValue = apiRef.current.upsertFilterItem(item), it's why we can access item.comparedColumnFieldName in the Select component
    };

    // Identify the type of the current column
    const currentColumnType = columns.find((col) => col.field === field)?.type;

    const comparableColumns = columns.filter((col) => {
        if (currentColumnType === 'date' || currentColumnType === 'dateTime') {
            return col.type === 'date' || col.type === 'dateTime';
        }
        return col.type === currentColumnType;
    });

    return (
        <FormControl fullWidth margin='dense' size='small'>
            <Select
                id={`compare-${field}`}
                value={item.comparedColumnFieldName || ''}
                onChange={handleSecondColumnSelection}
                displayEmpty
            >
                <MenuItem value='' disabled>
                    Column to Compare
                </MenuItem>
                {comparableColumns.map((col) => (
                    <MenuItem key={col.field} value={col.field}>
                        {col.headerName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const form471NonQAFieldMapping = {
    DateSentToClientToCertify: 'date_sent_to_client_to_certify',
    _userEnteredFieldUpdatedTimestamp: 'user_entered_field_updated_timestamp',
};

const form471QAFieldMapping = {
    QADateSubmitted: 'qa_date_submitted',
    QADateNeeded: 'qa_date_needed',
};

const getFieldValue = (params, field) => {
    if (field.startsWith('QA')) {
        const qaForm470App = params.row?.qa_form471app;
        return qaForm470App?.[form471QAFieldMapping[field]] || qaForm470App?.[field] || null;
    } else {
        const form471App = params.row?.form471app;
        return form471App?.[form471NonQAFieldMapping[field]] || params.row?.[field] || null;
    }
};

const returnDateOnly = (dateTimeString) => {
    // console.log('[returnDateOnly] dateTimeString:', dateTimeString);

    const date = new Date(dateTimeString);
    // console.log('[returnDateOnly] date:', date);

    if (!isNaN(date.getTime())) {
        // console.log('[returnDateOnly] date ISO:', date.toISOString().split('T')[0]);
        return date.toISOString().split('T')[0]; // Just returning the YYYY-MM-DD portion
    }
    return dateTimeString;
};

const createCompareColumnsOperator = (columns) => ({
    label: 'is less than column',
    value: 'isLessThanColumn',

    // This function must return another function that takes the cell value as an input and return true if it satisfies the operator condition
    getApplyFilterFn: (filterItem, column) => {
        // console.log('[LessThanColumnFilter][createCompareColumnsOperator][getApplyFilterFn] filterItem:', filterItem);

        if (
            !filterItem.columnField ||
            !filterItem.operatorValue ||
            !filterItem.comparedColumnFieldName ||
            filterItem.value === null ||
            filterItem.value === undefined
        ) {
            return null;
        }

        return (params) => {
            // console.log(
            //     '[LessThanColumnFilter][createCompareColumnsOperator][getApplyFilterFn] params.row:',
            //     params.row
            // );

            // Handling for 471 app due to unique data structure
            if (params.row.form471AppNum && params.row.form471app) {
                const originalColumnValue = getFieldValue(params, filterItem.columnField);
                const comparedColumnValue = getFieldValue(params, filterItem.comparedColumnFieldName);

                if (
                    originalColumnValue === null ||
                    originalColumnValue === undefined ||
                    comparedColumnValue === null ||
                    comparedColumnValue === undefined
                ) {
                    return false;
                }

                if (typeof originalColumnValue === 'number' && typeof comparedColumnValue === 'number') {
                    return originalColumnValue < comparedColumnValue;
                }

                const originalDateStr = returnDateOnly(originalColumnValue);
                const comparedDateStr = returnDateOnly(comparedColumnValue);

                const originalDate = new Date(originalDateStr);
                const comparedDate = new Date(comparedDateStr);

                if (!isNaN(originalDate.getTime()) && !isNaN(comparedDate.getTime())) {
                    return originalDate.getTime() < comparedDate.getTime();
                }

                return false;
            } else {
                const originalColumnValue = params.row[filterItem.columnField];
                const comparedColumnValue = params.row[filterItem.comparedColumnFieldName];

                if (
                    originalColumnValue === null ||
                    originalColumnValue === undefined ||
                    comparedColumnValue === null ||
                    comparedColumnValue === undefined
                ) {
                    return false;
                }

                if (typeof originalColumnValue === 'number' && typeof comparedColumnValue === 'number') {
                    return originalColumnValue < comparedColumnValue;
                }

                const originalDateStr = returnDateOnly(originalColumnValue);
                const comparedDateStr = returnDateOnly(comparedColumnValue);

                const originalDate = new Date(originalDateStr);
                const comparedDate = new Date(comparedDateStr);

                if (!isNaN(originalDate.getTime()) && !isNaN(comparedDate.getTime())) {
                    return originalDate.getTime() < comparedDate.getTime();
                }

                return false;
            }
        };
    },

    InputComponent: (props) => {
        // console.log('[LessThanColumnFilter][createCompareColumnsOperator][InputComponent] props:', props);
        return <LessThanColumnComponent {...props} columns={columns} />;
    },
});

const IsLessThanColumnWrapper = (columns) => {
    const compareColumnsOperator = createCompareColumnsOperator(columns);

    return columns.map((col) => {
        const columnType = col.type || 'string';

        // Filter out the default 'isAnyOf' operator if the column has an attribute mapping
        if (columnType === 'number' || columnType === 'date' || columnType === 'dateTime') {
            const customOperators = col.filterOperators.concat(compareColumnsOperator);

            return {
                ...col,
                filterOperators: customOperators,
            };
        }

        // Or just return the normal operators for this column
        return col;
    });
};

export default IsLessThanColumnWrapper;

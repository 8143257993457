import React from 'react';
import { useParams } from 'react-router-dom';

import ClientAPI from '../api/ClientAPI.js';
import ManageClientServices from '../components/ManageClientServices.js';

export default function ClientServices() {
    const clientAPI = new ClientAPI();
    let params = useParams();

    return (
        <>
            <ManageClientServices clientAPI={clientAPI} clientId={params.clientId} />
        </>
    );
}

import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAuthContext } from '../useAuthContext';

import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const authContext = useAuthContext();

    const [loginState, setLoginState] = useState('signIn');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [submitted, setSubmitted] = useState(false);

    let from = location.state?.from?.pathname || '/';

    function showAccountConfirm() {
        setSubmitted(false);

        setPassword('');
        setConfirmPassword('');
        setConfirmationCode('');

        setLoginState('confirm');
    }

    function showResendConfirm() {
        setSubmitted(false);

        setPassword('');
        setConfirmPassword('');
        setConfirmationCode('');

        setLoginState('resend');
    }

    function showForgotPassword() {
        setSubmitted(false);

        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setConfirmationCode('');

        setLoginState('forgotPassword');
    }

    function showResetPassword() {
        setSubmitted(false);

        setPassword('');
        setConfirmPassword('');
        setConfirmationCode('');

        setLoginState('resetPassword');
    }

    function showSignIn() {
        setSubmitted(false);

        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setConfirmPassword('');

        setLoginState('signIn');
    }

    async function loginUser(event) {
        event.preventDefault();
        try {
            setSubmitted(true);
            await authContext.signIn(username, password);
            navigate(from, { replace: true });
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function confirmUser(event) {
        event.preventDefault();
        try {
            setSubmitted(true);
            const response = await Auth.confirmSignUp(username, confirmationCode);
            toast.success('Account successfully confirmed');
            showSignIn();
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function resendConfirm(event) {
        event.preventDefault();
        try {
            const response = await Auth.resendSignUp(username);
            toast.success('Confirmation email re-sent');
            showAccountConfirm();
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function submitForgotPassword(event) {
        event.preventDefault();
        try {
            setSubmitted(true);
            const response = await Auth.forgotPassword(username);
            toast.success('Password Reset Token sent to ' + username);
            showResetPassword();
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function resetPassword(event) {
        event.preventDefault();
        try {
            setSubmitted(true);
            const response = await Auth.forgotPasswordSubmit(username, confirmationCode, password);
            toast.success('Password successfully reset');
            showSignIn();
        } catch (error) {
            toast.error(error.message);
        }
    }

    if (loginState === 'confirm') {
        return (
            <Container component='main' maxWidth='xs'>
                <ToastContainer theme='colored' />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component='h1' variant='h5'>
                        Confirm Sign Up
                    </Typography>
                    <Box component='form' onSubmit={confirmUser} noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={submitted && username === ''}
                            helperText={submitted && username === '' ? 'Username is required' : ''}
                            onChange={(event) => setUsername(event.target.value)}
                            value={username}
                            required={true}
                            label='Username'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && confirmationCode === ''}
                            helperText={submitted && confirmationCode === '' ? 'Confirmation Code is required' : ''}
                            onChange={(event) => setConfirmationCode(event.target.value)}
                            value={confirmationCode}
                            required={true}
                            label='Confirmation Code'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            Confirm
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href='#' onClick={showResendConfirm} variant='body2'>
                                    Resend Confirmation Code
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='#' onClick={showSignIn} variant='body2'>
                                    Back to Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    } else if (loginState === 'resend') {
        return (
            <Container component='main' maxWidth='xs'>
                <ToastContainer theme='colored' />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component='h1' variant='h5'>
                        Resend Confirmation Code
                    </Typography>
                    <Box component='form' onSubmit={resendConfirm} noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={submitted && username === ''}
                            helperText={submitted && username === '' ? 'Email is required' : ''}
                            onChange={(event) => setUsername(event.target.value)}
                            value={username}
                            required={true}
                            label='Username'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            Resend
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href='#' onClick={showAccountConfirm} variant='body2'>
                                    Confirm Account Creation
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='#' onClick={showSignIn} variant='body2'>
                                    Back to Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    } else if (loginState === 'forgotPassword') {
        return (
            <Container component='main' maxWidth='xs'>
                <ToastContainer theme='colored' />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component='h1' variant='h5'>
                        Send Password Reset Code
                    </Typography>
                    <Box component='form' onSubmit={submitForgotPassword} noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={submitted && username === ''}
                            helperText={submitted && username === '' ? 'Username is required' : ''}
                            onChange={(event) => setUsername(event.target.value)}
                            value={username}
                            required={true}
                            label='Username'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            Send Email
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href='#' onClick={showResetPassword} variant='body2'>
                                    Submit new Password
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='#' onClick={showSignIn} variant='body2'>
                                    Back to Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    } else if (loginState === 'resetPassword') {
        return (
            <Container component='main' maxWidth='xs'>
                <ToastContainer theme='colored' />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component='h1' variant='h5'>
                        Reset Password
                    </Typography>
                    <Box component='form' onSubmit={resetPassword} noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={submitted && username === ''}
                            helperText={submitted && username === '' ? 'Username is required' : ''}
                            onChange={(event) => setUsername(event.target.value)}
                            value={username}
                            required={true}
                            label='Username'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && confirmationCode === ''}
                            helperText={submitted && confirmationCode === '' ? 'Username is required' : ''}
                            onChange={(event) => setConfirmationCode(event.target.value)}
                            value={confirmationCode}
                            required={true}
                            label='Confirmation Code'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && password === ''}
                            helperText={submitted && password === '' ? 'Password is required' : ''}
                            onChange={(event) => setPassword(event.target.value)}
                            value={password}
                            required={true}
                            label='Password'
                            type='password'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && confirmPassword === ''}
                            helperText={submitted && confirmPassword === '' ? 'Password Confirmation is required' : ''}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            value={confirmPassword}
                            required={true}
                            label='Confirm Password'
                            type='password'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href='#' onClick={showSignIn} variant='body2'>
                                    Back to Sign In
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='#' onClick={showAccountConfirm} variant='body2'>
                                    Confirm Account Creation
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    } else {
        // Default to Login
        return (
            <Container component='main' maxWidth='xs'>
                <ToastContainer theme='colored' />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    <Box component='form' onSubmit={loginUser} noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={submitted && username === ''}
                            helperText={submitted && username === '' ? 'Username is required' : ''}
                            onChange={(event) => setUsername(event.target.value)}
                            value={username}
                            required={true}
                            label='Username'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && password === ''}
                            helperText={submitted && password === '' ? 'Password is required' : ''}
                            onChange={(event) => setPassword(event.target.value)}
                            value={password}
                            required={true}
                            label='Password'
                            type='password'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='Remember me' />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href='#' onClick={showForgotPassword} variant='body2'>
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='#' onClick={showAccountConfirm} variant='body2'>
                                    {'Confirm Account'}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    }
}

export default Login;

import React from 'react';
import { useParams } from 'react-router-dom';

import ClientCreateEdit from '../components/ClientCreateEdit.js';
import ClientAPI from '../api/ClientAPI.js';
import UserAPI from '../api/UserAPI.js';

export default function ClientEditPage() {
    const clientAPI = new ClientAPI();
    const userAPI = new UserAPI();
    let params = useParams();

    return (
        <>
            <ClientCreateEdit
                mode='edit'
                clientId={params.clientId}
                clientAPI={clientAPI}
                userAPI={userAPI}
            />
        </>
    );
}

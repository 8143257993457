import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CustomDatagridToolbar from './CustomDatagridToolbar.js';
import PropTypes from 'prop-types';
import useLocalStorage from 'react-use-localstorage';
import { Tooltip, Link, Box } from '@mui/material';
import renderCellExpand from './customcomponents/RenderCellExpand.js';

const defaultFilter = '{"items": []}';
const defaultSort = '[]';
const defaultHiddenColumns = '{}';
const defaultPinnedColumns = '{"left": [], "right": []}';

const LS_INCREMENT = 1;
const FRN_FILTER_LS_NAME = 'cedFRNFilterPrefs' + LS_INCREMENT;
const FRN_SORT_LS_NAME = 'cedFRNSortPrefs' + LS_INCREMENT;
const FRN_HIDDEN_LS_NAME = 'cedFRNHiddenColumns' + LS_INCREMENT;
const FRN_PINNED_LS_NAME = 'cedFRNPinnedColumns' + LS_INCREMENT;

function FRNTable({ form471AppNum, trackersAPI }) {
    const [filterPrefs, setFilterPrefs] = useLocalStorage(FRN_FILTER_LS_NAME, defaultFilter);
    const [sortPrefs, setSortPrefs] = useLocalStorage(FRN_SORT_LS_NAME, defaultSort);
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = useLocalStorage(FRN_HIDDEN_LS_NAME, defaultHiddenColumns);
    const [pinnedColumnPrefs, setPinnedColumnPrefs] = useLocalStorage(FRN_PINNED_LS_NAME, defaultPinnedColumns);

    const [FRNData, setFRNData] = React.useState([]);
    const [dataIsLoading, setDataIsLoading] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(25);

    React.useEffect(() => {
        const fetchFRNsForApplication = async () => {
            try {
                setDataIsLoading(true);
                let result = await trackersAPI.GetFRNsForApplication(form471AppNum);
                //console.log(result);
                setFRNData(result);
                setDataIsLoading(false);
            } catch (error) {
                console.error(error);
                setDataIsLoading(false);
            }
        };
        fetchFRNsForApplication();
    }, []);

    function handleFilterPrefs(newFilterModel) {
        setFilterPrefs(JSON.stringify(newFilterModel));
    }

    function handleSortPrefs(newSortModel) {
        setSortPrefs(JSON.stringify(newSortModel));
    }

    function handleVizPrefs(newVizModel) {
        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    function handlePinnedPrefs(newPinModel) {
        setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    const numberAndNoCommas = {
        type: 'number', // Sort as a number..
        valueFormatter: ({ value }) => value, // ..and display without commas.
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const usdPrice = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => {
            if (value === null) {
                return '';
            }

            const trimmed = value.replace('$', '').replace(',', '');
            return currencyFormatter.format(trimmed);
        },
        valueParser: (value, params) => {
            const trimmed = value.replace('$', '').replace(',', '');
            return parseFloat(trimmed);
        },
        cellClassName: 'font-tabular-nums',
    };

    const percent = {
        type: 'number',
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} %`;
        },
    };

    const columns = [
        {
            headerName: 'FRN',
            field: 'frn',
            width: 100,
            renderCell: (params) => (
                <Tooltip title='View FRN Summary' key={params.row.frn + '-FRNLink'}>
                    <Link
                        href={
                            '/embedded-dashboard?dashboardName=ApplicationSummaryDashboard&sheetID=b669d4e3-deb6-4aff-9ebb-c67bfe2f1ef8_dee02a4a-f1a8-4a79-8f07-e6e705dc31de&filterParam=FRN&filterValue=' +
                            String(params.row.frn)
                        }
                        target='_blank'
                        rel='noopener'
                    >
                        {params.row.frn}
                    </Link>
                </Tooltip>
            ),
        },
        {
            headerName: 'FRN Nickname',
            field: 'frnnickname',
            width: 200,
        },
        {
            headerName: 'FRN Status',
            field: 'frnstatus',
            width: 100,
        },
        {
            headerName: '471 Application Status',
            field: 'f471applicationstatus',
            width: 150,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    471 Application
                    <br />
                    Status
                </Box>
            ),
        },
        {
            headerName: '471 Review Status',
            field: 'f471reviewstatus',
            width: 150,
        },
        {
            headerName: '470 Establishing FCC Form Number',
            field: 'establishingfccform470number',
            ...numberAndNoCommas,
            width: 150,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    470 Establishing
                    <br />
                    FCC Form Number
                </Box>
            ),
        },
        {
            headerName: 'Service Provider',
            field: 'serviceprovidername',
            width: 200,
        },
        {
            headerName: 'SPIN',
            field: 'spin',
            ...numberAndNoCommas,
            width: 100,
        },
        {
            headerName: 'Funding Year',
            field: 'fundingyear',
            ...numberAndNoCommas,
            width: 100,
        },
        {
            headerName: '486 Service Start Date',
            field: 'f486servicestartdate',
            type: 'date',
            width: 100,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    486 Service
                    <br />
                    Start Date
                </Box>
            ),
        },
        {
            headerName: 'Service End Date',
            field: 'contractexpirationdate',
            //field: 'contractExpOrSvcEndDate', //TODO: WHAT IS THIS ONE?
            type: 'date',
            width: 150,
        },
        {
            headerName: 'Remaining Contract Extensions',
            field: 'remainingcontractextensions',
            width: 150,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Remaining Contract
                    <br />
                    Extensions
                </Box>
            ),
        },
        {
            headerName: 'Original Total Cost',
            field: 'origtotalcost',
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Original
                    <br />
                    Total Cost
                </Box>
            ),
        },
        {
            headerName: 'Committed Total Cost',
            field: 'cmtdtotalcost',
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Committed
                    <br />
                    Total Cost
                </Box>
            ),
        },
        {
            headerName: 'Original Discount',
            field: 'origdiscount',
            ...percent,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Original
                    <br />
                    Discount
                </Box>
            ),
        },
        {
            headerName: 'Committed Discount',
            field: 'cmtddiscount',
            ...percent,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Committed
                    <br />
                    Discount
                </Box>
            ),
        },
        {
            headerName: 'Original Funding Request',
            field: 'origfundingrequest',
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Original
                    <br />
                    Funding Request
                </Box>
            ),
        },
        {
            headerName: 'Committed Funding Request',
            field: 'cmtdfundingrequest',
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Committed
                    <br />
                    Funding Request
                </Box>
            ),
        },
        {
            headerName: 'Original FRN Service Type',
            field: 'origfrnservicetype',
            width: 175,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Original FRN
                    <br />
                    Service Type
                </Box>
            ),
        },
        {
            headerName: 'Committed FRN Service Type',
            field: 'cmtdfrnservicetype',
            width: 175,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Committed FRN
                    <br />
                    Service Type
                </Box>
            ),
        },
        {
            headerName: 'Discounted 471 SS Date',
            field: 'cmtd471ssd',
            width: 150,
            type: 'date',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Discounted 471
                    <br />
                    SS Date
                </Box>
            ),
        },
        {
            headerName: 'Wave Number',
            field: 'wavenumber',
            width: 100,
            ...numberAndNoCommas,
        },
        {
            headerName: 'FCDL Date',
            field: 'fcdldate',
            width: 100,
            type: 'date',
        },
        {
            headerName: 'FRN Committed Amount',
            field: 'frncommittedamount',
            width: 150,
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    FRN Committed
                    <br />
                    Amount
                </Box>
            ),
        },
        {
            headerName: 'Total Authorized Disbursement',
            field: 'totalauthorizeddisbursement',
            width: 150,
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Total Authorized
                    <br />
                    Disbursement
                </Box>
            ),
        },
        {
            headerName: 'FCDL Comment for 471 Application',
            field: 'fcdlcommentfor471application',
            width: 150,
            minPopupWidth: 300,
            renderCell: renderCellExpand,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    FCDL Comment for
                    <br />
                    471 Application
                </Box>
            ),
        },
        {
            headerName: 'FCDL Comment for FRN',
            field: 'fcdlcommentforfrn',
            width: 150,
            minPopupWidth: 300,
            renderCell: renderCellExpand,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    FCDL Comment
                    <br />
                    for FRN
                </Box>
            ),
        },
    ];

    return (
        <DataGridPro
            columns={columns}
            rows={FRNData}
            getRowId={(row) => row.frn}
            loading={dataIsLoading && FRNData.length === 0}
            components={{ Toolbar: CustomDatagridToolbar }}
            rowHeight={38}
            autoHeight
            pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500, 1000, 2000]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            filterModel={JSON.parse(filterPrefs)}
            onFilterModelChange={(newFilterModel) => handleFilterPrefs(newFilterModel)}
            sortModel={JSON.parse(sortPrefs)}
            onSortModelChange={(newSortModel) => handleSortPrefs(newSortModel)}
            columnVisibilityModel={JSON.parse(hiddenColumnPrefs)}
            onColumnVisibilityModelChange={(newVizModel) => handleVizPrefs(newVizModel)}
            pinnedColumns={JSON.parse(pinnedColumnPrefs)}
            onPinnedColumnsChange={(newPinnedModel) => handlePinnedPrefs(newPinnedModel)}
            sx={{
                '.MuiDataGrid-footerContainer': {
                    justifyContent: 'left !important',
                },
                /*'[class*="MuiDataGrid-toolbarQuickFilter"]': {
                    marginLeft: '15px !important',
                },*/
                '[aria-label="Search"]': {
                    marginLeft: '15px !important',
                },
            }}
        />
    );
}

FRNTable.propTypes = {
    form471AppNum: PropTypes.number.isRequired,
    trackersAPI: PropTypes.object.isRequired,
};

export default FRNTable;

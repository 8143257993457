import React, { useState } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Typography,
    Container,
} from '@mui/material';

import UserAPI from '../api/UserAPI.js';
const userAPI = new UserAPI();

function ProfilePage() {
    const [userData, setUserData] = useState({});
    const [teams, setTeams] = React.useState([]);

    React.useEffect(() => {
        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            console.log('GetUserOrUsers parsedObject:', parsedObject);
            setUserData(parsedObject);
        };

        const fetchTeams = async () => {
            let teams = await userAPI.GetTeams();
            console.log('ProfilePage: GetTeams =', teams);
            teams.sort((a, b) => {
                if (a.display_name > b.display_name) {
                    return 1;
                }

                if (b.display_name > a.display_name) {
                    return -1;
                }

                return 0;
            });
            setTeams(teams);
        };

        getUserData();
        fetchTeams();
    }, []);

    function getDisplayNameForTeam(team_id, organization_id) {
        const matchingTeam = teams.find((team) => team.id === team_id && team.organization_id === organization_id);
        return matchingTeam ? matchingTeam.display_name : '';
    }

    return (
        <Container maxWidth='md'>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                                <Typography variant='h3' align='left'>
                                    Basic Info
                                </Typography>
                                <Typography variant='h6' gutterBottom align='left' color='textSecondary'>
                                    Details about you and your linked organizations/teams.
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Name
                            </TableCell>
                            <TableCell>{userData.first_name + ' ' + userData.last_name}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Email
                            </TableCell>
                            <TableCell>{userData.email}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Organization(s)
                            </TableCell>
                            <TableCell>
                                {Array.isArray(userData.organizations)
                                    ? userData.organizations.map((org, index) => (
                                          <div key={index}>{org.display_name}</div>
                                      ))
                                    : ''}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component='th' scope='row'>
                                Team(s)
                            </TableCell>
                            <TableCell>
                                {userData.team_membership &&
                                    userData.team_membership.map((membership, index) => (
                                        <div key={index}>
                                            {getDisplayNameForTeam(membership.team_id, membership.organization_id)}
                                        </div>
                                    ))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default ProfilePage;

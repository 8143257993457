import * as React from 'react';
import {
    getGridStringOperators,
    getGridNumericOperators,
    getGridDateOperators,
    getGridBooleanOperators,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid';
import { TextField, MenuItem, InputLabel } from '@mui/material';

function RemoveAnyComponent(props) {
    const { item, applyValue } = props;
    const applyValueRef = React.useRef(applyValue);

    // Using strings because the false boolean wouldn't visually stick without the string conversion
    const initialFilterValue = item.value === false ? 'false' : 'true';
    const [filterValue, setFilterValue] = React.useState(initialFilterValue);

    React.useEffect(() => {
        applyValueRef.current = applyValue;
        if (item.value === undefined) {
            applyValueRef.current({ ...item, value: true });
        }
    }, [item, applyValue]);

    const handleValueChange = (event) => {
        const newValue = event.target.value;
        setFilterValue(newValue);

        // Convert the string value back to a boolean when applying the filter
        let booleanValue = newValue === 'true' ? true : newValue === 'false' ? false : null;
        applyValueRef.current({ ...item, value: booleanValue });
    };

    return (
        <>
            <InputLabel shrink>Value</InputLabel>
            <TextField
                select
                value={filterValue}
                onChange={handleValueChange}
                placeholder='Filter value'
                variant='standard'
                sx={{ marginTop: 2 }}
                fullWidth
            >
                <MenuItem value='true'>True</MenuItem>
                <MenuItem value='false'>False</MenuItem>
            </TextField>
        </>
    );
}

const customBooleanOperator = {
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem) => {
        return (params) => {
            return params.value === filterItem.value;
        };
    },
    InputComponent: RemoveAnyComponent,
};

const removeAnyBooleanFilterValueWrapper = (columns) => {
    const defaultStringOperators = getGridStringOperators();
    const defaultNumericOperators = getGridNumericOperators();
    const defaultSingleSelectOperators = getGridSingleSelectOperators();
    // const defaultBooleanOperators = getGridBooleanOperators();

    return columns.map((col) => {
        const columnType = col.type || 'string';
        let customOperators = [];

        // GridNativeColTypes = 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions' (there isn't an operator for actions);
        if (columnType === 'boolean') {
            return {
                ...col,
                filterOperators: [customBooleanOperator],
            };
        } else if (columnType === 'string') {
            customOperators = [...defaultStringOperators];
        } else if (columnType === 'number') {
            customOperators = [...defaultNumericOperators];
        } else if (columnType === 'date') {
            customOperators = [...getGridDateOperators(false)];
        } else if (columnType === 'dateTime') {
            customOperators = [...getGridDateOperators(true)];
        } else if (columnType === 'singleSelect') {
            customOperators = [...defaultSingleSelectOperators];
        }

        return {
            ...col,
            filterOperators: customOperators,
        };
    });
};

export default removeAnyBooleanFilterValueWrapper;

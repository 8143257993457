/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, createContext } from 'react';
import { Auth } from 'aws-amplify';

const authContext = createContext();

export function ProvideAuthContext({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuthContext = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const signIn = async (username, password) => {
        try {
            setLoading(true);
            const user = await Auth.signIn(username, password);
            setUser(user);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            throw e;
        }
    };

    const signOut = async () => {
        setLoading(true);
        await Auth.signOut();
        setUser(null);
        setLoading(false);
    };

    useEffect(() => {
        if (loading) {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    setUser(user);
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    return {
        user,
        loading,
        signIn,
        signOut,
        get isSignedIn() {
            return user != null;
        },
        get userEmail() {
            if (user != null) {
                return user.attributes?.email;
            }
            return null;
        },
        get userDisplayName() {
            if (user != null) {
                return user.attributes?.name;
            }
            return null;
        },
        get userFullName() {
            if (user != null) {
                return user.attributes?.given_name + ' ' + user.attributes?.family_name;
            }
            return null;
        },
        get cognitoID() {
            if (user != null) {
                return user.attributes?.sub;
            }
            return null;
        },
    };
}

import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ManageAddOrgs(props) {
    const userAPI = props.api;
    const TabPanel = props.TabPanel;

    // Org Table
    const [originalOrgRows, setOriginalOrgRows] = React.useState([]);
    const [orgRows, setOrgRows] = React.useState([]);
    const [orgRowsPerPage, setOrgRowsPerPage] = React.useState(10);
    const [orgPage, setOrgPage] = React.useState(0);
    const emptyOrgRows = orgRowsPerPage - Math.min(orgRowsPerPage, orgRows.length - orgPage * orgRowsPerPage);

    // Save Org
    const [orgID, setOrgID] = React.useState(-1);
    const [orgSubmitted, setOrgSubmitted] = React.useState(false);
    const [orgName, setOrgName] = React.useState('');
    const [orgDisplayName, setOrgDisplayName] = React.useState('');
    const [orgWebsite, setOrgWebsite] = React.useState('');
    const [orgContactName, setOrgContactName] = React.useState('');
    const [orgContactPhone, setOrgContactPhone] = React.useState('');
    const [orgContactEmail, setOrgContactEmail] = React.useState('');

    // Add Org
    const [addNewOrgOpen, setAddNewOrgOpen] = React.useState(false);

    // Edit Org
    const [editOrgOpen, setEditOrgOpen] = React.useState(false);

    // View Teams in Org
    const [viewTeamsInOrgOpen, setViewTeamsInOrgOpen] = React.useState(false);
    const [selectedOrgID, setSelectedOrgID] = React.useState('');
    const [hasClickedView, setHasClickedView] = React.useState(false);
    const [teamsInOrg, setTeamsInOrg] = React.useState([]);

    // Locals
    const selectOrgs = React.useRef(new Map());

    const fetchOrgs = async () => {
        let orgs = await userAPI.GetOrgs();
        console.log('ManageAddOrgs: GetOrgs/orgs =', orgs);

        orgs.sort((a, b) => a.display_name.localeCompare(b.display_name));
        setOriginalOrgRows(orgs);
        setOrgRows(orgs);

        selectOrgs.current = new Map();
        orgs.forEach((org) => {
            selectOrgs.current.set(org.id, org.display_name);
        });
    };

    React.useEffect(() => {
        fetchOrgs();
    }, []);

    // Org Table Section
    const handleChangePage = (event, newPage) => {
        setOrgPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setOrgRowsPerPage(parseInt(event.target.value, 10));
        setOrgPage(0);
    };
    const filterOrgTable = (event) => {
        setOrgRowsPerPage(10);
        setOrgPage(0);
        setOrgRows(
            originalOrgRows.filter(
                (row) =>
                    row.display_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    row.contact_name.toLowerCase().includes(event.target.value.toLowerCase())
            )
        );
    };

    // ---------- Submit/Save, Reset, and Delete Section ----------
    async function submitNewOrg() {
        try {
            setOrgSubmitted(true);
            if (
                orgName === '' ||
                orgDisplayName === '' ||
                orgWebsite === '' ||
                orgContactName === '' ||
                orgContactPhone === '' ||
                orgContactEmail === ''
            ) {
                // Validation
                toast.error('Please complete all required fields');
            } else {
                let orgInfo = {
                    id: orgID,
                    name: orgName,
                    display_name: orgDisplayName,
                    website: orgWebsite,
                    contact_name: orgContactName,
                    contact_phone: orgContactPhone,
                    contact_email: orgContactEmail,
                };
                await userAPI.SaveOrg(orgInfo);
                let successMessage = 'Successfully created organization';

                toast.success(successMessage);
                setOrgSubmitted(false);
                fetchOrgs();
                resetOrgFields();
                setAddNewOrgOpen(false);
            }
        } catch (error) {
            toast.error('Error saving organization');
        }
    }
    async function submitEditOrg() {
        try {
            setOrgSubmitted(true);
            if (
                orgName === '' ||
                orgDisplayName === '' ||
                orgWebsite === '' ||
                orgContactName === '' ||
                orgContactPhone === '' ||
                orgContactEmail === ''
            ) {
                // Validation
                toast.error('Please complete all required fields');
            } else {
                let orgInfo = {
                    id: orgID,
                    name: orgName,
                    display_name: orgDisplayName,
                    website: orgWebsite,
                    contact_name: orgContactName,
                    contact_phone: orgContactPhone,
                    contact_email: orgContactEmail,
                };
                await userAPI.SaveOrg(orgInfo);
                let successMessage = 'Successfully updated organization';

                toast.success(successMessage);
                setOrgSubmitted(false);
                fetchOrgs();
                resetOrgFields();
                setEditOrgOpen(false);
            }
        } catch (error) {
            toast.error('Error saving organization');
        }
    }
    function resetOrgFields() {
        setOrgID(-1);
        setOrgName('');
        setOrgDisplayName('');
        setOrgWebsite('');
        setOrgContactName('');
        setOrgContactPhone('');
        setOrgContactEmail('');
    }
    function endsWithOrg(orgName) {
        const lowercaseName = orgName.toLowerCase();
        return (
            lowercaseName.endsWith('org') ||
            lowercaseName.endsWith('organization') ||
            lowercaseName.split(-3).pop().endsWith('org') ||
            lowercaseName.split(-12).pop().endsWith('organization')
        );
    }
    async function deleteOrg(orgDetails) {
        try {
            let confirmationMessage = endsWithOrg(orgDetails.display_name)
                ? `Are you sure you want to delete the ${orgDetails.display_name}?`
                : `Are you sure you want to delete the ${orgDetails.display_name} organization?`;
            const confirmation = window.confirm(confirmationMessage);
            if (!confirmation) return;

            await userAPI.DeleteOrg(orgDetails.id);
            toast.success('Deleted organization');
        } catch (error) {
            toast.error('Error deleting organization');
        }
    }
    // ---------- End Submit/Save, Reset, and Delete Section ----------

    // ---------- Add Org Section ----------
    function handleAddNewOrgOpen() {
        resetOrgFields();
        setAddNewOrgOpen(true);
    }
    function handleAddNewOrgClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // If they selected values, ensure they want to exit.
        if (
            orgName !== '' ||
            orgDisplayName !== '' ||
            orgWebsite !== '' ||
            orgContactName !== '' ||
            orgContactPhone !== '' ||
            orgContactEmail !== ''
        ) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        setOrgID(-1);
        setOrgName('');
        setOrgDisplayName('');
        setOrgWebsite('');
        setOrgContactName('');
        setOrgContactPhone('');
        setOrgContactEmail('');
        setAddNewOrgOpen(false);
    }
    function addNewOrg() {
        return (
            <Dialog open={addNewOrgOpen} onClose={handleAddNewOrgClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'Add Organization'}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <TextField
                                error={orgSubmitted && orgName === ''}
                                helperText={orgSubmitted && orgName === '' ? 'Organization Name is required' : ''}
                                onChange={(event) => setOrgName(event.target.value)}
                                value={orgName}
                                required={true}
                                label='Organization Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgDisplayName === ''}
                                helperText={orgSubmitted && orgDisplayName === '' ? 'Display Name is required' : ''}
                                onChange={(event) => setOrgDisplayName(event.target.value)}
                                value={orgDisplayName}
                                required={true}
                                label='Display Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgWebsite === ''}
                                helperText={orgSubmitted && orgWebsite === '' ? 'Organization Website is required' : ''}
                                onChange={(event) => setOrgWebsite(event.target.value)}
                                value={orgWebsite}
                                required={true}
                                label='Organization Website'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgContactName === ''}
                                helperText={
                                    orgSubmitted && orgContactName === '' ? 'Organization Contact Name is required' : ''
                                }
                                onChange={(event) => setOrgContactName(event.target.value)}
                                value={orgContactName}
                                required={true}
                                label='Organization Contact Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgContactPhone === ''}
                                helperText={
                                    orgSubmitted && orgContactPhone === ''
                                        ? 'Organization Contact Phone Number is required'
                                        : ''
                                }
                                onChange={(event) => setOrgContactPhone(event.target.value)}
                                value={orgContactPhone}
                                required={true}
                                label='Organization Contact Phone Number'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgContactEmail === ''}
                                helperText={
                                    orgSubmitted && orgContactEmail === ''
                                        ? 'Organization Contact Email is required'
                                        : ''
                                }
                                onChange={(event) => setOrgContactEmail(event.target.value)}
                                value={orgContactEmail}
                                required={true}
                                label='Organization Contact Email'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />

                            <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                                <Button onClick={submitNewOrg} variant='contained'>
                                    Save Organization
                                </Button>
                                <Button onClick={handleAddNewOrgClose} variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
    // ---------- End Add Org Section ----------

    // ---------- Edit Org Section ----------
    async function showEditExistingOrg(orgDetails) {
        // console.log('ManageAddOrgs: orgDetails =', orgDetails);
        setOrgID(orgDetails.id);
        setOrgName(orgDetails.name);
        setOrgDisplayName(orgDetails.display_name);
        setOrgWebsite(orgDetails.website);
        setOrgContactName(orgDetails.contact_name);
        setOrgContactPhone(orgDetails.contact_phone);
        setOrgContactEmail(orgDetails.contact_email);
        setOrgSubmitted(false);
    }
    function handleEditExistingOrgOpen() {
        setEditOrgOpen(true);
    }
    function handleEditExistingOrgClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // If they selected values, ensure they want to exit.
        if (
            orgName !== '' ||
            orgDisplayName !== '' ||
            orgWebsite !== '' ||
            orgContactName !== '' ||
            orgContactPhone !== '' ||
            orgContactEmail !== ''
        ) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        setOrgID(-1);
        setOrgName('');
        setOrgDisplayName('');
        setOrgWebsite('');
        setOrgContactName('');
        setOrgContactPhone('');
        setOrgContactEmail('');
        setEditOrgOpen(false);
    }
    function editExistingOrg() {
        return (
            <Dialog open={editOrgOpen} onClose={handleEditExistingOrgClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'Edit Organization'}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <TextField
                                error={orgSubmitted && orgName === ''}
                                helperText={orgSubmitted && orgName === '' ? 'Organization Name is required' : ''}
                                onChange={(event) => setOrgName(event.target.value)}
                                value={orgName}
                                required={true}
                                label='Organization Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgDisplayName === ''}
                                helperText={orgSubmitted && orgDisplayName === '' ? 'Display Name is required' : ''}
                                onChange={(event) => setOrgDisplayName(event.target.value)}
                                value={orgDisplayName}
                                required={true}
                                label='Display Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgWebsite === ''}
                                helperText={orgSubmitted && orgWebsite === '' ? 'Organization Website is required' : ''}
                                onChange={(event) => setOrgWebsite(event.target.value)}
                                value={orgWebsite}
                                required={true}
                                label='Organization Website'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgContactName === ''}
                                helperText={
                                    orgSubmitted && orgContactName === '' ? 'Organization Contact Name is required' : ''
                                }
                                onChange={(event) => setOrgContactName(event.target.value)}
                                value={orgContactName}
                                required={true}
                                label='Organization Contact Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgContactPhone === ''}
                                helperText={
                                    orgSubmitted && orgContactPhone === ''
                                        ? 'Organization Contact Phone Number is required'
                                        : ''
                                }
                                onChange={(event) => setOrgContactPhone(event.target.value)}
                                value={orgContactPhone}
                                required={true}
                                label='Organization Contact Phone Number'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={orgSubmitted && orgContactEmail === ''}
                                helperText={
                                    orgSubmitted && orgContactEmail === ''
                                        ? 'Organization Contact Email is required'
                                        : ''
                                }
                                onChange={(event) => setOrgContactEmail(event.target.value)}
                                value={orgContactEmail}
                                required={true}
                                label='Organization Contact Email'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />

                            <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                                <Button onClick={submitEditOrg} variant='contained'>
                                    Save Organization
                                </Button>
                                <Button onClick={handleEditExistingOrgClose} variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
    // ---------- End Edit Org Section ----------

    // ---------- View Teams of Org Section ----------
    async function setValuesOfSpecificViewTeamsRow(rowDetails) {
        // console.log('ManageAddOrgs: orgDetails =', orgDetails);
        setSelectedOrgID(rowDetails.id);
        setOrgID(rowDetails.id);
        setOrgName(rowDetails.name);
        setOrgDisplayName(rowDetails.display_name);
        setOrgWebsite(rowDetails.website);
        setOrgContactName(rowDetails.contact_name);
        setOrgContactPhone(rowDetails.contact_phone);
        setOrgContactEmail(rowDetails.contact_email);
        submitViewTeamsInOrg(rowDetails.id);
        setOrgSubmitted(false);
    }
    function handleViewTeamsInOrgOpen() {
        setViewTeamsInOrgOpen(true);
    }
    function handleViewTeamsInOrgClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // Reset the state if the user confirmed they want to exit.
        setOrgID(-1);
        setOrgName('');
        setOrgDisplayName('');
        setViewTeamsInOrgOpen(false);
    }
    async function submitViewTeamsInOrg(org_id) {
        setHasClickedView(true);

        if (!orgID) {
            toast.error('No orgID provided!');
            return;
        }
        const providedOrStateID = org_id || selectedOrgID;
        const teamsByOrgID = await userAPI.GetTeamsByOrgID(providedOrStateID);
        console.log('ManageAddOrgs: GetTeamsByOrgID/teamsInOrg =', teamsByOrgID);

        let sortedTeamsByOrgID = teamsByOrgID.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        });

        if (Array.isArray(sortedTeamsByOrgID) && sortedTeamsByOrgID.length > 0) {
            setTeamsInOrg(sortedTeamsByOrgID);
        } else {
            setTeamsInOrg([]);
        }
    }
    function viewTeamsInOrg() {
        return (
            <Dialog open={viewTeamsInOrgOpen} onClose={handleViewTeamsInOrgClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'View Teams'}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <FormControl fullWidth={true} margin='normal' required>
                                <InputLabel
                                    id='organizationName-label'
                                    shrink={true}
                                    sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                                >
                                    Team Name
                                </InputLabel>
                                <Select
                                    id='organizationName-select'
                                    labelId='organizationName-label'
                                    label='Organization Name'
                                    value={selectedOrgID}
                                    onChange={(event) => {
                                        // console.log('ManageAddOrgs: event target =', event.target);
                                        setSelectedOrgID(event.target.value);
                                        setOrgID(event.target.value);
                                        setOrgDisplayName(selectOrgs.current.get(event.target.value));
                                    }}
                                    required
                                >
                                    {originalOrgRows.map((row, i) => {
                                        return (
                                            <MenuItem value={row.id} key={row.id}>
                                                {row.display_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                                <Button
                                    onClick={() => {
                                        // Second Layer of View Teams
                                        submitViewTeamsInOrg(orgID);
                                    }}
                                    variant='contained'
                                >
                                    View Teams
                                </Button>
                                <Button onClick={handleViewTeamsInOrgClose} variant='contained' color='error'>
                                    Close
                                </Button>
                            </Box>

                            {hasClickedView &&
                                (teamsInOrg.length === null || teamsInOrg.length === 0 ? (
                                    <Box display='flex' justifyContent='center' alignItems='center'>
                                        {`No teams found in the ${orgDisplayName} organization`}
                                    </Box>
                                ) : (
                                    <TableContainer component={Paper} sx={{ mt: 3 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Display Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {teamsInOrg.map((ea_team, i) => (
                                                    <TableRow key={`${ea_team.name}-${ea_team.id}`}>
                                                        <TableCell>{ea_team.id}</TableCell>
                                                        <TableCell>{ea_team.name}</TableCell>
                                                        <TableCell>{ea_team.display_name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
    // ---------- End View Teams of Org Section ----------

    return (
        <>
            {/* Manage Organizations Page (main landing) */}
            <TabPanel value={props.tabPanelValue} index={2}>
                <Box>
                    <Box display='flex' alignItems='center'>
                        <FormControl margin='normal'>
                            <TextField label='Filter Organizations' variant='outlined' onChange={filterOrgTable} />
                        </FormControl>
                        <Button
                            id='addNewOrganization'
                            variant='contained'
                            color='primary'
                            style={{ backgroundColor: '#4CAF50', color: 'white' }}
                            sx={{ m: 2 }}
                            onClick={() => {
                                handleAddNewOrgOpen();
                            }}
                        >
                            Add Organization
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label='Orgs Table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Organization Name</TableCell>
                                    <TableCell>Organization Website</TableCell>
                                    <TableCell>Organization Contact</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(orgRowsPerPage > 0
                                    ? orgRows.slice(orgPage * orgRowsPerPage, orgPage * orgRowsPerPage + orgRowsPerPage)
                                    : orgRows
                                ).map((row, i) => {
                                    return (
                                        <TableRow key={row.name}>
                                            <TableCell>{row.display_name}</TableCell>
                                            <TableCell>{row.website}</TableCell>
                                            <TableCell>{row.contact_name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant='contained'
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                    onClick={() => {
                                                        showEditExistingOrg(row);
                                                        handleEditExistingOrgOpen();
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                    onClick={() => {
                                                        // console.log('ManageAddOrgs: View Teams(1) row =', row);
                                                        setValuesOfSpecificViewTeamsRow(row);
                                                        handleViewTeamsInOrgOpen();
                                                    }}
                                                >
                                                    View Teams
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    color='error'
                                                    onClick={() => deleteOrg(row)}
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {emptyOrgRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyOrgRows }}>
                                        <TableCell colSpan={5} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                        count={orgRows.length}
                                        rowsPerPage={orgRowsPerPage}
                                        page={orgPage}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </TabPanel>

            {addNewOrg()}
            {editExistingOrg()}
            {viewTeamsInOrg()}
        </>
    );
}

export default ManageAddOrgs;

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// For assigning, editing, and removing services to an applicant.  Table: 'client_service'
export default function ManageClientServices({ clientAPI, clientId }) {
    const [currentClientId, setCurrentClientId] = React.useState(parseInt(clientId));
    const [services, setServices] = React.useState([]);
    const [clientServices, setClientServices] = React.useState([]);
    const [historicalClientServices, setHistoricalClientServices] = React.useState([]);
    const [futureClientServices, setFutureClientServices] = React.useState([]);
    const [bulkAction, setBulkAction] = React.useState('');
    const [bulkStartDate, setBulkStartDate] = React.useState('');
    const [bulkEndDate, setBulkEndDate] = React.useState('');
    const [clientNames, setClientNames] = React.useState([]);
    const [selectedClientName, setSelectedClientName] = React.useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [manualService, setManualService] = React.useState('');
    const [manualServiceAction, setManualServiceAction] = React.useState('');
    const [manualStartDate, setManualStartDate] = React.useState('');
    const [manualEndDate, setManualEndDate] = React.useState('');
    const [manualNotes, setManualNotes] = React.useState('');
    const [selectedForBulk, setSelectedForBulk] = React.useState([]);
    const [clientServicesLoaded, setClientServicesLoaded] = React.useState([]);
    const [serviceActions, setServiceActions] = React.useState([]);

    React.useEffect(() => {
        const fetchAllServices = async () => {
            let allServices = await clientAPI.GetServices();
            //console.log('All services data:\r\n', allServices);
            let sortedServices = [...allServices].sort((a, b) => a.service_id - b.service_id);
            setServices(sortedServices);
        };

        const fetchSelectedServices = async (cid) => {
            setClientServicesLoaded(false);
            let allClientServices = await clientAPI.GetClientServices(cid);
            //console.log('all client services data:\r\n', allClientServices);

            let currDate = new Date();
            let currentClientServices = allClientServices.filter(
                (cs) => Date.parse(cs.start_date) <= currDate && (!cs.end_date || Date.parse(cs.end_date) >= currDate)
            );
            let futureCS = allClientServices.filter((cs) => Date.parse(cs.start_date) > currDate);
            let histCS = allClientServices.filter((cs) => Date.parse(cs.end_date) < currDate);

            setClientServices(currentClientServices);
            setFutureClientServices(futureCS);
            setHistoricalClientServices(histCS);
            setClientServicesLoaded(true);
            //console.log('current CS\r\n', currentClientServices);
            //console.log('Future CS\r\n', futureCS);
            //console.log('historical CS\r\n', histCS);
        };

        const fetchAllClientNames = async () => {
            let allClientNames = await clientAPI.GetClientNames();
            // console.log('ManageClientServices: allClientNames =', allClientNames);

            let sortedClientNames = allClientNames.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            setClientNames(sortedClientNames);
        };

        const fetchSelectedClientName = async () => {
            let currentClient = await clientAPI.GetClient(currentClientId);
            setSelectedClientName(currentClient && currentClient.name ? currentClient.name : '');
        };

        const fetchServiceActionValues = async () => {
            let servActions = await clientAPI.GetServiceActionValues();
            setServiceActions(servActions);
        };

        fetchSelectedClientName();
        fetchAllServices();
        fetchSelectedServices(currentClientId);
        fetchAllClientNames();
        fetchServiceActionValues();
    }, [currentClientId]);

    function getFundingYear() {
        let currDate = new Date();
        let month = currDate.getMonth();
        if (month >= 6) {
            // JavaScript Date calculations are 0-based,so 6 is July
            return currDate.getFullYear();
        } else {
            return currDate.getFullYear() - 1;
        }
    }

    function upsert(serviceID, editedPropertyKey, editedPropertyValue) {
        let tempCS = clientServices.slice(); //Copy array
        const i = tempCS.findIndex((ele) => ele.service_id === serviceID);
        if (i > -1) {
            tempCS[i][editedPropertyKey] = editedPropertyValue;
            setClientServices(tempCS);
        } else {
            let releventService = services.find((s) => s.service_id === serviceID);

            let newClientService = {
                service_id: serviceID,
                client_id: currentClientId,
                start_date: getFundingYear() + '-07-01',
                end_date: getFundingYear() + 1 + '-06-30',
                service_value: '',
                notes: '',
                service: {
                    service_id: releventService.service_id,
                    service_name: releventService.service_name,
                    service_grouping: releventService.service_grouping,
                },
            };

            newClientService[editedPropertyKey] = editedPropertyValue;
            setClientServices([...clientServices, newClientService]);
        }
    }

    function selectAllServices(event, grouping) {
        if (event.target.checked) {
            let relServices = [];

            if (grouping === 'all') {
                relServices = services;
            } else {
                relServices = services.filter((s) => s.service_grouping === grouping);
            }

            relServices.forEach(function (s) {
                if (!selectedForBulk.includes(s.service_id)) {
                    toggleSelected(true, s);
                }
            });
        } else {
            if (grouping === 'all') {
                setSelectedForBulk([]);
            } else {
                let relIDs = services
                    .filter((s) => s.service_grouping === grouping)
                    .map((s) => {
                        return s.service_id;
                    });
                setSelectedForBulk(selectedForBulk.filter((sfb) => !relIDs.includes(sfb)));
            }
        }
    }

    function bulkUpsert(previousState, serviceID, editedPropertyKey, editedPropertyValue) {
        const i = previousState.findIndex((ele) => ele.service_id === serviceID);
        if (i > -1) {
            previousState[i][editedPropertyKey] = editedPropertyValue;
            return previousState;
        } else {
            let releventService = services.find((s) => s.service_id === serviceID);

            let newClientService = {
                service_id: serviceID,
                client_id: currentClientId,
                start_date: getFundingYear() + '-07-01',
                end_date: getFundingYear() + 1 + '-06-30',
                service_value: '',
                notes: '',
                service: {
                    service_id: releventService.service_id,
                    service_name: releventService.service_name,
                    service_grouping: releventService.service_grouping,
                },
            };

            newClientService[editedPropertyKey] = editedPropertyValue;
            previousState.push(newClientService);
            return previousState;
        }
    }

    function bulkSetServices() {
        let bulkUpdatedClientServices = clientServices.slice();
        selectedForBulk.forEach(function (sfb) {
            //Add records to ClientServices so we can just iterate through next
            bulkUpdatedClientServices = bulkUpsert(bulkUpdatedClientServices, sfb, 'service_id', sfb);
        });

        bulkUpdatedClientServices.forEach(function (s) {
            if (selectedForBulk.includes(s.service_id)) {
                if (bulkAction !== '') {
                    s.service_value = bulkAction;
                }

                if (bulkStartDate !== '') {
                    s.start_date = bulkStartDate;
                }

                s.end_date = bulkEndDate;
            }
        });
        setClientServices(bulkUpdatedClientServices);
    }

    function toggleSelected(isCheck, serv) {
        if (isCheck) {
            setSelectedForBulk((selectedForBulk) => [...selectedForBulk, serv.service_id]);
        } else {
            setSelectedForBulk(selectedForBulk.filter((sfb) => sfb !== serv.service_id));
        }
    }

    function handleDeleteRow(serviceID) {
        setClientServices(clientServices.filter((cs) => cs.service_id !== serviceID));
    }

    async function saveClientServices() {
        let serviceValueIDs = serviceActions.map((obj) => obj.service_id);

        if (clientServices.some((obj) => obj.start_date === '')) {
            let errorName = clientServices.find((obj) => obj.start_date === '').service.service_name;
            toast.error('Please specify a start date for ' + errorName);
        } else if (clientServices.some((obj) => !serviceValueIDs.includes(obj.service_value))) {
            let errorName = clientServices.find((obj) => !serviceValueIDs.includes(obj.service_value)).service
                .service_name;
            toast.error('Please specify a valid service action for ' + errorName);
        } else if (
            clientServices.some((obj) => obj.end_date !== '' && Date.parse(obj.start_date) > Date.parse(obj.end_date))
        ) {
            let errorName = clientServices.find(
                (obj) => obj.end_date !== '' && Date.parse(obj.start_date) > Date.parse(obj.end_date)
            ).service.service_name;

            toast.error('Please specify a valid service date range for ' + errorName);
        } else {
            //console.log(clientServices);

            let clientServiceInfo = {
                client_id: currentClientId,
                client_services: clientServices,
            };

            let result = await clientAPI.SaveClientService(clientServiceInfo);
            console.log(result);

            if (result === false || result === undefined) {
                toast.error('Error updating Client Services');
            } else {
                toast.success('Services successfully updated');
            }
        }
    }

    async function saveNonActiveClientService() {
        if (manualService && manualServiceAction && manualStartDate) {
            // Verify PK values are submitted
            let newService = {
                service_id: manualService,
                client_id: currentClientId,
                start_date: manualStartDate,
                end_date: manualEndDate,
                service_value: manualServiceAction,
                notes: manualNotes,
            };

            //console.log('Saving Manual ClientService...');
            //console.log(newService);
            await clientAPI.SaveNonActiveClientService(newService);
            toast.success('New Service Saved');
        } else {
            toast.error('Please select a Service, Service Action, & Start Date');
        }
    }

    function handleClickOpen() {
        setModalOpen(true);
    }

    function handleClose() {
        setModalOpen(false);
    }

    return (
        <>
            <Box sx={{ width: '90%', margin: '0 auto' }}>
                <ToastContainer theme='colored' />
                <FormControl sx={{ float: 'right', width: '250px' }}>
                    <InputLabel id={'changeClient'}>Change Client</InputLabel>
                    <Select
                        labelId={'changeClient'}
                        id={'changeClientSelect'}
                        label='Change Client'
                        value={clientNames.length > 0 ? currentClientId : ''}
                        onChange={(e) => setCurrentClientId(parseInt(e.target.value))}
                    >
                        <MenuItem value='' disabled>
                            <em>Select a Value</em>
                        </MenuItem>
                        {clientNames.map((cn, i) => {
                            return (
                                <MenuItem value={cn.client_id} key={cn.name + '-' + i}>
                                    {cn.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <h1>{selectedClientName} - Client Services</h1>
                <h2>Active Services</h2>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box>
                        <FormControl sx={{ width: '250px', marginRight: '8px' }}>
                            <InputLabel id={'bulkSetAction'}>Set All Service Actions</InputLabel>
                            <Select
                                labelId={'bulkSetAction'}
                                id={'bulkActionSelect'}
                                label='Set All Service Actions'
                                value={bulkAction}
                                onChange={(e) => setBulkAction(e.target.value)}
                            >
                                <MenuItem value='' disabled>
                                    <em>Select a Value</em>
                                </MenuItem>
                                {serviceActions.map((sv) => {
                                    return (
                                        <MenuItem value={sv.service_id} key={sv.service_text}>
                                            {sv.service_text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            label='Set All Start Dates'
                            sx={{ width: '250px', marginRight: '8px' }}
                            type='date'
                            value={bulkStartDate}
                            onChange={(e) => setBulkStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            label='Set All End Dates'
                            sx={{ width: '250px', marginRight: '8px' }}
                            type='date'
                            value={bulkEndDate}
                            onChange={(e) => setBulkEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            variant='contained'
                            sx={{ width: '250px', marginTop: '6px' }}
                            size='large'
                            onClick={bulkSetServices}
                        >
                            Bulk set
                        </Button>
                    </Box>
                </Box>
                <Grid item xs={12} sx={{ marginBottom: '25px' }}>
                    <FormControlLabel
                        control={<Checkbox color='primary' onChange={(e) => selectAllServices(e, 'all')} />}
                        label='Select All'
                    />

                    <FormControlLabel
                        control={<Checkbox color='primary' onChange={(e) => selectAllServices(e, 'C1')} />}
                        label='Select All C1'
                    />

                    <FormControlLabel
                        control={<Checkbox color='primary' onChange={(e) => selectAllServices(e, 'C2')} />}
                        label='Select All C2'
                    />

                    <FormControlLabel
                        control={<Checkbox color='primary' onChange={(e) => selectAllServices(e, 'Other')} />}
                        label='Select Other'
                    />
                </Grid>

                {services.length > 0 && clientServicesLoaded ? (
                    <>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <TableContainer component={Paper}>
                                    <Table size='small' aria-label='current table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Select for Bulk Edit</TableCell>
                                                <TableCell>Service Name</TableCell>
                                                <TableCell>Service Action</TableCell>
                                                <TableCell>Service Date</TableCell>
                                                <TableCell>Notes</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {services.map((serv, i) => {
                                                let cs = clientServices.find((cs) => cs.service_id === serv.service_id);
                                                return (
                                                    <TableRow
                                                        key={serv.service_name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                value={serv.service_id}
                                                                color='primary'
                                                                checked={selectedForBulk.includes(serv.service_id)}
                                                                onChange={(e) => toggleSelected(e.target.checked, serv)}
                                                            />
                                                        </TableCell>
                                                        <TableCell component='th' scope='row'>
                                                            <Typography
                                                                style={
                                                                    cs !== undefined
                                                                        ? { fontWeight: 'bold' }
                                                                        : { fontWeight: 'normal' }
                                                                }
                                                            >
                                                                {serv.service_name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{ width: '90%' }}>
                                                                <InputLabel id={'actionLabel-' + serv.service_id}>
                                                                    Service Action
                                                                </InputLabel>
                                                                <Select
                                                                    labelId={'actionLabel-' + serv.service_id}
                                                                    id={'actionSelect-' + serv.service_id}
                                                                    label='Service Action'
                                                                    value={
                                                                        cs && cs.service_value
                                                                            ? String(cs.service_value)
                                                                            : ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        upsert(
                                                                            serv.service_id,
                                                                            'service_value',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value='' disabled>
                                                                        <em>Select a Value</em>
                                                                    </MenuItem>
                                                                    {serviceActions.map((sv) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={sv.service_id}
                                                                                key={sv.service_text}
                                                                            >
                                                                                {sv.service_text}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box>
                                                                <TextField
                                                                    label='Start Date'
                                                                    type='date'
                                                                    value={cs && cs.start_date ? cs.start_date : ''}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    sx={{
                                                                        width: '44%',
                                                                        marginRight: '2%',
                                                                    }}
                                                                    onChange={(e) =>
                                                                        upsert(
                                                                            serv.service_id,
                                                                            'start_date',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />

                                                                <TextField
                                                                    label='End Date'
                                                                    type='date'
                                                                    value={cs && cs.end_date ? cs.end_date : ''}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    sx={{ width: '44%' }}
                                                                    onChange={(e) =>
                                                                        upsert(
                                                                            serv.service_id,
                                                                            'end_date',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label='Notes'
                                                                placeholder='Notes'
                                                                multiline
                                                                rows={2}
                                                                value={cs && cs.notes ? cs.notes : ''}
                                                                sx={{ width: '90%' }}
                                                                onChange={(e) =>
                                                                    upsert(serv.service_id, 'notes', e.target.value)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                onClick={() => handleDeleteRow(serv.service_id)}
                                                            >
                                                                <CloseIcon color='error' />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>

                        <Button
                            variant='contained'
                            sx={{ mt: 3, mb: 2, marginRight: '16px' }}
                            onClick={saveClientServices}
                        >
                            Save Client Services
                        </Button>

                        <Button variant='contained' sx={{ mt: 3, mb: 2 }} color='secondary' onClick={handleClickOpen}>
                            Enter Future / Historical Service
                        </Button>

                        <Dialog open={modalOpen} onClose={handleClose}>
                            <Box sx={{ display: 'inline-flex', justifyContent: 'space-between' }}>
                                <DialogTitle sx={{ fontSize: '1.5em' }}>Add Future / Historical Service</DialogTitle>
                                <IconButton
                                    aria-label='close'
                                    onClick={handleClose}
                                    sx={{ height: '100%', alignSelf: 'center' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id={'manualServiceLabel'}>Service</InputLabel>
                                            <Select
                                                labelId={'manualServiceLabel'}
                                                id={'manualServiceSelect'}
                                                label='Service'
                                                value={manualService}
                                                onChange={(e) => setManualService(e.target.value)}
                                            >
                                                <MenuItem value='' disabled>
                                                    <em>Select a Service</em>
                                                </MenuItem>
                                                {services.map((sv) => {
                                                    return (
                                                        <MenuItem value={sv.service_id} key={sv.service_name}>
                                                            {sv.service_name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id={'manualActionLabel'}>Service Action</InputLabel>
                                            <Select
                                                labelId={'manualActionLabel'}
                                                id={'manualActionSelect'}
                                                label='Service Action'
                                                value={manualServiceAction}
                                                onChange={(e) => setManualServiceAction(e.target.value)}
                                            >
                                                <MenuItem value='' disabled>
                                                    <em>Select a Value</em>
                                                </MenuItem>
                                                {serviceActions.map((sv) => {
                                                    return (
                                                        <MenuItem value={sv.service_id} key={sv.service_text}>
                                                            {sv.service_text}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label='Start Date'
                                            type='date'
                                            value={manualStartDate}
                                            InputLabelProps={{ shrink: true }}
                                            sx={{ width: '100%' }}
                                            onChange={(e) => setManualStartDate(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label='End Date'
                                            type='date'
                                            value={manualEndDate}
                                            InputLabelProps={{ shrink: true }}
                                            sx={{ width: '100%' }}
                                            onChange={(e) => setManualEndDate(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label='Notes'
                                            placeholder='Notes'
                                            multiline
                                            rows={3}
                                            value={manualNotes}
                                            sx={{ width: '100%' }}
                                            onChange={(e) => setManualNotes(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                        <Button
                                            variant='contained'
                                            sx={{ mt: 3, mb: 2 }}
                                            color='success'
                                            onClick={saveNonActiveClientService}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>

                        <Box>
                            <h2>Future Services</h2>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Service Name</TableCell>
                                            <TableCell>Service Action</TableCell>
                                            <TableCell>Start Date</TableCell>
                                            <TableCell>End Date</TableCell>
                                            <TableCell>Notes</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {futureClientServices.map((serv, i) => {
                                            return (
                                                <TableRow key={serv.service.service_name}>
                                                    <TableCell scope='row'>{serv.service.service_name}</TableCell>
                                                    <TableCell scope='row'>
                                                        {serv.service_action ? serv.service_action.service_text : ''}
                                                    </TableCell>
                                                    <TableCell scope='row'>{serv.start_date}</TableCell>
                                                    <TableCell scope='row'>{serv.end_date}</TableCell>
                                                    <TableCell scope='row'>{serv.notes}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box>
                            <h2>Historical Services</h2>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Service Name</TableCell>
                                            <TableCell>Service Action</TableCell>
                                            <TableCell>Start Date</TableCell>
                                            <TableCell>End Date</TableCell>
                                            <TableCell>Notes</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {historicalClientServices.map((serv, i) => {
                                            return (
                                                <TableRow key={serv.service.service_name}>
                                                    <TableCell scope='row'>{serv.service.service_name}</TableCell>
                                                    <TableCell scope='row'>
                                                        {serv.service_action ? serv.service_action.service_text : ''}
                                                    </TableCell>
                                                    <TableCell scope='row'>{serv.start_date}</TableCell>
                                                    <TableCell scope='row'>{serv.end_date}</TableCell>
                                                    <TableCell scope='row'>{serv.notes}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    );
}

import React from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form470TrackerAppEdit from '../components/Form470TrackerAppEdit.js';
import TrackersAPI from '../api/TrackersAPI.js';

export default function F470TAppEditPage() {
    const trackersAPI = new TrackersAPI();

    let params = useParams();
    const form470AppNum = params.form470AppNum;
    const form470AppIncrement = params.form470AppIncrement;
    //console.log('form470AppNum = ', form470AppNum);

    if (!form470AppNum) {
        console.error('Invalid form470AppNum: ' + form470AppNum);
        toast.error('Invalid form470AppNum: ' + form470AppNum);
        return null;
    }

    return (
        <>
            <ToastContainer theme='colored' />
            <Form470TrackerAppEdit
                form470AppNum={form470AppNum}
                form470AppIncrement={form470AppIncrement}
                trackersAPI={trackersAPI}
            />
        </>
    );
}

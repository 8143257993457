import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

import ManageAddUsers from '../components/ManageAddUsers.js';
import ManageAddTeams from '../components/ManageAddTeams.js';
import ManageAddOrgs from '../components/ManageAddOrgs.js';
import UserAPI from '../api/UserAPI.js';
import { useAuthContext } from '../useAuthContext';
import { useNavigate } from 'react-router-dom';

const userAPI = new UserAPI();

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any,
    value: PropTypes.any,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function UserManagement() {
    // Tabs
    const [tabValue, setTabValue] = React.useState(0);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const auth = useAuthContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        const getAdminStatus = async () => {
            try {
                let adminResult = await userAPI.IsAdmin(auth.cognitoID);
                setIsAdmin(adminResult);

                if (!adminResult) {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            }
        };

        getAdminStatus();
    }, []);

    // Tabs
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (isAdmin) {
        return (
            <>
                <h2>User Management</h2>
                <ToastContainer theme='colored' />
                <Box sx={{ width: '90%', margin: '0 auto' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label='tabs'>
                            <Tab label='Manage Users' {...a11yProps(0)} />
                            <Tab label='Manage Teams' {...a11yProps(1)} />
                            <Tab label='Manage Organizations' {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <ManageAddUsers
                        api={userAPI}
                        TabPanel={TabPanel}
                        tabPanelValue={tabValue}
                        updateTabPanel={setTabValue}
                    />

                    <ManageAddOrgs
                        api={userAPI}
                        TabPanel={TabPanel}
                        tabPanelValue={tabValue}
                        updateTabPanel={setTabValue}
                    />

                    <ManageAddTeams
                        api={userAPI}
                        TabPanel={TabPanel}
                        tabPanelValue={tabValue}
                        updateTabPanel={setTabValue}
                    />
                </Box>
            </>
        );
    } else {
        return <></>;
    }
}

export default UserManagement;

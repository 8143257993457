import React from 'react';

function F471Stats() {
    return (
        <>
            <h2>Apply for Discounts Statistics</h2>
        </>
    );
}

export default F471Stats;

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function NotesEditDialog({ open, title, label, initialNotes, username, onSave, onCancel, onClose }) {
    const [notesValue, setNotesValue] = React.useState(initialNotes);
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false);

    React.useEffect(() => {
        if (open === true) {
            //console.log('[NotesEditDialog, React.useEffect]  Setting notesValue to initialNotes ');
            setNotesValue(initialNotes);
            setSaveButtonDisabled(false);
        }
    }, [open, initialNotes]);

    const handleAddNoteButtonClick = () => {
        //console.log('[NotesEditDialog] handleAddNoteButtonClick');
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        setNotesValue((n) => `${currentDate} (${username}) - \r\n\r\n${n}`);
    };

    const handleSaveButtonClick = () => {
        //console.log('[NotesEditDialog] handleSaveButtonClick');
        setSaveButtonDisabled(true);
        if (typeof onSave === 'function') {
            onSave(notesValue);
        }
    };

    const handleCancelButtonClick = () => {
        //console.log('[NotesEditDialog] handleCancelButtonClick');
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleDialogClose = () => {
        // User pressed escape or clicked outside the dialog.
        //console.log('[NotesEditDialog] handleDialogClose');
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleDialogClose} PaperProps={{ style: { minWidth: '50%' } }}>
            <DialogTitle sx={{ fontSize: '1.0rem' }}>{title}</DialogTitle>
            <DialogContent>
                <Button
                    variant='contained'
                    size='small'
                    sx={{ backgroundColor: '#4CAF50', marginBottom: 2 }}
                    onClick={handleAddNoteButtonClick}
                >
                    Add Note
                </Button>
                <TextField
                    id='name'
                    label={label || 'Notes'}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    multiline
                    minRows={16}
                    value={notesValue}
                    onChange={(e) => setNotesValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveButtonClick} disabled={saveButtonDisabled}>
                    Save
                </Button>
                <Button onClick={handleCancelButtonClick}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

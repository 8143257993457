import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from './MultiSelect';

function ManageAddUsers(props) {
    const userAPI = props.api;
    const TabPanel = props.TabPanel;

    // User Table
    const [originalRows, setOriginalRows] = React.useState([]);
    const [userRows, setUserRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, userRows.length - page * rowsPerPage);
    const [selectedOrg, setSelectedOrg] = React.useState('');
    const [userSearchTerm, setUserSearchTerm] = React.useState('');

    // Save User
    const [userID, setUserID] = React.useState(-1);
    const [firstname, setFirstName] = React.useState('');
    const [lastname, setLastName] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [employeeType, setEmployeeType] = React.useState('');
    const [userOrgs, setUserOrgs] = React.useState([]);
    const [orgs, setOrgs] = React.useState([]);
    const [submitted, setSubmitted] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    // Add User
    const [newUserOpen, setNewUserOpen] = React.useState(false);

    // Edit User
    const [editUserOpen, setEditUserOpen] = React.useState(false);

    // Save User - User Org/Role/Team table
    const [userRoles, setUserRoles] = React.useState([]);
    const [teams, setTeams] = React.useState([]);

    // Locals
    const selectOrgs = React.useRef(new Map());
    const [employeeTypeSelectOptions, setEmployeeTypeSelectOptions] = React.useState([]);

    // Gets all current User records
    const fetchUsers = async () => {
        let users = await userAPI.GetUsers();
        // console.log('ManageAddUsers: users =', users);

        const parsedUsers = JSON.parse(users.body);
        parsedUsers.sort((a, b) => a.email.localeCompare(b.email));
        console.log('ManageAddUsers: GetUsers parsed =', parsedUsers);

        setOriginalRows(parsedUsers);
        setUserRows(parsedUsers);
    };

    React.useEffect(() => {
        // Gets all current Organization records
        const fetchOrgs = async () => {
            let orgs = await userAPI.GetOrgs();
            console.log('ManageAddUsers: GetOrgs response =', orgs);

            orgs.sort((a, b) => a.display_name.localeCompare(b.display_name));
            setOrgs(orgs);

            // Format orgs for multiselect
            selectOrgs.current = new Map();
            orgs.forEach((org) => {
                selectOrgs.current.set(org.id, org.display_name);
            });
        };

        const fetchUserRoles = async () => {
            let roles = await userAPI.GetUserRoles();
            setUserRoles(roles);
        };

        const fetchTeams = async () => {
            let teams = await userAPI.GetTeams();
            console.log('ManageAddUsers: GetTeams =', teams);
            teams.sort((a, b) => {
                if (a.display_name > b.display_name) {
                    return 1;
                }

                if (b.display_name > a.display_name) {
                    return -1;
                }

                return 0;
            });
            setTeams(teams);
        };

        const fetchEmployeeTypes = async () => {
            let employeeTypes = await userAPI.GetEmployeeTypes();
            console.log('ManageAddUsers: GetEmployeeTypes =', employeeTypes);

            employeeTypes.sort((a, b) => a.display_name.localeCompare(b.display_name));
            setEmployeeTypeSelectOptions(employeeTypes);
        };

        fetchUsers();
        fetchOrgs();
        fetchUserRoles();
        fetchTeams();
        fetchEmployeeTypes();
    }, []);

    const handleUserTextFilter = (event) => {
        setUserSearchTerm(event.target.value);
        filterTable(event.target.value, selectedOrg);
    };

    const handleOrgFilter = (event) => {
        setSelectedOrg(event.target.value);
        filterTable(userSearchTerm, event.target.value);
    };

    // User Table
    const filterTable = (userSearchTerm, orgID) => {
        setPage(0);
        setRowsPerPage(10);
        setUserRows(
            originalRows.filter(
                (row) =>
                    (row.email.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
                        row.first_name.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
                        row.last_name.toLowerCase().includes(userSearchTerm.toLowerCase())) &&
                    (row.organizations.find((o) => o.id === orgID) || orgID === '')
            )
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleUserOrgsChange = (event) => {
        var selectedIDs = event.target.value;

        if (selectedIDs.length < userOrgs.length) {
            let selectedUserOrgs = userOrgs.filter(function (userOrg) {
                return selectedIDs.includes(userOrg.id);
            });

            setUserOrgs(selectedUserOrgs);
        } else {
            let newOrgID = selectedIDs.at(-1);
            let selectedOrg = orgs.find((org) => org.id === newOrgID);

            let newUserOrg = {
                id: selectedOrg.id,
                display_name: selectedOrg.display_name,
                name: selectedOrg.name,
                role_id: 1,
                team_ids: [],
            };
            setUserOrgs((userOrgs) => [...userOrgs, newUserOrg]);
        }
    };

    const handleUserOrgsRoleChange = (orgID, roleID) => {
        let updatedUserOrgs = userOrgs.slice(); //Copy array
        let selectedUserOrgIndex = updatedUserOrgs.findIndex((userOrg) => userOrg.id === orgID);
        updatedUserOrgs[selectedUserOrgIndex].role_id = roleID;
        setUserOrgs(updatedUserOrgs);
    };

    const handleUserOrgsTeamChange = (orgID, teamIDs) => {
        //console.log('ManageAddUsers: teamIDs =', teamIDs);
        let updatedUserOrgs = userOrgs.slice(); //Copy array
        let selectedUserOrgIndex = updatedUserOrgs.findIndex((userOrg) => userOrg.id === orgID);
        updatedUserOrgs[selectedUserOrgIndex].team_ids = teamIDs;
        //console.log('ManageAddUsers: updatedUserOrgs =', updatedUserOrgs);
        setUserOrgs(updatedUserOrgs);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Save User
    async function submitNewUser() {
        try {
            setSubmitted(true);
            if (firstname === '' || lastname === '' || userEmail === '' || userOrgs.length === 0) {
                toast.error('Please complete all required fields');
            } else if (userID === -1 && password === '') {
                toast.error('Password is required to create a user');
            } else {
                let userInfo = {
                    id: userID,
                    first_name: firstname,
                    last_name: lastname,
                    password: password,
                    employee_type: employeeType,
                    email: userEmail,
                    user_orgs: userOrgs,
                };
                await userAPI.SaveUser(userInfo);
                // console.log('ManageAddUsers: SaveUser\'s userInfo =', userInfo);

                let successMessage = userID === -1 ? 'Successfully created user' : 'Successfully updated user';
                toast.success(successMessage);
                setSubmitted(false);
                fetchUsers();
                resetAddUserFields();
                setNewUserOpen(false);
            }
        } catch (error) {
            toast.error('Error saving user');
        }
    }
    async function submitEditUser() {
        try {
            setSubmitted(true);
            if (firstname === '' || lastname === '' || userEmail === '' || userOrgs.length === 0) {
                toast.error('Please complete all required fields');
            } else if (userID === -1 && password === '') {
                toast.error('Password is required to create a user');
            } else {
                let userInfo = {
                    id: userID,
                    first_name: firstname,
                    last_name: lastname,
                    password: password,
                    employee_type: employeeType,
                    email: userEmail,
                    user_orgs: userOrgs,
                };
                await userAPI.SaveUser(userInfo);
                // console.log('ManageAddUsers: SaveUser\'s userInfo =', userInfo);

                let successMessage = 'Successfully updated user';
                toast.success(successMessage);
                handleEditUserClose();
                setSubmitted(false);
                fetchUsers();
                resetAddUserFields();
            }
        } catch (error) {
            toast.error('Error saving user');
        }
    }

    function resetAddUserFields() {
        setUserID(-1);
        setFirstName('');
        setLastName('');
        setUserEmail('');
        setEmployeeType('');
        setPassword('');
        setUserOrgs([]);

        props.updateTabPanel(0);
    }

    // On edit button click
    async function setEditUserDetails(userDetails) {
        setUserID(userDetails.id);
        setFirstName(userDetails.first_name);
        setLastName(userDetails.last_name);
        setPassword('');
        setUserEmail(userDetails.email);
        setUserOrgs(userDetails.organizations);
        setEmployeeType(userDetails.employee.employee_type_id);

        // Merge data from oganizations and user_organizations objects
        let orgData = attachUserOrgsToOrgs(userDetails.organizations, userDetails.user_organizations);
        setUserOrgs(orgData);
    }

    async function deleteUser(userDetails) {
        try {
            // console.log('ManageAddUsers: userDetails =', userDetails);
            await userAPI.DeleteUser(userDetails.id);
            toast.success('Deleted User');
        } catch (error) {
            toast.error('Error deleting user');
        }
    }

    function getAllTeamsForOrgAsMap(orgID) {
        const teamsForOrg = teams.filter((team) => {
            return team.organization_id === orgID;
        });
        const teamsMap = new Map();

        // Format orgs for multiselect
        teamsForOrg.forEach((team) => {
            teamsMap.set(team.id, team.display_name);
        });

        return teamsMap;
    }

    function attachUserOrgsToOrgs(organizations, user_organizations) {
        let orgData = organizations?.map((org) => {
            let userOrg = user_organizations?.find((uOrg) => uOrg.organization_id === org.id);
            org.role_id = userOrg?.role_id;
            org.team_ids = userOrg?.team_membership?.map((team) => team.team_id);
            return org;
        });

        return orgData;
    }

    // Adding Users
    function handleNewUserOpen() {
        setNewUserOpen(true);
    }
    function handleNewUserClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // If they selected values, ensure they want to exit.
        if (userEmail !== '' || firstname !== '' || lastname !== '' || userOrgs.length !== 0) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        setUserEmail('');
        setFirstName('');
        setLastName('');
        setUserOrgs([]);
        setNewUserOpen(false);
    }
    function addNewUser() {
        return (
            <Dialog open={newUserOpen} onClose={handleNewUserClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'Add User'}
                    </DialogTitle>
                    <Box>
                        <TextField
                            error={submitted && userEmail === ''}
                            helperText={submitted && userEmail === '' ? 'User Email is required' : ''}
                            onChange={(event) => setUserEmail(event.target.value)}
                            value={userEmail}
                            required={true}
                            label='User Email'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && firstname === ''}
                            helperText={submitted && firstname === '' ? 'First Name is required' : ''}
                            onChange={(event) => setFirstName(event.target.value)}
                            value={firstname}
                            required={true}
                            label='First Name'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && lastname === ''}
                            helperText={submitted && lastname === '' ? 'Last Name is required' : ''}
                            onChange={(event) => setLastName(event.target.value)}
                            value={lastname}
                            required={true}
                            label='Last Name'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />

                        {
                            <>
                                <FormControl variant='outlined' fullWidth margin='normal'>
                                    <InputLabel htmlFor='password'>Password</InputLabel>
                                    <OutlinedInput
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        label='Password'
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl variant='outlined' fullWidth margin='normal'>
                                    <InputLabel id='employee-type-label'>Employee Type</InputLabel>
                                    <Select
                                        labelId='employee-type-label'
                                        id='employee-type'
                                        value={employeeType !== undefined ? employeeType : ''}
                                        label='Employee Type'
                                        onChange={(event) => setEmployeeType(event.target.value)}
                                        defaultValue=''
                                    >
                                        <MenuItem value=''>No Type</MenuItem>
                                        {employeeTypeSelectOptions.map((row, i) => {
                                            return (
                                                <MenuItem value={row.id} key={row.name}>
                                                    {row.display_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </>
                        }

                        <FormControl fullWidth margin='normal' required error={submitted && userOrgs.length === 0}>
                            <InputLabel id='user-org-label'>Organization</InputLabel>

                            <MultiSelect
                                id='user-org-select'
                                selectedOptions={userOrgs.map((org) => org.id)}
                                options={selectOrgs.current}
                                handleSelectChange={handleUserOrgsChange}
                            />

                            {submitted && userOrgs.length === 0 ? (
                                <FormHelperText>A user must be associated with at least one group</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>

                        <TableContainer component={Paper}>
                            <Table aria-label='user-org-role-team-table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Organization Name</TableCell>
                                        <TableCell>User Role</TableCell>
                                        <TableCell>Teams</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userOrgs.map((row, i) => {
                                        return (
                                            <TableRow key={row.name}>
                                                <TableCell>{row.display_name}</TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth>
                                                        <InputLabel id='user-role-label'>User Role</InputLabel>
                                                        <Select
                                                            labelId='user-role-label'
                                                            id='user-role-select'
                                                            value={row.role_id !== undefined ? row.role_id : 1}
                                                            label='User Role'
                                                            onChange={(event) =>
                                                                handleUserOrgsRoleChange(row.id, event.target.value)
                                                            }
                                                        >
                                                            {userRoles.map((row, i) => {
                                                                return (
                                                                    <MenuItem value={row.role_id} key={row.role_name}>
                                                                        {row.role_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth margin='normal'>
                                                        <InputLabel id='user-org-label'>Teams</InputLabel>

                                                        <MultiSelect
                                                            id='user-team-select'
                                                            selectedOptions={row.team_ids}
                                                            options={getAllTeamsForOrgAsMap(row.id)}
                                                            handleSelectChange={(event) =>
                                                                handleUserOrgsTeamChange(row.id, event.target.value)
                                                            }
                                                        />

                                                        {submitted && userOrgs.length === 0 ? (
                                                            <FormHelperText>
                                                                A user must be associated with at least one group
                                                            </FormHelperText>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                            <Button onClick={submitNewUser} variant='contained'>
                                Save User
                            </Button>
                            <Button onClick={handleNewUserClose} variant='contained' color='error'>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    // Editing Users
    function handleEditUserOpen() {
        setEditUserOpen(true);
    }
    function handleEditUserClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // Reset the state if the user confirmed they want to exit.
        setUserEmail('');
        setFirstName('');
        setLastName('');
        setUserOrgs([]);
        setEditUserOpen(false);
    }
    function editExistingUser() {
        return (
            <Dialog open={editUserOpen} onClose={handleEditUserClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'Edit User'}
                    </DialogTitle>
                    <Box>
                        <TextField
                            error={submitted && userEmail === ''}
                            helperText={submitted && userEmail === '' ? 'User Email is required' : ''}
                            onChange={(event) => setUserEmail(event.target.value)}
                            value={userEmail}
                            required={true}
                            label='User Email'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && firstname === ''}
                            helperText={submitted && firstname === '' ? 'First Name is required' : ''}
                            onChange={(event) => setFirstName(event.target.value)}
                            value={firstname}
                            required={true}
                            label='First Name'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <TextField
                            error={submitted && lastname === ''}
                            helperText={submitted && lastname === '' ? 'Last Name is required' : ''}
                            onChange={(event) => setLastName(event.target.value)}
                            value={lastname}
                            required={true}
                            label='Last Name'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />

                        {
                            <>
                                <FormControl variant='outlined' fullWidth margin='normal'>
                                    <InputLabel htmlFor='password'>Password</InputLabel>
                                    <OutlinedInput
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        label='Password'
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl variant='outlined' fullWidth margin='normal'>
                                    <InputLabel id='employee-type-label'>Employee Type</InputLabel>
                                    <Select
                                        labelId='employee-type-label'
                                        id='employee-type'
                                        value={employeeType}
                                        label='Employee Type'
                                        onChange={(event) => {
                                            console.log('ManageAddUsers: EVENT =', event);
                                            setEmployeeType(event.target.value);
                                        }}
                                        defaultValue=''
                                    >
                                        <MenuItem value=''>No Type</MenuItem>
                                        {employeeTypeSelectOptions.map((row, i) => {
                                            return (
                                                <MenuItem value={row.id} key={row.name}>
                                                    {row.display_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </>
                        }

                        <FormControl fullWidth margin='normal' required error={submitted && userOrgs.length === 0}>
                            <InputLabel id='user-org-label'>Organization</InputLabel>

                            <MultiSelect
                                id='user-org-select'
                                selectedOptions={userOrgs.map((org) => org.id)}
                                options={selectOrgs.current}
                                handleSelectChange={handleUserOrgsChange}
                            />

                            {submitted && userOrgs.length === 0 ? (
                                <FormHelperText>A user must be associated with at least one group</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>

                        <TableContainer component={Paper}>
                            <Table aria-label='user-org-role-team-table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Organization Name</TableCell>
                                        <TableCell>User Role</TableCell>
                                        <TableCell>Teams</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userOrgs.map((row, i) => {
                                        return (
                                            <TableRow key={row.name}>
                                                <TableCell>{row.display_name}</TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth>
                                                        <InputLabel id='user-role-label'>User Role</InputLabel>
                                                        <Select
                                                            labelId='user-role-label'
                                                            id='user-role-select'
                                                            value={row.role_id !== undefined ? row.role_id : 1}
                                                            label='User Role'
                                                            onChange={(event) =>
                                                                handleUserOrgsRoleChange(row.id, event.target.value)
                                                            }
                                                        >
                                                            {userRoles.map((row, i) => {
                                                                return (
                                                                    <MenuItem value={row.role_id} key={row.role_name}>
                                                                        {row.role_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth margin='normal'>
                                                        <InputLabel id='user-org-label'>Teams</InputLabel>

                                                        <MultiSelect
                                                            id='user-team-select'
                                                            selectedOptions={row.team_ids}
                                                            options={getAllTeamsForOrgAsMap(row.id)}
                                                            handleSelectChange={(event) =>
                                                                handleUserOrgsTeamChange(row.id, event.target.value)
                                                            }
                                                        />

                                                        {submitted && userOrgs.length === 0 ? (
                                                            <FormHelperText>
                                                                A user must be associated with at least one group
                                                            </FormHelperText>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                            <Button onClick={submitEditUser} variant='contained'>
                                Save User
                            </Button>
                            <Button onClick={handleEditUserClose} variant='contained' color='error'>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            <TabPanel value={props.tabPanelValue} index={0}>
                <Box display='flex' alignItems='center'>
                    <TextField
                        label='Filter Users'
                        margin='normal'
                        variant='outlined'
                        onChange={handleUserTextFilter}
                    />

                    <FormControl margin='normal' sx={{ minWidth: 240, marginLeft: '25px' }}>
                        <InputLabel id='user-org-filter-label'>Filter by Organization</InputLabel>
                        <Select
                            labelId='user-org-filter-label'
                            id='user-org-filter'
                            value={selectedOrg}
                            label='Organization'
                            onChange={handleOrgFilter}
                            defaultValue={-1}
                        >
                            <MenuItem value=''>No Filter</MenuItem>
                            {orgs.map((row, i) => {
                                return (
                                    <MenuItem value={row.id} key={row.name}>
                                        {row.display_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        id='addNewUser'
                        variant='contained'
                        color='primary'
                        style={{ backgroundColor: '#4CAF50', color: 'white' }}
                        sx={{ m: 2 }}
                        onClick={handleNewUserOpen}
                    >
                        Add User
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table aria-label='Users Table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Organizations</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? userRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : userRows
                            ).map((row, i) => {
                                return (
                                    <TableRow key={row.email}>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.first_name}</TableCell>
                                        <TableCell>{row.last_name}</TableCell>
                                        <TableCell>
                                            <Stack direction='row' spacing={1}>
                                                {row.organizations.map((org, i) => {
                                                    return <Chip key={org.name} label={org.display_name} />;
                                                })}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant='contained'
                                                style={{ marginRight: '15px' }}
                                                onClick={() => {
                                                    console.log('ManageAddUsers: Edit button row data =', row);
                                                    setEditUserDetails(row);
                                                    handleEditUserOpen();
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button variant='contained' color='error' onClick={() => deleteUser(row)}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    count={userRows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </TabPanel>
            {addNewUser()}
            {editExistingUser()}
        </>
    );
}

export default ManageAddUsers;

import React from 'react';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TrackersAPI from '../api/TrackersAPI.js';

// Displays the retrieval datetimes metadata for the USAC data upserted into CompiledForm471UsacData (stored in the Form471TrackerMetadata table).
export default function UsacDataRetrievalDatetimes() {
    const trackersAPI = new TrackersAPI();

    const [form470TrackerMetadata, setForm470TrackerMetadata] = React.useState(null);
    const [form471TrackerMetadata, setForm471TrackerMetadata] = React.useState(null);

    React.useEffect(() => {
        const fetchForm471TrackerMetadata = async () => {
            try {
                let f470_data = await trackersAPI.GetUsacDataRetrievalDatetimes('Form470TrackerMetadata');
                let f471_data = await trackersAPI.GetUsacDataRetrievalDatetimes('Form471TrackerMetadata');
                console.log('[fetchData] f470_data = ', f470_data);
                console.log('[fetchData] f471_data = ', f471_data);
                setForm470TrackerMetadata(f470_data);
                setForm471TrackerMetadata(f471_data);
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        };

        fetchForm471TrackerMetadata();
    }, []);

    return (
        <>
            <ToastContainer theme='colored' />
            <h3 style={{ textAlign: 'center' }}>Form 470 Data Retrieval Datetimes</h3>
            <Form470DataRetrievalDatetimesTable rows={form470TrackerMetadata} />
            <div style={{ marginBottom: '50px' }} />
            <ToastContainer theme='colored' />
            <h3 style={{ textAlign: 'center' }}>Form 471 Data Retrieval Datetimes</h3>
            <Form471DataRetrievalDatetimesTable rows={form471TrackerMetadata} />
        </>
    );
}

const renderDateTime = (isoDateTimeString, displayTime) => {
    if (isoDateTimeString) {
        try {
            let datedate = new Date(isoDateTimeString);
            let text;
            if (!displayTime) {
                text = new Intl.DateTimeFormat('en-US').format(datedate);
            } else {
                let dtfoptions = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                };
                text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
            }
            return <span title={datedate}>{text}</span>;
        } catch (err) {
            console.error('renderDateTime error:', err);
        }
    }
    return isoDateTimeString;
};

function Form470DataRetrievalDatetimesTable({ rows }) {
    if (!rows) {
        // Don't display anything until we have data.
        return null;
    }
    return (
        <Box display='flex' alignItems='center' justifyContent='center'>
            <Table sx={{ width: 1300, border: 1 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Funding Year</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 250 }}>EPC Form 470 apps scraped</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 250 }}>Open Data Form 470</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows &&
                        rows.map((row) => (
                            <StyledTableRow key={row.fundingYear}>
                                <StyledTableCell>{row.fundingyear}</StyledTableCell>
                                <StyledTableCell>
                                    {renderDateTime(row.epcform470_retrievedfromsource_datetime, true)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {renderDateTime(row.odform470_retrievedfromsource_datetime, true)}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
        </Box>
    );
}
function Form471DataRetrievalDatetimesTable({ rows }) {
    if (!rows) {
        // Don't display anything until we have data.
        return null;
    }
    return (
        <Box display='flex' alignItems='center' justifyContent='center'>
            <Table sx={{ width: 1300, border: 1 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Funding Year</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 250 }}>FST data downloaded</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 250 }}>EPC Form 471 apps scraped</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 250 }}>Open Data Form 471 Basic Information</StyledTableCell>
                        {/* <StyledTableCell sx={{ minWidth: 250 }}>ECF Form 471 apps scraped</StyledTableCell> */}
                        <StyledTableCell sx={{ minWidth: 250 }}>Open Data ECF Form 471</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows &&
                        rows.map((row) => (
                            <StyledTableRow key={row.fundingYear}>
                                <StyledTableCell>{row.fundingyear}</StyledTableCell>
                                <StyledTableCell>
                                    {renderDateTime(row.fstform471_retrievedfromsource_datetime, true)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {renderDateTime(row.epcform471_retrievedfromsource_datetime, true)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {renderDateTime(row.odform471bi_retrievedfromsource_datetime, true)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {renderDateTime(row.ecfwebsiteform471_retrievedfromsource_datetime, true)}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
        </Box>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#19638B',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { toast } from 'react-toastify';

import Chip from '@mui/material/Chip';

//import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { DataGridPro, gridColumnFieldsSelector, useGridApiRef } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';

import CustomDatagridToolbar from './CustomDatagridToolbar.js';
//import Form470ApplicationsColumnsDefinitionCreator from './datagridcolumns/Form470ApplicationsColumnsDefinitionCreator.js';

const defaultIssueFilterPrefs = '{"items": []}';
const defaultDateFilterPrefs = '{"items": []}';
const defaultSortPrefs = '[]';
const defaultHiddenColumns =
    '{"FilingWindowID": false, "fundingProgram": false, "state": false, "QAReviewStatusID": false, "QASubmitterUserID": false, "QADateSubmitted": false, "QADateNeeded": false, "QAEstimatedFundingRequest": false, "QAReviewerUserID": false, "_qaNotes": false, "_appStatusCombined": false}';
const defaultPinnedColumns = '{"left": [], "right": []}';

const LS_INCREMENT = 1;
const FIXED_ROW_HEIGHT = 38;
const F470_DENSITY_LS_NAME = 'cedClientListDensityPref' + LS_INCREMENT;
const F470_ROWHEIGHT_LS_NAME = 'cedF470TRowHeight' + LS_INCREMENT;
const F470_ISSUE_FILTER_LS_NAME = 'cedF470TIssueFilterPrefs' + LS_INCREMENT;
const F470_DATE_FILTER_LS_NAME = 'cedF470TDateFilterPrefs' + LS_INCREMENT;
const F470_SORT_LS_NAME = 'cedF470TSortPrefs' + LS_INCREMENT;
const F470_HIDDEN_LS_NAME = 'cedF470THiddenColumns' + LS_INCREMENT;
const F470_COLUMNORDER_LS_NAME = 'cedF470TColumnOrder' + LS_INCREMENT;
const F470_PINNED_LS_NAME = 'cedF470TPinnedColumns' + LS_INCREMENT;

export default function Form470TrackerAppDetails({
    form470AppNum,
    form470AppIncrement,
    trackersAPI,
    handleReturnToSourcePage,
    handleEditButtonClick,
    sourcePageName,
}) {
    const apiRef = useGridApiRef();
    let navigate = useNavigate();

    //~~~  Get app data  ~~~
    const [appData, setAppData] = React.useState(null);

    React.useEffect(() => {
        const fetchForm470AppData = async (form470AppNum, form470AppIncrement) => {
            try {
                let appdata = await trackersAPI.GetForm470TrackerAppData(form470AppNum, form470AppIncrement);
                console.log(JSON.parse(appdata.body));
                //console.log('[fetchForm470AppData] appdata.body = ', appdata.body);
                setAppData(JSON.parse(appdata.body));
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        };

        fetchForm470AppData(form470AppNum, form470AppIncrement);
    }, []);

    const [pageSize, setPageSize] = React.useState(2000);
    const [densityPref, setDensityPref] = useLocalStorage(F470_DENSITY_LS_NAME, 'standard');
    const [rowHeightPref, setRowHeightPref] = useLocalStorage(F470_ROWHEIGHT_LS_NAME, 'fixed'); // Storing 'fixed' or 'auto'
    const [issueFilterPrefs, setIssueFilterPrefs] = useLocalStorage(F470_ISSUE_FILTER_LS_NAME, defaultIssueFilterPrefs);
    const [dateFilterPrefs, setDateFilterPrefs] = useLocalStorage(F470_DATE_FILTER_LS_NAME, defaultDateFilterPrefs);
    const [sortPrefs, setSortPrefs] = useLocalStorage(F470_SORT_LS_NAME, defaultSortPrefs);
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = useLocalStorage(F470_HIDDEN_LS_NAME, defaultHiddenColumns);
    const [pinnedColumnPrefs, setPinnedColumnPrefs] = useLocalStorage(F470_PINNED_LS_NAME, defaultPinnedColumns);

    const getRowHeight = React.useCallback(
        ({ densityFactor }) => {
            if (rowHeightPref === 'auto') {
                return 'auto';
            } else {
                return FIXED_ROW_HEIGHT * densityFactor; // 0.7, 1, or 1.3
            }
        },
        [rowHeightPref]
    );

    const getDetailPanelHeight = React.useCallback(() => 'auto', []);

    const handleDensityChange = (newDensity) => {
        setDensityPref(newDensity);
    };

    async function handleIssueOnFilterModelChange(newFilterModel) {
        setIssueFilterPrefs(JSON.stringify(newFilterModel));
    }
    async function handleDateOnFilterModelChange(newFilterModel) {
        setDateFilterPrefs(JSON.stringify(newFilterModel));
    }

    function handleOnSortModelChange(newSortModel) {
        setSortPrefs(JSON.stringify(newSortModel));
    }

    function handleOnColumnVisibilityModelChange(newVizModel) {
        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    function handlePinnedPrefs(newPinModel) {
        setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    const setColumnOrderPref = (newColOrder) => {
        localStorage.setItem(F470_COLUMNORDER_LS_NAME, JSON.stringify(newColOrder));
    };

    const handleOnColumnOrderChange = React.useCallback(() => {
        setColumnOrderPref(gridColumnFieldsSelector(apiRef.current.state));
    }, [apiRef]);

    //~~~  Display functions  ~~~
    const renderDateTime = (isoDateTimeString, displayTime) => {
        if (isoDateTimeString) {
            try {
                let datedate = new Date(isoDateTimeString);
                let text;
                if (!displayTime) {
                    text = new Intl.DateTimeFormat('en-US').format(datedate);
                } else {
                    let dtfoptions = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    };
                    text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
                }
                return <span title={datedate}>{text}</span>;
            } catch (err) {
                console.error('renderDateTime error:', err);
            }
        }
        return isoDateTimeString;
    };

    const toISODate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns 0-11
        const day = String(date.getUTCDate()).padStart(2, '0');

        return `${month}-${day}-${year}`;
    };

    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    const convertYMDtoMDY = (ymddatestring) => {
        // Converts "YYYY-MM-DD" to "MM/DD/YYYY".
        if (!ymddatestring) {
            return '';
        }
        const noTimeString = ymddatestring.split('T')[0];
        const [year, month, day] = noTimeString.split('-');
        const mdy = [month, day, year].join('/');
        return mdy;
    };

    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getUsersDisplayNames = (users) => {
        if (!users || users.length === 0) {
            return '';
        }
        let ary = users.map((user) => {
            return getUserDisplayName(user);
        });
        return ary.join(', ');
    };

    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    const getApplicantCohortNames = (applicantCohorts) => {
        if (!applicantCohorts || applicantCohorts.length === 0) {
            return '';
        }
        return applicantCohorts
            .map((ac) => {
                return ac.applicantcohort.applicantcohort_name;
            })
            .join(', ');
    };

    function createNavButtons() {
        const EditButton_toeditpage = (
            <Button
                sx={{ m: 1 }}
                variant='contained'
                color='primary'
                size='small'
                onClick={() =>
                    navigate('/apply-for-discounts/f470-applications/edit/' + form470AppNum + form470AppIncrement)
                }
            >
                Edit
            </Button>
        );
        const ButtonToMainReport = (
            <Button
                sx={{ m: 1 }}
                variant='contained'
                color='secondary'
                size='small'
                onClick={() =>
                    navigate('/f470/f470-applications', {
                        state: {
                            updatedRow: appData,
                        },
                    })
                }
            >
                Go to Main Report
            </Button>
        );

        let EditButton_usinghandler = '';
        if (handleEditButtonClick instanceof Function) {
            EditButton_usinghandler = (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={() => handleEditButtonClick(form470AppNum, form470AppIncrement)}
                >
                    Edit
                </Button>
            );
        }

        let ReturnToSourceButton = '';
        if (handleReturnToSourcePage instanceof Function) {
            ReturnToSourceButton = (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={() => handleReturnToSourcePage()}
                >
                    {sourcePageName ? sourcePageName : 'Back'}
                </Button>
            );
        }

        return (
            <>
                {EditButton_usinghandler ? EditButton_usinghandler : EditButton_toeditpage}
                {ReturnToSourceButton ? ReturnToSourceButton : ButtonToMainReport}
            </>
        );
    }

    //~~~  Display  ~~~
    if (!form470AppNum) {
        return (
            <Box>
                <h3>Form 470 Application Details</h3>
                <h4 sx={{ color: 'red' }}>Missing or empty Form 470 AppNum provided.</h4>
            </Box>
        );
    }

    if (!form470AppIncrement) {
        return (
            <Box>
                <h3>Form 470 Application Details</h3>
                <h4 sx={{ color: 'red' }}>Missing or empty Form 470 app increment.</h4>
            </Box>
        );
    }

    if (!appData) {
        // Don't display anything until we have data.
        return null;
    }

    //~~~ QA Issue Functions, Columns, and Rows
    const qa_issue_columns = [
        {
            // TODO QA Issues
            align: 'center',
            headerName: 'ID',
            headerAlign: 'center',
            field: 'issueId',
            width: 100,
        },
        {
            // TODO QA Issues
            align: 'center',
            headerName: 'QA Issues',
            headerAlign: 'center',
            field: 'issueText',
            flex: 1,
        },
    ];
    // handling nulls/undefined/empty array
    const qa_issue_rows =
        appData.qa_issues && appData.qa_issues.length
            ? appData.qa_issues.length > 1
                ? appData.qa_issues.map((issue) => ({ ...issue }))
                : [appData.qa_issues[0]]
            : [];

    //~~~ Date Functions, Columns, and Rows
    const createDateChips = (dl, rowid, keyprefix) => {
        let dateList = dl.split(',');
        return dateList.map((date) => {
            let chipKey = (keyprefix ? keyprefix + '-' : '') + rowid + getRandomNumber(1, 5000);
            let chipLabel = date;
            return <Chip key={chipKey} label={chipLabel} sx={{ marginRight: '2px' }} />;
        });
    };

    const get_YMDString_from_JavascriptDate = (jsdate) => {
        //console.log('jsdate = ', jsdate);
        if (!jsdate) {
            return null;
        }
        if (!(jsdate instanceof Date)) {
            return null;
        }
        let offset = jsdate.getTimezoneOffset();
        let jsdate2 = new Date(jsdate.getTime() - offset * 60 * 1000); // remove offset
        let yyyymmddstring = jsdate2.toISOString().substring(0, 10); // or jsdate2.toISOString().split('T')[0];
        //console.log('yyyymmddstring = ', yyyymmddstring);
        return yyyymmddstring;
    };

    const local_t_and_tz_iso8601string = new Date().toISOString().substring(10);

    const date_columns = [
        {
            // W/T/PreBid Mtg Date
            align: 'center',
            headerName: 'W/T/PreBid Mtg Date',
            headerAlign: 'center',
            renderHeader: (params) => renderMultilineHeader(params, 'Pre Bid', 'Mtg Date'),
            field: 'appdates_WTPreBidMtg',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_WTPreBidMtg || params.row.appdates_WTPreBidMtg.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_WTPreBidMtg[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_WTPreBidMtg.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'preBid');
            },
            flex: 1,
        },
        {
            // Questions Deadline Date
            align: 'center',
            headerName: 'Questions Deadline Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Questions', 'Deadline'),
            headerAlign: 'center',
            field: 'appdates_QuestionsDeadline',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_QuestionsDeadline || params.row.appdates_QuestionsDeadline.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_QuestionsDeadline[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_QuestionsDeadline.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'questionsDeadline');
            },
            flex: 1,
        },
        {
            // Answers Deadline Date
            align: 'center',
            headerName: 'Answers Deadline Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Answers', 'Deadline'),
            headerAlign: 'center',
            field: 'appdates_AnswersDeadline',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_AnswersDeadline || params.row.appdates_AnswersDeadline.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_AnswersDeadline[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_AnswersDeadline.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'answersDeadline');
            },
            flex: 1,
        },
        {
            // Answers Posted Date
            align: 'center',
            headerName: 'Answers Posted Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Answers', 'Posted'),
            headerAlign: 'center',
            field: 'appdates_AnswersPosted',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_AnswersPosted || params.row.appdates_AnswersPosted.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_AnswersPosted[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_AnswersPosted.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'answersPosted');
            },
            flex: 1,
        },
        {
            // Bid Closing Date
            align: 'center',
            headerName: 'Bid Closing Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Closing'),
            headerAlign: 'center',
            field: 'appdates_BidClosing',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_BidClosing || params.row.appdates_BidClosing.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_BidClosing[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_BidClosing.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'bidClosing');
            },
            flex: 1,
        },
        {
            // Bid Opening Date
            align: 'center',
            headerName: 'Bid Evaluation Complete/Vendor Selected Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Bid Eval'),
            headerAlign: 'center',
            field: 'appdates_BidEvalCompVendSel',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_BidEvalCompVendSel || params.row.appdates_BidEvalCompVendSel.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_BidEvalCompVendSel[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_BidEvalCompVendSel.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'bidEvalCompletedVendorSelected');
            },
            flex: 1,
        },
        {
            // Award Date
            align: 'center',
            headerName: 'Award Date',
            headerAlign: 'center',
            field: 'appdates_Award',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_Award || params.row.appdates_Award.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_Award[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_Award.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'awardDate');
            },
            flex: 1,
        },
        {
            // Date Email Sent to Vendors
            align: 'center',
            headerName: 'Date Email Sent to Vendors',
            headerAlign: 'center',
            renderHeader: (params) => renderMultilineHeader(params, 'Email Sent', 'to Vendors'),
            field: 'appdates_EmailSentToVendors',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.appdates_EmailSentToVendors || params.row.appdates_EmailSentToVendors.length === 0) {
                    return [];
                }
                //console.log("valueGetter params", params);
                return [new Date(params.row.appdates_EmailSentToVendors[0]['date'] + local_t_and_tz_iso8601string)];

                // Uncomment to display multiple dates instead of just the first
                // return params.row.appdates_EmailSentToVendors.map(
                //     (issue) => new Date(issue.date + local_t_and_tz_iso8601string)
                // );
            },
            renderCell: (params) => {
                // console.log("renderCell params", params)
                if (params.value === null || params.value.length === 0) {
                    return '';
                }

                if (params.value.length === 1) {
                    // console.log(get_YMDString_from_JavascriptDate(params.value[0]))
                    return get_YMDString_from_JavascriptDate(params.value[0]); // yyyy-mm-dd
                }

                let dateList = params.value.map((date) => get_YMDString_from_JavascriptDate(date)).join(',');
                return createDateChips(dateList, params.row.form470AppNum, 'dateSentToVendors');
            },
            flex: 1,
        },
    ];

    const headerStyle1 = {
        width: '300px',
        fontWeight: 'bold',
    };
    // const headerStyle2 = {
    //     display: 'flex',
    //     width: '250px',
    //     fontWeight: 'bold'
    // };

    return (
        <Box>
            <h2 style={{ marginTop: 0, marginBottom: '1em' }}>Form 470 Application Details</h2>
            {/* <h4>
                AppNum {form470AppNum} - {appData.nickname}
                {appData.isMinibid && <span style={{ color: 'green', marginLeft: '1em' }}>(Mini-Bid)</span>}
            </h4> */}
            <h4>
                AppNum {form470AppNum} - {appData.nickname}
                {appData.isMinibid && (
                    <span style={{ marginLeft: '1em' }}>
                        <span style={{ color: 'black' }}>(</span>
                        <span style={{ color: 'green' }}>Mini-Bid</span>
                        <span style={{ color: 'black' }}>)</span>
                    </span>
                )}
            </h4>
            <Box sx={{ my: 1 }}>{createNavButtons()}</Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <h4 style={{ textDecoration: 'underline' }}>Client Data</h4>
                        <Table>
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell
                                        component='th'
                                        sx={{ fontWeight: 'bold', width: 250 }}
                                        style={headerStyle1}
                                    >
                                        Client Name
                                    </TableCell>
                                    <TableCell>{appData.client_name}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell
                                        component='th'
                                        sx={{ fontWeight: 'bold', width: 250 }}
                                        style={headerStyle1}
                                    >
                                        Client Ben
                                    </TableCell>
                                    <TableCell>
                                        {appData.isMinibid === true ? appData.minibidClientBEN : appData.ben}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell
                                        component='th'
                                        sx={{ fontWeight: 'bold', width: 250 }}
                                        style={headerStyle1}
                                    >
                                        State
                                    </TableCell>
                                    <TableCell>{appData.client_state}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Is a Client
                                    </TableCell>
                                    <TableCell>
                                        {appData.isAClient === true ? 'Yes' : appData.isAClient === false ? 'No' : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                                        Primary Contact
                                    </TableCell>
                                    <TableCell>{getUserDisplayName(appData.client_primary_contact)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                                        Secondary Contacts
                                    </TableCell>
                                    <TableCell>{getUsersDisplayNames(appData.client_secondary_contacts)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                                        Applicant Cohorts
                                    </TableCell>
                                    <TableCell>{getApplicantCohortNames(appData.client_applicantcohorts)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        AFY Form 470 Status
                                    </TableCell>
                                    <TableCell>{appData.cfy_form470_afystatus_text}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        AFY Form 470 Notes
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                                        {appData.cfy_form470_tracker_notes}
                                    </TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>

                        <h4 style={{ textDecoration: 'underline' }}>QA Data</h4>
                        <Table>
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }} style={headerStyle1}>
                                        QA Status
                                    </TableCell>
                                    <TableCell>{appData.qa_review_status_text}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Submitter
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{appData.qa_submitter_name}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Reviewer
                                    </TableCell>
                                    <TableCell>{appData.qa_reviewer_name}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Date Submitted
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_date_submitted, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Date Needed
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_date_needed, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Date Assigned
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_date_assigned, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Date Completed
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_date_completed, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Issues
                                    </TableCell>
                                    <TableCell>
                                        {Array.isArray(appData.qa_issues)
                                            ? appData.qa_issues.map((issue) => (
                                                  <div key={issue.issueId}>{issue.issueText}</div>
                                              ))
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Notes
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{appData.qa_notes}</TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>

                        <h4 style={{ textDecoration: 'underline' }}>Bid Eval QA Data</h4>
                        <Table>
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }} style={headerStyle1}>
                                        Bid Eval QA Status
                                    </TableCell>
                                    <TableCell>{appData.qa_bid_eval_status_text}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Eval QA Submitter
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                                        {appData.qa_bid_eval_submitter_name}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Eval QA Reviewer
                                    </TableCell>
                                    <TableCell>{appData.qa_bid_eval_reviewer_name}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Eval QA Date Submitted
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_bid_eval_date_submitted, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Eval QA Date Needed
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_bid_eval_date_needed, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Eval QA Date Completed
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.qa_bid_eval_date_completed, true)}</TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box>
                        <h4 style={{ textDecoration: 'underline' }}>Form 470 Application USAC Data</h4>
                        <Table>
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Form 470 Application Number
                                    </TableCell>
                                    <TableCell>{appData.form470AppNum}</TableCell>
                                </StyledTableRow>

                                {appData.isMinibid === true && (
                                    <StyledTableRow>
                                        <TableCell
                                            component='th'
                                            sx={{ fontWeight: 'bold', width: 250 }}
                                            style={headerStyle1}
                                        >
                                            Mini-bid Funding Year
                                        </TableCell>
                                        <TableCell>{appData.minibidFundingYear}</TableCell>
                                    </StyledTableRow>
                                )}

                                <StyledTableRow>
                                    <TableCell
                                        component='th'
                                        sx={{ fontWeight: 'bold', width: 250 }}
                                        style={headerStyle1}
                                    >
                                        Application Funding Year
                                    </TableCell>
                                    <TableCell>{appData.fundingYear}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Application BEN
                                    </TableCell>
                                    <TableCell>
                                        {appData.isMinibid === true ? appData.minibidClientBEN : appData.ben}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Application Billed Entity Name
                                    </TableCell>
                                    <TableCell>{appData.billed_entity_name}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Nickname
                                    </TableCell>
                                    <TableCell>{appData.nickname}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Service Types
                                    </TableCell>
                                    <TableCell>
                                        {/* Handles multiple service types*/}
                                        {appData.form470_sr_service_types &&
                                            appData.form470_sr_service_types
                                                .split('|')
                                                .map((st) => <div key={st}>{st}</div>)}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Categories of Service
                                    </TableCell>
                                    <TableCell>{appData.form470_sr_categories_of_service}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        USAC App Status
                                    </TableCell>
                                    <TableCell>{appData.usac_app_status_text}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Certified Date
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.certified_date_time, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Allowable Contract Date
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.allowable_contract_date, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Last Modified Date
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.last_modified_date_time, true)}</TableCell>
                                </StyledTableRow>

                                {/* <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Created Date
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.created_date_time, true)}</TableCell>
                                </StyledTableRow> */}
                            </TableBody>
                        </Table>

                        <h4 style={{ textDecoration: 'underline' }}>Form 470 Application User Entered Data</h4>
                        <Table>
                            <TableBody>
                                {/* <StyledTableRow>
                                    <TableCell component='th' sx={{fontWeight: 'bold'}} style={headerStyle1}>
                                        Mini-Bid
                                    </TableCell>
                                    <TableCell>{appData.isMinibid}</TableCell>
                                </StyledTableRow> */}

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }} style={headerStyle1}>
                                        Application Owner
                                    </TableCell>
                                    <TableCell>{getUserDisplayName(appData.resolved_application_owner)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Task Owner
                                    </TableCell>
                                    <TableCell>{getUsersDisplayNames(appData.task_owners)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        ERC Application Status
                                    </TableCell>
                                    <TableCell>{appData.erc_form470_app_status_text}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        QA Dark Fiber or Self-Provisioned
                                    </TableCell>
                                    <TableCell>{appData.qa_darkfiberselfprovisioned_name}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Form 470 Application Notes
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{appData.form470_app_notes}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Date Sent to Client To Certify
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.certified_date_time)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Issue Date
                                    </TableCell>
                                    <TableCell>{convertYMDtoMDY(appData.issue_date, true)}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Pre Bid Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_WTPreBidMtg && appData.appdates_WTPreBidMtg.length > 0
                                            ? convertYMDtoMDY(appData.appdates_WTPreBidMtg[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Questions Deadline Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_QuestionsDeadline &&
                                        appData.appdates_QuestionsDeadline.length > 0
                                            ? convertYMDtoMDY(appData.appdates_QuestionsDeadline[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Answers Deadline Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_AnswersDeadline && appData.appdates_AnswersDeadline.length > 0
                                            ? convertYMDtoMDY(appData.appdates_AnswersDeadline[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Answers Posted Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_AnswersPosted && appData.appdates_AnswersPosted.length > 0
                                            ? convertYMDtoMDY(appData.appdates_AnswersPosted[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Closing Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_BidClosing && appData.appdates_BidClosing.length > 0
                                            ? convertYMDtoMDY(appData.appdates_BidClosing[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Bid Evaluation Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_BidEvalCompVendSel &&
                                        appData.appdates_BidEvalCompVendSel.length > 0
                                            ? convertYMDtoMDY(appData.appdates_BidEvalCompVendSel[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Award Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_Award && appData.appdates_Award.length > 0
                                            ? convertYMDtoMDY(appData.appdates_Award[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Email Sent Date
                                    </TableCell>
                                    <TableCell>
                                        {appData.appdates_EmailSentToVendors &&
                                        appData.appdates_EmailSentToVendors.length > 0
                                            ? convertYMDtoMDY(appData.appdates_EmailSentToVendors[0]['date'], true)
                                            : ''}
                                    </TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Last Updated By
                                    </TableCell>
                                    <TableCell>{appData.last_updated_by}</TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Last Updated On
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.last_updated_on, true)}</TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>

                        {/* <h4 style={{ textDecoration: 'underline' }}>Form 470 Application data</h4>
                        <Table>
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold', width: 250 }}>
                                        Form 470 Application Number
                                    </TableCell>
                                    <TableCell>{appData.form470AppNum}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Funding Year
                                    </TableCell>
                                    <TableCell>{appData.fundingYear}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        BEN
                                    </TableCell>
                                    <TableCell>{appData.ben}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        ERC Application Status
                                    </TableCell>
                                    <TableCell>{appData.erc_form470_app_status_text}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Date Sent to Client To Certify
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.certified_date_time, true)}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Issue Date
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.issue_date, true)}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Form 470 Application Notes
                                    </TableCell>
                                    <TableCell>{appData.form470_app_notes}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Application Owner
                                    </TableCell>
                                    <TableCell>{getUserDisplayName(appData.resolved_application_owner)}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        Task Owner
                                    </TableCell>
                                    <TableCell>{getUsersDisplayNames(appData.task_owners)}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        AFY Form 470 Status
                                    </TableCell>
                                    <TableCell>{appData.cfy_form470_afystatus_text}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        AFY Form 470 Notes
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                                        {appData.cfy_form470_tracker_notes}
                                    </TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        User-entered Field(s) Updated By
                                    </TableCell>
                                    <TableCell>{appData.last_updated_by}</TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                                        User-entered Field(s) Updated On
                                    </TableCell>
                                    <TableCell>{renderDateTime(appData.last_updated_on, true)}</TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table> */}
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ width: 900, mx: 1 }}>{/* data from Form470TrackerMainReportSchema */}</Box>
            <Box sx={{ display: 'flex' }}></Box>
            <Box>
                {/* <h4 style={{ color: '#19638B' }}>Application Dates</h4>
                <DataGridPro
                    autoHeight={true}
                    experimentalFeatures={{ newEditingApi: true }}
                    columns={date_columns}
                    rows={[appData]}
                    getRowId={(row) => row.form470AppNum}
                    components={{ Toolbar: CustomDatagridToolbar }}
                    componentsProps={{
                        toolbar: {
                            handleDensityChange: handleDensityChange,
                        },
                    }}
                    editMode='row'
                    density={densityPref}
                    getRowHeight={getRowHeight}
                    pagination
                    rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000, 2000]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    filterModel={JSON.parse(dateFilterPrefs)}
                    onFilterModelChange={async (newFilterModel) => await handleDateOnFilterModelChange(newFilterModel)}
                    sortModel={JSON.parse(sortPrefs)}
                    onSortModelChange={(newSortModel) => handleOnSortModelChange(newSortModel)}
                    columnVisibilityModel={JSON.parse(hiddenColumnPrefs)}
                    onColumnVisibilityModelChange={(newVizModel) => handleOnColumnVisibilityModelChange(newVizModel)}
                    pinnedColumns={JSON.parse(pinnedColumnPrefs)}
                    onPinnedColumnsChange={(newPinnedModel) => handlePinnedPrefs(newPinnedModel)}
                    onColumnOrderChange={handleOnColumnOrderChange}
                    apiRef={apiRef}
                    getDetailPanelHeight={getDetailPanelHeight}
                    sx={{
                        minHeight: '300px',
                        height: '74vh',
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: '1px',
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '10px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '20px',
                        },
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell': {
                            border: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                            backgroundColor: '#fdfffe',
                        },
                        '& .MuiDataGrid-row:nth-of-type(2n)': {
                            backgroundColor: '#f0f2f4',
                        },
                        '& .MuiDataGrid-row.Form470-Certified:nth-of-type(2n+1), .MuiDataGrid-row.Form470-Committed:nth-of-type(2n+1)':
                            {
                                backgroundColor: 'hsl(103, 47%, 90%)',
                            },
                        '& .MuiDataGrid-row.Form470-Certified:nth-of-type(2n), .MuiDataGrid-row.Form470-Committed:nth-of-type(2n)':
                            {
                                backgroundColor: 'hsl(103, 43%, 86%)',
                            },
                        '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                            backgroundColor: '#fdfffe',
                        },
                        '& .centered-cell': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                /> */}
                {/* {createNavButtons()} */}
            </Box>
        </Box>
    );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

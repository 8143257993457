import React from 'react';
import useLocalStorage from 'react-use-localstorage';
import useSessionStorage from './useSessionStorage.js';
import { createContext } from 'react';

export const TDGPrefsContext = createContext();

// Form471TrackerAppsTable line 97
export function useProvideTDGPrefsContext(
    localStorageIncrement,
    sessionStorageIncrement,
    localStoragePrefix,
    sessionStoragePrefix,
    defaultFilterPrefs,
    defaultSortPrefs,
    defaultSavedViewPrefs,
    defaultSavedViewNamePrefs,
    defaultCurrentSavedViewWidthPrefs,
    defaultOriginalSavedViewWidthPrefs,
    defaultCurrentSavedViewOrderPrefs,
    defaultOriginalSavedViewOrderPrefs,
    defaultHiddenColumns,
    defaultPinnedColumns
) {
    //--- Display customization and preferences stored in Local Storage (useLocalStorage) ---
    const [densityPrefLS, setDensityPrefLS] = useLocalStorage(
        localStoragePrefix + 'DensityPref' + localStorageIncrement,
        'standard'
    );
    const [filterPrefsLS, setFilterPrefsLS] = useLocalStorage(
        localStoragePrefix + 'FilterPrefs' + localStorageIncrement,
        defaultFilterPrefs
    );
    const [sortPrefsLS, setSortPrefsLS] = useLocalStorage(
        localStoragePrefix + 'SortPrefs' + localStorageIncrement,
        defaultSortPrefs
    );
    const [viewPrefsLS, setViewPrefsLS] = useLocalStorage(
        localStoragePrefix + 'SavedViewPrefs' + localStorageIncrement,
        defaultSavedViewPrefs
    );
    const [viewNamePrefsLS, setViewNamePrefsLS] = useLocalStorage(
        localStoragePrefix + 'SavedViewName' + localStorageIncrement,
        defaultSavedViewNamePrefs
    );
    const [currentSavedViewColumnWidthPrefsLS, setCurrentSavedViewColumnWidthPrefsLS] = useLocalStorage(
        localStoragePrefix + 'CurrentSavedViewColumnsWidth' + localStorageIncrement,
        defaultCurrentSavedViewWidthPrefs
    );
    const [originalSavedViewColumnWidthPrefsLS, setOriginalSavedViewColumnWidthPrefsLS] = useLocalStorage(
        localStoragePrefix + 'OriginalSavedViewColumnsWidth' + localStorageIncrement,
        defaultOriginalSavedViewWidthPrefs
    );
    const [currentSavedViewColumnOrderPrefsLS, setCurrentSavedViewColumnOrderPrefsLS] = useLocalStorage(
        localStoragePrefix + 'CurrentSavedViewColumnsOrder' + localStorageIncrement,
        defaultCurrentSavedViewOrderPrefs
    );
    const [originalSavedViewColumnOrderPrefsLS, setOriginalSavedViewColumnOrderPrefsLS] = useLocalStorage(
        localStoragePrefix + 'OriginalSavedViewColumnsOrder' + localStorageIncrement,
        defaultOriginalSavedViewOrderPrefs
    );
    const [hiddenColumnPrefsLS, setHiddenColumnPrefsLS] = useLocalStorage(
        localStoragePrefix + 'HiddenColumnPrefs' + localStorageIncrement,
        defaultHiddenColumns
    );
    const [pinnedColumnPrefsLS, setPinnedColumnPrefsLS] = useLocalStorage(
        localStoragePrefix + 'PinnedColumnPrefs' + localStorageIncrement,
        defaultPinnedColumns
    );
    const [rowHeightPrefLS, setRowHeightPrefLS] = useLocalStorage(
        localStoragePrefix + 'RowHeightPref' + localStorageIncrement,
        'fixed'
    ); // Storing 'fixed' or 'auto'

    const getColumnOrderPrefLS = () => {
        return localStorage.getItem(localStoragePrefix + 'ColumnOrderPref' + localStorageIncrement);
    };
    const setColumnOrderPrefLS = (newColOrderJSON) => {
        localStorage.setItem(localStoragePrefix + 'ColumnOrderPref' + localStorageIncrement, newColOrderJSON);
    };
    const clearColumnOrderPrefLS = () => {
        localStorage.removeItem(localStoragePrefix + 'ColumnOrderPref' + localStorageIncrement);
    };

    //--- Display customization and preferences stored in Session Storage (useSessionStorage) ---
    const [densityPrefSS, setDensityPrefSS] = useSessionStorage(
        sessionStoragePrefix + 'DensityPref' + sessionStorageIncrement,
        'standard'
    );
    const [filterPrefsSS, setFilterPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'FilterPrefs' + sessionStorageIncrement,
        defaultFilterPrefs
    );
    const [sortPrefsSS, setSortPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'SortPrefs' + sessionStorageIncrement,
        defaultSortPrefs
    );
    const [viewPrefsSS, setViewPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'SavedViewPrefs' + sessionStorageIncrement,
        defaultSavedViewPrefs
    );
    const [viewNamePrefsSS, setViewNamePrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'SavedViewName' + sessionStorageIncrement,
        defaultSavedViewNamePrefs
    );
    const [currentSavedViewColumnWidthPrefsSS, setCurrentSavedViewColumnWidthPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'CurrentSavedViewColumnsWidth' + sessionStorageIncrement,
        defaultCurrentSavedViewWidthPrefs
    );
    const [originalSavedViewColumnWidthPrefsSS, setOriginalSavedViewColumnWidthPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'OriginalSavedViewColumnsWidth' + sessionStorageIncrement,
        defaultOriginalSavedViewWidthPrefs
    );
    const [currentSavedViewColumnOrderPrefsSS, setCurrentSavedViewColumnOrderPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'CurrentSavedViewColumnsOrder' + sessionStorageIncrement,
        defaultCurrentSavedViewOrderPrefs
    );
    const [originalSavedViewColumnOrderPrefsSS, setOriginalSavedViewColumnOrderPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'OriginalSavedViewColumnsOrder' + sessionStorageIncrement,
        defaultOriginalSavedViewOrderPrefs
    );
    const [hiddenColumnPrefsSS, setHiddenColumnPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'HiddenColumnPrefs' + sessionStorageIncrement,
        defaultHiddenColumns
    );
    const [pinnedColumnPrefsSS, setPinnedColumnPrefsSS] = useSessionStorage(
        sessionStoragePrefix + 'PinnedColumnPrefs' + sessionStorageIncrement,
        defaultPinnedColumns
    );
    const [rowHeightPrefSS, setRowHeightPrefSS] = useSessionStorage(
        sessionStoragePrefix + 'RowHeightPref' + sessionStorageIncrement,
        'fixed'
    ); // Storing 'fixed' or 'auto'

    const getColumnOrderPrefSS = () => {
        return sessionStorage.getItem(sessionStoragePrefix + 'ColumnOrderPref' + sessionStorageIncrement);
    };
    const setColumnOrderPrefSS = (newColOrderJSON) => {
        sessionStorage.setItem(sessionStoragePrefix + 'ColumnOrderPref' + sessionStorageIncrement, newColOrderJSON);
    };
    const clearColumnOrderPrefSS = () => {
        sessionStorage.removeItem(sessionStoragePrefix + 'ColumnOrderPref' + sessionStorageIncrement);
    };

    // THEN for retrieval/getter methods: If in SS, returns SS value, else returns LS value.
    //      For setter methods: Save to both SS and LS.

    //--- methods ---

    // view
    const setViewPrefs = (param) => {
        setViewPrefsSS(param);
        setViewPrefsLS(param);
    };
    const resetViewPrefs = () => {
        setViewPrefsSS(defaultSavedViewPrefs);
        setViewPrefsLS(defaultSavedViewPrefs);
    };

    // view name
    const setViewNamePrefs = (param) => {
        setViewNamePrefsSS(param);
        setViewNamePrefsLS(param);
    };
    const resetViewNamePrefs = () => {
        setViewNamePrefsSS(defaultSavedViewNamePrefs);
        setViewNamePrefsLS(defaultSavedViewNamePrefs);
    };

    // saved view current column width
    const setCurrentSavedViewColumnWidthPrefs = (param) => {
        setCurrentSavedViewColumnWidthPrefsSS(param);
        setCurrentSavedViewColumnWidthPrefsLS(param);
    };
    const resetCurrentSavedViewColumnWidthPrefs = () => {
        setCurrentSavedViewColumnWidthPrefsSS(defaultCurrentSavedViewWidthPrefs);
        setCurrentSavedViewColumnWidthPrefsLS(defaultCurrentSavedViewWidthPrefs);
    };

    // saved view original column width
    const setOriginalSavedViewColumnWidthPrefs = (param) => {
        setOriginalSavedViewColumnWidthPrefsSS(param);
        setOriginalSavedViewColumnWidthPrefsLS(param);
    };
    const resetOriginalSavedViewColumnWidthPrefs = () => {
        setOriginalSavedViewColumnWidthPrefsSS(defaultOriginalSavedViewWidthPrefs);
        setOriginalSavedViewColumnWidthPrefsLS(defaultOriginalSavedViewWidthPrefs);
    };

    // saved view current column order
    const setCurrentSavedViewColumnOrderPrefs = (param) => {
        setCurrentSavedViewColumnOrderPrefsSS(param);
        setCurrentSavedViewColumnOrderPrefsLS(param);
    };
    const resetCurrentSavedViewColumnOrderPrefs = () => {
        setCurrentSavedViewColumnOrderPrefsSS(defaultCurrentSavedViewOrderPrefs);
        setCurrentSavedViewColumnOrderPrefsLS(defaultCurrentSavedViewOrderPrefs);
    };

    // saved view original column order
    const setOriginalSavedViewColumnOrderPrefs = (param) => {
        setOriginalSavedViewColumnOrderPrefsSS(param);
        setOriginalSavedViewColumnOrderPrefsLS(param);
    };
    const resetOriginalSavedViewColumnOrderPrefs = () => {
        setOriginalSavedViewColumnOrderPrefsSS(defaultOriginalSavedViewOrderPrefs);
        setOriginalSavedViewColumnOrderPrefsLS(defaultOriginalSavedViewOrderPrefs);
    };

    // density
    const setDensityPref = (griddensity) => {
        // console.log('setDensityPref(griddensity) ', griddensity);
        setDensityPrefSS(griddensity);
        setDensityPrefLS(griddensity);
    };
    const resetDensityPrefToDefault = () => {
        setDensityPrefSS('standard');
        setDensityPrefLS('standard');
    };

    // filter
    const setFilterPrefs = (json) => {
        setFilterPrefsSS(json);
        setFilterPrefsLS(json);
    };
    const resetFilterPrefs = () => {
        setFilterPrefsSS(defaultFilterPrefs);
        setFilterPrefsLS(defaultFilterPrefs);
    };
    const clearFilterPrefs = () => {
        setFilterPrefsSS('{}');
        setFilterPrefsLS('{}');
    };

    // column sorting
    const setSortPrefs = (json) => {
        setSortPrefsSS(json);
        setSortPrefsLS(json);
    };
    const resetSortPrefs = () => {
        setSortPrefsSS(defaultSortPrefs);
        setSortPrefsLS(defaultSortPrefs);
    };

    // column visibility / hidden columns
    const setHiddenColumnPrefs = (json) => {
        setHiddenColumnPrefsSS(json);
        setHiddenColumnPrefsLS(json);
    };
    const resetColumnVisibility = () => {
        setHiddenColumnPrefsSS(defaultHiddenColumns);
        setHiddenColumnPrefsLS(defaultHiddenColumns);
        // resetFilterPrefs(); // uncomment if we want the RCV to be a complete reset
        // resetSortPrefs(); // uncomment if we want the RCV to be a complete reset
        // need to clear the selected view somehow
    };
    const showAllColumns = () => {
        setHiddenColumnPrefsSS('{}');
        setHiddenColumnPrefsLS('{}');
    };
    const updateColumnVisibility = (columns, bVisible) => {
        let existingPrefs = JSON.parse(hiddenColumnPrefsLS);
        columns.forEach((colName) => (existingPrefs[colName] = bVisible));
        setHiddenColumnPrefsSS(JSON.stringify(existingPrefs));
        setHiddenColumnPrefsLS(JSON.stringify(existingPrefs));
    };

    // pinned columns
    const setPinnedColumnPrefs = (json) => {
        setPinnedColumnPrefsSS(json);
        setPinnedColumnPrefsLS(json);
    };

    const resetPinnedColumnsPrefs = () => {
        setPinnedColumnPrefsSS(defaultPinnedColumns);
        setPinnedColumnPrefsLS(defaultPinnedColumns);
    };

    // row height
    const setRowHeightPref = (value) => {
        setRowHeightPrefSS(value);
        setRowHeightPrefLS(value);
    };

    // column order
    const setColumnOrderPref = (json) => {
        //console.log('setColumnOrderPref(json) ', json);
        setColumnOrderPrefSS(json);
        setColumnOrderPrefLS(json);
    };
    const clearColumnOrderPref = () => {
        //console.log('clearColumnOrderPref()');
        clearColumnOrderPrefSS();
        clearColumnOrderPrefLS();
    };

    // returns the setters that you want, meaning find prefs.set examples in the trackerdatagrid.js
    return {
        // saved views
        get viewPrefs() {
            return viewPrefsSS || viewPrefsLS;
        },
        setViewPrefs,
        resetViewPrefs,

        // view names
        get viewNamePrefs() {
            return viewNamePrefsSS || viewNamePrefsLS;
        },
        setViewNamePrefs,
        resetViewNamePrefs,

        // view column current width
        get viewCurrentColumnWidthPrefs() {
            return currentSavedViewColumnWidthPrefsSS || currentSavedViewColumnWidthPrefsLS;
        },
        setCurrentSavedViewColumnWidthPrefs,
        resetCurrentSavedViewColumnWidthPrefs,

        // view column original width
        get viewOriginalColumnWidthPrefs() {
            return originalSavedViewColumnWidthPrefsSS || originalSavedViewColumnWidthPrefsLS;
        },
        setOriginalSavedViewColumnWidthPrefs,
        resetOriginalSavedViewColumnWidthPrefs,

        // view column current order
        get viewCurrentColumnOrderPrefs() {
            return currentSavedViewColumnOrderPrefsSS || currentSavedViewColumnOrderPrefsLS;
        },
        setCurrentSavedViewColumnOrderPrefs,
        resetCurrentSavedViewColumnOrderPrefs,

        // view column original order
        get viewOriginalColumnOrderPrefs() {
            return originalSavedViewColumnOrderPrefsSS || originalSavedViewColumnOrderPrefsLS;
        },
        setOriginalSavedViewColumnOrderPrefs,
        resetOriginalSavedViewColumnOrderPrefs,

        // density
        get densityPrefs() {
            return densityPrefSS || densityPrefLS; // 'compact', 'standard', or 'comfortable'
        },
        setDensityPref,
        resetDensityPrefToDefault,

        // filter
        get filterPrefs() {
            return filterPrefsSS || filterPrefsLS;
        },
        setFilterPrefs,
        resetFilterPrefs,
        clearFilterPrefs,

        // column sorting
        get sortPrefs() {
            return sortPrefsSS || sortPrefsLS;
        },
        setSortPrefs,
        resetSortPrefs,

        // column visibility / hidden columns
        get hiddenColumnPrefs() {
            return hiddenColumnPrefsSS || hiddenColumnPrefsLS;
        },
        setHiddenColumnPrefs,
        resetColumnVisibility,
        showAllColumns,
        updateColumnVisibility,

        // pinned columns
        get pinnedColumnPrefs() {
            return pinnedColumnPrefsSS || pinnedColumnPrefsLS;
        },
        setPinnedColumnPrefs,
        resetPinnedColumnsPrefs,

        // row height
        get rowHeightPref() {
            return rowHeightPrefSS || rowHeightPrefLS; // 'fixed' or 'auto'
        },
        setRowHeightPref,

        // column order
        get columnOrderPref() {
            //console.log('get columnOrderPref()');
            return getColumnOrderPrefSS() || getColumnOrderPrefLS();
        },
        setColumnOrderPref,
        clearColumnOrderPref,
    };
}

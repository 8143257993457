import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    function isOverflown(element) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: '100%',
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17, zIndex: 5 }}
                >
                    <Paper elevation={5} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
                        <Typography variant='body2' sx={{ padding: '0.6em', whiteSpace: 'pre-wrap' }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});

GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};

export function renderCellExpand(params) {
    // If minPopupWidth is not provided then use computedWidth. Otherwise use the greater of minPopupWidth and computedWidth.
    const w = !params.colDef.minPopupWidth
        ? params.colDef.computedWidth
        : Math.max(params.colDef.minPopupWidth, params.colDef.computedWidth);
    return <GridCellExpand value={params.value || ''} width={w} />;
}

export function NotesCellDisplay({ notes, minPopupWidth, computedWidth, rowHeightPref, children }) {
    //console.log("<NotesCellDisplay> rowHeightPref = " + rowHeightPref)
    if (rowHeightPref === 'auto') {
        return notes;
    }
    const w = !minPopupWidth ? computedWidth : Math.max(minPopupWidth, computedWidth);

    // return <GridCellExpand value={notes || ''} width={w} />;

    // the optional children prop will be rendered as well as the notes that were passed in
    return (
        <React.Fragment>
            <GridCellExpand value={notes || ''} width={w} /> {/* Responsible for the text popup seen */}
            {children}
        </React.Fragment>
    );
}

export default renderCellExpand;

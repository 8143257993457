import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { value, onEdit, noteIsEditable, isInViewMode } = props;
    const wrapper = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);

    const isOverflown = (element) => {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    };

    const handleMouseEnter = () => {
        if (isOverflown(cellValue.current)) {
            setAnchorEl(wrapper.current);
            setShowFullCell(true);
        }
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        const handleKeyDown = (nativeEvent) => {
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        };

        if (showFullCell) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (showFullCell) {
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Typography ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {value}
            </Typography>
            {isInViewMode && noteIsEditable && (
                <IconButton size='small' onClick={onEdit} sx={{ ml: 'auto' }}>
                    <EditIcon fontSize='small' />
                </IconButton>
            )}
            {showFullCell && (
                <Popper
                    open={showFullCell}
                    anchorEl={anchorEl}
                    placement='bottom'
                    style={{ zIndex: 5, maxWidth: '30%' }}
                >
                    <Paper elevation={5} sx={{ padding: '0.6em', maxWidth: '100%', wordBreak: 'break-word' }}>
                        <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});

GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    onEdit: PropTypes.func,
    noteIsEditable: PropTypes.bool,
    isInViewMode: PropTypes.bool,
};

export function renderCellExpand(params) {
    const w = !params.colDef.minPopupWidth
        ? params.colDef.computedWidth
        : Math.max(params.colDef.minPopupWidth, params.colDef.computedWidth);

    return (
        <GridCellExpand
            value={params.value || ''}
            width={w}
            onEdit={params.api.getCellValue(params.id, 'onEdit')}
            isInViewMode={params.isInViewMode}
        />
    );
}

export function NotesCellDisplay({
    notes,
    noteIsEditable,
    minPopupWidth,
    computedWidth,
    rowHeightPref,
    onEdit,
    isInViewMode,
    children,
}) {
    const w = !minPopupWidth ? computedWidth : Math.max(minPopupWidth, computedWidth);
    if (rowHeightPref === 'auto') {
        return notes;
    }
    return (
        <React.Fragment>
            <GridCellExpand
                value={notes || ''}
                width={w}
                onEdit={onEdit}
                noteIsEditable={noteIsEditable}
                isInViewMode={isInViewMode}
            />{' '}
            {/* Pass onEdit here */}
            {children}
        </React.Fragment>
    );
}

export default renderCellExpand;

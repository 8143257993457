import React from 'react';

function SecondaryContactsDisplay({ secondaryContacts }) {
    // secondaryContacts  =  [{id: 1234, first_name: 'John', last_name: 'Smith', active: true, deleted: false}]
    //console.log('secondaryContacts:\r\n', secondaryContacts);
    if (!secondaryContacts) {
        return null;
    }
    let ary = [];
    for (const contact of secondaryContacts) {
        // TODO: Style contact depending on..
        // contact.active === false      grey color
        // contact.deleted === true      pink color and strikethrough
        // Maybe change to ul and li?
        ary.push(<div key={contact.id}>{contact.first_name + ' ' + contact.last_name}</div>);
    }
    return <div>{ary}</div>;
}

export default SecondaryContactsDisplay;

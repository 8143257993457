import React from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form471TrackerAppEdit from '../components/Form471TrackerAppEdit.js';
import TrackersAPI from '../api/TrackersAPI.js';

export default function F471TAppEditPage() {
    const trackersAPI = new TrackersAPI();

    let params = useParams();
    const form471AppNum = params.form471AppNum;
    //console.log('form471AppNum = ', form471AppNum);

    if (!form471AppNum) {
        console.error('Invalid form471AppNum: ' + form471AppNum);
        toast.error('Invalid form471AppNum: ' + form471AppNum);
        return null;
    }

    return (
        <>
            <ToastContainer theme='colored' />
            <Form471TrackerAppEdit form471AppNum={form471AppNum} trackersAPI={trackersAPI} />
        </>
    );
}

import React from 'react';

import throttle from 'lodash/throttle';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function ClientBENsListEdit({ bens, addToBENList, removeFromBENList }) {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [entityDataList, setEntityDataList] = React.useState([]);

    const addToBENList2 = (entityData) => {
        addToBENList(parseInt(entityData.entity_number, 10));
    };
    const removeFromBENList2 = (entityData) => {
        removeFromBENList(parseInt(entityData.entity_number, 10));
    };

    //====================================================================================================
    // E-rate Supplemental Entity Information
    // https://opendata.usac.org/E-rate/E-Rate-Supplemental-Entity-Information/7i5i-83qf
    const DatasetIdentifier_SupplementalEntityInformation = '6gqi-pvfx';

    const SocrataAppToken = 'W4bEC1KYhL2M90eTmI0d7UwsT';

    const createAPIURLBase = (datasetIdentifier) => {
        return 'https://opendata.usac.org/resource/' + datasetIdentifier + '.json?$$app_token=' + SocrataAppToken;
    };
    //====================================================================================================

    //====================================================================================================
    //------------------------------------------------------------
    //  Entity data (array) for autocomplete options.
    //      searchText: BEN or applicant name.
    //------------------------------------------------------------
    async function searchSuppEntityInfo(searchText) {
        //console.log('[searchSuppEntityInfo] searchText = ' + searchText);

        //let url = SocrataAPIUtil.createAPIURLBase(SocrataAPIUtil.DatasetIdentifier_SupplementalEntityInformation);
        let url = createAPIURLBase(DatasetIdentifier_SupplementalEntityInformation);
        if (isNumeric(searchText)) {
            // BEN only search..
            url += "&$where=starts_with(entity_number,'" + encodeURIComponent(searchText) + "')";
        } else {
            //url += "&$where=starts_with(entity_number,'" + encodeURIComponent(searchText) + "')";
            //url += " OR starts_with(entity_name,'" + encodeURIComponent(searchText) + "')";
            url += "&$where=starts_with(entity_number,'" + encodeURIComponent(searchText) + "')";
            url += " OR upper(entity_name) like upper('%25" + encodeURIComponent(searchText) + "%25')";
        }
        url += '&$select=distinct entity_number,entity_name,entity_type,status';
        url += '&$order=entity_name';
        url += '&$limit=30';
        //console.log('url = ' + url);

        return fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.hasOwnProperty('error')) {
                    console.error(
                        'error: ' + data.error + '\r\n' + 'code: ' + data.code + '\r\n' + 'message: ' + data.message
                    );
                    return [];
                }
                //console.log('Success.  data = ', data);
                //console.log('Success.  data.length = ' + data.length);
                return data;
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    }

    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };

    //------------------------------------------------------------
    //  Entity data (array) by BENs
    //------------------------------------------------------------
    async function fetchSuppEntityInfo_byBENs(bens) {
        //console.log('[fetchSuppEntityInfo_byBENs]');
        //console.log('bens = \r\n', bens);

        if (bens == null || bens.length === 0) {
            return Promise.resolve([]);
        }

        const commasepBENs = bens.map((ben) => "'" + ben + "'").join(',');
        //console.log('commasepBENs = ' + commasepBENs);

        //let url = SocrataAPIUtil.createAPIURLBase(SocrataAPIUtil.DatasetIdentifier_SupplementalEntityInformation)
        let url = createAPIURLBase(DatasetIdentifier_SupplementalEntityInformation);
        url += '&$where=entity_number IN(' + commasepBENs + ')';
        url += '&$select=distinct entity_number,entity_name,entity_type,status';
        url += '&$order=entity_number';
        //console.log('url = ' + url);

        return fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.hasOwnProperty('error')) {
                    console.error(
                        'error: ' + data.error + '\r\n' + 'code: ' + data.code + '\r\n' + 'message: ' + data.message
                    );
                    return [];
                }
                //console.log('Success.  data = ', data);
                //console.log('Success.  data.length = ' + data.length);
                return data;
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    }
    //====================================================================================================

    // Get entity data from Open Data dataset when `bens` changes.
    React.useEffect(() => {
        //console.log('[React.useEffect] bens: \r\n', bens);
        fetchSuppEntityInfo_byBENs(bens).then((data) => {
            //console.log('[fetchSuppEntityInfo_byBENs(bens).then] data: \r\n', data);
            setEntityDataList(data);
            // if (entityDataList.length !== bens.length) {
            //     console.error('Error: entityDataList.length !== bens.length');
            //     console.error('entityDataList: \r\n', entityDataList);
            //     console.error('bens: \r\n', bens);
            // }
        });
    }, [bens]);

    const fetchODData = React.useMemo(
        () =>
            throttle((searchText, callback) => {
                searchSuppEntityInfo(searchText).then(callback);
            }, 300),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetchODData(inputValue, (searchresults) => {
            //console.log('searchresults = \r\n', searchresults);
            if (active) {
                setOptions(searchresults);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchODData]);

    return (
        <Box sx={{ width: 1100 }}>
            <h4>BEN List ({bens.length})</h4>

            <Box sx={{ mt: 1, mb: 2, p: 2, borderRadius: 2, bgcolor: '#F6F6F6' }}>
                <Box sx={{ mb: 2, fontWeight: 400 }}>Search by BEN or applicant name:</Box>
                <Autocomplete
                    id='bensfromoddataset-autocomplete'
                    sx={{ width: 500, bgcolor: '#FFFFFF' }}
                    options={options}
                    getOptionLabel={(option) => option.entity_number}
                    filterOptions={(x) => x}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue); // newValue is the selected `option` object
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue); // newInputValue is the text in the input box
                    }}
                    renderInput={(params) => <TextField {...params} label='BEN or applicant name' />}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}>
                                <EntityInfoBox data={option} />
                            </li>
                        );
                    }}
                />

                {value && (
                    <Box sx={{ mt: '0.5rem', mb: '0.5rem' }}>
                        <EntityInfoTable data={[value]} handleAddToBENList={addToBENList2} />
                    </Box>
                )}
            </Box>

            <Box sx={{ mt: '0.5rem', mb: '0.5rem' }}>
                <EntityInfoTable data={entityDataList} handleRemoveFromBENList={removeFromBENList2} />
            </Box>
        </Box>
    );
}

function EntityInfoBox({ data }) {
    //console.log('[EntityInfoBox] data = ', data);
    if (data == null) {
        return null;
    }
    return (
        <Grid
            container
            id={'entityinfobox_' + data.entity_number}
            sx={{
                m: '0.2em',
                p: '0.5rem',
                backgroundColor: '#F8F9FA',
                maxWidth: 400,
                border: 1,
                borderRadius: 1,
                borderColor: 'grey.500',
            }}
        >
            <Grid item xs={12}>
                {data.entity_number} | {data.entity_name}
            </Grid>
            <Grid item xs={12}>
                <div>
                    <strong>Entity Type:</strong> {data.entity_type}
                </div>
                <div>
                    <strong>Status:</strong> {data.status}
                </div>
            </Grid>
        </Grid>
    );
}

function EntityInfoTable({ data, handleAddToBENList, handleRemoveFromBENList }) {
    //console.log('[EntityInfoTable]  data: \r\n', data);
    return (
        <TableContainer component={Paper}>
            <Table size='small' sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                color: '#1C6387',
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            BEN
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Name
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Entity Type
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Status
                        </TableCell>
                        {(handleAddToBENList || handleRemoveFromBENList) && (
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                    backgroundColor: '#FDFFFE',
                                }}
                            ></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.entity_number}>
                            <TableCell sx={{ color: '#1C6387', fontWeight: 'bold' }}>{row.entity_number}</TableCell>
                            <TableCell>{row.entity_name}</TableCell>
                            <TableCell>{row.entity_type}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            {(handleAddToBENList || handleRemoveFromBENList) && (
                                <TableCell align='center'>
                                    {handleAddToBENList && (
                                        <Button
                                            variant='contained'
                                            color='info'
                                            size='small'
                                            onClick={() => handleAddToBENList(row)}
                                        >
                                            Add BEN
                                        </Button>
                                    )}
                                    {handleRemoveFromBENList && (
                                        <Button
                                            variant='contained'
                                            color='warning'
                                            size='small'
                                            onClick={() => handleRemoveFromBENList(row)}
                                        >
                                            Remove BEN
                                        </Button>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ClientBENsListEdit;

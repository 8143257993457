import React from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form471TrackerAppDetails from '../components/Form471TrackerAppDetails.js';
import Form471TrackerAppEdit from '../components/Form471TrackerAppEdit.js';
import TrackersAPI from '../api/TrackersAPI.js';

export default function F471TAppDetailsPage({ displayMode }) {
    const trackersAPI = new TrackersAPI();
    const [displayMode2, setDisplayMode2] = React.useState(displayMode && '');

    let params = useParams();
    const form471AppNum = params.form471AppNum;
    //console.log('form471AppNum = ', form471AppNum);

    if (!form471AppNum) {
        console.error('Invalid form471AppNum: ' + form471AppNum);
        toast.error('Invalid form471AppNum: ' + form471AppNum);
        return null;
    }

    function handleEditButtonClick() {
        setDisplayMode2('edit');
    }

    function handleBackToDetails() {
        setDisplayMode2('details');
    }

    if (displayMode2 === 'edit') {
        return (
            <>
                <ToastContainer theme='colored' />
                <Form471TrackerAppEdit
                    form471AppNum={form471AppNum}
                    trackersAPI={trackersAPI}
                    handleReturnToSourcePage={handleBackToDetails}
                />
            </>
        );
    } else {
        return (
            <>
                <ToastContainer theme='colored' />
                <Form471TrackerAppDetails
                    form471AppNum={form471AppNum}
                    trackersAPI={trackersAPI}
                    handleEditButtonClick={handleEditButtonClick}
                />
            </>
        );
    }
}

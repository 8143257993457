import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DateTime } from 'luxon';
import 'react-toastify/dist/ReactToastify.css';
import MultiAutoComplete from './MultiAutoComplete.js';
import ClientBENsListEdit from './ClientBENsListEdit.js';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import { ClientFundingYearEdit } from './ClientFundingYearEdit.js';

export default function ClientCreateEdit(props) {
    const mode = props.mode === 'edit' ? 'edit' : 'create';
    const clientId = mode === 'edit' ? props.clientId : -1;
    const clientAPI = props.clientAPI;
    const userAPI = props.userAPI;
    const sourcePageName = props.sourcePageName ? props.sourcePageName : 'Back';
    const handleReturnToSourcePage =
        props.handleReturnToSourcePage instanceof Function ? props.handleReturnToSourcePage : false;
    //console.log('mode = ' + mode);
    //console.log('clientId = ' + clientId);
    //console.log('handleReturnToSourcePage = ', handleReturnToSourcePage);

    let navigate = useNavigate();

    //~~~  State  ~~~
    const [clientName, setClientName] = React.useState('');
    const [primaryContactId, setPrimaryContactId] = React.useState(null);
    const [clientState, setClientState] = React.useState('');
    const [secondaryContactsUserIds, setSecondaryContactsUserIds] = React.useState([]);
    const [applicantCohortIds, setApplicantCohortIds] = React.useState([]);
    const [clientStartDate, setClientStartDate] = React.useState('');
    const [clientEndDate, setClientEndDate] = React.useState('');
    const [isBusinessOrg, setIsBusinessOrg] = React.useState(false);
    const [legacyClientId, setLegacyClientId] = React.useState(null);
    const [legacyPrimaryBEN, setLegacyPrimaryBEN] = React.useState(null);
    const [filingForECF1, setFilingForECF1] = React.useState('');
    const [filingForECF2, setFilingForECF2] = React.useState('');
    const [filingForECF3, setFilingForECF3] = React.useState('');
    const [clientNotes, setClientNotes] = React.useState('');
    const [cyberSecurityPilotParticipation, setCyberSecurityPilotParticipation] = React.useState('');
    const [currentClientServicesCount, setCurrentClientServicesCount] = React.useState(null);
    const [bens, setBENs] = React.useState([]);
    const [clientSubmitted, setClientSubmitted] = React.useState(false);
    const [employeeList, setEmployeeList] = React.useState([]);
    const [applicantcohortList, setApplicantCohortList] = React.useState([]);
    const [clientStateList, setClientStateList] = React.useState([]);
    const [cipaCompliance, setCIPACompliance] = React.useState('');
    const [updatedClientForNavigation, setUpdatedClientForNavigation] = React.useState({});
    const [username, setUsername] = React.useState(null);

    // BEN List
    const addToBENList = (ben) => {
        let intBEN = isNumeric(ben) ? ben : parseInt(ben, 10); // Make sure only integers go on list.
        if (!bens.includes(intBEN)) {
            setBENs((prevArray) => [...prevArray, intBEN]);
        }
    };
    const removeFromBENList = (ben) => {
        let intBEN = isNumeric(ben) ? ben : parseInt(ben, 10);
        // Create new array without that BEN.
        setBENs((prevArray) =>
            prevArray.filter((value) => {
                return value !== intBEN;
            })
        );
    };
    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };

    const resetFormState = () => {
        setClientName('');
        setPrimaryContactId(null);
        setClientState('');
        setClientStartDate('');
        setClientEndDate('');
        setSecondaryContactsUserIds([]);
        setApplicantCohortIds([]);
        setIsBusinessOrg(false);
        setLegacyClientId(null);
        setLegacyPrimaryBEN(null);
        setFilingForECF1('');
        setFilingForECF2('');
        setFilingForECF3('');
        setClientNotes('');
        setCyberSecurityPilotParticipation('');
        setBENs([]);
        setCurrentClientServicesCount(null);
        setCIPACompliance('');
    };

    //~~~  On first render  ~~~
    React.useEffect(() => {
        const fetchClientData = async (clientId) => {
            let dbdata = await clientAPI.GetClient(clientId);

            console.log('fetchClientData | client data:', dbdata);
            //console.log('[fetchClientData] dbdata.secondary_contacts:', dbdata.secondary_contacts);
            //console.log('[fetchClientData] dbdata.bensList:', dbdata.bensList);

            setClientName(dbdata.name);
            setPrimaryContactId(dbdata.primary_contact_id);
            setClientState(dbdata.state_abbrev);
            setClientStartDate(dbdata.start_date);
            setClientEndDate(dbdata.end_date);
            setIsBusinessOrg(dbdata.is_business_org);
            setLegacyClientId(dbdata.legacy_clientid);
            setLegacyPrimaryBEN(dbdata.legacy_primary_ben);
            setClientNotes(dbdata.notes);

            if (dbdata.secondary_contacts == null) {
                setSecondaryContactsUserIds([]);
            } else {
                setSecondaryContactsUserIds(dbdata.secondary_contacts.map((contact) => contact.id));
            }

            if (dbdata.client_applicantcohorts == null) {
                setApplicantCohortIds([]);
            } else {
                setApplicantCohortIds(dbdata.client_applicantcohorts.map((cac) => cac.applicantcohort_id));
            }

            setCyberSecurityPilotParticipation(dbdata.cybersecuritypilot_participation);
            setFilingForECF1(dbdata.filingForECF1 ?? '');
            setFilingForECF2(dbdata.filingForECF2 ?? '');
            setFilingForECF3(dbdata.filingForECF3 ?? '');

            if (dbdata.bensList === null) {
                setBENs([]);
            } else {
                setBENs(dbdata.bensList);
            }

            setCurrentClientServicesCount(dbdata.currentClientServicesCount);
            setCIPACompliance(dbdata.cipa_compliance);
            setUpdatedClientForNavigation(dbdata);
            //console.log('[fetchClientData] secondaryContactsUserIds:', secondaryContactsUserIds);
            //console.log('[fetchClientData] filingForECF1:', filingForECF1);
            //console.log('[fetchClientData] filingForECF2:', filingForECF2);
            //console.log('[fetchClientData] filingForECF3:', filingForECF3);
            //console.log('[fetchClientData] bens:', bens);
        };

        const fetchEmployeesData = async () => {
            let employeesdb = await userAPI.GetERateGeneralEmployees();
            //console.log('employeesdb:\r\n', employeesdb);

            // Create employee list for Primary Contact dropdown.
            let aryEmployees = [];
            employeesdb.forEach((emp) =>
                aryEmployees.push({
                    user_id: emp.user_id,
                    user_fullname: emp.user.first_name + ' ' + emp.user.last_name,
                })
            );
            //console.log('aryEmployees:\r\n', aryEmployees);
            setEmployeeList(aryEmployees.sort((a, b) => (a.user_fullname > b.user_fullname ? 1 : -1)));
        };

        const fetchApplicantCohortList = async () => {
            let acsfromdb = await clientAPI.GetApplicantCohorts();
            //console.log('acsfromdb:\r\n', acsfromdb);
            // Create Applicant Cohort list for dropdown.
            let ary = [];
            acsfromdb.forEach((ac) =>
                ary.push({
                    applicantcohort_id: ac.applicantcohort_id,
                    applicantcohort_name: ac.applicantcohort_name,
                })
            );
            setApplicantCohortList(ary.sort((a, b) => (a.applicantcohort_name > b.applicantcohort_name ? 1 : -1)));
        };

        const fetchStatesList = async () => {
            let states = await clientAPI.GetStates();
            //console.log('States: \r\n', states);
            setClientStateList(states.sort((a, b) => (a.name > b.name ? 1 : -1)));
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUsername(parsedObject.email.split('@')[0]);
        };

        fetchEmployeesData();
        fetchApplicantCohortList();
        fetchStatesList();
        getUserData();

        if (mode === 'edit' && clientId > 0) {
            fetchClientData(clientId);
        }
    }, []);

    //~~~  Handlers  ~~~
    const handleSecondaryContactsChange = (event, selectedValues) => {
        setSecondaryContactsUserIds(selectedValues);
    };

    const handleApplicantCohortsChange = (event, selectedValues) => {
        setApplicantCohortIds(selectedValues);
    };

    //~~~  Save client data  ~~~
    async function saveClient() {
        try {
            //console.log(secondaryContactsUserIds);
            setClientSubmitted(true);

            // Validation
            if (
                clientName === '' ||
                primaryContactId === null ||
                primaryContactId === '' ||
                clientState === null ||
                clientState === '' ||
                (clientStartDate === null && mode === 'create') ||
                (clientStartDate === '' && mode === 'create')
            ) {
                toast.error('Please complete all required fields');
                return;
            }

            // console.log('saveClient | clientStartDate:', clientStartDate);
            // console.log('saveClient | clientEndDate:', clientEndDate);
            // console.log('saveClient | mode = ', mode);

            if (!isValidInputDate(clientStartDate)) {
                toast.error('Invalid Date Became Client.');
                return false;
            }

            if (!isValidInputDate(clientEndDate)) {
                if (mode === 'create') {
                    setClientEndDate(null); // clientEndDate can be null for new clients
                    //  console.log('saveClient | clientEndDate setting to null:', clientEndDate);
                    return false;
                } else {
                    toast.error('Invalid Date Ended as Client.');
                    return false;
                }
            }

            // Save to database
            let filingForECFArray = [];
            filingForECFArray.push({ window_num: 1, action: filingForECF1 });
            filingForECFArray.push({ window_num: 2, action: filingForECF2 });
            filingForECFArray.push({ window_num: 3, action: filingForECF3 });

            //console.log('[saveClient] bens:', bens);

            let clientInfo = {
                client_id: clientId,
                name: clientName,
                is_business_org: isBusinessOrg,
                primary_contact_id: primaryContactId,
                legacy_clientid: legacyClientId,
                legacy_primary_ben: legacyPrimaryBEN,
                notes: clientNotes,
                cybersecuritypilot_participation: cyberSecurityPilotParticipation,
                state_abbrev: clientState,
                cipa_compliance: cipaCompliance,
                start_date: clientStartDate,
                end_date: clientEndDate,
                secondaryContacts: secondaryContactsUserIds, // stored in separate table
                applicantCohortIds: applicantCohortIds, // stored in separate table
                filingForECFs: filingForECFArray, // stored in separate table
                bens: bens, // stored in separate table
            };

            console.log('saveClient | clientInfo:', clientInfo);
            const saveresponse = await clientAPI.SaveClient(clientInfo);
            console.log('saveClient | saveresponse:', saveresponse);

            if (saveresponse === false) {
                toast.error('Error saving client');
                return;
            }
            let newID = saveresponse.client_id;
            let successMessage = mode === 'create' ? 'Successfully created client' : 'Successfully updated client';
            toast.success(successMessage);

            // Cleanup
            resetFormState();
            setClientSubmitted(false);
            setUpdatedClientForNavigation(saveresponse);

            // Return to client list or details page
            //returnToSourcePage();
            navigate('/client-list/details/' + newID);
        } catch (error) {
            console.error('Error saving client: ', error);
            toast.error('Error saving client');
        }
    }

    const isValidInputDate = (datestring) => {
        if (datestring === null) return true;

        if (datestring === undefined || datestring === '') return false;

        if (datestring !== undefined || datestring !== null || datestring !== '') {
            var luxondatetime = DateTime.fromFormat(datestring, 'yyyy-MM-dd');
            return luxondatetime.isValid;
        }
    };

    //~~~  Navigation  ~~~
    function returnToSourcePage() {
        if (handleReturnToSourcePage) {
            handleReturnToSourcePage();
        } else {
            // Go to the Client List page by default.
            navigate('/client-list', {
                state: {
                    updatedRow: updatedClientForNavigation,
                },
            });
        }
    }

    function cancel() {
        returnToSourcePage();
    }

    //~~~  Display  ~~~
    function generateEmployeeListForMS() {
        const employeeListMS = [];
        employeeList.forEach((emp) => {
            employeeListMS.push({ value: emp.user_id, text: emp.user_fullname });
        });
        //console.log('[generateEmployeeListForMS] employeeListMS:\r\n', employeeListMS);
        return employeeListMS;
    }

    function generateApplicantCohortListForMS() {
        const acListMS = [];
        applicantcohortList.forEach((ac) => {
            acListMS.push({ value: ac.applicantcohort_id, text: ac.applicantcohort_name });
        });
        //console.log('[generateApplicantCohortListForMS] acmap:\r\n', acListMS);
        return acListMS;
    }

    const ecfFilingOptionsMenuItems = [
        <MenuItem value='' key=''>
            <em>None</em>
        </MenuItem>,
        <MenuItem value={'Yes'} key={'Yes'}>
            Yes
        </MenuItem>,
        <MenuItem value={'No'} key={'No'}>
            No
        </MenuItem>,
        <MenuItem value={'Self'} key={'Self'}>
            Self
        </MenuItem>,
        <MenuItem value={'Undecided'} key={'Undecided'}>
            Undecided
        </MenuItem>,
        <MenuItem value={'Unknown'} key={'Unknown'}>
            Unknown
        </MenuItem>,
    ];

    const cybersecuritypilot_participation_items = [
        <MenuItem value='' key=''>
            <em>None</em>
        </MenuItem>,
        <MenuItem value={'Yes'} key={'Yes'}>
            Yes
        </MenuItem>,
        <MenuItem value={'No'} key={'No'}>
            No
        </MenuItem>,
        <MenuItem value={'Self'} key={'Self'}>
            Self
        </MenuItem>,
        <MenuItem value={'Undecided'} key={'Undecided'}>
            Undecided
        </MenuItem>,
        <MenuItem value={'Unknown'} key={'Unknown'}>
            Unknown
        </MenuItem>,
    ];

    const cipaComplianceMenuItems = [
        <MenuItem value='' key=''>
            <em>{'<blank>'}</em>
        </MenuItem>,
        <MenuItem value={'2'} key={'Good'}>
            Good
        </MenuItem>,
        <MenuItem value={'3'} key={'Needs Improvement'}>
            Needs Improvement
        </MenuItem>,
        <MenuItem value={'5'} key={'N/A'}>
            N/A
        </MenuItem>,
    ];

    if (mode === 'edit' && clientId < 1) {
        console.error('Invalid clientId: ' + clientId);
        toast.error('Invalid clientId: ' + clientId);
        return null;
    }

    if (employeeList.length === 0 || applicantcohortList.length === 0) {
        return null;
    }

    const handleAddNoteButtonClick = (setter) => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        setter((prevNotes) => `${currentDate} (${username}) - \r\n\r\n${prevNotes}`);
    };

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />

            <h3>{mode === 'create' ? 'Create' : 'Edit'}</h3>
            <h4>Basic Info</h4>
            <Grid container spacing={3}>
                <Grid item xs={3} sm={3}>
                    <FormControl fullWidth={true} margin='normal'>
                        <TextField
                            label='Client Name'
                            id='clientname-textfield'
                            error={clientSubmitted && clientName === ''}
                            helperText={clientSubmitted && clientName === '' ? 'Client Name is required' : ''}
                            onChange={(event) => setClientName(event.target.value)}
                            value={clientName}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth={true} margin='normal' required>
                        <InputLabel id='primarycontact-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                            Primary Contact
                        </InputLabel>
                        <Select
                            id='primarycontact-select'
                            labelId='primarycontact-label'
                            label='Primary Contact'
                            value={primaryContactId}
                            onChange={(event) => setPrimaryContactId(event.target.value)}
                            required
                            error={clientSubmitted && (primaryContactId === null || primaryContactId === '')}
                        >
                            {employeeList.map((row, i) => {
                                return (
                                    <MenuItem value={row.user_id} key={row.user_id}>
                                        {row.user_fullname}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {clientSubmitted && (primaryContactId === null || primaryContactId === '') && (
                            <FormHelperText sx={{ color: '#d32f2f' }}>'A Primary Contact is required'</FormHelperText>
                        )}
                    </FormControl>

                    <FormControl fullWidth={true} margin='normal'>
                        <MultiAutoComplete
                            id='secondarycontacts-multiselect'
                            label='Secondary Contacts'
                            options={generateEmployeeListForMS()}
                            selectedOptions={secondaryContactsUserIds}
                            handleSelectChange={handleSecondaryContactsChange}
                        />
                    </FormControl>

                    <FormControl fullWidth={true} margin='normal'>
                        <MultiAutoComplete
                            id='applicantcohorts-multiselect'
                            label='Applicant Cohorts'
                            options={generateApplicantCohortListForMS()}
                            selectedOptions={applicantCohortIds}
                            handleSelectChange={handleApplicantCohortsChange}
                        />
                    </FormControl>

                    <FormControl fullWidth={true} margin='normal' required>
                        <InputLabel id='state-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                            Client State
                        </InputLabel>
                        <Select
                            id='state-select'
                            labelId='state-label'
                            label='Client State'
                            value={clientState}
                            onChange={(event) => setClientState(event.target.value)}
                            required
                            error={clientSubmitted && (clientState === null || clientState === '')}
                        >
                            {clientStateList.map((row, i) => {
                                return (
                                    <MenuItem value={row.abbreviation} key={row.abbreviation}>
                                        {row.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {clientSubmitted && (clientState === null || clientState === '') && (
                            <FormHelperText sx={{ color: '#d32f2f' }}>'A state is required'</FormHelperText>
                        )}
                    </FormControl>

                    <FormControl margin='normal'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isBusinessOrg}
                                    onChange={(event) => setIsBusinessOrg(event.target.checked)}
                                />
                            }
                            id='isbusorg-checkbox'
                            label='Is Business Organization'
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={4}>
                    <FormControl fullWidth={true} margin='normal'>
                        <TextField
                            label={mode === 'create' ? 'Date Became Client*' : 'Date Became Client'}
                            id='clientstartdate-textfield'
                            error={clientSubmitted && mode === 'create' && clientStartDate === ''}
                            helperText={
                                clientSubmitted && mode === 'create' && clientStartDate === ''
                                    ? 'Date Became Client is required'
                                    : ''
                            }
                            value={clientStartDate === '' ? null : clientStartDate}
                            // value={clientStartDate}
                            sx={{ width: '250px', marginRight: '8px' }}
                            type='date'
                            onChange={(e) =>
                                e.target.value === '' ? setClientStartDate(null) : setClientStartDate(e.target.value)
                            }
                            // onChange={(e) => setClientStartDate(e.target.value)}
                            // error={clientSubmitted && !isValidInputDate(clientStartDate)}
                            // helperText={clientSubmitted && !isValidInputDate(clientStartDate) ? 'Invalid date' : ''}

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth={true} margin='normal'>
                        <TextField
                            label='Date Ended as Client'
                            id='clientenddate-textfield'
                            value={clientEndDate === '' ? null : clientEndDate}
                            sx={{ width: '250px', marginRight: '8px' }}
                            type='date'
                            onChange={(e) =>
                                e.target.value === '' ? setClientEndDate(null) : setClientEndDate(e.target.value)
                            }
                            // onChange={(e) => setClientEndDate(e.target.value)}
                            // error={clientSubmitted && !isValidInputDate(clientEndDate)}
                            // helperText={clientSubmitted && !isValidInputDate(clientEndDate) ? 'Invalid date' : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>

                    <Box sx={{ mt: 2, mb: 1, width: 800 }}>
                        <Typography display='inline' sx={{ fontWeight: 'bold', mr: 2 }}>
                            # of Current Services:
                        </Typography>
                        <Link href={'/client-list/client-services/' + clientId}>{currentClientServicesCount}</Link>
                    </Box>

                    <Button
                        variant='contained'
                        size='small'
                        sx={{ backgroundColor: '#4CAF50', marginTop: 2 }}
                        onClick={() => handleAddNoteButtonClick(setClientNotes)}
                    >
                        Add Note
                    </Button>
                    <FormControl fullWidth={true} margin='normal'>
                        <TextField
                            label='Notes'
                            value={clientNotes}
                            id='notes-textfield'
                            onChange={(event) => setClientNotes(event.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline
                            rows={9}
                        />
                    </FormControl>
                    {/* <Button
                        variant='contained'
                        size='small'
                        sx={{ backgroundColor: '#4CAF50', marginBottom: 2 }}
                        onClick={() => handleAddNoteButtonClick(setClientNotes)}
                    >
                        Add Note
                    </Button> */}
                </Grid>

                <Grid item xs={3} sm={3}>
                    <FormControl fullWidth margin='normal'>
                        <InputLabel
                            id='filingforecf1-label'
                            // shrink={true}
                            // sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                        >
                            Filing for ECF 1
                        </InputLabel>
                        <Select
                            id='filingforecf1-select'
                            labelId='filingforecf1-label'
                            label='Filing for ECF 1'
                            value={filingForECF1}
                            onChange={(event) => setFilingForECF1(event.target.value)}
                        >
                            {ecfFilingOptionsMenuItems}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin='normal'>
                        <InputLabel id='filingforecf2-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                            Filing for ECF 2
                        </InputLabel>
                        <Select
                            id='filingforecf2-select'
                            labelId='filingforecf2-label'
                            label='Filing for ECF 2'
                            value={filingForECF2}
                            onChange={(event) => setFilingForECF2(event.target.value)}
                        >
                            {ecfFilingOptionsMenuItems}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin='normal'>
                        <InputLabel id='filingforecf3-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                            Filing for ECF 3
                        </InputLabel>
                        <Select
                            id='filingforecf3-select'
                            labelId='filingforecf3-label'
                            label='Filing for ECF 3'
                            value={filingForECF3}
                            onChange={(event) => setFilingForECF3(event.target.value)}
                        >
                            {ecfFilingOptionsMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth={true} margin='normal'>
                        <InputLabel
                            id='cybersecuritypilot-participation-label'
                            shrink={true}
                            sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                        >
                            Cyber Pilot
                        </InputLabel>
                        <Select
                            id='cybersecuritypilot-participation-select'
                            labelId='cybersecuritypilot-participation-label'
                            label='Cyber Pilot'
                            value={cyberSecurityPilotParticipation}
                            onChange={(event) => setCyberSecurityPilotParticipation(event.target.value)}
                        >
                            {cybersecuritypilot_participation_items}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin='normal'>
                        <InputLabel id='cipacompliance-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                            CIPA Compliance
                        </InputLabel>
                        <Select
                            id='cipacompliance-select'
                            labelId='cipacompliance-label'
                            label='CIPA Compliance'
                            value={cipaCompliance}
                            onChange={(event) => setCIPACompliance(event.target.value)}
                        >
                            {cipaComplianceMenuItems}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin='normal'>
                        <TextField
                            label='Primary BEN (legacy)'
                            value={legacyPrimaryBEN}
                            id='primarybenlegacy-textfield'
                            onChange={(event) => setLegacyPrimaryBEN(event.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>

                    {mode === 'edit' && (
                        <FormControl fullWidth margin='normal'>
                            <TextField
                                label='clientId (legacy)'
                                value={legacyClientId}
                                id='clientidlegacy-textfield'
                                onChange={(event) => setLegacyClientId(event.target.value)}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    )}
                </Grid>
            </Grid>
            <Box display='flex' justifyContent='center' alignItems='center' sx={{ m: 3, width: 1200 }}>
                <Button sx={{ m: 1 }} variant='contained' color='primary' size='small' onClick={() => saveClient()}>
                    Save
                </Button>
                <Button sx={{ m: 1 }} variant='contained' color='secondary' size='small' onClick={() => cancel()}>
                    Cancel
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, marginLeft: 0 }}>
                <ClientBENsListEdit bens={bens} addToBENList={addToBENList} removeFromBENList={removeFromBENList} />
            </Box>

            {/* <Box sx={{ marginTop: 0.5, display: 'flex' }}>
                <Box sx={{ flexBasis: '700px' }}>
                    <h4>Basic Info</h4>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: 500 }}>
                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Client Name'
                                id='clientname-textfield'
                                error={clientSubmitted && clientName === ''}
                                helperText={clientSubmitted && clientName === '' ? 'Client Name is required' : ''}
                                onChange={(event) => setClientName(event.target.value)}
                                value={clientName}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal' required>
                            <InputLabel
                                id='primarycontact-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Primary Contact
                            </InputLabel>
                            <Select
                                id='primarycontact-select'
                                labelId='primarycontact-label'
                                label='Primary Contact'
                                value={primaryContactId}
                                onChange={(event) => setPrimaryContactId(event.target.value)}
                                required
                                error={clientSubmitted && (primaryContactId === null || primaryContactId === '')}
                            >
                                {employeeList.map((row, i) => {
                                    return (
                                        <MenuItem value={row.user_id} key={row.user_id}>
                                            {row.user_fullname}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {clientSubmitted && (primaryContactId === null || primaryContactId === '') && (
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                    'A Primary Contact is required'
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <MultiAutoComplete
                                id='secondarycontacts-multiselect'
                                label='Secondary Contacts'
                                options={generateEmployeeListForMS()}
                                selectedOptions={secondaryContactsUserIds}
                                handleSelectChange={handleSecondaryContactsChange}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <MultiAutoComplete
                                id='applicantcohorts-multiselect'
                                label='Applicant Cohorts'
                                options={generateApplicantCohortListForMS()}
                                selectedOptions={applicantCohortIds}
                                handleSelectChange={handleApplicantCohortsChange}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal' required>
                            <InputLabel id='state-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                Client State
                            </InputLabel>
                            <Select
                                id='state-select'
                                labelId='state-label'
                                label='Client State'
                                value={clientState}
                                onChange={(event) => setClientState(event.target.value)}
                                required
                                error={clientSubmitted && (clientState === null || clientState === '')}
                            >
                                {clientStateList.map((row, i) => {
                                    return (
                                        <MenuItem value={row.abbreviation} key={row.abbreviation}>
                                            {row.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {clientSubmitted && (clientState === null || clientState === '') && (
                                <FormHelperText sx={{ color: '#d32f2f' }}>'A state is required'</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label={mode === 'create' ? 'Date Became Client*' : 'Date Became Client'}
                                value={clientStartDate === '' ? null : clientStartDate}
                                // value={clientStartDate}
                                sx={{ width: '250px', marginRight: '8px' }}
                                type='date'
                                onChange={(e) =>
                                    e.target.value === ''
                                        ? setClientStartDate(null)
                                        : setClientStartDate(e.target.value)
                                }
                                // onChange={(e) => setClientStartDate(e.target.value)}
                                // error={clientSubmitted && !isValidInputDate(clientStartDate)}
                                // helperText={clientSubmitted && !isValidInputDate(clientStartDate) ? 'Invalid date' : ''}

                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Date Ended as Client'
                                value={clientEndDate === '' ? null : clientEndDate}
                                sx={{ width: '250px', marginRight: '8px' }}
                                type='date'
                                onChange={(e) =>
                                    e.target.value === '' ? setClientEndDate(null) : setClientEndDate(e.target.value)
                                }
                                // onChange={(e) => setClientEndDate(e.target.value)}
                                // error={clientSubmitted && !isValidInputDate(clientEndDate)}
                                // helperText={clientSubmitted && !isValidInputDate(clientEndDate) ? 'Invalid date' : ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <Box sx={{ mt: 2, mb: 1, width: 800 }}>
                            <Typography display='inline' sx={{ fontWeight: 'bold', mr: 2 }}>
                                # of Current Services:
                            </Typography>
                            <Link href={'/client-list/client-services/' + clientId}>{currentClientServicesCount}</Link>
                        </Box>

                        <FormControl margin='normal'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isBusinessOrg}
                                        onChange={(event) => setIsBusinessOrg(event.target.checked)}
                                    />
                                }
                                id='isbusorg-checkbox'
                                label='Is Business Organization'
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Notes'
                                value={clientNotes}
                                id='notes-textfield'
                                onChange={(event) => setClientNotes(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={6}
                            />
                        </FormControl>

                        <FormControl margin='normal'>
                            <InputLabel
                                id='filingforecf1-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Filing for ECF 1
                            </InputLabel>
                            <Select
                                id='filingforecf1-select'
                                labelId='filingforecf1-label'
                                label='Filing for ECF 1'
                                value={filingForECF1}
                                onChange={(event) => setFilingForECF1(event.target.value)}
                            >
                                {ecfFilingOptionsMenuItems}
                            </Select>
                        </FormControl>

                        <FormControl margin='normal'>
                            <InputLabel
                                id='filingforecf2-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Filing for ECF 2
                            </InputLabel>
                            <Select
                                id='filingforecf2-select'
                                labelId='filingforecf2-label'
                                label='Filing for ECF 2'
                                value={filingForECF2}
                                onChange={(event) => setFilingForECF2(event.target.value)}
                            >
                                {ecfFilingOptionsMenuItems}
                            </Select>
                        </FormControl>

                        <FormControl margin='normal'>
                            <InputLabel
                                id='filingforecf3-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Filing for ECF 3
                            </InputLabel>
                            <Select
                                id='filingforecf3-select'
                                labelId='filingforecf3-label'
                                label='Filing for ECF 3'
                                value={filingForECF3}
                                onChange={(event) => setFilingForECF3(event.target.value)}
                            >
                                {ecfFilingOptionsMenuItems}
                            </Select>
                        </FormControl>

                        <FormControl margin='normal'>
                            <InputLabel
                                id='cipacompliance-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                CIPA Compliance
                            </InputLabel>
                            <Select
                                id='cipacompliance-select'
                                labelId='cipacompliance-label'
                                label='CIPA Compliance'
                                value={cipaCompliance}
                                onChange={(event) => setCIPACompliance(event.target.value)}
                            >
                                {cipaComplianceMenuItems}
                            </Select>
                        </FormControl>

                        <FormControl margin='normal'>
                            <TextField
                                label='Primary BEN (legacy)'
                                value={legacyPrimaryBEN}
                                id='primarybenlegacy-textfield'
                                onChange={(event) => setLegacyPrimaryBEN(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        {mode === 'edit' && (
                            <FormControl margin='normal'>
                                <TextField
                                    label='clientId (legacy)'
                                    value={legacyClientId}
                                    id='clientidlegacy-textfield'
                                    onChange={(event) => setLegacyClientId(event.target.value)}
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        )}
                    </Box>

                    <Box display='flex' justifyContent='center' alignItems='center' sx={{ m: 3, width: 500 }}>
                        <Button
                            sx={{ m: 1 }}
                            variant='contained'
                            color='primary'
                            size='small'
                            onClick={() => saveClient()}
                        >
                            Save
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant='contained'
                            color='secondary'
                            size='small'
                            onClick={() => cancel()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, marginLeft: 2 }}>
                    <ClientBENsListEdit bens={bens} addToBENList={addToBENList} removeFromBENList={removeFromBENList} />
                </Box>
            </Box> */}

            {mode === 'edit' && clientId > 0 && (
                // <Box sx={{ marginTop: 5 }}>
                //     <h4 style={{ color: '#19638B' }}>Per Funding Year Data</h4>
                //     <ClientFundingYearEdit clientAPI={clientAPI} clientId={clientId} />
                // </Box>

                // <Box sx={{ marginTop: 5 }}>
                //     <h4 style={{ color: '#19638B' }}>Per Funding Year Data
                //         <Tooltip
                //                 title={<span style={{ whiteSpace: 'pre-line' }}>- 'Draft' and 'Certified' application counts exclude applications marked as 'Discharged' or 'ERC not Responsible for Filing'<br /></span>}
                //                 sx={{ marginLeft: '0.5em' }}
                //                 placement='top-start'
                //                 arrow
                //                 TransitionComponent={Fade}
                //                 TransitionProps={{ timeout: 900 }}
                //             >
                //                 <Chip label="Legend" variant="outlined" color="info" sx={{ vertical: 'top', float: 'right' }} icon={<InfoIcon />} />
                //         </Tooltip>
                //     </h4>

                //      <ClientFundingYearEdit clientAPI={clientAPI} clientId={clientId} />
                // </Box>

                <Grid container alignItems='flex-end' rowSpacing={0.5}>
                    <Grid item xs={6}>
                        {' '}
                        <Box sx={{ marginTop: 5 }}>
                            <h4 style={{ color: '#19638B' }}>Per Funding Year Data</h4>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display='flex' justifyContent='flex-end'>
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        <ul>
                                            <li>
                                                'Draft' and 'Certified' application counts exclude applications marked
                                                as 'Discarded Application' or 'ERC not Responsible for Filing'
                                            </li>
                                        </ul>
                                        <br />
                                    </span>
                                }
                                // title={<span style={{ whiteSpace: 'pre-line' }}>- 'Draft' and 'Certified' application counts exclude applications marked as 'Discarded Application' or 'ERC not Responsible for Filing'<br /></span>}
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <Chip
                                    label='Legend'
                                    variant='outlined'
                                    color='info'
                                    sx={{ float: 'right' }}
                                    icon={<InfoIcon />}
                                />
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ClientFundingYearEdit
                            bens={bens}
                            clientAPI={clientAPI}
                            clientId={clientId}
                            username={username}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

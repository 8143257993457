import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// MUI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Custom components
import { BulkEditDropdown } from '../customcomponents/BulkEditDropdown.js';
import { BulkEditDatePicker } from '../customcomponents/BulkEditDatePicker.js';

export const Form470AppsBulkEdit = ({
    applicationOwnerEditOptions,
    bulkEditButtonClicked,
    ercAppStatusEditOptions,
    pruUpdatedRow,
    qaBidEvalReviewerEditOptions,
    qaBidEvalStatusEditOptions,
    qaBidEvalSubmitterEditOptions,
    qaDarkFiberEditOptions,
    qaReviewerEditOptions,
    qaReviewStatusEditOptions,
    qaSubmitterEditOptions,
    selectionModel,
    setBulkEditButtonClicked,
    setTrackerData,
    taskOwnerEditOptions,
    trackerData,
    trackersAPI,
    username,
}) => {
    // ################################### Bulk Editing #############################################
    const [openBulkEditDialog, setOpenBulkEditDialog] = React.useState(false);
    const [openBulkEditConfirmChangesTable, setOpenBulkEditConfirmChangesTable] = React.useState(false);
    const [nextButtonClicked, setNextButtonClicked] = React.useState(false);
    const [oldBulkEditRows, setOldBulkEditRows] = React.useState([]);
    const [newBulkEditRows, setNewBulkEditRows] = React.useState([]);

    const [bulkEditApplicationOwner, setBulkEditApplicationOwner] = React.useState('');
    const [bulkEditTaskOwners, setBulkEditTaskOwners] = React.useState('');
    const [bulkEditERCAppStatus, setBulkEditERCAppStatus] = React.useState('');
    const [bulkEditQADarkFiber, setBulkEditQADarkFiber] = React.useState('');
    const [bulkEditDateSentToClientToCertify, setBulkEditDateSentToClientToCertify] = React.useState('');
    const [bulkEditIssueDate, setBulkEditIssueDate] = React.useState('');

    const [bulkEditPreBidDate, setBulkEditPreBidDate] = React.useState('');
    const [bulkEditBidClosing, setBulkEditBidClosing] = React.useState('');
    const [bulkEditAwardDate, setBulkEditAwardDate] = React.useState('');
    const [bulkEditQuestionsDeadline, setBulkEditQuestionsDeadline] = React.useState('');
    const [bulkEditAnswersDeadline, setBulkEditAnswersDeadline] = React.useState('');
    const [bulkEditAnswersPosted, setBulkEditAnswersPosted] = React.useState('');
    const [bulkEditBidEval, setBulkEditBidEval] = React.useState('');
    const [bulkEditEmailSentToVendors, setBulkEditEmailSentToVendors] = React.useState('');

    const [bulkEditQASubmitter, setBulkEditQASubmitter] = React.useState('');
    const [bulkEditQAReviewer, setBulkEditQAReviewer] = React.useState('');
    const [bulkEditQAReviewStatus, setBulkEditQAReviewStatus] = React.useState('');
    const [bulkEditQADateSubmitted, setBulkEditQADateSubmitted] = React.useState('');
    const [bulkEditQADateCompleted, setBulkEditQADateCompleted] = React.useState('');
    const [bulkEditQADateNeeded, setBulkEditQADateNeeded] = React.useState('');
    const [bulkEditQADateAssigned, setBulkEditQADateAssigned] = React.useState('');

    const [bulkEditBidEvalQASubmitter, setBulkEditBidEvalQASubmitter] = React.useState('');
    const [bulkEditBidEvalQAStatus, setBulkEditBidEvalQAStatus] = React.useState('');
    const [bulkEditBidEvalQAReviewer, setBulkEditBidEvalQAReviewer] = React.useState('');
    const [bulkEditBidEvalQADateSubmitted, setBulkEditBidEvalQADateSubmitted] = React.useState('');
    const [bulkEditBidEvalQADateNeeded, setBulkEditBidEvalQADateNeeded] = React.useState('');
    const [bulkEditBidEvalQADateCompleted, setBulkEditBidEvalQADateCompleted] = React.useState('');

    const [bulkEditApplicationNotes, setBulkEditApplicationNotes] = React.useState('');
    const [bulkEditQAReviewerNotes, setBulkEditQAReviewerNotes] = React.useState('');
    const [allChanges, setAllChanges] = React.useState([]);
    const [changedAttributes, setChangedAttributes] = React.useState([]);

    // ################################### States for Has Multiple Values #############################################
    const [bulkEditApplicationOwnerHMV, setBulkEditApplicationOwnerHMV] = React.useState(false);
    const [bulkEditTaskOwnersHMV, setBulkEditTaskOwnersHMV] = React.useState(false);
    const [bulkEditERCAppStatusHMV, setBulkEditERCAppStatusHMV] = React.useState(false);
    const [bulkEditQADarkFiberHMV, setBulkEditQADarkFiberHMV] = React.useState(false);
    const [bulkEditDateSentToClientToCertifyHMV, setBulkEditDateSentToClientToCertifyHMV] = React.useState(false);
    const [bulkEditIssueDateHMV, setBulkEditIssueDateHMV] = React.useState(false);
    const [bulkEditPreBidDateHMV, setBulkEditPreBidDateHMV] = React.useState(false);
    const [bulkEditQuestionsDeadlineHMV, setBulkEditQuestionsDeadlineHMV] = React.useState(false);
    const [bulkEditAnswersDeadlineHMV, setBulkEditAnswersDeadlineHMV] = React.useState(false);
    const [bulkEditAnswersPostedHMV, setBulkEditAnswersPostedHMV] = React.useState(false);
    const [bulkEditBidClosingHMV, setBulkEditBidClosingHMV] = React.useState(false);
    const [bulkEditAwardDateHMV, setBulkEditAwardDateHMV] = React.useState(false);
    const [bulkEditEmailSentToVendorsHMV, setBulkEditEmailSentToVendorsHMV] = React.useState(false);

    // ################################### Initial Values #############################################
    const [bulkEditApplicationOwnerIV, setBulkEditApplicationOwnerIV] = React.useState('');
    const [bulkEditTaskOwnersIV, setBulkEditTaskOwnersIV] = React.useState('');
    const [bulkEditERCAppStatusIV, setBulkEditERCAppStatusIV] = React.useState('');
    const [bulkEditQADarkFiberIV, setBulkEditQADarkFiberIV] = React.useState('');
    const [bulkEditDateSentToClientToCertifyIV, setBulkEditDateSentToClientToCertifyIV] = React.useState('');
    const [bulkEditIssueDateIV, setBulkEditIssueDateIV] = React.useState('');

    const [bulkEditPreBidDateIV, setBulkEditPreBidDateIV] = React.useState('');
    const [bulkEditBidClosingIV, setBulkEditBidClosingIV] = React.useState('');
    const [bulkEditAwardDateIV, setBulkEditAwardDateIV] = React.useState('');
    const [bulkEditQuestionsDeadlineIV, setBulkEditQuestionsDeadlineIV] = React.useState('');
    const [bulkEditAnswersDeadlineIV, setBulkEditAnswersDeadlineIV] = React.useState('');
    const [bulkEditAnswersPostedIV, setBulkEditAnswersPostedIV] = React.useState('');
    const [bulkEditBidEvalIV, setBulkEditBidEvalIV] = React.useState('');
    const [bulkEditEmailSentToVendorsIV, setBulkEditEmailSentToVendorsIV] = React.useState('');

    const [bulkEditQASubmitterIV, setBulkEditQASubmitterIV] = React.useState('');
    const [bulkEditQAReviewerIV, setBulkEditQAReviewerIV] = React.useState('');
    const [bulkEditQAReviewStatusIV, setBulkEditQAReviewStatusIV] = React.useState('');
    const [bulkEditQADateSubmittedIV, setBulkEditQADateSubmittedIV] = React.useState('');
    const [bulkEditQADateCompletedIV, setBulkEditQADateCompletedIV] = React.useState('');
    const [bulkEditQADateNeededIV, setBulkEditQADateNeededIV] = React.useState('');
    const [bulkEditQADateAssignedIV, setBulkEditQADateAssignedIV] = React.useState('');

    const [bulkEditBidEvalQASubmitterIV, setBulkEditBidEvalQASubmitterIV] = React.useState('');
    const [bulkEditBidEvalQAStatusIV, setBulkEditBidEvalQAStatusIV] = React.useState('');
    const [bulkEditBidEvalQAReviewerIV, setBulkEditBidEvalQAReviewerIV] = React.useState('');
    const [bulkEditBidEvalQADateSubmittedIV, setBulkEditBidEvalQADateSubmittedIV] = React.useState('');
    const [bulkEditBidEvalQADateNeededIV, setBulkEditBidEvalQADateNeededIV] = React.useState('');
    const [bulkEditBidEvalQADateCompletedIV, setBulkEditBidEvalQADateCompletedIV] = React.useState('');

    const [bulkEditQAReviewStatusHMV, setBulkEditQAReviewStatusHMV] = React.useState(false);
    const [bulkEditQADateSubmittedHMV, setBulkEditQADateSubmittedHMV] = React.useState(false);
    const [bulkEditQADateCompletedHMV, setBulkEditQADateCompletedHMV] = React.useState(false);
    const [bulkEditQADateNeededHMV, setBulkEditQADateNeededHMV] = React.useState(false);
    const [bulkEditQADateAssignedHMV, setBulkEditQADateAssignedHMV] = React.useState(false);
    const [bulkEditQAReviewerHMV, setBulkEditQAReviewerHMV] = React.useState(false);
    const [bulkEditQASubmitterHMV, setBulkEditQASubmitterHMV] = React.useState(false);

    const [bulkEditBidEvalHMV, setBulkEditBidEvalHMV] = React.useState(false);
    const [bulkEditBidEvalQADateSubmittedHMV, setBulkEditBidEvalQADateSubmittedHMV] = React.useState(false);
    const [bulkEditBidEvalQADateCompletedHMV, setBulkEditBidEvalQADateCompletedHMV] = React.useState(false);
    const [bulkEditBidEvalQADateNeededHMV, setBulkEditBidEvalQADateNeededHMV] = React.useState(false);
    const [bulkEditBidEvalQAStatusHMV, setBulkEditBidEvalQAStatusHMV] = React.useState(false);
    const [bulkEditBidEvalQAReviewerHMV, setBulkEditBidEvalQAReviewerHMV] = React.useState(false);
    const [bulkEditBidEvalQASubmitterHMV, setBulkEditBidEvalQASubmitterHMV] = React.useState(false);

    const [confirmButtonIsDisabled, setConfirmButtonIsDisabled] = React.useState(false);
    const [cancelButtonIsDisabled, setCancelButtonIsDisabled] = React.useState(false);
    const [backButtonIsDisabled, setBackButtonIsDisabled] = React.useState(false);
    const [bulkEditProgressIsOpen, setBulkEditProgressIsOpen] = React.useState(false);

    // Adds newly-selected existing data to a state
    React.useEffect(() => {
        setOldBulkEditSelectedRows();
    }, [selectionModel]);

    // Creates the displayed changes on the "confirmation page"
    React.useEffect(() => {
        // if (oldBulkEditRows.length > 0 && openBulkEditConfirmChangesTable) {
        if (oldBulkEditRows.length > 0) {
            const all_changes = findChangesBetweenRows(oldBulkEditRows, newBulkEditRows);
            if (all_changes.length !== 0) {
                // console.log('[promptBulkEditChangesConfirmation][all_changes] =', all_changes);
                // console.log('[promptBulkEditChangesConfirmation][changedAttributes] = ', changedAttributes);
                setAllChanges(all_changes);
            }

            // Determine the unique set of changed attributes across all selected records
            const changedAttributes = [...new Set(all_changes.flatMap((ea_change) => Object.keys(ea_change.changes)))];
            // console.log('[promptBulkEditChangesConfirmation][changedAttributes] =', changedAttributes);
            setChangedAttributes(changedAttributes);
        }
    }, [nextButtonClicked]);

    // After the user clicks the next button, add the newly modified data/records to a state
    React.useEffect(() => {
        if (newBulkEditRows.length > 0) {
            setOpenBulkEditConfirmChangesTable(true);
        }
    }, [newBulkEditRows]);

    // After processRunUpdate, find and update the corresponding row in trackerData (needed to prevent display errors during bulk editing)
    React.useEffect(() => {
        const updatedTrackerData = trackerData.map((row) => {
            if (
                `${row.form470AppNum}-${row.form470AppIncrement}` ===
                `${pruUpdatedRow.form470AppNum}-${pruUpdatedRow.form470AppIncrement}`
            ) {
                return pruUpdatedRow; // Replace with the updated row
            }
            return row; // Return unchanged row
        });

        setTrackerData(updatedTrackerData);
    }, [pruUpdatedRow]);

    // Triggered when the user clicks the BE button in the TrackerDataGrid
    React.useEffect(() => {
        if (bulkEditButtonClicked === true) {
            handleBulkEditButtonClicked();
            setBulkEditButtonClicked(false);
        }
    }, [bulkEditButtonClicked]);

    // If value is a number, return it.  If value is text then search the dropdown options for the numeric value (id).
    const correctTheDropdownValue = (value, ddoptions) => {
        if (value === null || value === undefined) {
            return 0;
        }
        if (Number.isInteger(value)) {
            return value;
        }
        if (
            value === '' ||
            value.toLowerCase() === '<default to primary contact>' ||
            value.toLowerCase() === '<not set>' ||
            value.toLowerCase() === '<blank>'
        ) {
            return 0;
        }

        let ddoption = ddoptions.find((element) => element.text === value);
        let ddvalue = ddoption?.value;
        return ddvalue;
    };

    // TODO: Review why this is actually necessary in BE
    const setApplicationOwnerUserID = (value) => {
        if (Number.isInteger(value)) {
            return value;
        } else {
            return value || 0;
        }
    };

    // Extracts first and last name from object
    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    // Provides a display text based on a provided ID and array of options
    const getOtherDisplayName = (id, dropDownOptions) => {
        if (!id) {
            return '';
        }

        const results = dropDownOptions.find((option) => option.value === id);
        return results?.text;
    };

    // Used to potentially extract 1+ ('First Last') names from the task owners array of objects
    const getTaskOwnerNames = (value) => {
        if (!value || value.length === 0) {
            return '';
        }
        let ary = value.map((tu) => {
            return getUserDisplayName(tu);
        });
        return ary.join(', ');
    };

    // Date handling
    const create_todayJSDate = () => {
        // Create a 'today' JavaScript Date for DataGrid 'date' edit fields.
        let today = new Date();
        today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero.
        return new Date(today.getTime() + today.getTimezoneOffset() * 60 * 1000);
    };

    // Date handling
    const convert_JSDate_to_yyyymmddString = (jsdate) => {
        // Input: Javascript Date object.
        // Output: Date string in the format "yyyy-mm-dd".  Time was pushed back as per the local timezone offset.
        if (!(jsdate instanceof Date)) {
            console.error('Error in convert_JSDate_to_yyyymmddString: Input was not a JavaScript Date object.');
            return null;
        }
        let jsdate2 = new Date(jsdate.getTime() - jsdate.getTimezoneOffset() * 60 * 1000);
        return jsdate2.toISOString().split('T')[0];
    };

    // V2 Date handling
    const addDaysToDateNeeded = (dateStr) => {
        // Split the date string into year, month, day
        const [year, month, day] = dateStr.split('-').map(Number);

        // Month - 1 because the months are zero-indexed
        // const date = new Date(Date.UTC(year, month - 1, day));
        const date = new Date(year, month - 1, day);

        // Getting the day of the week (0-6, Sunday-Saturday)
        const dayOfTheWeek = date.getDay();

        // Clone of the date (for adding the dates later)
        let adjustedDate = new Date(date);

        // If it's Monday, move the date to the closest Wednesday (2 days until Wednesday)
        if (dayOfTheWeek === 1) {
            adjustedDate.setDate(date.getDate() + 2);
        }
        // If it's Tuesday, move the date to the closest Thursday (3 days to Thursday)
        else if (dayOfTheWeek === 2) {
            adjustedDate.setDate(date.getDate() + 2);
        }
        // If it's Wednesday, move the date to the closest Friday
        else if (dayOfTheWeek === 3) {
            adjustedDate.setDate(date.getDate() + 2); // 2 days to Friday
        }
        // If it's Thursday or Friday, move the date to the closest Tuesday (9 - dayOfTheWeek gives the number of days to Tuesday)
        else if (dayOfTheWeek === 4 || dayOfTheWeek === 5) {
            adjustedDate.setDate(date.getDate() + (9 - dayOfTheWeek));
        }
        // If it's Saturday, move the date to the closest Tuesday (3 days to Tuesday)
        else if (dayOfTheWeek === 6) {
            adjustedDate.setDate(date.getDate() + 3);
        }
        // If it's Sunday, move the date to the closest Tuesday (2 days to Tuesday)
        else if (dayOfTheWeek === 0) {
            adjustedDate.setDate(date.getDate() + 2);
        }

        return adjustedDate.toISOString().split('T')[0];
    };

    // ERC Date handling
    const addDaysToDateNeededERC = (dateStr) => {
        if (!dateStr) {
            return dateStr;
        }

        let date = new Date(dateStr);
        const weekday = date.getDay(); // (0-6, Sunday-Saturday)

        // Add 2 days to the date by default
        let daysToAdd = 2;

        // Thursday/Friday (4 days Until Monday)
        if (weekday === 4 || weekday === 5) {
            daysToAdd = 4;
        }
        // Saturday (3 days until Tuesday)
        else if (weekday === 6) {
            daysToAdd = 3;
        }
        // Sunday (2 days until Tuesday)
        else if (weekday === 0) {
            daysToAdd = 2;
        }
        date.setDate(date.getDate() + daysToAdd);

        // Returns the date in YYYY-MM-DD format
        return date.toISOString().split('T')[0];
    };

    // Ran *only* if the user has selected a new ERC status (value 50/ready) after the next button has been clicked
    const checkIfEmptyAndFillQAFields = (uedata) => {
        // User has changed the "ERC App Status" dropdown to "Ready for QA" (50)...
        // If empty, set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.

        let ExistingSubmitterUserID = uedata.qa_submitter_user_id;
        let ExistingDateSubmitted = uedata.qa_date_submitted;
        let ExistingDateNeeded = uedata.qa_date_needed;

        if (ExistingSubmitterUserID == null && ExistingDateSubmitted == null && ExistingDateNeeded == null) {
            let newQASubmitterUserId = -1;
            if (uedata.application_owner_user_id !== null) {
                // If "Application Owner" has been overriden, then use that value.
                newQASubmitterUserId = uedata.application_owner_user_id;
            } else {
                // If "Application Owner" is the default value..
                if (uedata.resolved_application_owner.id) {
                    newQASubmitterUserId = uedata.resolved_application_owner.id;
                } else if (uedata.resolved_application_owner.user_id) {
                    newQASubmitterUserId = uedata.resolved_application_owner.user_id;
                }
            }

            let bOnQASubmitterDropdownList = false;
            let ddoption = qaSubmitterEditOptions.find((element) => element.value === newQASubmitterUserId);
            if (ddoption) {
                bOnQASubmitterDropdownList = true;
            }

            if (bOnQASubmitterDropdownList) {
                uedata.qa_submitter_user_id = newQASubmitterUserId;
            }

            let today = create_todayJSDate();
            uedata.qa_date_submitted = convert_JSDate_to_yyyymmddString(today);
            uedata.qa_date_needed = addDaysToDateNeededERC(today);

            return uedata;
        }
        return uedata;
    };

    // Ran *only* if the user has selected a new Bid Eval QA Status (value 1/ready) after the next button is clicked
    const checkIfEmptyAndFillQABidEvalFields = (uedata) => {
        // User has changed the "Bid Eval" dropdown to "Ready for QA" (50)...
        // If empty, set "QA Bid Eval Submitter", "QA Bid Eval Date Submitted", and "QA Bid Eval Date Needed" to defaults.

        // console.log('checkIfEmptyAndFillQABidEvalFields --> uedata:', uedata)
        let ExistingSubmitterUserID = uedata.qa_bid_eval_submitter_user_id;
        let ExistingDateSubmitted = uedata.qa_bid_eval_date_submitted;
        let ExistingDateNeeded = uedata.qa_bid_eval_date_needed;
        let Existing470QAReviewer = uedata.qa_reviewer_user_id;

        if (ExistingSubmitterUserID == null && ExistingDateSubmitted == null && ExistingDateNeeded == null) {
            //*****  Set "QA Bid Eval Submitter", "QA Bid Eval Date Submitted", and "QA Bid Eval Date Needed" to defaults.  *****

            let newQABidEvalSubmitterUserId = -1;
            if (uedata.application_owner_user_id !== null) {
                // If "Application Owner" has been overriden, then use that value.
                newQABidEvalSubmitterUserId = uedata.application_owner_user_id;
            } else {
                // If "Application Owner" is the default value..
                if (uedata.resolved_application_owner.id) {
                    newQABidEvalSubmitterUserId = uedata.resolved_application_owner.id;
                } else if (uedata.resolved_application_owner.user_id) {
                    newQABidEvalSubmitterUserId = uedata.resolved_application_owner.user_id;
                }
            }

            let bOnQABidEvalSubmitterDropdownList = false;
            let ddoption = qaBidEvalSubmitterEditOptions.find(
                (element) => element.value === newQABidEvalSubmitterUserId
            );
            if (ddoption) {
                bOnQABidEvalSubmitterDropdownList = true;
            }

            if (bOnQABidEvalSubmitterDropdownList) {
                uedata.qa_bid_eval_submitter_user_id = newQABidEvalSubmitterUserId;
            }

            let today = create_todayJSDate();
            uedata.qa_bid_eval_date_submitted = convert_JSDate_to_yyyymmddString(today);
            uedata.qa_bid_eval_date_needed = addDaysToDateNeededERC(today);
            uedata.qa_bid_eval_reviewer_user_id = Existing470QAReviewer;

            return uedata;
        }
        return uedata;
    };

    // Changed attribute to column header mapping
    const attributeHeaderMapping = {
        application_owner_id: 'Application Owner',
        task_owner_id: 'Task Owner',
        qa_submitter_user_id: 'QA Submitter',
        qa_reviewer_user_id: 'QA Reviewer',
        qa_review_status_id: 'QA Review Status',
        qa_bid_eval_submitter_user_id: 'QA Bid Eval Submitter',
        qa_bid_eval_status_id: 'QA Bid Eval Status',
        qa_bid_eval_reviewer_user_id: 'QA Bid Eval Reviewer',
        erc_form470_app_status_id: 'ERC App Status',
        qa_darkfiberselfprovisioned_name: 'Dark Fiber or Self Prov',
        qa_date_submitted: 'QA Date Submitted',
        qa_date_needed: 'QA Date Needed',
        qa_date_assigned: 'QA Date Assigned',
        qa_date_completed: 'QA Date Completed',
        qa_bid_eval_date_submitted: 'QA Bid Eval Date Submitted',
        qa_bid_eval_date_needed: 'QA Bid Eval Date Needed',
        qa_bid_eval_date_completed: 'QA Bid Eval Date Completed',
        date_sent_to_client_to_certify: 'Date Sent to Client to Certify',
        appdates_WTPreBidMtg: 'Pre Bid',
        appdates_BidClosing: 'Bid Closing',
        appdates_Award: 'Award Date',
        appdates_QuestionsDeadline: 'Questions Deadline',
        appdates_AnswersDeadline: 'Answers Deadline',
        appdates_AnswersPosted: 'Answers Posted',
        appdates_BidEvalCompVendSel: 'Bid Eval',
        appdates_EmailSentToVendors: 'Email Sent to Vendors',
        issue_date: 'Issue Date',
        form470_app_notes: 'Application Notes',
        qa_notes: 'QA Reviewer Notes',
    };

    // Needed for when the data's options has 0 for one of the values of the {text, value} keypairs
    const emptyValueDisplayMapping = {
        application_owner_id: '<Default to Primary Contact>',
        task_owner_id: '<Not Set>',
        qa_submitter_user_id: '<Not Set>',
        qa_review_status_id: '<Not Set>',
        qa_reviewer_user_id: '<Not Set>',
        qa_bid_eval_submitter_user_id: '<Not Set>',
        qa_bid_eval_status_id: '<Not Set>',
        qa_bid_eval_reviewer_user_id: '<Not Set>',
        erc_form470_app_status_id: '<Not Set>',
        qa_darkfiberselfprovisioned_name: '<Not Set>',
    };

    // A mapping of attribute to state setter to check if any key in a Bulk Edit action has any varying values and set true/false accordingly. Used in checkIfAttrHasMultipleValues once the Bulk Edit button is clicked
    const attributeBEStateMapping = {
        resolved_application_owner: [
            setBulkEditApplicationOwnerHMV,
            setBulkEditApplicationOwnerIV,
            setBulkEditApplicationOwner,
        ],
        task_owners: [setBulkEditTaskOwnersHMV, setBulkEditTaskOwnersIV, setBulkEditTaskOwners],
        erc_form470_app_status_id: [setBulkEditERCAppStatusHMV, setBulkEditERCAppStatusIV, setBulkEditERCAppStatus],
        qa_darkfiberselfprovisioned_name: [setBulkEditQADarkFiberHMV, setBulkEditQADarkFiberIV, setBulkEditQADarkFiber],
        date_sent_to_client_to_certify: [
            setBulkEditDateSentToClientToCertifyHMV,
            setBulkEditDateSentToClientToCertifyIV,
            setBulkEditDateSentToClientToCertify,
        ],
        issue_date: [setBulkEditIssueDateHMV, setBulkEditIssueDateIV, setBulkEditIssueDate],

        appdates_WTPreBidMtg: [setBulkEditPreBidDateHMV, setBulkEditPreBidDateIV, setBulkEditPreBidDate],
        appdates_BidClosing: [setBulkEditBidClosingHMV, setBulkEditBidClosingIV, setBulkEditBidClosing],
        appdates_Award: [setBulkEditAwardDateHMV, setBulkEditAwardDateIV, setBulkEditAwardDate],
        appdates_QuestionsDeadline: [
            setBulkEditQuestionsDeadlineHMV,
            setBulkEditQuestionsDeadlineIV,
            setBulkEditQuestionsDeadline,
        ],
        appdates_AnswersDeadline: [
            setBulkEditAnswersDeadlineHMV,
            setBulkEditAnswersDeadlineIV,
            setBulkEditAnswersDeadline,
        ],
        appdates_AnswersPosted: [setBulkEditAnswersPostedHMV, setBulkEditAnswersPostedIV, setBulkEditAnswersPosted],
        appdates_BidEvalCompVendSel: [setBulkEditBidEvalHMV, setBulkEditBidEvalIV, setBulkEditBidEval],
        appdates_EmailSentToVendors: [
            setBulkEditEmailSentToVendorsHMV,
            setBulkEditEmailSentToVendorsIV,
            setBulkEditEmailSentToVendors,
        ],

        qa_submitter_user_id: [setBulkEditQASubmitterHMV, setBulkEditQASubmitterIV, setBulkEditQASubmitter],
        qa_reviewer_user_id: [setBulkEditQAReviewerHMV, setBulkEditQAReviewerIV, setBulkEditQAReviewer],
        qa_review_status_id: [setBulkEditQAReviewStatusHMV, setBulkEditQAReviewStatusIV, setBulkEditQAReviewStatus],
        qa_date_submitted: [setBulkEditQADateSubmittedHMV, setBulkEditQADateSubmittedIV, setBulkEditQADateSubmitted],
        qa_date_completed: [setBulkEditQADateCompletedHMV, setBulkEditQADateCompletedIV, setBulkEditQADateCompleted],
        qa_date_needed: [setBulkEditQADateNeededHMV, setBulkEditQADateNeededIV, setBulkEditQADateNeeded],
        qa_date_assigned: [setBulkEditQADateAssignedHMV, setBulkEditQADateAssignedIV, setBulkEditQADateAssigned],

        qa_bid_eval_submitter_user_id: [
            setBulkEditBidEvalQASubmitterHMV,
            setBulkEditBidEvalQASubmitterIV,
            setBulkEditBidEvalQASubmitter,
        ],
        qa_bid_eval_status_id: [
            setBulkEditBidEvalQAStatusHMV,
            setBulkEditBidEvalQAStatusIV,
            setBulkEditBidEvalQAStatus,
        ],
        qa_bid_eval_reviewer_user_id: [
            setBulkEditBidEvalQAReviewerHMV,
            setBulkEditBidEvalQAReviewerIV,
            setBulkEditBidEvalQAReviewer,
        ],
        qa_bid_eval_date_submitted: [
            setBulkEditBidEvalQADateSubmittedHMV,
            setBulkEditBidEvalQADateSubmittedIV,
            setBulkEditBidEvalQADateSubmitted,
        ],
        qa_bid_eval_date_needed: [
            setBulkEditBidEvalQADateNeededHMV,
            setBulkEditBidEvalQADateNeededIV,
            setBulkEditBidEvalQADateNeeded,
        ],
        qa_bid_eval_date_completed: [
            setBulkEditBidEvalQADateCompletedHMV,
            setBulkEditBidEvalQADateCompletedIV,
            setBulkEditBidEvalQADateCompleted,
        ],
    };

    // Cycles through the *bulk selected* records, and for each attribute, checks if there's any difference in value for that attr within the dataset, and sets the respective state to true if so. Ran when the BE button is clicked
    const checkIfAttrHasMultipleValues = (selected_records, attributeStateMapping) => {
        // console.log('[checkIfAttrHasMultipleValues][[selected_records] =', selected_records);
        // console.log('[checkIfAttrHasMultipleValues][attributeStateMapping] =', attributeStateMapping);

        for (const [key, valueSetters] of Object.entries(attributeStateMapping)) {
            let allHaveThisValue = '';
            const areAllValuesTheSame = selected_records.every((ea_obj) => {
                if (key.startsWith('appdates_')) {
                    // All provided appdates date values of the given type (handles nulls w. empty array). Note the join is for the nested arrays
                    const arrayOfAllAppdatesDateValuesOfKey = selected_records.map((ea_obj_arr1) => {
                        // return ea_obj_arr1[key]?.map((ea_appdate_obj) => ea_appdate_obj.date) ?? [];
                        return (ea_obj_arr1[key]?.map((ea_appdate_obj) => ea_appdate_obj.date) ?? []).join(',');
                    });
                    // console.log('[checkIfAttrHasMultipleValues][arrayOfAllAppdatesDateValuesOfKey]', arrayOfAllAppdatesDateValuesOfKey);
                    // console.log('[checkIfAttrHasMultipleValues][typeof arrayOfAllAppdatesDateValuesOfKey]', typeof arrayOfAllAppdatesDateValuesOfKey);

                    // The "current" and only record (usually just one appdate in the array) that needs a comparison
                    const firstObjDateValuesString = (
                        selected_records[0][key]?.map((ea_appdate_of_key) => ea_appdate_of_key.date) ?? []
                    ).join(',');
                    // console.log('[checkIfAttrHasMultipleValues][firstObjDateValuesString]', firstObjDateValuesString);
                    // console.log('[checkIfAttrHasMultipleValues][typeof firstObjDateValuesString]', typeof firstObjDateValuesString);

                    // Check if each of the provided dates are exactly the same as the first, if there's a difference, multiple values are present for the appdate
                    allHaveThisValue = firstObjDateValuesString;
                    return arrayOfAllAppdatesDateValuesOfKey.every(
                        (ea_existing_date) => ea_existing_date === firstObjDateValuesString
                    );
                }

                if (key === 'resolved_application_owner') {
                    allHaveThisValue = selected_records[0][key].id
                        ? selected_records[0][key].id
                        : selected_records[0][key].user_id;
                    return ea_obj[key].id
                        ? ea_obj[key].id === allHaveThisValue
                        : ea_obj[key].user_id === allHaveThisValue;
                }

                if (key === 'task_owners') {
                    // Array of all provided task owner IDs (or user_ids if id isn't truthy/after a bulk edit)
                    const arrOfAllTaskOwnerIDValues = selected_records.map((ea_obj) =>
                        ea_obj[key]
                            .map((ea_task_owner_obj) => ea_task_owner_obj.id || ea_task_owner_obj.user_id)
                            .join(',')
                    );

                    // The "current" and only record (usually just one task owner ID or user_id in the array) that needs a comparison
                    const firstObjIDValuesString = selected_records[0][key]
                        .map((ea_task_owner_obj) => ea_task_owner_obj.id || ea_task_owner_obj.user_id)
                        .join(',');

                    // Check if each of the provided IDs or user_ids are exactly the same as the first; if there's a difference, multiple values are present for TO
                    allHaveThisValue = firstObjIDValuesString;
                    return arrOfAllTaskOwnerIDValues.every((ea_owner_id) => ea_owner_id === firstObjIDValuesString);
                }

                allHaveThisValue = selected_records[0][key];
                return ea_obj[key] === selected_records[0][key];
            });

            // If there are different values (AAVTS = false because every is all or nothing), then set MV to true.
            if (!areAllValuesTheSame) {
                valueSetters[0](true);
            } else {
                // if (key === 'resolved_application_owner') {
                //     valueSetters[1](allHaveThisValue);
                //     valueSetters[2](allHaveThisValue);
                // }
                valueSetters[1](allHaveThisValue);
                valueSetters[2](allHaveThisValue);
            }
        }
    };

    // Comparing the old record values against the new edited values
    const findChangesBetweenRows = (oldRecords, newRecords) => {
        let changes = newRecords.map((newRecord, index) => {
            let oldRecord = oldRecords.find(
                (oldRecord) =>
                    `${oldRecord.form470AppNum}-${oldRecord.form470AppIncrement}` ===
                    `${newRecord.form470AppNum}-${newRecord.form470AppIncrement}`
            );

            const oldNewRecordAttrTranslator = {
                application_owner_id: 'application_owner_user_id',
                task_owner_id: 'task_owners',
            };

            let recordChanges = {};
            Object.keys(attributeHeaderMapping).forEach((attr_key) => {
                // Check if there's any special old to new attribute mapping required, then just get the newRecord key value
                const translatedKey = oldNewRecordAttrTranslator[attr_key] || attr_key;
                let oldValue = oldRecord ? oldRecord[translatedKey] : undefined;
                let newValue = newRecord[attr_key];

                // Handling for task owner object (oldRecord value)
                if (attr_key === 'task_owner_id' && translatedKey === 'task_owners') {
                    oldValue = getTaskOwnerNames(oldValue);
                    if (newValue !== undefined) {
                        newValue = getOtherDisplayName(newValue, taskOwnerEditOptions);
                    } else {
                        newValue = getTaskOwnerNames(newRecord[translatedKey]);
                    }
                }

                // Handling for appdates
                if (attr_key.startsWith('appdates')) {
                    const isNullOrEmptyOldValue =
                        oldValue === null || (Array.isArray(oldValue) && oldValue.length === 0);
                    const isNullOrEmptyNewValue =
                        newValue === null || (Array.isArray(newValue) && newValue.length === 0);

                    if (!isNullOrEmptyOldValue || !isNullOrEmptyNewValue) {
                        if (oldValue?.[0]?.date === newValue) {
                            return;
                        }
                        recordChanges[attr_key] = { oldValue: oldValue, newValue: newValue };
                    }
                } else if (oldValue !== newValue) {
                    recordChanges[attr_key] = { oldValue: oldValue, newValue: newValue };
                }
            });

            return {
                rowID: newRecord.form470AppNum + '-' + newRecord.form470AppIncrement,
                form470AppNum: newRecord.form470AppNum,
                nickname: newRecord.nickname,
                changes: recordChanges,
            };
        });
        // return changes.filter((ea_change) => Object.keys(ea_change.changes).length > 0); // Controls the 4 out of 5 scenario display
        return changes;
    };

    // Used for the visual display of original/unmodified data to the user within the "confirmation page"
    const applyOldRowTransformations = (rowID, value, attr) => {
        const matching_row = oldBulkEditRows.find(
            (ea_row) => `${ea_row.form470AppNum}-${ea_row.form470AppIncrement}` === rowID
        );
        // console.log('[applyOldRowTransformations] old_matching_row =', matching_row);

        if (!matching_row) {
            return null;
        }

        switch (attr) {
            case 'application_owner_id':
                return getUserDisplayName(matching_row.resolved_application_owner);
            case 'task_owner_id':
                return getTaskOwnerNames(matching_row.task_owners);
            case 'qa_submitter_user_id':
                return getOtherDisplayName(value, qaSubmitterEditOptions);
            case 'qa_reviewer_user_id':
                return getOtherDisplayName(value, qaReviewerEditOptions);
            case 'qa_review_status_id':
                return getOtherDisplayName(value, qaReviewStatusEditOptions);
            case 'qa_bid_eval_submitter_user_id':
                return getOtherDisplayName(value, qaBidEvalSubmitterEditOptions);
            case 'qa_bid_eval_status_id':
                return matching_row.qa_bid_eval_status_text;
            case 'qa_bid_eval_reviewer_user_id':
                return getOtherDisplayName(value, qaBidEvalReviewerEditOptions);
            case 'erc_form470_app_status_id':
                return getOtherDisplayName(value, ercAppStatusEditOptions);
            case 'appdates_WTPreBidMtg':
                return matching_row.appdates_WTPreBidMtg ? matching_row.appdates_WTPreBidMtg[0]?.date : '';
            case 'appdates_BidClosing':
                return matching_row.appdates_BidClosing ? matching_row.appdates_BidClosing[0]?.date : '';
            case 'appdates_Award':
                return matching_row.appdates_Award ? matching_row.appdates_Award[0]?.date : '';
            case 'appdates_QuestionsDeadline':
                return matching_row.appdates_QuestionsDeadline ? matching_row.appdates_QuestionsDeadline[0]?.date : '';
            case 'appdates_AnswersDeadline':
                return matching_row.appdates_AnswersDeadline ? matching_row.appdates_AnswersDeadline[0]?.date : '';
            case 'appdates_AnswersPosted':
                return matching_row.appdates_AnswersPosted ? matching_row.appdates_AnswersPosted[0]?.date : '';
            case 'appdates_BidEvalCompVendSel':
                return matching_row.appdates_BidEvalCompVendSel
                    ? matching_row.appdates_BidEvalCompVendSel[0]?.date
                    : '';
            case 'appdates_EmailSentToVendors':
                return matching_row.appdates_EmailSentToVendors
                    ? matching_row.appdates_EmailSentToVendors[0]?.date
                    : '';
            default:
                return value;
        }
    };

    // Used for the visual display of new/modified data to the user within the "confirmation page"
    const applyNewRowTransformations = (rowID, value, attr) => {
        const matching_row = newBulkEditRows.find(
            (ea_row) => `${ea_row.form470AppNum}-${ea_row.form470AppIncrement}` === rowID
        );
        // console.log('[applyOldRowTransformations] new_matching_row =', matching_row);

        if (!matching_row) {
            return null;
        }

        const characterLimit = 250;
        switch (attr) {
            case 'application_owner_id':
                return getOtherDisplayName(matching_row.application_owner_id, applicationOwnerEditOptions);
            case 'task_owner_id':
                return getOtherDisplayName(matching_row.task_owner_id, taskOwnerEditOptions);
            case 'qa_submitter_user_id':
                return getOtherDisplayName(value, qaSubmitterEditOptions);
            case 'qa_reviewer_user_id':
                return getOtherDisplayName(value, qaReviewerEditOptions);
            case 'qa_review_status_id':
                return getOtherDisplayName(value, qaReviewStatusEditOptions);
            case 'qa_bid_eval_submitter_user_id':
                return getOtherDisplayName(value, qaBidEvalSubmitterEditOptions);
            case 'qa_bid_eval_status_id':
                return getOtherDisplayName(value, qaBidEvalStatusEditOptions);
            case 'qa_bid_eval_reviewer_user_id':
                return getOtherDisplayName(value, qaBidEvalReviewerEditOptions);
            case 'erc_form470_app_status_id':
                return getOtherDisplayName(value, ercAppStatusEditOptions);
            default:
                if (value.length > characterLimit) {
                    return `${value.substring(0, characterLimit)}...`;
                }
                return value;
        }
    };

    // Adds newly-selected existing data to a state, ran when the Bulk Edit button is clicked
    const setOldBulkEditSelectedRows = () => {
        let oldBulkEditRowsTemp = [];

        if (selectionModel.length > 0) {
            selectionModel.forEach((rowID) => {
                const row = trackerData.find((row) => `${row.form470AppNum}-${row.form470AppIncrement}` === rowID);
                if (row) oldBulkEditRowsTemp.push(row);
            });
            // console.log('[setOldBulkEditSelectedRows] oldBulkEditRowsTemp =', oldBulkEditRowsTemp);
            setOldBulkEditRows(oldBulkEditRowsTemp);
            return oldBulkEditRowsTemp;
        }
    };

    // Prevents the user from clicking outside of the dialog to exit, and throws a window if they cancel with values selected
    const handleBulkEditClose = (event, reason) => {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.

        // Handles clicking outside of the dialog
        if (reason === 'backdropClick') {
            return;
        }

        // If they searched and selected values then confirm they want to exit.
        if (
            (!isEmptyChecker(bulkEditApplicationOwner) && bulkEditApplicationOwner !== bulkEditApplicationOwnerIV) ||
            (!isEmptyChecker(bulkEditTaskOwners) && bulkEditTaskOwners !== bulkEditTaskOwnersIV) ||
            (!isEmptyChecker(bulkEditQASubmitter) && bulkEditQASubmitter !== bulkEditQASubmitterIV) ||
            (!isEmptyChecker(bulkEditQAReviewer) && bulkEditQAReviewer !== bulkEditQAReviewerIV) ||
            (!isEmptyChecker(bulkEditQAReviewStatus) && bulkEditQAReviewStatus !== bulkEditQAReviewStatusIV) ||
            (!isEmptyChecker(bulkEditBidEvalQASubmitter) &&
                bulkEditBidEvalQASubmitter !== bulkEditBidEvalQASubmitterIV) ||
            (!isEmptyChecker(bulkEditBidEvalQAStatus) && bulkEditBidEvalQAStatus !== bulkEditBidEvalQAStatusIV) ||
            (!isEmptyChecker(bulkEditBidEvalQAReviewer) && bulkEditBidEvalQAReviewer !== bulkEditBidEvalQAReviewerIV) ||
            (!isEmptyChecker(bulkEditERCAppStatus) && bulkEditERCAppStatus !== bulkEditERCAppStatusIV) ||
            (!isEmptyChecker(bulkEditQADarkFiber) && bulkEditQADarkFiber !== bulkEditQADarkFiberIV) ||
            (!isEmptyChecker(bulkEditQADateSubmitted) && bulkEditQADateSubmitted !== bulkEditQADateSubmittedIV) ||
            (!isEmptyChecker(bulkEditQADateNeeded) && bulkEditQADateNeeded !== bulkEditQADateNeededIV) ||
            (!isEmptyChecker(bulkEditQADateAssigned) && bulkEditQADateAssigned !== bulkEditQADateAssignedIV) ||
            (!isEmptyChecker(bulkEditQADateCompleted) && bulkEditQADateCompleted !== bulkEditQADateCompletedIV) ||
            (!isEmptyChecker(bulkEditBidEvalQADateSubmitted) &&
                bulkEditBidEvalQADateSubmitted !== bulkEditBidEvalQADateSubmittedIV) ||
            (!isEmptyChecker(bulkEditBidEvalQADateNeeded) &&
                bulkEditBidEvalQADateNeeded !== bulkEditBidEvalQADateNeededIV) ||
            (!isEmptyChecker(bulkEditBidEvalQADateCompleted) &&
                bulkEditBidEvalQADateCompleted !== bulkEditBidEvalQADateCompletedIV) ||
            (!isEmptyChecker(bulkEditDateSentToClientToCertify) &&
                bulkEditDateSentToClientToCertify !== bulkEditDateSentToClientToCertifyIV) ||
            (!isEmptyChecker(bulkEditPreBidDate) && bulkEditPreBidDate !== bulkEditPreBidDateIV) ||
            (!isEmptyChecker(bulkEditBidClosing) && bulkEditBidClosing !== bulkEditBidClosingIV) ||
            (!isEmptyChecker(bulkEditAwardDate) && bulkEditAwardDate !== bulkEditAwardDateIV) ||
            (!isEmptyChecker(bulkEditQuestionsDeadline) && bulkEditQuestionsDeadline !== bulkEditQuestionsDeadlineIV) ||
            (!isEmptyChecker(bulkEditAnswersDeadline) && bulkEditAnswersDeadline !== bulkEditAnswersDeadlineIV) ||
            (!isEmptyChecker(bulkEditAnswersPosted) && bulkEditAnswersPosted !== bulkEditAnswersPostedIV) ||
            (!isEmptyChecker(bulkEditBidEval) && bulkEditBidEval !== bulkEditBidEvalIV) ||
            (!isEmptyChecker(bulkEditEmailSentToVendors) &&
                bulkEditEmailSentToVendors !== bulkEditEmailSentToVendorsIV) ||
            (!isEmptyChecker(bulkEditIssueDate) && bulkEditIssueDate !== bulkEditIssueDateIV) ||
            bulkEditApplicationNotes ||
            bulkEditQAReviewerNotes
        ) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        resetThreeBulkStateSets();
    };

    const handleBulkEditCloseConfirmationPage = (event, reason) => {
        return;
    };

    // Opens the initial bulk editing dialog after the user clicks the Bulk Edit button. Checks if the old data has multiple values
    const handleBulkEditButtonClicked = () => {
        const oldBulkEditRowsTemp = setOldBulkEditSelectedRows();
        // console.log('[handleBulkEditButtonClicked][oldBulkEditRowsTemp] =', oldBulkEditRowsTemp);
        setOpenBulkEditDialog(true);
        checkIfAttrHasMultipleValues(oldBulkEditRowsTemp, attributeBEStateMapping);
    };

    // Prepends the newly-typed note to the corresponding already-existing note (ea record at a time)
    const handleAddNotesBulk = (oldValue, newValue) => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        const newNote = `${currentDate} (${username.email.split('@')[0]}) - ${newValue}\n\n`;
        if (!oldValue) return newNote;
        return `${newNote}${oldValue}`;
        // return newNote;
    };

    // Sets the newly-edited BE records to state after applying transformations after the user clicks the Next button
    const handleBulkEditNextButtonClicked = () => {
        const ERCForm470AppStatusId_ReadyForQA = 50;
        const ERCForm470AppStatusId_OutOfQA = 60;
        const QA470BidEvalStatusId_QABidEvalCompleteNoIssues = 4;
        const QA470BidEvalStatusId_QABidEvalCompleteWithIssues = 5;
        const QA470BidEvalStatusId_QABidEvalWillNotBeReviewed = 6;

        const bulkSelectedRows = selectionModel.map((rowID) => {
            const specificRow = trackerData.find((row) => `${row.form470AppNum}-${row.form470AppIncrement}` === rowID);

            let uedata = {
                ...specificRow,

                application_owner_id: !isEmptyChecker(bulkEditApplicationOwner)
                    ? setApplicationOwnerUserID(bulkEditApplicationOwner)
                    : specificRow.application_owner_user_id,
                task_owner_id: !isEmptyChecker(bulkEditTaskOwners)
                    ? correctTheDropdownValue(bulkEditTaskOwners, taskOwnerEditOptions)
                    : specificRow.task_owners[0]?.['id'],
                qa_submitter_user_id: !isEmptyChecker(bulkEditQASubmitter)
                    ? correctTheDropdownValue(bulkEditQASubmitter, qaSubmitterEditOptions)
                    : specificRow.qa_submitter_user_id,
                qa_reviewer_user_id: !isEmptyChecker(bulkEditQAReviewer)
                    ? correctTheDropdownValue(bulkEditQAReviewer, qaReviewerEditOptions)
                    : specificRow.qa_reviewer_user_id,
                qa_review_status_id: !isEmptyChecker(bulkEditQAReviewStatus)
                    ? correctTheDropdownValue(bulkEditQAReviewStatus, qaReviewStatusEditOptions)
                    : specificRow.qa_review_status_id,
                qa_bid_eval_submitter_user_id: !isEmptyChecker(bulkEditBidEvalQASubmitter)
                    ? correctTheDropdownValue(bulkEditBidEvalQASubmitter, qaBidEvalSubmitterEditOptions)
                    : specificRow.qa_bid_eval_submitter_user_id,
                qa_bid_eval_status_id: !isEmptyChecker(bulkEditBidEvalQAStatus)
                    ? correctTheDropdownValue(bulkEditBidEvalQAStatus, qaBidEvalStatusEditOptions)
                    : specificRow.qa_bid_eval_status_id,
                qa_bid_eval_reviewer_user_id: !isEmptyChecker(bulkEditBidEvalQAReviewer)
                    ? correctTheDropdownValue(bulkEditBidEvalQAReviewer, qaBidEvalReviewerEditOptions)
                    : specificRow.qa_bid_eval_reviewer_user_id,

                erc_form470_app_status_id: !isEmptyChecker(bulkEditERCAppStatus)
                    ? bulkEditERCAppStatus
                    : specificRow.erc_form470_app_status_id,

                qa_darkfiberselfprovisioned_name: !isEmptyChecker(bulkEditQADarkFiber)
                    ? bulkEditQADarkFiber
                    : specificRow.qa_darkfiberselfprovisioned_name,

                qa_date_submitted: bulkEditQADateSubmitted ? bulkEditQADateSubmitted : specificRow.qa_date_submitted,
                qa_date_needed: bulkEditQADateNeeded ? bulkEditQADateNeeded : specificRow.qa_date_needed,
                qa_date_assigned: bulkEditQADateAssigned ? bulkEditQADateAssigned : specificRow.qa_date_assigned,
                qa_date_completed: bulkEditQADateCompleted ? bulkEditQADateCompleted : specificRow.qa_date_completed,

                qa_bid_eval_date_submitted: bulkEditBidEvalQADateSubmitted
                    ? bulkEditBidEvalQADateSubmitted
                    : specificRow.qa_bid_eval_date_submitted,
                qa_bid_eval_date_needed: bulkEditBidEvalQADateNeeded
                    ? bulkEditBidEvalQADateNeeded
                    : specificRow.qa_bid_eval_date_needed,

                qa_bid_eval_date_completed: bulkEditBidEvalQADateCompleted
                    ? bulkEditBidEvalQADateCompleted
                    : specificRow.qa_bid_eval_date_completed,
                date_sent_to_client_to_certify: bulkEditDateSentToClientToCertify
                    ? bulkEditDateSentToClientToCertify
                    : specificRow.date_sent_to_client_to_certify,

                appdates_WTPreBidMtg: bulkEditPreBidDate
                    ? bulkEditPreBidDate
                    : specificRow.appdates_WTPreBidMtg?.[0]?.date ?? null,
                appdates_BidClosing: bulkEditBidClosing
                    ? bulkEditBidClosing
                    : specificRow.appdates_BidClosing?.[0]?.date ?? null,
                appdates_Award: bulkEditAwardDate ? bulkEditAwardDate : specificRow.appdates_Award?.[0]?.date ?? null,

                appdates_QuestionsDeadline: bulkEditQuestionsDeadline
                    ? bulkEditQuestionsDeadline
                    : specificRow.appdates_QuestionsDeadline?.[0]?.date ?? null,
                appdates_AnswersDeadline: bulkEditAnswersDeadline
                    ? bulkEditAnswersDeadline
                    : specificRow.appdates_AnswersDeadline?.[0]?.date ?? null,
                appdates_AnswersPosted: bulkEditAnswersPosted
                    ? bulkEditAnswersPosted
                    : specificRow.appdates_AnswersPosted?.[0]?.date ?? null,
                appdates_BidEvalCompVendSel: bulkEditBidEval
                    ? bulkEditBidEval
                    : specificRow.appdates_BidEvalCompVendSel?.[0]?.date ?? null,
                appdates_EmailSentToVendors: bulkEditEmailSentToVendors
                    ? bulkEditEmailSentToVendors
                    : specificRow.appdates_EmailSentToVendors?.[0]?.date ?? null,

                issue_date: bulkEditIssueDate ? bulkEditIssueDate : specificRow.issue_date,

                form470_app_notes: bulkEditApplicationNotes
                    ? handleAddNotesBulk(specificRow.form470_app_notes, bulkEditApplicationNotes)
                    : specificRow.form470_app_notes,
                qa_notes: bulkEditQAReviewerNotes
                    ? handleAddNotesBulk(specificRow.qa_notes, bulkEditQAReviewerNotes)
                    : specificRow.qa_notes,
            };

            if (uedata['qa_darkfiberselfprovisioned_name'] === '<blank>') {
                uedata['qa_darkfiberselfprovisioned_name'] = '';
            }

            // If the Bid Eval QA Status was EXPLICITLY selected during BE
            if (bulkEditBidEvalQAStatus === 1 && bulkEditBidEvalQAStatus !== specificRow.qa_bid_eval_status_id) {
                uedata = checkIfEmptyAndFillQABidEvalFields(uedata);
            }

            // If the ERC App Status was EXPLICITLY selected during BE
            if (bulkEditERCAppStatus === 50 && bulkEditERCAppStatus !== specificRow.erc_form470_app_status_id) {
                uedata = checkIfEmptyAndFillQAFields(uedata);
            }

            // If the QA Date Submitted was EXPLICITLY selected selected during BE
            if (
                bulkEditQADateSubmitted &&
                bulkEditQADateSubmitted !== specificRow.qa_date_submitted &&
                !bulkEditQADateNeeded &&
                !specificRow.qa_date_needed
            ) {
                const dateNeededResults = addDaysToDateNeeded(bulkEditQADateSubmitted);
                uedata.qa_date_needed = dateNeededResults;
            }

            // If the Bid Eval QA Date Submitted was EXPLICITLY selected selected during BE
            if (
                bulkEditBidEvalQADateSubmitted &&
                bulkEditBidEvalQADateSubmitted !== specificRow.qa_bid_eval_date_submitted &&
                !bulkEditBidEvalQADateNeeded &&
                !specificRow.qa_bid_eval_date_needed
            ) {
                const bidEvalDateNeededResults = addDaysToDateNeeded(bulkEditBidEvalQADateSubmitted);
                uedata.qa_bid_eval_date_needed = bidEvalDateNeededResults;
            }

            // Business rule brought from backend. If the ERC App Status was EXPLICITLY selected during BE
            if (bulkEditERCAppStatus && bulkEditERCAppStatus !== specificRow.erc_form470_app_status_id) {
                if (bulkEditERCAppStatus === ERCForm470AppStatusId_ReadyForQA) {
                    if (uedata['qa_review_status_id'] === 0) {
                        uedata['qa_review_status_id'] = 2; // QA Ready
                    }
                }
            }

            // Business rule brought from backend. If the QA Review Status was EXPLICITLY selected during BE
            if (bulkEditQAReviewStatus && bulkEditQAReviewStatus !== specificRow.qa_review_status_id) {
                const qaStatusIDs = [
                    QA470BidEvalStatusId_QABidEvalCompleteNoIssues,
                    QA470BidEvalStatusId_QABidEvalCompleteWithIssues,
                    QA470BidEvalStatusId_QABidEvalWillNotBeReviewed,
                ];

                if (qaStatusIDs.includes(bulkEditQAReviewStatus)) {
                    if (uedata['erc_form470_app_status_id'] === ERCForm470AppStatusId_ReadyForQA) {
                        uedata['erc_form470_app_status_id'] = ERCForm470AppStatusId_OutOfQA;
                    }
                }
            }

            return uedata;
        });

        console.log('[handleBulkEditSaveAPICall] bulkSelectRows =', bulkSelectedRows);
        setNewBulkEditRows(bulkSelectedRows);
        setOpenBulkEditDialog(false);
        setNextButtonClicked(false);
    };

    // TODO: Apply loading spinner logic after this click. Makes the save call after the user clicks on the Confirm button
    const handleBulkEditConfirmButtonClicked = () => {
        setConfirmButtonIsDisabled(true);
        setCancelButtonIsDisabled(true);
        setBackButtonIsDisabled(true);
        setBulkEditProgressIsOpen(true);

        // Make the API call to save the data, then clear relevant state
        handleBulkEditSaveAPICall();
    };

    // Reopens the landing Bulk Editing component
    const handleBulkEditBackButtonClicked = () => {
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(true);
        setNextButtonClicked(false);
    };

    // Resets all states dealing with BE
    const resetThreeBulkStateSets = () => {
        handleBulkEditResetState();
        handleBulkEditHasMultipleValuesResetState();
        handleBulkEditInitialValuesResetState();
    };

    // Allows the user to keep BE changes if the API call fails
    const resetStateForFailedAPIResponse = () => {
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(false);
        setBulkEditProgressIsOpen(false);
        setConfirmButtonIsDisabled(false);
        setCancelButtonIsDisabled(false);
        setBackButtonIsDisabled(false);
        setNextButtonClicked(false);
    };

    // Clear out all of the bulk editing state management (after cancel or successful saving)
    const handleBulkEditResetState = () => {
        setBulkEditAnswersDeadline('');
        setBulkEditAnswersPosted('');
        setBulkEditApplicationOwner('');
        setBulkEditAwardDate('');
        setBulkEditBidClosing('');
        setBulkEditBidEval('');
        setBulkEditBidEvalQADateCompleted('');
        setBulkEditBidEvalQADateNeeded('');
        setBulkEditBidEvalQADateSubmitted('');
        setBulkEditBidEvalQAReviewer('');
        setBulkEditBidEvalQAStatus('');
        setBulkEditBidEvalQASubmitter('');
        setBulkEditDateSentToClientToCertify('');
        setBulkEditEmailSentToVendors('');
        setBulkEditERCAppStatus('');
        setBulkEditIssueDate('');
        setBulkEditPreBidDate('');
        setBulkEditQADarkFiber('');
        setBulkEditQADateAssigned('');
        setBulkEditQADateCompleted('');
        setBulkEditQADateNeeded('');
        setBulkEditQADateSubmitted('');
        setBulkEditQAReviewer('');
        setBulkEditQAReviewStatus('');
        setBulkEditQASubmitter('');
        setBulkEditQuestionsDeadline('');
        setBulkEditTaskOwners('');
        setBulkEditApplicationNotes('');
        setBulkEditQAReviewerNotes('');
        setOldBulkEditRows([]);
        setNewBulkEditRows([]);
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(false);
        setBulkEditProgressIsOpen(false);
        setConfirmButtonIsDisabled(false);
        setCancelButtonIsDisabled(false);
        setBackButtonIsDisabled(false);
        setNextButtonClicked(false);
        setChangedAttributes([]);
        setAllChanges([]);
    };

    // Clear out all of the bulk editing multiple values state management (after cancel or successful saving)
    const handleBulkEditHasMultipleValuesResetState = () => {
        setBulkEditApplicationOwnerHMV(false);
        setBulkEditTaskOwnersHMV(false);
        setBulkEditERCAppStatusHMV(false);
        setBulkEditQADarkFiberHMV(false);
        setBulkEditDateSentToClientToCertifyHMV(false);
        setBulkEditIssueDateHMV(false);
        setBulkEditPreBidDateHMV(false);
        setBulkEditQuestionsDeadlineHMV(false);
        setBulkEditAnswersDeadlineHMV(false);
        setBulkEditAnswersPostedHMV(false);
        setBulkEditBidClosingHMV(false);
        setBulkEditAwardDateHMV(false);
        setBulkEditEmailSentToVendorsHMV(false);
        setBulkEditQAReviewStatusHMV(false);
        setBulkEditQADateSubmittedHMV(false);
        setBulkEditQADateCompletedHMV(false);
        setBulkEditQADateNeededHMV(false);
        setBulkEditQADateAssignedHMV(false);
        setBulkEditQAReviewerHMV(false);
        setBulkEditQASubmitterHMV(false);
        setBulkEditBidEvalHMV(false);
        setBulkEditBidEvalQADateSubmittedHMV(false);
        setBulkEditBidEvalQADateCompletedHMV(false);
        setBulkEditBidEvalQADateNeededHMV(false);
        setBulkEditBidEvalQAStatusHMV(false);
        setBulkEditBidEvalQAReviewerHMV(false);
        setBulkEditBidEvalQASubmitterHMV(false);
    };

    // Clear out all of the bulk editing initial values
    const handleBulkEditInitialValuesResetState = () => {
        setBulkEditApplicationOwnerIV('');
        setBulkEditTaskOwnersIV('');
        setBulkEditERCAppStatusIV('');
        setBulkEditQADarkFiberIV('');
        setBulkEditDateSentToClientToCertifyIV('');
        setBulkEditIssueDateIV('');
        setBulkEditPreBidDateIV('');
        setBulkEditQuestionsDeadlineIV('');
        setBulkEditAnswersDeadlineIV('');
        setBulkEditAnswersPostedIV('');
        setBulkEditBidClosingIV('');
        setBulkEditAwardDateIV('');
        setBulkEditEmailSentToVendorsIV('');
        setBulkEditQAReviewStatusIV('');
        setBulkEditQADateSubmittedIV('');
        setBulkEditQADateCompletedIV('');
        setBulkEditQADateNeededIV('');
        setBulkEditQADateAssignedIV('');
        setBulkEditQAReviewerIV('');
        setBulkEditQASubmitterIV('');
        setBulkEditBidEvalIV('');
        setBulkEditBidEvalQADateSubmittedIV('');
        setBulkEditBidEvalQADateCompletedIV('');
        setBulkEditBidEvalQADateNeededIV('');
        setBulkEditBidEvalQAStatusIV('');
        setBulkEditBidEvalQAReviewerIV('');
        setBulkEditBidEvalQASubmitterIV('');
    };

    // Excludes numeric 0 from being falsy for dropdown edit options
    const isEmptyChecker = (value) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value.trim().length === 0) return true;
        return false;
    };

    // Checks if any values have been selected, used to control disabled attributes
    const isNextButtonDisabled = !(
        (!isEmptyChecker(bulkEditApplicationOwner) && bulkEditApplicationOwner !== bulkEditApplicationOwnerIV) ||
        (!isEmptyChecker(bulkEditTaskOwners) && bulkEditTaskOwners !== bulkEditTaskOwnersIV) ||
        (!isEmptyChecker(bulkEditQASubmitter) && bulkEditQASubmitter !== bulkEditQASubmitterIV) ||
        (!isEmptyChecker(bulkEditQAReviewer) && bulkEditQAReviewer !== bulkEditQAReviewerIV) ||
        (!isEmptyChecker(bulkEditQAReviewStatus) && bulkEditQAReviewStatus !== bulkEditQAReviewStatusIV) ||
        (!isEmptyChecker(bulkEditBidEvalQASubmitter) && bulkEditBidEvalQASubmitter !== bulkEditBidEvalQASubmitterIV) ||
        (!isEmptyChecker(bulkEditBidEvalQAStatus) && bulkEditBidEvalQAStatus !== bulkEditBidEvalQAStatusIV) ||
        (!isEmptyChecker(bulkEditBidEvalQAReviewer) && bulkEditBidEvalQAReviewer !== bulkEditBidEvalQAReviewerIV) ||
        (!isEmptyChecker(bulkEditERCAppStatus) && bulkEditERCAppStatus !== bulkEditERCAppStatusIV) ||
        (!isEmptyChecker(bulkEditQADarkFiber) && bulkEditQADarkFiber !== bulkEditQADarkFiberIV) ||
        (!isEmptyChecker(bulkEditQADateSubmitted) && bulkEditQADateSubmitted !== bulkEditQADateSubmittedIV) ||
        (!isEmptyChecker(bulkEditQADateNeeded) && bulkEditQADateNeeded !== bulkEditQADateNeededIV) ||
        (!isEmptyChecker(bulkEditQADateAssigned) && bulkEditQADateAssigned !== bulkEditQADateAssignedIV) ||
        (!isEmptyChecker(bulkEditQADateCompleted) && bulkEditQADateCompleted !== bulkEditQADateCompletedIV) ||
        (!isEmptyChecker(bulkEditBidEvalQADateSubmitted) &&
            bulkEditBidEvalQADateSubmitted !== bulkEditBidEvalQADateSubmittedIV) ||
        (!isEmptyChecker(bulkEditBidEvalQADateNeeded) &&
            bulkEditBidEvalQADateNeeded !== bulkEditBidEvalQADateNeededIV) ||
        (!isEmptyChecker(bulkEditBidEvalQADateCompleted) &&
            bulkEditBidEvalQADateCompleted !== bulkEditBidEvalQADateCompletedIV) ||
        (!isEmptyChecker(bulkEditDateSentToClientToCertify) &&
            bulkEditDateSentToClientToCertify !== bulkEditDateSentToClientToCertifyIV) ||
        (!isEmptyChecker(bulkEditPreBidDate) && bulkEditPreBidDate !== bulkEditPreBidDateIV) ||
        (!isEmptyChecker(bulkEditBidClosing) && bulkEditBidClosing !== bulkEditBidClosingIV) ||
        (!isEmptyChecker(bulkEditAwardDate) && bulkEditAwardDate !== bulkEditAwardDateIV) ||
        (!isEmptyChecker(bulkEditQuestionsDeadline) && bulkEditQuestionsDeadline !== bulkEditQuestionsDeadlineIV) ||
        (!isEmptyChecker(bulkEditAnswersDeadline) && bulkEditAnswersDeadline !== bulkEditAnswersDeadlineIV) ||
        (!isEmptyChecker(bulkEditAnswersPosted) && bulkEditAnswersPosted !== bulkEditAnswersPostedIV) ||
        (!isEmptyChecker(bulkEditBidEval) && bulkEditBidEval !== bulkEditBidEvalIV) ||
        (!isEmptyChecker(bulkEditEmailSentToVendors) && bulkEditEmailSentToVendors !== bulkEditEmailSentToVendorsIV) ||
        (!isEmptyChecker(bulkEditIssueDate) && bulkEditIssueDate !== bulkEditIssueDateIV) ||
        bulkEditApplicationNotes ||
        bulkEditQAReviewerNotes
    );

    // Saves the Bulk Editing data and controls related Toast pop-ups
    const handleBulkEditSaveAPICall = async () => {
        try {
            // Creating the new data structure for bulk editing
            const recordIDs = selectionModel.map((ea_item) => {
                let [appNum, appInc] = ea_item.split('-').map(Number);
                return { form470AppNum: appNum, form470AppIncrement: appInc };
            });

            const findNewData = () => {
                const newData = newBulkEditRows.map((record) => {
                    return changedAttributes.reduce((newRecord, field) => {
                        newRecord['form470AppNum'] = record['form470AppNum'];
                        newRecord['form470AppIncrement'] = record['form470AppIncrement'];

                        if (record.hasOwnProperty(field)) {
                            newRecord[field] = record[field];
                        }
                        return newRecord;
                    }, {});
                });
                return newData;
            };

            // Initially sent the number of rows and the request timestamp
            // const createRequestTimestamp = () => {
            //     const currentDatetime = new Date();
            //     const year = currentDatetime.getFullYear();
            //     const month = String(currentDatetime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            //     const day = String(currentDatetime.getDate()).padStart(2, '0');
            //     const hour = String(currentDatetime.getHours()).padStart(2, '0');
            //     const minute = String(currentDatetime.getMinutes()).padStart(2, '0');

            //     return `${year}-${month}-${day}T${hour}:${minute}`;
            // };

            const formattedData = {
                recordIDs: recordIDs,
                fieldsToChange: changedAttributes,
                newData: findNewData(),
            };
            console.log('[handleBulkEditSaveAPICall] formattedData =', formattedData);
            const apiResponse = await trackersAPI.SaveForm470TrackerAppDataBulkV2(formattedData);

            // const apiResponse = await trackersAPI.SaveForm470TrackerAppDataBulk(newBulkEditRows);
            console.log('[handleBulkEditSaveAPICall][apiResponse] =', apiResponse);
            if (apiResponse && apiResponse?.length) {
                const updatedRecordsMap = new Map(
                    apiResponse.map((record) => [`${record.form470AppNum}-${record.form470AppIncrement}`, record])
                );

                const updatedTrackerData = trackerData.map((record) => {
                    const uniqueID = `${record.form470AppNum}-${record.form470AppIncrement}`;
                    return updatedRecordsMap.has(uniqueID) ? updatedRecordsMap.get(uniqueID) : record;
                });

                setTrackerData(updatedTrackerData);
                resetThreeBulkStateSets();
                toast.success(`Successfully bulk edited (${selectionModel.length}) applications`, {
                    autoClose: 3000,
                });
            } else {
                resetStateForFailedAPIResponse();
                toast.error(`Error saving bulk edits for (${selectionModel.length}) applications`);
            }
        } catch (error) {
            resetStateForFailedAPIResponse();
            toast.error('An error occurred while saving bulk edits');
            console.error('Error in handleBulkEditSaveAPICall:', error);
        }
    };

    // ################################### Return Component #############################################
    return (
        <>
            <Dialog open={openBulkEditDialog} onClose={handleBulkEditClose} PaperProps={{ style: { minWidth: '80%' } }}>
                <DialogTitle sx={{ fontSize: '1.0rem' }}>
                    {`Bulk editing for (${selectionModel.length}) applications`}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {/* FIRST COLUMN */}
                        <Grid item xs={4}>
                            {/* ERC App Status Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-erc-app-status-label'>
                                    {bulkEditERCAppStatusHMV ? 'ERC App Status (Multiple Values)' : 'ERC App Status'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditERCAppStatusIV}
                                    value={bulkEditERCAppStatus}
                                    setValue={setBulkEditERCAppStatus}
                                    dropDownOptions={ercAppStatusEditOptions}
                                    label='erc-app-status-label'
                                    multipleValues={bulkEditERCAppStatusHMV}
                                />
                            </FormControl>

                            {/* Application Owner Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-application-owner-label'>
                                    {bulkEditApplicationOwnerHMV
                                        ? 'Application Owner (Multiple Values)'
                                        : 'Application Owner'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditApplicationOwnerIV}
                                    value={bulkEditApplicationOwner}
                                    setValue={setBulkEditApplicationOwner}
                                    dropDownOptions={applicationOwnerEditOptions}
                                    label='application-owner-label'
                                    multipleValues={bulkEditApplicationOwnerHMV}
                                />
                            </FormControl>

                            {/* Task Owners Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-task-owners-label'>
                                    {bulkEditTaskOwnersHMV ? 'Task Owners (Multiple Values)' : 'Task Owners'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditTaskOwnersIV}
                                    value={bulkEditTaskOwners}
                                    setValue={setBulkEditTaskOwners}
                                    dropDownOptions={taskOwnerEditOptions}
                                    label='task-owners-label'
                                    multipleValues={bulkEditTaskOwnersHMV}
                                />
                            </FormControl>

                            {/* Date Sent to Client to Certify Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditDateSentToClientToCertifyHMV
                                        ? 'Date Sent to Client to Certify (Multiple Values)'
                                        : 'Date Sent to Client to Certify'
                                }
                                providedDate={bulkEditDateSentToClientToCertify}
                                initialDate={bulkEditDateSentToClientToCertifyIV}
                                onDateChange={(newDate) => setBulkEditDateSentToClientToCertify(newDate)}
                                multipleValues={bulkEditDateSentToClientToCertifyHMV}
                            />

                            {/* Issue Date Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditIssueDateHMV ? 'Issue Date (Multiple Values)' : 'Issue Date'}
                                providedDate={bulkEditIssueDate}
                                initialDate={bulkEditIssueDateIV}
                                onDateChange={(newDate) => setBulkEditIssueDate(newDate)}
                                multipleValues={bulkEditIssueDateHMV}
                            />

                            {/* Dark Fiber or Self Provisioned Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-dark-fiber-or-self-prov-label'>
                                    {bulkEditQADarkFiberHMV
                                        ? 'Dark Fiber or Self Prov (Multiple Values)'
                                        : 'Dark Fiber or Self Prov'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQADarkFiberIV}
                                    value={bulkEditQADarkFiber}
                                    setValue={setBulkEditQADarkFiber}
                                    dropDownOptions={qaDarkFiberEditOptions}
                                    label='dark-fiber-or-self-prov-label'
                                    multipleValues={bulkEditQADarkFiberHMV}
                                />
                            </FormControl>

                            {/* Application Notes */}
                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='Application Notes'
                                    value={bulkEditApplicationNotes}
                                    id='form470_app_notes_textfield'
                                    onChange={(event) => setBulkEditApplicationNotes(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={10}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bulkEditApplicationNotes ? 'orange' : '',
                                                borderWidth: bulkEditApplicationNotes ? '2px' : '1px',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        {/* SECOND COLUMN */}
                        <Grid item xs={4}>
                            {/* 470 QA Status Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-470-qa-status-label'>
                                    {bulkEditQAReviewStatusHMV ? '470 QA Status (Multiple Values)' : '470 QA Status'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQAReviewStatusIV}
                                    value={bulkEditQAReviewStatus}
                                    setValue={setBulkEditQAReviewStatus}
                                    dropDownOptions={qaReviewStatusEditOptions}
                                    label='470-qa-status-label'
                                    multipleValues={bulkEditQAReviewStatusHMV}
                                />
                            </FormControl>

                            {/* QA Submitter Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-qa-submitter-label'>
                                    {bulkEditQASubmitterHMV ? 'QA Submitter (Multiple Values)' : 'QA Submitter'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQASubmitterIV}
                                    value={bulkEditQASubmitter}
                                    setValue={setBulkEditQASubmitter}
                                    dropDownOptions={qaSubmitterEditOptions}
                                    label='qa-submitter-label'
                                    multipleValues={bulkEditQASubmitterHMV}
                                />
                            </FormControl>

                            {/* 470 QA Date Submitted Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditQADateSubmittedHMV
                                        ? '470 QA Date Submitted (Multiple Values)'
                                        : '470 QA Date Submitted'
                                }
                                providedDate={bulkEditQADateSubmitted}
                                initialDate={bulkEditQADateSubmittedIV}
                                onDateChange={(newDate) => setBulkEditQADateSubmitted(newDate)}
                                multipleValues={bulkEditQADateSubmittedHMV}
                            />

                            {/* 470 QA Date Needed Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditQADateNeededHMV
                                        ? '470 QA Date Needed (Multiple Values)'
                                        : '470 QA Date Needed'
                                }
                                providedDate={bulkEditQADateNeeded}
                                initialDate={bulkEditQADateNeededIV}
                                onDateChange={(newDate) => setBulkEditQADateNeeded(newDate)}
                                multipleValues={bulkEditQADateNeededHMV}
                            />

                            {/* 470 QA Date Assigned Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditQADateAssignedHMV
                                        ? '470 QA Date Assigned (Multiple Values)'
                                        : '470 QA Date Assigned'
                                }
                                providedDate={bulkEditQADateAssigned}
                                initialDate={bulkEditQADateAssignedIV}
                                onDateChange={(newDate) => setBulkEditQADateAssigned(newDate)}
                                multipleValues={bulkEditQADateAssignedHMV}
                            />

                            {/* QA Reviewer Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-470-qa-reviewer-label'>
                                    {bulkEditQAReviewerHMV ? '470 QA Reviewer (Multiple Values)' : '470 QA Reviewer'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQAReviewerIV}
                                    value={bulkEditQAReviewer}
                                    setValue={setBulkEditQAReviewer}
                                    dropDownOptions={qaReviewerEditOptions}
                                    label='470-qa-reviewer-label'
                                    multipleValues={bulkEditQAReviewerHMV}
                                />
                            </FormControl>

                            {/* QA Reviewer Notes */}
                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='QA Reviewer Notes'
                                    value={bulkEditQAReviewerNotes}
                                    id='qa_notes_textfield'
                                    onChange={(event) => setBulkEditQAReviewerNotes(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={10}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bulkEditQAReviewerNotes ? 'orange' : '',
                                                borderWidth: bulkEditQAReviewerNotes ? '2px' : '1px',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>

                            {/* 470 QA Date Completed Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditQADateCompletedHMV
                                        ? '470 QA Date Completed (Multiple Values)'
                                        : '470 QA Date Completed'
                                }
                                providedDate={bulkEditQADateCompleted}
                                initialDate={bulkEditQADateCompletedIV}
                                onDateChange={(newDate) => setBulkEditQADateCompleted(newDate)}
                                multipleValues={bulkEditQADateCompletedHMV}
                            />
                        </Grid>

                        {/* THIRD COLUMN */}
                        <Grid item xs={4}>
                            {/* Bid Eval QA Submitter Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-bid-eval-qa-submitter-label'>
                                    {bulkEditBidEvalQASubmitterHMV
                                        ? 'Bid Eval QA Submitter (Multiple Values)'
                                        : 'Bid Eval QA Submitter'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditBidEvalQASubmitterIV}
                                    value={bulkEditBidEvalQASubmitter}
                                    setValue={setBulkEditBidEvalQASubmitter}
                                    dropDownOptions={qaBidEvalSubmitterEditOptions}
                                    label='bid-eval-qa-submitter-label'
                                    multipleValues={bulkEditBidEvalQASubmitterHMV}
                                />
                            </FormControl>

                            {/* Bid Eval QA Status Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-bid-eval-qa-status-label'>
                                    {bulkEditBidEvalQAStatusHMV
                                        ? 'Bid Eval QA Status (Multiple Values)'
                                        : 'Bid Eval QA Status'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditBidEvalQAStatusIV}
                                    value={bulkEditBidEvalQAStatus}
                                    setValue={setBulkEditBidEvalQAStatus}
                                    dropDownOptions={qaBidEvalStatusEditOptions}
                                    label='bid-eval-qa-status-label'
                                    multipleValues={bulkEditBidEvalQAStatusHMV}
                                />
                            </FormControl>

                            {/* Bid Eval QA Date Submitted Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditBidEvalQADateSubmittedHMV
                                        ? 'Bid Eval QA Date Submitted (Multiple Values)'
                                        : 'Bid Eval QA Date Submitted'
                                }
                                providedDate={bulkEditBidEvalQADateSubmitted}
                                initialDate={bulkEditBidEvalQADateSubmittedIV}
                                onDateChange={(newDate) => setBulkEditBidEvalQADateSubmitted(newDate)}
                                multipleValues={bulkEditBidEvalQADateSubmittedHMV}
                            />

                            {/* Bid Eval QA Date Needed Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditBidEvalQADateNeededHMV
                                        ? 'Bid Eval QA Date Needed (Multiple Values)'
                                        : 'Bid Eval QA Date Needed'
                                }
                                providedDate={bulkEditBidEvalQADateNeeded}
                                initialDate={bulkEditBidEvalQADateNeededIV}
                                onDateChange={(newDate) => setBulkEditBidEvalQADateNeeded(newDate)}
                                multipleValues={bulkEditBidEvalQADateNeededHMV}
                            />

                            {/* Bid Eval QA Reviewer Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-bid-eval-qa-reviewer-label'>
                                    {bulkEditBidEvalQAReviewerHMV
                                        ? 'Bid Eval QA Reviewer (Multiple Values)'
                                        : 'Bid Eval QA Reviewer'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditBidEvalQAReviewerIV}
                                    value={bulkEditBidEvalQAReviewer}
                                    setValue={setBulkEditBidEvalQAReviewer}
                                    dropDownOptions={qaBidEvalReviewerEditOptions}
                                    label='bid-eval-qa-reviewer-label'
                                    multipleValues={bulkEditBidEvalQAReviewerHMV}
                                />
                            </FormControl>

                            {/* Bid Eval QA Date Completed Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditBidEvalQADateCompletedHMV
                                        ? 'Bid Eval QA Date Completed (Multiple Values)'
                                        : 'Bid Eval QA Date Completed'
                                }
                                providedDate={bulkEditBidEvalQADateCompleted}
                                initialDate={bulkEditBidEvalQADateCompletedIV}
                                onDateChange={(newDate) => setBulkEditBidEvalQADateCompleted(newDate)}
                                multipleValues={bulkEditBidEvalQADateCompletedHMV}
                            />

                            {/* Pre Bid Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditPreBidDateHMV ? 'Pre Bid Date (Multiple Values)' : 'Pre Bid Date'}
                                providedDate={bulkEditPreBidDate}
                                initialDate={bulkEditPreBidDateIV}
                                onDateChange={(newDate) => setBulkEditPreBidDate(newDate)}
                                multipleValues={bulkEditPreBidDateHMV}
                            />

                            {/* Questions Deadline Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditQuestionsDeadlineHMV
                                        ? 'Questions Deadline (Multiple Values)'
                                        : 'Questions Deadline'
                                }
                                providedDate={bulkEditQuestionsDeadline}
                                initialDate={bulkEditQuestionsDeadlineIV}
                                onDateChange={(newDate) => setBulkEditQuestionsDeadline(newDate)}
                                multipleValues={bulkEditQuestionsDeadlineHMV}
                            />

                            {/* Answers Deadline Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditAnswersDeadlineHMV
                                        ? 'Answers Deadline (Multiple Values)'
                                        : 'Answers Deadline'
                                }
                                providedDate={bulkEditAnswersDeadline}
                                initialDate={bulkEditAnswersDeadlineIV}
                                onDateChange={(newDate) => setBulkEditAnswersDeadline(newDate)}
                                multipleValues={bulkEditAnswersDeadlineHMV}
                            />

                            {/* Answers Posted Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditAnswersPostedHMV ? 'Answers Posted (Multiple Values)' : 'Answers Posted'}
                                providedDate={bulkEditAnswersPosted}
                                initialDate={bulkEditAnswersPostedIV}
                                onDateChange={(newDate) => setBulkEditAnswersPosted(newDate)}
                                multipleValues={bulkEditAnswersPostedHMV}
                            />

                            {/* Bid Closing Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditBidClosingHMV ? 'Bid Closing (Multiple Values)' : 'Bid Closing'}
                                providedDate={bulkEditBidClosing}
                                initialDate={bulkEditBidClosingIV}
                                onDateChange={(newDate) => setBulkEditBidClosing(newDate)}
                                multipleValues={bulkEditBidClosingHMV}
                            />

                            {/* Bid Eval Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditBidEvalHMV ? 'Bid Eval (Multiple Values)' : 'Bid Eval'}
                                providedDate={bulkEditBidEval}
                                initialDate={bulkEditBidEvalIV}
                                onDateChange={(newDate) => setBulkEditBidEval(newDate)}
                                multipleValues={bulkEditBidEvalHMV}
                            />

                            {/* Award Date Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditAwardDateHMV ? 'Award Date (Multiple Values)' : 'Award Date'}
                                providedDate={bulkEditAwardDate}
                                initialDate={bulkEditAwardDateIV}
                                onDateChange={(newDate) => setBulkEditAwardDate(newDate)}
                                multipleValues={bulkEditAwardDateHMV}
                            />

                            {/* Email Sent to Vendors Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditEmailSentToVendorsHMV
                                        ? 'Email Sent to Vendors (Multiple Values)'
                                        : 'Email Sent to Vendors'
                                }
                                providedDate={bulkEditEmailSentToVendors}
                                initialDate={bulkEditEmailSentToVendorsIV}
                                onDateChange={(newDate) => setBulkEditEmailSentToVendors(newDate)}
                                multipleValues={bulkEditEmailSentToVendorsHMV}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            handleBulkEditNextButtonClicked();
                            setNextButtonClicked(true);
                        }}
                        disabled={isNextButtonDisabled}
                    >
                        Next
                    </Button>
                    <Button onClick={handleBulkEditClose}>Cancel</Button>{' '}
                </DialogActions>
            </Dialog>

            {/* Creates the "confirmation page" dialog and displays the changes between the old matching records and the newly-edited records within a table */}
            <Dialog
                open={openBulkEditConfirmChangesTable}
                onClose={() => {
                    handleBulkEditCloseConfirmationPage();
                }}
                aria-labelledby='bulk-edit-changes-confirmation-title'
                fullWidth
                maxWidth='lg'
            >
                {bulkEditProgressIsOpen && (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.modal + 1,
                            display: 'flex',
                            position: 'absolute',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        open={bulkEditProgressIsOpen}
                        invisible={!bulkEditProgressIsOpen}
                    >
                        <CircularProgress color='inherit' />
                        <Typography variant='body1' sx={{ marginTop: 2, color: '#fff' }}>
                            Saving Bulk Edits...
                        </Typography>
                    </Backdrop>
                )}
                <DialogTitle id='bulk-edit-changes-confirmation-title' sx={{ fontSize: '1em' }}>
                    Confirm Bulk Edit Changes
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                        Application Number
                                    </TableCell>
                                    <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                        Nickname
                                    </TableCell>
                                    {changedAttributes.map((changed_attr) => (
                                        <TableCell
                                            key={changed_attr}
                                            align='center'
                                            style={{ borderRight: '1px solid #ddd' }}
                                        >
                                            {attributeHeaderMapping[changed_attr]}
                                            {changed_attr.toLowerCase().includes('notes') && (
                                                <div style={{ fontSize: '0.75em', color: '#6c757d' }}>
                                                    (prepended to existing note)
                                                </div>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allChanges.map((change_obj) => (
                                    <TableRow key={change_obj.rowID}>
                                        <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                            {change_obj.form470AppNum}
                                        </TableCell>
                                        <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                            {change_obj.nickname}
                                        </TableCell>
                                        {changedAttributes.map((changed_attr) => (
                                            <TableCell
                                                key={`${change_obj.rowID}-${changed_attr}`}
                                                align='center'
                                                style={{ whiteSpace: 'pre-wrap', borderRight: '1px solid #ddd' }}
                                            >
                                                {change_obj.changes[changed_attr] ? (
                                                    changed_attr.toLowerCase().includes('notes') ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {change_obj.changes[changed_attr].newValue !== undefined
                                                                ? '<Prepending Note>'
                                                                : '<blank>'}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                {change_obj.changes[changed_attr].oldValue !== undefined
                                                                    ? applyOldRowTransformations(
                                                                          change_obj.rowID,
                                                                          change_obj.changes[changed_attr].oldValue,
                                                                          changed_attr
                                                                      ) || '<blank>'
                                                                    : '<blank>'}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <KeyboardArrowDownIcon style={{ color: '#4CAF50' }} />
                                                            </div>
                                                            <div>
                                                                {change_obj.changes[changed_attr].newValue !== undefined
                                                                    ? applyNewRowTransformations(
                                                                          change_obj.rowID,
                                                                          change_obj.changes[changed_attr].newValue,
                                                                          changed_attr
                                                                      ) || emptyValueDisplayMapping[changed_attr]
                                                                    : '<blank>'}
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    'No Change'
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button disabled={backButtonIsDisabled} onClick={handleBulkEditBackButtonClicked}>
                        Back
                    </Button>
                    <Box flexGrow={1} display='flex' justifyContent='flex-end'>
                        <Button
                            onClick={handleBulkEditConfirmButtonClicked}
                            color='primary'
                            disabled={confirmButtonIsDisabled}
                        >
                            Confirm
                        </Button>
                        <Button disabled={cancelButtonIsDisabled} onClick={handleBulkEditClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

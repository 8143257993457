import * as React from 'react';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

function MultiSelect(props) {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const { id, label = '', options = [], selectedOptions = [], handleSelectChange = () => {} } = props;

    return (
        <Select
            id={id}
            labelId={`${id}-label`}
            label={label}
            multiple
            value={selectedOptions}
            onChange={handleSelectChange}
            variant='outlined'
            fullWidth
            input={<OutlinedInput id={`${id}-chip`} label={`${label}`} />}
            renderValue={(selected) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                    }}
                >
                    {selectedOptions.map((value) => (
                        <Chip key={value} label={options.get(value)} />
                    ))}
                </Box>
            )}
            MenuProps={MenuProps}
        >
            {[...options.keys()].map((key) => (
                <MenuItem key={key} value={key}>
                    {options.get(key)}
                </MenuItem>
            ))}
        </Select>
    );
}

MultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: function (props, propName, componentName) {
        if (!(props[propName] instanceof Map)) {
            return new Error(`Invalid prop '${propName}' supplied to '${componentName}' - Map required`);
        }
    },
    selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    handleSelectChange: PropTypes.func,
};

export default MultiSelect;

import React from 'react';
import Container from '@mui/material/Container';
import Embed from '../components/Embed.js';
import { useSearchParams } from 'react-router-dom';

function GeneralEmbeddedDashboard(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    let dashboardName = searchParams.get('dashboardName');
    let sheetID = searchParams.get('sheetID');
    let filterParam = searchParams.get('filterParam');
    let filterValue = searchParams.get('filterValue');

    let parameterObject = {};
    parameterObject[filterParam] = filterValue;

    return (
        <Container
            maxWidth={false}
            style={{
                width: '100%',
            }}
        >
            <Embed
                dashboardName={dashboardName}
                sheetID={sheetID}
                defaultToCurrentUser={false}
                passedParameters={parameterObject}
            />
        </Container>
    );
}

export default GeneralEmbeddedDashboard;

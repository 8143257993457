import React from 'react';

function UserSettings() {
    return (
        <>
            <h2>User Settings</h2>
        </>
    );
}

export default UserSettings;

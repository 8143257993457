import React from 'react';
import useLocalStorage from 'react-use-localstorage';
import { addDays, subDays } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';

import {
    DataGridPro,
    GridLinkOperator,
    useGridApiRef,
    GridRowModes,
    GridActionsCellItem,
    gridColumnFieldsSelector,
} from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Fade from '@mui/material/Fade';
import { Dialog, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';

import { useAuthContext } from '../useAuthContext';
import ClientAPI from '../api/ClientAPI.js';
import TrackersAPI from '../api/TrackersAPI.js';
import UserAPI from '../api/UserAPI.js';
import MultiAutoComplete from './MultiAutoComplete.js';
import CustomDatagridToolbarARCL from './CustomDatagridToolbarARCL.js';
// import { NotesCellDisplay } from './customcomponents/RenderCellExpand.js';
import { NotesCellDisplay } from './customcomponents/RenderCellExpandV2.js'; // NEW
import { InlineEditTextarea1 } from './customcomponents/InlineEditTextarea.js';
import { InlineEditDropdown } from './customcomponents/InlineEditDropdown.js';
import NotesEditDialog from './dialogs/NotesEditDialog.js';
import { PromptUpdateOrSaveNewViewDialog } from './customcomponents/PromptUpdateOrSaveNewViewDialog.js';
import removeAnyBooleanFilterValueWrapper from './customcomponents/RemoveAnyBooleanFilterValue.js';
import doesNotContainWrapper from './customcomponents/DoesNotContainFilter.js';
import IsEqualToColumnWrapper from './customcomponents/IsEqualToColumnFilter.js';
import IsNotEqualToColumnWrapper from './customcomponents/IsNotEqualToColumnFilter.js';
import IsGreaterThanColumnWrapper from './customcomponents/IsGreaterThanColumnFilter.js';
import IsGreaterThanOrEqualToColumnWrapper from './customcomponents/IsGreaterThanOrEqualToColumnFilter.js';
import IsLessThanColumnWrapper from './customcomponents/IsLessThanColumnFilter.js';
import IsLessThanOrEqualToColumnWrapper from './customcomponents/IsLessThanOrEqualToColumnFilter.js';
import IsAnyOfWrapper from './customcomponents/IsAnyOfFilter.js';
import IsNotAnyOfWrapper from './customcomponents/IsNotAnyOfFilter.js';
// import IsBeforeXDaysWrapper from './customcomponents/IsBeforeXDaysFilter.js';
// import IsAfterXDaysWrapper from './customcomponents/IsAfterXDaysFilter.js';
import { PromptUpdateOrSaveNewSearchDialog } from './customcomponents/PromptUpdateOrSaveNewSearchDialog.js';

function Form471TrackerApplicantReport(props) {
    const auth = useAuthContext();
    const appliesTo = 'Form471 Applicants';
    const clientAPI = new ClientAPI();
    const trackersAPI = new TrackersAPI();
    const userAPI = new UserAPI();
    const [searchParameters, setSearchParameters] = React.useState(null);
    const [userID, setUserID] = React.useState(0);

    React.useEffect(() => {
        const getCurrentUserID = async (cognitoID) => {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetDatabaseIDForUser';
            const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

            const idResponse = await API.get(apiName, path, queryStringParameters);
            setUserID(idResponse);
        };

        getCurrentUserID(auth.cognitoID);
    }, []);

    // handleFieldsInitialized puts a value into searchParameters which causes the table to query and load data on first page load.
    return (
        <>
            <Form471TrackerApplicantReportSearchBox
                appliesTo={appliesTo}
                handleFieldsInitialized={setSearchParameters}
                handleSearchButtonClick={setSearchParameters}
                trackersAPI={trackersAPI}
                userAPI={userAPI}
                userID={userID}
            />
            <Form471TrackerApplicantReportTable
                appliesTo={appliesTo}
                clientAPI={clientAPI}
                trackersAPI={trackersAPI}
                userAPI={userAPI}
                userID={userID}
                searchParameters={searchParameters}
            />
        </>
    );
}

const defaultFilter = '{"items": []}';
const defaultSort = '[]';
const defaultHiddenColumns =
    '{"filingForECF1": false, "filingForECF2": false, "filingForECF3": false, "fundingYear": false, "expectedNumberOfMinibids": false, "minibidCount": false}';
const defaultPinnedColumns = '{"left":["actions"],"right":[]}';

const CACHE_INCREMENT = 1;
const LS_INCREMENT = 2;
const SS_INCREMENT = 2;
const APPLICANT_FILTER_LS_NAME = 'cedF471TApplicantsFilterPrefs' + LS_INCREMENT;
const APPLICANT_SORT_LS_NAME = 'cedF471TApplicantsSortPrefs' + LS_INCREMENT;
const APPLICANT_HIDDEN_LS_NAME = 'cedF471TApplicantsHiddenColumns' + LS_INCREMENT;
const APPLICANT_PINNED_LS_NAME = 'cedF471TApplicantsPinnedColumns' + LS_INCREMENT;
const APPLICANT_SEARCHFIELDS_LS_NAME = 'cedF471TApplicantsReportSearchFieldValues' + LS_INCREMENT;
const APPLICANT_SEARCHFIELDS_SS_NAME = 'cedF471TApplicantsReportSearchFieldValuesSS' + SS_INCREMENT;
const APPLICANT_CACHE_NAME = 'ced-f471tapplicantsreport-cache-' + CACHE_INCREMENT;
const APPLICANT_COLUMNORDER_LS_NAME = 'cedF471TApplicantColumnOrder' + LS_INCREMENT;
const APPLICANT_ROWHEIGHT_LS_NAME = 'ced471ApplicantRowHeight' + LS_INCREMENT;
const APPLICANT_ROWHEIGHT_SS_NAME = 'ced471ApplicantRowHeightSS' + SS_INCREMENT;
const APPLICANT_DENSITY_LS_NAME = 'cedF471ApplicantsDensityPref' + LS_INCREMENT;

function Form471TrackerApplicantReportTable({ appliesTo, clientAPI, trackersAPI, userAPI, userID, searchParameters }) {
    const [reportData, setReportData] = React.useState([]); // per-applicant, per-funding-year data
    const [afyStatusEditOptions, setAfyStatusEditOptions] = React.useState([]);
    const [dataIsLoading, setDataIsLoading] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(2000);
    // const [pageSize, setPageSize] = React.useState(25);
    const [filterPrefs, setFilterPrefs] = useLocalStorage(APPLICANT_FILTER_LS_NAME, defaultFilter);
    const [sortPrefs, setSortPrefs] = useLocalStorage(APPLICANT_SORT_LS_NAME, defaultSort);
    const [densityPref, setDensityPref] = useLocalStorage(APPLICANT_DENSITY_LS_NAME, 'standard');
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = useLocalStorage(APPLICANT_HIDDEN_LS_NAME, defaultHiddenColumns);
    const [pinnedColumnPrefs, setPinnedColumnPrefs] = useLocalStorage(APPLICANT_PINNED_LS_NAME, defaultPinnedColumns);
    const [columnResetCount, setColumnResetCount] = React.useState(0);
    const FIXED_ROW_HEIGHT = 38;
    const [rowHeightPref, setRowHeightPref] = useLocalStorage(APPLICANT_ROWHEIGHT_LS_NAME, 'fixed'); // Storing 'fixed' or 'auto'
    const getRowHeight = React.useCallback(
        ({ densityFactor }) => {
            //console.log('densityFactor = ' + densityFactor);
            if (rowHeightPref === 'auto') {
                return 'auto';
            } else {
                return FIXED_ROW_HEIGHT * densityFactor; // 0.7, 1, or 1.3
            }
        },
        [rowHeightPref]
    );
    const [editDropdownOptionsRendered, setEditDropdownOptionsRendered] = React.useState(false);
    const [usedCacheOnLoad, setUsedCacheOnLoad] = React.useState(false);
    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = React.useState({});

    // ################################### VIEW STATE #############################################
    const [currentSavedViews, setCurrentSavedViews] = React.useState([]);
    const [selectedViewName, setSelectedViewName] = React.useState('');
    const [editingViewName, setEditingViewName] = React.useState('');
    const [currentColumnOrder, setCurrentColumnOrder] = React.useState([]);
    const [currentColumnWidths, setCurrentColumnWidths] = React.useState({});
    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');

    // ################################### Bulk Editing #############################################
    // const [selectionModel, setSelectionModel] = React.useState([]);
    // const [bulkEditButtonClicked, setBulkEditButtonClicked] = React.useState(false);

    // ################################### NOTES STATE #############################################
    const [openNotesEditDialog, setOpenNotesEditDialog] = React.useState(false);
    const [editableNotes, setEditableNotes] = React.useState('');
    const [notesEditRowId, setNotesEditRowId] = React.useState(null);
    const [notesFieldName, setNotesFieldName] = React.useState('');
    const [pruUpdatedRow, setPruUpdatedRow] = React.useState({});
    const [notesEditDialogTitle, setNotesEditDialogTitle] = React.useState('');
    const [notesEditDialogLabel, setNotesEditDialogLabel] = React.useState('');
    const [userData, setUserData] = React.useState(null);

    // ################################### FILTER OPTIONS #############################################
    const [applicantCohortMSFilterOptions, setApplicantCohortMSFilterOptions] = React.useState([]);
    const [applicantTypeMSFilterOptions, setApplicantTypesMSFilterOptions] = React.useState([]);
    const [form470AFYStatusesMSFilterOptions, setForm470AFYStatusesMSFilterOptions] = React.useState([]);
    const [applicantNameMSFilterOptions, setApplicantNameMSFilterOptions] = React.useState([]);
    const [primaryContactMSFilterOptions, setPrimaryContactMSFilterOptions] = React.useState([]);
    const [secondaryContactMSFilterOptions, setSecondaryContactMSFilterOptions] = React.useState([]);
    const [stateMSFilterOptions, setStateMSFilterOptions] = React.useState([]);
    const [lastUpdatedByMSFilterOptions, setLastUpdatedByMSFilterOptions] = React.useState([]);
    const [cyberSecurityPilotParticipationOptions, setCyberSecurityPilotParticipationOptions] = React.useState([]);

    // ################################### VIEW STORAGE NAMES #############################################
    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const APPLICANT_VIEW_LS_NAME = 'cedF471ApplicantsViewPrefsLS' + LS_INCREMENT;
    const APPLICANT_VIEW_SS_NAME = 'cedF471ApplicantsViewPrefsSS' + SS_INCREMENT;
    const APPLICANT_CURRENT_COL_WIDTHS_LS_NAME = 'cedF471ApplicantsViewColumnCurrentWidthPrefsLS' + LS_INCREMENT;
    const APPLICANT_CURRENT_COL_WIDTHS_SS_NAME = 'cedF471ApplicantsViewColumnCurrentWidthPrefsSS' + SS_INCREMENT;
    const APPLICANT_ORIGINAL_COL_WIDTHS_LS_NAME = 'cedF471ApplicantsViewColumnOriginalWidthPrefsLS' + LS_INCREMENT;
    const APPLICANT_ORIGINAL_COL_WIDTHS_SS_NAME = 'cedF471ApplicantsViewColumnOriginalWidthPrefsSS' + SS_INCREMENT;
    const APPLICANT_CURRENT_COL_ORDER_LS_NAME = 'cedF471ApplicantsViewColumnCurrentOrderPrefsLS' + LS_INCREMENT;
    const APPLICANT_CURRENT_COL_ORDER_SS_NAME = 'cedF471ApplicantsViewColumnCurrentOrderPrefsSS' + SS_INCREMENT;
    const APPLICANT_ORIGINAL_COL_ORDER_LS_NAME = 'cedF471ApplicantsViewColumnOriginalOrderPrefsLS' + LS_INCREMENT;
    const APPLICANT_ORIGINAL_COL_ORDER_SS_NAME = 'cedF471ApplicantsViewColumnOriginalOrderPrefsSS' + SS_INCREMENT;
    // ################################### VIEW LS STORAGE SETTERS #############################################
    const [currentColumnWidthPrefsLS, setCurrentColumnWidthPrefsLS] = useLocalStorage(
        APPLICANT_CURRENT_COL_WIDTHS_LS_NAME,
        ''
    );
    const [originalColumnWidthPrefsLS, setOriginalColumnWidthPrefsLS] = useLocalStorage(
        APPLICANT_ORIGINAL_COL_WIDTHS_LS_NAME,
        ''
    );

    const [currentColumnOrderPrefsLS, setCurrentColumnOrderPrefsLS] = useLocalStorage(
        APPLICANT_CURRENT_COL_ORDER_LS_NAME,
        ''
    );
    const [originalColumnOrderPrefsLS, setOriginalColumnOrderPrefsLS] = useLocalStorage(
        APPLICANT_ORIGINAL_COL_ORDER_LS_NAME,
        ''
    );
    const [viewPrefsLS, setViewPrefsLS] = useLocalStorage(APPLICANT_VIEW_LS_NAME, '');
    // ################################### END VIEW LS STORAGE SETTERS #############################################

    const determineInitialShowHideECFColumnsButtonText = () => {
        let hcprefs = JSON.parse(hiddenColumnPrefs);
        if (
            hcprefs['filingForECF1'] === false ||
            hcprefs['filingForECF1'] === false ||
            hcprefs['filingForECF1'] === false
        ) {
            // If any of the ECF columns are hidden (not visible) set the button text to 'Show ECF Columns'.
            return 'Show ECF Columns';
        } else {
            return 'Hide ECF Columns';
        }
    };
    // This sets the Show/Hide ECF column text value in a state
    const [showHideECFColumnsButtonText, setShowHideECFColumnsButtonText] = React.useState(
        determineInitialShowHideECFColumnsButtonText
    );

    const determineInitialAutoFixedColumnsButtonText = () => {
        if (rowHeightPref === 'fixed') {
            return 'Auto Row Height';
        } else {
            return 'Fixed Row Height';
        }
    };
    const [autoFixedColumnsButtonText, setAutoFixedColumnsButtonText] = React.useState(
        determineInitialAutoFixedColumnsButtonText
    );

    React.useEffect(() => {
        const originalOrder = originalColumns.map((ea_col) => ea_col.field);
        const originalWidths = {};
        originalColumns.forEach((ea_col) => {
            originalWidths[ea_col.field] = ea_col.width;
        });

        setOriginalColumnOrderToStorage(JSON.stringify(originalOrder));
        setOriginalColumnWidthsToStorage(JSON.stringify(originalWidths));

        setCurrentColumnOrder(JSON.parse(getCurrentColumnOrderFromStorage()) ?? []);
        setCurrentColumnWidths(JSON.parse(getCurrentColumnWidthsFromStorage()) ?? {});
    }, []);

    // Attempts to pull from SS then LS
    const retrieveViewFieldValuesJSONFromStorage = () => {
        let viewFieldValuesInSS = sessionStorage.getItem(APPLICANT_VIEW_SS_NAME);
        if (viewFieldValuesInSS) {
            return viewFieldValuesInSS;
        }

        if (viewPrefsLS) {
            return viewPrefsLS;
        }
        return null;
    };

    // Gets all of the saved views (should be called on page load within useEffect)
    const getSavedViews = async (ap) => {
        const viewResults = await userAPI.GetSavedViews(ap);
        const parsedViewResults = JSON.parse(viewResults);

        // Defines the base layout for all retrieved views
        const structuredViews = parsedViewResults.map((ea_row) => ({
            view_id: ea_row.id,
            view_name: ea_row.view_name,
            parameters: ea_row,
        }));
        structuredViews.sort((a, b) => a.view_name.localeCompare(b.view_name));
        console.log('ClientListTableWithSearch: getSavedViewsFN: structuredViews = ', structuredViews);

        setCurrentSavedViews(structuredViews);
        const vnFromStorage = retrieveViewFieldValuesJSONFromStorage();
        if (vnFromStorage) {
            const vnFromStorageParsed = JSON.parse(vnFromStorage);
            setSelectedViewName(vnFromStorageParsed.viewName);
        }
    };

    React.useEffect(() => {
        const checkCacheForPreviousSearch = async () => {
            let APICache = await caches.open(APPLICANT_CACHE_NAME);
            const response = await APICache.match('/Form471Tracker/ApplicantForm471Search');
            // console.log('previousSearchCache = ', response);

            if (response !== undefined) {
                const cachedData = await response.json();
                // console.log('cachedData = ', cachedData);
                console.log('cachedData.length = ', cachedData.length);

                // ? let rowData = updateRowsWithEditedEntry(cachedData, updatedRow);
                //setReportData(rowData);
                setReportData(cachedData);
                setUsedCacheOnLoad(true);

                // console.log('useEffect 1 (cached) | reportData = ', reportData)
                // console.log('useEffect 1 (cached) | usedCacheOnLoad = ', usedCacheOnLoad)
            }

            // const afyStatusEditOptionsResponse = await APICache.match('/Form471Tracker/ApplicantForm471Search/afyStatusEditOptions');
            // console.log('afyStatusEditOptions previousCache = ', afyStatusEditOptionsResponse);

            // if (afyStatusEditOptionsResponse !== undefined) {
            //     const afyStatusCachedData = await afyStatusEditOptionsResponse.json();
            //     console.log('afyStatusCachedData = ', afyStatusCachedData);

            //     setAfyStatusEditOptions(afyStatusCachedData);
            //     // setUsedCacheOnLoad(true);
            //     console.log('useEffect 1 (cached) | afyStatusEditOptions = ', afyStatusEditOptions)
            // }

            // if ((response !== undefined) && (afyStatusEditOptionsResponse !== undefined)) {
            //     setUsedCacheOnLoad(true);
            // }
        };

        const fetchInlineEditOptions = async () => {
            try {
                let afyStatusEditOptionsResult = await clientAPI.GetClientFundingYearEditOptionsData();
                // console.log('afyStatusEditOptionsResult = ', afyStatusEditOptionsResult);
                // console.log(
                //     'afyStatusEditOptionsResult.form470AFYStatuses = ',
                //     afyStatusEditOptionsResult.form470AFYStatuses
                // );
                // console.log(
                //     'afyStatusEditOptionsResult.form470AFYStatuses.length = ',
                //     afyStatusEditOptionsResult.form470AFYStatuses.length
                // );
                setAfyStatusEditOptions(afyStatusEditOptionsResult.form470AFYStatuses);
                setEditDropdownOptionsRendered(true);
                // console.log('useEffect 1 | fetchInlineEditOptions | afyStatusEditOptions = ', afyStatusEditOptions)
            } catch (error) {
                console.error('fetchInlineEditOptions error: ', error);
                toast.error(error);
            }
        };

        // Potential
        const getOptionsForFiltering = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                // Potential
                let options = await trackersAPI.GetForm471TrackerApplicantsReportFilterOptions();
                console.log('[getOptionsForFiltering][options] =', options);
                setApplicantCohortMSFilterOptions(options.applicantCohorts);
                setApplicantNameMSFilterOptions(options.applicantNames);
                setApplicantTypesMSFilterOptions(options.applicantTypes);
                setForm470AFYStatusesMSFilterOptions(options.form470AFYStatuses);
                setPrimaryContactMSFilterOptions(options.employees);
                setSecondaryContactMSFilterOptions(options.employees);
                setCyberSecurityPilotParticipationOptions(options.cyberSecurityPilotParticipation);
                setStateMSFilterOptions(options.states);
                setLastUpdatedByMSFilterOptions(options.employees);
            } catch (error) {
                console.log('Error getting filter options: ' + error);
                toast.error(error);
            }
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUserData(parsedObject);
        };

        checkCacheForPreviousSearch();
        fetchInlineEditOptions();
        getOptionsForFiltering();
        getUserData();
        getSavedViews(appliesTo);
    }, []);

    React.useEffect(() => {
        const searchApplicants = async (searchParameters) => {
            try {
                if (!usedCacheOnLoad) {
                    setDataIsLoading(true);
                }

                // const searchParametersForAPI = {
                //     reporttype: searchParameters.reportType,
                //     categoryDropdownDisabled: searchParameters.categoryDropdownDisabled,
                //     ignoreCDCheckboxDisabled: searchParameters.ignoreCDCheckboxDisabled,
                //     funding_year: searchParameters.funding_year,
                //     primary_contacts: searchParameters.primaryContacts,
                //     secondary_contacts: searchParameters.secondaryContacts,
                //     teams: searchParameters.teams,
                //     application_owners: searchParameters.applicationOwners,
                //     applicant_types: searchParameters.applicantTypes,
                //     applicant_cohorts: searchParameters.applicantCohorts,
                //     services: searchParameters.services,
                //     is_a_client: searchParameters.isAClient,
                //     form470_filing_status: searchParameters.form470FilingStatus,
                //     form471_filing_status: searchParameters.form471FilingStatus,
                //     ignore_cancelled_discarded: searchParameters.ignoreCancelledDiscarded,
                //     category: searchParameters.category,
                //     // deadline_field_name: searchParameters?.deadlineFieldName?.value ?? null,
                //     // deadline_field_operator: searchParameters?.deadlineFieldOperator?.value ?? null,
                //     // deadline_field_days: searchParameters?.deadlineFieldDays ?? null,
                //     // calculated_deadline_date: searchParameters.calculatedDeadlineDate
                // };

                // const deadlineFieldName = searchParameters?.deadlineFieldName?.value ?? null;
                // const deadlineFieldOperator = searchParameters?.deadlineFieldOperator?.value ?? null;
                // const deadlineFieldDays = searchParameters?.deadlineFieldDays ?? null;
                // // console.log(deadlineFieldName, deadlineFieldOperator, deadlineFieldDays);

                // if (deadlineFieldName && deadlineFieldOperator && deadlineFieldDays) {
                //     let targetDate = 0;
                //     const currentDate = new Date();
                //     searchParametersForAPI.current_date = currentDate.toISOString().split('T')[0];

                //     if (deadlineFieldOperator == 'isBeforeXDays') {
                //         targetDate = subDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }

                //     if (deadlineFieldOperator == 'isAfterXDays') {
                //         targetDate = addDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }
                // }
                // console.log(
                //     '[searchApplicants, React.useEffect, searchApplicants]  searchParametersForAPI = ',
                //     searchParametersForAPI
                // );

                // let searchResult = await trackersAPI.Form471TrackerApplicantsReportSearch(searchParametersForAPI);
                // Potential - searchParameters defined by handleFieldsInitialized are passed to the lambda
                console.log('searchApplicants | searchParameters = ', searchParameters);
                let searchResult = await trackersAPI.Form471TrackerApplicantsReportSearch(searchParameters);
                // console.log('[searchApplicants, React.useEffect, searchApplicants]  searchResult = ', searchResult);
                // let searchResultFull = await trackersAPI.Form471TrackerApplicantsReportSearch(searchParameters);
                // console.log('searchApplicants | searchResultFull = \r\n', searchResultFull);
                // let searchResult = searchResultFull.slice(0, 10) // FOR DEBUGGING

                // console.log('searchApplicants | searchResult = ', searchResult);
                console.log('searchApplicants | searchResult.length = ', searchResult.length);
                console.log('searchApplicants | searchResult top3 = ', searchResult.slice(0, 3));
                // setReportData(searchResult);

                if (searchResult !== false && searchResult.body !== '[]') {
                    let APICache = await caches.open(APPLICANT_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    APICache.put(
                        '/Form471Tracker/ApplicantForm471Search',
                        new Response(JSON.stringify(searchResult), headersOptions)
                    );
                } else {
                    console.log('searchResult.body IS FALSE/UNDEFINED');
                }

                // console.log('useEffect 2 | searchApplicants | usedCacheOnLoad = ', usedCacheOnLoad)
                // console.log('useEffect 2 | searchApplicants | dataIsLoading = ', dataIsLoading)

                // let afyStatusEditOptionsResult = await clientAPI.GetClientFundingYearEditOptionsData();
                // console.log('afyStatusEditOptionsResult.form470AFYStatuses = ', afyStatusEditOptionsResult.form470AFYStatuses);
                // setAfyStatusEditOptions(afyStatusEditOptionsResult.form470AFYStatuses);
                // console.log('useEffect 2 | afyStatusEditOptions = ', afyStatusEditOptions)

                // if (afyStatusEditOptionsResult !== false) {
                //     let APICache = await caches.open(APPLICANT_CACHE_NAME);
                //     let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                //     APICache.put(
                //         '/Form471Tracker/ApplicantForm471Search/afyStatusEditOptions',
                //         new Response(JSON.stringify(afyStatusEditOptionsResult.form470AFYStatuses), headersOptions)
                //     );
                // }

                // if (searchResult) {
                //     console.log('searchResult.length = ' + searchResult.length);
                // }

                setReportData(searchResult);
                // console.log('useEffect 2 | reportData = ', reportData)
                // console.log('useEffect 2 | afyStatusEditOptions = ', afyStatusEditOptions)

                setUsedCacheOnLoad(false);
                setDataIsLoading(false);
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        };

        if (searchParameters) {
            if (!usedCacheOnLoad) {
                console.log('executing search');
                setReportData([]); // Setting data to a empty array displays the loading spinner while we query for new data.
            }

            searchApplicants(searchParameters);
        }
    }, [searchParameters]);

    // ################################### SAVED VIEWS #############################################
    function endsWithView(viewName) {
        const lowercaseName = viewName.trim().toLowerCase();
        return lowercaseName.endsWith('view') || lowercaseName.split(-4).pop().endsWith('view');
    }

    // Check if the view name already exists in the currentSavedViews
    const viewNameExists = (viewName) => {
        return currentSavedViews.some((ea_view) => ea_view.view_name === viewName);
    };

    const handleKeyDownForNewView = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewView2();
        }
    };

    // Retrieve the CURRENT width values from session or local storage
    const getCurrentColumnWidthsFromStorage = () => {
        return (
            sessionStorage.getItem(APPLICANT_CURRENT_COL_WIDTHS_SS_NAME) ||
            localStorage.getItem(APPLICANT_CURRENT_COL_WIDTHS_LS_NAME)
        );
    };

    // Set the CURRENT width values to session and local storage
    const setCurrentColumnWidthsToStorage = (params) => {
        sessionStorage.setItem(APPLICANT_CURRENT_COL_WIDTHS_SS_NAME, params);
        setCurrentColumnWidthPrefsLS(params);
    };

    // Retrieve the ORIGINAL width values from session or local storage
    const getOriginalColumnWidthsFromStorage = () => {
        return (
            sessionStorage.getItem(APPLICANT_ORIGINAL_COL_WIDTHS_SS_NAME) ||
            localStorage.getItem(APPLICANT_ORIGINAL_COL_WIDTHS_LS_NAME)
        );
    };

    // Set the ORIGINAL width values to session and local storage
    const setOriginalColumnWidthsToStorage = (params) => {
        sessionStorage.setItem(APPLICANT_ORIGINAL_COL_WIDTHS_SS_NAME, params);
        setOriginalColumnWidthPrefsLS(params);
    };

    // Retrieve the CURRENT order values from session or local storage
    const getCurrentColumnOrderFromStorage = () => {
        return (
            sessionStorage.getItem(APPLICANT_CURRENT_COL_ORDER_SS_NAME) ||
            localStorage.getItem(APPLICANT_CURRENT_COL_ORDER_LS_NAME)
        );
    };

    // Set the CURRENT order values to session and local storage
    const setCurrentColumnOrderToStorage = (params) => {
        sessionStorage.setItem(APPLICANT_CURRENT_COL_ORDER_SS_NAME, params);
        setCurrentColumnOrderPrefsLS(params);
    };

    // Retrieve the ORIGINAL order values from session or local storage
    const getOriginalColumnOrderFromStorage = () => {
        return (
            sessionStorage.getItem(APPLICANT_ORIGINAL_COL_ORDER_SS_NAME) ||
            localStorage.getItem(APPLICANT_ORIGINAL_COL_ORDER_LS_NAME)
        );
    };

    const resetPinnedColumnsPrefs = () => {
        setPinnedColumnPrefs(defaultPinnedColumns);
    };

    // Set the ORIGINAL order values to session and local storage
    const setOriginalColumnOrderToStorage = (params) => {
        sessionStorage.setItem(APPLICANT_ORIGINAL_COL_ORDER_SS_NAME, params);
        setOriginalColumnOrderPrefsLS(params);
    };

    // Saves SFVs to session & local storage
    const saveViewFieldValuesJSONToStorage = (json) => {
        setViewPrefsLS(json);
        sessionStorage.setItem(APPLICANT_VIEW_SS_NAME, json);
    };

    // Function used to reset the view-related data to its defaults (would need a reset to default view button)
    const setDefaultViewValues = () => {
        setFilterPrefs(defaultFilter);
        setDensityPref('standard');
        setHiddenColumnPrefs(defaultHiddenColumns);
        setSortPrefs(defaultSort);
        setViewPrefsLS('');
        sessionStorage.setItem(APPLICANT_VIEW_SS_NAME, '');
    };

    const resetViewToDefaults = () => {
        setSelectedViewName('');
        setTextFieldValue('');
        resetPinnedColumnsPrefs();

        const originalOrder = JSON.parse(getOriginalColumnOrderFromStorage());
        const originalWidth = JSON.parse(getOriginalColumnWidthsFromStorage());

        setCurrentColumnOrder(originalOrder);
        setCurrentColumnOrderToStorage(JSON.stringify(originalOrder));

        setCurrentColumnWidths(originalWidth);
        setCurrentColumnWidthsToStorage(JSON.stringify(originalWidth));

        // Resets the ECF text to default
        setColumnVisibility(['filingForECF1', 'filingForECF2', 'filingForECF3'], false);
        setShowHideECFColumnsButtonText('Show ECF Columns');

        // Resets the Row Height text to default
        setRowHeightPref('fixed');
        setAutoFixedColumnsButtonText('Auto Row Height');
        setDefaultViewValues();
    };

    // Called when a view has been selected via handleSelectSavedViewFN
    const setViewFieldPrefs = (parsedVP) => {
        // Sets the default values for each of view parameters
        setDefaultViewValues();

        // Fill in view values with real data
        try {
            if (parsedVP.viewName) {
                setSelectedViewName(parsedVP.viewName);
            }
            if (parsedVP.filterPrefs != null) {
                setFilterPrefs(parsedVP.filterPrefs);
            }
            if (parsedVP.sortPrefs) {
                setSortPrefs(parsedVP.sortPrefs);
            }
            if (parsedVP.densityPref != null) {
                setDensityPref(parsedVP.densityPref);
            }
            if (parsedVP.hiddenColumnPrefs != null) {
                setHiddenColumnPrefs(parsedVP.hiddenColumnPrefs);
            }
            if (parsedVP.pinnedColumnPrefs != null) {
                setPinnedColumnPrefs(parsedVP.pinnedColumnPrefs);
            }
            if (parsedVP.viewColumnWidthPrefs != null) {
                setCurrentColumnWidthsToStorage(parsedVP.viewColumnWidthPrefs);
                setCurrentColumnWidths(JSON.parse(parsedVP.viewColumnWidthPrefs));
            }
            if (parsedVP.viewColumnOrderPrefs != null) {
                const currentOrder = JSON.parse(parsedVP.viewColumnOrderPrefs);
                setCurrentColumnOrder(currentOrder);
                setCurrentColumnOrderToStorage(parsedVP.viewColumnOrderPrefs);
            }
        } catch (error) {
            console.error('error: ', error);
            toast.error(error);
            return false;
        }
    };

    // Creates an object of key/value pairs of view-related data
    const createViewsParametersObject = () => {
        return {
            selectedViewName: selectedViewName,
            filterPrefs: filterPrefs,
            sortPrefs: sortPrefs,
            densityPref: densityPref,
            hiddenColumnPrefs: hiddenColumnPrefs,
            pinnedColumnPrefs: pinnedColumnPrefs,
            viewColumnWidthPrefs: getCurrentColumnWidthsFromStorage(),
            viewColumnOrderPrefs: getCurrentColumnOrderFromStorage(),
        };
    };

    const handleOnColumnWidthChange = React.useCallback(
        (event) => {
            const allColumns = apiRef.current.getAllColumns();
            const updatedWidths = allColumns.reduce((accumulator, current_col) => {
                accumulator[current_col.field] = current_col.width;
                return accumulator;
            }, {});
            setCurrentColumnWidths(updatedWidths);
        },
        [apiRef]
    );

    React.useEffect(() => {
        setCurrentColumnOrderToStorage(JSON.stringify(currentColumnOrder));
        setCurrentColumnWidthsToStorage(JSON.stringify(currentColumnWidths));
    }, [currentColumnOrder, currentColumnWidths]);

    // NOTE: attempting to immediately save the view BEFORE the toast expires can lead to warning being displayed
    const handleSaveView = async () => {
        let newOrUpdatedViewName = selectedViewName;

        if (newOrUpdatedViewName !== '' && newOrUpdatedViewName !== null) {
            // Search for duplicate/determine if the user has already selected a view
            const duplicateViewIndex = currentSavedViews.findIndex(
                (ea_view) => ea_view.view_name === newOrUpdatedViewName
            );

            // If a duplicate was found/the user has already selected a view
            if (duplicateViewIndex !== -1) {
                openDialog1(newOrUpdatedViewName);
            }
        } else {
            openDialog2();
            promptSaveNewView();
        }
        setSelectedViewName(newOrUpdatedViewName);
    };

    const saveViewButtonClicked = () => {
        handleSaveView();
    };

    const handleOnlyUpdateView = async () => {
        const message = 'update';
        const tempCurrentSavedViews = [...currentSavedViews];

        const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === selectedViewName);
        const matchingSavedView = currentSavedViews[duplicateViewIndex];
        const vp_obj = createViewsParametersObject();

        const replacementView = {
            view_id: matchingSavedView.parameters.id,
            view_name: selectedViewName,
            parameters: vp_obj,
        };
        replacementView['parameters']['viewName'] = selectedViewName;
        tempCurrentSavedViews[duplicateViewIndex] = replacementView;
        vp_obj['viewName'] = selectedViewName;

        const response = await userAPI.SaveView(
            userID,
            message,
            appliesTo,
            tempCurrentSavedViews.at(duplicateViewIndex)
        );

        if (response) {
            setLanding(false);
            setIsDialogOpen1(false);
            saveViewFieldValuesJSONToStorage(JSON.stringify(vp_obj));
            getSavedViews(appliesTo);

            const successMessage = endsWithView(selectedViewName)
                ? `Successfully updated the ${selectedViewName}`
                : `Successfully updated the ${selectedViewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            const failedMessage = endsWithView(selectedViewName)
                ? `Failed to update the ${selectedViewName}`
                : `Failed to update the ${selectedViewName} saved view`;

            toast.error(failedMessage);
        }
        setLanding(true);
    };

    const handleOnlySaveAsNewView1 = async () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewView2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved view name cannot be empty!');
            return;
        }

        const message = 'new';
        const viewName = textFieldValue.trim();
        const tempCurrentSavedViews = [...currentSavedViews];

        if (viewNameExists(viewName)) {
            toast.error(
                `A saved view with the name ${viewName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const vp_obj = createViewsParametersObject();
        const newView = {
            view_name: viewName,
            parameters: vp_obj,
        };
        newView['parameters']['viewName'] = viewName;
        newView['parameters']['selectedViewName'] = viewName; // new view's name
        tempCurrentSavedViews.push(newView);

        const response = await userAPI.SaveView(userID, message, appliesTo, tempCurrentSavedViews.at(-1));
        if (response) {
            setSelectedViewName(viewName);
            saveViewFieldValuesJSONToStorage(JSON.stringify(vp_obj));

            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            getSavedViews(appliesTo);
            let successMessage = endsWithView(viewName)
                ? `Successfully created the ${viewName}`
                : `Successfully created the ${viewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithView(viewName)
                ? `Failed to create the ${viewName}`
                : `Failed to create the ${viewName} saved view`;

            toast.error(failedMessage);
        }
        setLanding(true);
    };

    // Called when the user selects a view from the dropdown
    const handleSelectSavedView = (view_name) => {
        // console.log('view_name is = ', view_name);
        setSelectedViewName(view_name);
        setTextFieldValue(view_name);

        // Finds the matching view object
        const retrieved_view = currentSavedViews.find((ea_view) => ea_view.view_name === view_name);
        // console.log('retrieved_view is = ', retrieved_view);

        // Applies the correct filters to the DGP and sets the filters to storage
        if (retrieved_view) {
            const retrieved_filters = retrieved_view.parameters.view_filters;
            setViewFieldPrefs(retrieved_filters);
            saveViewFieldValuesJSONToStorage(JSON.stringify(retrieved_filters));
        } else {
            return;
        }
    };

    // Called when the user clicks the edit icon
    const handleEditSavedViewName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/view_name already exists for the other saved views
            if (currentSavedViews.some((ea_view) => ea_view.view_name === new_name)) {
                alert('A saved view with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the view we want to edit
            const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === old_name);

            // If a duplicate was found, let's update it
            if (duplicateViewIndex !== -1) {
                // Returns an array of objects with the previous saved view + the edited view
                const updatedViews = currentSavedViews.map((ea_view, index) => {
                    if (index === duplicateViewIndex) {
                        return {
                            ...ea_view,
                            view_name: new_name,
                            parameters: {
                                ...ea_view.parameters,
                                view_name: new_name,
                                view_filters: {
                                    ...ea_view.parameters.view_filters,
                                    viewName: new_name, // for setting selectedViewName on render
                                },
                            },
                        };
                    }
                    return ea_view;
                });

                // Process the edited view
                const view_response = await userAPI.SaveView(
                    userID,
                    message,
                    appliesTo,
                    updatedViews.at(duplicateViewIndex)
                );

                if (old_name === selectedViewName) {
                    setViewPrefsLS(JSON.stringify({ viewName: new_name }));
                    sessionStorage.setItem(APPLICANT_VIEW_SS_NAME, JSON.stringify({ viewName: new_name }));
                }
                getSavedViews(appliesTo);

                if (view_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedView = async (view_name, view_id, applies_to, user_id) => {
        let confirmationMessage = endsWithView(view_name)
            ? `Are you sure you want to delete the ${view_name}?`
            : `Are you sure you want to delete the ${view_name} saved view?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedView(view_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithView(view_name)
                    ? `Successfully deleted the ${view_name}`
                    : `Successfully deleted the ${view_name} saved view`;

                const emptyViewNameObject = { viewName: '' };
                sessionStorage.setItem(APPLICANT_VIEW_SS_NAME, JSON.stringify(emptyViewNameObject));
                getSavedViews(appliesTo);

                toast.success(successMessage, {
                    autoClose: 3000,
                });
            } else {
                const errorMessage = endsWithView(view_name)
                    ? `Failed to delete the ${view_name}`
                    : `Failed to delete the ${view_name} saved view`;

                toast.error(errorMessage);
            }
        }
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewView2();
        }
    };

    const openDialog1 = (newOrUpdatedViewName) => {
        let confirmationMessage = endsWithView(newOrUpdatedViewName)
            ? `Do you want to update the ${newOrUpdatedViewName} or save as a new view?`
            : `Do you want to update the ${newOrUpdatedViewName} saved view or save as a new view?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewView = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved view: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='View Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDownForNewView}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewView2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED VIEWS #############################################

    // Find and update the corresponding row in reportData after processRowUpdate (needed to prevent display errors during bulk editing and note saving post inline editing)
    React.useEffect(() => {
        const updatedReportData = reportData.map((row) => {
            // console.log('useEffect --> pruUpdatedRow:', pruUpdatedRow);
            if (row.client_id === pruUpdatedRow.client_id) {
                return {
                    ...row,
                    ...pruUpdatedRow, // Replace changed values with those from the updated row
                };
            }
            return row; // Return unchanged row
        });

        setReportData(updatedReportData);
    }, [pruUpdatedRow]);

    // ################################### Custom Notes Start (NotesEditDialog) #############################################

    const usernameForNotesEditDialog = userData ? userData.email.split('@')[0] : '';

    const handleEditNotesEvent = (rowId, fieldName, currentNotes, bens, name) => {
        //console.log(`[handleEditNotesEvent] rowId = ${rowId} | fieldName = ${fieldName} | bens = ${bens} | name = ${name}`);
        setNotesEditRowId(rowId);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(`Edit Note for BEN${bens.length > 1 ? 's' : ''}: ${bens}, Name: ${name}`);
        setNotesEditDialogLabel('Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };

    const handleNotesEditDialogSave = (newNotes) => {
        //console.log('[handleNotesEditDialogSave] ');
        saveNewNotes(newNotes);
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const saveNewNotes = async (newNotes) => {
        // Determine the field title of the notes that was edited. (And sanity check notesFieldName.)
        let fieldTitle = '';
        if (notesFieldName === 'form470TrackerNotes') {
            fieldTitle = 'Form 470 Application Notes';
        } else if (notesFieldName === 'form471TrackerNotes') {
            fieldTitle = 'Form 471 Application Notes';
        } else {
            console.error(`An Error Occurred. Invalid notesFieldName: ${notesFieldName}`);
            toast.error(`An Error Occurred. (Invalid notesFieldName.)`);
            return;
        }

        // Find the record to edit/update in `reportData'.
        const specificRow = reportData.find((row) => row.client_id === notesEditRowId);
        //console.log('handleSaveNote --> specificRow: ', specificRow);

        // Create `uedata` and call the 'save notes' api endpoint.
        let form470TrackerNotesToSave;
        let form471TrackerNotesToSave;
        if (notesFieldName === 'form470TrackerNotes') {
            // User edited 'Form 470 Application Notes'..
            form470TrackerNotesToSave = newNotes;
            form471TrackerNotesToSave = specificRow['form471TrackerNotes'];
        } else if (notesFieldName === 'form471TrackerNotes') {
            // User edited 'Form 471 Application Notes'..
            form470TrackerNotesToSave = specificRow['form470TrackerNotes'];
            form471TrackerNotesToSave = newNotes;
        }

        let uedata = {
            client_id: specificRow.client_id,
            funding_year: specificRow.fundingYear,
            form470_tracker_notes: form470TrackerNotesToSave,
            form471_tracker_notes: form471TrackerNotesToSave,
        };

        //console.log('handleSaveNote --> uedata: ', uedata);

        const apiResponse = await trackersAPI.SaveForm471TrackerApplicantsReportNotes(uedata);
        console.log('handleSaveNote --> apiResponse: ', apiResponse);

        if (apiResponse) {
            // Update the data locally.
            // (Note that for these notes fields the DataGrid field name is the same name in the data.)
            const newNotes = apiResponse['record'][notesFieldName];
            const modifiedOn = apiResponse['record']['userEnteredFieldUpdatedTimestamp'];
            const modifiedBy = apiResponse['record']['userEnteredFieldUpdatedByUserName'];
            updateReportData_WithNewNotes(notesEditRowId, notesFieldName, newNotes, modifiedOn, modifiedBy);

            toast.success(`Successfully updated the ${fieldTitle}`, {
                autoClose: 3000,
            });
        } else {
            toast.error(`Failed to update the ${fieldTitle}`);
        }
    };

    const updateReportData_WithNewNotes = (rowId, dataFieldName, newNotes, modifiedOn, modifiedBy) => {
        let updatedReportData = reportData.map((row) => {
            if (row.client_id === rowId) {
                return {
                    ...row,
                    [dataFieldName]: newNotes,
                    userEnteredFieldUpdatedTimestamp: modifiedOn,
                    userEnteredFieldUpdatedByUserName: modifiedBy,
                };
            }
            return row;
        });
        setReportData(updatedReportData);
    };

    const handleNotesEditDialogCancel = () => {
        //console.log('[handleNotesEditDialogCancel] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const handleNotesEditDialogClose = () => {
        //console.log('[handleNotesEditDialogClose] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const cleanupNotesEditDialogStateVars = () => {
        setNotesEditRowId(null);
        setNotesFieldName('');
        setNotesEditDialogLabel('');
        setNotesEditDialogTitle('');
        setEditableNotes('');
    };

    // ################################### End Custom Notes #############################################

    const autoFixedRowHeight = () => {
        if (autoFixedColumnsButtonText === 'Auto Row Height') {
            toggleRowHeight();
            setAutoFixedColumnsButtonText('Fixed Row Height');
        } else {
            toggleRowHeight();
            setAutoFixedColumnsButtonText('Auto Row Height');
        }
    };

    const handleDensityChange = (newDensity) => {
        setDensityPref(newDensity);
    };

    const handleOnColumnOrderChangeV2 = React.useCallback(() => {
        const newColOrder = gridColumnFieldsSelector(apiRef.current.state);
        setCurrentColumnOrder(newColOrder);
        setCurrentColumnOrderToStorage(JSON.stringify(newColOrder));
    }, [apiRef]);

    function handleOnColumnVisibilityModelChange(newVizModel) {
        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    async function handleOnFilterModelChange(newFilterModel) {
        setFilterPrefs(JSON.stringify(newFilterModel));
    }

    function handleOnSortModelChange(newSortModel) {
        setSortPrefs(JSON.stringify(newSortModel));
    }

    function handlePinnedPrefs(newPinModel) {
        setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    const resetColumnOrderToDefault = () => {
        localStorage.removeItem(APPLICANT_COLUMNORDER_LS_NAME);
        setColumnResetCount((prevCount) => prevCount + 1);
        //setColumnResetDialogOpen(true);
    };

    const resetColumnVisibilityToDefault = () => {
        setHiddenColumnPrefs(defaultHiddenColumns);
        if (showHideECFColumnsButtonText === 'Hide ECF Columns') {
            showHideECFColumns();
        }
    };

    const resetFiltersToDefault = () => {
        setFilterPrefs(defaultFilter);
    };

    // Sets each of the ECF columns to either True or False, depending on the showHide text value
    const setColumnVisibility = (columns, bVisible) => {
        let existingPrefs = JSON.parse(hiddenColumnPrefs);
        columns.forEach((colName) => (existingPrefs[colName] = bVisible));
        setHiddenColumnPrefs(JSON.stringify(existingPrefs));
    };

    const showAllColumns = () => {
        setHiddenColumnPrefs('{}');
    };

    // Based on the showHide text value, calls the setColumnVisibility with the list of columns to be set to true/false (each), and inverts the value of showHide afterwards
    const showHideECFColumns = () => {
        if (showHideECFColumnsButtonText === 'Show ECF Columns') {
            setColumnVisibility(['filingForECF1', 'filingForECF2', 'filingForECF3'], true);
            setShowHideECFColumnsButtonText('Hide ECF Columns');
        } else {
            setColumnVisibility(['filingForECF1', 'filingForECF2', 'filingForECF3'], false);
            setShowHideECFColumnsButtonText('Show ECF Columns');
        }
    };

    const toggleRowHeight = () => {
        setRowHeightPref(rowHeightPref === 'fixed' ? 'auto' : 'fixed');
        // sessionStorage.setItem(APPLICANT_ROWHEIGHT_SS_NAME, rowHeightPref === 'fixed' ? 'auto' : 'fixed');
    };

    const processRowUpdate = React.useCallback(
        async (newRow, oldRow) => {
            // console.log('processRowUpdate | oldRow:', oldRow);
            // console.log('processRowUpdate | newRow:', newRow);
            if (oldRow.form470AFYstatusId === undefined) {
                newRow.isNew = true;
                if (newRow.form470AFYstatusId === undefined) {
                    newRow.form470AFYstatusId = 1;
                }
            } else {
                newRow.isNew = false;
            }

            let ueData = {
                client_id: newRow.client_id,
                funding_year: newRow.fundingYear,
                isNew: newRow.isNew, //isNew: false
                expected_number_of_form470s:
                    newRow.expectedNumberOfForm470s === null || newRow.expectedNumberOfForm470s < -1
                        ? -1
                        : newRow.expectedNumberOfForm470s,
                // form470_will_not_be_filed: newRow.expectedNumberOfForm470s === 0 ? true : false,
                // form471_will_not_be_filed: newRow.form471WillNotBeFiled,
                expected_number_of_form471s:
                    newRow.expectedNumberOfForm471s === null || newRow.expectedNumberOfForm471s < -1
                        ? -1
                        : newRow.expectedNumberOfForm471s,
                form471_will_not_be_filed:
                    newRow.expectedNumberOfForm471s === null || newRow.expectedNumberOfForm471s !== 0 ? false : true,
                form470_afystatus_id: newRow.form470AFYstatusId,
                form470_initialoutreachdate: newRow.form470InitialOutreachDate, // This should be a string in the format "yyyy-mm-dd".
                expected_number_of_minibids: newRow.expectedNumberOfMinibids,
                form470_tracker_notes: newRow.form470TrackerNotes,
                form471_tracker_notes: newRow.form471TrackerNotes,
                // applicantNotes: newRow.applicantNotes,
            };

            // console.log('processRowUpdate | uedata = ', ueData);

            let apiResponse = await trackersAPI.SaveForm471TrackerApplicantsReportUED(ueData);
            console.log('processRowUpdate | apiResponse = ', apiResponse);

            if (apiResponse === false) {
                console.error('Unknown error while saving.');
                toast.error('Unknown error while saving.');
                return Promise.reject(new Error('Unknown error while saving.'));
            }

            if (apiResponse.error === true) {
                console.error(apiResponse.errorMessage || 'Error while saving.');
                toast.error('Error while saving.');
                return Promise.reject(new Error(apiResponse.errorMessage || 'Error while saving.'));
            }

            // newRow.applicantNotes = apiResponse.applicantNotes; // This ensures that we're displaying what we saved.

            // console.log('processRowUpdate | apiResponse.record = ', apiResponse.record);

            let savedRowData = {
                // To display what we saved
                ...apiResponse.record,
                isNew: false,
            };
            if (apiResponse.error === false) {
                toast.success(
                    `FY ${newRow.fundingYear} record for '${newRow.clientName}' has been successfully updated.`,
                    {
                        autoClose: 3000,
                    }
                );

                setPruUpdatedRow(apiResponse.record);
                return savedRowData;
            }
        },
        [trackersAPI]
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.error('error.message = ', error.message);
        toast.error('Error saving data: ' + error.message);
    }, []);

    //============================================================
    //      DataGrid columns
    //============================================================
    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    // const valueGetter_BENs = (params) => {
    //     if (!params.row.bens) {
    //         return '';
    //     }
    //     return params.row.bens.join(', ');
    // };

    const createBENsLinks = (bens, clientid) => {
        if (!bens || bens.length === 0) {
            return bens;
        }

        const createLink = (ben, clientid) => {
            return (
                <Link
                    title='Client Details'
                    key={clientid + '-' + ben}
                    href={'/client-list/details/' + clientid}
                    target='_blank'
                    rel='noopener'
                >
                    {ben}
                </Link>
            );
        };

        return bens.map((ben) => createLink(ben, clientid)).reduce((prev, curr) => [prev, <span>,&nbsp;</span>, curr]);
    };

    const valueGetter_ApplicantTypes = (params) => {
        if (!params.row.applicantTypes || params.row.applicantTypes.length === 0) {
            return '';
        }
        let ary = params.row.applicantTypes.map((at) => {
            return at;
        });
        return ary.join(', ');
    };

    const valueGetter_PrimaryContactName = (params) => {
        return getUserDisplayName(params.row.primaryContact);
    };

    const valueGetter_SecondaryContactNames = (params) => {
        if (!params.row.secondaryContacts || params.row.secondaryContacts.length === 0) {
            return '';
        }
        let ary = params.row.secondaryContacts.map((sc) => {
            return getUserDisplayName(sc);
        });
        return ary.join(', ');
    };

    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    // Potential
    const valueGetter_ApplicantCohortNames = (params) => {
        if (!params.row.applicantCohorts || params.row.applicantCohorts.length === 0) {
            return '';
        }
        return params.row.applicantCohorts
            .map((ac) => {
                return ac.applicantcohort.applicantcohort_name;
            })
            .join(', ');
    };

    const createApplicantCohortChips = (applicantCohorts, rowId) => {
        return applicantCohorts.map((ac) => {
            return (
                <Chip
                    key={rowId + '-' + ac.applicantcohort_id}
                    label={ac.applicantcohort.applicantcohort_name}
                    sx={{ marginRight: '4px' }}
                />
            );
        });
    };

    const createOneUserChip = (user, rowid, keyprefix) => {
        if (!user) {
            return '';
        }
        let chipkey = (keyprefix ? keyprefix + '-' : '') + rowid + '-' + user.first_name + user.last_name;
        let chiplabel = user.first_name + ' ' + user.last_name;
        return <Chip key={chipkey} label={chiplabel} />;
    };

    const createUserChips = (userlist, rowid, keyprefix) => {
        return userlist.map((u) => {
            let chipkey = (keyprefix ? keyprefix + '-' : '') + rowid + '-' + u.first_name + u.last_name;
            let chiplabel = u.first_name + ' ' + u.last_name;
            return <Chip key={chipkey} label={chiplabel} sx={{ marginRight: '2px' }} />;
        });
    };

    const renderCell_FormData = (
        fundingYear,
        ben,
        form470Count,
        form470InfoText,
        cellText,
        formType,
        usacAppStatus
    ) => {
        if (form470Count !== null) {
            if (form470Count > 0) {
                if (typeof cellText === 'string' || cellText instanceof String) {
                    return (
                        <>
                            {cellText}
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        {form470InfoText} <br />
                                        Exclude applications marked as 'Discarded Application' or 'ERC not Responsible
                                        for Filing'
                                    </span>
                                }
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <InfoOutlinedIcon color='info' fontSize='small' />
                                {/* <IconButton size='small'>
                                    <InfoOutlinedIcon color='info' fontSize='small' />
                                </IconButton> */}
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title='Open in new tab' placement='top'>
                                <Link
                                    href={`/${formType}/${formType}-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=${usacAppStatus}&applicationType=Regular`}
                                    target='_blank'
                                    rel='noreferrer'
                                    underline='hover'
                                    // style={{ textDecoration: 'none' }}
                                >
                                    {cellText}
                                </Link>
                            </Tooltip>
                            {/* {cellText} */}
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                        {form470InfoText} <br />
                                        Excludes applications marked as 'Discarded Application' or 'ERC not Responsible
                                        for Filing'
                                    </span>
                                }
                                sx={{ marginLeft: '0.5em' }}
                                placement='top-start'
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 900 }}
                            >
                                <InfoOutlinedIcon color='info' fontSize='small' />
                                {/* <IconButton size='small'>
                                    <InfoOutlinedIcon color='info' fontSize='small' />
                                </IconButton> */}
                            </Tooltip>
                        </>
                    );
                }
            }
            return '-';
        }
        return '';
    };

    const renderCell_DraftForms = (fundingYear, ben, count, formType) => {
        return (
            <>
                {/* {count} */}
                <Tooltip title='Open in new tab' placement='top'>
                    <Link
                        href={`/${formType}/${formType}-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=1&applicationType=Regular`}
                        target='_blank'
                        rel='noreferrer'
                        underline='hover'
                        // style={{ textDecoration: 'none' }}
                    >
                        {count}
                    </Link>
                </Tooltip>
                <Tooltip
                    title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                            Excludes applications marked as 'Discarded Application' or 'ERC not Responsible for Filing'
                        </span>
                    }
                    sx={{ marginLeft: '0.5em' }}
                    placement='top-start'
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 900 }}
                >
                    <InfoOutlinedIcon color='info' fontSize='small' />
                    {/* <IconButton size='small'>
                        <InfoOutlinedIcon color='info' fontSize='small' />
                    </IconButton> */}
                </Tooltip>
            </>
            // <>
            //     <Tooltip title="Excludes 'Discarded' and 'ERC not Responsible for filing' applications"
            //         // sx={{ marginLeft: '0.5em' }}
            //         placement='top-start'
            //         arrow
            //         TransitionComponent={Fade}
            //         TransitionProps={{ timeout: 900 }}
            //     >
            //         <IconButton size="small"><span style={{ fontSize: '0.8em' }}>{count}</span></IconButton>
            //     </Tooltip>
            // </>
        );
    };

    const renderCell_MinibidData = (fundingYear, ben, count) => {
        return (
            <Tooltip title='Open in new tab' placement='top'>
                <Link
                    href={`/f470/f470-applications?ben=${ben}&fundingYear=${fundingYear}&usacAppStatus=2&applicationType=Minibid`}
                    target='_blank'
                    rel='noreferrer'
                    underline='hover'
                    // style={{ textDecoration: 'none' }}
                >
                    {count}
                </Link>
            </Tooltip>
        );
    };

    function getForm470StatusText(id) {
        // console.log('id = ', id);
        if (id === 0) {
            return '-';
        }
        // if (id === 7) { return 'id=7 text';}
        let afyText = afyStatusEditOptions.map((row) => {
            if (id === row.value) {
                return row.text;
            } else {
                return '-';
            }
        });
        return afyText;
    }

    // Converts the status options into [{ value: 'X', label: 'Y' }]
    const form470Status_DataGridFilterOptions = afyStatusEditOptions.map((opt) => {
        // console.log('form470Status_DataGridFilterOptions | afyStatusEditOptions = ', afyStatusEditOptions)
        // if (opt.text === '<not set>') {
        if (opt.text === '') {
            return { value: '', label: '<no status>' };
        } else {
            return { value: opt.text, label: opt.text };
        }
    });

    const local_t_and_tz_iso8601string = new Date().toISOString().substring(10);

    // Gets a "yyyy-mm-dd" string from a javascript date.
    const get_YMDString_from_JavascriptDate = (jsdate) => {
        //console.log('jsdate = ', jsdate);
        if (!jsdate) {
            return null;
        }
        if (!(jsdate instanceof Date)) {
            return null;
        }
        let offset = jsdate.getTimezoneOffset();
        let jsdate2 = new Date(jsdate.getTime() - offset * 60 * 1000); // remove offset
        let yyyymmddstring = jsdate2.toISOString().substring(0, 10); // or jsdate2.toISOString().split('T')[0];
        //console.log('yyyymmddstring = ', yyyymmddstring);
        return yyyymmddstring;
    };

    const renderDateTime2 = (jsDate, displayTime) => {
        // Version 2. Input is a JavaScript Date object.
        // Returns a React node (when there is data).
        if (!jsDate) {
            return null;
        }
        try {
            let text;
            if (!displayTime) {
                text = new Intl.DateTimeFormat('en-US').format(jsDate); // "12/20/2020"
            } else {
                let dtfoptions = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                };
                text = new Intl.DateTimeFormat('en-US', dtfoptions).format(jsDate); // "10/26/1985, 1:21 AM"
            }
            let ttoptions = {
                weekday: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short',
            };
            let titletext = new Intl.DateTimeFormat('en-US', ttoptions).format(jsDate);

            return <span title={titletext}>{text}</span>;
        } catch (err) {
            console.error('renderDateTime2 error:', err);
        }
        // If above failed then output something reasonable.
        return jsDate.toLocaleString();
    };

    const benArraySortComparator = (v1, v2) => {
        // v1 and v2 are arrays of 0 or more BENs.
        // console.log('benArraySortComparator | v1 = ', v1, '| v2 = ', v2);
        let v1Desc = v1.sort((a, b) => b - a);
        let v2Desc = v2.sort((a, b) => b - a);
        // console.log('benArraySortComparator | v1Desc = ', v1Desc, '| v2Desc = ', v2Desc);
        // let ben1 = v1.length > 0 ? v1[0] : 0;
        // let ben2 = v2.length > 0 ? v2[0] : 0;
        let ben1 = v1Desc.length > 0 ? v1Desc[0] : 0;
        let ben2 = v2Desc.length > 0 ? v2Desc[0] : 0;
        // console.log('benArraySortComparator | ben1 = ', ben1, '| ben2 = ', ben2);
        return ben1 - ben2;
    };

    const dynamicColumnWidthWrapper = (original_cols, changing_cols) => {
        return original_cols.map((col) => ({
            ...col,
            width: changing_cols[col.field] || col.width,
        }));
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSaveIconClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelIconClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const originalColumns = [
        {
            // Actions (Details and Edit page links)
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            getActions: (params) => {
                // params = GridRowParams (columns, id, row)
                // console.log('[originalColumns][getActions] params:', params);
                // console.log('[originalColumns][getActions] rowModesModel:', rowModesModel);

                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <Tooltip title='Save' key={`save-${params.id}`}>
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label='Save'
                                onClick={handleSaveIconClick(params.id)}
                                // color='inherit'
                                style={{ color: '#4CAF50' }}
                            />
                        </Tooltip>,
                        <Tooltip title='Cancel' key={`cancel-${params.id}`}>
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label='Cancel'
                                onClick={handleCancelIconClick(params.id)}
                                // color='inherit'
                                style={{ color: 'red' }}
                            />
                        </Tooltip>,
                    ];
                }

                return [
                    <Tooltip title='Edit' key={`edit-${params.id}`}>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            onClick={handleEditClick(params.id)}
                            label='Edit'
                            color='primary' // primary makes the pencil icon blue instead of grey. Use inherit for black
                            aria-label='edit'
                        />
                    </Tooltip>,
                ];
            },
            filterable: false,
            sortable: false,
            width: 100,
        },
        {
            headerName: 'BEN(s)',
            field: 'bens',
            valueGetter: (params) => params.row.bens,
            renderCell: (params) => createBENsLinks(params.row.bens, params.row.client_id),
            valueFormatter: (params) => {
                if (!params.value || params.value.length === 0) {
                    return '';
                }
                return params.value.join(', ');
            },
            sortComparator: benArraySortComparator,
            width: 100,
        },
        {
            headerName: 'Applicant Name',
            field: 'clientName',
            width: 200,
        },
        {
            headerName: 'Is a Client',
            field: 'isAClient',
            type: 'boolean',
            renderCell: (params) => <>{params.value === true ? 'Yes' : params.value === false ? 'No' : ''}</>,
            width: 80,
        },
        {
            headerName: 'Applicant Type(s)',
            field: 'applicantTypes',
            valueGetter: valueGetter_ApplicantTypes,
            width: 130,
        },
        {
            headerName: 'State',
            field: 'clientState',
            width: 50,
        },
        {
            headerName: 'Applicant Cohorts',
            field: 'applicantCohorts',
            valueGetter: valueGetter_ApplicantCohortNames,
            renderCell: (params) => createApplicantCohortChips(params.row.applicantCohorts, params.row.client_id),
            width: 190,
        },
        {
            headerName: 'Primary Contact',
            field: 'primaryContact',
            valueGetter: valueGetter_PrimaryContactName,
            renderCell: (params) => createOneUserChip(params.row.primaryContact, params.row.client_id, 'pc'),
            width: 150,
        },
        {
            headerName: 'Secondary Contacts',
            field: 'secondaryContacts',
            valueGetter: valueGetter_SecondaryContactNames,
            renderCell: (params) => createUserChips(params.row.secondaryContacts, params.row.client_id, 'sc'),
            width: 190,
        },
        {
            headerName: 'Funding Year',
            field: 'fundingYear',
            type: 'number', // Sort as a number..
            valueFormatter: ({ value }) => value, // ..and display without commas.
            headerAlign: 'left',
            renderHeader: (params) => renderMultilineHeader(params, 'Funding', 'Year'),
            width: 70,
        },
        {
            headerName: 'ECF 1',
            field: 'filingForECF1',
            width: 80,
        },
        {
            headerName: 'ECF 2',
            field: 'filingForECF2',
            width: 80,
        },
        {
            headerName: 'ECF 3',
            field: 'filingForECF3',
            width: 80,
        },
        // Potential - use column names provided to ColumnsV2 via editOptionsMapping dictionary
        {
            headerName: 'Cyber Pilot',
            field: 'cybersecuritypilot_participation',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Cyber
                    <br />
                    Pilot
                </Box>
            ),
            width: 80,
        },
        // {
        //     headerName: 'Not Filing Form 470',
        //     field: 'form470WillNotBeFiled',
        //     type: 'boolean',
        //     headerAlign: 'left',
        //     renderHeader: (params) => renderMultilineHeader(params, 'Not Filing', 'Form 470'),
        //     renderCell: (params) => <>{params.value === true ? 'X' : params.value === false ? '' : '-'}</>,
        //     width: 100,
        // },
        {
            headerName: 'Expected # of Forms470',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Forms 470'),
            field: 'expectedNumberOfForm470s',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'number', // Sort as a number..
            valueFormatter: (params) => {
                if (params.value < 0) {
                    return 'Client Not Contacted';
                }
                return params.value;
            },
            cellClassName: (params) =>
                params.value < 0 ? 'ExpectedNumberOfForm470s-false centered-cell' : 'centered-cell',
            width: 120,
        },
        {
            headerName: 'Draft # of Forms 470',
            field: 'form470DraftCount',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Draft # of', 'Forms 470'),
            renderCell: (params) => {
                if (params.value === null) return '';
                // console.log('[form470DraftCount]  params = ', params, ' | draft params.value = ', params.value);
                return params.value > 0
                    ? renderCell_DraftForms(params.row.fundingYear, params.row.bens[0], params.value, 'f470')
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Certified Form 470 Count',
            field: 'form470Count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 470', 'Count'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.fundingYear,
                    params.row.bens[0],
                    params.row.form470Count,
                    params.row.form470InfoText,
                    params.value,
                    'f470',
                    2
                ),
            width: 90,
        },
        {
            headerName: 'Certified Form 470 Categories',
            field: 'form470Categories',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 470', 'Categories'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.fundingYear,
                    params.row.bens[0],
                    params.row.form470Count,
                    params.row.form470InfoText,
                    params.value,
                    'f470',
                    2
                ),
            width: 90,
        },
        {
            headerName: 'Expected # of Mini-Bids',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Mini-Bids'),
            field: 'expectedNumberOfMinibids',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'number', // Sort as a number..
            // valueFormatter: (params) => params.value,
            width: 100,
        },
        {
            headerName: 'Created # of Mini-Bids',
            field: 'minibidCount',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Created # of', 'Mini-Bids'),
            renderCell: (params) => {
                if (params.value === null) return '';
                return params.value > 0
                    ? renderCell_MinibidData(params.row.fundingYear, params.row.bens[0], params.value)
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Form 470 Status (per-Applicant, per-FY)',
            renderHeader: (params) => renderMultilineHeader(params, 'Form 470 Status', '(per-Applicant, per-FY)'),
            // field: 'form470AFYstatusId',
            field: 'form470AFYstatusText',
            valueGetter: ({ value }) => (value === 0 ? '-' : value),
            // valueGetter: ({ value }) => { console.log('F470Status valueGetter | value = ', value); return afyStatusEditOptions.find((item) => item.value === value).text },
            // valueGetter: ({ value }) => { return afyStatusEditOptions.find((item) => item.value === value).text },
            // valueGetter: ({ value }) => ( value === 0 ? '-' : afyStatusEditOptions.find((item) => item.value === value).text ),

            editable: true,
            renderEditCell: (params) => {
                // console.log("f470Status renderEditCell | STATUS PARAMS:", params);
                // console.log('f470Status renderEditCell | params.row.form470AFYstatusId = ', params.row.form470AFYstatusId);
                // console.log('f470Status renderEditCell | afyStatusEditOptions = ', afyStatusEditOptions);
                let selectedid = params.row.form470AFYstatusId || 0;
                // console.log('f470Status renderEditCell | selectedid = ', selectedid);
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid} // integer representing the status text
                        field={params.field} // field name header
                        dropDownOptions={afyStatusEditOptions} // list of status texts
                    />
                );
            },
            valueSetter: (params) => {
                // console.log('f470Status valueSetter |  params.value = ', params.value);
                if (Number.isInteger(params.value)) {
                    // console.log('f470Status valueSetter |  ' + params.value + ' is an integer.')
                    const form470AFYstatusId = params.value;
                    return { ...params.row, form470AFYstatusId };
                }
                return { ...params.row };
            },
            type: 'singleSelect', // For DataGrid Filter.
            valueOptions: form470Status_DataGridFilterOptions, // For DataGrid Filter.
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'centered-cell',
            width: 200,
        },
        {
            headerName: 'Form 470 Initial Outreach Date',
            renderHeader: (params) => renderMultilineHeader(params, 'Form 470', 'Initial', 'Outreach Date'),
            field: 'form470InitialOutreachDate',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Input is a string in the format "yyyy-mm-dd".
                // Convert it to a javascript Date object and set it the client's local time and timezone.
                // Otherwise Date assumes "T00:00:00.000Z" which causes an off-by-one-day issue when Datagrid displays it.
                // Needs to be a javascript Date object for filtering and sorting.
                // return new Date(params.value + local_t_and_tz_iso8601string);
                return new Date(
                    new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
                ); //DB date -> JS Date conversion: adjust UTC by local timezone offset to be shown properly in DataGrid
            },
            valueSetter: (params) => {
                // console.log('[form470_initialoutreachdate  valueSetter]  params.value = ', params.value);
                // console.log('[form470_initialoutreachdate  valueSetter]  typeof params.value = ', (typeof params.value));
                // console.log('[form470_initialoutreachdate  valueSetter]  params = ', params);
                if (params.value === null) {
                    return { ...params.row, form470InitialOutreachDate: null };
                }
                if (params.value instanceof Date) {
                    // Convert javascript Date back into "yyy-mm-dd" string.
                    // Example:  Thu Jan 02 2003 00:00:00 GMT-0500 (Eastern Standard Time)
                    // let yyyymmddstring = get_YMDString_from_JavascriptDate(params.value);
                    // return { ...params.row, form470InitialOutreachDate: yyyymmddstring };
                    // let dateToDB = new Date(new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
                    // console.log('dateToDB = ', dateToDB, '| yyyymmddstring = ', yyyymmddstring);
                    return {
                        ...params.row,
                        form470InitialOutreachDate: new Date(
                            new Date(params.value).getTime() - new Date(params.value).getTimezoneOffset() * 60 * 1000
                        )
                            .toISOString()
                            .split('T')[0],
                    }; //JS Date -> DB date: adjust UTC by local timezone offset to be saved properly to DB
                }
                return { ...params.row };
            },
            cellClassName: 'centered-cell',
            width: 110,
        },
        {
            headerName: 'Form 470 Notes',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'form470TrackerNotes',
            // OLD RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => {
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={rowHeightPref}
                        onEdit={() =>
                            handleEditNotesEvent(
                                params.id,
                                params.field,
                                params.value,
                                params.row.bens,
                                params.row.clientName
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            editable: false, // Saved to ClientFundingYear.Form470TrackerNotes by client_id and fundingYear.
            renderEditCell: (params) => {
                return <InlineEditTextarea1 {...params} />;
            },
            minWidth: 350,
            flex: 1,
        },
        // {
        //     headerName: 'Not Filing Form 471',
        //     renderHeader: (params) => renderMultilineHeader(params, 'Not Filing', 'Form 471'),
        //     field: 'form471WillNotBeFiled',
        //     headerAlign: 'center',
        //     headerClassName: 'Editable-NotRequired',
        //     type: 'boolean',
        //     editable: true,
        //     renderCell: (params) => <>{params.value === true ? 'X' : params.value === false ? '' : '-'}</>,
        //     cellClassName: 'centered-cell',
        //     width: 80,
        // },
        // ---------------------------------------------------------------------------------------------------------
        {
            headerName: 'Expected # of Forms471',
            renderHeader: (params) => renderMultilineHeader(params, 'Expected # of', 'Forms 471'),
            field: 'expectedNumberOfForm471s',
            headerAlign: 'center',
            headerClassName: 'Editable-NotRequired',
            editable: true,
            type: 'number', // Sort as a number..
            valueFormatter: (params) => {
                if (params.value < 0) {
                    return 'Client Not Contacted';
                }
                return params.value;
            },
            cellClassName: (params) =>
                params.value < 0 ? 'ExpectedNumberOfForm471s-false centered-cell' : 'centered-cell',
            width: 120,
        },
        {
            headerName: 'Draft # of Forms 471',
            field: 'form471DraftCount',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Draft # of', 'Forms 471'),
            renderCell: (params) => {
                if (params.value === null) return '';
                // console.log('[form470DraftCount]  params = ', params, ' | draft params.value = ', params.value);
                return params.value > 0
                    ? renderCell_DraftForms(params.row.fundingYear, params.row.bens[0], params.value, 'f471')
                    : '-';
            },
            width: 90,
        },
        {
            headerName: 'Certified Form 471 Count',
            field: 'form471Count',
            type: 'number',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 471', 'Count'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.fundingYear,
                    params.row.bens[0],
                    params.row.form471Count,
                    params.row.form471InfoText,
                    params.value,
                    'f471',
                    23
                ),
            width: 90,
        },
        {
            headerName: 'Certified Form 471 Categories',
            field: 'form471Categories',
            headerAlign: 'center',
            align: 'right',
            renderHeader: (params) => renderMultilineHeader(params, 'Certified', 'Form 471', 'Categories'),
            renderCell: (params) =>
                renderCell_FormData(
                    params.row.fundingYear,
                    params.row.bens[0],
                    params.row.form471Count,
                    params.row.form471InfoText,
                    params.value,
                    'f471',
                    23
                ),
            width: 90,
        },

        // ---------------------------------------------------------------------------------------------------------

        {
            headerName: 'Form 471 Notes',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'form471TrackerNotes',
            // OLD RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => {
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={rowHeightPref}
                        onEdit={() =>
                            handleEditNotesEvent(
                                params.id,
                                params.field,
                                params.value,
                                params.row.bens,
                                params.row.clientName
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            editable: false, // Saved to ClientFundingYear.Form471TrackerNotes by client_id and fundingYear.
            renderEditCell: (params) => {
                return <InlineEditTextarea1 {...params} />;
            },
            minWidth: 350,
            flex: 1,
        },
        {
            headerName: 'Last Updated By',
            field: 'userEnteredFieldUpdatedByUserName',
            headerAlign: 'center',
            cellClassName: 'centered-cell',
            width: 150,
        },
        {
            headerName: 'Last Modified On',
            field: 'userEnteredFieldUpdatedTimestamp',
            headerAlign: 'center',
            type: 'dateTime',
            // valueGetter: ({ value }) => value && new Date(value),
            // valueGetter: (params) => {
            //     if (!params.value) {
            //         return null;
            //     }
            //     // Input is a string in the format "yyyy-mm-dd".
            //     // Convert it to a javascript Date object and set it the client's local time and timezone.
            //     // Otherwise Date assumes "T00:00:00.000Z" which causes an off-by-one-day issue when Datagrid displays it.
            //     // Needs to be a javascript Date object for filtering and sorting.
            //     // return new Date(params.value + local_t_and_tz_iso8601string);
            //     return new Date(
            //         new Date(params.value).getTime() + new Date(params.value).getTimezoneOffset() * 60 * 1000
            //     ); //DB date -> JS Date conversion: adjust UTC by local timezone offset to be shown properly in DataGrid
            // },
            valueGetter: (params) => {
                // Input example: "2024-01-12T15:32:20.080060+00:00" or null
                if (!params.row.userEnteredFieldUpdatedTimestamp) {
                    return null;
                }
                return new Date(params.row.userEnteredFieldUpdatedTimestamp);
            },
            renderCell: (params) => {
                //console.log('<Last Updated by User, renderCell> params.row.form470AppNum = ' + params.row.form470AppNum);
                //console.log('<Last Updated by User, renderCell> params.row.form470app.user_entered_field_updated_timestamp = ' + params.row.form470app.user_entered_field_updated_timestamp);
                // params.value is from valueGetter and is expected to be either null or a JavaScript Date object.
                if (params.value === null) {
                    return null;
                }
                return renderDateTime2(params.value, true);
            },
            cellClassName: 'centered-cell',
            width: 200,
        },
    ];

    const ecfMSFilterOptions = [
        { text: 'Yes', value: 0 },
        { text: 'No', value: 1 },
    ];

    // Potential - These edit options are fed into columnsV2, which iteslef is passed as the rows of the datagrid. I.e., getOptionsForFiltering() is one area where you added new columns to be displayed
    const editOptions = {
        applicantCohortMSFilterOptions,
        applicantNameMSFilterOptions,
        applicantTypeMSFilterOptions,
        ecfMSFilterOptions,
        form470AFYStatusesMSFilterOptions,
        primaryContactMSFilterOptions,
        secondaryContactMSFilterOptions,
        cyberSecurityPilotParticipationOptions,
        stateMSFilterOptions,
        lastUpdatedByMSFilterOptions,
    };

    const editOptionsMapping = {
        applicantCohorts: 'applicantCohortMSFilterOptions',
        applicantTypes: 'applicantTypeMSFilterOptions',
        filingForECF1: 'ecfMSFilterOptions',
        filingForECF2: 'ecfMSFilterOptions',
        filingForECF3: 'ecfMSFilterOptions',
        form470AFYstatusText: 'form470AFYStatusesMSFilterOptions',
        clientName: 'applicantNameMSFilterOptions',
        primaryContact: 'primaryContactMSFilterOptions',
        secondaryContacts: 'secondaryContactMSFilterOptions',
        cyberSecurityPilotParticipation: 'cyberSecurityPilotParticipationOptions',
        clientState: 'stateMSFilterOptions',
        userEnteredFieldUpdatedByUserName: 'lastUpdatedByMSFilterOptions',
    };

    const columnsV2 = React.useCallback(() => {
        // let col_array = dynamicColumnWidthWrapper(
        //     doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns)),
        //     currentColumnWidths
        // );
        let col_array = dynamicColumnWidthWrapper(
            // Uncomment for column-to-column filter comparisons
            // IsBeforeXDaysWrapper(
            //     IsAfterXDaysWrapper(
            //         IsNotAnyOfWrapper(
            //             IsAnyOfWrapper(
            //                 IsLessThanOrEqualToColumnWrapper(
            //                     IsLessThanColumnWrapper(
            //                         IsGreaterThanOrEqualToColumnWrapper(
            //                             IsGreaterThanColumnWrapper(
            //                                 IsNotEqualToColumnWrapper(
            //                                     IsEqualToColumnWrapper(
            //                                         doesNotContainWrapper(
            //                                             removeAnyBooleanFilterValueWrapper(originalColumns)
            //                                         )
            //                                     )
            //                                 )
            //                             )
            //                         )
            //                     )
            //                 ),
            //                 editOptions,
            //                 editOptionsMapping
            //             ),
            //             editOptions,
            //             editOptionsMapping
            //         )
            //     )
            // ),

            // IsNotAnyOfWrapper(
            //     IsAnyOfWrapper(
            //         doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns)),
            //         editOptions,
            //         editOptionsMapping
            //     ),
            //     editOptions,
            //     editOptionsMapping
            // ),

            IsNotAnyOfWrapper(
                IsAnyOfWrapper(
                    IsLessThanOrEqualToColumnWrapper(
                        IsLessThanColumnWrapper(
                            IsGreaterThanOrEqualToColumnWrapper(
                                IsGreaterThanColumnWrapper(
                                    IsNotEqualToColumnWrapper(
                                        IsEqualToColumnWrapper(
                                            doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns))
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    editOptions,
                    editOptionsMapping
                ),
                editOptions,
                editOptionsMapping
            ),
            currentColumnWidths
        );
        let col_order = currentColumnOrder;

        if (col_order) {
            let sorted_cols = col_array.sort((a, b) => {
                let indexA = col_order.findIndex((element) => element === a.field);
                let indexB = col_order.findIndex((element) => element === b.field);
                return indexA - indexB;
            });
            return sorted_cols;
        } else {
            return col_array;
        }
    }, [
        applicantCohortMSFilterOptions,
        applicantNameMSFilterOptions,
        applicantTypeMSFilterOptions,
        ecfMSFilterOptions,
        form470AFYStatusesMSFilterOptions,
        primaryContactMSFilterOptions,
        secondaryContactMSFilterOptions,
        cyberSecurityPilotParticipationOptions,
        stateMSFilterOptions,
        lastUpdatedByMSFilterOptions,
        editDropdownOptionsRendered,
        currentColumnWidths,
        currentColumnOrder,
    ]);

    //============================================================

    // const getRowClassName = (row) => {
    //     if (row.form470WillNotBeFiled === false) {
    //         return 'NotFiling470-false';
    //     } else if (row.form471WillNotBeFiled === false) {
    //         return 'NotFiling471-false';
    //     } else {
    //         return '';
    //     }
    // };

    if (reportData === undefined) {
        return <h3>ERROR retrieving data.</h3>;
    }

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <Box sx={{ paddingBottom: '4px' }}>
                {/* <Button
                    variant='contained'
                    size='small'
                    disabled={selectionModel.length < 2}
                    color='info'
                    sx={{ marginRight: '1em' }}
                    // sx={{ marginRight: '1em', backgroundColor: '#4CAF50' }}
                    onClick={() => setBulkEditButtonClicked(true)}
                >
                    {selectionModel.length > 0 ? `Bulk Edit (${selectionModel.length})` : 'Bulk Edit'}
                </Button> */}
                <Button
                    variant='contained'
                    size='small'
                    style={{ marginRight: '1em', backgroundColor: '#4CAF50', color: 'white' }}
                    onClick={() => saveViewButtonClicked()}
                >
                    {selectedViewName ? 'Update View' : 'Save View'}
                </Button>
                <Button variant='contained' size='small' onClick={() => showHideECFColumns()}>
                    {showHideECFColumnsButtonText}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => autoFixedRowHeight()}
                    sx={{ marginLeft: '1em' }}
                >
                    {autoFixedColumnsButtonText}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => resetColumnOrderToDefault()}
                    sx={{ marginLeft: '1em' }}
                >
                    Reset Column Order
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => resetColumnVisibilityToDefault()}
                    sx={{ marginLeft: '1em' }}
                >
                    Reset Column Visibility
                </Button>
                <Button variant='contained' size='small' onClick={() => showAllColumns()} sx={{ marginLeft: '1em' }}>
                    Show All Columns
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => resetFiltersToDefault()}
                    sx={{ marginLeft: '1em' }}
                >
                    Clear all Filters
                </Button>
                {usedCacheOnLoad && (
                    <>
                        <CircularProgress sx={{ marginLeft: '25px' }} size='.75rem' />
                        <Typography variant='span' sx={{ color: 'gray', marginLeft: '10px' }}>
                            <em>Most recent data loading - Inline editing temporarily disabled</em>
                        </Typography>
                    </>
                )}
            </Box>
            <DataGridPro
                // checkboxSelection
                // selectionModel={selectionModel}
                // setSelectionModel={setSelectionModel}
                className='trackerReportDataGrid'
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                columns={columnsV2()}
                rows={reportData}
                getRowId={(row) => row.client_id}
                loading={dataIsLoading && reportData.length === 0}
                components={{
                    // Toolbar: CustomDatagridToolbar, // Point to ARCL because of saved views. ARCL because it's not just the CL using it anymore
                    Toolbar: CustomDatagridToolbarARCL,
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            display='flex'
                            justifyContent='flex-start'
                            flexDirection='column'
                            alignItems='center'
                            paddingTop='5%'
                        >
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Your search returned no records.</div>
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Please adjust the Search criteria.</div>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            display='flex'
                            justifyContent='flex-start'
                            flexDirection='column'
                            alignItems='center'
                            paddingTop='5%'
                        >
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>No records matched your filters.</div>
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>Please adjust the DataGrid filters.</div>
                        </Stack>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                        appliesTo,
                        currentSavedViews,
                        editingViewName,
                        handleDeleteSavedView,
                        handleDensityChange,
                        handleEditSavedViewName,
                        handleSelectSavedView,
                        resetViewToDefaults,
                        selectedViewName,
                        setEditingViewName,
                        userID,
                    },
                }}
                editMode='row' // Only one editable field. Set to "row" to be consistent with other tracker DataGrids.
                isCellEditable={() => !usedCacheOnLoad}
                disableSelectionOnClick
                density={densityPref}
                getRowHeight={getRowHeight}
                // getRowClassName={(params) => getRowClassName(params.row)} // determines which css class to apply
                pagination
                rowsPerPageOptions={[25, 50, 100, 250, 500, 1000, 2000]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                filterModel={JSON.parse(filterPrefs)}
                onFilterModelChange={async (newFilterModel) => await handleOnFilterModelChange(newFilterModel)}
                sortModel={JSON.parse(sortPrefs)}
                onSortModelChange={(newSortModel) => handleOnSortModelChange(newSortModel)}
                columnVisibilityModel={JSON.parse(hiddenColumnPrefs)}
                onColumnWidthChange={handleOnColumnWidthChange}
                onColumnVisibilityModelChange={(newVizModel) => handleOnColumnVisibilityModelChange(newVizModel)}
                onColumnOrderChange={handleOnColumnOrderChangeV2}
                pinnedColumns={JSON.parse(pinnedColumnPrefs)}
                onPinnedColumnsChange={(newPinnedModel) => handlePinnedPrefs(newPinnedModel)}
                apiRef={apiRef}
                sx={{
                    minHeight: '300px',
                    height: '74vh',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: '1px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '10px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                        py: '20px',
                    },
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell': {
                        border: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                        backgroundColor: '#fdfffe',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n)': {
                        backgroundColor: '#f0f2f4',
                    },
                    '& .MuiDataGrid-row.Form471-Certified:nth-of-type(2n+1), .MuiDataGrid-row.Form471-Committed:nth-of-type(2n+1)':
                        {
                            backgroundColor: 'hsl(103, 47%, 90%)',
                        },
                    '& .MuiDataGrid-row.Form471-Certified:nth-of-type(2n), .MuiDataGrid-row.Form471-Committed:nth-of-type(2n)':
                        {
                            backgroundColor: 'hsl(103, 43%, 86%)',
                        },
                    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                        backgroundColor: '#fdfffe',
                    },
                }}
            />

            <NotesEditDialog
                open={openNotesEditDialog}
                title={notesEditDialogTitle}
                label={notesEditDialogLabel}
                initialNotes={editableNotes}
                username={usernameForNotesEditDialog}
                onSave={handleNotesEditDialogSave}
                onCancel={handleNotesEditDialogCancel}
                onClose={handleNotesEditDialogClose}
            />

            <PromptUpdateOrSaveNewViewDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateView={handleOnlyUpdateView}
                handleOnlySaveAsNewView1={handleOnlySaveAsNewView1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewView2={handleOnlySaveAsNewView2}
            />
            {promptSaveNewView()}
        </>
    );
}

function Form471TrackerApplicantReportSearchBox({
    appliesTo,
    handleFieldsInitialized,
    handleSearchButtonClick,
    trackersAPI,
    userAPI,
    userID,
}) {
    const [defaultUserID, setDefaultUserID] = React.useState(null);
    const [searchFieldValuesInLS, setSearchFieldValuesInLS] = useLocalStorage(APPLICANT_SEARCHFIELDS_LS_NAME, '');
    const auth = useAuthContext();

    const categoryOptions = [
        { text: 'Any Category', value: 0 },
        { text: 'Category 1', value: 1 },
        { text: 'Category 2', value: 2 },
    ];

    const formFilingOptions = [
        { text: 'NA', value: 0 },
        { text: 'Filed', value: 1 },
        { text: 'Not Filed', value: 2 },
    ];

    //=====  Search Box Options  =====
    const [fundingYearOptions, setFundingYearOptions] = React.useState([]);
    const [primaryContactMSOptions, setPrimaryContactMSOptions] = React.useState([]);
    const [secondaryContactMSOptions, setSecondaryContactMSOptions] = React.useState([]);
    const [teamsMSOptions, setTeamsMSOptions] = React.useState([]);
    const [applicantTypeMSOptions, setApplicantTypeMSOptions] = React.useState([]);
    const [applicantCohortMSOptions, setApplicantCohortMSOptions] = React.useState([]);
    const [servicesMSOptions, setServicesMSOptions] = React.useState([]);
    const [cyberSecurityPilotParticipation, setCyberSecurityPilotParticipation] = React.useState([]);
    //const [applicationOwnerMSOptions, setApplicationOwnerMSOptions] = React.useState([]);

    //=====  Search Box State  =====
    const [reportType, setReportType] = React.useState('Applicants');
    const [categoryDropdownDisabled, setCategoryDropdownDisabled] = React.useState(true);
    const [ignoreCDCheckboxDisabled, setIgnoreCDCheckboxDisabled] = React.useState(true);
    const [fundingYear, setFundingYear] = React.useState(new Date().getFullYear());
    const [primaryContacts, setPrimaryContacts] = React.useState([]);
    const [secondaryContacts, setSecondaryContacts] = React.useState([]);
    const [teams, setTeams] = React.useState([]);
    const [applicationOwners, setApplicationOwners] = React.useState([]);
    const [applicantTypes, setApplicantTypes] = React.useState([]);
    const [applicantCohorts, setApplicantCohorts] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [form470FilingStatus, setForm470FilingStatus] = React.useState(0);
    const [form471FilingStatus, setForm471FilingStatus] = React.useState(0);
    const [category, setCategory] = React.useState(0);
    const [ignoreCancelledDiscarded, setIgnoreCancelledDiscarded] = React.useState(true);
    const [isAClient, setIsAClient] = React.useState(true);
    const [areFieldsInitialized, setAreFieldsInitialized] = React.useState(false);

    //################################### SAVED SEARCHES #############################################
    const [currentSavedSearches, setCurrentSavedSearches] = React.useState([]);
    const [selectedSearchName, setSelectedSearchName] = React.useState('');
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [editingSearchName, setEditingSearchName] = React.useState('');

    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [savedSearchObject, setSavedSearchObject] = React.useState({});
    //################################### END SAVED SEARCHES #############################################

    //###################################  Seach Box State (Deadline)  =====
    // const [deadlineFieldName, setDeadlineFieldName] = React.useState(null);
    // const [deadlineFieldOperator, setDeadlineFieldOperator] = React.useState(null);
    // const [deadlineFieldDays, setDeadlineFieldDays] = React.useState(null);
    // const [deadlineValue, setDeadlineValue] = React.useState(deadlineFieldDays ?? '');
    // const [calculatedDeadlineDate, setCalculatedDeadlineDate] = React.useState('');
    // const [deadlineFieldsCount, setDeadlineFieldsCount] = React.useState(0);
    // const [isDeadlineAccordionSelected, setIsDeadlineAccordionSelected] = React.useState(false);

    //=====  Functions to set or retrieve search values  =====
    const setDefaultSearchValues = (defaultUserID) => {
        //console.log('SETTING DEFAULTS');
        //console.log('defaultUserID: ', defaultUserID);
        setReportType('Applicants');
        setCategoryDropdownDisabled(true);
        setIgnoreCDCheckboxDisabled(true);
        setFundingYear(new Date().getMonth() > 3 ? new Date().getFullYear() + 1 : new Date().getFullYear());
        if (defaultUserID != null) {
            setPrimaryContacts([defaultUserID]);
        } else {
            setPrimaryContacts([]);
        }
        setSecondaryContacts([]);
        setTeams([]);
        setApplicationOwners([]);
        setApplicantTypes([]);
        setApplicantCohorts([]);
        setServices([]);
        setIsAClient(true);
        //
        setForm470FilingStatus(0);
        setForm471FilingStatus(0);
        setCategory(0);
        setIgnoreCancelledDiscarded(true);
    };

    // Potential
    const createSearchParametersObject = () => {
        return {
            reporttype: reportType,
            categoryDropdownDisabled: categoryDropdownDisabled,
            ignoreCDCheckboxDisabled: ignoreCDCheckboxDisabled,
            funding_year: fundingYear,
            primary_contacts: primaryContacts,
            secondary_contacts: secondaryContacts,
            teams: teams,
            application_owners: applicationOwners,
            applicant_types: applicantTypes,
            applicant_cohorts: applicantCohorts,
            services: services,
            is_a_client: isAClient,
            form470_filing_status: form470FilingStatus,
            form471_filing_status: form471FilingStatus,
            cybersecuritypilot_participation: cyberSecurityPilotParticipation,
            ignore_cancelled_discarded: ignoreCancelledDiscarded,
            category: category,
            // deadlineFieldName: deadlineFieldName,
            // deadlineFieldOperator: deadlineFieldOperator,
            // deadlineFieldDays: deadlineFieldDays,
            // calculatedDeadlineDate: calculatedDeadlineDate
        };
    };

    const setSearchFieldPrefs = (parsedSP) => {
        // Start with default values.
        setDefaultSearchValues(defaultUserID);

        // Fill in search values.
        try {
            if (parsedSP.reporttype != null) {
                setReportType(parsedSP.reporttype);
            }
            if (parsedSP.categoryDropdownDisabled != null) {
                // This is 'UI state', not a search parameter.
                setCategoryDropdownDisabled(parsedSP.categoryDropdownDisabled === true);
            }
            if (parsedSP.ignoreCDCheckboxDisabled != null) {
                // This is 'UI state', not a search parameter.
                setIgnoreCDCheckboxDisabled(parsedSP.ignoreCDCheckboxDisabled === true);
            }
            //
            if (parsedSP.funding_year != null) {
                setFundingYear(parsedSP.funding_year);
            }
            if (parsedSP.primary_contacts != null && Array.isArray(parsedSP.primary_contacts)) {
                setPrimaryContacts(parsedSP.primary_contacts);
            }
            if (parsedSP.secondary_contacts != null && Array.isArray(parsedSP.secondary_contacts)) {
                setSecondaryContacts(parsedSP.secondary_contacts);
            }
            if (parsedSP.teams != null && Array.isArray(parsedSP.teams)) {
                setTeams(parsedSP.teams);
            }
            if (parsedSP.application_owners != null && Array.isArray(parsedSP.application_owners)) {
                setApplicationOwners(parsedSP.application_owners);
            }
            if (parsedSP.applicant_types != null && Array.isArray(parsedSP.applicant_types)) {
                setApplicantTypes(parsedSP.applicant_types);
            }
            if (parsedSP.applicant_cohorts != null && Array.isArray(parsedSP.applicant_cohorts)) {
                setApplicantCohorts(parsedSP.applicant_cohorts);
            }
            if (parsedSP.services != null && Array.isArray(parsedSP.services)) {
                setServices(parsedSP.services);
            }
            if (parsedSP.isAClient != null && (parsedSP.isAClient === true || parsedSP.isAClient === false)) {
                setIsAClient(parsedSP.isAClient);
            }
            //
            if (parsedSP.form470_filing_status != null && Array.isArray(parsedSP.form470_filing_status)) {
                setForm470FilingStatus(parsedSP.form470_filing_status);
            }
            if (parsedSP.form471_filing_status != null && Array.isArray(parsedSP.form471_filing_status)) {
                setForm471FilingStatus(parsedSP.form471_filing_status);
            }
            if (
                parsedSP.ignore_cancelled_discarded != null &&
                (parsedSP.ignore_cancelled_discarded === true || parsedSP.ignore_cancelled_discarded === false)
            ) {
                setIgnoreCancelledDiscarded(parsedSP.ignore_cancelled_discarded);
            }
            if (parsedSP.category != null && Array.isArray(parsedSP.category)) {
                setCategory(parsedSP.category);
            }
        } catch (error) {
            console.error('error: ', error);
            toast.error(error);
            return false;
        }
    };

    // Attempts to pull from SS then LS
    const retrieveSearchFieldValuesJSONFromStorage = () => {
        let searchFieldValuesInSS = sessionStorage.getItem(APPLICANT_SEARCHFIELDS_SS_NAME);
        if (searchFieldValuesInSS) {
            return searchFieldValuesInSS;
        }

        if (searchFieldValuesInLS) {
            return searchFieldValuesInLS;
        }
        return null;
    };

    // Saves SFVs to session & local storage
    const saveSearchFieldValuesJSONToStorage = (json) => {
        setSearchFieldValuesInLS(json);
        sessionStorage.setItem(APPLICANT_SEARCHFIELDS_SS_NAME, json);
    };

    // Clears out the values of the storage keys
    const clearSearchFieldValuesStorage = () => {
        setSearchFieldValuesInLS('');
        sessionStorage.setItem(APPLICANT_SEARCHFIELDS_SS_NAME, '');
    };

    // Clears out the values of the storage keys & sets default values
    const resetToDefaults = () => {
        clearSearchFieldValuesStorage();
        setTextFieldValue('');
        setDefaultSearchValues(defaultUserID);
    };

    // Gets all of the user's saved searches
    const getSavedSearches = async (ap) => {
        const searchResults = await userAPI.GetSavedSearches(ap);
        const parsedSearchResults = JSON.parse(searchResults);
        // console.log('ClientListTableWithSearch: getSavedSearchesFN: parsedSearchResults = ', parsedSearchResults);

        // Defines the base layout for all retrieved searches
        const structuredSearches = parsedSearchResults.map((ea_row) => ({
            search_id: ea_row.id,
            search_name: ea_row.search_name,
            parameters: ea_row,
        }));
        structuredSearches.sort((a, b) => a.search_name.localeCompare(b.search_name));
        console.log('ClientListTableWithSearch: getSavedSearchesFN: structuredSearches = ', structuredSearches);
        setCurrentSavedSearches(structuredSearches);
    };

    //=====  On first render  =====
    React.useEffect(() => {
        const getDefaultUserID = async (cognitoID) => {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetDatabaseIDForUser';
            const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

            const idResponse = await API.get(apiName, path, queryStringParameters);
            return idResponse;
        };

        // Potential
        const getSearchOptions = async () => {
            try {
                //~~~ Get dropdown and multi-select options ~~~
                let options = await trackersAPI.GetForm471TrackerApplicantsReportSearchOptions();
                // console.log('[getSearchOptions] options = ', options);
                // console.log('[getSearchOptions] options.fundingYears = ', options.fundingYears);

                setFundingYearOptions(options.fundingYears);
                setPrimaryContactMSOptions(options.primaryContacts);
                setSecondaryContactMSOptions(options.secondaryContacts);
                setTeamsMSOptions(options.teams);
                setApplicantTypeMSOptions(options.applicantTypes);
                setApplicantCohortMSOptions(options.applicantCohorts);
                setServicesMSOptions(options.form471Services);
                setCyberSecurityPilotParticipation(options.cyberSecurityPilotParticipation);
                //setApplicationOwnerMSOptions(options.applicationOwners);

                //~~~ Get the user's userId ~~~
                let userID = await getDefaultUserID(auth.cognitoID);
                if (options.primaryContacts) {
                    // Check if that user (id) in the "Primary Contacts" options array..
                    let pcoption = options.primaryContacts.find((pc) => pc.value === userID);
                    if (!pcoption) {
                        userID = null;
                    }

                    setDefaultUserID(userID);
                }

                // Initialize search box to previous values or defaults
                let sfv_json = JSON.parse(retrieveSearchFieldValuesJSONFromStorage());
                if (sfv_json) {
                    setSearchFieldPrefs(sfv_json);
                    setSelectedSearchName(sfv_json.selectedSearchName);
                } else {
                    setDefaultSearchValues(userID);
                }

                setAreFieldsInitialized(true);
            } catch (error) {
                console.log(error);
                toast.error(error);
            }
        };

        getSearchOptions();
        getSavedSearches(appliesTo);
    }, []);

    React.useEffect(() => {
        if (areFieldsInitialized === true) {
            handleFieldsInitialized(createSearchParametersObject());
        }
    }, [areFieldsInitialized]);

    //=====  Handlers  =====
    const handleReportTypeChange = (event) => {
        setReportType(event.target.value);
        // Enable/disable "Category" and "Ignore C & D" depending on report type.
        switch (event.target.value) {
            case 'Applicants':
                setCategoryDropdownDisabled(true);
                setIgnoreCDCheckboxDisabled(true);
                break;
            case 'Applicants_without_Form_471_Applications':
                setCategoryDropdownDisabled(false);
                setIgnoreCDCheckboxDisabled(false);
                break;
            case 'Applicants_with_Form_470_without_a_Form_471':
                setCategoryDropdownDisabled(false);
                setIgnoreCDCheckboxDisabled(false);
                break;
            default: // no default
        }
    };

    const handlePrimaryContactChange = (event, selectedValues) => {
        setPrimaryContacts(selectedValues);
    };

    const handleSecondaryContactChange = (event, selectedValues) => {
        setSecondaryContacts(selectedValues);
    };

    const handleTeamsChange = (event, selectedValues) => {
        setTeams(selectedValues);
    };

    // const handleApplicationOwnerChange = (event, selectedValues) => {
    //     setApplicationOwners(selectedValues);
    // };

    const handleApplicantTypeChange = (event, selectedValues) => {
        setApplicantTypes(selectedValues);
    };

    const handleApplicantCohortChange = (event, selectedValues) => {
        setApplicantCohorts(selectedValues);
    };

    const handleServicesChange = (event, selectedValues) => {
        setServices(selectedValues);
    };

    // const handleDeadlineFieldStatusChange = (event) => {
    //     setDeadlineFieldName(event);
    // };

    // const handleDeadlineOperatorStatusChange = (event) => {
    //     setDeadlineFieldOperator(event);
    // };

    // const handleDeadlineDaysChange = (event) => {
    //     setDeadlineFieldDays(event);
    // };

    // // For Deadlines
    // React.useEffect(() => {
    //     const totals = (deadlineFieldName ? 1 : 0) + (deadlineFieldOperator ? 1 : 0) + (deadlineValue ? 1 : 0);

    //     const anySelected = totals > 0;

    //     if (anySelected !== isDeadlineAccordionSelected) {
    //         setIsDeadlineAccordionSelected(anySelected);
    //     }

    //     if (anySelected) {
    //         setDeadlineFieldsCount(totals);
    //     } else if (deadlineFieldsCount !== 0) {
    //         setDeadlineFieldsCount(0);
    //     }
    // }, [deadlineFieldName, deadlineFieldOperator, deadlineValue, deadlineFieldsCount, isDeadlineAccordionSelected]);

    //################################### SAVED SEARCHES #############################################
    function endsWithSearch(searchName) {
        const lowercaseName = searchName.trim().toLowerCase();
        return lowercaseName.endsWith('search') || lowercaseName.split(-6).pop().endsWith('search');
    }

    // Check if the search name already exists
    const searchNameExists = (searchName) => {
        return currentSavedSearches.some((ea_search) => ea_search.search_name === searchName);
    };

    // Components rendered when the user begins to edit a MenuItem
    const EditableMenuItem = ({ ea_search, onSave, onCancel }) => {
        const [editValue, setEditValue] = React.useState(ea_search.search_name);

        const handleKeyDown = (event) => {
            // Stops the select component from navigating while typing
            event.stopPropagation();
            if (event.key === 'Enter') {
                onSave(ea_search.search_name, editValue);
            }
        };

        return (
            <MenuItem>
                <TextField
                    value={editValue}
                    onChange={(event) => setEditValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => onSave(ea_search.search_name, editValue)}>
                    <DoneIcon fontSize='inherit' />
                </IconButton>
                <IconButton onClick={onCancel}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </MenuItem>
        );
    };

    const handleSaveSearch = async (sp_obj) => {
        setSavedSearchObject(sp_obj);

        if (selectedSearchName !== '' && selectedSearchName !== null) {
            // Search for duplicate/determine if the user has already selected a search
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === selectedSearchName
            );

            // If a duplicate was found/the user has already selected a search
            if (duplicateSearchIndex !== -1) {
                openDialog1(selectedSearchName);
            }
        } else {
            openDialog2();
            promptSaveNewSearch();
        }
    };

    const handleOnlyUpdateSearch = async () => {
        let message = 'update';
        let newOrUpdatedSearchName = selectedSearchName;
        let tempCurrentSavedSearches = [...currentSavedSearches];

        const duplicateSearchIndex = currentSavedSearches.findIndex(
            (ea_search) => ea_search.search_name === newOrUpdatedSearchName
        );

        // Finds the matching search object
        const matchingSavedSearch = currentSavedSearches[duplicateSearchIndex];

        // Building the updated search object (state updates too slowly to add searchName to CSPO)
        const replacementSearch = {
            search_id: matchingSavedSearch.parameters.id,
            search_name: newOrUpdatedSearchName,
            parameters: savedSearchObject,
        };

        // Update state
        tempCurrentSavedSearches[duplicateSearchIndex] = replacementSearch;
        const response = await userAPI.SaveSearch(
            userID,
            message,
            appliesTo,
            tempCurrentSavedSearches.at(duplicateSearchIndex)
        );

        if (response) {
            setLanding(false);
            setShowTextField1(false);
            setIsDialogOpen1(false);

            let successMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Successfully updated the ${newOrUpdatedSearchName}`
                : `Successfully updated the ${newOrUpdatedSearchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Failed to update the ${newOrUpdatedSearchName}`
                : `Failed to update the ${newOrUpdatedSearchName} saved search`;

            toast.error(failedMessage);
        }
        getSavedSearches(appliesTo);
        setLanding(true);
    };

    const handleOnlySaveAsNewSearch1 = () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewSearch2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved search name cannot be empty!');
            return;
        }

        const message = 'new';
        const searchName = textFieldValue.trim();
        const tempCurrentSavedSearches = [...currentSavedSearches];

        if (searchNameExists(searchName)) {
            toast.error(
                `A saved search with the name ${searchName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const newSearch = {
            search_name: searchName,
            parameters: savedSearchObject,
        };
        // newSearch['parameters']['searchName'] = searchName;
        newSearch['parameters']['selectedSearchName'] = searchName;
        tempCurrentSavedSearches.push(newSearch);

        const response = await userAPI.SaveSearch(userID, message, appliesTo, tempCurrentSavedSearches.at(-1));
        if (response) {
            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            let successMessage = endsWithSearch(searchName)
                ? `Successfully created the ${searchName}`
                : `Successfully created the ${searchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(searchName)
                ? `Failed to create the ${searchName}`
                : `Failed to create the ${searchName} saved search`;

            toast.error(failedMessage);
        }
        setLanding(true);
        getSavedSearches(appliesTo);
        setSelectedSearchName(searchName);
    };

    // Called when the user selects a search from the dropdown
    const handleSelectSavedSearch = (search_name) => {
        setSelectedSearchName(search_name);

        // Finds the matching search object
        const retrieved_search = currentSavedSearches.find((ea_search) => ea_search.search_name === search_name);
        if (retrieved_search) {
            setSearchFieldPrefs(retrieved_search.parameters.search_filters); // parses the values into searchbar
        } else {
            return;
        }
    };

    // TODO: change searchName to selectedSearchName ?
    // Called when the user clicks the edit icon
    const handleEditSavedSearchName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/search_name already exists for the other saved searches
            if (currentSavedSearches.some((ea_search) => ea_search.search_name === new_name)) {
                alert('A saved search with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the search we want to edit
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === old_name
            );

            // If a duplicate was found
            if (duplicateSearchIndex !== -1) {
                // Returns an array of objects with the previous saved search + the edited search
                const updatedSearches = currentSavedSearches.map((ea_search, index) => {
                    if (index === duplicateSearchIndex) {
                        return {
                            ...ea_search,
                            search_name: new_name,
                            parameters: {
                                ...ea_search.parameters,
                                search_name: new_name,
                                search_filters: {
                                    ...ea_search.parameters.search_filters,
                                    searchName: new_name,
                                    selectedSearchName: new_name,
                                },
                            },
                        };
                    }
                    return ea_search;
                });

                // Update the state
                if (selectedSearchName === old_name) {
                    setSelectedSearchName(new_name);
                }
                setCurrentSavedSearches(updatedSearches);

                // Process the edited search
                const search_response = await userAPI.SaveSearch(
                    userID,
                    message,
                    appliesTo,
                    updatedSearches.at(duplicateSearchIndex)
                );

                if (search_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }

                getSavedSearches(appliesTo);
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedSearch = async (search_name, search_id, applies_to, user_id) => {
        let confirmationMessage = endsWithSearch(search_name)
            ? `Are you sure you want to delete the ${search_name}?`
            : `Are you sure you want to delete the ${search_name} saved search?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedSearch(search_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithSearch(search_name)
                    ? `Successfully deleted the ${search_name}`
                    : `Successfully deleted the ${search_name} saved search`;

                toast.success(successMessage, {
                    autoClose: 3000,
                });

                if (search_name === selectedSearchName) {
                    setSelectedSearchName('');
                    resetToDefaults();
                }
                getSavedSearches(appliesTo);
            } else {
                const errorMessage = endsWithSearch(search_name)
                    ? `Failed to delete the ${search_name}`
                    : `Failed to delete the ${search_name} saved search`;

                toast.error(errorMessage);
            }
        }
    };

    // Saves the current set search parameters to storage
    const searchButtonClicked = () => {
        let sfv_obj = createSearchParametersObject();
        saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
        handleSearchButtonClick(sfv_obj);
    };

    const saveSearchButtonClicked = () => {
        let sfv_obj = createSearchParametersObject();
        handleSaveSearch(sfv_obj);
        saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewSearch2();
        }
    };

    const openDialog1 = (searchName) => {
        let confirmationMessage = endsWithSearch(searchName)
            ? `Do you want to update the ${searchName} or save as a new search?`
            : `Do you want to update the ${searchName} saved search or save as a new search?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewSearch = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved search: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='Search Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewSearch2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED SEARCHES #############################################

    // const deadlineFieldsMapping = [{ text: 'Form 470 Initial Outreach Date', value: 'form470InitialOutreachDate' }];
    // deadlineFieldsMapping.sort((a, b) => a.text.localeCompare(b.text));

    // const deadlineFieldsOperatorMapping = [
    //     { text: 'is x days past deadline', value: 'isBeforeXDays' },
    //     { text: 'is x days out from deadline', value: 'isAfterXDays' },
    // ];

    // const deadlineDaysOptions = [
    //     { text: '30', value: 30 },
    //     { text: '60', value: 60 },
    //     { text: '90', value: 90 },
    // ];

    // const handleInputChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (inputValue === '') {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     } else {
    //         setDeadlineValue(inputValue);
    //         handleDeadlineDaysChange(inputValue);
    //     }
    // };

    // const handleSelect = (event, newValue) => {
    //     if (newValue !== null) {
    //         setDeadlineValue(newValue.value);
    //         handleDeadlineDaysChange(newValue.value);
    //     } else {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     }
    // };

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <Accordion sx={{ marginBottom: '25px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='search-panel-content'
                    id='search-panel-header'
                >
                    Search Applicants
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <FormControl>
                                <FormLabel id='search-reporttype-radio-buttons-group-label'>Report Type</FormLabel>
                                <RadioGroup
                                    id='search-reporttype'
                                    name='reporttype-radio-buttons-group'
                                    value={reportType}
                                    onChange={handleReportTypeChange}
                                >
                                    <FormControlLabel value='Applicants' control={<Radio />} label='Applicants' />
                                    <FormControlLabel
                                        value='Applicants_without_Form_471_Applications'
                                        control={<Radio />}
                                        label='Applicants without Form 471 Applications'
                                    />
                                    <FormControlLabel
                                        value='Applicants_with_Form_470_without_a_Form_471'
                                        control={<Radio />}
                                        label='Applicants with Form 470 without a Form 471'
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <InputLabel id='search-category-label'>Category</InputLabel>
                                <Select
                                    labelId='search-category-label'
                                    id='search-category'
                                    value={category}
                                    label='Category'
                                    onChange={(event) => setCategory(event.target.value)}
                                    displayEmpty
                                    disabled={categoryDropdownDisabled}
                                >
                                    {categoryOptions.map((row, i) => {
                                        return (
                                            <MenuItem value={row.value} key={row.value}>
                                                {row.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ignoreCancelledDiscarded}
                                            onChange={(event) => setIgnoreCancelledDiscarded(event.target.checked)}
                                            disabled={ignoreCDCheckboxDisabled}
                                        />
                                    }
                                    label='Ignore Cancelled and Discarded Form 471 Applications'
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <InputLabel id='search-fundingyear-label'>Funding Year</InputLabel>
                                <Select
                                    labelId='search-fundingyear-label'
                                    id='search-fundingyear'
                                    value={fundingYear}
                                    label='Funding Year'
                                    onChange={(event) => setFundingYear(event.target.value)}
                                >
                                    {fundingYearOptions.map((row) => {
                                        return (
                                            <MenuItem value={row.value} key={row.value}>
                                                {row.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-primarycontact'
                                    label='Primary Contact'
                                    selectedOptions={primaryContacts}
                                    options={primaryContactMSOptions}
                                    handleSelectChange={handlePrimaryContactChange}
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-secondarycontact'
                                    selectedOptions={secondaryContacts}
                                    options={secondaryContactMSOptions}
                                    handleSelectChange={handleSecondaryContactChange}
                                    label='Secondary Contact'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-teams'
                                    selectedOptions={teams}
                                    options={teamsMSOptions}
                                    handleSelectChange={handleTeamsChange}
                                    label='Teams'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicationowner'
                                    selectedOptions={applicationOwners}
                                    options={applicationOwnerMSOptions}
                                    handleSelectChange={handleApplicationOwnerChange}
                                    label='Form 471 Application Owner'
                                />
                            </FormControl> */}

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicanttype'
                                    selectedOptions={applicantTypes}
                                    options={applicantTypeMSOptions}
                                    handleSelectChange={handleApplicantTypeChange}
                                    label='Applicant Type'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicantcohort'
                                    selectedOptions={applicantCohorts}
                                    options={applicantCohortMSOptions}
                                    handleSelectChange={handleApplicantCohortChange}
                                    label='Applicant Cohorts'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-services'
                                    selectedOptions={services}
                                    options={servicesMSOptions}
                                    handleSelectChange={handleServicesChange}
                                    label='Services'
                                />
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAClient}
                                            onChange={(event) => setIsAClient(event.target.checked)}
                                        />
                                    }
                                    label='Is A Client'
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* Deadline Dropdowns */}
                            {/* <Accordion
                                style={{
                                    border: isDeadlineAccordionSelected ? '2px solid #4CAF50' : '',
                                }}
                            > */}
                            {/* <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='qa-fields'
                                    id='qa-fields'
                                    sx={{ mt: 1, mb: 0.7 }}
                                >
                                    <Typography color='textSecondary'>
                                        {isDeadlineAccordionSelected
                                            ? `(${deadlineFieldsCount}) Deadline Field${
                                                  deadlineFieldsCount > 1 ? 's' : ''
                                              } Selected`
                                            : 'Deadline Fields'}
                                    </Typography>
                                </AccordionSummary> */}
                            {/* <AccordionDetails> */}
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            id='deadline-field-options'
                                            options={deadlineFieldsMapping}
                                            getOptionLabel={(option) => (option.text ? option.text : '')}
                                            value={deadlineFieldName}
                                            isOptionEqualToValue={(option, value) => {
                                                // console.log(option, value);
                                                return option.value === value.value;
                                            }}
                                            onChange={(event, newValue) => {
                                                handleDeadlineFieldStatusChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label='Date Fields' variant='outlined' />
                                            )}
                                        />
                                    </FormControl> */}

                            {/* Before or After Deadline */}
                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            id='deadline-field-operator-options'
                                            options={deadlineFieldsOperatorMapping}
                                            getOptionLabel={(option) => (option.text ? option.text : '')}
                                            value={deadlineFieldOperator}
                                            isOptionEqualToValue={(option, value) => {
                                                // console.log(option, value);
                                                return option.value === value.value;
                                            }}
                                            onChange={(event, newValue) => {
                                                handleDeadlineOperatorStatusChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label='Operators' variant='outlined' />
                                            )}
                                        />
                                    </FormControl> */}

                            {/* <FormControl fullWidth margin='dense' size='small'>
                                        <Autocomplete
                                            freeSolo
                                            options={deadlineDaysOptions}
                                            getOptionLabel={(option) => option.text || ''}
                                            value={
                                                deadlineDaysOptions.find((option) => option.value === deadlineValue) ||
                                                null
                                            }
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            onChange={handleSelect}
                                            onInputChange={(event, value) => {
                                                // Handles the direct text input from renderInput
                                                if (value === '') {
                                                    setDeadlineValue('');
                                                    handleDeadlineDaysChange('');
                                                } else {
                                                    setDeadlineValue(value);
                                                    handleDeadlineDaysChange(value);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    id='amount-of-days'
                                                    label='Amount of Days'
                                                    type='number'
                                                    value={deadlineValue}
                                                    onChange={handleInputChange}
                                                    variant='outlined'
                                                />
                                            )}
                                        />
                                    </FormControl> */}
                            {/* </AccordionDetails> */}
                            {/* </Accordion> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='space-between' width='100%'>
                            <Box flex='1' maxWidth='340.2px' mr={2}>
                                <FormControl fullWidth={true} margin='normal'>
                                    <InputLabel
                                        id='ercAppStatus-label'
                                        shrink={true}
                                        sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                                    >
                                        Saved Searches
                                    </InputLabel>
                                    <Select
                                        id='currentSavedSearches-select'
                                        labelId='currentSavedSearches-label'
                                        label='Current Saved Searches'
                                        value={selectedSearchName ?? ''}
                                        renderValue={(selected) => (selected ? selected : 'Select a search')}
                                        onChange={(event) => {
                                            handleSelectSavedSearch(event.target.value);
                                        }}
                                        onOpen={() => setIsSelectOpen(true)}
                                        onClose={() => setIsSelectOpen(false)}
                                    >
                                        <MenuItem value='' onClick={resetToDefaults}>
                                            Default
                                        </MenuItem>
                                        {currentSavedSearches.map((ea_search, index) => {
                                            if (editingSearchName === ea_search.search_name) {
                                                return (
                                                    <EditableMenuItem
                                                        key={index}
                                                        ea_search={ea_search}
                                                        onSave={(oldName, newName) => {
                                                            handleEditSavedSearchName(oldName, newName);
                                                            setEditingSearchName(null);
                                                        }}
                                                        onCancel={() => {
                                                            setEditingSearchName(null);
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <MenuItem key={index} value={ea_search.search_name}>
                                                        {ea_search.search_name}
                                                        {isSelectOpen && (
                                                            <>
                                                                <Tooltip title='Edit Name'>
                                                                    <IconButton
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            setEditingSearchName(ea_search.search_name);
                                                                        }}
                                                                        size='small'
                                                                        style={{ marginLeft: '8px' }}
                                                                    >
                                                                        <EditIcon fontSize='inherit' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title='Delete'>
                                                                    <IconButton
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            handleDeleteSavedSearch(
                                                                                ea_search.search_name,
                                                                                ea_search.search_id,
                                                                                appliesTo,
                                                                                userID
                                                                            );
                                                                        }}
                                                                        size='small'
                                                                        style={{ marginLeft: '8px' }}
                                                                    >
                                                                        <DeleteIcon fontSize='inherit' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Grid
                                item
                                xs={12}
                                md={7}
                                container
                                justifyContent='flex-start'
                                spacing={2}
                                marginTop={'1em'}
                            >
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        onClick={searchButtonClicked}
                                        sx={{ backgroundColor: '#4CAF50' }}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' onClick={saveSearchButtonClicked}>
                                        {selectedSearchName ? 'Update Search' : 'Save Search'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' color='secondary' onClick={resetToDefaults}>
                                        Reset to Defaults
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <PromptUpdateOrSaveNewSearchDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateSearch={handleOnlyUpdateSearch}
                handleOnlySaveAsNewSearch1={handleOnlySaveAsNewSearch1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewSearch2={handleOnlySaveAsNewSearch2}
            />
            {promptSaveNewSearch()}
        </>
    );
}

export default Form471TrackerApplicantReport;

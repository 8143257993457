import React from 'react';

import throttle from 'lodash/throttle';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function ClientListSearchForBEN({ clientAPI, ben, setBen }) {
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [suppressAutocompleteSearch, setSuppressAutocompleteSearch] = React.useState(false);

    const setBen2 = (selectedOptionData) => {
        //console.log('[setBen2] selectedOptionData = ', selectedOptionData);
        if (!selectedOptionData) {
            setBen(null);
        } else {
            setBen(selectedOptionData.ben);
        }
    };

    //  searchValue: BEN number or Client Name.
    const searchForBENOptions = async (searchValue, limit) => {
        setLoading(true);
        const data = {
            searchValue: searchValue,
            limit: limit,
        };
        const results = await clientAPI.ClientListAutocompleteSearch(data);
        setLoading(false);
        return results;
    };

    React.useEffect(() => {
        if (ben !== null) {
            // We're starting with an Application Number..
            setSuppressAutocompleteSearch(true);
            //console.log('[React.useEffect 1] ben = ', ben);
            let benstring = ben.toString();
            searchForBENOptions(benstring, 20).then((searchresults) => {
                //console.log('[React.useEffect 1]  searchresults = \r\n', searchresults);
                if (searchresults && searchresults.length > 0) {
                    console.log('These are the searchresults: ', searchresults);
                    console.log('Setting inputValue (to applicationNumber) and value (to searchresults[0])..');
                    setSearchValue(ben);
                    setValue(searchresults[0]);
                }
                setSuppressAutocompleteSearch(false);
            });
        }
    }, []);

    const startDataSearch = React.useMemo(
        () =>
            throttle((searchValue, callback) => {
                //console.log('throttling with:', searchValue);
                searchForBENOptions(searchValue, 20).then(callback);
            }, 200),
        []
    );

    React.useEffect(() => {
        //console.log("[React.useEffect 2]");

        let active = true;

        if (searchValue === '' || searchValue === undefined) {
            setOptions([]);
            return undefined;
        }

        if (suppressAutocompleteSearch) {
            //console.log("[React.useEffect 2] Suppressing the normal autocomplete search.");
            return undefined;
        }

        startDataSearch(searchValue, (searchresults) => {
            if (active) {
                //console.log('options is being set to:', searchresults);
                setOptions(searchresults);
            }
        });

        return () => {
            active = false;
        };
    }, [searchValue, startDataSearch]);

    return (
        <Box sx={{ mt: 1, mb: 1, p: 2, borderRadius: 2, border: '1px solid #E5EAF2', bgcolor: '#F3F6F9' }}>
            <Box sx={{ mb: 2, fontWeight: 'bold' }}>Search for a client (by BEN or Client Name):</Box>
            <Grid container justifyContent='flex-start' alignItems='center'>
                <Grid item xs={7}>
                    <Autocomplete
                        id='bensfromoddataset-autocomplete'
                        loading={loading}
                        disabled={suppressAutocompleteSearch}
                        sx={{ bgcolor: '#FFFFFF' }}
                        options={options}
                        getOptionLabel={(option) => (option.ben ? option.ben.toString() : '')}
                        filterOptions={(x) => x}
                        value={value}
                        isOptionEqualToValue={(option, value) => {
                            return option.ben === value.ben;
                        }}
                        onChange={(event, newValue) => {
                            //console.log('onChange newValue: ', newValue);
                            setValue(newValue); // newValue is the selected `option` object
                            setBen2(newValue);
                        }}
                        onInputChange={(event, newSearchValue) => {
                            setSearchValue(newSearchValue);
                        }}
                        renderInput={(params) => <TextField {...params} label='BEN or Client Name' />}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <ClientInfoBox data={option} />
                                </li>
                            );
                        }}
                    ></Autocomplete>
                </Grid>
                <Grid item xs={5}>
                    {loading && <CircularProgress size={25} sx={{ ml: 2.5 }} />}
                </Grid>
            </Grid>

            {value && (
                <Box sx={{ mt: '0.7em' }}>
                    <Box sx={{ fontSize: '0.8em', mb: '0.5em' }}>Selected Client BEN:</Box>
                    <Box>
                        <ClientInfoTable data={[value]} />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

// Generates boxes that appear throughout text input
function ClientInfoBox({ data }) {
    //console.log('[ClientInfoBox] data = ', data);
    if (data == null) {
        return null;
    }
    return (
        <Grid
            container
            id={'clientben_' + data['ben']}
            sx={{
                m: '0.2em',
                p: '0.5rem',
                backgroundColor: '#F8F9FA',
                border: 1,
                borderRadius: 1,
                borderColor: 'grey.500',
            }}
        >
            <Grid item xs={12}>
                <div>
                    <strong>BEN:</strong> {data['ben']}{' '}
                </div>
                <div>
                    <strong>Client Name:</strong> {data['client_name']}{' '}
                </div>
                <div>
                    <strong>State:</strong> {data['state']}{' '}
                </div>
                <div>
                    <strong>Primary Contact:</strong> {data['primary_contact']}
                </div>
                <div>
                    <strong>Applicant Cohorts:</strong>{' '}
                    {data['applicant_cohorts'] ? data['applicant_cohorts'].join(', ') : ''}
                </div>
            </Grid>
        </Grid>
    );
}

function ClientInfoTable({ data }) {
    //console.log('[ClientInfoTable]  data: \r\n', data);
    return (
        <TableContainer component={Paper}>
            <Table size='small' sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                color: '#1C6387',
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            BEN
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Client Name
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            State
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Primary Contact
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 'bold',
                                backgroundColor: '#FDFFFE',
                            }}
                        >
                            Applicant Cohorts
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.ben}>
                            <TableCell sx={{ color: '#1C6387', fontWeight: 'bold' }}>{row['ben']}</TableCell>
                            <TableCell>{row['client_name']}</TableCell>
                            <TableCell>{row['state']}</TableCell>
                            <TableCell>{row['primary_contact']}</TableCell>
                            <TableCell>{row['applicant_cohorts'] ? row['applicant_cohorts'].join(', ') : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default ClientListSearchForBEN;

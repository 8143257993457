import React from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import UsacDataAPI from '../api/UsacDataAPI.js';

function UsacDataStats() {
    const usacdataAPI = new UsacDataAPI();

    const [usacFileDataStats, setUsacFileDataStats] = React.useState(null);
    const [usacApiDataStats, setUsacApiDataStats] = React.useState(null);

    React.useEffect(() => {
        const getUsacFileDataStats = async () => {
            let filedatastats = await usacdataAPI.GetFileDataStats();
            console.log('filedatastats', filedatastats);
            getUsacFileDataStats(filedatastats);
        };
        getUsacFileDataStats();
    }, []);

    React.useEffect(() => {
        const getUsacApiDataStats = async () => {
            let apidatastats = await usacdataAPI.GetApiDataStats();
            console.log('apidatastats', apidatastats);
            setUsacApiDataStats(apidatastats);
        };
        getUsacApiDataStats();
    }, []);

    return (
        <>
            <h2>USAC Data Stats</h2>
            <h3>Stats on the USAC data in UsacFileData and usacapidata database tables.</h3>

            <Typography variant='h3' sx={{ marginTop: '1em', marginBottom: '0.5em', color: 'hsl(201, 70%, 15%)' }}>
                UsacFileData
            </Typography>
            <Box>
                Hello, world!
                {/* usacFileDataStats */}
            </Box>

            <Typography variant='h3' sx={{ marginTop: '1em', marginBottom: '0.5em', color: 'hsl(201, 70%, 15%)' }}>
                usacapidata
            </Typography>
            <Box>
                Hello, world!
                {/* usacApiDataStats */}
            </Box>
        </>
    );
}

export default UsacDataStats;

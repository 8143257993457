import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGridApiContext } from '@mui/x-data-grid-pro';

export function InlineEditDropdown({ id, value, field, dropDownOptions, selectElementRef }) {
    const [dropdownValue, setDropDownValue] = React.useState(value);

    //.........................................
    //console.log('[InlineEditDropdown] field = ' + field + ', id = ' + id + ', value = ' + value);
    //console.log(dropDownOptions);
    //if (field === 'FilingWindowID') {
    //    console.log('[InlineEditDropdown] dropDownOptions.length = ' + dropDownOptions.length);
    //}
    //.........................................

    const apiRef = useGridApiContext();

    const handleChange = (event) => {
        // console.log('>>>  InlineEditDropdown - handleChange of Select  <<<');
        //console.log('event = ', event);
        // console.log('dropdownValue = ', dropdownValue);
        // console.log('event.target.value = ', event.target.value);

        const newValue = event.target.value; // The new value entered by the user
        setDropDownValue(newValue);

        // eslint-disable-next-line eqeqeq
        //if (newValue != value) {
        //    console.log("SET EDIT VALUE");
        //
        //} else {
        //    console.log("STOP CELL EDIT MODE");
        //    apiRef.current.stopCellEditMode({id, field})
        //}

        //console.log('BEFORE apiRef.current.setEditCellValue({' + id + ', ' + field + ', ' + newValue + '});');
        apiRef.current.setEditCellValue({ id, field, value: newValue }); // Once a new value is entered into the input, it must be sent to the grid.
    };

    // ref={handleRef}
    // const handleRef = (element) => {
    //     //console.log("IT'S REFFIN TIME");
    //     //console.log(element);
    //     /*if (element) {
    //         const input = element.querySelector(`input[value="${value}"]`);
    //         console.log(input);
    //         input?.focus();
    //     }*/
    // };

    return (
        <Select id={id + '-' + field} value={dropdownValue} fullWidth onChange={handleChange} ref={selectElementRef}>
            {dropDownOptions.map((row) => {
                return (
                    <MenuItem value={row.value} key={row.value}>
                        {row.text}
                    </MenuItem>
                );
            })}
        </Select>
    );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

import UserAPI from '../api/UserAPI.js';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { useAuthContext } from '../useAuthContext';
import { API } from 'aws-amplify';

export default function Form471TrackerAppEdit({ form471AppNum, trackersAPI, handleReturnToSourcePage }) {
    let navigate = useNavigate();
    const auth = useAuthContext();
    const userAPI = new UserAPI();

    //~~~  State  ~~~
    const [appData, setAppData] = React.useState(null);
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const [username, setUsername] = React.useState(null);
    //const [currentUserID, setCurrentUserID] = React.useState(null);

    // Editable app data
    const [ercAppStatusId, setERCAppStatusId] = React.useState(null);
    const [applicationOwnerUserId, setApplicationOwnerUserId] = React.useState(null);
    const [taskOwnerUserId, setTaskOwnerUserId] = React.useState(null);
    const [primaryContactUserId, setPrimaryContactUserId] = React.useState(null);
    const [dateSentToClientToCertify, setDateSentToClientToCertify] = React.useState(null);
    const [form471AppNotes, setForm471AppNotes] = React.useState('');
    const [filingWindowId, setFilingWindowId] = React.useState(null);
    const [qaReviewStatusId, setQAReviewStatusId] = React.useState(null);
    const [qaReviewerUserId, setQAReviewerUserId] = React.useState(null);
    const [qaNotes, setQANotes] = React.useState('');
    const [qaSubmitterUserId, setQASubmitterUserId] = React.useState(null);
    const [qaDateSubmitted, setQADateSubmitted] = React.useState(null);
    const [qaDateNeeded, setQADateNeeded] = React.useState(null);
    const [qaEstimatedFundingRequest, setQAEstimatedFundingRequest] = React.useState(null);

    const handleERCAppStatusChange = (ercAppStatusId) => {
        setERCAppStatusId(ercAppStatusId);

        // (1)  When an AM changes ERC App Status to "Ready for QA":
        //          If QA Status is blank then change QA Status to "QA Ready".
        // (2)  When an AM changes ERC App Status to "Ready for QA":
        //          If "QA Submitter", "QA Date Submitted", and "QA Date Needed" are empty
        //          then set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.
        const ERCForm471AppStatusId_ReadyForQA = 20; // "Ready for QA"
        const QAReviewStatusId_QAReady = 1; // "QA Ready"
        if (ercAppStatusId === ERCForm471AppStatusId_ReadyForQA) {
            if (qaReviewStatusId == null || qaReviewStatusId === 0) {
                setQAReviewStatusId(QAReviewStatusId_QAReady);
            }

            if (qaSubmitterUserId === null && qaDateSubmitted === null && qaDateNeeded === null) {
                let qadefaultdatestrings = calcQADefaultDateStrings();
                let resolved_qa_submitter_id =
                    applicationOwnerUserId !== null && applicationOwnerUserId !== 0
                        ? applicationOwnerUserId
                        : primaryContactUserId;

                setQASubmitterUserId(resolved_qa_submitter_id);
                setQADateSubmitted(qadefaultdatestrings.todayString);
                setQADateNeeded(qadefaultdatestrings.defaultDateNeededString);
            }
        }
    };

    const calcQADefaultDateStrings = () => {
        let today1 = new Date();
        let offset = today1.getTimezoneOffset();
        let today2 = new Date(today1.getTime() - offset * 60 * 1000); // remove offset
        let defaultDateNeeded = new Date(today2);
        defaultDateNeeded.setDate(defaultDateNeeded.getDate() + 2); // TODO: Add 2 weekday/business days not calendar days.

        let todayString = today2.toISOString().split('T')[0];
        let defaultDateNeededString = defaultDateNeeded.toISOString().split('T')[0];

        // console.log('today1 = ', today1);
        // console.log('today1.toISOString() = ', today1.toISOString());
        // console.log('offset = ', offset);
        // console.log('today2 = ', today2);
        // console.log('defaultDateNeeded = ', defaultDateNeeded);
        // console.log('today2.toISOString() = ', today2.toISOString());
        // console.log('defaultDateNeeded.toISOString() = ', defaultDateNeeded.toISOString());
        // console.log('todayString = ', todayString);
        // console.log('defaultDateNeededString = ', defaultDateNeededString);

        return { todayString, defaultDateNeededString };
    };

    const handleQAReviewStatusChange = (qaReviewStatusId) => {
        setQAReviewStatusId(qaReviewStatusId);

        //  When a reviewer changes QA Status to "QA Passed" or "Will Not Be Reviewed":
        //      If ERC App Status is "Ready for QA" then change ERC App Status to "Out of QA".
        // const QAReviewStatusId_QAReturned = 3; // "QA Returned"
        const QAReviewStatusId_QAPassed = 4; // "QA Passed"
        const QAReviewStatusId_WillNotBeReviewed = 5; // "Will Not Be Reviewed"
        const ERCForm471AppStatusId_ReadyForQA = 20; // "Ready for QA"
        const ERCForm471AppStatusId_OutOfQA = 21; // "Out of QA"
        if (qaReviewStatusId === QAReviewStatusId_QAPassed || qaReviewStatusId === QAReviewStatusId_WillNotBeReviewed) {
            if (ercAppStatusId === ERCForm471AppStatusId_ReadyForQA) {
                setERCAppStatusId(ERCForm471AppStatusId_OutOfQA);
            }
        }
    };

    // Edit dropdowns options
    const [filingWindowEditOptions, setFilingWindowEditOptions] = React.useState([]);
    const [applicationOwnerEditOptions, setApplicationOwnerEditOptions] = React.useState([]);
    const [taskOwnerEditOptions, setTaskOwnerEditOptions] = React.useState([]);
    const [ercAppStatusEditOptions, setERCAppStatusEditOptions] = React.useState([]);
    const [qaReviewStatusEditOptions, setQAReviewStatusEditOptions] = React.useState([]);
    const [qaReviewerEditOptions, setQAReviewerEditOptions] = React.useState([]);
    const [qaSubmitterEditOptions, setQASubmitterEditOptions] = React.useState([]);
    const [qaEstimatedFundingRequestEditOptions, setQAEstimatedFundingRequestEditOptions] = React.useState([]);

    //~~~  Get data  ~~~
    React.useEffect(() => {
        const fetchEditOptions = async () => {
            try {
                let result = await trackersAPI.GetForm471TrackerMainReportEditOptions();
                //console.log(result);
                setFilingWindowEditOptions(result.filingWindows);
                setApplicationOwnerEditOptions(result.applicationOwners);
                setTaskOwnerEditOptions(result.taskOwners);
                setERCAppStatusEditOptions(result.ercAppStatuses);
                setQAReviewStatusEditOptions(result.qaReviewStatuses);
                setQAReviewerEditOptions(result.qaReviewers);
                setQASubmitterEditOptions(result.qaSubmitters);
                setQAEstimatedFundingRequestEditOptions(result.qaEstimatedFundingRequests);
            } catch (error) {
                //console.error(error);
                toast.error(error);
            }
        };

        const fetchForm471AppData = async (form471AppNum) => {
            try {
                let appdata = await trackersAPI.GetForm471TrackerOneAppData(form471AppNum);
                console.log('[fetchForm471AppData] appdata = ', appdata);
                setAppData(appdata);

                //--- form471app ---
                setERCAppStatusId(appdata.form471app.erc_form471_app_status_id);
                setPrimaryContactUserId(appdata.primary_contact.id);
                setApplicationOwnerUserId(appdata.form471app.application_owner_user_id); // application_owner_user_id can be null.
                if (appdata.form471app.task_users && appdata.form471app.task_users.length > 0) {
                    setTaskOwnerUserId(appdata.form471app.task_users[0].id);
                }

                setDateSentToClientToCertify(appdata.form471app.date_sent_to_client_to_certify);
                if (appdata.form471app.form471_app_notes === null) {
                    setForm471AppNotes('');
                } else {
                    setForm471AppNotes(appdata.form471app.form471_app_notes);
                }
                setFilingWindowId(appdata.form471app.filing_window_id);

                //--- qa_form471app ---
                if (appdata.qa_form471app) {
                    setQAReviewStatusId(appdata.qa_form471app.qa_review_status_id); // qa_review_status_id can be null.
                    setQAReviewerUserId(appdata.qa_form471app.qa_review_user_id); // qa_review_user_id can be null.

                    if (appdata.qa_form471app.qa_notes === null) {
                        setQANotes('');
                    } else {
                        setQANotes(appdata.qa_form471app.qa_notes);
                    }
                    setQASubmitterUserId(appdata.qa_form471app.qa_submitter_user_id);
                    setQADateSubmitted(appdata.qa_form471app.qa_date_submitted);
                    setQADateNeeded(appdata.qa_form471app.qa_date_needed);
                    setQAEstimatedFundingRequest(appdata.qa_form471app.qa_estimated_funding_request);
                }
            } catch (error) {
                //console.error(error);
                toast.error(error);
            }
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUsername(parsedObject.email.split('@')[0]);
        };

        /*const getCurrentUserID = async (cognitoID) => {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetDatabaseIDForUser';
            const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

            const idResponse = await API.get(apiName, path, queryStringParameters);
            setCurrentUserID(idResponse);
        };*/

        fetchEditOptions();
        fetchForm471AppData(form471AppNum);
        getUserData();
        //getCurrentUserID(auth.cognitoID);
    }, []);

    //~~~  Save app data  ~~~
    async function saveAppData() {
        try {
            setFormSubmitted(true);

            // Validation
            // Check for valid dates.
            if (isNotEmptyString(dateSentToClientToCertify) && !isValidInputDate(dateSentToClientToCertify)) {
                toast.error('Invalid "Date Sent to Client to Certify".');
                return false;
            }
            if (isNotEmptyString(qaDateSubmitted) && !isValidInputDate(qaDateSubmitted)) {
                toast.error('Invalid "QA Date Submitted".');
                return false;
            }
            if (isNotEmptyString(qaDateNeeded) && !isValidInputDate(qaDateNeeded)) {
                toast.error('Invalid "QA Date Needed".');
                return false;
            }

            // Null out empty date strings (needs to be addressed b/c of date input field)
            let parsedDSTCTC = dateSentToClientToCertify === '' ? null : dateSentToClientToCertify;
            let parsedQADS = qaDateSubmitted === '' ? null : qaDateSubmitted;
            let parsedQADN = qaDateNeeded === '' ? null : qaDateNeeded;

            // Save to database
            let appdatatosave = {
                form471AppNum: form471AppNum,
                ercAppStatusId: ercAppStatusId,
                applicationOwnerUserId: applicationOwnerUserId,
                taskOwnerUserId: taskOwnerUserId,
                dateSentToClientToCertify: parsedDSTCTC,
                form471AppNotes: form471AppNotes,
                filingWindowId: filingWindowId,
                qaReviewStatusId: qaReviewStatusId,
                qaReviewerUserId: qaReviewerUserId,
                qaNotes: qaNotes,
                qaSubmitterUserId: qaSubmitterUserId,
                qaDateSubmitted: parsedQADS,
                qaDateNeeded: parsedQADN,
                qaEstimatedFundingRequest: qaEstimatedFundingRequest,
            };
            //console.log('appdatatosave:', appdatatosave);

            const saveresponse = await trackersAPI.SaveForm471TrackerOneAppData(appdatatosave);
            //console.log('saveresponse:', saveresponse);

            if (saveresponse === false) {
                toast.error('Error saving app data.');
                return false;
            }

            toast.success('Successfully updated app data.');

            // Cleanup
            setFormSubmitted(false);

            // Return to source (list or details) page
            returnToSourcePage(saveresponse);
        } catch (error) {
            //console.error('Error saving app data: ', error);
            toast.error('Error saving app data.');
        }
        return false;
    }

    const isValidInputDate = (datestring) => {
        if (datestring === undefined || datestring === null || datestring === '') {
            return false;
        }
        var luxondatetime = DateTime.fromFormat(datestring, 'yyyy-MM-dd');
        return luxondatetime.isValid;
    };

    const isNotEmptyString = (str) => {
        return str !== null && str.length > 0;
    };

    //~~~  Navigation  ~~~
    const returnToSourcePage = (data) => {
        if (handleReturnToSourcePage) {
            handleReturnToSourcePage();
        } else {
            //// Go to the Main Report page by default.
            navigate('/f471/f471-applications', {
                state: {
                    updatedRow: data,
                },
            });

            // Go to the Details page by default.
            //navigate('/apply-for-discounts/f471-applications/details/' + form471AppNum);
        }
    };

    const cancel = () => {
        returnToSourcePage();
    };

    //~~~  Display functions  ~~~
    const renderDateTime = (isoDateTimeString, displayTime) => {
        // This should only be used with an ISO 8601 datetime string. `Date` does not handle "YYYY-MM-DD" correctly.
        if (isoDateTimeString) {
            try {
                let datedate = new Date(isoDateTimeString);
                let text;
                if (!displayTime) {
                    text = new Intl.DateTimeFormat('en-US').format(datedate);
                } else {
                    let dtfoptions = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    };
                    text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
                }
                return <span title={datedate}>{text}</span>;
            } catch (err) {
                console.error('renderDateTime error:', err);
            }
        }
        return isoDateTimeString;
    };

    const displayCategory12 = (category) => {
        return category === null || category === 0 || category === '0' ? '' : category;
    };

    const handleAddNoteButtonClick = (setter) => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        setter((prevNotes) => `${currentDate} (${username}) - \r\n\r\n${prevNotes}`);
    };

    //~~~  Display  ~~~
    if (!form471AppNum) {
        return (
            <Box>
                <h3>Edit Form 471 Application</h3>
                <h4 sx={{ color: 'red' }}>Missing or empty Form 471 AppNum provided.</h4>
            </Box>
        );
    }

    if (!appData) {
        return null;
    }

    return (
        <Box>
            <h2 style={{ marginTop: 0, marginBottom: '1em' }}>Edit Form 471 Application</h2>
            <h4>
                AppNum {form471AppNum} - {appData.nickname}
            </h4>

            <Box sx={{ width: '95%', margin: '0 auto' }}>
                <Box sx={{ width: 500, mt: 0.5, mb: 1 }}>
                    <Grid container spacing={1.5}>
                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            F471 Application Number:
                        </Grid>
                        <Grid item xl={8}>
                            {appData.form471AppNum}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            Funding Year:
                        </Grid>
                        <Grid item xl={8}>
                            {appData.fundingYear}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            BEN:
                        </Grid>
                        <Grid item xl={8}>
                            {appData.ben}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            Funding Program:
                        </Grid>
                        <Grid item xl={8}>
                            {appData.fundingProgram}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            Applicant Type:
                        </Grid>
                        <Grid item xl={8}>
                            {appData.applicantTypeText}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            Nickname:
                        </Grid>
                        <Grid item xl={8}>
                            {appData.nickname}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            Category of Service:
                        </Grid>
                        <Grid item xl={8}>
                            {displayCategory12(appData.category)}
                        </Grid>

                        <Grid item xl={4} sx={{ fontWeight: 'bold' }}>
                            Certified Date:
                        </Grid>
                        <Grid item xl={8}>
                            {renderDateTime(appData.certifiedDate, true)}
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='ercAppStatus-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                ERC App Status
                            </InputLabel>
                            <Select
                                id='ercAppStatus-select'
                                labelId='ercAppStatus-label'
                                label='ERC App Status'
                                labelWidth={800}
                                value={ercAppStatusId}
                                onChange={(event) => handleERCAppStatusChange(event.target.value)}
                            >
                                {ercAppStatusEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='applicationOwner-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Application Owner
                            </InputLabel>
                            <Select
                                id='applicationOwner-select'
                                labelId='applicationOwner-label'
                                label='Application Owner'
                                labelWidth={800}
                                value={applicationOwnerUserId}
                                onChange={(event) => setApplicationOwnerUserId(event.target.value)}
                            >
                                {applicationOwnerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='taskOwner-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                Task Owner
                            </InputLabel>
                            <Select
                                id='taskOwner-select'
                                labelId='taskOwner-label'
                                label='Task Owner'
                                labelWidth={800}
                                value={taskOwnerUserId}
                                onChange={(event) => setTaskOwnerUserId(event.target.value)}
                            >
                                {taskOwnerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Date Sent to Client to Certify'
                                type='date'
                                value={dateSentToClientToCertify}
                                fullWidth
                                onChange={(e) => setDateSentToClientToCertify(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(dateSentToClientToCertify) &&
                                    !isValidInputDate(dateSentToClientToCertify)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(dateSentToClientToCertify) &&
                                    !isValidInputDate(dateSentToClientToCertify)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <Button
                            variant='contained'
                            size='small'
                            sx={{ backgroundColor: '#4CAF50', marginTop: 2 }}
                            onClick={() => handleAddNoteButtonClick(setForm471AppNotes)}
                        >
                            Add Note
                        </Button>
                        <FormControl fullWidth={true} margin='normal'>
                            {/* <Button
                                variant='contained'
                                size='small'
                                sx={{ backgroundColor: '#4CAF50', marginBottom: 2 }}
                                onClick={() => handleAddNoteButtonClick(setForm471AppNotes)}
                            >
                                Add Note
                            </Button> */}
                            <TextField
                                label='Form 471 App Notes'
                                value={form471AppNotes}
                                id='form471AppNotes-textfield'
                                onChange={(event) => setForm471AppNotes(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={10}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='filingWindow-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                Filing Window
                            </InputLabel>
                            <Select
                                id='filingWindow-select'
                                labelId='filingWindow-label'
                                label='Filing Window'
                                labelWidth={800}
                                value={filingWindowId}
                                onChange={(event) => setFilingWindowId(event.target.value)}
                            >
                                {filingWindowEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='qaSubmitter-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                QA Submitter
                            </InputLabel>
                            <Select
                                id='qaSubmitter-select'
                                labelId='qaSubmitter-label'
                                label='QA Submitter'
                                labelWidth={800}
                                value={qaSubmitterUserId}
                                onChange={(event) => setQASubmitterUserId(event.target.value)}
                            >
                                {qaSubmitterEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='QA Date Submitted'
                                fullWidth
                                type='date'
                                value={qaDateSubmitted}
                                onChange={(e) => setQADateSubmitted(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateSubmitted) &&
                                    !isValidInputDate(qaDateSubmitted)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateSubmitted) &&
                                    !isValidInputDate(qaDateSubmitted)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='QA Date Needed'
                                fullWidth
                                type='date'
                                value={qaDateNeeded}
                                onChange={(e) => setQADateNeeded(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted && isNotEmptyString(qaDateNeeded) && !isValidInputDate(qaDateNeeded)
                                }
                                helperText={
                                    formSubmitted && isNotEmptyString(qaDateNeeded) && !isValidInputDate(qaDateNeeded)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='qaEstimatedFundingRequest-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                QA Estimated Funding Request
                            </InputLabel>
                            <Select
                                id='qaEstimatedFundingRequest-select'
                                labelId='qaEstimatedFundingRequest-label'
                                label='QA Estimated Funding Request'
                                labelWidth={800}
                                value={qaEstimatedFundingRequest}
                                onChange={(event) => setQAEstimatedFundingRequest(event.target.value)}
                            >
                                {qaEstimatedFundingRequestEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='qaReviewStatus-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                QA Review Status
                            </InputLabel>
                            <Select
                                id='qaReviewStatus-select'
                                labelId='qaReviewStatus-label'
                                label='QA Review Status'
                                labelWidth={800}
                                value={qaReviewStatusId}
                                onChange={(event) => handleQAReviewStatusChange(event.target.value)}
                            >
                                {qaReviewStatusEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='qaReviewer-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                QA Reviewer
                            </InputLabel>
                            <Select
                                id='qaReviewer-select'
                                labelId='qaReviewer-label'
                                label='QA Reviewer'
                                labelWidth={800}
                                value={qaReviewerUserId}
                                onChange={(event) => setQAReviewerUserId(event.target.value)}
                            >
                                {qaReviewerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <Button
                            variant='contained'
                            size='small'
                            sx={{ backgroundColor: '#4CAF50', marginTop: 2 }}
                            onClick={() => handleAddNoteButtonClick(setQANotes)}
                        >
                            Add Note
                        </Button>
                        <FormControl fullWidth={true} margin='normal'>
                            {/* <Button
                                variant='contained'
                                size='small'
                                sx={{ backgroundColor: '#4CAF50', marginBottom: 2 }}
                                onClick={() => handleAddNoteButtonClick(setQANotes)}
                            >
                                Add Note
                            </Button> */}
                            <TextField
                                label='QA Notes'
                                value={qaNotes}
                                id='qaNotes-textfield'
                                onChange={(event) => setQANotes(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={10}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{ m: 4, textAlign: 'center' }}>
                    <Button
                        sx={{ m: 1 }}
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => saveAppData()}
                    >
                        Save
                    </Button>
                    <Button sx={{ m: 1 }} variant='contained' color='secondary' size='small' onClick={() => cancel()}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

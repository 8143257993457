import * as React from 'react';
import { unstable_useId as useId, unstable_useForkRef as useForkRef } from '@mui/utils';
import MenuList from '@mui/material/MenuList';
//import { ButtonProps } from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
//import { gridDensityValueSelector } from '../../hooks/features/density/densitySelector';
//import { GridDensity } from '../../models/gridDensity';
//import { isHideMenuKey, isTabKey } from '../../utils/keyboardUtils';
//import { useGridApiContext } from '../../hooks/utils/useGridApiContext';
//import { useGridSelector } from '../../hooks/utils/useGridSelector';
//import { GridDensityOption } from '../../models/api/gridDensityApi';
//import { GridMenu, GridMenuProps } from '../menu/GridMenu';
//import { useGridRootProps } from '../../hooks/utils/useGridRootProps';
//import { gridClasses } from '../../constants/gridClasses';
import {
    gridDensityValueSelector,
    useGridApiContext,
    useGridSelector,
    GridMenu,
    useGridRootProps,
    gridClasses,
} from '@mui/x-data-grid-pro';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline'; //  DensityCompactIcon: GridViewHeadlineIcon,
import TableRowsIcon from '@mui/icons-material/TableRows'; //  DensityStandardIcon: GridTableRowsIcon,
import ViewStreamIcon from '@mui/icons-material/ViewStream'; //  DensityComfortableIcon: GridViewStreamIcon,
import Button from '@mui/material/Button'; // BaseButton: MUIButton,
//----------------------------------------------------------------------------------------------------------------------------------
//-   Custom version of GridToolbarDensitySelector with `onDensityChange`.
//-   Original source code:
//-     https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/components/toolbar/GridToolbarDensitySelector.tsx
//----------------------------------------------------------------------------------------------------------------------------------

//export const CustomGridToolbarDensitySelector = React.forwardRef<HTMLButtonElement, ButtonProps>(
export const CustomGridToolbarDensitySelector = React.forwardRef(function GridToolbarDensitySelector(props, ref) {
    //const { onClick, ...other } = props;
    const { onClick, onDensityChange, ...other } = props; // <<<<< Change from original.
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const densityValue = useGridSelector(apiRef, gridDensityValueSelector);
    const densityButtonId = useId();
    const densityMenuId = useId();

    const [open, setOpen] = React.useState(false);
    //const buttonRef = React.useRef<HTMLButtonElement>(null);
    const buttonRef = React.useRef(null);
    const handleRef = useForkRef(ref, buttonRef);

    // copied from mui-x/packages/grid/x-data-grid/src/utils/keyboardUtils.ts
    const isTabKey = (key) => key === 'Tab';
    const isHideMenuKey = (key) => isTabKey(key) || isEscapeKey(key);
    const isEscapeKey = (key) => key === 'Escape';

    //const densityOptions: GridDensityOption[] = [
    const densityOptions = [
        {
            //icon: <rootProps.slots.densityCompactIcon />,
            icon: <ViewHeadlineIcon />,
            label: apiRef.current.getLocaleText('toolbarDensityCompact'),
            value: 'compact',
        },
        {
            //icon: <rootProps.slots.densityStandardIcon />,
            icon: <TableRowsIcon />,
            label: apiRef.current.getLocaleText('toolbarDensityStandard'),
            value: 'standard',
        },
        {
            //icon: <rootProps.slots.densityComfortableIcon />,
            icon: <ViewStreamIcon />,
            label: apiRef.current.getLocaleText('toolbarDensityComfortable'),
            value: 'comfortable',
        },
    ];

    //const startIcon = React.useMemo<React.ReactElement>(() => {
    const startIcon = React.useMemo(() => {
        switch (densityValue) {
            case 'compact':
                //return <rootProps.slots.densityCompactIcon />;
                return <ViewHeadlineIcon />;
            case 'comfortable':
                //return <rootProps.slots.densityComfortableIcon />;
                return <ViewStreamIcon />;
            default:
                //return <rootProps.slots.densityStandardIcon />;
                return <TableRowsIcon />;
        }
    }, [densityValue, rootProps]);

    //const handleDensitySelectorOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const handleDensitySelectorOpen = (event) => {
        setOpen((prevOpen) => !prevOpen);
        onClick?.(event);
    };
    //const handleDensitySelectorClickAway: GridMenuProps['onClickAway'] = (event) => {
    const handleDensitySelectorClickAway = (event) => {
        if (
            buttonRef.current === event.target ||
            // if user clicked on the icon
            //buttonRef.current?.contains(event.target as Element)
            buttonRef.current?.contains(event.target)
        ) {
            return;
        }
        setOpen(false);
    };
    //const handleDensityUpdate = (newDensity: GridDensity) => {
    const handleDensityUpdate = (newDensity) => {
        apiRef.current.setDensity(newDensity);
        onDensityChange?.(newDensity); // <<<<< Addition to original.
        setOpen(false);
    };

    //const handleListKeyDown = (event: React.KeyboardEvent) => {
    const handleListKeyDown = (event) => {
        if (isTabKey(event.key)) {
            event.preventDefault();
        }
        if (isHideMenuKey(event.key)) {
            setOpen(false);
        }
    };

    // Disable the button if the corresponding is disabled
    if (rootProps.disableDensitySelector) {
        return null;
    }

    //const densityElements = densityOptions.map<React.ReactElement>((option, index) => (
    // This creates a list of mui menu items per density listed in the densityOptions to be displayed when the density button is clicked
    const densityElements = densityOptions.map((option, index) => (
        <MenuItem
            key={index}
            onClick={() => handleDensityUpdate(option.value)}
            selected={option.value === densityValue}
        >
            <ListItemIcon>{option.icon}</ListItemIcon>
            {option.label}
        </MenuItem>
    ));

    return (
        <React.Fragment>
            {/* <rootProps.slots.baseButton */}
            <Button
                ref={handleRef}
                size='small'
                startIcon={startIcon}
                aria-label={apiRef.current.getLocaleText('toolbarDensityLabel')}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='menu'
                aria-controls={densityMenuId}
                id={densityButtonId}
                {...other}
                onClick={handleDensitySelectorOpen}
                {...rootProps.slotProps?.baseButton}
            >
                {apiRef.current.getLocaleText('toolbarDensity')}
            </Button>
            {/* </rootProps.slots.baseButton> */}
            <GridMenu
                open={open}
                target={buttonRef.current}
                onClickAway={handleDensitySelectorClickAway}
                position='bottom-start'
            >
                <MenuList
                    id={densityMenuId}
                    className={gridClasses.menuList}
                    aria-labelledby={densityButtonId}
                    onKeyDown={handleListKeyDown}
                    autoFocusItem={open}
                >
                    {densityElements}
                </MenuList>
            </GridMenu>
        </React.Fragment>
    );
});

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function ClientFundingSummary(props) {
    const clientId = props.clientId;
    const kyList = props.kyList;
    const spin = props.spin;
    const state = props.state;
    const searchType = props.searchType;
    const clientAPI = props.clientAPI;
    const [clientData, setClientData] = React.useState(null);
    const [clientECFData, setClientECFData] = React.useState(null);
    const [clientFRNData, setClientFRNData] = React.useState(null);
    const [serviceProviderDisplayName, setServiceProviderDisplayName] = React.useState('');
    const [selectedYear, setSelectedYear] = React.useState(null);
    let navigate = useNavigate();
    console.log(
        '[ClientFundingSummary] clientId, kyList, spin, state, searchType:',
        clientId,
        kyList,
        spin,
        state,
        searchType
    );

    React.useEffect(() => {
        const getClientData = async () => {
            const clientdata = await clientAPI.GetClient(clientId);
            console.log('[ClientFundingSummary][useEffect][getClientData] clientdata:', clientdata);
            setClientData(clientdata);

            return clientdata;
        };

        const getECFData = async (searchType, kyList, state) => {
            const ecfdata = await clientAPI.GetClientECFData(searchType, kyList, state, null);
            setClientECFData(ecfdata);
            console.log('[ClientFundingSummary][useEffect][getECFData] ecfdata:', ecfdata);
        };

        const getFRNData = async (searchType, kyList, state) => {
            let frndata = [];

            if (spin) {
                frndata = await clientAPI.GetClientFRNData(searchType, spin, state);
            } else {
                frndata = await clientAPI.GetClientFRNData(searchType, kyList, state);
            }

            setClientFRNData(frndata);
            console.log('[ClientFundingSummary][useEffect][getFRNData] frndata:', frndata);
            return frndata;
        };

        // Trying to get the first available serviceprovidername
        const getServiceProviderName = (allFRNData) => {
            // Get all the keys of the object
            const keys = Object.keys(allFRNData);

            if (keys.length === 0) {
                return null;
            }

            // Access the last key (most recent year), first object (index 0)
            const firstKey = keys[keys.length - 1];
            const firstArray = allFRNData[firstKey];
            const firstObj = firstArray[0];

            // Check if serviceprovidername exists and is not null or undefined
            if (firstObj && firstObj.serviceprovidername) {
                return firstObj.serviceprovidername;
            }

            // If the first check fails, access the first key (earliest year), first object
            const lastKey = keys[0];
            const lastArray = allFRNData[lastKey];
            const lastObj = lastArray[0];

            // Check if serviceprovidername exists and is not null or undefined
            if (lastObj && lastObj.serviceprovidername) {
                return lastObj.serviceprovidername;
            }

            // Return null (or whatever we want) if a serviceprovidername couldn't be found
            return null;
        };

        const getAllData = async () => {
            // Getting the client data first
            const clientdata = await getClientData();

            // Using client data for the subsequent API calls
            if (searchType === 'entNum') {
                if (state) {
                    await getECFData('entNum', kyList, state);
                    await getFRNData('entNum', kyList, state);
                } else {
                    await getECFData('entNum', kyList, clientdata.state_abbrev);
                    await getFRNData('entNum', kyList, clientdata.state_abbrev);
                }
            } else if (searchType === 'spin') {
                if (spin) {
                    await getECFData('spin', spin, clientdata.state_abbrev);
                } else {
                    await getECFData('spin', kyList, clientdata.state_abbrev);
                }
                const frndatatemp = await getFRNData('spin', kyList, clientdata.state_abbrev);
                setServiceProviderDisplayName(getServiceProviderName(frndatatemp));
            } else if (searchType === 'f471') {
                await getECFData('f471', kyList, clientdata.state);
                await getFRNData('f471', kyList, clientdata.state);
            }
        };

        getAllData();
    }, [clientAPI, clientId, kyList, searchType, spin]);

    // Don't display anything until we have data.
    if (!clientData || !clientECFData || !clientFRNData) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>
                        Gathering data for Emergency Connectivity Fund Utilization & E-Rate Utilization Summary
                        Charts...
                    </em>
                </Typography>
            </Box>
        );
    }

    const createYearsOptions = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

        const startYear = 2016;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            if (year < currentYear || (year === currentYear && currentMonth >= 6)) {
                years.push(year);
            }
        }

        return years.sort((a, b) => b - a);
    };
    const years = createYearsOptions();

    const handleFundingYearSelectionChange = (event, value) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
        navigate(`/client-list/funding-year-details/${clientId}/${kyList}/${newYear}/${searchType}`);
    };

    return (
        <Box>
            {searchType === 'entNum' && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>Applicant:</h3>
                        <Link
                            href={`/client-list/contact-search-report/${clientId}/${clientData.bensList}/${searchType}`}
                            // target='_blank'
                            rel='noreferrer'
                            sx={{
                                textDecoration: 'none',
                                fontWeight: 'normal',
                                marginLeft: 1,
                                fontSize: '1.125rem',
                            }}
                        >
                            {clientData.name}
                        </Link>
                    </Box>

                    <Box sx={{ marginTop: 1 }}>
                        <h3 style={{ margin: 0 }}>
                            Billed Entity Number{kyList.includes(',') ? 's' : ''}:{' '}
                            <span style={{ fontWeight: 'normal' }}>{kyList}</span>
                        </h3>
                    </Box>
                </>
            )}

            {searchType === 'spin' && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>
                            Service Provider: <span style={{ fontWeight: 'normal' }}>{serviceProviderDisplayName}</span>
                        </h3>
                    </Box>

                    <Box sx={{ marginTop: 1 }}>
                        <h3 style={{ margin: 0 }}>
                            SPIN{kyList.includes(',') ? 's' : ''}:{' '}
                            <span style={{ fontWeight: 'normal' }}>{spin ? spin : kyList}</span>
                        </h3>
                    </Box>
                </>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ margin: 0, marginRight: 4 }}>{`Funding Year:   `}</h3>
                <FormControl sx={{ minWidth: 100 }}>
                    <InputLabel
                        id='funding-year-selection-label'
                        sx={{
                            top: '20%',
                            transform: 'translateX(80%)',
                        }}
                    >
                        Year
                    </InputLabel>
                    <Select
                        id='funding-year-selection'
                        label='Funding Year Selection'
                        value={selectedYear}
                        defaultValue={null}
                        onChange={handleFundingYearSelectionChange}
                        sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* TODO: handle searchType === 'f471' */}

            <Box sx={{ flexBasis: '700px' }}>
                <ClientFundingSummarySection
                    clientId={clientId}
                    kyList={kyList}
                    spin={spin}
                    searchType={searchType}
                    clientECFData={clientECFData}
                    clientFRNData={clientFRNData}
                />
            </Box>
        </Box>
    );
}

function ClientFundingSummarySection({ clientId, kyList, spin, searchType, clientECFData, clientFRNData }) {
    const [ecfOrder, setECFOrder] = React.useState('asc');
    const [frnOrder, setFRNOrder] = React.useState('asc');
    const [ecfOrderBy, setECFOrderBy] = React.useState('');
    const [frnOrderBy, setFRNOrderBy] = React.useState('');
    const [newClientECFData, setNewClientECFData] = React.useState([]);
    const [newClientFRNData, setNewClientFRNData] = React.useState([]);

    React.useEffect(() => {
        const processedECFResults = processAllECFData(clientECFData);
        console.log('4) processedECFResults: ', processedECFResults);

        const processedFRNResults = processAllFRNData(clientFRNData);
        console.log('5) processedFRNResults: ', processedFRNResults);

        setNewClientFRNData(processedFRNResults);
        setNewClientECFData(processedECFResults);
    }, [clientECFData, clientFRNData]);

    // Don't display anything until we have data.
    if (!clientECFData || !clientFRNData) {
        return null;
    }

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => {
        // Checking if the value is 0 or "0"
        if (value === 0 || value === '0' || !value) {
            return '$0.00';
        }

        // Ensuring the value is a number
        const numericValue = parseFloat(value);

        // Formatting the number to money
        return `$${numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };

    const ecfHeaders = [
        { id: 'filingwindow', label: 'Filing Window' },
        { id: 'ecfFRNCountTotal', label: 'Requested FRNs' },
        { id: 'ecfFRNFundedCountTotal', label: 'Funded FRNs' },
        { id: 'ecfOriginalRequestTotal', label: 'Requested Amount' },
        { id: 'ecfSubtotalEquipmentTotal', label: 'Committed Equipment' },
        { id: 'ecfSubtotalServicesTotal', label: 'Committed Services' },
        { id: 'ecfCommittedRequestTotal', label: 'Total Committed' },
        { id: 'ecfDisbursedTotal', label: 'Total Disbursed' },
        { id: 'ecfRemainingTotal', label: 'Remaining Balance' },
        { id: 'ecfUtilized', label: 'Util.%' },
    ];

    const frnHeaders = [
        { id: 'fundingYear', label: 'Funding Year' },
        { id: 'numReq', label: 'Requested FRNs' },
        { id: 'numFunded', label: 'Funded FRNs' },
        { id: 'num486', label: '486 on File' },
        { id: 'amtOrigReq', label: 'Requested Amount' },
        { id: 'amtPriority1', label: 'Category 1' },
        { id: 'amtPriority2', label: 'Category 2' },
        { id: 'amtComm', label: 'Total Committed' },
        { id: 'amtDisb', label: 'Total Disbursed' },
        { id: 'amtBal', label: 'Remaining Balance' },
        { id: 'pcUtil', label: 'Util.%' },
    ];

    const handleECFRequestSort = (property) => {
        const isAsc = ecfOrderBy === property && ecfOrder === 'asc';
        setECFOrder(isAsc ? 'desc' : 'asc');
        setECFOrderBy(property);
    };

    const handleFRNRequestSort = (property) => {
        const isAsc = frnOrderBy === property && frnOrder === 'asc';
        setFRNOrder(isAsc ? 'desc' : 'asc');
        setFRNOrderBy(property);
    };

    const sortedECFData = [...newClientECFData].sort((a, b) => {
        if (ecfOrderBy) {
            let aValue = a[ecfOrderBy];
            let bValue = b[ecfOrderBy];

            // Convert monetary values to numbers for sorting
            if (
                ecfOrderBy.startsWith('ecfOriginalRequestTotal') ||
                ecfOrderBy.startsWith('ecfSubtotalEquipmentTotal') ||
                ecfOrderBy.startsWith('ecfSubtotalServicesTotal') ||
                ecfOrderBy.startsWith('ecfCommittedRequestTotal') ||
                ecfOrderBy.startsWith('ecfDisbursedTotal') ||
                ecfOrderBy.startsWith('ecfRemainingTotal')
            ) {
                aValue = parseFloat(aValue.toString().replace(/[$,]/g, ''));
                bValue = parseFloat(bValue.toString().replace(/[$,]/g, ''));
            }

            // Convert utilization percentage to number for sorting
            if (ecfOrderBy === 'ecfUtilized') {
                aValue = parseFloat(aValue);
                bValue = parseFloat(bValue);
            }

            // Handle sorting based on the value type
            if (typeof aValue === 'number') {
                return ecfOrder === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string') {
                return ecfOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        }
        return 0;
    });

    const sortedFRNData = Object.keys(newClientFRNData)
        .sort((a, b) => b - a) // Sorting years in descending order
        .map((year) => ({
            year, // Include the year as a property
            ...newClientFRNData[year],
        }))
        .sort((a, b) => {
            if (frnOrderBy) {
                let aValue = a[frnOrderBy];
                let bValue = b[frnOrderBy];

                if (frnOrderBy === 'fundingYear') {
                    aValue = parseInt(a.year);
                    bValue = parseInt(b.year);
                    return frnOrder === 'asc' ? aValue - bValue : bValue - aValue;
                }

                // Convert monetary values or percentages to numbers for sorting
                if (frnOrderBy.startsWith('amt') || frnOrderBy === 'pcUtil') {
                    aValue = parseMoney(aValue);
                    bValue = parseMoney(bValue);
                }

                // Handle sorting based on the value type
                if (typeof aValue === 'number') {
                    return frnOrder === 'asc' ? aValue - bValue : bValue - aValue;
                } else if (typeof aValue === 'string') {
                    return frnOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
            }
            return 0;
        });

    const processEachECFObj = (ea_window_data) => {
        if (searchType === 'entNum') {
            let acc = {
                ecfUtilized: 0,
                ecfRemaining: 0,
                ecfFRNCountTotal: 0,
                ecfFRNFundedCountTotal: 0,
                ecfOriginalRequestTotal: 0,
                ecfSubtotalEquipmentTotal: 0,
                ecfSubtotalServicesTotal: 0,
                ecfCommittedRequestTotal: 0,
                ecfDisbursedTotal: 0,
                ecfRemainingTotal: 0,
            };

            // Calculate Utilization Percentage
            if (ea_window_data.committedrequest > 0 && ea_window_data.disbursement > 0) {
                acc.ecfUtilized = parseFloat(ea_window_data.disbursement / ea_window_data.committedrequest);
            } else {
                acc.ecfUtilized = 0;
            }

            // Calculate Remaining Balance
            if (ea_window_data.committedrequest > 0) {
                if (ea_window_data.disbursement > 0) {
                    acc.ecfRemaining = parseFloat(ea_window_data.committedrequest - ea_window_data.disbursement);
                } else {
                    acc.ecfRemaining = parseFloat(ea_window_data.committedrequest);
                }
            }

            // ECF Totals Calculation
            acc.ecfFRNCountTotal = ea_window_data.frncount || 0;
            acc.ecfFRNFundedCountTotal = ea_window_data.frncountcommitted || 0;
            // acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest.replace(/[$,]/g, '')) || 0;
            // acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment.replace(/[$,]/g, '')) || 0;
            // acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices.replace(/[$,]/g, '')) || 0;
            // acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.committedrequest.replace(/[$,]/g, '')) || 0;
            // acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement.replace(/[$,]/g, '')) || 0;

            acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest) || 0;
            acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment) || 0;
            acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices) || 0;
            acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.committedrequest) || 0;
            acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement) || 0;
            acc.ecfRemainingTotal = acc.ecfCommittedRequestTotal - acc.ecfDisbursedTotal;

            return acc;
        } else if (searchType === 'spin') {
            if (ea_window_data.filingwindow === 'ECF Window 1') {
                let acc = {
                    ecfUtilized: 0,
                    ecfRemaining: 0,
                    ecfFRNCountTotal: 0,
                    ecfFRNFundedCountTotal: 0,
                    ecfOriginalRequestTotal: 0,
                    ecfSubtotalEquipmentTotal: 0,
                    ecfSubtotalServicesTotal: 0,
                    ecfCommittedRequestTotal: 0,
                    ecfDisbursedTotal: 0,
                    ecfRemainingTotal: 0,
                };

                // Calculate Utilization Percentage
                if (ea_window_data.currentfrnapprovedamount > 0 && ea_window_data.disbursement > 0) {
                    acc.ecfUtilized = parseFloat(ea_window_data.disbursement / ea_window_data.currentfrnapprovedamount); // ECF Window 1 = currentfrnapprovedamount,
                } else {
                    acc.ecfUtilized = 0;
                }

                // Calculate Remaining Balance
                if (ea_window_data.currentfrnapprovedamount > 0) {
                    if (ea_window_data.disbursement > 0) {
                        acc.ecfRemaining = parseFloat(
                            ea_window_data.currentfrnapprovedamount - ea_window_data.disbursement
                        );
                    } else {
                        acc.ecfRemaining = parseFloat(ea_window_data.currentfrnapprovedamount);
                    }
                }

                // ECF Totals Calculation
                acc.ecfFRNCountTotal = ea_window_data.frncount || 0;
                acc.ecfFRNFundedCountTotal = ea_window_data.frncountcommitted || 0;
                // acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest.replace(/[$,]/g, '')) || 0;
                // acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment.replace(/[$,]/g, '')) || 0;
                // acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices.replace(/[$,]/g, '')) || 0;
                // acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.currentfrnapprovedamount.replace(/[$,]/g, '')) || 0; // If SPIN + window 1, use currentfrnapprovedamount instead of committedrequest
                // acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement.replace(/[$,]/g, '')) || 0;

                acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest) || 0;
                acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment) || 0;
                acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices) || 0;
                acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.currentfrnapprovedamount) || 0; // If SPIN + window 1, use currentfrnapprovedamount instead of committedrequest. Maybe change name and handle it?
                acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement) || 0;
                acc.ecfRemainingTotal = acc.ecfCommittedRequestTotal - acc.ecfDisbursedTotal;

                return acc;
            } else {
                let acc = {
                    ecfUtilized: 0,
                    ecfRemaining: 0,
                    ecfFRNCountTotal: 0,
                    ecfFRNFundedCountTotal: 0,
                    ecfOriginalRequestTotal: 0,
                    ecfSubtotalEquipmentTotal: 0,
                    ecfSubtotalServicesTotal: 0,
                    ecfCommittedRequestTotal: 0,
                    ecfDisbursedTotal: 0,
                    ecfRemainingTotal: 0,
                };

                // Calculate Utilization Percentage
                if (ea_window_data.committedrequest > 0 && ea_window_data.disbursement > 0) {
                    acc.ecfUtilized = parseFloat(ea_window_data.disbursement / ea_window_data.committedrequest); // ECF Window 2, 3 = committedrequest,
                } else {
                    acc.ecfUtilized = 0;
                }

                // Calculate Remaining Balance
                if (ea_window_data.committedrequest > 0) {
                    if (ea_window_data.disbursement > 0) {
                        acc.ecfRemaining = parseFloat(ea_window_data.committedrequest - ea_window_data.disbursement);
                    } else {
                        acc.ecfRemaining = parseFloat(ea_window_data.committedrequest);
                    }
                }

                // ECF Totals Calculation
                acc.ecfFRNCountTotal = ea_window_data.frncount || 0;
                acc.ecfFRNFundedCountTotal = ea_window_data.frncountcommitted || 0;
                // acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest.replace(/[$,]/g, '')) || 0;
                // acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment.replace(/[$,]/g, '')) || 0;
                // acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices.replace(/[$,]/g, '')) || 0;
                // acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.currentfrnapprovedamount.replace(/[$,]/g, '')) || 0; // If SPIN + window 1, use currentfrnapprovedamount instead of committedrequest
                // acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement.replace(/[$,]/g, '')) || 0;

                acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest) || 0;
                acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment) || 0;
                acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices) || 0;
                acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.committedrequest) || 0; // If SPIN + window 1, use currentfrnapprovedamount instead of committedrequest. Maybe change name and handle it?
                acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement) || 0;
                acc.ecfRemainingTotal = acc.ecfCommittedRequestTotal - acc.ecfDisbursedTotal;

                return acc;
            }
        }
    };

    // Handles multiple ECF Window duplicates (due to multiple BENs being provided)
    const processAllECFData = (ecfData) => {
        const groupedData = ecfData.reduce((acc, ea_obj) => {
            const { filingwindow } = ea_obj;

            if (!acc[filingwindow]) {
                acc[filingwindow] = {
                    filingwindow,
                    ecfUtilized: 0,
                    ecfRemaining: 0,
                    ecfFRNCountTotal: 0,
                    ecfFRNFundedCountTotal: 0,
                    ecfOriginalRequestTotal: 0,
                    ecfSubtotalEquipmentTotal: 0,
                    ecfSubtotalServicesTotal: 0,
                    ecfCommittedRequestTotal: 0,
                    ecfDisbursedTotal: 0,
                    ecfRemainingTotal: 0,
                };
            }

            const processedData = processEachECFObj(ea_obj);

            // Aggregate the data
            acc[filingwindow].ecfUtilized += processedData.ecfUtilized || 0;
            acc[filingwindow].ecfRemaining += processedData.ecfRemaining || 0;
            acc[filingwindow].ecfFRNCountTotal += processedData.ecfFRNCountTotal || 0;
            acc[filingwindow].ecfFRNFundedCountTotal += processedData.ecfFRNFundedCountTotal || 0;
            acc[filingwindow].ecfOriginalRequestTotal += processedData.ecfOriginalRequestTotal || 0;
            acc[filingwindow].ecfSubtotalEquipmentTotal += processedData.ecfSubtotalEquipmentTotal || 0;
            acc[filingwindow].ecfSubtotalServicesTotal += processedData.ecfSubtotalServicesTotal || 0;
            acc[filingwindow].ecfCommittedRequestTotal += processedData.ecfCommittedRequestTotal || 0;
            acc[filingwindow].ecfDisbursedTotal += processedData.ecfDisbursedTotal || 0;
            acc[filingwindow].ecfRemainingTotal += processedData.ecfRemainingTotal || 0;

            return acc;
        }, {});

        // Convert the result back to an array
        return Object.values(groupedData).map((windowData) => ({
            filingwindow: windowData.filingwindow,
            ecfUtilized: (windowData.ecfUtilized * 100).toFixed(1),
            ecfRemaining: windowData.ecfRemaining,
            ecfFRNCountTotal: windowData.ecfFRNCountTotal,
            ecfFRNFundedCountTotal: windowData.ecfFRNFundedCountTotal,
            ecfOriginalRequestTotal: windowData.ecfOriginalRequestTotal,
            ecfSubtotalEquipmentTotal: windowData.ecfSubtotalEquipmentTotal,
            ecfSubtotalServicesTotal: windowData.ecfSubtotalServicesTotal,
            ecfCommittedRequestTotal: windowData.ecfCommittedRequestTotal,
            ecfDisbursedTotal: windowData.ecfDisbursedTotal,
            ecfRemainingTotal: windowData.ecfRemainingTotal,
        }));
    };

    const calculateECFTotals = (processedData) => {
        let acc = {
            ecfUtilized: 0,
            ecfRemaining: 0,
            ecfFRNCountTotal: 0,
            ecfFRNFundedCountTotal: 0,
            ecfOriginalRequestTotal: 0,
            ecfSubtotalEquipmentTotal: 0,
            ecfSubtotalServicesTotal: 0,
            ecfCommittedRequestTotal: 0,
            ecfDisbursedTotal: 0,
            ecfRemainingTotal: 0,
        };

        processedData.forEach((ea_obj) => {
            // Accumulate monetary values as numbers
            acc.ecfRemaining += parseFloat(ea_obj.ecfRemaining) || 0;
            acc.ecfOriginalRequestTotal += parseFloat(ea_obj.ecfOriginalRequestTotal) || 0;
            acc.ecfSubtotalEquipmentTotal += parseFloat(ea_obj.ecfSubtotalEquipmentTotal) || 0;
            acc.ecfSubtotalServicesTotal += parseFloat(ea_obj.ecfSubtotalServicesTotal) || 0;
            acc.ecfCommittedRequestTotal += parseFloat(ea_obj.ecfCommittedRequestTotal) || 0;
            acc.ecfDisbursedTotal += parseFloat(ea_obj.ecfDisbursedTotal) || 0;
            acc.ecfRemainingTotal += parseFloat(ea_obj.ecfRemainingTotal) || 0;

            // Accumulate the "count" columns
            acc.ecfFRNCountTotal += ea_obj.ecfFRNCountTotal || 0;
            acc.ecfFRNFundedCountTotal += ea_obj.ecfFRNFundedCountTotal || 0;

            // Calculating the total utilization percentage
            if (ea_obj.ecfCommittedRequestTotal > 0) {
                const utilization = ea_obj.ecfDisbursedTotal / ea_obj.ecfCommittedRequestTotal;
                acc.ecfUtilized += utilization;
            }
        });

        // Calculating the total average utilization percentage
        acc.ecfUtilized = ((acc.ecfDisbursedTotal / acc.ecfCommittedRequestTotal) * 100).toFixed(0);

        return acc;
    };

    const processEachFRNObj = (ea_year_data) => {
        return ea_year_data.reduce(
            (acc, item) => {
                item.frncommittedamount = parseMoney(item.frncommittedamount);
                item.origfundingrequest = parseMoney(item.origfundingrequest);
                item.totalauthorizeddisbursement = parseMoney(item.totalauthorizeddisbursement);

                // Count the number of requests (numReq)
                acc.numReq += 1;

                // Count the number of funded FRNs (numFunded)
                if (item.frnstatus.toLowerCase() === 'funded') {
                    acc.numFunded += 1;

                    if (item.f486servicestartdate && item.f486servicestartdate.length > 0) {
                        acc.num486 += 1;
                    }
                }

                if (item.origfundingrequest && item.origfundingrequest >= 0) {
                    acc.amtOrigReq += item.origfundingrequest;
                }

                if (item.cmtdfrnservicetype && item.cmtdfrnservicetype.length > 0) {
                    switch (item.cmtdfrnservicetype) {
                        case 'Data Transmission and/or Internet Access':
                            acc.amtIA += item.frncommittedamount;
                            acc.amtPriority1 += item.frncommittedamount;
                            break;

                        case 'Internal Connections':
                            acc.amtIC += item.frncommittedamount;
                            acc.amtPriority2 += item.frncommittedamount;
                            break;

                        case 'Basic Maintenance of Internal Connections':
                            acc.amtICM += item.frncommittedamount;
                            acc.amtPriority2 += item.frncommittedamount;
                            break;

                        case 'Managed Internal Broadband Services':
                            acc.amtICB += item.frncommittedamount;
                            acc.amtPriority2 += item.frncommittedamount;
                            break;
                        default:
                    }
                }

                // Commitments
                if (item.frncommittedamount > 0) {
                    acc.amtComm += item.frncommittedamount;
                }

                // Disbursed
                if (item.totalauthorizeddisbursement > 0) {
                    acc.amtDisb += item.totalauthorizeddisbursement;
                }

                acc.amtBal = acc.amtComm - acc.amtDisb;

                if (acc.amtComm > 0 && acc.amtDisb > 0) {
                    acc.pcUtil = acc.amtDisb / acc.amtComm;
                }
                return acc;
            },
            {
                numReq: 0,
                numFunded: 0,
                num486: 0,
                amtOrigReq: 0,
                amtPriority1: 0,
                amtPriority2: 0,
                amtComm: 0,
                amtDisb: 0,
                amtBal: 0,
                pcUtil: 0,
            }
        );
    };

    const processAllFRNData = (frnData) => {
        const result = {};

        Object.keys(frnData).forEach((ea_year_key) => {
            const yearData = frnData[ea_year_key]; // Gets the data for the specific iteration's corresponding year
            const processedData = processEachFRNObj(yearData); // Each specific year COULD hold multiple objects. In the case of 2024, 4 are being passed in here

            result[ea_year_key.replace('aryData', '')] = {
                numReq: processedData.numReq,
                numFunded: processedData.numFunded,
                num486: processedData.num486,
                amtOrigReq: formatMoney(processedData.amtOrigReq),
                amtPriority1: formatMoney(processedData.amtPriority1),
                amtPriority2: formatMoney(processedData.amtPriority2),
                amtComm: formatMoney(processedData.amtComm),
                amtDisb: formatMoney(processedData.amtDisb),
                amtBal: formatMoney(processedData.amtBal),
                pcUtil: (processedData.pcUtil * 100).toFixed(1),
            };
        });

        return result;
    };

    const calculateFRNTotals = (processedData) => {
        return Object.values(processedData).reduce(
            (acc, yearData) => {
                acc.numReq += yearData.numReq;
                acc.numFunded += yearData.numFunded;
                acc.num486 += yearData.num486;
                acc.amtOrigReq += parseMoney(yearData.amtOrigReq);
                acc.amtPriority1 += parseMoney(yearData.amtPriority1);
                acc.amtPriority2 += parseMoney(yearData.amtPriority2);
                acc.amtComm += parseMoney(yearData.amtComm);
                acc.amtDisb += parseMoney(yearData.amtDisb);
                acc.amtBal += parseMoney(yearData.amtBal);
                acc.pcUtil += parseFloat(yearData.pcUtil.replace('%', ''));

                return acc;
            },
            {
                numReq: 0,
                numFunded: 0,
                num486: 0,
                amtOrigReq: 0,
                amtPriority1: 0,
                amtPriority2: 0,
                amtComm: 0,
                amtDisb: 0,
                amtBal: 0,
                pcUtil: 0,
            }
        );
    };

    // Used for E-Rate Organizer Utilization Summary Chart's totals column
    const ecfTotals = calculateECFTotals(newClientECFData);
    const frnTotals = calculateFRNTotals(newClientFRNData);
    const totalYears = Object.keys(newClientFRNData).length;

    return (
        <Box>
            {/* E-Rate Utilization Summary Chart */}
            <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                E-Rate Utilization Summary Chart
            </h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {frnHeaders.map((header) => (
                                <TableCell
                                    key={header.id}
                                    sortDirection={frnOrderBy === header.id ? frnOrder : false}
                                    sx={{ padding: '4px 8px' }}
                                >
                                    <TableSortLabel
                                        active={frnOrderBy === header.id}
                                        direction={frnOrderBy === header.id ? frnOrder : 'asc'}
                                        onClick={() => handleFRNRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedFRNData.map((yearData, index) => (
                            <TableRow
                                key={`${yearData.year}-${index}`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={
                                            spin
                                                ? `/client-list/funding-year-details/${clientId}/${spin}/${yearData.year}/${searchType}`
                                                : `/client-list/funding-year-details/${clientId}/${kyList}/${yearData.year}/${searchType}`
                                        }
                                        // target='_blank'
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {yearData.year}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.numReq}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.numFunded}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.num486}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.amtOrigReq}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.amtPriority1}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.amtPriority2}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.amtComm}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.amtDisb}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{yearData.amtBal}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {isNaN(yearData.pcUtil) ? '0.0%' : `${yearData.pcUtil}%`}
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow sx={{ backgroundColor: sortedFRNData.length % 2 === 0 ? 'white' : '#f5f5f5' }}>
                            <TableCell sx={{ padding: '4px 8px' }}>Totals</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{frnTotals.numReq}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{frnTotals.numFunded}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{frnTotals.num486}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{formatMoney(frnTotals.amtOrigReq)}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{formatMoney(frnTotals.amtPriority1)}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{formatMoney(frnTotals.amtPriority2)}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{formatMoney(frnTotals.amtComm)}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{formatMoney(frnTotals.amtDisb)}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{formatMoney(frnTotals.amtBal)}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {isNaN(frnTotals.pcUtil / totalYears)
                                    ? '0.0%'
                                    : `${(frnTotals.pcUtil / totalYears).toFixed(1)}%`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Emergency Connectivity Fund Utilization Summary Chart */}
            <h4 style={{ color: '#19638B', marginTop: '50px', marginBottom: '10px' }}>
                Emergency Connectivity Fund Utilization Summary Chart
            </h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {ecfHeaders.map((header) => (
                                <TableCell
                                    key={header.id}
                                    sortDirection={ecfOrderBy === header.id ? ecfOrder : false}
                                    sx={{ padding: '4px 8px' }}
                                >
                                    <TableSortLabel
                                        active={ecfOrderBy === header.id}
                                        direction={ecfOrderBy === header.id ? ecfOrder : 'asc'}
                                        onClick={() => handleECFRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedECFData.map((rowData, index) => (
                            <TableRow
                                key={`${rowData.filingwindow}-${index}`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {rowData.filingwindow === 'ECF Window 1' ? (
                                        <Link
                                            href={`/client-list/funding-history-details/${clientId}/${
                                                spin ? spin : kyList
                                            }/ECF/${searchType}`}
                                            // target='_blank'
                                            rel='noreferrer'
                                            sx={{
                                                textDecoration: 'none',
                                                fontWeight: 'normal',
                                                fontSize: '.9rem',
                                            }}
                                        >
                                            {rowData.filingwindow}
                                        </Link>
                                    ) : (
                                        rowData.filingwindow
                                    )}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.ecfFRNCountTotal}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{rowData.ecfFRNFundedCountTotal}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(rowData.ecfOriginalRequestTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(rowData.ecfSubtotalEquipmentTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(rowData.ecfSubtotalServicesTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(rowData.ecfCommittedRequestTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(rowData.ecfDisbursedTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {formatMoney(rowData.ecfRemainingTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {isNaN(rowData.ecfUtilized) ? '0.0%' : `${rowData.ecfUtilized}%`}
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell sx={{ padding: '4px 8px' }}>Totals</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{ecfTotals.ecfFRNCountTotal}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>{ecfTotals.ecfFRNFundedCountTotal}</TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {formatMoney(ecfTotals.ecfOriginalRequestTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {formatMoney(ecfTotals.ecfSubtotalEquipmentTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {formatMoney(ecfTotals.ecfSubtotalServicesTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {formatMoney(ecfTotals.ecfCommittedRequestTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {formatMoney(ecfTotals.ecfDisbursedTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {formatMoney(ecfTotals.ecfRemainingTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px' }}>
                                {isNaN(ecfTotals.ecfUtilized) ? '0.0%' : `${ecfTotals.ecfUtilized}%`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='body2' sx={{ marginTop: 5 }}>
                <strong>Service Legend:</strong> Category 1 includes Telecomm, Voice, Data Transmission and/or Internet
                Access; Category 2 includes Internal Connections, Internal Connections Maintenance and Managed Internal
                Broadband Services
            </Typography>
        </Box>
    );
}

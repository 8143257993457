import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MultiSelect from './MultiSelect';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserAPI from '../api/UserAPI';

function ManageAddTeams(props) {
    const userAPI = props.api;
    const TabPanel = props.TabPanel;

    // Team Table
    const [originalTeamRows, setOriginalTeamRows] = React.useState([]);
    const [teamRows, setTeamRows] = React.useState([]);
    const [teamRowsPerPage, setTeamRowsPerPage] = React.useState(10);
    const [teamPage, setTeamPage] = React.useState(0);
    const emptyTeamRows = teamRowsPerPage - Math.min(teamRowsPerPage, teamRows.length - teamPage * teamRowsPerPage);
    const [selectedOrgID, setSelectedOrgID] = React.useState('');
    const [teamSearchTerm, setTeamSearchTerm] = React.useState('');
    const [orgs, setOrgs] = React.useState([]);

    // Save Team
    const [teamID, setTeamID] = React.useState(-1);
    const [selectedTeamID, setSelectedTeamID] = React.useState('');
    const [teamSubmitted, setTeamSubmitted] = React.useState(false);
    const [teamName, setTeamName] = React.useState('');
    const [teamDisplayName, setTeamDisplayName] = React.useState('');
    const [teamDefault, setTeamDefault] = React.useState(false);
    const [teamOrgID, setTeamOrgID] = React.useState('');
    const [teamMembers, setTeamMembers] = React.useState([]);
    const [hasClickedView, setHasClickedView] = React.useState(false);

    // Add User
    const [addUserOpen, setAddUserOpen] = React.useState(false);
    const [userRows, setUserRows] = React.useState([]);
    const [selectedUserID, setSelectedUserID] = React.useState('');

    // Add Team
    const [addNewTeamOpen, setAddNewTeamOpen] = React.useState(false);

    // Edit Team
    const [editExistingTeamOpen, setEditExistingTeamOpen] = React.useState(false);

    // View Team
    const [viewExistingTeamOpen, setViewExistingTeamOpen] = React.useState(false);

    // Locals
    const selectTeams = React.useRef(new Map());

    const fetchTeams = async () => {
        let teams = await userAPI.GetTeams();
        teams.sort((a, b) => a.display_name.localeCompare(b.display_name));
        console.log('ManageAddTeams: GetTeams/teams =', teams);

        setOriginalTeamRows(teams);
        setTeamRows(teams);

        selectTeams.current = new Map();
        teams.forEach((team) => {
            selectTeams.current.set(team.id, team.display_name);
        });
    };

    const fetchUsers = async () => {
        let users = await userAPI.GetUsers();
        const parsedUsers = JSON.parse(users.body);
        console.log('ManageAddTeams: GetUsers parsed =', parsedUsers);

        sortFirstName(parsedUsers, setUserRows);
    };

    React.useEffect(() => {
        const fetchOrgs = async () => {
            let orgs = await userAPI.GetOrgs();
            // console.log('ManageAddTeams: orgs =', orgs);

            orgs.sort((a, b) => a.display_name.localeCompare(b.display_name));
            setOrgs(orgs);
        };

        fetchUsers();
        fetchTeams();
        fetchOrgs();
    }, []);

    // Team Table
    const handleChangePage = (event, newPage) => {
        setTeamPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setTeamRowsPerPage(parseInt(event.target.value, 10));
        setTeamPage(0);
    };

    const handleTeamTextFilter = (event) => {
        setTeamSearchTerm(event.target.value);
        filterTeamTable(event.target.value, selectedOrgID);
    };

    const handleTeamOrgFilter = (event) => {
        setSelectedOrgID(event.target.value);
        filterTeamTable(teamSearchTerm, event.target.value);
    };

    const filterTeamTable = (teamSearchTerm, orgID) => {
        setTeamPage(0);
        setTeamRowsPerPage(10);
        setTeamRows(
            originalTeamRows.filter(
                (row) =>
                    (row.display_name.toLowerCase().includes(teamSearchTerm.toLowerCase()) ||
                        row.organization.display_name.toLowerCase().includes(teamSearchTerm.toLowerCase())) &&
                    (row.organization_id === orgID || orgID === '')
            )
        );
    };

    const sortFirstName = (obj, setState) => {
        let sortedObj = obj.sort((a, b) => {
            if (a.first_name < b.first_name) return -1;
            if (a.first_name > b.first_name) return 1;
            return 0;
        });
        setState(sortedObj);
    };

    function endsWithTeam(teamName) {
        const lowercaseName = teamName.toLowerCase();
        return lowercaseName.endsWith('team') || lowercaseName.split(-4).pop().endsWith('team');
    }

    // ---------- Submit ----------
    async function submitNewTeam() {
        try {
            setTeamSubmitted(true);
            if (teamName === '' || teamDisplayName === '' || teamOrgID === '') {
                // Validation
                toast.error('Please complete all required fields');
            } else {
                let teamInfo = {
                    id: teamID,
                    name: teamName,
                    display_name: teamDisplayName,
                    active: true,
                    default: teamDefault,
                    organization_id: teamOrgID,
                };

                await userAPI.SaveTeam(teamInfo);
                let successMessage = 'Successfully created team';
                toast.success(successMessage);
                setTeamSubmitted(false);
                fetchTeams();
                resetTeamFields();
                setAddNewTeamOpen(false);
            }
        } catch (error) {
            toast.error('Error saving team');
        }
    }
    async function submitEditTeam() {
        try {
            setTeamSubmitted(true);
            if (teamName === '' || teamDisplayName === '' || teamOrgID === '') {
                // Validation
                toast.error('Please complete all required fields');
            } else {
                let teamInfo = {
                    id: teamID,
                    name: teamName,
                    display_name: teamDisplayName,
                    active: true,
                    default: teamDefault,
                    organization_id: teamOrgID,
                };

                await userAPI.SaveTeam(teamInfo);
                let successMessage = 'Successfully updated team';
                toast.success(successMessage);
                setTeamSubmitted(false);
                fetchTeams();
                resetTeamFields();
                setEditExistingTeamOpen(false);
            }
        } catch (error) {
            toast.error('Error saving team');
        }
    }
    async function submitViewTeamMembers(orgID, teamID) {
        setHasClickedView(true);

        if (!teamID) {
            toast.error('No team ID provided!');
            return;
        }
        const providedOrStateID = teamID || selectedTeamID;
        const response = await userAPI.GetTeamMembers(orgID, providedOrStateID);
        console.log('ManageAddTeams: teamMembers =', response);

        if (Array.isArray(response) && response.length > 0) {
            sortFirstName(response, setTeamMembers);
        } else {
            setTeamMembers(null);
        }
    }

    // ---------- Reset Fields ----------
    function resetTeamFields() {
        setTeamID(-1);
        setTeamName('');
        setTeamDisplayName('');
        setTeamDefault(false);
        setTeamOrgID('');
    }

    // ---------- Remove User From Team ----------
    async function removeUserFromTeam(team_display_name, member, selected_team_id, team_org_id) {
        try {
            let confirmationMessage = endsWithTeam(teamName)
                ? `Are you sure you want to remove ${member.first_name} ${member.last_name} from the ${team_display_name}?`
                : `Are you sure you want to remove ${member.first_name} ${member.last_name} from the ${team_display_name} team?`;
            const confirmation = window.confirm(confirmationMessage);
            if (!confirmation) return;

            await userAPI.RemoveUserFromTeam(member.id, selected_team_id, team_org_id);
            toast.success('Removed user from team');
        } catch (error) {
            toast.error('Error removing user from team');
        }
    }

    // ---------- Delete Team ----------
    async function deleteTeam(teamDetails) {
        try {
            let confirmationMessage = endsWithTeam(teamDetails.display_name)
                ? `Are you sure you want to delete the ${teamDetails.display_name}?`
                : `Are you sure you want to delete the ${teamDetails.display_name} team?`;
            const confirmation = window.confirm(confirmationMessage);
            if (!confirmation) return;

            await userAPI.DeleteTeam(teamDetails.id);
            toast.success('Deleted team');
        } catch (error) {
            toast.error('Error deleting team');
        }
    }

    // ---------- Add User Section ----------
    function handleAddUserToTeamOpen() {
        setAddUserOpen(true);
    }
    async function addButtonClick(userID, teamID, orgID) {
        try {
            await userAPI.AddUserToTeam(userID, teamID, orgID);
            toast.success('Successfully added user to team');
            setAddUserOpen(false);
            submitViewTeamMembers(teamOrgID, selectedTeamID);
        } catch (error) {
            toast.error(error);
        }
    }
    function handleAddUserToTeamClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // Reset the state if the user confirmed they want to exit.
        setSelectedUserID('');
        setAddUserOpen(false);
    }
    function addNewUser() {
        return (
            <Dialog open={addUserOpen} onClose={handleAddUserToTeamClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {`Add User to ${endsWithTeam(teamDisplayName) ? teamDisplayName : teamDisplayName + ' Team'}`}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <FormControl fullWidth={true} margin='normal' required>
                                <InputLabel
                                    id='userSelect-label'
                                    shrink={true}
                                    sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                                >
                                    User Selection
                                </InputLabel>
                                <Select
                                    style={{ minWidth: '200px' }}
                                    id='user-select'
                                    labelId='userSelect-label'
                                    value={selectedUserID}
                                    onChange={(event) => {
                                        console.log('ManageAddTeams: event target =', event.target);
                                        setSelectedUserID(event.target.value);
                                    }}
                                    required
                                >
                                    {userRows.map((row, i) => {
                                        // console.log('ManageAddTeams: individual userRows row =', row);
                                        return (
                                            <MenuItem value={row.id} key={row.id}>
                                                {row.first_name} {row.last_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                            <Button
                                id='addNewUser'
                                variant='contained'
                                color='primary'
                                style={{ backgroundColor: '#4CAF50', color: 'white' }}
                                onClick={() => {
                                    handleAddUserToTeamOpen();
                                    addButtonClick(selectedUserID, selectedTeamID, teamOrgID);
                                }}
                            >
                                Add
                            </Button>
                            <Button onClick={handleAddUserToTeamClose} variant='contained' color='error'>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    // ---------- Add Team Section ----------
    function handleAddNewTeamOpen() {
        setAddNewTeamOpen(true);
    }
    function handleAddNewTeamClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // If they selected values, ensure they want to exit.
        // console.log('ManageAddTeams: teamOrgId type =', typeof(teamOrgID));
        if (teamName !== '' || teamDisplayName !== '' || teamOrgID !== '') {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        setTeamID(-1);
        setTeamName('');
        setTeamDisplayName('');
        setTeamDefault(false);
        setTeamOrgID('');
        setAddNewTeamOpen(false);
    }
    function addNewTeam() {
        return (
            <Dialog open={addNewTeamOpen} onClose={handleAddNewTeamClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'Add Team'}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <TextField
                                error={teamSubmitted && teamName === ''}
                                helperText={teamSubmitted && teamName === '' ? 'Team Name is required' : ''}
                                onChange={(event) => {
                                    setTeamName(event.target.value);
                                }}
                                value={teamName}
                                required={true}
                                label='Team Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={teamSubmitted && teamDisplayName === ''}
                                helperText={teamSubmitted && teamDisplayName === '' ? 'Display Name is required' : ''}
                                onChange={(event) => {
                                    setTeamDisplayName(event.target.value);
                                }}
                                value={teamDisplayName}
                                required={true}
                                label='Display Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />

                            <FormControl fullWidth>
                                <InputLabel id='team-org-label'>Organization</InputLabel>
                                <Select
                                    labelId='team-org-label'
                                    id='team-org-select'
                                    value={teamOrgID}
                                    label='Team'
                                    onChange={(event) => {
                                        setTeamOrgID(event.target.value);
                                    }}
                                    required
                                    error={teamSubmitted && teamOrgID === ''}
                                >
                                    {orgs.map((row, i) => {
                                        return (
                                            <MenuItem value={row.id} key={row.name}>
                                                {row.display_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                    {teamSubmitted && teamOrgID === '' ? 'An Organization is required' : ''}
                                </FormHelperText>
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onClick={(e) => {
                                                setTeamDefault(!teamDefault);
                                            }}
                                        />
                                    }
                                    label="Default Team for it's organization"
                                />
                            </FormGroup>

                            <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                                <Button onClick={submitNewTeam} variant='contained'>
                                    Save Organization
                                </Button>
                                <Button onClick={handleAddNewTeamClose} variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    // ---------- Edit Team Section ----------
    async function showEditExistingTeam(teamDetails) {
        setTeamID(teamDetails.id);
        setTeamName(teamDetails.name);
        setTeamDisplayName(teamDetails.display_name);
        setTeamDefault(teamDetails.default);
        setTeamOrgID(teamDetails.organization_id);
        setTeamSubmitted(false);
    }
    function handleEditExistingTeamOpen() {
        setEditExistingTeamOpen(true);
    }
    function handleEditExistingTeamClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // Reset the state if the user confirmed they want to exit.
        setTeamID(-1);
        setTeamName('');
        setTeamDisplayName('');
        setTeamDefault(false);
        setTeamOrgID('');
        setEditExistingTeamOpen(false);
    }
    function editExistingTeam() {
        return (
            <Dialog open={editExistingTeamOpen} onClose={handleEditExistingTeamClose} maxWidth='md' fullWidth>
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'Edit Team'}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <TextField
                                error={teamSubmitted && teamName === ''}
                                helperText={teamSubmitted && teamName === '' ? 'Team Name is required' : ''}
                                onChange={(event) => setTeamName(event.target.value)}
                                value={teamName}
                                required={true}
                                label='Team Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />
                            <TextField
                                error={teamSubmitted && teamDisplayName === ''}
                                helperText={teamSubmitted && teamDisplayName === '' ? 'Display Name is required' : ''}
                                onChange={(event) => setTeamDisplayName(event.target.value)}
                                value={teamDisplayName}
                                required={true}
                                label='Display Name'
                                variant='outlined'
                                fullWidth
                                margin='normal'
                            />

                            <FormControl fullWidth>
                                <InputLabel id='team-org-label'>Organization</InputLabel>
                                <Select
                                    labelId='team-org-label'
                                    id='team-org-select'
                                    value={teamOrgID}
                                    label='Team'
                                    onChange={(event) => setTeamOrgID(event.target.value)}
                                    required
                                    error={teamSubmitted && teamOrgID === ''}
                                >
                                    {orgs.map((row, i) => {
                                        return (
                                            <MenuItem value={row.id} key={row.name}>
                                                {row.display_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                    {teamSubmitted && teamOrgID === '' ? 'An Organization is required' : ''}
                                </FormHelperText>
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox onClick={(e) => setTeamDefault(!teamDefault)} />}
                                    label="Default Team for it's organization"
                                />
                            </FormGroup>

                            <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                                <Button onClick={submitEditTeam} variant='contained'>
                                    Save Organization
                                </Button>
                                <Button onClick={handleEditExistingTeamClose} variant='contained' color='error'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    // ---------- View Team Section ----------
    async function setValuesOfSpecificViewMembersRow(rowDetails) {
        console.log('ManageAddTeams: View Members rowDetails =', rowDetails);
        setSelectedTeamID(rowDetails.id);
        setTeamID(rowDetails.id);
        setTeamDisplayName(rowDetails.display_name);
        setTeamName(rowDetails.name);
        setTeamOrgID(rowDetails.organization_id);
        submitViewTeamMembers(rowDetails.organization_id, rowDetails.id);
        setTeamSubmitted(false);
    }
    function handleViewExistingTeamOpen() {
        setViewExistingTeamOpen(true);
    }
    function handleViewExistingTeamClose(event, reason) {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.
        if (reason === 'backdropClick') {
            return;
        }

        // Reset the state if the user confirmed they want to exit.
        setTeamID(-1);
        setTeamName('');
        setTeamDisplayName('');
        setTeamDefault(false);
        setTeamOrgID('');
        setViewExistingTeamOpen(false);
    }
    function viewExistingTeam() {
        return (
            <Dialog
                open={!addUserOpen && viewExistingTeamOpen}
                onClose={handleViewExistingTeamClose}
                maxWidth='md'
                fullWidth
            >
                <DialogContent>
                    <DialogTitle component='h1' variant='h3' textAlign={'center'}>
                        {'View Team Members'}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <FormControl fullWidth={true} margin='normal' required>
                                <InputLabel id='teamName-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                    Team Name
                                </InputLabel>
                                <Select
                                    id='teamName-select'
                                    labelId='teamName-label'
                                    label='Team Name'
                                    value={selectedTeamID}
                                    onChange={(event) => {
                                        console.log('ManageAddTeams: event target =', event.target);
                                        setSelectedTeamID(event.target.value);
                                        setTeamID(event.target.value);
                                        setTeamDisplayName(selectTeams.current.get(event.target.value));
                                    }}
                                    required
                                >
                                    {originalTeamRows.map((row, i) => {
                                        return (
                                            <MenuItem value={row.id} key={row.id}>
                                                {row.display_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <Box display='flex' justifyContent='start' gap={2} sx={{ mt: 3, mb: 2 }}>
                                <Button
                                    onClick={() => {
                                        submitViewTeamMembers(teamOrgID, teamID);
                                    }}
                                    variant='contained'
                                >
                                    {/* Second View Members */}
                                    View Members
                                </Button>
                                <Button onClick={handleViewExistingTeamClose} variant='contained' color='error'>
                                    Close
                                </Button>
                                <Box flexGrow={1}></Box>
                                <Button
                                    id='addNewUser'
                                    variant='contained'
                                    color='primary'
                                    style={{ backgroundColor: '#4CAF50', color: 'white' }}
                                    onClick={handleAddUserToTeamOpen}
                                >
                                    Add User
                                </Button>
                            </Box>

                            {hasClickedView &&
                                (teamMembers === null || teamMembers.length === 0 ? (
                                    <Box display='flex' justifyContent='center' alignItems='center'>
                                        {`No members found in ${teamDisplayName}`}
                                    </Box>
                                ) : (
                                    <TableContainer component={Paper} sx={{ mt: 3 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Email Address</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {teamMembers.map((member, i) => (
                                                    <TableRow key={`${member.first_name}-${member.last_name}-${i}`}>
                                                        <TableCell>
                                                            {member.first_name + ' ' + member.last_name}
                                                        </TableCell>
                                                        <TableCell>{member.email}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title='Remove User from Team'>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        removeUserFromTeam(
                                                                            teamDisplayName,
                                                                            member,
                                                                            selectedTeamID,
                                                                            teamOrgID
                                                                        );
                                                                    }}
                                                                >
                                                                    <DeleteIcon></DeleteIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            <TabPanel value={props.tabPanelValue} index={1}>
                <Box display='flex' alignItems='center'>
                    <TextField
                        label='Filter Teams'
                        margin='normal'
                        variant='outlined'
                        onChange={handleTeamTextFilter}
                    />
                    <FormControl margin='normal' sx={{ minWidth: 240, marginLeft: '25px' }}>
                        <InputLabel id='team-org-filter-label'>Filter by Organization</InputLabel>
                        <Select
                            labelId='team-org-filter-label'
                            id='team-org-filter'
                            value={selectedOrgID}
                            label='Organization'
                            onChange={handleTeamOrgFilter}
                            defaultValue=''
                        >
                            <MenuItem value=''>No Filter</MenuItem>
                            {orgs.map((row, i) => {
                                return (
                                    <MenuItem value={row.id} key={row.name}>
                                        {row.display_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        id='addNewTeam'
                        variant='contained'
                        color='primary'
                        style={{ backgroundColor: '#4CAF50', color: 'white' }}
                        sx={{ m: 2 }}
                        onClick={() => {
                            handleAddNewTeamOpen();
                        }}
                    >
                        Add Team
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table aria-label='Teams Table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Team Name</TableCell>
                                <TableCell>Is Default Team?</TableCell>
                                <TableCell>Organization</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(teamRowsPerPage > 0
                                ? teamRows.slice(
                                      teamPage * teamRowsPerPage,
                                      teamPage * teamRowsPerPage + teamRowsPerPage
                                  )
                                : teamRows
                            ).map((row, i) => {
                                return (
                                    <TableRow key={row.name}>
                                        <TableCell>{row.display_name}</TableCell>
                                        <TableCell>{row.default ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{row.organization.display_name}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant='contained'
                                                style={{ marginRight: '15px' }}
                                                onClick={() => {
                                                    showEditExistingTeam(row);
                                                    handleEditExistingTeamOpen();
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant='contained'
                                                style={{ marginRight: '15px' }}
                                                onClick={() => {
                                                    // console.log('ManageAddTeams: View Members Individual Row =', row);
                                                    setValuesOfSpecificViewMembersRow(row);
                                                    handleViewExistingTeamOpen();
                                                }}
                                            >
                                                {/* First View Members */}
                                                View Members
                                            </Button>
                                            <Button variant='contained' color='error' onClick={() => deleteTeam(row)}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyTeamRows > 0 && (
                                <TableRow style={{ height: 53 * emptyTeamRows }}>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    count={teamRows.length}
                                    rowsPerPage={teamRowsPerPage}
                                    page={teamPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </TabPanel>

            {addNewUser()}
            {addNewTeam()}
            {editExistingTeam()}
            {viewExistingTeam()}
        </>
    );
}

export default ManageAddTeams;
